<div class="container">
  <esg-side-menu class="side" [showBackButton]="useNewMainNavigation">
    <div top class="top-section">
      <h2 class="title">Settings</h2>
      <hr />
    </div>
    <div class="menu-container">
      <div class="menu-section">
        <a class="menu-item" routerLink="profile" routerLinkActive="active">{{ appState.userInfo.value?.email }}</a>
      </div>
      <ng-container *ngIf="state.showClientSettings">
        <hr />
        <div class="menu-section">
          <a *ngIf="state.hasOrganizationReadPermission" class="menu-item" routerLink="organization"
            routerLinkActive="active">
            Organization
          </a>
          <a class="menu-item" routerLink="regions" routerLinkActive="active">Regions</a>
          <a *ngIf="state.hasUserReadPermission" class="menu-item" routerLink="users" routerLinkActive="active">
            Users
          </a>
          <a *ngIf="state.hasAccessTokenReadPermission" class="menu-item" routerLink="access-token"
            routerLinkActive="active">
            Access token
          </a>
        </div>
      </ng-container>
      <ng-container *ngIf="state.showSuperAdminSettings">
        <hr />
        <div class="menu-section">
          <a *ngIf="state.hasOrganizationReadPermission" class="menu-item" routerLink="all-organizations"
            routerLinkActive="active">
            All organizations
          </a>
          <a *ngIf="state.hasUserReadPermission" class="menu-item" routerLink="all-users" routerLinkActive="active">
            All users
          </a>
          <a *ngIf="state.hasSettingsReadPermission" class="menu-item" routerLink="indicator-library"
            routerLinkActive="active">
            Indicator Library
          </a>
          <a *ngIf="state.hasSettingsReadPermission" class="menu-item" routerLink="categories"
            routerLinkActive="active">
            Data Collect Categories
          </a>
          <a *ngIf="state.hasSettingsReadPermission" class="menu-item" routerLink="units" routerLinkActive="active">
            Units
          </a>
        </div>
      </ng-container>
    </div>
  </esg-side-menu>
  <div class="main">
    <router-outlet></router-outlet>
  </div>
</div>