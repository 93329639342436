import { Component, Input } from '@angular/core';
import {
  IndicatorPeriodLabelsUi,
  VisibleValueColumn,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';
import { IKeyfigures } from '../section-content-preview.component';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';

@Component({
  selector: 'esg-indicator-set-preview',
  templateUrl: './indicator-set-preview.component.html',
  styleUrl: './indicator-set-preview.component.scss',
})
export class IndicatorSetPreviewComponent {
  @Input({ required: true }) keyFigures!: IKeyfigures[];
  @Input() indicatorPeriodLabels: IndicatorPeriodLabelsUi = {
    targetLabel: 'Target',
    currentLabel: 'Current',
    previousLabel: 'Previous',
  };
  @Input() visibleColumns: VisibleValueColumn[] = [];
  @Input() isCustomPeriod: boolean = false;

  visibleColumnEnum = VisibleValueColumn;
  references: string = '';

  get getGridTemplateColumns(): string {
    return `1fr min-content repeat(${this.getVisibleColumnsCount() - 2}, minmax(124px, 15%))`;
  }

  get getGridColumnSpan(): string {
    return `span ${this.getVisibleColumnsCount()}`;
  }

  ngOnInit(): void {
    this.references = Array.from(
      new Set(this.keyFigures.flatMap(value => value.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ' | ').split(' | ')))
    ).join(' | ');
  }

  getFormattedNumber(value: string | number | undefined) {
    return value || value === 0 ? numberToFormattedNumberString(Number(value), 0, 2) : '';
  }

  isColumnVisible(columnValue: string): boolean {
    if (!this.visibleColumns) return true;
    return this.visibleColumns.some(column => column === columnValue);
  }

  private getVisibleColumnsCount(): number {
    let count = 2;
    if (this.isColumnVisible(this.visibleColumnEnum.TargetValue)) count++;
    if (this.isColumnVisible(this.visibleColumnEnum.CurrentValue)) count++;
    if (this.isColumnVisible(this.visibleColumnEnum.PreviousValue)) count++;
    return count;
  }
}
