import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface AssignedTaskUi {
  reportId: string;
  reportName: string;
  topicId: string;
  topicItemId: string;
  name: string;
  statusLabel: string;
  statusColor: string;
  lastEdited: Date;
}

// Currently not used

@Component({
  selector: 'esg-assigned-tasks',
  templateUrl: './assigned-tasks.component.html',
  styleUrls: ['./assigned-tasks.component.scss'],
})
export class AssignedTasksComponent {
  @Input({ required: true }) tasks!: AssignedTaskUi[];
  @Output() onTaskClick = new EventEmitter<AssignedTaskUi>();

  handleTaskClick(item: AssignedTaskUi) {
    this.onTaskClick.emit(item);
  }
}
