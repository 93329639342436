import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeyboardShortcutsDirective } from 'src/app/shared/directives/keyboard-shortcuts.directive';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';

// Currently not used

@Component({
  selector: 'esg-indicator-input',
  templateUrl: './indicator-input.component.html',
  styleUrls: ['./indicator-input.component.scss'],
  host: {
    '(onEscapeKey)': 'handleOnClose($event)',
    '(onEnterKey)': 'handleOnEnter()',
  },
  hostDirectives: [{ directive: KeyboardShortcutsDirective, outputs: ['onEscapeKey', 'onEnterKey'] }],
})
export class IndicatorInputComponent {
  @Input({ required: true }) fc!: FormControl<string>;
  @Input() header: string = '';
  @Input() unit: string = '';
  @Input() isCalculated: boolean = false;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onGoToDatacollect = new EventEmitter();
  @Output() onSetConnection = new EventEmitter();

  showConnectionDialog: boolean = false;
  disconnectDataCollectMessage =
    'If you disconnect this value from Data Collect\nyou will be able to manually edit the value in\nthe report. Any updates done in Data Collect\nwill not show in the report as long as it is\ndisconnected. But no worries, you can re-\nconnect to Data Collect at any time.';
  reconnectDataCollectMessage =
    'If you reconnect this value to Data Collect it will\nbe calculated based on data in Data Collect. You\n will not be able to manually edit the value in the\nreport. ';
  alertTitle: 'Disconnect from Data Collect?' | 'Reconnect to Data Collect?' = 'Disconnect from Data Collect?';
  alertSubmitText: 'Disconnect' | 'Reconnect' = 'Disconnect';
  alertMessage = '';

  handleOnClose(event: Event) {
    event.stopPropagation();
    this.onClose.emit();
  }

  handleOnEnter() {
    this.handleOnSubmit();
    this.onClose.emit();
  }

  handleOnSubmit() {
    if (this.fc.dirty) {
      this.onSubmit.emit();
      this.fc.markAsPristine();
    }
  }

  handleOnCloseConnectionDialog() {
    this.showConnectionDialog = false;
  }

  handleOnSetConnection() {
    this.onSetConnection.emit();
    this.showConnectionDialog = false;
  }

  indicatorValueMenuProvider = (): IMenuItem[] => [
    { id: 'go-to-data-collect', label: 'Go to Data Collect', onClick: () => this.onGoToDatacollect.emit() },
    {
      id: 'connect-disconnect',
      label: this.fc.disabled ? 'Disconnect Data' : 'Reconnect Data',
      onClick: () => {
        this.alertTitle = this.fc.disabled ? 'Disconnect from Data Collect?' : 'Reconnect to Data Collect?';
        this.alertMessage = this.fc.disabled ? this.disconnectDataCollectMessage : this.reconnectDataCollectMessage;
        this.alertSubmitText = this.fc.disabled ? 'Disconnect' : 'Reconnect';
        this.showConnectionDialog = true;
      },
    },
  ];
}
