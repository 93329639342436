<div [class]="['container', variant]">
  <ngx-dropzone
    #drop
    (change)="onSelect($event)"
    [multiple]="false"
    [disableClick]="true"
    [accept]="accepted"
    [maxFileSize]="2000000"
    [ngStyle]="dropZoneStyle">
    <ngx-dropzone-label>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_494_9700)">
          <path
            d="M30.5669 0.000204043H5.43472C3.99451 0.00258654 2.61402 0.576 1.59596 1.59471C0.577904 2.61342 0.00537089 3.99427 0.00390625 5.43448V30.5701C0.00591938 32.0101 0.578651 33.3905 1.59661 34.409C2.61457 35.4275 3.99474 36.0009 5.43472 36.0037H30.5703C32.011 36.0018 33.3922 35.4287 34.4109 34.41C35.4296 33.3913 36.0028 32.0101 36.0046 30.5694V5.43379C36.0031 3.99298 35.4301 2.6116 34.4113 1.59279C33.3925 0.573987 32.0111 0.000978193 30.5703 -0.000488281L30.5669 0.000204043ZM1.6166 5.43448C1.61788 4.42213 2.02066 3.45161 2.73656 2.73584C3.45247 2.02006 4.42306 1.61746 5.43541 1.61636H30.571C31.5833 1.61764 32.5537 2.02032 33.2694 2.73608C33.9852 3.45184 34.3879 4.42225 34.3891 5.43448V23.4518L28.7 18.397C28.5438 18.2575 28.3394 18.1844 28.1301 18.1931C27.9209 18.2017 27.7232 18.2915 27.579 18.4434L22.2153 24.0736L16.9341 18.4475C16.859 18.3675 16.7684 18.3037 16.6678 18.2598C16.5672 18.216 16.4587 18.1932 16.349 18.1927H16.3455C16.2363 18.1927 16.1283 18.2148 16.0278 18.2576C15.9274 18.3004 15.8367 18.3632 15.7611 18.442L2.21972 32.6107C1.8275 32.0015 1.6182 31.2926 1.6166 30.568V5.43448ZM3.35532 33.7609L16.3393 20.1752L29.6688 34.384H5.43334C4.69497 34.3831 3.97296 34.1665 3.35601 33.7609H3.35532ZM31.7045 34.1922L23.3184 25.2535L28.2063 20.1225L34.385 25.6122V30.5659C34.3825 31.3771 34.1211 32.1663 33.6389 32.8186C33.1566 33.4709 32.4787 33.9521 31.7038 34.1922H31.7045Z"
            fill="#0071E3" />
          <path
            d="M20.8456 14.7454C21.7716 14.7465 22.6693 14.4264 23.3857 13.8396C24.1022 13.2529 24.5929 12.4358 24.7743 11.5278C24.9557 10.6197 24.8166 9.6768 24.3806 8.85983C23.9447 8.04286 23.2388 7.40238 22.3835 7.04757C21.5281 6.69276 20.5762 6.64559 19.69 6.9141C18.8037 7.18261 18.038 7.75017 17.5234 8.52004C17.0088 9.2899 16.7771 10.2144 16.8679 11.136C16.9587 12.0575 17.3662 12.9191 18.0211 13.5738C18.77 14.3234 19.786 14.7449 20.8456 14.7454ZM20.8456 8.36873C21.3967 8.36843 21.9308 8.55921 22.357 8.90858C22.7832 9.25794 23.075 9.74427 23.1829 10.2847C23.2907 10.8251 23.2078 11.3862 22.9483 11.8724C22.6888 12.3586 22.2687 12.7397 21.7597 12.9509C21.2507 13.1621 20.6842 13.1902 20.1568 13.0306C19.6293 12.8709 19.1735 12.5333 18.8671 12.0752C18.5607 11.6172 18.4225 11.0671 18.4762 10.5186C18.5299 9.97019 18.7721 9.45731 19.1616 9.0674C19.3825 8.84573 19.6449 8.66979 19.9339 8.54967C20.2228 8.42954 20.5326 8.36758 20.8456 8.36734V8.36873Z"
            fill="#0071E3" />
        </g>
        <defs>
          <clipPath id="clip0_494_9700">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div class="content" [class.component]="variant === 'component'">
        <div class="first-line">
          <span
            [innerHTML]="
              uploaded
                ? sanitizeHtml(componentTexts.firstLineP1UploadedPhoto)
                : sanitizeHtml(componentTexts.firstLineP1)
            "></span>
          <span
            class="link"
            (click)="drop.showFileSelector()"
            [innerHTML]="sanitizeHtml(componentTexts.firstLineSpan)"></span>
          <span
            [innerHTML]="
              uploaded
                ? sanitizeHtml(componentTexts.firstLineP2UploadedPhoto)
                : sanitizeHtml(componentTexts.firstLineP2)
            "></span>
        </div>
        <div class="second-line" [innerHTML]="sanitizeHtml(componentTexts.secondLine)"></div>
        <div class="validation-error" [ngClass]="{ hidden: validImg }">
          This is not a valid format. Please check the requirements above.
        </div>
      </div>
    </ngx-dropzone-label>
    <ngx-dropzone-label class="list-of-files" *ngIf="!!uploadedPhotos.length && variant !== 'component'">
      <div class="file" *ngFor="let file of uploadedPhotos">
        <span class="img-container">
          <img [src]="file.url" alt="" />
          <svg
            (click)="handleClearPhoto(file)"
            class="clear-icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 15C11.8294 15 15 11.8225 15 8C15 4.17059 11.8225 1 7.99314 1C4.17059 1 1 4.17059 1 8C1 11.8225 4.17745 15 8 15ZM5.69412 10.8824C5.37843 10.8824 5.13137 10.6284 5.13137 10.3127C5.13137 10.1618 5.18627 10.0176 5.29608 9.91471L7.19706 8.00686L5.29608 6.10588C5.18627 5.99608 5.13137 5.85882 5.13137 5.70784C5.13137 5.38529 5.37843 5.1451 5.69412 5.1451C5.85196 5.1451 5.97549 5.2 6.08529 5.30294L8 7.21078L9.92843 5.29608C10.0451 5.17941 10.1686 5.13137 10.3196 5.13137C10.6353 5.13137 10.8892 5.37843 10.8892 5.69412C10.8892 5.85196 10.8412 5.97549 10.7176 6.09902L8.8098 8.00686L10.7108 9.90784C10.8275 10.0108 10.8824 10.1549 10.8824 10.3127C10.8824 10.6284 10.6284 10.8824 10.3059 10.8824C10.148 10.8824 10.0039 10.8275 9.90098 10.7176L8 8.8098L6.10588 10.7176C5.99608 10.8275 5.85196 10.8824 5.69412 10.8824Z"
              fill="#183642" />
          </svg>
        </span>
        <span>
          {{ file.fileName }}
        </span>
      </div>
    </ngx-dropzone-label>
    <ngx-dropzone-label
      class="alt-tag-input"
      *ngIf="!!uploadedPhotos.length && altTagControl && variant !== 'component'">
      <div class="alt-tag-input-label">PLEASE Type an Alt tag</div>
      <esg-textbox-form
        class="alt-tag-input-form"
        placeholder="A short description of the image"
        [fc]="altTagControl"></esg-textbox-form>
    </ngx-dropzone-label>
  </ngx-dropzone>
</div>
