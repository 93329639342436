import { Component, EventEmitter, Output } from '@angular/core';
import { ContentTypes } from '../content-configuration';

@Component({
  selector: 'esg-content-context-menu',
  templateUrl: './content-context-menu.component.html',
  styleUrl: './content-context-menu.component.scss',
})
export class ContentContextMenuComponent {
  @Output() onAddContent = new EventEmitter<ContentTypes>();

  open: boolean = false;
  dropUp: boolean = false;

  handleClick(event: Event) {
    event.stopPropagation();
    this.open = !this.open;
  }

  handleClose() {
    this.open = false;
  }

  handleAddIndicator(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ContentTypes.Indicator);
    this.open = false;
  }

  handleAddText(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ContentTypes.Text);
    this.open = false;
  }

  handleAddImage(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ContentTypes.Picture);
    this.open = false;
  }

  handleAddImportFromExcel(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ContentTypes.ImportFromExcel);
    this.open = false;
  }

  handleIntersection(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.intersectionRatio < 1) {
      this.dropUp = true;
    }
  }
}
