import { Component } from '@angular/core';
import { ElectricityStateService } from './electricity-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-electricity',
  templateUrl: './electricity.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: ElectricityComponent }, ElectricityStateService],
})
export class ElectricityComponent extends RecordsComponent {
  constructor(public state: ElectricityStateService) {
    super(state);
  }
}
