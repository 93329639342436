<esg-dialog class="auto" [showCloseBtn]="false" (onClose)="handleOnClose()">
  <div class="container">
    <ng-container *ngFor="let slide of slides; let index = index">
      @defer (when currentSlideIndex + 1 >= index){
      <esg-carousel-slide [slide]="slide" [class.active]="index === currentSlideIndex"></esg-carousel-slide>
      } @placeholder { }
    </ng-container>
    <button class="skip-button" (click)="handleOnClose()">Skip</button>
    <div class="controls">
      <button class="arrow-button previous" (click)="handlePreviousSlide()" [disabled]="isFirstSlide">
        <esg-chevron></esg-chevron>
        Previous
      </button>
      <div class="pagination">
        <span
          *ngFor="let slide of slides; let index = index"
          class="pagination-bar"
          [class.active]="index === currentSlideIndex"></span>
      </div>
      <button class="arrow-button" (click)="handleNextSlide()">
        Next
        <esg-chevron></esg-chevron>
      </button>
      <esg-button *ngIf="isLastSlide" ngClass="confirm-button" (onClick)="handleOnClose()">Got it</esg-button>
    </div>
  </div>
</esg-dialog>
