import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppAuthService } from 'src/app/core/app-auth.service';

@Component({
  selector: 'esg-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  constructor(private appAuth: AppAuthService, private router: Router) {}

  ngOnInit(): void {
    this.appAuth.accountInfo$.subscribe(result => {
      if (result) {
        this.router.navigate(['/home']);
      }
    });
  }
  handleLogin() {
    this.appAuth.login();
  }
}
