import { Component } from '@angular/core';
import { DataCollectCategoriesStateService } from './data-collect-categories-state.service';

export interface DataCategoriesUi {
  dataRecordCategory: string;
  name: string;
  mainTopic: string;
  lastUpdated: Date;
  editedBy: string;
}

@Component({
  selector: 'esg-data-collect-categories',
  templateUrl: './data-collect-categories.component.html',
  styleUrls: ['./data-collect-categories.component.scss'],
  providers: [DataCollectCategoriesStateService],
})
export class DataCollectCategoriesComponent {
  constructor(public state: DataCollectCategoriesStateService) {}

  handleSelectCategory(dataRecordCategory: string) {
    this.state.selectCategory(dataRecordCategory);
  }

  handleCloseCategory() {
    this.state.closeSelectedCategory();
  }
}
