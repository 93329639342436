<div class="container" [class.clickable]="onClick.observed" (click)="onClick.emit()">
  <div class="background-image">
    <div class="button-contaniner">
      <div class="button">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.899 7.73165L10.6993 4.13084C10.6277 4.05262 10.5281 4.00578 10.4222 4.0005C10.3163 3.99522 10.2126 4.03191 10.1336 4.10262C10.0585 4.17603 10.0139 4.2751 10.0086 4.38001C10.0034 4.48492 10.038 4.58794 10.1054 4.66846L12.7123 7.60019H2.39997C2.29389 7.60019 2.19216 7.64234 2.11715 7.71737C2.04214 7.7924 2 7.89417 2 8.00028C2 8.10639 2.04214 8.20815 2.11715 8.28318C2.19216 8.35822 2.29389 8.40037 2.39997 8.40037H12.713L10.1061 11.3321C9.9654 11.4875 9.98361 11.7522 10.1343 11.8979C10.2914 12.0498 10.5593 12.0255 10.7 11.8697L13.8997 8.26891C14.0426 8.07137 14.024 7.90383 13.8997 7.73308L13.899 7.73165Z"
            fill="white" />
        </svg>
        <span>Edit Settings</span>
      </div>
      <div *ngIf="onRemove.observed" class="button-remove" (click)="handleRemove($event)">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.16116 15H10.845C11.7314 15 12.3264 14.4154 12.3698 13.5164L12.7975 4.37584H13.5413C13.7955 4.37584 14 4.15581 14 3.89807C14 3.64032 13.7955 3.42658 13.5413 3.42658H10.7087V2.46475C10.7087 1.55321 10.126 1 9.17149 1H6.81612C5.86157 1 5.27893 1.55321 5.27893 2.46475V3.42658H2.46488C2.21694 3.42658 2 3.64032 2 3.89807C2 4.1621 2.21694 4.37584 2.46488 4.37584H3.20868L3.63636 13.5227C3.67975 14.4216 4.2624 15 5.16116 15ZM6.26446 2.52762C6.26446 2.16929 6.5124 1.93669 6.8843 1.93669H9.10331C9.47521 1.93669 9.72314 2.16929 9.72314 2.52762V3.42658H6.26446V2.52762ZM5.24793 14.0507C4.89463 14.0507 4.6281 13.7804 4.6095 13.4095L4.17562 4.37584H11.7996L11.3905 13.4095C11.3781 13.7867 11.1116 14.0507 10.7459 14.0507H5.24793ZM6.16529 12.9758C6.40083 12.9758 6.55579 12.8249 6.54959 12.6048L6.35744 5.87831C6.35124 5.65828 6.19628 5.5137 5.97314 5.5137C5.7376 5.5137 5.58264 5.66457 5.58884 5.8846L5.77479 12.6048C5.78099 12.8312 5.93595 12.9758 6.16529 12.9758ZM8 12.9758C8.23554 12.9758 8.40289 12.8249 8.40289 12.6048V5.8846C8.40289 5.66457 8.23554 5.5137 8 5.5137C7.76446 5.5137 7.60331 5.66457 7.60331 5.8846V12.6048C7.60331 12.8249 7.76446 12.9758 8 12.9758ZM9.84091 12.9758C10.064 12.9758 10.219 12.8312 10.2252 12.6048L10.4112 5.8846C10.4174 5.66457 10.2624 5.5137 10.0269 5.5137C9.80372 5.5137 9.64876 5.65828 9.64256 5.8846L9.45661 12.6048C9.45041 12.8249 9.60537 12.9758 9.84091 12.9758Z"
            fill="#183642" />
        </svg>
      </div>
    </div>
    <img *ngIf="backgroundPictureUrl" [src]="backgroundPictureUrl" [alt]="title" />
    <div *ngIf="!backgroundPictureUrl" class="blank">
      <div class="empty-field"></div>
      <div class="empty-field"></div>
    </div>
  </div>
  <div class="title">
    {{ title }}
  </div>
  <div class="description">
    {{ shortDescription }}
  </div>
</div>
