import { Injectable, Input } from '@angular/core';
import {
  DataRecordType,
  DataRecordCategory,
  AssetType,
  ICreateEmployeeWorkRelatedInjuriesDataRecordCommandParams,
  CreateEmployeeWorkRelatedInjuriesDataRecordCommandParams,
  InputType,
} from 'src/api-client/report-api.generated';
import {
  FieldConfig,
  ImportDataOutput,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { RecordsStateService } from '../records-state.service';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class WorkRelatedInjuriesStateService extends RecordsStateService {
  title = 'Work Related Injuries';
  recordCategory = DataRecordCategory.EmployessWorkRelatedInjuries;
  assetCategories = [AssetType.Organization, AssetType.Facility];

  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'recordableInjuries', title: 'Recordable Injuries', type: 'number' },
    { propertyName: 'fatalities', title: 'Fatalities', type: 'number' },
    { propertyName: 'highConsequenceInjuries', title: 'High Consequence Injuries', type: 'number' },
    { propertyName: 'illnessHealth', title: 'Illness/Health', type: 'number' },
    { propertyName: 'lostTimeInjuries', title: 'Lost Time Injuries', type: 'number' },
    { propertyName: 'daysLost', title: 'Days lost', type: 'number' },
    { propertyName: 'exposureHours', title: 'Exposure Hours', type: 'number' },
  ];

  importFromExcelEnabled = true;

  importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) {
      this.notificationService.showError(assetValidation.title, assetValidation.description);
      this.importingInProgress = false;
      return;
    }

    const addRecordPromises = data.records.map(record => {
      const asset = this.getAssetOptionColumn(record.asset?.value);
      const incidents: ICreateEmployeeWorkRelatedInjuriesDataRecordCommandParams = {
        type: DataRecordType.EmployeeWorkRelatedInjuries,
        dataSetId: dataSetId,
        assetId: asset.value,
        inputType: InputType.ImportedFromExcel,
        startDate: record.startDate?.value,
        endDate: record.endDate?.value,
        recordableInjuries: record.recordableInjuries?.value,
        fatalities: record.fatalities?.value,
        highConsequenceInjuries: record.highConsequenceInjuries?.value,
        illnessHealth: record.illnessHealth?.value,
        lostTimeInjuries: record.lostTimeInjuries?.value,
        daysLost: record.daysLost?.value,
        exposureHours: record.exposureHours?.value,
      };

      return this.dataRecordService.createDataRecord(
        new CreateEmployeeWorkRelatedInjuriesDataRecordCommandParams({ ...incidents })
      );
    });
    Promise.all(addRecordPromises).then(async response => {
      response.forEach(res => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });
      await this.loadRecords();
      this.importingInProgress = false;
      this.importDataFromExcelClose();
    });
  }

  async loadRecords() {
    await this.getDataRecords(DataRecordType.EmployeeWorkRelatedInjuries);
  }

  handleDeleteAllFilteredRecordsSubmit() {
    this.handleOnDeleteAllFilteredRecords(DataRecordType.EmployeeWorkRelatedInjuries);
  }
}
