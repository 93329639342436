import { Component, Input, OnInit } from '@angular/core';
import { AppAuthService } from 'src/app/core/app-auth.service';

@Component({
  selector: 'esg-loading-user',
  templateUrl: './loading-user.component.html',
  styleUrls: ['./loading-user.component.scss'],
})
export class LoadingUserComponent implements OnInit {
  @Input({ required: true }) userInfoLoading!: boolean;
  constructor(private appAuth: AppAuthService) {}

  ngOnInit(): void {}

  handleLogout() {
    this.appAuth.logout();
  }
}
