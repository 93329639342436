<div class="container">
  <div class="buttons">
    <header>Buttons</header>
    <div class="content">
      <esg-button>Default</esg-button>
      <esg-button class="secondary">secondary</esg-button>
      <esg-button class="tertiary">tertiary</esg-button>
    </div>
  </div>
  <div class="text-boxes">
    <header>Textboxes</header>
    <div class="content">
      <esg-textbox label="esg-textbox" [value]="textboxValue" (onChange)="textboxChange($event)"></esg-textbox>
      <esg-textbox-form label="esg-textbox" [fc]="textboxFormControl"></esg-textbox-form>
    </div>
  </div>
  <div class="context-menu">
    <header>Context menu (esg-context-menu)</header>
    <div class="content">
      <esg-context-menu [options]="contextMenuOptions"></esg-context-menu>
    </div>
  </div>
  <div class="dropdowns">
    <header>selector multiple</header>
    <div class="content">
      <div class="dropdown">
        <label>esg-dropdown-multiple-form</label>
        <esg-dropdown-multiple-form [fc]="multipleOptions" [options]="options"></esg-dropdown-multiple-form>
      </div>
      <div class="dropdown">
        <label>esg-dropdown-multiple-form with categories</label>
        <esg-dropdown-multiple-form
          [fc]="multipleOptions"
          [groupedOptions]="groupedOptions"></esg-dropdown-multiple-form>
      </div>
    </div>
  </div>
  <div class="dropdowns">
    <header>Selectors</header>
    <div class="content">
      <esg-select
        label="esg-select"
        [options]="options"
        [value]="optionValue.value"
        (onChange)="optionValue.setValue($event)"></esg-select>
      <esg-select
        label="esg-select with ngModel"
        [options]="options"
        [(ngModel)]="options[2]"
        [disabled]="true"
        (onChange)="optionValue.setValue($event)"></esg-select>
      <div *ngIf="optionValue.dirty">Dirty</div>
      <div *ngIf="optionValue.touched">Touched</div>
      <esg-select label="esg-select with formControl" [formControl]="optionValue" [options]="options"></esg-select>
    </div>
  </div>

  <div class="dropdowns">
    <header>Selector variants</header>
    <div class="content">
      <div class="dropdown">
        <label>standard</label>
        <esg-select [formControl]="optionValue" [options]="options"></esg-select>
      </div>
      <div class="dropdown">
        <label>ghost</label>
        <esg-select variant="ghost" [formControl]="optionValue" [options]="options"></esg-select>
      </div>
      <div class="dropdown">
        <label>table</label>
        <td class="input-cell">
          <esg-table-select class="input-dropdown" [formControl]="optionValue" [options]="options"></esg-table-select>
        </td>
      </div>
    </div>
  </div>

  <div class="dialogs">
    <header>Dialogs</header>
    <div class="toolbar">
      <button (click)="openDialogType = 'full-screen'">Open full</button>
      <button (click)="openDialogType = 'large-screen'">Open default</button>
      <button (click)="openDialogType = 'auto'">Open auto</button>
      <button (click)="openDialogType = 'preview-mode'">Open preview mode</button>
    </div>
    <div class="content">
      <esg-dialog class="full-screen" *ngIf="openDialogType === 'full-screen'" (onClose)="handleDialogClose()">
        Full dialog size
      </esg-dialog>
      <esg-dialog *ngIf="openDialogType === 'large-screen'" (onClose)="handleDialogClose()">
        Default dialog size
      </esg-dialog>
      <esg-dialog class="auto" *ngIf="openDialogType === 'auto'" (onClose)="handleDialogClose()">
        Auto dialog size
      </esg-dialog>
      <esg-dialog class="preview-mode" *ngIf="openDialogType === 'preview-mode'" (onClose)="handleDialogClose()">
        Preview-mode dialog size
      </esg-dialog>
    </div>
  </div>

  <div class="dot-flashing">
    <header>Dot flashing</header>
    <div class="content">
      <esg-section-dot-flashing [active]="true" [label]="'Importing...'">
        <div class="section-dot-flashing"></div>
      </esg-section-dot-flashing>
    </div>
  </div>
</div>
