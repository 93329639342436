<div class="notification-table">
  <div></div>
  <div class="header">Full</div>
  <div class="header">Title + link</div>
  <div class="header">Title + body</div>
  <div class="header">Title</div>
  <div class="header">Success</div>
  <div class="example">
    <esg-notification [title]="title" [message]="message" [link]="link" variant="success"></esg-notification>
    <button class="success" (click)="handleSuccess(title, message, link)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" [link]="link" variant="success"></esg-notification>
    <button class="success" (click)="handleSuccess(title, '', link)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" [message]="message" variant="success"></esg-notification>
    <button class="success" (click)="handleSuccess(title, message)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" variant="success"></esg-notification>
    <button class="success" (click)="handleSuccess(title, '')">Test</button>
  </div>
  <div class="header">Info</div>
  <div class="example">
    <esg-notification [title]="title" [message]="message" [link]="link" variant="info"></esg-notification>
    <button class="info" (click)="handleInfo(title, message, link)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" [link]="link" variant="info"></esg-notification>
    <button class="info" (click)="handleInfo(title, '', link)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" [message]="message" variant="info"></esg-notification>
    <button class="info" (click)="handleInfo(title, message)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" variant="info"></esg-notification>
    <button class="info" (click)="handleInfo(title, '')">Test</button>
  </div>
  <div class="header">Warning</div>
  <div class="example">
    <esg-notification [title]="title" [message]="message" [link]="link" variant="warning"></esg-notification>
    <button class="warning" (click)="handleWarning(title, message, link)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" [link]="link" variant="warning"></esg-notification>
    <button class="warning" (click)="handleWarning(title, '', link)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" [message]="message" variant="warning"></esg-notification>
    <button class="warning" (click)="handleWarning(title, message)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" variant="warning"></esg-notification>
    <button class="warning" (click)="handleWarning(title, '')">Test</button>
  </div>
  <div class="header">Error</div>
  <div class="example">
    <esg-notification [title]="title" [message]="message" [link]="link" variant="error"></esg-notification>
    <button class="error" (click)="handleError(title, message, link)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" [link]="link" variant="error"></esg-notification>
    <button class="error" (click)="handleError(title, '', link)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" [message]="message" variant="error"></esg-notification>
    <button class="error" (click)="handleError(title, message)">Test</button>
  </div>
  <div class="example">
    <esg-notification [title]="title" variant="error"></esg-notification>
    <button class="error" (click)="handleError(title, '')">Test</button>
  </div>
</div>
<esg-notification [title]="longTextTitle" [message]="longTextMessage" [link]="link" variant="info"></esg-notification>
