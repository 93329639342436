import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'esg-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
})
export class TemplateCardComponent implements OnInit {
  @Input() backgroundPictureUrl?: string;
  @Input({ required: true }) title!: string;
  @Input() shortDescription?: string = '';
  @Input() longDescription?: string = '';
  @Input() showChooseBtn: boolean = true;
  @Input() blankReport: boolean = true;
  @Output() onClick = new EventEmitter();

  ngOnInit(): void {}
}
