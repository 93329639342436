import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  GetAllIndicatorDataSourcesQueryResponse,
  GetRawDataSourcesCompatibleWithIndicatorDefinitionQueryResponse,
  GetIndicatorDefinitionAsDataSourcesCompatibleWithIndicatorDefinitionQueryResponse,
  IndicatorDataSourceClient,
} from '../../../api-client/report-api.generated';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndicatorDataSourceApiService {
  client: IndicatorDataSourceClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new IndicatorDataSourceClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async getAll(): Promise<GetAllIndicatorDataSourcesQueryResponse> {
    const observable$ = this.client.getAllIndicatorDataSources();
    return await lastValueFrom(observable$);
  }

  async getRawDataSourcesCompatibleWithIndicatorDefinition(
    indicatorDefinitionId: string
  ): Promise<GetRawDataSourcesCompatibleWithIndicatorDefinitionQueryResponse> {
    const observable$ = this.client.getRawDataSourcesCompatibleWithIndicatorDefinition(indicatorDefinitionId);
    return await lastValueFrom(observable$);
  }

  async getIndicatorDefinitionDataSourcesCompatibleWithIndicatorDefinition(
    indicatorDefinitionId: string
  ): Promise<GetIndicatorDefinitionAsDataSourcesCompatibleWithIndicatorDefinitionQueryResponse> {
    const observable$ =
      this.client.getIndicatorDefinitionAsDataSourcesCompatibleWithIndicatorDefinition(indicatorDefinitionId);
    return await lastValueFrom(observable$);
  }
}
