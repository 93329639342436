<div class="chart">
  <div class="content">
    <highcharts-chart
      [Highcharts]="Highcharts"
      [options]="chartOptions"
      [(update)]="update"
      (chartInstance)="hanleOnCreateChart($event)"
      style="width: 100%; height: 400px; display: block"></highcharts-chart>

    <div *ngIf="isLoading" class="spinner-container">
      <esg-dot-flashing label="Recalculating..."></esg-dot-flashing>
    </div>
  </div>
</div>
