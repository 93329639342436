<esg-dialog class="auto" (onClose)="onClose.emit()">
  <div class="content">
    <div class="title">Share Report</div>
    <div class="list">
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Url</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of state.list">
            <td>{{ item.name }}</td>
            <td>
              <div class="cell-url">
                <span #textToCopy>
                  {{ item.url }}
                </span>
                <button (click)="copyText(textToCopy.innerText)">
                  <svg
                    aria-hidden="true"
                    height="16"
                    viewBox="0 0 16 16"
                    version="1.1"
                    width="16"
                    data-view-component="true"
                    class="octicon octicon-copy">
                    <path
                      d="M0 6.75C0 5.784.784 5 1.75 5h1.5a.75.75 0 0 1 0 1.5h-1.5a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-1.5a.75.75 0 0 1 1.5 0v1.5A1.75 1.75 0 0 1 9.25 16h-7.5A1.75 1.75 0 0 1 0 14.25Z"></path>
                    <path
                      d="M5 1.75C5 .784 5.784 0 6.75 0h7.5C15.216 0 16 .784 16 1.75v7.5A1.75 1.75 0 0 1 14.25 11h-7.5A1.75 1.75 0 0 1 5 9.25Zm1.75-.25a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25Z"></path>
                  </svg>
                </button>
              </div>
            </td>
            <td>
              <esg-button class="tertiary" (onClick)="state.deleteAccessToken(item.id)">Delete</esg-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="create-line">
      <esg-add-button
        *ngIf="!state.showCreateAccessToken"
        label="Create"
        (onClick)="state.createAccessToken()"></esg-add-button>
      <esg-textbox-form
        *ngIf="state.showCreateAccessToken"
        label="Description"
        [required]="true"
        [autoFocus]="true"
        [fc]="state.accessTokenName"></esg-textbox-form>
      <esg-button
        [disabled]="!state.accessTokenName.valid"
        *ngIf="state.showCreateAccessToken"
        (onClick)="state.saveAccessToken()">
        Save
      </esg-button>
      <esg-button *ngIf="state.showCreateAccessToken" class="tertiary" (onClick)="state.cancelCreateAccessToken()">
        Cancel
      </esg-button>
    </div>
  </div>
</esg-dialog>
