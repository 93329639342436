import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-beta-tag',
  templateUrl: './beta-tag.component.html',
  styleUrls: ['./beta-tag.component.scss'],
})
export class BetaTagComponent {
  @Input() backgroundColor: string = '#999999';
  @Input() textColor: string = '#FFFFFF';
}
