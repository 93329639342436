import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { DialogComponentType } from 'src/app/shared/ui/dialog/dialog.component';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-ui-components',
  templateUrl: './ui-components.component.html',
  styleUrls: ['./ui-components.component.scss'],
})
export class UiComponentsComponent {
  textboxValue = 'textVALUE';
  textboxChange = (value: string) => {
    this.textboxValue = value;
  };
  textboxFormControl = new FormControl<string>('textVALUE', { nonNullable: true });

  contextMenuOptions: IMenuItem[] = [{ id: 'recalculate', label: 'Recalculate', onClick: () => {} }];

  options: IOption[] = Array.from({ length: 10 }, (_, i) => ({ value: (i + 1).toString(), label: `Label ${i + 1}` }));
  optionValue = new FormControl<IOption>(this.options[0], { nonNullable: true });
  //optionValue = new FormControl<IOption>({ value: this.options[0], disabled: true }, { nonNullable: true });
  groupedOptions: IGroupedOptions[] = [
    { label: 'group 1', options: this.options.slice(0, 2) },
    { label: 'group 2', options: this.options.slice(2, 4) },
    { label: 'group 3', options: this.options.slice(4, 6) },
    { label: 'group 4', options: this.options.slice(6, 8) },
    { label: 'group 5', options: this.options.slice(8, 10) },
  ];

  multipleOptions = new FormControl<IOption[]>([], { nonNullable: true });

  ngOnInit() {
    this.options.push({
      value: '31313',
      label: 'DDasd asd asd asd asd asd asd asd asd asd asd asd asd asd asd asd asd ',
    });
  }

  openDialogType?: DialogComponentType;
  handleDialogClose() {
    this.openDialogType = undefined;
  }
}
