import * as XLSX from 'xlsx';
import { ValueType } from './excel-importer';

export interface FieldConfig {
  title: string;
  type: ValueType;
  optional?: boolean;
  propertyName: string;
}

export function generateExcelFile(title: string, fieldConfigs: FieldConfig[]) {
  const workbook = XLSX.utils.book_new();

  const sheetData: any[][] = [];

  const headerRow = fieldConfigs.map(fc => fc.title);
  sheetData.push(headerRow);
  const templateRowData = fieldConfigs.map(fc => generateFakeDataForType(fc.type));
  sheetData.push(templateRowData);

  const sheet = XLSX.utils.aoa_to_sheet(sheetData);
  XLSX.utils.book_append_sheet(workbook, sheet, title);
  XLSX.writeFile(workbook, `${title} template.xlsx`);
}

function generateFakeDataForType(type: ValueType) {
  switch (type) {
    case 'number':
      return 1;
    case 'string':
      return 'string';
    case 'date':
      return new Date();
    case 'boolean':
      return true;
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}
