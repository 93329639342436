import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomFormValidators } from 'src/app/shared/utils/custom-form-validators';
import { DomSanitizer } from '@angular/platform-browser';
import { IUploadedPhoto } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { IOption } from 'src/app/static-data/options';
import {
  CountryCodes,
  CreateOrganizationCommandParams,
  CurrencyCodes,
  OrganizationLogo,
  UpdateOrganizationCommandParams,
} from 'src/api-client/report-api.generated';
import { IOrganizationDetailsUi } from './organization-form.component';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { CurrencyApiService } from 'src/app/api-client/report-api/currency-api-service';
import { sortOptionsAlphabetically } from 'src/app/shared/utils/array';

@Injectable()
export class OrganizationFormStateService {
  countryOptions: IOption[] = [];
  currencyOptions: IOption[] = [];

  organizationId?: string;
  fc: IOrganizationDetailsUi = {
    name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    logo: new FormControl<IUploadedPhoto | null>(null, {
      validators: [Validators.required],
    }),
    logoSquare: new FormControl<IUploadedPhoto | null>(null, {
      validators: [Validators.required],
    }),
    countryCode: new FormControl<IOption | null>(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    currencyCode: new FormControl<IOption | null>(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    orgID: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    brandColor: new FormControl<string | null>('', [CustomFormValidators.hexColor]),
    tableHeadColor: new FormControl<string | null>('', [CustomFormValidators.hexColor]),
  };

  formgroup = new FormGroup(this.fc);

  currencyFavouriteOptions: IOption[] = [
    {
      value: CurrencyCodes.USD,
      label: CurrencyCodes.USD,
    },
    {
      value: CurrencyCodes.EUR,
      label: CurrencyCodes.EUR,
    },
    {
      value: CurrencyCodes.GBP,
      label: CurrencyCodes.GBP,
    },
    {
      value: CurrencyCodes.NOK,
      label: CurrencyCodes.NOK,
    },
  ];

  showCurrencyWarning: boolean = false;
  organizationCurrency?: CurrencyCodes;

  constructor(
    private appApiService: ReportAppApiService,
    private organizationApiService: OrganizationApiService,
    private countryApiService: CountryApiService,
    private currencyApiService: CurrencyApiService,
    private domSanitizer: DomSanitizer
  ) {}

  async initOrganizationForm(organizationId?: string) {
    this.organizationId = organizationId;
    await Promise.all([this.getCountries(), this.getCurrencies()]);

    if (organizationId) {
      var { result } = await this.organizationApiService.getOrganizationById(organizationId);
      this.fc.name.setValue(result.name);
      this.fc.orgID.setValue(result.orgID);
      this.fc.countryCode.setValue(this.countryOptions.find(c => c.value === result.countryCode) || null);
      this.fc.currencyCode.setValue(this.currencyOptions.find(c => c.value === result.currencyCode) || null);
      this.fc.logo.setValue(result.logo ? await this.getLogo(result.logo) : null);
      this.fc.logoSquare.setValue(result.logoSquare ? await this.getLogo(result.logoSquare) : null);
      this.fc.brandColor.setValue(result.brandColor || null);
      this.fc.tableHeadColor.setValue(result.tableHeadColor || null);

      this.organizationCurrency = result.currencyCode;
    }
  }

  async getCountries() {
    var { result: allCoutries } = await this.countryApiService.getAllCountries();
    this.countryOptions = allCoutries.map<IOption>(c => ({ value: c.code, label: c.name }));
  }

  async getCurrencies() {
    const response = await this.currencyApiService.getAllCurrencies();
    this.currencyOptions = sortOptionsAlphabetically(
      response.result.map(currency => ({ value: currency.code, label: currency.code }))
    );
  }

  handleCountryChange(value: IOption) {
    this.fc.countryCode.markAsDirty();
    this.fc.countryCode.setValue(value);
  }

  handleCurrencyChange(value: IOption) {
    this.fc.currencyCode.markAsDirty();
    this.fc.currencyCode.setValue(value);
  }

  onColorChange(formControl: FormControl, value: string) {
    formControl.setValue(value);
  }

  async submit() {
    if (this.formgroup.valid && this.fc.countryCode.value && this.fc.currencyCode.value) {
      if (this.organizationId) {
        if (this.fc.currencyCode.value.value !== this.organizationCurrency && !this.showCurrencyWarning) {
          this.showCurrencyWarning = true;
          return;
        }
        const result = await this.organizationApiService.updateOrganization(
          new UpdateOrganizationCommandParams({
            id: this.organizationId,
            name: this.fc.name.value,
            orgID: this.fc.orgID.value,
            brandColor: this.fc.brandColor.value || undefined,
            tableHeadColor: this.fc.tableHeadColor.value || undefined,
            countryCode: CountryCodes[this.fc.countryCode.value.value as keyof typeof CountryCodes],
            currencyCode: CurrencyCodes[this.fc.currencyCode.value.value as keyof typeof CurrencyCodes],
            logo: this.fc.logo.value
              ? new OrganizationLogo({
                  blobName: this.fc.logo.value?.blobName || '',
                  fileName: this.fc.logo.value?.fileName,
                })
              : undefined,
            logoSquare: this.fc.logoSquare.value
              ? new OrganizationLogo({
                  blobName: this.fc.logoSquare.value?.blobName || '',
                  fileName: this.fc.logoSquare.value?.fileName,
                })
              : undefined,
          })
        );
      } else {
        const result = await this.organizationApiService.createOrganization(
          new CreateOrganizationCommandParams({
            name: this.fc.name.value,
            orgID: this.fc.orgID.value,
            brandColor: this.fc.brandColor.value || undefined,
            tableHeadColor: this.fc.tableHeadColor.value || undefined,
            countryCode: CountryCodes[this.fc.countryCode.value.value as keyof typeof CountryCodes],
            currencyCode: CurrencyCodes[this.fc.currencyCode.value?.value as keyof typeof CurrencyCodes],
            logo: this.fc.logo.value
              ? new OrganizationLogo({
                  blobName: this.fc.logo.value?.blobName || '',
                  fileName: this.fc.logo.value?.fileName,
                })
              : undefined,
            logoSquare: this.fc.logoSquare.value
              ? new OrganizationLogo({
                  blobName: this.fc.logoSquare.value?.blobName || '',
                  fileName: this.fc.logoSquare.value?.fileName,
                })
              : undefined,
          })
        );
      }
    }
  }

  uploadLogo(file: File) {
    this.appApiService
      .uploadImage({
        fileName: file.name,
        data: file,
      })
      .subscribe(result => {
        let objectUrl = URL.createObjectURL(file);
        var logo: IUploadedPhoto = {
          url: this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl),
          fileName: file.name,
          blobName: result.blobName,
        };
        this.fc.logo.markAsDirty();
        this.fc.logo.setValue(logo);
      });
  }
  uploadLogoSquare(file: File) {
    this.appApiService
      .uploadImage({
        fileName: file.name,
        data: file,
      })
      .subscribe(result => {
        let objectUrl = URL.createObjectURL(file);
        var logo: IUploadedPhoto = {
          url: this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl),
          fileName: file.name,
          blobName: result.blobName,
        };
        this.fc.logoSquare.markAsDirty();
        this.fc.logoSquare.setValue(logo);
      });
  }

  removeLogo() {
    this.fc.logo.setValue(null);
  }
  removeLogoSquare() {
    this.fc.logoSquare.setValue(null);
  }

  onCurrencyWarningClose() {
    this.showCurrencyWarning = false;
    if (this.organizationCurrency) {
      this.fc.currencyCode.setValue(this.currencyOptions.find(c => c.value === this.organizationCurrency) || null);
    }
  }

  async onCurrencyWarningConfirm() {
    await this.submit();
    this.showCurrencyWarning = false;
  }

  async getLogo(logo: OrganizationLogo): Promise<IUploadedPhoto> {
    const blobUrl = this.appApiService.getBlobUrl(logo.blobName);
    const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return {
      url: safeUrl,
      fileName: logo.fileName,
      blobName: logo.blobName,
      file: {
        data: blob,
        fileName: logo.fileName,
      },
    };
  }
}
