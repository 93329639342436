<div class="layout">
  <nav
    class="navbar"
    esg-preview-navbar
    [showShareButton]="showShareButton"
    [reportName]="reportState.reportName || ''"
    [organizationName]="reportState.organizationName || ''"
    [organizationLogoSquare]="reportState.organizationLogoSquareUrl || ''"
    [hasContent]="reportState.stickyTabs"
    [showDetails]="reportState.showNavbarDetails"
    [open]="reportState.isMenuOpen"
    [breakpoint]="reportState.currentBreakpoint"
    (onCopyLink)="onShare.emit($event)"
    (onMenuClick)="reportState.onToggleMenu()">
    <div class="navbar-tabs">
      <esg-segmented-tabs
        *ngIf="reportState.stickyTabs"
        class="tabs"
        [activeColor]="reportState.brandColor || ''"
        [tabs]="reportState.mainTopicTabs"></esg-segmented-tabs>
      <esg-topic-tabs
        *ngIf="feedState.topicTabs && feedState.topicTabs.length && reportState.stickyTabs"
        class="topic-tabs"
        [tabs]="feedState.topicTabs"
        (onTabClick)="feedState.onContentLinkClick($event)"></esg-topic-tabs>
    </div>
  </nav>
  <div class="layout-content">
    <div
      class="cover photo"
      [class.mobile]="reportState.isMobile"
      [class.narrow-desktop]="reportState.isNarrowDesktop"
      [class.tablet]="reportState.isTablet"
      [style.background]="coverPhotoBackground">
      <div></div>
      <div class="mid-section" [class.mobile]="reportState.isMobile">
        <span class="organization-name">{{ reportState.organizationName }}</span>
        <span class="report-name">{{ reportState.reportName }}</span>
      </div>
      <div
        esgIntersectionObserver
        [rootMargin]="tabObserverRootMargin"
        (onIntersection)="reportState.handleTabsObservation($event)"
        class="bottom-section"
        [class.tablet]="reportState.isTablet">
        <esg-segmented-tabs
          *ngIf="!reportState.isMobile"
          [class.hidden]="reportState.stickyTabs"
          [activeColor]="reportState.brandColor || ''"
          class="tabs"
          [tabs]="reportState.mainTopicTabs"
          (onTabClick)="handleOnTabClick()"></esg-segmented-tabs>
      </div>
    </div>
    <esg-topic-tabs
      *ngIf="feedState.topicTabs && feedState.topicTabs.length && !reportState.isMobile"
      class="topic-tabs"
      [class.hidden]="reportState.stickyTabs"
      [class.tablet]="reportState.isTablet"
      [tabs]="feedState.topicTabs"
      (onTabClick)="feedState.onContentLinkClick($event)"></esg-topic-tabs>
    <ng-content></ng-content>
  </div>
  <esg-side-dialog
    *ngIf="reportState.isMobile"
    class="side-dialog"
    [open]="reportState.isMenuOpen"
    [fullWidth]="reportState.isMobile"
    [contentStyle]="reportState.isWideDesktop ? { width: '70vw', 'margin-top': '0', 'padding-top': '0' } : {}"
    (onClose)="reportState.onToggleMenu()">
    <esg-mobile-navigation
      [mainLevels]="reportState.mainLevels"
      (onClose)="reportState.onToggleMenu()"></esg-mobile-navigation>
  </esg-side-dialog>
</div>
