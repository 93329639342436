<table class="indicator-table-value" [class.not-visible]="!isVisibleInReport">
  <thead>
    <tr>
      <th></th>
      <th></th>
      <th *ngIf="isColumnVisible(visibleColumnEnum.PreviousValue)" class="number">
        {{ indicatorPeriodLabels.previousLabel }}
      </th>
      <th *ngIf="isColumnVisible(visibleColumnEnum.CurrentValue)" class="number">
        {{ indicatorPeriodLabels.currentLabel }}
      </th>
      <th *ngIf="isColumnVisible(visibleColumnEnum.TargetValue)" class="number">
        {{ indicatorPeriodLabels.targetLabel }}
      </th>
      <th>Unit</th>
      <th></th>
    </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="handleOnContentDrop($event)">
    <ng-container *ngFor="let row of indicatorSet">
      <tr cdkDrag class="indicator-row" [id]="row.indicatorId || ''">
        <td cdkDragHandle>
          <esg-icon-move *ngIf="active && indicatorSet.length > 1"></esg-icon-move>
        </td>
        <td>{{ row.metadata.label }}</td>
        <td *ngIf="isColumnVisible(visibleColumnEnum.PreviousValue)" class="number">
          {{ formatNumberCell(row.previousValue.value?.toString() || '') || '-' }}
        </td>
        <td *ngIf="isColumnVisible(visibleColumnEnum.CurrentValue)" class="number bold">
          {{ formatNumberCell(row.currentValue.value?.toString() || '') || '-' }}
        </td>
        <td *ngIf="isColumnVisible(visibleColumnEnum.TargetValue)" class="number">
          {{ formatNumberCell(row.targetValue.value?.toString() || '') || '-' }}
        </td>
        <td class="italic">{{ row.currentValue.unitDetails || row.metadata.unit | formatUnitWithUnicode }}</td>
        <td (click)="row.substrateData && row.substrateData.tBody.length && $event.stopPropagation()">
          <button
            *ngIf="active"
            class="remove-button"
            [disabled]="indicatorSet.length < 2"
            (click)="handleOnRemoveIndicator(row.indicatorId || '')">
            <esg-delete-icon></esg-delete-icon>
          </button>
          <button
            *ngIf="!active && row.substrateData && row.substrateData.tBody.length"
            [class.expanded]="expandedRowIds.includes(row.indicatorId || '')"
            (click)="handleOnExpandRow(row.indicatorId || '')">
            <esg-icon-expanders></esg-icon-expanders>
          </button>
        </td>
      </tr>
      <tr
        *ngIf="row.substrateData && row.substrateData.tBody.length && expandedRowIds.includes(row.indicatorId || '')"
        class="substrate-data">
        <td [colSpan]="8">
          <esg-indicator-content-substrate-data
            [substrateData]="row.substrateData"></esg-indicator-content-substrate-data>
        </td>
      </tr>
    </ng-container>
    <tr *ngIf="active" class="add-row">
      <td [colSpan]="8">
        <esg-select
          [customDropdown]="true"
          [groupedOptions]="indicatorOptions"
          [isCollapsed]="indicatorSelectCollapsed"
          (onChange)="handleOnAddIndicator($event)"
          (onToggleCollapse)="handleOnIndicatorSelectCollapse($event)">
          <esg-add-button label="Add new indicator" (onClick)="handleOnIndicatorSelectClick($event)"></esg-add-button>
        </esg-select>
      </td>
    </tr>
    <tr *ngIf="!active" class="reference-row">
      <td class="reference" [colSpan]="8">
        <span class="reference-label">Disclosure Reference:&nbsp;</span>
        <span>{{ references }}</span>
      </td>
    </tr>
  </tbody>
</table>
