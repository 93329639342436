import { Component } from '@angular/core';
import { VesselCombustionStateService } from './vessel-combustion-state.service';
import { FuelCombustionComponent } from './fuel-combustion.component';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-vessel-combustion',
  templateUrl: './vessel-combustion.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: VesselCombustionComponent }, VesselCombustionStateService],
})
export class VesselCombustionComponent extends FuelCombustionComponent {
  constructor(public state: VesselCombustionStateService) {
    super(state);
  }
}
