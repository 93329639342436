<table [style.grid-template-columns]="getGridTemplateColumns">
  <thead>
    <tr>
      <th></th>
      <th class="unit">
        <div class="header-content">
          <span class="label unit">Unit</span>
          <span *ngIf="isCustomPeriod" class="description"></span>
        </div>
      </th>
      <th *ngIf="isColumnVisible(visibleColumnEnum.TargetValue)" class="number">
        <div class="header-content">
          <span class="label target">
            {{ isCustomPeriod ? 'target' : indicatorPeriodLabels.targetLabel }}
          </span>
          <span *ngIf="isCustomPeriod" class="description">{{ indicatorPeriodLabels.targetLabel }}</span>
        </div>
      </th>
      <th *ngIf="isColumnVisible(visibleColumnEnum.CurrentValue)" class="number">
        <div class="header-content">
          <span class="label current">
            {{ isCustomPeriod ? 'current' : indicatorPeriodLabels.currentLabel }}
          </span>
          <span *ngIf="isCustomPeriod" class="description">{{ indicatorPeriodLabels.currentLabel }}</span>
        </div>
      </th>
      <th *ngIf="isColumnVisible(visibleColumnEnum.PreviousValue)" class="number">
        <div class="header-content">
          <span class="label">{{ isCustomPeriod ? 'previous' : indicatorPeriodLabels.previousLabel }}</span>
          <span *ngIf="isCustomPeriod" class="description">{{ indicatorPeriodLabels.previousLabel }}</span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let value of keyFigures">
      <tr>
        <td class="name">{{ value.metadata.label || '' }}</td>
        <td class="unit" [innerHTML]="value.currentValue.unitDetails || value.metadata.unit | formatUnitWithHtml"></td>
        <td *ngIf="isColumnVisible(visibleColumnEnum.TargetValue)" class="number">
          {{ getFormattedNumber(value.targetValue.value) }}
        </td>
        <td *ngIf="isColumnVisible(visibleColumnEnum.CurrentValue)" class="number current">
          {{ getFormattedNumber(value.currentValue.value) }}
        </td>
        <td *ngIf="isColumnVisible(visibleColumnEnum.PreviousValue)" class="number">
          {{ getFormattedNumber(value.previousValue.value) }}
        </td>
      </tr>
      <tr *ngIf="value.substrateData && value.substrateData.tBody.length">
        <esg-substrate-data-preview
          [substrateData]="value.substrateData"
          [style.grid-column]="getGridColumnSpan"></esg-substrate-data-preview>
      </tr>
    </ng-container>
    <tr class="reference-row">
      <td class="reference" [style.grid-column]="getGridColumnSpan">
        <span class="reference-label">Disclosure Reference:&nbsp;</span>
        <span>{{ references }}</span>
      </td>
    </tr>
  </tbody>
</table>
