import { Component } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NotificationLinkUi } from 'src/app/shared/ui/notification/notification.component';

@Component({
  selector: 'esg-feedback-examples',
  templateUrl: './feedback-examples.component.html',
  styleUrls: ['./feedback-examples.component.scss'],
})
export class FeedbackExamplesComponent {
  title = 'Message (Title)';
  message = 'Body';
  link: NotificationLinkUi = { text: 'Go to this link', routerLink: ['/example'] };

  longTextTitle = 'The anatomy of notification';
  longTextMessage =
    'Four Elements: Icon, title (text), body (text) and link(text). Icon is fixed. The text column should be max 280px width, and the max total width of notification is 460px. Body and link optional elements in notifications.';

  constructor(private notificationService: NotificationService) {}

  handleSuccess(title: string, message?: string, link?: NotificationLinkUi): void {
    this.notificationService.showSuccess(title, message, link);
  }

  handleInfo(title: string, message?: string, link?: NotificationLinkUi): void {
    this.notificationService.showInfo(title, message, link);
  }

  handleWarning(title: string, message?: string, link?: NotificationLinkUi): void {
    this.notificationService.showWarning(title, message, link);
  }

  handleError(title: string, message?: string, link?: NotificationLinkUi): void {
    this.notificationService.showError(title, message, link);
  }
}
