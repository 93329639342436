import { Component } from '@angular/core';
import { AssetTableComponent } from '../../../shared/asset-table/asset-table.component';
import { IOption } from 'src/app/static-data/options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetUi } from '../../asset-list.component';
import { AssetType, CountryCodes, CreateClientAssetCommandParams } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['../../../shared/asset-table/asset-table.component.scss', './clients-table.component.scss'],
  providers: [{ provide: AssetTableComponent, useExisting: ClientsTableComponent }],
})
export class ClientsTableComponent extends AssetTableComponent {
  headers = ['CLIENT NAME', 'CLIENT’S COUNTRY', 'ORGANIZATION'];

  initateAdd(event?: Event) {
    event?.stopPropagation();
    this.fc = {
      name: new FormControl<string>('', [Validators.required]),
      country: new FormControl<IOption | undefined>(this.defaultCountry),
      organization: new FormControl<IOption | undefined>(
        {
          value: this.defaultOrganization,
          disabled: this.defaultOrganization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  iniateEdit(asset: AssetUi) {
    if (this.fc) return;

    this.onSelectedAssetChange.emit(asset);

    const organization =
      this.organizationOptions.find(org => org.label === asset.organizationName) || this.defaultOrganization;
    this.fc = {
      name: new FormControl<string>(asset.name, [Validators.required]),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(country => country.label === asset.country) || this.defaultCountry,
        [Validators.required]
      ),
      organization: new FormControl<IOption | undefined>(
        {
          value: organization,
          disabled: organization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  handleOnFormSubmit() {
    const countryCode = Object.values(CountryCodes).find(code => code === this.fc?.country?.value?.value);
    if (this.fc && this.formgroup?.valid && countryCode) {
      const subOrganizationId =
        this.organizationId === this.fc.organization.value?.value ? undefined : this.fc.organization.value?.value;
      const params = new CreateClientAssetCommandParams({
        type: AssetType.Client,
        name: this.fc.name.value || '',
        organizationId: this.organizationId || '',
        subOrganizationId: subOrganizationId,
        countryCode: countryCode,
        versionValidFrom: new Date(),
      });
      this.onFormSubmit.emit(params);
      this.resetForm();
    } else this.handleSubmitRejection();
  }
}
