import { Component } from '@angular/core';
import { AssetListComponent } from '../asset-list.component';
import { AssetListStateService } from '../asset-list-state.service';
import { ClientsStateService } from './clients-state.service';

@Component({
  selector: 'esg-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['../asset-list.component.scss'],
  providers: [{ provide: AssetListStateService, useClass: ClientsStateService }],
})
export class ClientsComponent extends AssetListComponent {}
