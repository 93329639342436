<div
  class="container"
  esg-on-click-outside
  (onClickOutside)="handleOnOutsideClick()"
  (click)="handleOnClick()"
  [class.active]="active"
  esgKeyboardShortcuts
  (onEscapeKey)="handleOnCancel($event)"
  (onEnterKey)="!saveDisabled && handleOnSave($event)">
  <div class="form-content"><ng-content></ng-content></div>
  <div *ngIf="active" class="active-overlay">
    <div class="bottom-section">
      <div class="divider"></div>
      <div class="buttons">
        <div>
          <button class="delete" (click)="handleOnDelete($event)" [disabled]="deleteDisabled">
            Delete this version
          </button>
        </div>
        <div>
          <esg-button size="small" class="tertiary" (onClick)="handleOnCancel($event)">Cancel</esg-button>
          <esg-button size="small" (onClick)="handleOnSave($event)" [disabled]="saveDisabled">Save</esg-button>
        </div>
      </div>
    </div>
  </div>
</div>
