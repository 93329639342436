import { Component, Input, forwardRef } from '@angular/core';
import { SelectComponent } from '../../ui/select/select.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'esg-table-select',
  templateUrl: './table-select.component.html',
  styleUrls: ['./table-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TableSelectComponent),
      multi: true,
    },
  ],
})
export class TableSelectComponent extends SelectComponent {
  @Input() placeholder = 'Select';
  @Input() required = true;
}
