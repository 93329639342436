import {
  CalculationType,
  CellValueType,
  ColumnDefinition,
  DynamicRowsDataSource,
  IndicatorTableValueDefinition,
  RowDefinition,
  RowsType,
} from 'src/api-client/report-api.generated';

export const defaultStaticTable = new IndicatorTableValueDefinition({
  tableLabel: '',
  editableSchema: true,
  columns: [
    new ColumnDefinition({
      columnId: 'staticColumn',
      header: 'Fixed Column',
      description: 'Description',
      unit: '',
      type: CellValueType.StaticText,
    }),
    new ColumnDefinition({
      columnId: 'numeric',
      header: 'Number',
      description: 'Number input',
      unit: 'Number',
      calculationType: CalculationType.Sum,
      type: CellValueType.Numeric,
    }),
    new ColumnDefinition({
      columnId: 'currency',
      header: 'Currency',
      description: 'Currency input',
      unit: 'USD',
      calculationType: CalculationType.Average,
      type: CellValueType.Currency,
    }),
  ],
  rows: [
    new RowDefinition({
      rowId: 'static1',
      header: 'Static 1',
      description: '',
    }),
    new RowDefinition({
      rowId: 'static2',
      header: 'Static 2',
      description: '',
    }),
  ],
  rowsType: RowsType.Static,
});

export const defaultDynamicTable = new IndicatorTableValueDefinition({
  tableLabel: 'Default Table',
  editableSchema: true,
  columns: [
    new ColumnDefinition({
      columnId: 'regions',
      header: 'Regions',
      description: 'Description',
      unit: '',
      type: CellValueType.StaticText,
    }),
    new ColumnDefinition({
      columnId: 'numeric',
      header: 'Number',
      description: 'Number input',
      unit: 'Number',
      type: CellValueType.Numeric,
    }),
    new ColumnDefinition({
      columnId: 'currency',
      header: 'Currency',
      description: 'Currency input',
      unit: 'USD',
      type: CellValueType.Currency,
    }),
  ],
  rows: [],
  rowsType: RowsType.Dynamic,
  dynamicRowsDataSource: DynamicRowsDataSource.OrganizationRegionNames,
});
