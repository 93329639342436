import { Component } from '@angular/core';
import { EmployeeCommutingStateService } from './employee-commuting-state.service';

@Component({
  selector: 'esg-employee-commuting',
  templateUrl: './employee-commuting.component.html',
  styleUrls: ['../profiles.component.scss'],
  providers: [EmployeeCommutingStateService],
})
export class EmployeeCommutingComponent {
  constructor(public state: EmployeeCommutingStateService) {}
}
