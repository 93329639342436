import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface IOption {
  value: string;
  label: string;
}

@Component({
  selector: 'esg-table-dropdown',
  templateUrl: './table-dropdown.component.html',
  styleUrls: ['./table-dropdown.component.scss'],
})
export class TableDropdownComponent implements OnInit {
  @Input({ required: true }) name!: string;
  @Input({ required: true }) fc!: FormControl<IOption | null>;
  @Input({ required: true }) options!: IOption[];
  @Input() disabled = false;
  open = false;

  toggleOpenOptions() {
    if (!this.disabled) {
      this.open = !this.open;
    }
  }

  handleSelectOption(option: IOption) {
    this.fc.setValue(option);
    this.open = false;
  }

  closeOptions() {
    this.open = false;
  }

  constructor() {}

  ngOnInit(): void {}
}
