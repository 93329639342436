import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { IntegrationClient } from '../../../api-client/integration-api.generated';

@Injectable({
  providedIn: 'root',
})
export class IntegrationApiService {
  client: IntegrationClient;
  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new IntegrationClient(httpClient, appSettings.ReportApiBaseUrl);
  }
}
