import { Component } from '@angular/core';
import { VehiclesCraftsMachineryStateService } from './vehicles-crafts-machinery-state.service';
import { AssetListComponent } from '../asset-list.component';
import { AssetListStateService } from '../asset-list-state.service';

@Component({
  selector: 'esg-vehicles-crafts-machinery',
  templateUrl: './vehicles-crafts-machinery.component.html',
  styleUrls: ['../asset-list.component.scss'],
  providers: [{ provide: AssetListStateService, useClass: VehiclesCraftsMachineryStateService }],
})
export class VehiclesCraftsMachineryComponent extends AssetListComponent {}
