import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-check-circle-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <circle cx="8" cy="8" r="7" attr.fill="{{ color }}" />
      <path
        d="M7.27046 11C7.4202 11 7.5386 10.9333 7.62217 10.8034L10.9129 5.57929C10.9756 5.47747 11 5.40023 11 5.31949C11 5.12639 10.8746 5 10.6831 5C10.5438 5 10.4672 5.04564 10.3836 5.17905L7.25653 10.203L5.63378 8.06144C5.54672 7.93856 5.45966 7.88941 5.3343 7.88941C5.13581 7.88941 5 8.02633 5 8.21943C5 8.30018 5.03482 8.39146 5.10099 8.47572L6.9083 10.7964C7.01277 10.9333 7.12072 11 7.27046 11Z"
        fill="white" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class CheckCircleIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
