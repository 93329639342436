import { Component } from '@angular/core';
import { LobbyPoliticalContributionStateService } from './lobby-political-contribution-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-lobby-political-contribution',
  templateUrl: './lobby-political-contribution.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [
    { provide: RecordsComponent, useExisting: LobbyPoliticalContributionComponent },
    LobbyPoliticalContributionStateService,
  ],
})
export class LobbyPoliticalContributionComponent extends RecordsComponent {
  constructor(public state: LobbyPoliticalContributionStateService) {
    super(state);
  }
}
