import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportPageComponent } from './report.component';
import { RouterModule } from '@angular/router';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { ReportCardComponent } from './report-card/report-card.component';
import { TemplateCardViewComponent } from './template-form/template-card-view/template-card-view.component';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { ReportFormComponent } from './report-form/report-form.component';
import { TemplateListComponent } from './report-form/template-list/template-list.component';
import { EditFormComponent } from './report-form/edit-form/edit-form.component';
import { TemplateCardComponent } from './report-form/template-card/template-card.component';
import { TemplateFormComponent } from './template-form/template-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { QuillModule } from 'ngx-quill';
import { DirectiveModule } from 'src/app/shared/directives/directive.module';
import { SideNavigationComponent } from 'src/app/app-layout/side-navigation/side-navigation.component';

@NgModule({
  declarations: [
    ReportPageComponent,
    ReportCardComponent,
    TemplateCardViewComponent,
    ReportFormComponent,
    TemplateListComponent,
    EditFormComponent,
    TemplateCardComponent,
    TemplateFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiModule,
    SharedComponentsModule,
    QuillModule,
    DirectiveModule,
    RouterModule.forChild([
      {
        path: 'report',
        component: AppLayoutComponent,
        children: [
          {
            path: ``,
            component: SideNavigationComponent,
            outlet: 'side',
          },
          { path: '', component: ReportPageComponent },
        ],
        canActivate: [MsalGuard],
      },
    ]),
  ],
  exports: [ReportFormComponent, TemplateFormComponent],
})
export class ReportModule {}
