import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-text-icon',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="16" width="1" height="16" transform="rotate(90 16 0)" attr.fill="{{ color }}" />
      <rect x="16" y="3" width="1" height="16" transform="rotate(90 16 3)" attr.fill="{{ color }}" />
      <rect x="13" y="6" width="1" height="13" transform="rotate(90 13 6)" attr.fill="{{ color }}" />
      <rect x="16" y="9" width="1" height="16" transform="rotate(90 16 9)" attr.fill="{{ color }}" />
      <rect x="16" y="12" width="1" height="16" transform="rotate(90 16 12)" attr.fill="{{ color }}" />
      <rect x="9" y="15" width="1" height="9" transform="rotate(90 9 15)" attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class TextIconComponent {
  @Input() size: string = '16';
  @Input() color: string = 'black';

  sizePx = this.size + 'px';
}
