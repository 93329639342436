import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUserDetailsVm, IUserInfoVm, UpdateUserProfileCommandParams } from 'src/api-client/report-api.generated';
import { UserApiService } from 'src/app/api-client/report-api/user-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';

@Injectable()
// @Injectable({
//   providedIn: 'root',
// })
export class ProfileStateService {
  user?: IUserDetailsVm;

  fc = {
    firstName: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    lastName: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    email: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true }
    ),
    organizationName: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true }
    ),
    role: new FormControl<string>(
      { value: '', disabled: true },
      { validators: [Validators.required], nonNullable: true }
    ),
  };

  formgroup = new FormGroup(this.fc);

  constructor(private appInfo: AppInfoService, private userApiService: UserApiService) {
    appInfo.userInfo$.subscribe(userInfo => {
      if (userInfo) {
        this.loadUserDetals(userInfo);
      }
    });
  }

  async loadUserDetals(userInfo: IUserInfoVm) {
    const { result } = await this.userApiService.getUserDetailsById(userInfo?.id);

    if (!result) {
      throw new Error(`User data empty for id: ${userInfo?.id}`);
    }

    this.user = result;

    this.fc = {
      firstName: new FormControl<string>(this.user.firstName, { validators: [Validators.required], nonNullable: true }),
      lastName: new FormControl<string>(this.user.lastName, { validators: [Validators.required], nonNullable: true }),
      email: new FormControl<string>(
        { value: this.user.email, disabled: true },
        { validators: [Validators.required], nonNullable: true }
      ),
      organizationName: new FormControl<string>(
        { value: this.user.organizationName, disabled: true },
        { validators: [Validators.required], nonNullable: true }
      ),
      role: new FormControl<string>(
        { value: this.user.role, disabled: true },
        { validators: [Validators.required], nonNullable: true }
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  async handleSubmit() {
    const result = await this.userApiService.updateUserProfile(
      new UpdateUserProfileCommandParams({
        firstName: this.fc.firstName.value || '',
        lastName: this.fc.lastName.value || '',
      })
    );
    await this.appInfo.loadUserInfo();
    this.formgroup.markAsPristine();
  }
}
