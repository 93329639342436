import { Component } from '@angular/core';
import { ParentalLeaveStateService } from './parental-leave-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-parental-leave',
  templateUrl: './parental-leave.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: ParentalLeaveComponent }, ParentalLeaveStateService],
})
export class ParentalLeaveComponent extends RecordsComponent {
  constructor(public state: ParentalLeaveStateService) {
    super(state);
  }
}
