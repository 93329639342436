import { Injectable } from '@angular/core';
import { SupportTopicItemApiService } from 'src/app/api-client/report-api/support-topic-item-api-service';
import {
  ComponentType2,
  CreateSupportComponentCommandParams,
  DeleteSupportComponentCommandParams,
  MoveSupportComponentCommandParams,
  UpdateSupportComponentCommandParams,
} from 'src/api-client/report-api.generated';
import { SupportArticleDetailsUi } from './support-article-details.component';
import { DynamicComponentFactory, DynamicContent } from 'src/app/content/dynamic-content';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentDataTypes, ContentStatesTypes, ContentTypes } from 'src/app/content/content-configuration';
import { IMovedContent } from 'src/app/content/dynamic-content-data';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { IUploadedPhoto } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { IPictureContentData } from 'src/app/content/content-item/picture/picture.component';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class SupportArticleStateService {
  article?: SupportArticleDetailsUi;
  contentFade: boolean = false;

  constructor(
    private supportTopicItemApiService: SupportTopicItemApiService,
    private appApiService: ReportAppApiService,
    private domSanitizer: DomSanitizer
  ) {}

  async init(articleId: string) {
    const response = await this.supportTopicItemApiService.getTopicItem(articleId);
    this.article = {
      id: response.id,
      name: response.name,
      components: response.components.map(c => {
        const dynamicComponent = DynamicComponentFactory({
          uploadPhoto: this.handleUploadPhoto,
          getUploadedPhoto: this.getUploadPhoto,
        })[c.type];
        return dynamicComponent(c.id, c.content);
      }),
    };
  }

  handleOnAddComponent(type: ContentTypes) {
    switch (type) {
      case ContentTypes.Text:
        this.addTextComponent();
        break;
      case ContentTypes.Picture:
        this.addImageComponent();
        break;
      case ContentTypes.ImportFromExcel:
        this.addImportFromExcelComponent();
        break;
      default:
        break;
    }
  }

  addTextComponent() {
    this.supportTopicItemApiService
      .createComponent(
        new CreateSupportComponentCommandParams({
          supportTopicItemId: this.article?.id || '',
          type: ComponentType2.Text,
          title: '',
        })
      )
      .then(result => {
        if (this.article)
          this.article.components = [
            ...this.article.components,
            new DynamicContent(result.result?.id || '', ContentTypes.Text, {}, {}, undefined),
          ];
      });
  }

  addImageComponent() {
    this.supportTopicItemApiService
      .createComponent(
        new CreateSupportComponentCommandParams({
          supportTopicItemId: this.article?.id || '',
          type: ComponentType2.Picture,
          title: '',
        })
      )
      .then(result => {
        if (this.article)
          this.article.components = [
            ...this.article.components,
            new DynamicContent(
              result.result?.id || '',
              ContentTypes.Picture,
              {
                uploadPhoto: this.handleUploadPhoto(result.result?.id || ''),
              },
              {},
              undefined
            ),
          ];
      });
  }

  addImportFromExcelComponent() {
    this.supportTopicItemApiService
      .createComponent(
        new CreateSupportComponentCommandParams({
          supportTopicItemId: this.article?.id || '',
          type: ComponentType2.ImportFromExcel,
          title: '',
        })
      )
      .then(result => {
        if (this.article)
          this.article.components = [
            ...this.article.components,
            new DynamicContent(result.result?.id || '', ContentTypes.ImportFromExcel, {}, {}, undefined),
          ];
      });
  }

  moveComponent(movedContent: IMovedContent) {
    if (this.article) {
      const newList = [...this.article.components];
      const prevList = [...this.article.components];
      moveItemInArray(newList, movedContent.prevIndex, movedContent.newIndex);
      this.article.components = [...newList];
      this.supportTopicItemApiService
        .moveComponent(
          new MoveSupportComponentCommandParams({
            supportTopicItemId: this.article.id,
            componentId: movedContent.contentId,
            prevIndex: movedContent.prevIndex,
            newIndex: movedContent.newIndex,
          })
        )
        .then(
          result => {},
          error => {
            if (this.article) this.article.components = [...prevList];
          }
        );
    }
  }

  deleteComponent(contentId: string) {
    this.supportTopicItemApiService
      .deleteComponent(
        new DeleteSupportComponentCommandParams({ supportTopicItemId: this.article?.id || '', componentId: contentId })
      )
      .then(result => {
        if (result.success && this.article) {
          const newList = [...this.article.components];
          newList.splice(
            this.article.components.findIndex(comp => comp.contentId === contentId),
            1
          );
          this.article.components = [...newList];
        }
      });
  }

  onDataChangeComponent = (contentId: string, states: ContentStatesTypes, data?: ContentDataTypes) => {
    const component = this.article?.components.find(c => c.contentId === contentId);

    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exists`);
    }

    var updatedComponent: DynamicContent = {
      ...component,
      states: states,
      data,
    };

    if (this.article) {
      this.article.components = this.article.components.map(c =>
        c.contentId !== component.contentId ? c : updatedComponent
      );

      this.supportTopicItemApiService
        .updateComponent(
          new UpdateSupportComponentCommandParams({
            componentId: component.contentId,
            supportTopicItemId: this.article.id || '',
            title: '',
            content: data ? JSON.stringify(data) : '',
          })
        )
        .then(result => {
          console.log('updated successfully!');
        });
    }
  };

  async uploadPhoto(file: File) {
    const photo$ = this.appApiService.uploadImage({
      data: file,
      fileName: file.name,
    });
    return await lastValueFrom(photo$);
  }

  getUploadPhoto = (blobName: string): IUploadedPhoto => {
    var blobUrl = this.appApiService.getBlobUrl(blobName);
    var safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    return {
      url: safeUrl,
      fileName: blobName,
    };
  };

  handleUploadPhoto = (componentId: string) => async (file?: File) => {
    const component = this.article?.components.find(c => c.contentId === componentId);

    if (!component) {
      throw Error(`Comonent with id: ${componentId} doesn't exists`);
    }

    if (file) {
      const result = await this.uploadPhoto(file);
      const uploadedPhoto = this.getUploadPhoto(result.blobName || '');

      const updatedContent: IPictureContentData = {
        altText: '',
        blobName: result.blobName || '',
      };

      this.onDataChangeComponent(
        componentId,
        {
          ...component?.states,
          uploadedPhoto: uploadedPhoto,
        },
        updatedContent
      );
    } else {
      this.onDataChangeComponent(
        componentId,
        {
          ...component?.states,
          uploadedPhoto: undefined,
        },
        undefined
      );
    }
  };

  onReorder(reordering: boolean) {
    this.contentFade = reordering;
  }
}
