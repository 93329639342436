import { Component } from '@angular/core';
import { AssetTableComponent } from '../../../shared/asset-table/asset-table.component';
import { IOption, powerSourceOptions, vehicleCraftMachineryTypeOptions } from 'src/app/static-data/options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetUi } from '../../asset-list.component';
import {
  AssetType,
  CreateVehicleCraftMachineryAssetCommandParams,
  PowerSource,
  VehicleCraftMachineryType,
} from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-vehicles-crafts-machinery-table',
  templateUrl: './vehicles-crafts-machinery-table.component.html',
  styleUrls: [
    '../../../shared/asset-table/asset-table.component.scss',
    './vehicles-crafts-machinery-table.component.scss',
  ],
  providers: [{ provide: AssetTableComponent, useExisting: VehiclesCraftsMachineryTableComponent }],
})
export class VehiclesCraftsMachineryTableComponent extends AssetTableComponent {
  headers = ['ASSET', 'ASSET TYPE', 'POWER SOURCE', 'ORGANIZATION'];
  typeOptions: IOption[] = vehicleCraftMachineryTypeOptions;
  subTypeOptions: IOption[] = powerSourceOptions;

  initateAdd(event?: Event) {
    event?.stopPropagation();
    this.fc = {
      name: new FormControl<string>('', [Validators.required]),
      type: new FormControl<IOption | undefined>(this.typeOptions[0], [Validators.required]),
      subType: new FormControl<IOption | undefined>(this.subTypeOptions[0], [Validators.required]),
      organization: new FormControl<IOption | undefined>(
        {
          value: this.defaultOrganization,
          disabled: this.defaultOrganization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  iniateEdit(asset: AssetUi) {
    if (this.fc) return;

    this.onSelectedAssetChange.emit(asset);

    const organization =
      this.organizationOptions.find(org => org.label === asset.organizationName) || this.defaultOrganization;
    this.fc = {
      name: new FormControl<string>(asset.name, [Validators.required]),
      type: new FormControl<IOption | undefined>(
        this.typeOptions.find(type => type.label === asset.type || type.value === asset.type) || this.typeOptions[0],
        [Validators.required]
      ),
      subType: new FormControl<IOption | undefined>(
        this.subTypeOptions.find(subType => subType.label === asset.subType || subType.value === asset.subType) ||
          this.subTypeOptions[0],
        [Validators.required]
      ),
      organization: new FormControl<IOption | undefined>(
        {
          value: organization,
          disabled: organization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  handleOnFormSubmit() {
    const type = Object.values(VehicleCraftMachineryType).find(type => type === this.fc?.type?.value?.value);
    const subType = Object.values(PowerSource).find(type => type === this.fc?.subType?.value?.value);
    if (this.fc && this.formgroup?.valid && type && subType) {
      const subOrganizationId =
        this.organizationId === this.fc.organization.value?.value ? undefined : this.fc.organization.value?.value;
      const params = new CreateVehicleCraftMachineryAssetCommandParams({
        type: AssetType.VehicleCraftMachinery,
        subType: type,
        name: this.fc.name.value || '',
        powerSource: subType,
        organizationId: this.organizationId || '',
        subOrganizationId: subOrganizationId,
        versionValidFrom: new Date(),
      });

      this.onFormSubmit.emit(params);
      this.resetForm();
    } else this.handleSubmitRejection();
  }
}
