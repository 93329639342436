import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-save-template',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (mouseenter)="toggleHover()"
      (mouseleave)="toggleHover()">
      <path
        d="M6.47472 9.16292C6.60292 9.16292 6.7067 9.12018 6.82879 8.99809L8.89212 7.00801C8.98369 6.91644 9.03863 6.81877 9.03863 6.68447C9.03863 6.44029 8.84329 6.25715 8.593 6.25715C8.47091 6.25715 8.34882 6.30599 8.26335 6.40366L7.33546 7.3926L6.92646 7.81992L6.96309 6.91034V0.47005C6.96309 0.213659 6.73722 0 6.47472 0C6.21223 0 5.99247 0.213659 5.99247 0.47005V6.91034L6.02909 7.82602L5.61398 7.3926L4.6922 6.40366C4.60674 6.30599 4.47244 6.25715 4.35645 6.25715C4.10006 6.25715 3.91692 6.44029 3.91692 6.68447C3.91692 6.81877 3.96576 6.91644 4.05733 7.00801L6.12066 8.99809C6.24886 9.12018 6.35263 9.16292 6.47472 9.16292ZM2.97682 13.1736H4.62505V14.1076C4.62505 15.3651 5.26603 16 6.54187 16H13.0249C14.3007 16 14.9417 15.3712 14.9417 14.1076V8.02137C14.9417 6.75773 14.3007 6.12896 13.0249 6.12896H11.8956V5.19496C11.8956 3.93743 11.2546 3.30256 9.97873 3.30256H8.27556V4.28539H9.96042C10.5648 4.28539 10.9127 4.60893 10.9127 5.2438V11.2324C10.9127 11.8672 10.5648 12.1908 9.96042 12.1908H2.98903C2.37858 12.1908 2.04283 11.8672 2.04283 11.2324V5.2438C2.04283 4.60893 2.37858 4.28539 2.98903 4.28539H4.67999V3.30256H2.97682C1.70097 3.30256 1.06 3.93132 1.06 5.19496V11.2812C1.06 12.5448 1.70097 13.1736 2.97682 13.1736ZM6.56019 15.0172C5.95584 15.0172 5.60788 14.6936 5.60788 14.0588V13.1736H9.97873C11.2546 13.1736 11.8956 12.5387 11.8956 11.2812V7.11179H13.0127C13.6231 7.11179 13.9589 7.43533 13.9589 8.0702V14.0588C13.9589 14.6936 13.6231 15.0172 13.0127 15.0172H6.56019Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
})
export class SaveTemplateComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';
  @Input() hoverColor: string = '#0071E3';

  currentColor: string = this.color;
  hovered: boolean = false;

  toggleHover() {
    this.hovered = !this.hovered;
    this.currentColor = this.hovered ? this.hoverColor : this.color;
  }
}
