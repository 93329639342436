<esg-data-import-from-excel
  *ngIf="dialogOpen"
  [importingInProgress]="inProgress"
  [importingInProgressLabel]="inProgressLabel"
  [fieldConfigs]="filedConfigs"
  (importData)="onImportData.emit($event)"
  (onClose)="onClose.emit()"></esg-data-import-from-excel>
<div class="header-toolbar">
  <button [class.disabled]="!enabled" (click)="enabled && onImportDataOpen.emit()">
    <esg-icon-excel></esg-icon-excel>
    <span>Import Excel</span>
  </button>
  <button [class.disabled]="!enabled" (click)="enabled && handleOnGetExcelTemplate()">
    <esg-icon-download></esg-icon-download>
    <span>Download Excel Template</span>
  </button>
</div>
