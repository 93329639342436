<table>
  <thead>
    <tr *ngFor="let row of substrateData.tHead">
      <ng-container *ngFor="let cell of row.items">
        <th [attr.rowspan]="cell.rowSpan" [attr.colspan]="cell.colSpan" [class.number]="cell.isNumber">
          {{ cell.content }}
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of substrateData.tBody">
      <ng-container *ngFor="let cell of row.items">
        <td [attr.rowspan]="cell.rowSpan" [attr.colspan]="cell.colSpan" [class.number]="cell.isNumber">
          {{ cell.isNumber ? getFormattedNumbers(cell.content || '', 0, 2) : cell.content || '' }}
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
