<span class="title">{{ tableValue.metadata.label || '' }}</span>
<div class="table-value-container">
  @if (labelTable && currentTable && previousTable) {
  <table class="table-value">
    <thead>
      @for (row of labelTable.tHead; track row; let index = $index) {
      <tr>
        @for (cell of row.items; track cell; let last = $last) {
        <th
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [class.right-border]="last">
          {{ cell.content }}
        </th>
        } @if(isColumnVisible(visibleColumnEnum.CurrentValue) && index < currentTable.tHead.length) { @for (cell of
        currentTable.tHead[index].items; track cell; let last=$last) {
        <th
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [class.right-border]="last">
          {{ cell.content }}
        </th>
        } } @if(isColumnVisible(visibleColumnEnum.PreviousValue) && index < previousTable.tHead.length) { @for (cell of
        previousTable.tHead[index].items; track cell; let last=$last) {
        <th
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [class.right-border]="last">
          {{ cell.content }}
        </th>
        } }
      </tr>
      }
    </thead>
    <tbody>
      @for (row of labelTable.tBody; track row; let index = $index) {
      <tr>
        @for (cell of row.items; track cell; let first = $first; let last = $last) { @if (first) {
        <th
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [class.right-border]="last">
          {{ cell.content }}
        </th>
        } @else {
        <td [attr.rowspan]="cell.rowSpan" [attr.colspan]="cell.colSpan" [class.number]="cell.isNumber">
          {{ cell.isNumber ? getFormattedNumbers(cell.content || '', 0, 0) : cell.content || '' }}
          {{ cell.unit || '' }}
        </td>
        } } @if(isColumnVisible(visibleColumnEnum.CurrentValue)) {@if(index < currentTable.tBody.length) {@for (cell of
        currentTable.tBody[index].items; track cell; let last = $last) {
        <td
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [class.right-border]="last">
          {{ formatCellValue(cell) }}
        </td>
        } } @else if(index < previousTable.tBody.length) { @for (cell of previousTable.tBody[index].items; track cell;
        let last = $last) {
        <td
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [class.right-border]="last">
          -
        </td>
        } } } @if(isColumnVisible(visibleColumnEnum.PreviousValue)){ @if(index < previousTable.tBody.length) { @for
        (cell of previousTable.tBody[index].items; track cell; let last = $last) {
        <td
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [class.right-border]="last">
          {{ formatCellValue(cell) }}
        </td>
        }} @else if(index < currentTable.tBody.length) { @for (cell of currentTable.tBody[index].items; track cell; let
        last = $last) {
        <td
          [attr.rowspan]="cell.rowSpan"
          [attr.colspan]="cell.colSpan"
          [class.number]="cell.isNumber"
          [class.right-border]="last">
          -
        </td>
        } } }
      </tr>
      }
    </tbody>
  </table>
  }
</div>
@if (references) {
<div class="reference">
  <span class="reference-label">Disclosure Reference:&nbsp;</span>
  <span>{{ references }}</span>
</div>
}
