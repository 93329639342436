import { Component } from '@angular/core';
import { FinesStateService } from './fines-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-fines',
  templateUrl: './fines.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: FinesComponent }, FinesStateService],
})
export class FinesComponent extends RecordsComponent {
  constructor(public state: FinesStateService) {
    super(state);
  }
}
