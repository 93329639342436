import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FieldConfig,
  ImportDataOutput,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import { generateExcelFile } from 'src/app/shared/components/data-import-from-excel/excel-exporter';

@Component({
  selector: 'esg-import-from-excel-toolbar [onImportDataOpen] [onImportData] [onClose]',
  templateUrl: './import-from-excel-toolbar.component.html',
  styleUrls: ['./import-from-excel-toolbar.component.scss'],
})
export class ImportFromExcelToolbarComponent {
  @Input() title?: string;
  @Input({ required: true }) dialogOpen!: boolean;
  @Input({ required: true }) filedConfigs!: FieldConfig[];

  @Input({ required: true }) inProgress!: boolean;
  @Input({ required: true }) inProgressLabel!: string;

  @Input({ required: true }) enabled!: boolean;
  @Output() onImportDataOpen = new EventEmitter();
  @Output() onImportData = new EventEmitter<ImportDataOutput>();
  @Output() onClose = new EventEmitter();

  handleOnGetExcelTemplate() {
    generateExcelFile(this.title || 'Excel template', this.filedConfigs);
  }
}
