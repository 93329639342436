<div class="top-section">
  <div class="greetings">
    <h1>{{ greetings }}{{ name }}</h1>
    <span>What do you want to do today?</span>
  </div>
  <div class="links">
    <a class="card-link" [routerLink]="['/indicators']">
      <img src="/assets/images/home/Ill-performance.svg" alt="A map of the world and a man looking at a graph" />
      <span class="description">Manage KPIs</span>
      <span class="label">Indicators</span>
    </a>
    <a class="card-link" [routerLink]="['/data-collect']">
      <img src="/assets/images/home/Ill-data.svg" alt="A woman collecting and connecting data points" />
      <span class="description">Add ESG data</span>
      <span class="label">Data Records</span>
    </a>
    <a class="card-link" [routerLink]="['/report']">
      <img src="/assets/images/home/Ill-reports.svg" alt="Two people structuring a report together" />
      <span class="description">Work on ESG reports</span>
      <span class="label">Report</span>
    </a>
  </div>
</div>
<div class="assigned-tasks-wrapper">
  <div class="assigned-tasks">
    <span class="label">Tasks assigned to you</span>
    <esg-assigned-tasks
      *ngIf="state.tasks.length && !state.loading"
      [tasks]="state.tasks"
      (onTaskClick)="handleTaskClick($event)"></esg-assigned-tasks>
    <div *ngIf="!state.tasks.length && !state.loading" class="no-assigned">
      <span>You have no task assigned to you.</span>
      <span>
        Please go to
        <a [routerLink]="['/report']">Reports</a>
        to assign tasks.
      </span>
    </div>
    <esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading tasks"></esg-dot-flashing>
  </div>
</div>
<!--
<div class="content-wrapper"><esg-home-content class="content"></esg-home-content></div>
-->
