import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'esg-table-delete-button [onClick]',
  templateUrl: './table-delete-button.component.html',
  styleUrls: ['./table-delete-button.component.scss'],
})
export class TableDeleteButtonComponent implements OnInit {
  @Input({ required: true }) label!: string;
  @Input() disabled: boolean = false;
  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleClick(event: Event) {
    event.stopPropagation();
    this.onClick.emit();
  }
}
