import { Component } from '@angular/core';
import { UsersStateService, IUser } from './users-state.service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';

@Component({
  selector: 'esg-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [UsersStateService],
})
export class UsersComponent {
  showUserForm: boolean = false;
  showUserDetails: boolean = false;
  showRemoveUser: boolean = false;

  removeUserMessage: string = 'Once you delete this user\nthere is no turning back.';
  removeAlertTitle: string = 'Can’t delete user';

  constructor(public state: UsersStateService) {}

  handleSelectUser(userId: string) {
    this.showUserDetails = true;
    this.state.setUserDetails(userId);
  }

  handleFormDialogClose() {
    this.showUserForm = false;
  }

  handleDialogClose() {
    this.showUserForm = false;
    this.showRemoveUser = false;
    if (this.state.selectedUser) this.showUserDetails = true;
  }

  handleDetailsDialogClose() {
    this.showUserDetails = false;
    this.state.selectedUser = undefined;
  }

  async handleAddUser() {
    this.showUserForm = true;
  }

  handleEditUser() {
    this.showUserDetails = false;
    this.showUserForm = true;
  }

  handleSubmitAddUser(data: IUser) {
    this.state.addUser(data);
    this.showUserForm = false;
  }

  handleSubmitEditUser(editUserData: IUser) {
    this.state.editUser(editUserData);
    this.showUserForm = false;
    this.showUserDetails = true;
  }

  handleDeleteUser() {
    this.showRemoveUser = true;
  }

  handleDeleteUserSubmit(userId: string | undefined) {
    if (userId) {
      this.state.deleteUser(userId);
      this.state.selectedUser = undefined;
      this.showUserDetails = false;
      this.showRemoveUser = false;
    }
  }

  userMenuProvider = (): IMenuItem[] => [
    { id: 'edit', label: 'Edit', onClick: () => this.handleEditUser(), disabled: !this.state.hasEditPermission },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => this.handleDeleteUser(),
      disabled: !this.state.hasDeletePermission,
    },
  ];
}
