import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  DataRecordClient,
  DataRecordType,
  GetDataRecordsByAssetQueryResponse,
  CreateDataRecordCommandParams,
  CreateDataRecordCommandResponse,
  UpdateDataRecordCommandParams,
  UpdateDataRecordCommandResponse,
  DeleteDataRecordCommandResponse,
  GetDataRecordsByTypeQueryResponse,
  DataRecordCategory,
  GetDataRecordsByCategoryQueryResponse,
  GetDataRecordsByDataProfileQueryResponse,
  GetDataRecordsByQueryResponse,
  GetAssetDataRecordsOverviewQueryResponse,
  AssetType,
  GetDataRecordsCountByQueryResponse,
  InputType,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class DataRecordApiService {
  client: DataRecordClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new DataRecordClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createDataRecord(body: CreateDataRecordCommandParams): Promise<CreateDataRecordCommandResponse> {
    const observable$ = this.client.createDataRecord(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async updateDataRecord(body: UpdateDataRecordCommandParams): Promise<UpdateDataRecordCommandResponse> {
    const observable$ = this.client.updateDataRecord(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async deleteDataRecord(recordId: string): Promise<DeleteDataRecordCommandResponse> {
    const observable$ = this.client.deleteDataRecord(recordId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async deleteDataRecordsBy(
    inputType: InputType | undefined,
    type: DataRecordType | undefined,
    category: DataRecordCategory | undefined,
    dataProfileId: string | undefined,
    assetIds: string[] | undefined,
    assetTypes: AssetType[] | undefined,
    orderBy: string | undefined,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ) {
    const observable$ = this.client
      .deleteDataRecordsBy(inputType, type, category, dataProfileId, assetIds, assetTypes, orderBy, dateFrom, dateTo)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return await lastValueFrom(observable$);
  }

  async getDataRecordsByAssetAndType(
    assetId: string,
    dataRecordType: DataRecordType
  ): Promise<GetDataRecordsByAssetQueryResponse> {
    const observable$ = this.client.getDataRecordsByAssetAndType(assetId, dataRecordType).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getDataRecordsByType(dataRecordType: DataRecordType): Promise<GetDataRecordsByTypeQueryResponse> {
    const observable$ = this.client.getDataRecordsByType(dataRecordType).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getDataRecordsByCategory(
    dataRecordCategory: DataRecordCategory
  ): Promise<GetDataRecordsByCategoryQueryResponse> {
    const observable$ = this.client.getDataRecordsByCategory(dataRecordCategory).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getDataRecordsByProfile(dataProfileId: string): Promise<GetDataRecordsByDataProfileQueryResponse> {
    const observable$ = this.client.getDataRecordsByDataProfile(dataProfileId).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getAssetRecordsOverview(assetId: string): Promise<GetAssetDataRecordsOverviewQueryResponse> {
    const observable$ = this.client.getAssetRecordsOverview(assetId).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getDataRecordsByFilter(
    inputType: InputType | undefined,
    type: DataRecordType | undefined,
    category: DataRecordCategory | undefined,
    dataProfileId: string | undefined,
    assetIds: string[] | undefined,
    assetTypes: AssetType[] | undefined,
    orderBy: string | undefined,
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
    pageNumber: number | undefined,
    pageSize: number | undefined
  ): Promise<GetDataRecordsByQueryResponse> {
    const observable$ = this.client
      .getBy(
        inputType,
        type,
        category,
        dataProfileId,
        assetIds,
        assetTypes,
        orderBy,
        dateFrom,
        dateTo,
        pageNumber,
        pageSize
      )
      .pipe(
        catchError(error => {
          console.log(error.errors);
          return throwError(() => error);
        })
      );
    return await lastValueFrom(observable$);
  }

  async getDataRecordsCountByFilter(
    inputType: InputType | undefined,
    type: DataRecordType | undefined,
    category: DataRecordCategory | undefined,
    dataProfileId: string | undefined,
    assetIds: string[] | undefined,
    assetTypes: AssetType[] | undefined,
    dateFrom: Date | undefined,
    dateTo: Date | undefined
  ): Promise<GetDataRecordsCountByQueryResponse> {
    const observable$ = this.client.countBy(
      inputType,
      type,
      category,
      dataProfileId,
      assetIds,
      assetTypes,
      dateFrom,
      dateTo
    );
    return await lastValueFrom(observable$);
  }
}
