<span *ngIf="showYearStartMonth" class="start-month-label">ACCOUNTING year starts in</span>
<div class="options" [class.has-custom]="!hidePeriodTypes.includes(timePeriodEnum.Custom)">
  <div class="radio-button" (click)="selectTimePeriod(timePeriodEnum.Annual)">
    <esg-radio-button [checked]="timePeriodType === timePeriodEnum.Annual" [disabled]="disabled"></esg-radio-button>
    <span>Annual</span>
  </div>
  <esg-select
    [disabled]="timePeriodType !== timePeriodEnum.Annual || disabled"
    [value]="annualYearValue"
    [options]="yearOptions"
    [isPopup]="true"
    (onChange)="handleAnnualTimePeriodChange($event, annualYearStartMonthValue)"></esg-select>
  <esg-select
    *ngIf="showYearStartMonth"
    [disabled]="timePeriodType !== timePeriodEnum.Annual || disabled"
    [value]="annualYearStartMonthValue"
    [options]="monthOptions"
    [isPopup]="true"
    (onChange)="handleAnnualTimePeriodChange(annualYearValue, $event)"></esg-select>

  <div style="grid-column: 1" (click)="selectTimePeriod(timePeriodEnum.Quarterly)" class="radio-button">
    <esg-radio-button [checked]="timePeriodType === timePeriodEnum.Quarterly" [disabled]="disabled"></esg-radio-button>
    <span>Quarterly</span>
  </div>
  <esg-select
    [disabled]="timePeriodType !== timePeriodEnum.Quarterly || disabled"
    [value]="quarterlyValue"
    [options]="quarterOptions"
    [isPopup]="true"
    (onChange)="handleQuarterlyTimePeriodChange(quarterlyYearValue, $event)"></esg-select>
  <esg-select
    [disabled]="timePeriodType !== timePeriodEnum.Quarterly || disabled"
    [value]="quarterlyYearValue"
    [options]="yearOptions"
    [isPopup]="true"
    (onChange)="handleQuarterlyTimePeriodChange($event, quarterlyValue)"></esg-select>
  <ng-container *ngIf="!hidePeriodTypes.includes(timePeriodEnum.Monthly)">
    <div style="grid-column: 1" class="radio-button" (click)="selectTimePeriod(timePeriodEnum.Monthly)">
      <esg-radio-button [checked]="timePeriodType === timePeriodEnum.Monthly" [disabled]="disabled"></esg-radio-button>
      <span>Monthly</span>
    </div>
    <esg-select
      [disabled]="timePeriodType !== timePeriodEnum.Monthly || disabled"
      [value]="monthlyValue"
      [options]="monthOptions"
      [isPopup]="true"
      (onChange)="handleMonthTimePeriodChange(monthlyYearValue, $event)"></esg-select>
    <esg-select
      [disabled]="timePeriodType !== timePeriodEnum.Monthly || disabled"
      [value]="monthlyYearValue"
      [options]="yearOptions"
      [isPopup]="true"
      (onChange)="handleMonthTimePeriodChange($event, monthlyValue)"></esg-select>
  </ng-container>
  <ng-container *ngIf="!hidePeriodTypes.includes(timePeriodEnum.Custom)">
    <div style="grid-column: 1" class="radio-button custom" (click)="selectTimePeriod(timePeriodEnum.Custom)">
      <esg-radio-button [checked]="timePeriodType === timePeriodEnum.Custom" [disabled]="disabled"></esg-radio-button>
      <span>Custom</span>
    </div>
    <esg-datepicker
      class="custom"
      [fc]="customStartDate"
      variant="standard"
      [maxDate]="customEndDate.value || undefined"
      placeholder="From"
      (onChange)="updateTimePeriod()"></esg-datepicker>
    <esg-datepicker
      class="custom"
      [fc]="customEndDate"
      variant="standard"
      [minDate]="customStartDate.value || undefined"
      placeholder="To"
      (onChange)="updateTimePeriod()"></esg-datepicker>
  </ng-container>
</div>
