<span class="header">{{ list.label }}</span>

<ng-container *ngFor="let item of list.items">
  <a
    *ngIf="item.link"
    class="item"
    [class.disabled]="item.disabled"
    [routerLink]="item.link"
    routerLinkActive="active"
    [fragment]="item.fragment"
    [routerLinkActiveOptions]="item.fragment ? fragmentActiveOptions : { exact: true }">
    {{ item.label ? item.label : item.value }}
    <button class="item-button" (click)="handleOnItemClick($event, item.value)"></button>
  </a>

  <div
    *ngIf="!item.link"
    class="item"
    [class.disabled]="item.disabled"
    [class.active]="item.value === activeItem"
    (click)="handleOnItemClick($event, item.value)">
    {{ item.label ? item.label : item.value }}
  </div>
</ng-container>
