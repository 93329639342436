import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'esg-template-card-view',
  templateUrl: './template-card-view.component.html',
  styleUrls: ['./template-card-view.component.scss'],
})
export class TemplateCardViewComponent implements OnInit {
  @Input() backgroundPictureUrl?: string;
  @Input({ required: true }) title!: string;
  @Input({ required: true }) shortDescription!: string;
  @Input() lastModifiedData?: Date;
  @Output() onClick = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  ngOnInit(): void {}

  handleRemove(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.onRemove.emit();
  }
}
