import { ChangeDetectorRef, Component, EventEmitter, Output, SimpleChanges, ViewChild } from '@angular/core';
import { RecordFormDialogStateService } from './record-form-dialog-state.service';
import { RecordsComponent } from '../records/records.component';
import { CreateDataRecordCommandParams } from 'src/api-client/report-api.generated';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'esg-record-form-dialog',
  templateUrl: './record-form-dialog.component.html',
  styleUrls: ['./record-form-dialog.component.scss'],
  providers: [RecordFormDialogStateService],
})
export class RecordFormDialogComponent {
  @Output() onCancel = new EventEmitter();

  formGroup?: FormGroup;

  @ViewChild(RecordsComponent)
  private recordsComponent?: RecordsComponent;

  constructor(private cdr: ChangeDetectorRef, public state: RecordFormDialogStateService) {}

  ngAfterViewChecked() {
    this.updateFormGroup();
  }

  private updateFormGroup() {
    if (this.formGroup !== this.recordsComponent?.formGroup) {
      this.formGroup = this.recordsComponent?.formGroup;
      this.cdr.detectChanges();
    }
  }

  handleCancel() {
    this.onCancel.emit();
  }

  handleOnSubmitClick() {
    if (this.recordsComponent) {
      this.recordsComponent.handleOnSubmit();
    }
  }

  async handleOnFormSubmit(params: CreateDataRecordCommandParams) {
    await this.state.submitRecordForm(params);
    this.handleCancel();
  }
}
