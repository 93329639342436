<div class="input-row">
  <div class="radio-button" (click)="handleSelectTimeperiodType(timePeriodEnum.Annual)">
    <esg-radio-button [checked]="formGroup.controls.periodType.value === timePeriodEnum.Annual"></esg-radio-button>
    <span class="radio-label">Annual</span>
  </div>
  <esg-textbox-form
    [fc]="formGroup.controls.annualYear"
    size="small"
    type="digit"
    [maxLength]="4"
    placeholder="YYYY"
    [inputStyle]="{ 'font-size': '14px' }"
    (onEnter)="this.handleOnChange()"></esg-textbox-form>
</div>
<div class="input-row">
  <div class="radio-button" (click)="handleSelectTimeperiodType(timePeriodEnum.Quarterly)">
    <esg-radio-button [checked]="formGroup.controls.periodType.value === timePeriodEnum.Quarterly"></esg-radio-button>
    <span class="radio-label">Quarter</span>
  </div>
  <esg-select
    [value]="formGroup.controls.quarter.value"
    [disabled]="formGroup.controls.quarter.disabled"
    size="small"
    [options]="quarterOptions"
    (onChange)="handlePeriodChange(formGroup.controls.quarter, $event)"></esg-select>
  <esg-textbox-form
    [fc]="formGroup.controls.quarterYear"
    type="digit"
    size="small"
    [maxLength]="4"
    placeholder="YYYY"
    [inputStyle]="{ 'font-size': '14px' }"
    (onEnter)="this.handleOnChange()"></esg-textbox-form>
</div>
<div class="input-row">
  <div class="radio-button" (click)="handleSelectTimeperiodType(timePeriodEnum.Monthly)">
    <esg-radio-button [checked]="formGroup.controls.periodType.value === timePeriodEnum.Monthly"></esg-radio-button>
    <span class="radio-label">Month</span>
  </div>
  <esg-select
    [value]="formGroup.controls.month.value"
    [disabled]="formGroup.controls.month.disabled"
    size="small"
    [options]="monthOptions"
    (onChange)="handlePeriodChange(formGroup.controls.month, $event)"></esg-select>
  <esg-textbox-form
    [fc]="formGroup.controls.monthYear"
    type="digit"
    size="small"
    [maxLength]="4"
    placeholder="YYYY"
    [inputStyle]="{ 'font-size': '14px' }"
    (onEnter)="this.handleOnChange()"></esg-textbox-form>
</div>
