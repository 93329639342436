import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DynamicContentBase, IContentCommonActions, IContentCommonStates } from '../../dynamic-content-data';

export interface ITextAreaContentActions extends IContentCommonActions {
  generateText?: (question: string) => Promise<string>;
}

export interface ITextAreaContentStates extends IContentCommonStates {}

export interface ITextAreaContentData {
  textHtml: string;
}

@Component({
  selector: '[esg-text-area]',
  templateUrl: './text-area.component.html',
  styleUrls: ['../content-item.component.scss', './text-area.component.scss'],
})
export class TextAreaComponent
  extends DynamicContentBase<ITextAreaContentActions, ITextAreaContentStates, ITextAreaContentData>
  implements OnInit
{
  textAreaStyles = {
    fontSize: '18px',
    lineHeight: '28px',
    color: '#333333',
    border: 'none',
    padding: '20px 16px 4px 8px',
    opacity: '1',
  };
  textAreaStylesFocus = {
    fontSize: '18px',
    lineHeight: '28px',
    color: '#333333',
    border: '1px solid #0071E3',
    background: '#EDF5FD99',
    padding: '19px 15px 3px 7px',
    borderRadius: '4px',
    opacity: '1',
  };

  toolbarStyles = {
    position: 'sticky',
    width: 'fit-content',
    marginTop: '-36px',
    marginLeft: '50%',
    transform: 'translate(-50%, 18px)',
    top: '85px',
    zIndex: '3',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  };
  toolbarHidden = {
    display: 'none',
  };
  loading: boolean = false;

  fc = {
    content: new FormControl<string>(''),
  };
  formgroup = new FormGroup(this.fc);

  get innerText() {
    return this.fc.content.value ? String(this.fc.content.value).replace(/<[^>]+>/gm, '') : '';
  }

  get readContent() {
    return this.data?.textHtml;
  }

  get editorStyles() {
    const baseStyles = this.active ? { ...this.textAreaStylesFocus } : { ...this.textAreaStyles };
    if (!this.isVisibleInReport) {
      baseStyles.opacity = '0.4';
    }
    return baseStyles;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.fc.content.setValue(this.data?.textHtml || '');

    this.formgroup.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(result => {
      if (this.formgroup.dirty && !this.formgroup.controls.content.disabled && !this.loading) {
        this.onChange(this.contentId, {}, { textHtml: result.content || '' });
        this.formgroup.markAsPristine();
      }
    });
  }

  handleComponentClick() {
    this.onSetActive.emit(true);
  }

  handleOutsideClick() {
    this.onSetActive.emit(false);
  }

  handleOnGenerateText(prompt: string) {
    if (this.actions.generateText) {
      this.loading = true;
      this.formgroup.controls.content.setValue('<br><br><br><br>');
      this.actions
        .generateText(prompt)
        .then(res => {
          this.formgroup.controls.content.setValue('');
          this.loading = false;
          this.typingEffect(res);
        })
        .catch(() => {
          this.loading = false;
          this.formgroup.controls.content.setValue(
            '<p>ChatGPT is currently not responding. Please try again later.</p>'
          );
        });
    }
  }

  async typingEffect(text: string) {
    this.fc.content.disable();
    const words = text.split(' ');
    try {
      for (let i = 0; i < words.length - 1; i++) {
        this.fc.content.setValue(this.fc.content.value + words[i] + ' ');
        await new Promise(resolve => setTimeout(resolve, 50));
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.fc.content.enable();
      this.fc.content.markAsDirty();
      this.fc.content.setValue(text);
    }
  }
}
