import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface Style {
  [key: string]: string;
}

@Component({
  selector: 'esg-side-dialog',
  templateUrl: './side-dialog.component.html',
  styleUrls: ['./side-dialog.component.scss'],
})
export class SideDialogComponent implements OnInit {
  @Input() open = false;
  @Input() fullWidth: boolean = false;
  @Input() contentStyle: Style = {};
  @Output() onClose = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  handleClickBackground(event: Event) {
    event.stopPropagation();
    this.onClose.emit();
  }
}
