<div
  class="table-observer"
  esgIntersectionObserver
  [threshold]="0"
  [rootMargin]="rootObserverMargin"
  [observe]="!addOnly"
  (onIntersection)="handleStickyHeaders($event)"></div>
<table [style.grid-template-columns]="gridTemplateColumns" [class.sticky]="stickyHeaders" [class.read-only]="readOnly">
  <thead>
    <tr>
      <ng-container *ngFor="let cell of headerCells; let i = index; trackBy: trackByHeaderCell; let isFirst = first">
        <th
          *ngIf="isColumnVisible(cell.columnId)"
          [class.number]="cell.number || false"
          [class.add-only]="addOnly"
          [style.top.px]="stickyHeaderTopOffset"
          [style.padding-left.px]="isFirst ? stickyHeaderPadding : null"
          [style.margin-left.px]="isFirst ? -stickyHeaderPadding : null">
          <div class="header-cell-content">
            <span class="header-cell-label" [innerHTML]="cell.label | formatUnitWithHtml">
              <span *ngIf="cell.optional" class="header-cell-optional">(optional)</span>
            </span>
            <span class="header-cell-desc" [innerHTML]="cell.description | formatUnitWithHtml"></span>
          </div>
        </th>
      </ng-container>
      <th *ngIf="numberOfVisibleColumns === 2" [class.add-only]="addOnly" [style.top.px]="stickyHeaderTopOffset"></th>
      <th
        *ngIf="!addOnly"
        [style.top.px]="stickyHeaderTopOffset"
        [style.padding-right.px]="stickyHeaderPadding"
        [style.margin-right.px]="-stickyHeaderPadding">
        <ng-container *ngIf="hidableColumnOptions.length && columnFilter">
          <span class="divider"></span>
          <esg-column-viewer
            *ngIf="hidableColumnOptions.length && columnFilter"
            [fc]="columnFilter"
            [options]="hidableColumnOptions"></esg-column-viewer>
        </ng-container>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="!addOnly">
      <tr *ngIf="isAddButtonVisible">
        <td [style.grid-column]="fullGridColumn">
          <esg-add-button
            label="New record"
            variant="filled"
            size="small"
            (onClick)="handleOnAddRecord($event)"></esg-add-button>
        </td>
      </tr>
      <tr
        *ngIf="!readOnly && isAdding"
        class="record-form"
        esg-on-click-outside
        (onClickOutside)="handleOnSubmit()"
        esgKeyboardShortcuts
        (onEscapeKey)="handleOnEscape()"
        (onEnterKey)="handleOnEnter()"
        (focusin)="handleFocusInside()">
        <ng-container *ngTemplateOutlet="recordForm"></ng-container>
        <td class="input-row-action" (click)="onFormClose.emit()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="2.81445"
              y="1.87207"
              width="16"
              height="1.33333"
              rx="0.666667"
              transform="rotate(45 2.81445 1.87207)" />
            <rect
              x="1.87109"
              y="13.1855"
              width="16"
              height="1.33333"
              rx="0.666667"
              transform="rotate(-45 1.87109 13.1855)" />
          </svg>
        </td>
      </tr>
      <ng-container *ngFor="let row of bodyRows; let i = index; trackBy: trackByRecord">
        <tr *ngIf="editRecordId !== row.id" class="record-view" [class.connected-row]="row.recordSource ? true : false">
          <ng-container *ngFor="let cell of row.cells; let isFirst = first">
            <td
              *ngIf="isColumnVisible(cell.columnId)"
              [class.number]="cell.number || false"
              (click)="!row.recordSource && handleOnRowClick($event, row.id)">
              <span *ngIf="isFirst" class="icon-wrapper">
                <ng-container *ngIf="row.recordSource">
                  <esg-raw-data-icon
                    *ngIf="row.recordSource === RecordSourceEnum.RawData"
                    class="source-icon"></esg-raw-data-icon>
                  <esg-column-stack-icon
                    *ngIf="row.recordSource === RecordSourceEnum.IndicatorDefinition"
                    class="source-icon"></esg-column-stack-icon>
                </ng-container>
                <esg-pulsing-dot
                  *ngIf="!row.recordSource"
                  [class.pulsing]="newlyAddedRowsIds.includes(row.id)"></esg-pulsing-dot>
              </span>
              {{ cell.number ? formatNumberCell(cell.value) : cell.value }}

              <span *ngIf="cell.unit" class="unit">&nbsp;{{ cell.unit | formatUnitWithUnicode }}</span>
            </td>
          </ng-container>
          <td *ngIf="numberOfVisibleColumns === 2"></td>
          <td class="row-menu">
            <span class="divider"></span>
            <esg-context-menu
              class="ellipsis"
              [options]="userContextMenuProvider(row.id, row.recordSource ? true : false)"></esg-context-menu>
          </td>
        </tr>
        <tr
          *ngIf="!readOnly && editRecordId === row.id"
          class="record-form"
          esg-on-click-outside
          (onClickOutside)="handleOnFormClose()"
          esgKeyboardShortcuts
          (onEscapeKey)="handleOnEscape()"
          (onEnterKey)="handleOnEscape()"
          (focusin)="handleFocusInside()">
          <ng-container *ngTemplateOutlet="recordForm"></ng-container>
          <td class="input-row-action">
            <esg-context-menu class="ellipsis" [options]="userContextMenuProvider(row.id)"></esg-context-menu>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="addOnly">
      <tr class="record-form">
        <ng-container *ngTemplateOutlet="recordForm"></ng-container>
      </tr>
    </ng-container>
    <ng-template #recordForm><ng-content></ng-content></ng-template>
  </tbody>
</table>
