<esg-form-dialog
  submitText="Create record"
  [submitDisabled]="!formGroup?.valid"
  (onCancel)="handleCancel()"
  (onSubmit)="handleOnSubmitClick()">
  <div class="container">
    <header>Add new Record</header>

    <div class="content">
      <div class="section">
        <span class="section-header">1. Select Category</span>
        <div class="section-selectors">
          <esg-select
            [options]="state.recordCategoryOptions"
            [value]="state.selectedCategory"
            label="CATEGORY"
            placeholder="Select"
            [isPopup]="true"
            (onChange)="state.setSelectedCategory($event)"></esg-select>
          <esg-select
            *ngIf="state.recordSubCategoryOptions"
            [options]="state.recordSubCategoryOptions"
            [value]="state.selectedSubCategory"
            label="SUB CATEGORY"
            placeholder="Select"
            [isPopup]="true"
            (onChange)="state.setSelectedSubCategory($event)"></esg-select>
          <esg-select
            *ngIf="state.profileOptions"
            [options]="state.profileOptions"
            [value]="state.selectedProfile"
            label="PROFILE"
            placeholder="Select"
            [isPopup]="true"
            (onChange)="state.setSelectedProfile($event)"></esg-select>
        </div>
      </div>
      <div class="section">
        <span class="section-header" [class.disabled]="!state.selectedCategory?.value">2. Add record properties</span>
        <ng-container [ngSwitch]="state.selectedCategory?.value">
          <ng-container *ngSwitchCase="state.categoryEnum.DirectEmissionFuelCombustion">
            <esg-vessel-combustion
              *ngIf="state.selectedSubCategory?.value === state.subCategoryEnum.VesselConsumption"
              [addOnly]="true"
              (onSubmit)="handleOnFormSubmit($event)"></esg-vessel-combustion>
            <esg-vehicle-combustion
              *ngIf="state.selectedSubCategory?.value === state.subCategoryEnum.VehicleConsumption"
              [addOnly]="true"
              (onSubmit)="handleOnFormSubmit($event)"></esg-vehicle-combustion>
          </ng-container>
          <esg-electricity
            *ngSwitchCase="state.categoryEnum.IndirectEmissionsElectricity"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-electricity>
          <esg-waste
            *ngSwitchCase="state.categoryEnum.IndirectEmissionsWaste"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-waste>
          <ng-container *ngSwitchCase="state.categoryEnum.IndirectEmissionsBusinessTravel">
            <esg-air-travel
              *ngIf="state.selectedSubCategory?.value === state.subCategoryEnum.OrganizationBusinessAirTravel"
              [addOnly]="true"
              (onSubmit)="handleOnFormSubmit($event)"></esg-air-travel>
            <esg-hotel-nights
              *ngIf="state.selectedSubCategory?.value === state.subCategoryEnum.OrganizationBusinessTravelHotelNights"
              [addOnly]="true"
              (onSubmit)="handleOnFormSubmit($event)"></esg-hotel-nights>
          </ng-container>
          <esg-leased-assets
            *ngSwitchCase="state.categoryEnum.EmbeddedEmissionsLeasedAssets"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-leased-assets>
          <esg-spills-to-environment
            *ngSwitchCase="state.categoryEnum.BiodiversitySpillsToEnvironment"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-spills-to-environment>
          <esg-marine-protected-areas
            *ngSwitchCase="state.categoryEnum.BiodiversityProtectedAreas"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-marine-protected-areas>
          <esg-new-hires-and-turnover
            *ngSwitchCase="state.categoryEnum.EmployeesNewHiresAndTurnovers"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-new-hires-and-turnover>
          <esg-sick-leave
            *ngSwitchCase="state.categoryEnum.EmployeesSickLeave"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-sick-leave>
          <esg-parental-leave
            *ngSwitchCase="state.categoryEnum.EmployeesParentalLeave"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-parental-leave>
          <esg-work-related-injuries
            *ngSwitchCase="state.categoryEnum.EmployessWorkRelatedInjuries"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-work-related-injuries>
          <esg-marine-casualties
            *ngSwitchCase="state.categoryEnum.EmployessMarineCasualties"
            [addOnly]="true"
            (onSubmit)="handleOnFormSubmit($event)"></esg-marine-casualties>
        </ng-container>
      </div>
    </div>
  </div>
</esg-form-dialog>
