<div *ngIf="!reportState.freshReport" class="no-topic welcome-back">
  <span class="no-topic-header">{{ noContentImage.header }}</span>
  <span class="no-topic-text">{{ noContentImage.text }}</span>
  <img
    [src]="noContentImage.src"
    [width]="noContentImage.width"
    [height]="noContentImage.height"
    [alt]="noContentImage.alt" />
</div>
<div *ngIf="reportState.freshReport" class="no-topic fresh-report">
  <span class="no-topic-header">Howdy! What a fresh start :-)</span>
  <span class="no-topic-text">Please select topics in the column to the left to start adding content.</span>
  <img
    src="/assets/images/report/new-report.png"
    width="486.73"
    height="320"
    alt="Two colleagues working together in front of a whiteboard" />
</div>
