import { Injectable } from '@angular/core';
import { UnitApiService } from 'src/app/api-client/report-api/unit-api-service';
import { ObjectArray, toHashTableBy } from '../../utils/object-array';
import { NotificationService } from '../notification/notification.service';
import { BehaviorSubject } from 'rxjs';

export interface IUnit {
  key: string;
  name: string;
  description: string;
}

@Injectable()
export class UnitService {
  private unitsSubject: BehaviorSubject<ObjectArray<IUnit>> = new BehaviorSubject<ObjectArray<IUnit>>({});
  units$ = this.unitsSubject.asObservable();

  constructor(private unitApiService: UnitApiService, private notificationService: NotificationService) {
    this.getAllUnits();
  }

  async getAllUnits() {
    try {
      const response = await this.unitApiService.getAllUnits();
      const units = response.result.map(unit => ({
        key: unit.key,
        name: unit.name,
        description: unit.description,
      }));
      const unitsHashTable = toHashTableBy(units, 'key');
      this.unitsSubject.next(unitsHashTable);
    } catch (error) {
      this.notificationService.showError('An error occurred while fetching units.');
    }
  }
}
