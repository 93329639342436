import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'esg-report-menu-item',
  templateUrl: './report-menu-item.component.html',
  styleUrls: ['./report-menu-item.component.scss'],
})
export class ReportMenuItemComponent implements OnInit {
  @Input() label: string = '';
  @Output() onClick = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  handleClick(event: Event) {
    this.onClick.emit(event);
  }
}
