import { Component } from '@angular/core';
import { AssetListComponent } from '../asset-list.component';
import { AssetListStateService } from '../asset-list-state.service';
import { SuppliersStateService } from './suppliers-state.service';

@Component({
  selector: 'esg-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['../asset-list.component.scss'],
  providers: [{ provide: AssetListStateService, useClass: SuppliersStateService }],
})
export class SuppliersComponent extends AssetListComponent {}
