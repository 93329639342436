import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-dashboard-icon',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="6.5" y="1.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="11.5" y="1.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="6.5" y="6.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="11.5" y="6.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="1.5" y="6.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="1.5" y="11.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="6.5" y="11.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="11.5" y="11.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class DashboardIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
