import { Component } from '@angular/core';
import { SickLeaveStateService } from './sick-leave-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-sick-leave',
  templateUrl: './sick-leave.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: SickLeaveComponent }, SickLeaveStateService],
})
export class SickLeaveComponent extends RecordsComponent {
  constructor(public state: SickLeaveStateService) {
    super(state);
  }
}
