import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RiskCategory, TimePeriodType } from 'src/api-client/report-api.generated';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';

@Component({
  selector: 'esg-shared-components [riskCategory]',
  templateUrl: './shared-components.component.html',
  styleUrls: ['./shared-components.component.scss'],
})
export class SharedComponentsComponent {
  riskCategoryEnum = RiskCategory;
  timePeriodFormControl: FormControl<ITimePeriodData> = new FormControl<ITimePeriodData>(
    {
      type: TimePeriodType.Annual,
      year: 2024,
    },
    {
      nonNullable: true,
    }
  );
  nan = NaN;
}
