import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITimePeriodData } from '../../time-period/time-period.component';
import { IOption, globalMonthShortOptions, globalQuarterOptions } from 'src/app/static-data/options';
import { TimePeriodType } from 'src/api-client/report-api.generated';
import { FormControl, FormGroup, Validators } from '@angular/forms';

interface TimePeriodForm {
  periodType: FormControl<TimePeriodType | undefined>;
  annualYear: FormControl<string>;
  quarterYear: FormControl<string>;
  quarter: FormControl<IOption>;
  monthYear: FormControl<string>;
  month: FormControl<IOption>;
}

@Component({
  selector: 'esg-time-period-selector-options',
  templateUrl: './time-period-selector-options.component.html',
  styleUrl: './time-period-selector-options.component.scss',
})
export class TimePeriodSelectorOptionsComponent {
  @Input({ required: true }) timePeriod!: ITimePeriodData | undefined;
  @Output() onChange = new EventEmitter<ITimePeriodData>();

  formGroup!: FormGroup<TimePeriodForm>;
  quarterOptions: IOption[] = globalQuarterOptions;
  monthOptions: IOption[] = globalMonthShortOptions;

  timePeriodEnum = TimePeriodType;

  ngOnInit(): void {
    this.initializeForm();
  }

  handleSelectTimeperiodType(timePeriodType: TimePeriodType) {
    this.formGroup.controls.periodType.setValue(timePeriodType);
    switch (timePeriodType) {
      case TimePeriodType.Annual:
        this.formGroup.controls.annualYear.enable();
        this.formGroup.controls.quarterYear.disable();
        this.formGroup.controls.quarter.disable();
        this.formGroup.controls.monthYear.disable();
        this.formGroup.controls.month.disable();
        break;
      case TimePeriodType.Quarterly:
        this.formGroup.controls.quarterYear.enable();
        this.formGroup.controls.quarter.enable();
        this.formGroup.controls.annualYear.disable();
        this.formGroup.controls.monthYear.disable();
        this.formGroup.controls.month.disable();
        break;
      case TimePeriodType.Monthly:
        this.formGroup.controls.monthYear.enable();
        this.formGroup.controls.month.enable();
        this.formGroup.controls.annualYear.disable();
        this.formGroup.controls.quarterYear.disable();
        this.formGroup.controls.quarter.disable();
        break;
    }

    this.handleOnChange();
  }

  handlePeriodChange(formControl: FormControl<IOption>, option: IOption) {
    if (option !== formControl.value) {
      formControl.setValue(option);
      formControl.markAsDirty();
      this.handleOnChange();
    }
  }

  handleOnChange() {
    switch (this.formGroup.controls.periodType.value) {
      case TimePeriodType.Annual:
        if (this.formGroup.controls.annualYear.value) {
          this.onChange.emit({
            type: TimePeriodType.Annual,
            year: parseInt(this.formGroup.controls.annualYear.value),
            period: 1,
          });
        }
        break;
      case TimePeriodType.Quarterly:
        if (this.formGroup.controls.quarterYear.value) {
          this.onChange.emit({
            type: TimePeriodType.Quarterly,
            year: parseInt(this.formGroup.controls.quarterYear.value),
            period: parseInt(this.formGroup.controls.quarter.value.value),
          });
        }
        break;
      case TimePeriodType.Monthly:
        if (this.formGroup.controls.monthYear.value) {
          this.onChange.emit({
            type: TimePeriodType.Monthly,
            year: parseInt(this.formGroup.controls.monthYear.value),
            period: parseInt(this.formGroup.controls.month.value.value),
          });
        }
        break;
    }
  }

  private initializeForm() {
    const quarterOption =
      this.timePeriod?.type === TimePeriodType.Quarterly
        ? this.quarterOptions.find(o => o.value === this.timePeriod?.period?.toString()) || this.quarterOptions[0]
        : this.quarterOptions[0];

    const monthOption =
      this.timePeriod?.type === TimePeriodType.Monthly
        ? this.monthOptions.find(o => o.value === this.timePeriod?.period?.toString()) || this.monthOptions[0]
        : this.monthOptions[0];

    const year = this.timePeriod?.year?.toString() || new Date().getFullYear().toString();
    const form: TimePeriodForm = {
      periodType: new FormControl<TimePeriodType | undefined>(this.timePeriod?.type, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      annualYear: new FormControl<string>(
        { value: year, disabled: this.timePeriod?.type !== TimePeriodType.Annual },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      quarterYear: new FormControl<string>(
        { value: year, disabled: this.timePeriod?.type !== TimePeriodType.Quarterly },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      quarter: new FormControl<IOption>(
        { value: quarterOption, disabled: this.timePeriod?.type !== TimePeriodType.Quarterly },
        {
          nonNullable: true,
        }
      ),
      monthYear: new FormControl<string>(
        { value: year, disabled: this.timePeriod?.type !== TimePeriodType.Monthly },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      month: new FormControl<IOption>(
        { value: monthOption, disabled: this.timePeriod?.type !== TimePeriodType.Monthly },
        {
          nonNullable: true,
        }
      ),
    };
    this.formGroup = new FormGroup<TimePeriodForm>(form);
  }
}
