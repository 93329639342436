<div esg-on-click-outside (onClickOutside)="collapse()" class="container">
  <div #input class="select-wrapper">
    <esg-select-field
      [value]="selectedValueLabel"
      [variant]="variant"
      [size]="size"
      [placeholder]="placeholder"
      [longestLabel]="placeholder"
      [isCollapsed]="isCollapsed"
      [disabled]="disabled"
      (onToggleCollapse)="toggleCollapse()"></esg-select-field>
    <esg-time-period-selector-options
      *ngIf="!isCollapsed || focused"
      class="options"
      [timePeriod]="selectedValue"
      [class.drop-up]="dropUp"
      (onChange)="handleChange($event)"></esg-time-period-selector-options>
  </div>
</div>
