<table (mouseleave)="clearHoverState()" #table>
  <thead *ngIf="!hideColumns" (mouseenter)="clearHoverState()">
    <tr>
      <th
        *ngFor="let column of columns; let firstIndex = first; trackBy: trackByColumnId"
        (mouseenter)="onColumnMouseEnter(column.columnId)"
        [style.width]="column.width"
        [class.delete-cell]="hoveredColumnId === column.columnId && isDeleteColumnHovered">
        <ng-container *ngIf="isConfigurable">
          <div *ngIf="hoveredColumnId === column.columnId" class="column-buttons">
            <button
              class="table-button"
              (mouseenter)="setHoverActionState(hoverActionStateTypeEnum.DeleteColumn)"
              (mouseleave)="clearHoverActionState()"
              (click)="handleOnColumnDelete(column.columnId)">
              <esg-delete-icon></esg-delete-icon>
            </button>
            <button
              class="table-button"
              (mouseenter)="setHoverActionState(hoverActionStateTypeEnum.AddColumn)"
              (mouseleave)="clearHoverActionState()"
              (click)="handleOnColumnAddAfter(column.columnId)">
              <esg-icon-add></esg-icon-add>
            </button>
          </div>
          <div *ngIf="firstIndex && isFirstHeaderHovered" class="row-buttons">
            <button class="table-button" [disabled]="true">
              <esg-delete-icon></esg-delete-icon>
            </button>
            <button
              class="table-button"
              (mouseenter)="setHoverActionState(hoverActionStateTypeEnum.AddRow)"
              (mouseleave)="clearHoverActionState()"
              (click)="handleOnRowAddAfter()">
              <esg-icon-add></esg-icon-add>
            </button>
          </div>
          <div
            class="right-border"
            [class.resize]="resizingForColumn === column.columnId"
            [class.add]="isAddColumnHovered && hoveredColumnId === column.columnId"
            (mouseenter)="handleResizeMouseEnter(column.columnId)"
            (mouseleave)="handleResizeMouseLeave()"
            (mousedown)="startResizing($event, column.columnId)"></div>
          <div class="bottom-border" [class.add]="isFirstHeaderHovered && isAddRowHovered"></div>
        </ng-container>
        <div
          class="header-cell-content"
          [class.number]="column.type === inputTypeEnum.Numeric || column.type === inputTypeEnum.Currency">
          <input
            class="header-cell-label"
            [value]="column.header"
            [disabled]="!isConfigurable"
            placeholder="Placeholder"
            (blur)="handleOnHeaderChange(column.columnId, $event)" />
          <input
            class="header-cell-desc"
            [value]="column.description"
            [disabled]="!isConfigurable"
            placeholder="Placeholder"
            (blur)="handleOnDescriptionChange(column.columnId, $event)" />
        </div>
      </th>
    </tr>
  </thead>
  <tbody (mouseenter)="clearHoverState()" [class.rows-only]="hideColumns">
    <tr *ngFor="let row of rows; trackBy: trackByRowId">
      <td
        *ngFor="let cell of row.values; let firstIndex = first"
        [class.selected]="isSelectedCell(row.rowId, cell.columnId)"
        [class.delete-cell]="
          (hoveredRowId === row.rowId && isDeleteRowHovered) ||
          (hoveredColumnId === cell.columnId && isDeleteColumnHovered)
        "
        [attr.data-row-id]="row.rowId"
        [attr.data-column-id]="cell.columnId">
        <ng-container *ngIf="isConfigurable">
          <div *ngIf="hoveredRowId === row.rowId && firstIndex" class="row-buttons">
            <button
              class="table-button"
              (mouseenter)="setHoverActionState(hoverActionStateTypeEnum.DeleteRow)"
              (mouseleave)="clearHoverActionState()"
              (click)="handleOnRowDelete(row.rowId)">
              <esg-delete-icon></esg-delete-icon>
            </button>
            <button
              class="table-button"
              (mouseenter)="setHoverActionState(hoverActionStateTypeEnum.AddRow)"
              (mouseleave)="clearHoverActionState()"
              (click)="handleOnRowAddAfter(row.rowId)">
              <esg-icon-add></esg-icon-add>
            </button>
          </div>
          <div
            class="right-border"
            [class.resize]="resizingForColumn === cell.columnId"
            [class.add]="isAddColumnHovered && hoveredColumnId === cell.columnId"
            (mousedown)="startResizing($event, cell.columnId)"
            (mouseenter)="handleResizeMouseEnter(cell.columnId)"
            (mouseleave)="handleResizeMouseLeave()"></div>
        </ng-container>
        <div class="bottom-border" [class.add]="hoveredRowId === row.rowId && isAddRowHovered"></div>
        <div class="body-cell-content" (mouseenter)="firstIndex && onRowMouseEnter(row.rowId)">
          <ng-container
            *ngIf="
              getColumnType(cell.columnId) === inputTypeEnum.StaticText ||
              getColumnType(cell.columnId) === inputTypeEnum.Generic
            ">
            <esg-textbox
              type="text"
              placeholder=""
              [value]="cell.value.toString()"
              [inputStyle]="{ 'text-align': firstIndex ? 'left' : 'right' }"
              [disabled]="getColumnType(cell.columnId) === inputTypeEnum.StaticText"
              variant="ghost-table"
              (onChange)="handleOnTextChange(row.rowId, cell.columnId, $event)"></esg-textbox>
          </ng-container>
          <ng-container
            *ngIf="
              getColumnType(cell.columnId) === inputTypeEnum.Numeric ||
              getColumnType(cell.columnId) === inputTypeEnum.Currency
            ">
            <esg-textbox
              type="number"
              placeholder=""
              [value]="cell.value.toString() || ''"
              [inputStyle]="{ 'text-align': firstIndex ? 'left' : 'right' }"
              variant="ghost-table"
              (onChange)="handleOnNumberChange(row.rowId, cell.columnId, $event)"></esg-textbox>
            <esg-table-select
              *ngIf="getColumnType(cell.columnId) === inputTypeEnum.Currency"
              class="currency"
              [options]="currencyOptions"
              [fitLongestLabel]="false"
              [value]="getCurrencyOption(cell.unit || '')"
              (onChange)="handleOnCurrencyChange(row.rowId, cell.columnId, $event)"></esg-table-select>
          </ng-container>
          <ng-container *ngIf="getColumnType(cell.columnId) === inputTypeEnum.Selector">
            <esg-table-select
              [fitLongestLabel]="false"
              [value]="getOptionValue(cell.columnId, cell.value)"
              [options]="getColumnOptions(cell.columnId) || []"
              (onChange)="handleOnDropdownChange(row.rowId, cell.columnId, $event)"></esg-table-select>
          </ng-container>
        </div>
      </td>
    </tr>
  </tbody>
</table>
