import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-no-lock-icon',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.8333 1.23054C8.34295 1.23054 8.81194 1.35006 9.24026 1.5891C9.674 1.82815 10.021 2.18128 10.2812 2.6485C10.5469 3.11573 10.6797 3.69161 10.6797 4.37614V6.49494H7.72758L8.96375 7.72548H11.5174C11.6963 7.72548 11.8319 7.77709 11.924 7.88031C12.0216 7.98354 12.0704 8.13566 12.0704 8.33667V10.8385L13.3879 12.1505V8.42631C13.3879 7.8613 13.2659 7.42124 13.022 7.10614C12.7834 6.7856 12.431 6.59273 11.9647 6.52754V4.54728C11.9647 3.5259 11.7668 2.67838 11.371 2.00471C10.9807 1.33104 10.471 0.828506 9.84208 0.497104C9.21315 0.165701 8.54356 0 7.8333 0C7.27486 0 6.74081 0.103224 6.23116 0.309671C5.72152 0.516118 5.27151 0.823073 4.88114 1.23054C4.49077 1.638 4.19799 2.14597 4.00281 2.75444L5.04379 3.80569C5.11427 3.24611 5.28235 2.77617 5.54802 2.39588C5.81369 2.01015 6.14442 1.71949 6.54021 1.52391C6.94142 1.32833 7.37245 1.23054 7.8333 1.23054ZM4.11666 15.9807H11.5581C11.7478 15.9807 11.9213 15.9589 12.0786 15.9155C12.2412 15.872 12.3822 15.8122 12.5015 15.7362L11.4036 14.636C11.371 14.6686 11.3304 14.6958 11.2816 14.7175C11.2382 14.7393 11.1867 14.7501 11.127 14.7501H4.15733C3.98383 14.7501 3.84829 14.6958 3.75069 14.5871C3.6531 14.4839 3.6043 14.3318 3.6043 14.1308V8.33667C3.6043 8.13566 3.6531 7.98354 3.75069 7.88031C3.84829 7.77709 3.98383 7.72548 4.15733 7.72548H4.4989L3.40912 6.63348H3.33593C3.0052 6.74214 2.74766 6.94858 2.56332 7.25282C2.37898 7.55706 2.28681 7.94822 2.28681 8.42631V14.0411C2.28681 14.6876 2.44133 15.1712 2.75037 15.4917C3.06484 15.8177 3.52027 15.9807 4.11666 15.9807ZM14.5346 16.4126C14.6539 16.5375 14.8003 16.6 14.9738 16.6C15.1527 16.6 15.3018 16.5375 15.4211 16.4126C15.5404 16.293 15.6 16.1464 15.6 15.9725C15.6 15.7987 15.5404 15.6493 15.4211 15.5243L1.47354 1.56465C1.34884 1.4397 1.19974 1.37722 1.02624 1.37722C0.852744 1.37722 0.703645 1.4397 0.578944 1.56465C0.459664 1.68418 0.400024 1.83358 0.400024 2.01286C0.400024 2.18671 0.459664 2.3334 0.578944 2.45292L14.5346 16.4126Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class NoLockIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
