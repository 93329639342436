import { Component } from '@angular/core';
import { FieldConfig } from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';

@Component({
  selector: 'esg-data-import-from-excel-example',
  templateUrl: './data-import-from-excel.component.html',
  styleUrls: ['./data-import-from-excel.component.scss'],
})
export class DataImportFromExcelExampleComponent {
  customImportFromExcelDialogVisible = true;
  fieldConfigs: FieldConfig[] = [
    { type: 'number', title: 'Installed Power', propertyName: 'installedPower' },
    { type: 'number', title: 'Distance Sailed', propertyName: 'distanceSailed' },
    { type: 'number', title: 'In Operation', propertyName: 'inOperation' },
    { type: 'number', title: 'MGO Fuel Consumption', propertyName: 'mgoConsumption' },
  ];

  handleImportData(event: any) {
    console.log('FROM EXAMPLE', event);
  }

  showCustomImportFromExcelDialog() {
    this.customImportFromExcelDialogVisible = true;
  }
  closeCustomImportFromExcelDialog() {
    this.customImportFromExcelDialogVisible = false;
  }
}
