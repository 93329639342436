<esg-records-table
  [headerCells]="headerCells"
  [bodyRows]="dataRecordsRows"
  [newlyAddedRowsIds]="newlyAddedRowsIds"
  [readOnly]="readOnly"
  [addOnly]="addOnly"
  [isAdding]="recordFormgroup !== undefined && !editRecordId"
  [editRecordId]="editRecordId"
  [stickyHeaderTopOffset]="stickyHeaderTopOffset"
  [intersectionObserverRootMargin]="intersectionObserverRootMargin"
  [stickyHeaderPadding]="stickyHeaderPadding"
  (onAdding)="handleOnIniateAddRecord()"
  (onFormClose)="handleCloseForm()"
  (onEditing)="handleOnInitateEditRecord($event)"
  (onSubmit)="handleOnSubmit()"
  (onDelete)="handleOnDelete($event)">
  <ng-container *ngIf="recordFc">
    <td class="datepicker-cell" [class.focus]="startDateOpen">
      <esg-datepicker
        *ngIf="recordFc.startDate"
        class="input-text"
        [fc]="recordFc.startDate"
        variant="ghost-table"
        [maxDate]="recordFc.endDate.value || undefined"
        [inputIndent]="15"
        [autoFocus]="startDateOpen"
        (onOpen)="startDateOpen = true"
        (onClose)="onStartDateClose()"></esg-datepicker>
    </td>
    <td class="datepicker-cell" [class.focus]="endDateOpen">
      <span class="date-divider" [class.focus]="startDateOpen"><span class="triangle"></span></span>
      <esg-datepicker
        *ngIf="recordFc.endDate"
        class="input-text"
        [fc]="recordFc.endDate"
        variant="ghost-table"
        [minDate]="recordFc.startDate.value || undefined"
        [inputIndent]="13"
        [autoFocus]="endDateOpen"
        (onOpen)="endDateOpen = true"
        (onClose)="endDateOpen = false"></esg-datepicker>
    </td>
    <td class="input-cell" [class.disabled]="assetOptions.length < 2">
      <esg-table-select
        *ngIf="recordFc.asset"
        class="input-dropdown"
        [isPopup]="addOnly"
        [groupedOptions]="assetOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.asset.value || undefined"
        [disabled]="assetOptions.length < 2"
        (onChange)="handleDropdownChange(recordFc.asset, $event)"></esg-table-select>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.recordableInjuries.valid">
      <esg-textbox-form
        *ngIf="recordFc.recordableInjuries"
        class="input-text"
        type="number"
        placeholder="Number"
        [fc]="recordFc.recordableInjuries"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.recordableInjuries.setValue($event)"></esg-textbox-form>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.fatalities.valid">
      <esg-textbox-form
        *ngIf="recordFc.fatalities"
        class="input-text"
        type="number"
        placeholder="Number"
        [fc]="recordFc.fatalities"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.fatalities.setValue($event)"></esg-textbox-form>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.highConsequenceInjuries.valid">
      <esg-textbox-form
        *ngIf="recordFc.highConsequenceInjuries"
        class="input-text"
        type="number"
        placeholder="Number"
        [fc]="recordFc.highConsequenceInjuries"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.highConsequenceInjuries.setValue($event)"></esg-textbox-form>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.illnessHealth.valid">
      <esg-textbox-form
        *ngIf="recordFc.illnessHealth"
        class="input-text"
        type="number"
        placeholder="Number"
        [fc]="recordFc.illnessHealth"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.illnessHealth.setValue($event)"></esg-textbox-form>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.lostTimeInjuries.valid">
      <esg-textbox-form
        *ngIf="recordFc.lostTimeInjuries"
        class="input-text"
        type="number"
        placeholder="Number"
        [fc]="recordFc.lostTimeInjuries"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.lostTimeInjuries.setValue($event)"></esg-textbox-form>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.daysLost.valid">
      <esg-textbox-form
        *ngIf="recordFc.daysLost"
        class="input-text"
        type="number"
        placeholder="Number"
        [fc]="recordFc.daysLost"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.daysLost.setValue($event)"></esg-textbox-form>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.exposureHours.valid">
      <esg-textbox-form
        *ngIf="recordFc.exposureHours"
        class="input-text"
        type="number"
        placeholder="Number"
        [fc]="recordFc.exposureHours"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.exposureHours.setValue($event)"></esg-textbox-form>
    </td>
  </ng-container>
</esg-records-table>
