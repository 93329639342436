import { Injectable } from '@angular/core';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { AssetType, CreateAssetCommandParams, Permission } from 'src/api-client/report-api.generated';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AddAssetOptions, IOption } from 'src/app/static-data/options';
import { Subscription } from 'rxjs';
import { AssetsMenuStateService } from '../assets-menu/assets-menu-state.service';
import { NotificationLinkUi } from 'src/app/shared/ui/notification/notification.component';
import { SectorApiService } from 'src/app/api-client/report-api/sector-api-service';

@Injectable()
export class AssetFormDialogStateService {
  private userInfoSub!: Subscription;
  private organizationContextSub!: Subscription;
  readonly assetTypeEnum = AssetType;

  assetTypeOptions = AddAssetOptions;
  organizationOptions: IOption[] = [];
  countryOptions: IOption[] = [];
  sectorOptions: IOption[] = [];
  organizationCountryMap = new Map<string, string>();

  organizationId?: string;

  selectedAssetType?: IOption = undefined;

  constructor(
    private appInfo: AppInfoService,
    private assetApiService: AssetApiService,
    private countryApiService: CountryApiService,
    private organizationApiService: OrganizationApiService,
    private sectorApiService: SectorApiService,
    private notificationService: NotificationService,
    private assetsMenuState: AssetsMenuStateService
  ) {
    this.userInfoSub = this.appInfo.userInfo$.subscribe(userInfo => {
      if (userInfo && !this.appInfo.hasPermission(Permission.Change_Current_Context_Organization)) {
        this.organizationId = userInfo.organizationId;
        this.init();
      }
    });

    this.organizationContextSub = this.appInfo.organizationContext$.subscribe(organizationId => {
      if (organizationId) {
        this.organizationId = organizationId;
        this.init();
      }
    });
  }

  init() {
    this.getOrganizationList();
    this.getCountries();
    this.getSectors();
  }

  async submitAssetForm(params: CreateAssetCommandParams) {
    const response = await this.assetApiService.createAsset(params);
    if (response.success) {
      const assetType = Object.values(AssetType).find(type => type === this.selectedAssetType?.value);
      let routerLink: string[] = [];

      switch (assetType) {
        case AssetType.Vessel:
          routerLink = ['/assets/vessels'];
          break;
        case AssetType.VehicleCraftMachinery:
          routerLink = ['/assets/vehicles-crafts-machinery'];
          break;
        case AssetType.Supplier:
          routerLink = ['/assets/suppliers'];
          break;
        case AssetType.Facility:
          routerLink = ['/assets/facilities'];
          break;
        case AssetType.Client:
          routerLink = ['/assets/clients'];
          break;
        default:
          routerLink = ['/assets'];
          break;
      }
      const link: NotificationLinkUi = { text: 'Go to asset details.', routerLink: routerLink };
      this.notificationService.showSuccess('1 new asset was added.', '', link);
      this.assetsMenuState.loadAssetTypeCounters();
      if (assetType) this.assetsMenuState.notifyOfNewAsset(assetType);
    }
  }

  setSelectAssetType(assetType: IOption) {
    this.selectedAssetType = assetType;
  }

  async getOrganizationList() {
    if (this.organizationId) {
      const response = await this.organizationApiService.getSubOrganizationList(this.organizationId);
      this.organizationOptions = response.result.map(org => {
        this.organizationCountryMap.set(
          org.subOrganizationId ? org.subOrganizationId : org.organizationId,
          org.countryCode
        );

        return {
          value: org.subOrganizationId ? org.subOrganizationId : org.organizationId,
          label: org.name,
        };
      });
    }
  }

  async getCountries() {
    const response = await this.countryApiService.getAllCountries();
    this.countryOptions = response.result.map(country => ({ value: country.code, label: country.name }));
  }

  async getSectors() {
    const response = await this.sectorApiService.getAll();
    this.sectorOptions = response.result.map(s => ({ value: s.type, label: s.name }));
  }

  ngOnDestroy() {
    this.userInfoSub.unsubscribe();
    this.organizationContextSub.unsubscribe();
  }
}
