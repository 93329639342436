import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ReportTopic } from 'src/api-client/report-api.generated';
import { ContextMenuComponent, IMenuItem } from '../../../../shared/ui/context-menu/context-menu.component';

export interface EditTopicData {
  mainLevelId: string;
  topicId: string;
  updatedName: string;
}

export interface DeleteTopicData {
  mainLevelId: string;
  topicId: string;
  topicName?: string;
}
export interface DeactivateTopicData {
  mainLevelId: string;
  topicId: string;
  deactivated: boolean;
}

@Component({
  selector: 'esg-topic-item',
  templateUrl: './topic-item.component.html',
  styleUrls: ['./topic-item.component.scss'],
})
export class TopicItemComponent {
  editingFormControl?: FormControl<string | null>;
  @Input({ required: true }) reportId!: string;
  @Input({ required: true }) mainLevelId!: string;
  @Input({ required: true }) topicData!: ReportTopic;
  @Input() isLoading: boolean = false;
  @Output() onSelectTopic = new EventEmitter<string>();
  @Output() onEditTopic = new EventEmitter<EditTopicData>();
  @Output() onDeleteTopic = new EventEmitter<DeleteTopicData>();
  @Output() onDeactivateTopic = new EventEmitter<DeactivateTopicData>();

  @ViewChild(ContextMenuComponent)
  private contextMenuComponent?: ContextMenuComponent;

  handleEditTopic() {
    this.editingFormControl = new FormControl<string>('', [Validators.required]);
    this.editingFormControl.setValue(this.topicData.name || '');
  }

  handleSingleClick() {
    this.onSelectTopic.emit(this.topicData.id);
    this.contextMenuComponent?.handleClose();
  }

  handleDoubleClick() {
    this.handleEditTopic();
    this.contextMenuComponent?.handleClose();
  }

  handleMouseLeave() {
    this.contextMenuComponent?.handleClose();
  }

  handleDragStart() {
    this.contextMenuComponent?.handleClose();
  }

  handleClickOutside() {
    if (this.editingFormControl?.value) {
      this.onEditTopic.emit({
        mainLevelId: this.mainLevelId,
        topicId: this.topicData.id || '',
        updatedName: this.editingFormControl.value,
      });
    }
    this.editingFormControl = undefined;
  }

  handleCancel() {
    this.editingFormControl = undefined;
  }

  topicMenuProvider = (): IMenuItem[] => [
    { id: 'edit', label: 'Edit', onClick: () => this.handleEditTopic() },
    {
      id: 'active-deactive',
      label: this.topicData.deactivated ? 'Activate' : 'Deactivate',
      onClick: () =>
        this.onDeactivateTopic.emit({
          mainLevelId: this.mainLevelId,
          topicId: this.topicData.id || '',
          deactivated: !this.topicData.deactivated,
        }),
    },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () =>
        this.onDeleteTopic.emit({
          mainLevelId: this.mainLevelId,
          topicId: this.topicData.id || '',
          topicName: this.topicData.name || 'topic',
        }),
    },
  ];
}
