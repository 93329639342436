import { Injectable } from '@angular/core';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import { Subscription } from 'rxjs';
import {
  CalculationType,
  CurrencyCodes,
  IndicatorCategory,
  IndicatorTableValueDefinition,
  IndicatorValueType,
  RecordDuration,
  SectorType,
} from 'src/api-client/report-api.generated';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IndicatorDataSourceApiService } from 'src/app/api-client/report-api/indicator-data-source-api-service';
import { ObjectArray, toHashTableBy } from 'src/app/shared/utils/object-array';
import { CompatibleDataSource } from './indicator-properties/indicator-properties.component';

export interface IndicatorUnitUi {
  key: string;
  name: string;
  label: string;
  description: string;
}

export enum IndicatorTab {
  Performance = 'Performance',
  Data = 'Data',
  Properties = 'Properties',
}

export interface IndicatorDetailsUi {
  id: string;
  name: string;
  indicatorDefinitionId: string;
}

export interface IndicatorDefinitionProperties {
  valueType: IndicatorValueType;
  recordDuration: RecordDuration;
  description: string;
  standards: string;
  unit: string;
  unitName: string;
  numericCalculationType?: CalculationType;
  category: IndicatorCategory;
  sectors: SectorType[];
  rawDataSources: string[];
  indicatorDefinitionDataSources: string[];
}

@Injectable()
export class IndicatorDetailsStateService {
  readonly IndicatorTabEnum = IndicatorTab;

  private unitOptionsSubscription?: Subscription;
  indicator?: IndicatorDetailsUi;
  indicatorDefinitonProperties?: IndicatorDefinitionProperties;
  indicatorUnit?: IndicatorUnitUi;
  indicatorTableDefinition?: IndicatorTableValueDefinition;
  organizationCurrency?: CurrencyCodes;
  tabs: ITabItem[] = [
    { value: IndicatorTab.Performance, label: 'Performance' },
    { value: IndicatorTab.Data, label: 'Data' },
    { value: IndicatorTab.Properties, label: 'Properties' },
  ];
  activeTab = this.tabs[0].value;
  loading: boolean = false;
  compatibleRawDataSources: ObjectArray<CompatibleDataSource> = {};
  compatibleIndicatorDefinitionDataSources: ObjectArray<CompatibleDataSource> = {};

  constructor(
    private indicatorApiService: IndicatorApiService,
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private indicatorDataSourceClientApiService: IndicatorDataSourceApiService,
    private unitService: UnitService,
    private notificationService: NotificationService
  ) {}

  async init(indicatorId: string) {
    try {
      this.loading = true;
      await this.getIndicatorDetails(indicatorId);
      await Promise.all([
        this.getIndicatorDefinitionData(),
        this.getCompatibleRawDataSources(),
        this.getCompatibleIndicatorDefinitionDataSources(),
      ]);
    } catch (error) {
      this.notificationService.showError('Unable to load indicator details');
    } finally {
      this.loading = false;
    }
  }

  async getIndicatorDetails(indicatorId: string) {
    const response = await this.indicatorApiService.getIndicatorById(indicatorId);

    this.indicator = {
      id: response.result.id,
      name: response.result.name,
      indicatorDefinitionId: response.result.indicatorDefinitionId,
    };
  }

  async getCompatibleRawDataSources() {
    if (this.indicator) {
      const response =
        await this.indicatorDataSourceClientApiService.getRawDataSourcesCompatibleWithIndicatorDefinition(
          this.indicator.indicatorDefinitionId
        );
      this.compatibleRawDataSources = toHashTableBy(response.result, 'id');
    }
  }

  async getCompatibleIndicatorDefinitionDataSources() {
    if (this.indicator) {
      const response =
        await this.indicatorDataSourceClientApiService.getIndicatorDefinitionDataSourcesCompatibleWithIndicatorDefinition(
          this.indicator.indicatorDefinitionId
        );
      this.compatibleIndicatorDefinitionDataSources = toHashTableBy(response.result, 'id');
    }
  }

  async getIndicatorDefinitionData() {
    if (this.indicator) {
      const definitionResponse = await this.indicatorDefinitionsApiService.getById(
        this.indicator.indicatorDefinitionId
      );

      this.indicatorDefinitonProperties = {
        valueType: definitionResponse.result.valueType,
        recordDuration: definitionResponse.result.recordDuration,
        description: definitionResponse.result.description,
        standards: definitionResponse.result.frameworkStandards,
        unit: definitionResponse.result.numericValueDefinition?.unit || '',
        unitName: definitionResponse.result.numericValueDefinition?.unit || '',
        numericCalculationType: definitionResponse.result.numericValueDefinition?.calculationType,
        category: definitionResponse.result.category,
        sectors: definitionResponse.result.sectors,
        rawDataSources: definitionResponse.result.indicatorRawDataSourceIds,
        indicatorDefinitionDataSources: definitionResponse.result.indicatorDefinitionAsDataSourceIds,
      };

      this.indicatorTableDefinition = definitionResponse.result.tableValueDefinition;
      this.indicatorUnit = {
        key: definitionResponse.result.numericValueDefinition?.unit || '',
        name: definitionResponse.result.numericValueDefinition?.unit || '',
        label: definitionResponse.result.numericValueDefinition?.unitLabel || '',
        description: definitionResponse.result.numericValueDefinition?.unit || '',
      };

      this.unitOptionsSubscription = this.unitService.units$.subscribe(units => {
        const unit = Object.values(units).find(unit => unit.key === this.indicatorUnit?.key);
        if (unit) {
          if (this.indicatorUnit) {
            this.indicatorUnit.name = unit.name;
            this.indicatorUnit.description = unit.description;
          }
          if (this.indicatorDefinitonProperties) {
            this.indicatorDefinitonProperties.unitName = unit.name;
          }
        }
        if (this.organizationCurrency) this.setOrganizationCurrency(this.organizationCurrency);
      });
    }
  }

  setOrganizationCurrency(currency: CurrencyCodes) {
    this.organizationCurrency = currency;
    if (this.indicatorUnit?.key === 'Currency') {
      this.indicatorUnit.name = currency;
      this.indicatorUnit.description = currency;
    }
  }

  setActiveTab(tabValue: string) {
    this.activeTab = tabValue;
  }

  ngOnDestroy() {
    this.unitOptionsSubscription?.unsubscribe();
  }
}
