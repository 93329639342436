import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  CreateRegionCommandParams,
  CreateRegionCommandResponse,
  DeleteRegionCommandResponse,
  GetAllNamesRegionsQueryResponse,
  GetListRegionsQueryResponse,
  RegionClient,
  UpdateRegionCommandParams,
  UpdateRegionCommandResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class RegionClientApiService {
  client: RegionClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new RegionClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async create(body: CreateRegionCommandParams): Promise<CreateRegionCommandResponse> {
    const observable$ = this.client.createRegion(body);
    return await lastValueFrom(observable$);
  }

  async update(body: UpdateRegionCommandParams): Promise<UpdateRegionCommandResponse> {
    const observable$ = this.client.updateRegion(body);
    return await lastValueFrom(observable$);
  }

  async delete(id: string): Promise<DeleteRegionCommandResponse> {
    const observable$ = this.client.deleteRegion(id);
    return await lastValueFrom(observable$);
  }

  async getAllRegionNames(): Promise<GetAllNamesRegionsQueryResponse> {
    const observable$ = this.client.getAllNamesRegions();
    return await lastValueFrom(observable$);
  }
  async getAllRegions(): Promise<GetListRegionsQueryResponse> {
    const observable$ = this.client.getListRegions();
    return await lastValueFrom(observable$);
  }
}
