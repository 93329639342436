import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-suborg-icon',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3603 9.4298C11.3603 10.03 11.5403 10.6167 11.8775 11.1157C12.2147 11.6147 12.694 12.0036 13.2547 12.2333C13.8154 12.463 14.4325 12.5231 15.0277 12.406C15.623 12.2889 16.1698 11.9999 16.599 11.5755C17.0282 11.1511 17.3204 10.6104 17.4388 10.0218C17.5572 9.43317 17.4965 8.82303 17.2642 8.26855C17.0319 7.71406 16.6386 7.24014 16.134 6.9067C15.6293 6.57326 15.036 6.39529 14.4291 6.39529C13.6154 6.39617 12.8354 6.71615 12.2601 7.28504C11.6848 7.85394 11.3612 8.62527 11.3603 9.4298ZM17.0976 9.4298C17.0976 9.95161 16.9411 10.4617 16.6479 10.8956C16.3548 11.3294 15.9381 11.6676 15.4506 11.8673C14.963 12.067 14.4266 12.1192 13.909 12.0174C13.3915 11.9156 12.9161 11.6643 12.5429 11.2954C12.1698 10.9264 11.9157 10.4563 11.8127 9.94451C11.7098 9.43273 11.7626 8.90226 11.9646 8.42017C12.1665 7.93809 12.5085 7.52604 12.9472 7.23614C13.386 6.94624 13.9018 6.79151 14.4295 6.79151C15.1369 6.79238 15.815 7.07062 16.3152 7.56521C16.8153 8.0598 17.0967 8.73035 17.0976 9.4298Z"
        fill="black" />
      <path
        d="M17.4978 18.4655C17.4978 17.8653 17.3178 17.2786 16.9806 16.7796C16.6434 16.2806 16.1642 15.8916 15.6034 15.662C15.0427 15.4323 14.4257 15.3722 13.8304 15.4893C13.2351 15.6064 12.6883 15.8954 12.2591 16.3198C11.83 16.7441 11.5377 17.2848 11.4193 17.8735C11.3009 18.4621 11.3617 19.0723 11.5939 19.6267C11.8262 20.1812 12.2195 20.6551 12.7242 20.9886C13.2288 21.322 13.8221 21.5 14.4291 21.5C15.2427 21.4991 16.0227 21.1791 16.598 20.6102C17.1733 20.0413 17.4969 19.27 17.4978 18.4655ZM11.761 18.4655C11.761 17.9437 11.9175 17.4336 12.2107 16.9997C12.5038 16.5659 12.9205 16.2277 13.408 16.028C13.8956 15.8283 14.432 15.7761 14.9496 15.8779C15.4671 15.9797 15.9425 16.231 16.3157 16.5999C16.6888 16.9689 16.9429 17.439 17.0458 17.9508C17.1488 18.4626 17.096 18.993 16.894 19.4751C16.6921 19.9572 16.3501 20.3692 15.9113 20.6591C15.4726 20.949 14.9567 21.1038 14.4291 21.1038C13.7218 21.1028 13.0437 20.8245 12.5436 20.3299C12.0434 19.8354 11.762 19.1649 11.761 18.4655Z"
        fill="black" />
      <path
        d="M8.6627 18.2676C7.68982 18.2676 6.89988 17.4168 6.89988 16.3709V5.79605H6.50015V16.3709C6.50015 17.6349 7.47113 18.6629 8.66509 18.6629H10.6294V18.2676H8.6627Z"
        fill="black" />
      <path
        d="M8.66255 9.24319C7.68966 9.24319 6.89973 8.39281 6.89973 7.34692V2.5H6.5V7.34692C6.5 8.61047 7.47098 9.63894 8.66493 9.63894H10.6293V9.24319H8.66255Z"
        fill="black" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class SuborgIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
