import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface AssetVersionUi {
  id: string;
  value: Date;
}

@Component({
  selector: 'esg-asset-version-menu',
  templateUrl: './asset-version-menu.component.html',
  styleUrls: ['./asset-version-menu.component.scss'],
})
export class AssetVersionMenuComponent {
  @Input({ required: true }) activeVersion!: string;
  @Input({ required: true }) assetVersions!: AssetVersionUi[];
  @Input() newVersion?: Date;
  @Output() onVersionClick = new EventEmitter<string>();
  @Output() onNewVersionClick = new EventEmitter();

  handleOnVersionClick(version: string) {
    if (this.activeVersion !== version) {
      this.onVersionClick.emit(version);
    }
  }

  handleOnNewVersionClick(event: Event) {
    event.stopPropagation();
    this.onNewVersionClick.emit();
  }
}
