import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';
import { UserRole } from 'src/api-client/report-api.generated';
import { AppInfoService } from 'src/app/core/app-info.service';

@Injectable({
  providedIn: 'root',
})
export class SysAdminService {
  constructor(private router: Router, private appInfoState: AppInfoService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.appInfoState.userInfo$.pipe(
      filter(userInfo => !!userInfo),
      take(1),
      map(userInfo => {
        if (userInfo && userInfo.role === UserRole.SuperAdmin) {
          return true;
        } else {
          this.router.navigate(['/home']);
          return false;
        }
      })
    );
  }
}

export const SuperAdminGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const permissionsService = new SysAdminService(inject(Router), inject(AppInfoService));
  return permissionsService.canActivate();
};
