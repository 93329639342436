import { Component, OnInit } from '@angular/core';
import { AppInfoService } from 'src/app/core/app-info.service';
import { AdminPageStateService } from './admin-page-state.service';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Component({
  selector: 'esg-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
  providers: [UnitService],
})
export class AdminPageComponent implements OnInit {
  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(
    public appState: AppInfoService,
    public state: AdminPageStateService,
    private appConfig: AppConfigurationService
  ) {}

  ngOnInit(): void {}
}
