import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { TopicItemStatus } from 'src/api-client/report-api.generated';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IOption, topicItemStatusOptions } from 'src/app/static-data/options';
import { TopicDetailsStateService } from '../topic-details-state.service';

export interface TopicItemUi {
  id: string;
  name: string;
  status: TopicItemStatus;
  visible: boolean;
  responsiblePerson?: IOption;
  modifiedDate?: Date;
}

@Component({
  selector: 'esg-topic-item-list',
  templateUrl: './topic-item-list.component.html',
  styleUrls: ['./topic-item-list.component.scss'],
})
export class TopicItemListComponent implements OnInit {
  @Output() onTopicItemClick = new EventEmitter<string>();

  statusOptions: IOption[] = topicItemStatusOptions;

  constructor(public state: TopicDetailsStateService) {}

  ngOnInit(): void {}

  drop(event: CdkDragDrop<any[]>) {
    if (this.state.topicTopicItems) {
      moveItemInArray(this.state.topicTopicItems, event.previousIndex, event.currentIndex);

      this.state.moveTopicItem(
        this.state.topicTopicItems[event.currentIndex].id || '',
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  handleTopicItemClick(topicItemId: string | undefined) {
    if (topicItemId) {
      this.onTopicItemClick.emit(topicItemId);
    }
  }

  getInitialStatus(status: TopicItemStatus | undefined) {
    return this.statusOptions.find(option => option.value === status);
  }

  updateStatus(topicItemId: string | undefined, status: IOption) {
    const topicItemStatus = Object.values(TopicItemStatus).find(s => s === status.value);
    topicItemId && topicItemStatus && this.state.updateStatus(topicItemId, <TopicItemStatus>status.value);
  }

  updateVisibility(topicItemId: string, visible: boolean) {
    this.state.updateVisibility(topicItemId, visible);
  }

  updateResponsiblePerson(topicItemId: string, responsiblePerson: IOption) {
    this.state.updateResponsiblePerson(topicItemId, responsiblePerson);
  }
}
