import { Component } from '@angular/core';
import { AssetTableComponent } from '../../../shared/asset-table/asset-table.component';
import { IOption, vesselTypeOptions } from 'src/app/static-data/options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetUi } from '../../asset-list.component';
import { AssetType, CreateVesselAssetCommandParams, VesselType } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-vessels-table',
  templateUrl: './vessels-table.component.html',
  styleUrls: ['../../../shared/asset-table/asset-table.component.scss', './vessels-table.component.scss'],
  providers: [{ provide: AssetTableComponent, useExisting: VesselsTableComponent }],
})
export class VesselsTableComponent extends AssetTableComponent {
  headers = ['VESSEL', 'VESSEL TYPE', 'ORGANIZATION'];
  typeOptions: IOption[] = vesselTypeOptions;

  initateAdd(event?: Event) {
    event?.stopPropagation();
    this.fc = {
      name: new FormControl<string>('', [Validators.required]),
      type: new FormControl<IOption | undefined>(this.typeOptions[0], [Validators.required]),
      organization: new FormControl<IOption | undefined>(
        {
          value: this.defaultOrganization,
          disabled: this.defaultOrganization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  iniateEdit(asset: AssetUi) {
    if (this.fc) return;

    this.onSelectedAssetChange.emit(asset);

    const organization =
      this.organizationOptions.find(org => org.label === asset.organizationName) || this.defaultOrganization;
    this.fc = {
      name: new FormControl<string>(asset.name, [Validators.required]),
      type: new FormControl<IOption | undefined>(
        this.typeOptions.find(type => type.label === asset.type || type.value === asset.type) || this.typeOptions[0],
        [Validators.required]
      ),
      organization: new FormControl<IOption | undefined>(
        {
          value: organization,
          disabled: organization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  handleOnFormSubmit() {
    const type = Object.values(VesselType).find(type => type === this.fc?.type?.value?.value);
    if (this.fc && this.formgroup?.valid && type) {
      const subOrganizationId =
        this.organizationId === this.fc.organization.value?.value ? undefined : this.fc.organization.value?.value;
      const params = new CreateVesselAssetCommandParams({
        type: AssetType.Vessel,
        vesselType: type,
        name: this.fc.name.value || '',
        organizationId: this.organizationId || '',
        subOrganizationId: subOrganizationId,
        installedPower: 0,
        scrubbersInstalled: false,
        versionValidFrom: new Date(),
      });
      this.onFormSubmit.emit(params);
      this.resetForm();
    } else this.handleSubmitRejection();
  }
}
