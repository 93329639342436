<div esg-on-click-outside (onClickOutside)="handleClickOutside()">
  <label *ngIf="label" class="input-label">
    {{ label }}
    <span *ngIf="required">*</span>
  </label>
  <div class="content-container" (click)="handleOpen()">
    <esg-select-field [value]="value?.label" [variant]="variant" [size]="size" [placeholder]="placeholder"
      [isCollapsed]="!open" [disabled]="disabled" (onToggleCollapse)="handleOpen()"></esg-select-field>
  </div>
  @if(open){
  <div class="options-container">
    <div class="options">
      <esg-select-tree-options [options]="options" [value]="value" (onSelectOption)="handleSelectOption($event)">
      </esg-select-tree-options>
    </div>
  </div>
  }
</div>