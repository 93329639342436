import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { stringToFormattedNumberString, stringToNumberString } from 'src/app/shared/utils/number-converters';

interface Style {
  [key: string]: string;
}

@Component({
  selector: 'esg-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent implements OnInit {
  @Input() value: string = '';
  @Input() type: 'text' | 'number' | 'color' | 'digit' = 'text';
  @Input() variant: 'standard' | 'ghost' | 'ghost-table' | 'icon-box' = 'standard';
  @Input() size: 'medium' | 'small' | 'large' = 'medium';
  @Input() label = '';
  @Input() placeholder = 'Type...';
  @Input() errorMessage: string = '';
  @Input() autoFocus = false;
  @Input() showClearText = false;
  @Input() showOptionalText = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() valid?: boolean = true;
  @Input() inputStyle: Style = {};
  @Input() maxLength: null | number = null;
  @Input() maxDec: number = 3;
  @Input() showPercent = false;
  @Output() onEnter = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<string>();
  inputEl: HTMLInputElement | unknown;
  showValidation: boolean = false;
  initialValue: string = '';

  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    this.inputEl = element?.nativeElement;

    if (this.inputEl && this.autoFocus) {
      (this.inputEl as HTMLInputElement).focus();
    }
  }

  get getValue() {
    return this.value;
  }

  get isRequired() {
    return this.required;
  }

  get isValid() {
    return this.valid;
  }

  get isDisabled() {
    return this.disabled;
  }

  constructor() {}

  ngOnInit(): void {
    if (!this.isRequired && !this.label && this.variant === 'standard')
      this.placeholder = this.placeholder + ' (Optional)';
    this.setInputTypeSettings();
    this.initialValue = this.getValue;
  }

  handleSubmit() {
    this.showValidation = true;
    if (this.onEnter.observed) {
      this.onEnter.emit(this.getValue);
    }
    if (this.type === 'number') this.setValue(stringToFormattedNumberString(this.getValue, 0, this.maxDec));
    this.initialValue = this.getValue;
  }

  handleEnter() {
    this.handleSubmit();
    if (this.inputEl) (this.inputEl as HTMLInputElement).blur();
  }

  handleEscape() {
    this.resetValue();
    if (this.inputEl) (this.inputEl as HTMLInputElement).blur();
  }

  handleOnChange(value: string) {
    value = this.format_input(value);
    this.setValue(value);
    this.onChange.emit(value);
    if (value.length === this.maxLength) this.handleSubmit();
  }

  handleClearText(event: Event) {
    event.stopPropagation();
    this.showValidation = false;
    this.setValue('');
    this.onChange.emit('');
  }

  format_input(value: string) {
    switch (this.type) {
      case 'number':
        return stringToNumberString(value);
      case 'color':
        if (!value || value.substring(0, 1) === '#') return value;
        else return '#' + value.substring(0, 6);
      case 'digit':
        return value.replace(/[^0-9]/g, '');
      default:
        return value;
    }
  }

  setInputTypeSettings() {
    switch (this.type) {
      case 'number':
        !isNaN(Number(this.getValue)) &&
          this.setValue(stringToFormattedNumberString(this.getValue || '', 0, this.maxDec));
        break;
      case 'color':
        this.maxLength = 7;
        break;
    }
  }

  setValue(value: string) {
    this.value = value;
    if (this.inputEl instanceof HTMLInputElement) {
      this.inputEl.value = value;
    }
  }
  resetValue() {
    this.setValue(this.initialValue);
  }
}
