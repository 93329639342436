import { Component, EventEmitter, Input, Output } from '@angular/core';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';

export interface AssetCalculatedDataUi {
  name: string;
  value: number;
}

export interface SubstrateDataUi {
  groupName: string;
  numberOfAssets: number;
  valueTotal: number;
  expanded: boolean;
  assets: AssetCalculatedDataUi[];
}

export interface NumericCalculatedDataUi {
  periodLabel: string;
  period: number;
  year: number;
  number: number;
  value?: number;
  targetValue?: number;
  performanceToTarget?: number;
  errorMessage?: string;
  substrateDataIsLoading?: boolean;
  substrateData?: SubstrateDataUi[];
}

@Component({
  selector: 'esg-numeric-calculated-data [expandSubstrateData] [expandSubstrateGroupData]',
  templateUrl: './numeric-calculated-data.component.html',
  styleUrl: './numeric-calculated-data.component.scss',
})
export class NumericCalculatedDataComponent {
  @Input({ required: true }) data!: NumericCalculatedDataUi[];
  @Input() unit: string = '';
  @Input() isAnnual: boolean = true;
  @Output() expandSubstrateData = new EventEmitter<number>();
  @Output() expandSubstrateGroupData = new EventEmitter<{ index: number; groupIndex: number }>();

  getFormattedNumber(value: number | undefined): string {
    if (value === undefined || value === null || isNaN(value)) return '';
    else return numberToFormattedNumberString(Math.abs(value), 0, Math.abs(value) < 100 ? 2 : 0);
  }

  handleExpand(index: number) {
    this.expandSubstrateData.emit(index);
  }

  handleExpandGroup(index: number, groupIndex: number) {
    this.expandSubstrateGroupData.emit({ index, groupIndex });
  }
}
