import { TableModule } from './../../shared/table/table.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExampleComponent } from './example.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { ContentModule } from 'src/app/content/content.module';
import { DirectiveModule } from 'src/app/shared/directives/directive.module';
import { UiComponentsComponent } from './ui-components/ui-components.component';
import { RouterModule } from '@angular/router';
import { ExamplesComponent } from './examples/examples.component';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { DataImportFromExcelExampleComponent } from './data-import-from-excel/data-import-from-excel.component';
import { SuperAdminGuard } from 'src/app/core/guards/super-admin-guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeedbackExamplesComponent } from './feedback-examples/feedback-examples.component';
import { NotificationModule } from 'src/app/shared/services/notification/notification.module';
import { SharedComponentsComponent } from './shared-components/shared-components.component';
import { OnboardingExampleComponent } from './onboarding-example/onboarding-example.component';
import { GenericTableExampleComponent } from './generic-table-example/generic-table-example.component';

@NgModule({
  declarations: [
    ExampleComponent,
    UiComponentsComponent,
    ExamplesComponent,
    DataImportFromExcelExampleComponent,
    FeedbackExamplesComponent,
    SharedComponentsComponent,
    OnboardingExampleComponent,
    GenericTableExampleComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    UiModule,
    ContentModule,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    NotificationModule,
    RouterModule.forChild([
      {
        path: 'example',
        component: ExampleComponent,
        children: [
          { path: '', component: ExamplesComponent },
          { path: 'ui-components', component: UiComponentsComponent },
          { path: 'data-import-from-excel', component: DataImportFromExcelExampleComponent },
          { path: 'feedback-examples', component: FeedbackExamplesComponent },
          { path: 'shared-components', component: SharedComponentsComponent },
          { path: 'onboarding', component: OnboardingExampleComponent },
          { path: 'generic-table', component: GenericTableExampleComponent },
        ],
        canActivate: [SuperAdminGuard],
      },
    ]),
  ],
})
export class ExampleModule {}
