import { Injectable } from '@angular/core';
import {
  DataRecordType,
  AssetType,
  EmployeeCommutingMode,
  DataRecordCategory,
} from 'src/api-client/report-api.generated';
import { IOption, CommutingTransportOptions, CommutingModeByTransport } from 'src/app/static-data/options';
import { RecordsStateService } from '../../../records/records-state.service';

@Injectable()
export class EmployeeCommutingDetailsStateService extends RecordsStateService {
  recordCategory = DataRecordCategory.IndirectEmissionsEmployeeCommuting;

  profileId?: string;

  assetCategories = [AssetType.Facility];
  commutingTransportOptions: IOption[] = CommutingTransportOptions;
  commutingModeOptions: IOption[] = CommutingModeByTransport[EmployeeCommutingMode.Car];

  get intersectionObserverRootMargin() {
    if (this.appConfig.NewNavbarEnabled) {
      return 136;
    } else return 181;
  }

  setProfileId(profileId: string) {
    this.profileId = profileId;
  }

  async loadRecords() {
    await this.getDataRecords(DataRecordType.EmployeeCommuting, this.profileId);
  }

  handleDeleteAllFilteredRecordsSubmit() {
    this.handleOnDeleteAllFilteredRecords(DataRecordType.EmployeeCommuting, this.profileId);
  }
}
