import { Component, Input } from '@angular/core';
import { Trend } from 'src/api-client/report-api.generated';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';

export interface NumericCalculatedSummaryUi {
  value?: number;
  targetValue?: number;
  performanceToTarget?: number;
  targetTrend: Trend;
}

@Component({
  selector: 'esg-numeric-calculated-summary',
  templateUrl: './numeric-calculated-summary.component.html',
  styleUrl: './numeric-calculated-summary.component.scss',
})
export class NumericCalculatedSummaryComponent {
  @Input({ required: true }) summary!: NumericCalculatedSummaryUi;
  @Input() unit: string = '';
  @Input() yearLabel: string = '';
  @Input() isAverage: boolean = false;

  getFormattedNumber(value: number | undefined): string {
    if (value === undefined || value === null || isNaN(value)) return '';
    else return numberToFormattedNumberString(Math.abs(value), 0, Math.abs(value) < 100 ? 2 : 0);
  }

  get isAboveTarget() {
    if (!this.summary || !this.summary.value || !this.summary.targetValue) return true;
    return this.summary.value >= this.summary.targetValue;
  }
}
