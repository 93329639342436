<esg-records-table
  [headerCells]="headerCells"
  [bodyRows]="dataRecordsRows"
  [newlyAddedRowsIds]="newlyAddedRowsIds"
  [readOnly]="readOnly"
  [addOnly]="addOnly"
  [isAdding]="recordFormgroup !== undefined && !editRecordId"
  [editRecordId]="editRecordId"
  [stickyHeaderTopOffset]="stickyHeaderTopOffset"
  [intersectionObserverRootMargin]="intersectionObserverRootMargin"
  [stickyHeaderPadding]="stickyHeaderPadding"
  (onAdding)="handleOnIniateAddRecord()"
  (onFormClose)="handleCloseForm()"
  (onEditing)="handleOnInitateEditRecord($event)"
  (onSubmit)="handleOnSubmit()"
  (onDelete)="handleOnDelete($event)">
  <ng-container *ngIf="recordFc">
    <td class="datepicker-cell" [style.grid-column]="'span 2'" [class.focus]="startDateOpen">
      <esg-datepicker
        *ngIf="recordFc.startDate"
        class="input-text"
        [fc]="recordFc.startDate"
        variant="ghost-table"
        [inputIndent]="15"
        [autoFocus]="startDateOpen"
        (onOpen)="startDateOpen = true"
        (onClose)="onStartDateClose()"></esg-datepicker>
    </td>
    <td class="input-cell" [class.disabled]="isAssetsDisabled">
      <esg-table-select
        *ngIf="recordFc.asset"
        class="input-dropdown"
        [isPopup]="addOnly"
        [groupedOptions]="assetOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.asset.value || undefined"
        [disabled]="isAssetsDisabled"
        (onChange)="handleDropdownChange(recordFc.asset, $event)"></esg-table-select>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.spillsType.valid">
      <esg-table-select
        *ngIf="recordFc.spillsType"
        class="input-dropdown"
        [isPopup]="addOnly"
        [options]="spillsOptions"
        [value]="recordFc.spillsType.value || undefined"
        (onChange)="handleDropdownChange(recordFc.spillsType, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-table-select
        *ngIf="recordFc.isHazardous"
        class="input-dropdown"
        [isPopup]="addOnly"
        [options]="hazardiousOptions"
        [value]="recordFc.isHazardous.value || undefined"
        (onChange)="handleDropdownChange(recordFc.isHazardous, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.volume_m3"
        class="input-text"
        type="number"
        placeholder="Cubic metres"
        [fc]="recordFc.volume_m3"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.volume_m3.setValue($event)"></esg-textbox-form>
    </td>
  </ng-container>
</esg-records-table>
