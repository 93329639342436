<esg-records-table
  [headerCells]="headerCells"
  [bodyRows]="dataRecordsRows"
  [newlyAddedRowsIds]="newlyAddedRowsIds"
  [columnFilter]="useColumnFilter ? columnFilter : undefined"
  [readOnly]="readOnly"
  [addOnly]="addOnly"
  [isAdding]="recordFormgroup !== undefined && !editRecordId"
  [editRecordId]="editRecordId"
  [stickyHeaderTopOffset]="stickyHeaderTopOffset"
  [intersectionObserverRootMargin]="intersectionObserverRootMargin"
  [stickyHeaderPadding]="stickyHeaderPadding"
  (onAdding)="handleOnIniateAddRecord()"
  (onFormClose)="handleCloseForm()"
  (onEditing)="handleOnInitateEditRecord($event)"
  (onSubmit)="handleOnSubmit()"
  (onDelete)="handleOnDelete($event)">
  <ng-container *ngIf="recordFc">
    <td class="datepicker-cell" [class.focus]="startDateOpen">
      <esg-datepicker
        *ngIf="recordFc.startDate"
        class="input-text"
        [fc]="recordFc.startDate"
        variant="ghost-table"
        [maxDate]="recordFc.endDate.value || undefined"
        [inputIndent]="15"
        [autoFocus]="startDateOpen"
        (onOpen)="startDateOpen = true"
        (onClose)="onStartDateClose()"></esg-datepicker>
    </td>
    <td class="datepicker-cell" [class.focus]="endDateOpen">
      <span class="date-divider" [class.focus]="startDateOpen"><span class="triangle"></span></span>
      <esg-datepicker
        *ngIf="recordFc.endDate"
        class="input-text"
        [fc]="recordFc.endDate"
        variant="ghost-table"
        [minDate]="recordFc.startDate.value || undefined"
        [inputIndent]="13"
        [autoFocus]="endDateOpen"
        (onOpen)="endDateOpen = true"
        (onClose)="endDateOpen = false"></esg-datepicker>
    </td>
    <td class="input-cell" [class.disabled]="isAssetsDisabled">
      <esg-table-select
        *ngIf="recordFc.asset"
        class="input-dropdown"
        [groupedOptions]="assetOptions"
        [isPopup]="addOnly"
        [fitLongestLabel]="false"
        [value]="recordFc.asset.value || undefined"
        [disabled]="isAssetsDisabled"
        (onChange)="handleDropdownChange(recordFc.asset, $event)"></esg-table-select>
    </td>
    <td *ngIf="isColumnVisible('inOperation_hrs')" class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.inOperation_hrs"
        class="input-text"
        type="number"
        placeholder="Hours"
        [fc]="recordFc.inOperation_hrs"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.inOperation_hrs.setValue($event)"></esg-textbox-form>
    </td>
    <td *ngIf="isColumnVisible('distanceSailed_nm')" class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.distanceSailed_nm"
        class="input-text"
        type="number"
        placeholder="Nautical Miles"
        [fc]="recordFc.distanceSailed_nm"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.distanceSailed_nm.setValue($event)"></esg-textbox-form>
    </td>
    <td *ngIf="isColumnVisible('mgo')" class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.mgo"
        class="input-text"
        type="number"
        placeholder="Quantity"
        [fc]="recordFc.mgo"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.mgo.setValue($event)"></esg-textbox-form>
      <esg-table-select
        *ngIf="recordFc.mgo_unit"
        class="input-dropdown"
        [isPopup]="addOnly"
        [style.width.px]="80"
        [options]="unitOptions"
        [value]="recordFc.mgo_unit.value || undefined"
        (onChange)="handleDropdownChange(recordFc.mgo_unit, $event)"></esg-table-select>
    </td>
    <td *ngIf="isColumnVisible('hfo')" class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.hfo"
        class="input-text"
        type="number"
        placeholder="Quantity"
        [fc]="recordFc.hfo"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.hfo.setValue($event)"></esg-textbox-form>
      <esg-table-select
        *ngIf="recordFc.hfo_unit"
        class="input-dropdown"
        [isPopup]="addOnly"
        [style.width.px]="80"
        [options]="unitOptions"
        [value]="recordFc.hfo_unit.value || undefined"
        (onChange)="handleDropdownChange(recordFc.hfo_unit, $event)"></esg-table-select>
    </td>
    <td *ngIf="isColumnVisible('lfo')" class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.lfo"
        class="input-text"
        type="number"
        placeholder="Quantity"
        [fc]="recordFc.lfo"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.lfo.setValue($event)"></esg-textbox-form>
      <esg-table-select
        *ngIf="recordFc.lfo_unit"
        class="input-dropdown"
        [isPopup]="addOnly"
        [style.width.px]="80"
        [options]="unitOptions"
        [value]="recordFc.lfo_unit.value || undefined"
        (onChange)="handleDropdownChange(recordFc.lfo_unit, $event)"></esg-table-select>
    </td>
    <td *ngIf="isColumnVisible('lng')" class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.lng"
        class="input-text"
        type="number"
        placeholder="Quantity"
        [fc]="recordFc.lng"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.lng.setValue($event)"></esg-textbox-form>
      <esg-table-select
        *ngIf="recordFc.lng_unit"
        class="input-dropdown"
        [isPopup]="addOnly"
        [style.width.px]="80"
        [options]="unitOptions"
        [value]="recordFc.lng_unit.value || undefined"
        (onChange)="handleDropdownChange(recordFc.lng_unit, $event)"></esg-table-select>
    </td>
    <td *ngIf="isColumnVisible('urea_l')" class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.urea_l"
        class="input-text"
        type="number"
        placeholder="Litres"
        [fc]="recordFc.urea_l"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.urea_l.setValue($event)"></esg-textbox-form>
    </td>
  </ng-container>
</esg-records-table>
