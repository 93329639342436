import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-arrow-down',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9973 10.5C8.11068 10.5 8.21326 10.4522 8.29424 10.3672L12.3866 6.12699C12.4568 6.05792 12.5 5.9729 12.5 5.86663C12.5 5.6594 12.338 5.5 12.1275 5.5C12.0303 5.5 11.9277 5.53719 11.8575 5.60627L8.0027 9.60202L4.13707 5.60627C4.07229 5.53719 3.9751 5.5 3.87253 5.5C3.66197 5.5 3.5 5.6594 3.5 5.86663C3.5 5.9729 3.54319 6.05792 3.61338 6.13231L7.70576 10.3725C7.79214 10.4522 7.88392 10.5 7.9973 10.5Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class ArrowDownComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
