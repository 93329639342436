<tr
  (click.single)="onOrganizationClick.emit(organization.id)"
  (click.double)="handleExpand($event)"
  class="organization-row"
  [class.active]="active">
  <td>
    <button (click)="handleExpand($event)" class="expand-button" [class.expanded]="expanded">
      <esg-organization-icon></esg-organization-icon>
    </button>
    <span class="name">
      <button class="add-button" (click)="handleOnAddClick($event)">
        <esg-icon-add color="white"></esg-icon-add>
      </button>
      {{ organization.name }}
    </span>
  </td>
  <td>{{ organization.countryName }}</td>
  <td>{{ organization.orgID }}</td>
  <td class="align-right">{{ organization.allSubOrganizationsCount || '-' }}</td>
  <td class="align-right">{{ organization.usersCount }}</td>
  <td class="align-right">
    <esg-table-button label="Details" (onClick)="onOrganizationClick.emit(organization.id)"></esg-table-button>
  </td>
</tr>
<ng-container *ngIf="expanded">
  <esg-sub-organization-form
    *ngIf="subOrgFc"
    [subOrgForm]="subOrgFc"
    [countryOptions]="countryOptions"
    (onSubmit)="handleOnSubOrgSubmit()"
    (onCancel)="handleOnSubOrgCancel()"></esg-sub-organization-form>
  <esg-sub-organization-row
    *ngFor="let sub of organization.subOrganizations"
    [organization]="sub"
    [countryOptions]="countryOptions"
    (onEditSubOrganization)="onEditSubOrganization.emit($event)"
    (onDeleteSubOrganization)="onDeleteSubOrganization.emit($event)"
    (onAddSubOrganization)="onAddSubOrganization.emit($event)"></esg-sub-organization-row>
</ng-container>
