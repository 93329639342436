import { PdfWritingSystem } from './../../api-client/report-api.generated';
import { TFontDictionary } from 'pdfmake/interfaces';
import { arrayBufferToBase64 } from './converters';

interface TypographyInfo {
  sTypoAscender: number;
  sTypoDescender: number;
  unitsPerEm: number;
}

export enum FontTypes {
  Regular = 'Regular',
  SemiBold = 'SemiBold',
  Light = 'Light',
}

// Values needed for calculating correct lineheight in pdfMake
const fontValues: Record<string, TypographyInfo> = {
  [FontTypes.Regular]: {
    sTypoAscender: 1024,
    sTypoDescender: -400,
    unitsPerEm: 1000,
  },
  [FontTypes.SemiBold]: {
    sTypoAscender: 1024,
    sTypoDescender: -400,
    unitsPerEm: 1000,
  },
  [FontTypes.Light]: {
    sTypoAscender: 1024,
    sTypoDescender: -400,
    unitsPerEm: 1000,
  },
};

export function fixLineHeight(lineHeight: number, font: string = 'Regular'): number {
  const fontTypographyInfo = fontValues[font];
  if (!fontTypographyInfo) {
    throw new Error('Missing Typography info for font: ' + font);
  }
  const { sTypoAscender, sTypoDescender, unitsPerEm } = fontTypographyInfo;
  const ascender = sTypoAscender / unitsPerEm;
  const descender = sTypoDescender / unitsPerEm;
  return lineHeight / (ascender - descender);
}

export async function getFontBase64(fontPath: string): Promise<{ [file: string]: string }> {
  const response = await fetch(fontPath);
  const fontData = await response.arrayBuffer();
  const base64Font = arrayBufferToBase64(fontData);
  const vfs: { [file: string]: string } = {
    [fontPath]: base64Font,
  };
  return vfs;
}

export const fontsFactory =
  (appBaseUrl: string) =>
  (pdfWritingSystem: PdfWritingSystem): TFontDictionary => {
    const availableFonts: { [key: string]: TFontDictionary } = {
      [PdfWritingSystem.English]: {
        [FontTypes.Regular]: {
          normal: `${appBaseUrl}/assets/fonts/SourceSans3-Regular.ttf`,
          bold: `${appBaseUrl}/assets/fonts/SourceSans3-Bold.ttf`,
          italics: `${appBaseUrl}/assets/fonts/SourceSans3-Italic.ttf`,
          bolditalics: `${appBaseUrl}/assets/fonts/SourceSans3-BoldItalic.ttf`,
        },
        [FontTypes.SemiBold]: {
          normal: `${appBaseUrl}/assets/fonts/SourceSans3-SemiBold.ttf`,
          bold: `${appBaseUrl}/assets/fonts/SourceSans3-SemiBold.ttf`,
          italics: `${appBaseUrl}/assets/fonts/SourceSans3-SemiBoldItalic.ttf`,
          bolditalics: `${appBaseUrl}/assets/fonts/SourceSans3-SemiBoldItalic.ttf`,
        },
        [FontTypes.Light]: {
          normal: `${appBaseUrl}/assets/fonts/SourceSans3-Light.ttf`,
          bold: `${appBaseUrl}/assets/fonts/SourceSans3-Light.ttf`,
          italics: `${appBaseUrl}/assets/fonts/SourceSans3-LightItalic.ttf`,
          bolditalics: `${appBaseUrl}/assets/fonts/SourceSans3-LightItalic.ttf`,
        },
      },
      [PdfWritingSystem.Chinese]: {
        [FontTypes.Regular]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansSC-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
        },
        [FontTypes.SemiBold]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansSC-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
        },
        [FontTypes.Light]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansSC-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansSC-Regular.otf`,
        },
      },
      [PdfWritingSystem.Japanese]: {
        [FontTypes.Regular]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansJP-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
        },
        [FontTypes.SemiBold]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansJP-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
        },
        [FontTypes.Light]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansJP-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansJP-Regular.otf`,
        },
      },
      [PdfWritingSystem.Korean]: {
        [FontTypes.Regular]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansKR-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
        },
        [FontTypes.SemiBold]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansKR-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
        },
        [FontTypes.Light]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansKR-Bold.otf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansKR-Regular.otf`,
        },
      },
      [PdfWritingSystem.Thai]: {
        [FontTypes.Regular]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansThai-Bold.ttf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
        },
        [FontTypes.SemiBold]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansThai-Bold.ttf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
        },
        [FontTypes.Light]: {
          normal: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
          bold: `${appBaseUrl}/assets/fonts/NotoSansThai-Bold.ttf`,
          italics: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
          bolditalics: `${appBaseUrl}/assets/fonts/NotoSansThai-Regular.ttf`,
        },
      },
    };

    return availableFonts[pdfWritingSystem] || availableFonts[PdfWritingSystem.English];
  };
