import { Component } from '@angular/core';
import { IndicatorDefinitionUi, IndicatorsLibraryStateService } from './indicators-library-state.service';

@Component({
  selector: 'esg-indicators-library',
  templateUrl: './indicators-library.component.html',
  styleUrls: ['./indicators-library.component.scss'],
  providers: [IndicatorsLibraryStateService],
})
export class IndicatorsLibraryComponent {
  stickyHeaders: boolean = false;

  constructor(public state: IndicatorsLibraryStateService) {}

  handleStickyHeaders(tabEntry: IntersectionObserverEntry) {
    this.stickyHeaders = tabEntry.intersectionRatio === 0;
  }

  trackByIndicator(index: number, indicator: IndicatorDefinitionUi) {
    return indicator.id;
  }

  handleAddIndicator(indicatorId: string) {
    this.state.addIndicatorToOrganization(indicatorId);
  }
}
