import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AppAuthService } from './core/app-auth.service';
import { AppInfoService } from './core/app-info.service';
import { AppConfigurationService } from './core/app-configuration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessTokenApiService } from './api-client/report-api/access-token-service';
import { RootPathOfTheSharePage } from './report-preview/report-preview.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  isIframe = false;
  loginDisplay = false;
  userInfoLoading = true;
  assignedToOrganization = false;
  loadInitUserInfoRequested = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appAuth: AppAuthService,
    private authService: MsalService,
    public appInfoState: AppInfoService,
    private msalBroadcastService: MsalBroadcastService,
    public settingsService: AppConfigurationService,
    private accessTokenApiService: AccessTokenApiService
  ) {}

  ngOnInit() {
    this.initUserAuthentication();
    this.initTokenAuthentication();
  }

  initTokenAuthentication() {
    this.route.queryParams.subscribe(async params2 => {
      if (params2['t']) {
        const response = await this.accessTokenApiService.validateToken(params2['t']);
        this.assignedToOrganization = true;
      }
    });
  }

  async initUserAuthentication() {
    this.authService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    await this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(async (result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          await this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        tap((status: InteractionStatus) => console.log('Interaction Status:', status)), // Log the interaction status
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        await this.setLoginDisplay();
        await this.checkAndSetActiveAccount();
      });
  }

  async setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    console.log('Login Display:', this.loginDisplay);
    if (this.loginDisplay) {
      this.appAuth.setUserAccount();
    }
  }

  async checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      activeAccount = accounts[0];
      this.authService.instance.setActiveAccount(activeAccount);
    }
    if (activeAccount) {
      var userInfoResponse = await this.appInfoState.loadInitUserInfo();
      this.assignedToOrganization = userInfoResponse.result.userInfo.organizationAssigned;
      this.userInfoLoading = false;
      console.log('Load Init User Info:', userInfoResponse);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
