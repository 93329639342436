import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  CreateUnitCommandParams,
  CreateUnitCommandResponse,
  DeleteUnitCommandResponse,
  GetAllUnitsQueryResponse,
  GetUnitByKeyQueryResponse,
  UnitClient,
  UpdateUnitCommandParams,
  UpdateUnitCommandResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class UnitApiService {
  client: UnitClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new UnitClient(httpClient, appSettings.ReportApiBaseUrl);
  }
  async createUnit(body: CreateUnitCommandParams): Promise<CreateUnitCommandResponse> {
    const observable$ = this.client.createUnit(body);
    return await lastValueFrom(observable$);
  }

  async updateUnit(body: UpdateUnitCommandParams): Promise<UpdateUnitCommandResponse> {
    const observable$ = this.client.updateUnit(body);
    return await lastValueFrom(observable$);
  }

  async deleteUnit(key: string): Promise<DeleteUnitCommandResponse> {
    const observable$ = this.client.deleteUnit(key);
    return await lastValueFrom(observable$);
  }

  async getAllUnits(): Promise<GetAllUnitsQueryResponse> {
    const observable$ = this.client.getAllUnits().pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getUnitByKey(key: string): Promise<GetUnitByKeyQueryResponse> {
    const observable$ = this.client.getUnitByKey(key).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }
}
