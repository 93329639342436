import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DynamicContent } from 'src/app/content/dynamic-content';
import { SupportArticleStateService } from './support-article-details-state.service';

export interface SupportArticleDetailsUi {
  id: string;
  name: string;
  components: DynamicContent[];
}

@Component({
  selector: 'esg-support-article-details',
  templateUrl: './support-article-details.component.html',
  styleUrls: ['./support-article-details.component.scss'],
  providers: [SupportArticleStateService],
})
export class SupportArticleDetailsComponent {
  @Input({ required: true }) selectedArticleId!: string;
  @Input() readMode: boolean = true;
  @Output() onClose = new EventEmitter();
  @Output() onReorder = new EventEmitter<boolean>();

  stickyComponentsToolbar: boolean = false;

  constructor(public hostRef: ElementRef, public state: SupportArticleStateService) {}

  ngOnInit(): void {
    this.state.init(this.selectedArticleId);
  }

  handleOnClose() {
    this.onClose.emit();
  }

  handleOnReorder(reordering: boolean) {
    this.state.onReorder(reordering);
    this.onReorder.emit(reordering);
  }

  handleStickyComponentMenu(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.rootBounds?.top) {
      this.stickyComponentsToolbar = tabEntry.boundingClientRect.top < tabEntry.rootBounds.top;
    } else this.stickyComponentsToolbar = false;
  }
}
