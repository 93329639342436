<esg-confirm-dialog *ngIf="removeReportId || removeTemplateId" [message]="removeMessage"
  (onCancel)="handleRemoveCancel()" (onSubmit)="handleRemoveSubmit()"></esg-confirm-dialog>

<section class="main">
  <div *ngIf="!state.isLoadingReports" class="create-report">
    <!-- <esg-add-button
      label="Create old report"
      variant="filled"
      size="standard"
      [disabled]="!hasCreateReportPermission"
      (onClick)="state.handleCreate(reportVersionEnum.V1)"></esg-add-button> -->

    <esg-add-button *ngIf="hasCreateReportPermission" label="Create new report" variant="filled" size="standard"
      (onClick)="state.handleCreate(reportVersionEnum.V2)"></esg-add-button>
  </div>

  <esg-tabs *ngIf="!state.isLoadingReports" [activeTab]="activeTab" [tabs]="tabs"
    (onTabClick)="handleOnTabClick($event)"></esg-tabs>

  <ng-container *ngIf="!state.isLoadingReports && activeTab === 'reports'">
    <div *ngIf="state.reports?.length" class="list-container">
      <div class="list">
        <ng-container *ngFor="let item of state.reports">
          <div *ngIf="!item.id" class="report-converted">
            <esg-dot-flashing label="Duplicate Report"></esg-dot-flashing>
          </div>
          <a *ngIf="item.id" [routerLink]="['/report', item.id]" class="list-item">
            <esg-report-card [title]="item.name || ''" [status]="getStatusColor(item.status)"
              [organizationName]="item.subOrganizationId ? item.subOrganizationName : item.organizationName"
              [lastModifiedData]="item.modifiedDate"
              [timePeriod]="item.timePeriod"
              [backgroundPictureUrl]="getCoverPhotoUrl(item.coverPhoto?.blobThumbnailName || item.coverPhoto?.blobName)"
              [oldVersion]="item.reportVersion === reportVersionEnum.V1"
              [showRemoveIcon]="hasRemoveReportPermission"
              (onDuplicate)="handleDuplicate(item.id || '')"
              (onRemove)="handleRemoveReport(item.id || '')"></esg-report-card>
          </a>
        </ng-container>
      </div>
    </div>

    <div *ngIf="state.reports && !state.reports.length" class="no-reports">
      <img src="/assets/images/report/no-reports.png" width="268.98" height="240"
        alt="Woman selecting report from report list" />
      <span class="no-data-text">
        {{ 'You have yet not created any reports.\n Please ' }}
        <span class="no-data-button" (click)="state.handleCreate(reportVersionEnum.V2)">create a new report</span>
        .
      </span>
    </div>
  </ng-container>

  <div *ngIf="activeTab === 'templates'" class="list-container">
    <div *ngIf="!state.isLoadingTemplates && state.templates.length" class="list">
      <div *ngFor="let item of state.templates" (click)="handleSelectTemplate(item.id)" class="list-item">
        <esg-template-card-view [title]="item.title || ''" [shortDescription]="item.shortDescription || ''"
          [backgroundPictureUrl]="getCoverPhotoUrl(item.coverPhoto.blobThumbnailName || item.coverPhoto.blobName)"
          (onRemove)="handleRemoveTemplate(item.id || '')"></esg-template-card-view>
      </div>
    </div>
    <div *ngIf="!state.isLoadingTemplates && !state.templates.length" class="no-templates">
      <img src="/assets/images/report/no-templates.png" width="135.08" height="200" alt="Woman holding refresh sign" />
      <span class="no-data-text">There are no custom templates in your organization yet.</span>
    </div>
    <esg-dot-flashing *ngIf="state.isLoadingTemplates" class="template-loader"
      label="We are gathering your templates"></esg-dot-flashing>
  </div>
  <esg-dot-flashing *ngIf="state.isLoadingReports" class="report-loader"
    label="We are gathering your reports"></esg-dot-flashing>
  <esg-gri-report-tip></esg-gri-report-tip>
</section>
<esg-report-form *ngIf="state.reportFormDialogOpen" [version]="state.reportVersion"
  (onClose)="state.handleCloseCreateDialog()" (onSubmit)="state.handleCloseCreateDialog()"></esg-report-form>
<esg-template-form *ngIf="selectedTemplateId" [templateId]="selectedTemplateId" (onClose)="handleCloseTemplateDialog()"
  (onSubmit)="handleOnEditTemplate()"></esg-template-form>