import { Component, Input } from '@angular/core';
import { IndicatorDefinitionProperties } from '../indicator-details-state.service';
import { CalculationType, IndicatorValueType, RecordDuration, SectorVm } from 'src/api-client/report-api.generated';
import { ObjectArray } from 'src/app/shared/utils/object-array';

export interface CompatibleDataSource {
  id: string;
  name: string;
}

@Component({
  selector: 'esg-indicator-properties',
  templateUrl: './indicator-properties.component.html',
  styleUrl: './indicator-properties.component.scss',
})
export class IndicatorPropertiesComponent {
  @Input({ required: true }) indicatorProperties!: IndicatorDefinitionProperties;
  @Input() sectorTypes: ObjectArray<SectorVm> = {};
  @Input() compatibleRawDataSources: ObjectArray<CompatibleDataSource> = {};
  @Input()
  compatibleIndicatorDefinitionDataSources: ObjectArray<CompatibleDataSource> = {};

  get valueType() {
    switch (this.indicatorProperties.valueType) {
      case IndicatorValueType.Numeric:
        return 'Single Value';
      case IndicatorValueType.Table:
        return 'Table Value';
      default:
        return '-';
    }
  }

  get recordDuration() {
    switch (this.indicatorProperties.recordDuration) {
      case RecordDuration.DateFromTo:
        return 'Date From-To';
      case RecordDuration.SingleDate:
        return 'Single Date';
      case RecordDuration.Period:
        return 'Period';
      default:
        return '-';
    }
  }

  get unit() {
    if (this.indicatorProperties.valueType !== IndicatorValueType.Numeric) return 'n/a';
    else return this.indicatorProperties.unitName;
  }

  get calculationType() {
    if (this.indicatorProperties.valueType !== IndicatorValueType.Numeric) return 'n/a';
    switch (this.indicatorProperties.numericCalculationType) {
      case CalculationType.Sum:
        return 'Summarize';
      case CalculationType.Average:
        return 'Average (Mean)';
      case CalculationType.LastValue:
        return 'Last value';
      default:
        return '-';
    }
  }

  get sectorsStringList(): string {
    if (this.indicatorProperties.sectors.length === 0) {
      return 'All';
    } else return this.indicatorProperties.sectors.map(sector => this.sectorTypes[sector]?.name || sector).join(', ');
  }

  get rawDataSourcesList(): string {
    if (this.indicatorProperties.rawDataSources.length === 0) {
      return '-';
    } else
      return this.indicatorProperties.rawDataSources
        .map(id => this.compatibleRawDataSources[id]?.name || id)
        .join(', ');
  }

  get indicatorDefinitionDataSourcesList(): string {
    if (this.indicatorProperties.indicatorDefinitionDataSources.length === 0) {
      return '-';
    } else
      return this.indicatorProperties.indicatorDefinitionDataSources
        .map(id => this.compatibleIndicatorDefinitionDataSources[id]?.name || id)
        .join(', ');
  }
}
