import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ITimePeriodData } from '../time-period/time-period.component';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimePeriodType } from 'src/api-client/report-api.generated';
import { globalMonthShortOptions, globalQuarterOptions } from 'src/app/static-data/options';

@Component({
  selector: 'esg-time-period-selector',
  templateUrl: './time-period-selector.component.html',
  styleUrl: './time-period-selector.component.scss',
  host: {
    '(focus)': 'handleFocus()',
    '(onEscapeKey)': 'handleOnCancel()',
    '(onEnterKey)': 'handleOnCancel()',
    '[tabindex]': 'disabled? -1 : tabindex',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePeriodSelectorComponent),
      multi: true,
    },
  ],
})
export class TimePeriodSelectorComponent implements ControlValueAccessor {
  @Input({ required: true }) formControl!: FormControl<ITimePeriodData | undefined>;
  @Input() variant: 'standard' | 'ghost' | 'ghost-table' = 'standard';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() placeholder = 'Select period';
  @Input() tabindex: number = 0;
  @Output() onChange = new EventEmitter<ITimePeriodData>();

  selectedValue: ITimePeriodData | undefined;
  selectedValueLabel: string | undefined = undefined;
  focused: boolean = false;
  isCollapsed: boolean = true;
  disabled: boolean = false;
  dropUp: boolean = false;

  onModelChange?: (modelValue?: ITimePeriodData) => void;
  onModelTouched?: () => void;

  @ViewChild('input') private input: ElementRef | undefined;

  constructor(private hostRef: ElementRef) {}

  writeValue(value: ITimePeriodData): void {
    this.selectedValue = value;
    this.selectedValueLabel = this.getSelectedValueLabel();
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  handleChange(option: ITimePeriodData) {
    if (this.onModelChange) {
      this.selectedValue = option;
      this.onModelChange(option);
      this.selectedValueLabel = this.getSelectedValueLabel();
    }
    if (this.onModelTouched) this.onModelTouched();
    this.onChange.emit(option);
  }

  toggleCollapse(): void {
    if (this.onModelTouched) this.onModelTouched();
    if (this.isCollapsed) {
      this.setDropUp();
    }
    if (this.focused) this.focused = false;
    this.isCollapsed = !this.isCollapsed;
  }

  collapse(): void {
    if (this.focused) this.focused = false;
    this.isCollapsed = true;
  }

  handleOnCancel() {
    this.collapse();
    this.hostRef.nativeElement.blur();
  }

  handleFocus() {
    if (!this.disabled) {
      this.focused = true;
      this.setDropUp();
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleFocusOut() {
    this.collapse();
  }

  setDropUp() {
    if (this.input && window.innerHeight - this.input.nativeElement.getBoundingClientRect().bottom < 200)
      this.dropUp = true;
    else this.dropUp = false;
  }

  private getSelectedValueLabel(): string | undefined {
    switch (this.selectedValue?.type) {
      case TimePeriodType.Annual: {
        return this.selectedValue?.year ? (this.selectedValue?.year).toString() : '';
      }
      case TimePeriodType.Quarterly: {
        if (!this.selectedValue?.period || !this.selectedValue?.year) return '';
        const year = this.selectedValue.year.toString();
        const quarter =
          globalQuarterOptions.find(option => option.value === this.selectedValue?.period?.toString())?.label || '';
        return quarter + ' ' + year;
      }
      case TimePeriodType.Monthly: {
        if (!this.selectedValue?.period || !this.selectedValue?.year) return '';
        const year = this.selectedValue.year.toString();
        const month =
          globalMonthShortOptions.find(option => option.value === this.selectedValue?.period?.toString())?.label || '';
        return month + ' ' + year;
      }
      default:
        return undefined;
    }
  }
}
