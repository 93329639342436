import { Component, OnInit } from '@angular/core';
import { MainLevel, ReportPreviewStateService } from '../../report-preview-state.service';
import { stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { KeyFiguresPreviewStateService } from './key-figures-preview-state.service';
import { TimePeriodType } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-key-figures',
  templateUrl: './key-figures.component.html',
  styleUrls: ['./key-figures.component.scss'],
})
export class KeyFiguresComponent implements OnInit {
  getFormattedNumber = stringToFormattedNumberString;
  constructor(public reportState: ReportPreviewStateService, public state: KeyFiguresPreviewStateService) {}

  get isCustomPeriod() {
    return this.reportState.timePeriod?.type === TimePeriodType.Custom;
  }

  ngOnInit(): void {
    this.reportState.mainLevelsObservable$.subscribe((mainLevels: (MainLevel | undefined)[]) => {
      this.state.setKeyFigures(mainLevels);
    });
  }

  getFigureTimeLabel(shift: number) {
    if (this.reportState.timePeriod) {
      return getTimeLabelFromPeriod(this.reportState.timePeriod, shift);
    }
    return '';
  }
}
