import { Component, Input, SimpleChanges } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateVesselConsumptionDataRecordCommandParams,
  DataRecordType,
  InputType,
  UpdateVesselConsumptionDataRecordCommandParams,
  VesselConsumptionDataRecord,
  VesselEmissionsDataRecord,
} from 'src/api-client/report-api.generated';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { formatDate } from '@angular/common';
import { IOption } from 'src/app/static-data/options';

interface VesselCompustionRecordForm extends RecordForm {
  distanceSailed_nm: FormControl<string>;
  inOperation_hrs: FormControl<string>;
  mgo: FormControl<string>;
  mgo_unit: FormControl<IOption>;
  hfo: FormControl<string>;
  hfo_unit: FormControl<IOption>;
  lfo: FormControl<string>;
  lfo_unit: FormControl<IOption>;
  lng: FormControl<string>;
  lng_unit: FormControl<IOption>;
  urea_l: FormControl<string>;
}

@Component({
  selector: 'esg-vessel-combustion-form-table',
  templateUrl: './vessel-combustion-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: VesselCombustionFormTableComponent }],
})
export class VesselCombustionFormTableComponent extends RecordFormTableComponent {
  @Input() showSegmentedControl = false;
  @Input() activeSegmentedTab: string = DataRecordType.VesselConsumption;

  unitOptions: IOption[] = [
    { value: 'mt', label: 'mt', optionLabel: 'Metric Tons (mt)' },
    { value: 'm3', label: 'm³', optionLabel: 'Cubic Metres (m³)' },
  ];

  recordFc?: VesselCompustionRecordForm = undefined;
  recordFormgroup?: FormGroup<VesselCompustionRecordForm> = undefined;

  columnFilter: FormControl<IOption[]> = new FormControl([], { nonNullable: true });
  columnFilterStorageKey = 'vesselCombustionColumnFilter';

  get useColumnFilter() {
    return this.activeSegmentedTab === DataRecordType.VesselConsumption;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.activeSegmentedTab) {
      const activeTab = changes.activeSegmentedTab.currentValue;
      if (this.showSegmentedControl && activeTab === DataRecordType.VesselEmissions) {
        this.headerCells = [
          { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
          { columnId: 'asset', label: 'Asset', description: '', number: false },
          { columnId: 'mgo', label: 'MGO', description: 'm3', number: true },
          { columnId: 'lng', label: 'LNG', description: 'm3', number: true },
          { columnId: 'nox', label: 'Nox', description: 'Tonnes', number: true },
          { columnId: 'co2', label: 'CO2', description: 'Tonnes', number: true },
          { columnId: 'sox', label: 'SOx', description: 'kg', number: true },
          { columnId: 'powerFromShore', label: 'Power from shore', description: 'Kwh', number: true },
          { columnId: 'hoursInOperation', label: 'Hours in operation', description: 'Hours', number: true },
        ];
      } else {
        this.headerCells = [
          { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
          { columnId: 'asset', label: 'Asset', description: '', number: false },
          {
            columnId: 'inOperation_hrs',
            label: 'In Operation',
            description: 'Hours',
            number: true,
            possibleToHide: true,
          },
          {
            columnId: 'distanceSailed_nm',
            label: 'Distance',
            description: 'Nautical Miles',
            number: true,
            possibleToHide: true,
          },
          { columnId: 'mgo', label: 'MGO', description: 'mt or m3', number: true, possibleToHide: true },
          { columnId: 'hfo', label: 'HFO', description: 'mt or m3', number: true, possibleToHide: true },
          { columnId: 'lfo', label: 'LFO', description: 'mt or m3', number: true, possibleToHide: true },
          { columnId: 'lng', label: 'LNG', description: 'mt or m3', number: true, possibleToHide: true },
          { columnId: 'urea_l', label: 'UREA', description: 'Litres', number: true, possibleToHide: true },
        ];
      }
    }
  }

  setRecordsRows() {
    if (this.showSegmentedControl && this.activeSegmentedTab === DataRecordType.VesselEmissions) {
      this.dataRecordsRows = (this.dataRecords as VesselEmissionsDataRecord[])
        .filter(record => record.type === DataRecordType.VesselEmissions)
        .map(record => ({
          id: record.id,
          cells: [
            {
              columnId: 'period',
              value:
                formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
                ' - ' +
                formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
              number: false,
            },
            {
              columnId: 'asset',
              value:
                this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
              number: false,
            },
            { columnId: 'mgo', value: record.mgO_m3?.toString() || '', number: true },
            { columnId: 'lng', value: record.lnG_m3?.toString() || '', number: true },
            { columnId: 'nox', value: record.nox_t?.toString() || '', number: true },
            { columnId: 'co2', value: record.cO2_t?.toString() || '', number: true },
            { columnId: 'sox', value: record.sOx_kg?.toString() || '', number: true },
            { columnId: 'powerFromShore', value: record.powerFromShore_kwh?.toString() || '', number: true },
            { columnId: 'hoursInOperation', value: record.inOperation_hrs?.toString() || '', number: true },
          ],
        }));
    } else {
      this.dataRecordsRows = (this.dataRecords as VesselConsumptionDataRecord[]).map(record => ({
        id: record.id,
        cells: [
          {
            columnId: 'period',
            value:
              formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
              ' - ' +
              formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
            number: false,
          },
          {
            columnId: 'asset',
            value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
            number: false,
          },
          { columnId: 'inOperation_hrs', value: record.inOperation_hrs?.toString() || '', number: true },
          { columnId: 'distanceSailed_nm', value: record.distanceSailed_nm?.toString() || '', number: true },
          {
            columnId: 'mgo',
            value: record.mgo?.toString() || '',
            number: true,
            unit: record.mgo ? this.unitOptions.find(c => c.value === record.mgO_Unit)?.label || record.mgO_Unit : '',
          },
          {
            columnId: 'hfo',
            value: record.hfo?.toString() || '',
            number: true,
            unit: record.hfo ? this.unitOptions.find(c => c.value === record.hfO_Unit)?.label || record.hfO_Unit : '',
          },
          {
            columnId: 'lfo',
            value: record.lfo?.toString() || '',
            number: true,
            unit: record.lfo ? this.unitOptions.find(c => c.value === record.lfO_Unit)?.label || record.lfO_Unit : '',
          },
          {
            columnId: 'lng',
            value: record.lng?.toString() || '',
            number: true,
            unit: record.lng ? this.unitOptions.find(c => c.value === record.lnG_Unit)?.label || record.lnG_Unit : '',
          },
          { columnId: 'urea_l', value: record.urea_l?.toString() || '', number: true },
        ],
      }));
    }
  }

  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      inOperation_hrs: new FormControl<string>('', {
        nonNullable: true,
      }),
      distanceSailed_nm: new FormControl<string>('', {
        nonNullable: true,
      }),
      mgo: new FormControl<string>('', {
        nonNullable: true,
      }),
      mgo_unit: new FormControl<IOption>(this.unitOptions[0], {
        nonNullable: true,
      }),
      hfo: new FormControl<string>('', {
        nonNullable: true,
      }),
      hfo_unit: new FormControl<IOption>(this.unitOptions[0], {
        nonNullable: true,
      }),
      lfo: new FormControl<string>('', {
        nonNullable: true,
      }),
      lfo_unit: new FormControl<IOption>(this.unitOptions[0], {
        nonNullable: true,
      }),
      lng: new FormControl<string>('', {
        nonNullable: true,
      }),
      lng_unit: new FormControl<IOption>(this.unitOptions[0], {
        nonNullable: true,
      }),
      urea_l: new FormControl<string>('', {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const vesselRecord = this.dataRecords.find(record => record.id === id) as VesselConsumptionDataRecord;
    if (vesselRecord) {
      this.recordFc = {
        startDate: new FormControl<Date>(vesselRecord.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(vesselRecord.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === vesselRecord.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        inOperation_hrs: new FormControl<string>(vesselRecord.inOperation_hrs?.toString() || '', {
          nonNullable: true,
        }),
        distanceSailed_nm: new FormControl<string>(vesselRecord.distanceSailed_nm?.toString() || '', {
          nonNullable: true,
        }),
        mgo: new FormControl<string>(vesselRecord.mgo?.toString() || '', {
          nonNullable: true,
        }),
        mgo_unit: new FormControl<IOption>(
          this.unitOptions.find(opt => opt.value === vesselRecord.mgO_Unit) || this.unitOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        hfo: new FormControl<string>(vesselRecord.hfo?.toString() || '', {
          nonNullable: true,
        }),
        hfo_unit: new FormControl<IOption>(
          this.unitOptions.find(opt => opt.value === vesselRecord.hfO_Unit) || this.unitOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        lfo: new FormControl<string>(vesselRecord.lfo?.toString() || '', {
          nonNullable: true,
        }),
        lfo_unit: new FormControl<IOption>(
          this.unitOptions.find(opt => opt.value === vesselRecord.lfO_Unit) || this.unitOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        lng: new FormControl<string>(vesselRecord.lng?.toString() || '', {
          nonNullable: true,
        }),
        lng_unit: new FormControl<IOption>(
          this.unitOptions.find(opt => opt.value === vesselRecord.lnG_Unit) || this.unitOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        urea_l: new FormControl<string>(vesselRecord.urea_l?.toString() || '', {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup(this.recordFc);
    }
  }

  getParams():
    | CreateVesselConsumptionDataRecordCommandParams
    | UpdateVesselConsumptionDataRecordCommandParams
    | undefined {
    this.hasSubmitted = true;
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const vesselCompustion = {
        type: DataRecordType.VesselConsumption,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        inOperation_hrs: formattedStringToNumber(this.recordFc.inOperation_hrs.value || ''),
        distanceSailed_nm: formattedStringToNumber(this.recordFc.distanceSailed_nm.value || ''),
        mgo: formattedStringToNumber(this.recordFc.mgo.value || ''),
        mgO_Unit: this.recordFc.mgo_unit.value.value,
        hfo: formattedStringToNumber(this.recordFc.hfo.value || ''),
        hfO_Unit: this.recordFc.hfo_unit.value.value,
        lfo: formattedStringToNumber(this.recordFc.lfo.value || ''),
        lfO_Unit: this.recordFc.lfo_unit.value.value,
        lng: formattedStringToNumber(this.recordFc.lng.value || ''),
        lnG_Unit: this.recordFc.lng_unit.value.value,
        urea_l: formattedStringToNumber(this.recordFc.urea_l.value || ''),
      };
      if (this.editRecordId) {
        return new UpdateVesselConsumptionDataRecordCommandParams({ id: this.editRecordId, ...vesselCompustion });
      } else {
        return new CreateVesselConsumptionDataRecordCommandParams(vesselCompustion);
      }
    }
    return undefined;
  }
}
