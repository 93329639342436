import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  CreateIndicatorDefinitionCommandParams,
  CreateIndicatorDefinitionCommandResponse,
  DeleteIndicatorDefinitionCommandParams,
  DeleteIndicatorDefinitionCommandResponse,
  DynamicRowsDataSource,
  GetAllIndicatorDefinitionsQueryResponse,
  GetAllNamesIndicatorDefinitionsQueryResponse,
  GetByIdIndicatorDefinitionQueryResponse,
  GetDynamicRowsQueryResponse,
  IndicatorCategory,
  IndicatorDefinitionAccessibility,
  IndicatorDefinitionClient,
  UpdateIndicatorDefinitionCommandParams,
  UpdateIndicatorDefinitionCommandResponse,
} from '../../../api-client/report-api.generated';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndicatorDefinitionApiService {
  client: IndicatorDefinitionClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new IndicatorDefinitionClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createIndicatorDefinition(
    body: CreateIndicatorDefinitionCommandParams
  ): Promise<CreateIndicatorDefinitionCommandResponse> {
    const observable$ = this.client.createIndicatorDefinition(body);
    return await lastValueFrom(observable$);
  }

  async updateIndicatorDefinition(
    body: UpdateIndicatorDefinitionCommandParams
  ): Promise<UpdateIndicatorDefinitionCommandResponse> {
    const observable$ = this.client.updateIndicatorDefinition(body);
    return await lastValueFrom(observable$);
  }

  async deleteIndicatorDefinition(indicatorId: string): Promise<DeleteIndicatorDefinitionCommandResponse> {
    const observable$ = this.client.deleteIndicatorDefinition(
      indicatorId,
      new DeleteIndicatorDefinitionCommandParams({ id: indicatorId })
    );
    return await lastValueFrom(observable$);
  }

  async getAll(
    onlyOrganizationIndicatorDefinitions: boolean,
    name: string | undefined,
    category: IndicatorCategory[] | undefined,
    accessibility: IndicatorDefinitionAccessibility[] | undefined,
    orderBy: string | undefined,
    pageNumber: number | undefined,
    pageSize: number | undefined
  ): Promise<GetAllIndicatorDefinitionsQueryResponse> {
    const observable$ = this.client.getAllIndicatorDefinitions(
      onlyOrganizationIndicatorDefinitions,
      name,
      category,
      accessibility,
      orderBy,
      pageNumber,
      pageSize
    );
    return await lastValueFrom(observable$);
  }

  async getAllNames(
    onlyOrganizationIndicatorDefinitions: boolean = true
  ): Promise<GetAllNamesIndicatorDefinitionsQueryResponse> {
    const observable$ = this.client.getAllNamesIndicatorDefinitions(
      onlyOrganizationIndicatorDefinitions,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    return await lastValueFrom(observable$);
  }

  async getById(indicatorId: string): Promise<GetByIdIndicatorDefinitionQueryResponse> {
    const observable$ = this.client.getIndicatorDefinitionById(indicatorId);
    return await lastValueFrom(observable$);
  }

  async getDynamicRows(dynamicRowsDataSource: DynamicRowsDataSource): Promise<GetDynamicRowsQueryResponse> {
    const observable$ = this.client.getDynamicRows(dynamicRowsDataSource);
    return await lastValueFrom(observable$);
  }
}
