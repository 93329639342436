import { Component } from '@angular/core';
import { CorruptionRiskStateService } from './corruption-risk-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-corruption-risk',
  templateUrl: './corruption-risk.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: CorruptionRiskComponent }, CorruptionRiskStateService],
})
export class CorruptionRiskComponent extends RecordsComponent {
  constructor(public state: CorruptionRiskStateService) {
    super(state);
  }
}
