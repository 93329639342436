<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading records"></esg-dot-flashing>
<esg-filter-pagination-bar
  *ngIf="!state.loading"
  class="filter transparent"
  [showFilter]="false"
  [paginationData]="state.currentPagination"
  [pageSizeOptions]="state.pageSizeOptions"
  (onPaginationChange)="state.handlePaginationChange($event)"></esg-filter-pagination-bar>
<esg-gender-diversity-equality-form-table
  *ngIf="!state.loading"
  [dataRecords]="state.dataRecords"
  [newlyAddedRowsIds]="state.newlyAddedRowsIds"
  [readOnly]="state.isReadonly"
  [stickyHeaderTopOffset]="55"
  [intersectionObserverRootMargin]="state.intersectionObserverRootMargin"
  [stickyHeaderPadding]="100"
  [assetOptions]="state.assetOptions"
  [organizationCurrency]="state.organizationCurrency"
  [countryOptions]="state.countryOptions"
  [regionOptions]="state.regionOptions"
  [assetCountryMap]="state.assetCountryMap"
  [profileId]="profileId"
  [assetId]="assetId"
  [currencyCode]="currencyCode"
  (onSubmit)="state.handleRecordFormSubmit($event)"
  (onDelete)="state.handleDeleteRecord($event)"></esg-gender-diversity-equality-form-table>
