import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrganizationFormStateService } from './organization-form-state.service';
import { IUploadedPhoto, IUploadPhotoComponentTexts } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { IOption } from 'src/app/static-data/options';
import { FormControl } from '@angular/forms';

export interface IOrganizationDetailsUi {
  name: FormControl<string>;
  countryCode: FormControl<IOption | null>;
  currencyCode: FormControl<IOption | null>;
  orgID: FormControl<string>;
  logo: FormControl<IUploadedPhoto | null>;
  logoSquare: FormControl<IUploadedPhoto | null>;
  brandColor: FormControl<string | null>;
  tableHeadColor: FormControl<string | null>;
}

@Component({
  selector: 'esg-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss'],
  providers: [OrganizationFormStateService],
})
export class OrganizationFormComponent implements OnInit {
  @Input() organizationId?: string;
  @Input() countryOptions: IOption[] = [];

  @Output() onCancel = new EventEmitter();
  @Output() onSubmited = new EventEmitter();

  headerText = 'Create new Organization';
  submitText = 'Create';

  componentTexts: IUploadPhotoComponentTexts = {
    firstLineP1: 'Drag and drop a logo here or ',
    firstLineP2: ' a logo',
    firstLineP1UploadedPhoto: 'Drag and drop or ',
    firstLineP2UploadedPhoto: ' to replace logo',
    secondLine: 'Logo must be SVG or transparent PNG and minimum height must be 200px.',
  };

  componentTextsSquareUpload: IUploadPhotoComponentTexts = {
    firstLineP1: 'Drag and drop a <span style="font-style: italic;">square</span> logo here or ',
    firstLineP2: ' a logo',
    firstLineP1UploadedPhoto: 'Drag and drop or ',
    firstLineP2UploadedPhoto: ' to replace logo',
    secondLine: 'Logo must be a square SVG or transparent PNG and minimum dimensions <br />must be 512 x512 px.',
  };

  constructor(public state: OrganizationFormStateService) {}

  ngOnInit(): void {
    this.initOrganizationForm(this.organizationId);
    if (this.organizationId) {
      this.headerText = 'Edit Organization';
      this.submitText = 'Save changes';
    }
  }

  async initOrganizationForm(organizationId?: string) {
    await this.state.initOrganizationForm(organizationId);
  }

  handleDialogClose() {
    this.onCancel.emit();
  }

  handleUploadLogo(file: File) {
    this.state.uploadLogo(file);
  }

  handleRemoveLogo() {
    this.state.removeLogo();
  }

  handleRemoveLogoSquare() {
    this.state.removeLogoSquare();
  }

  async handleSubmit() {
    await this.state.submit();
    if (!this.state.showCurrencyWarning) this.onSubmited.emit();
  }

  async handleCurrencyWarningConfirm() {
    await this.state.onCurrencyWarningConfirm();
    this.onSubmited.emit();
  }

  handleCurrencyWarningClose() {
    this.state.onCurrencyWarningClose();
  }
}
