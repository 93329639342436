import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigurationService } from '../core/app-configuration.service';
import { AppInfoService } from '../core/app-info.service';
import { KeyFiguresPreviewStateService } from './pages/key-figures/key-figures-preview-state.service';
import { MainTopicFeedStateService } from './pages/main-topic-feed/main-topic-feed-state.service';
import { ReportPreviewStateService } from './report-preview-state.service';
import { FormatUnitWithUnicodePipe } from '../shared/pipes/format-unit-with-unicode.pipe';

@Component({
  selector: 'esg-report-preview',
  templateUrl: './report-preview.component.html',
  providers: [KeyFiguresPreviewStateService, MainTopicFeedStateService, FormatUnitWithUnicodePipe],
})
export class ReportPreviewComponent implements OnInit {
  reportId!: string;

  constructor(
    public state: ReportPreviewStateService,
    public appSettings: AppConfigurationService,
    public appInfoState: AppInfoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      var oldReportId = this.reportId;
      this.reportId = params['id'];

      if (oldReportId !== this.reportId) {
        let token = this.route.snapshot.queryParams['t'];

        await this.state.initHTMLReport(this.reportId, token);
      }
    });
  }
}
