import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'esg-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title: string = 'Are you sure?';
  @Input() message: string = 'Once you delete it\nthere is no turning back.';
  @Input() cancelText: string = 'Never Mind';
  @Input() submitText: string = 'Delete';
  @Output() onCancel = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  handleCancel() {
    this.onCancel.emit();
  }

  handleSubmit() {
    this.onSubmit.emit();
  }
}
