<div class="esg-chart-tooltip">
  <div class="title">Q1 2022</div>
  <div class="content">
    <div class="target">
      <div class="circle" style="background-color: blue"></div>
      <span class="name">Target:</span>
      <span class="value">7192</span>
    </div>
    <div class="value">
      <div class="circle"></div>
      <span class="name">Carbon Intensity (CII):</span>
      <span class="value">6835</span>
    </div>
  </div>
</div>

<div class="dropdown-favourite-options">
  <esg-select
    [value]="dropdownFavouriteValue"
    [options]="dropdownFavouriteOptions"
    [favouriteOptions]="favouriteOptions"></esg-select>
</div>
<div class="dropdown-multiple-example">
  <esg-dropdown-multiple-form
    [fc]="dropdownMultipleFc"
    [options]="dropdownMultipleOptions"></esg-dropdown-multiple-form>
</div>

<div class="checkbox-example">
  <esg-checkbox-form label="Show substrate data" [fc]="checkboxFc"></esg-checkbox-form>
</div>

<div class="table-example">
  <table>
    <tr>
      <td>
        <esg-table-input name="input1" [fc]="tableInputFormControl"></esg-table-input>
      </td>
      <td>
        <esg-table-input name="input2" [fc]="tableInputFormControl2"></esg-table-input>
      </td>
      <td>
        <esg-table-dropdown
          name="dropdown1"
          [fc]="tableDropdownFormControl1"
          [options]="tableDropdownOptions1"></esg-table-dropdown>
      </td>
      <td>
        <esg-table-dropdown
          name="dropdown2"
          [fc]="tableDropdownFormControl1"
          [options]="tableDropdownOptions1"></esg-table-dropdown>
      </td>
    </tr>
  </table>
</div>

<div #content class="content-wrapper">
  <div
    class="content-menu-observer"
    esgIntersectionObserver
    [root]="content"
    rootMargin="0px"
    (onIntersection)="contentState.handleStickyComponentMenu($event)"></div>
  <esg-content
    [contentItems]="contentState.contentList"
    (onAddContent)="contentState.handleOnAddContent($event)"
    (onDeleteContent)="contentState.deleteContent($event)"
    (onMoveContent)="contentState.moveContent($event)"
    [onChange]="contentState.onContentDataChange"
    [stickyMenu]="contentState.stickyComponentsToolbar"></esg-content>
</div>
