import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-lock-icon',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.30645 16H11.6935C12.2957 16 12.7473 15.8423 13.0484 15.5268C13.3495 15.2167 13.5 14.7272 13.5 14.0581V8.43651C13.5 7.77834 13.3495 7.29424 13.0484 6.98419C12.7473 6.66871 12.2957 6.51096 11.6935 6.51096H4.30645C3.7043 6.51096 3.25269 6.66871 2.95161 6.98419C2.65054 7.29424 2.5 7.77834 2.5 8.43651V14.0581C2.5 14.7272 2.65054 15.2167 2.95161 15.5268C3.25269 15.8423 3.7043 16 4.30645 16ZM4.35484 14.7761C4.16667 14.7761 4.02688 14.7272 3.93548 14.6293C3.84409 14.5368 3.79839 14.3763 3.79839 14.1479V8.35492C3.79839 8.12647 3.84409 7.966 3.93548 7.87353C4.02688 7.78106 4.16667 7.73483 4.35484 7.73483H11.6532C11.8414 7.73483 11.9785 7.78106 12.0645 7.87353C12.1559 7.966 12.2016 8.12647 12.2016 8.35492V14.1479C12.2016 14.3763 12.1559 14.5368 12.0645 14.6293C11.9785 14.7272 11.8414 14.7761 11.6532 14.7761H4.35484ZM3.89516 7.15553H5.19355V4.3896C5.19355 3.70423 5.32258 3.13038 5.58065 2.66803C5.83871 2.20024 6.18011 1.8494 6.60484 1.6155C7.03495 1.37617 7.5 1.2565 8 1.2565C8.50538 1.2565 8.97043 1.37617 9.39516 1.6155C9.81989 1.8494 10.1613 2.20024 10.4194 2.66803C10.6774 3.13038 10.8065 3.70423 10.8065 4.3896V7.15553H12.1048V4.56094C12.1048 3.79398 11.9919 3.12494 11.7661 2.5538C11.5403 1.98266 11.2339 1.50943 10.8468 1.13412C10.4597 0.753357 10.0188 0.470508 9.52419 0.285569C9.03495 0.0951895 8.52688 0 8 0C7.47312 0 6.96505 0.0951895 6.47581 0.285569C5.98656 0.470508 5.54839 0.753357 5.16129 1.13412C4.77419 1.50943 4.46505 1.98266 4.23387 2.5538C4.00806 3.12494 3.89516 3.79398 3.89516 4.56094V7.15553Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class LockIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
