import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';

export interface SheetUi {
  id: string;
  name: string;
  headers: RecordHeaderCell[];
  bodyRows: RecordBodyRow[];
}

export interface RecordHeaderCell {
  label: string;
  description: string;
  number?: boolean;
}

export interface RecordBodyRow {
  id: string;
  label: string;
  cells: RecordBodyCell[];
}

export interface RecordBodyCell {
  value: string;
  unit?: string;
  number: boolean;
}

// Currently not used

@Component({
  selector: 'esg-records-sheet',
  templateUrl: './records-sheet.component.html',
  styleUrls: ['./records-sheet.component.scss'],
})
export class RecordsSheetComponent {
  @Input({ required: true }) sheet!: SheetUi;
  @Input() readonly: boolean = false;
  @Input() editRecordId?: string;

  @Output() onFormClose = new EventEmitter();
  @Output() onEditing = new EventEmitter<string>();

  hasActiveCell: boolean = false;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  get gridTemplateColumns() {
    return '1fr repeat(' + this.sheet.headers.length + ', minmax(min-content, 1fr))';
  }

  sanitizeHtml(html?: string): SafeHtml {
    return html ? this.domSanitizer.bypassSecurityTrustHtml(html) : '';
  }

  handleFocusInside() {
    this.hasActiveCell = true;
  }

  handleOnFormClose() {
    if (!this.hasActiveCell) this.onFormClose.emit();
    else this.hasActiveCell = false;
  }

  formatNumberCell(value: string, unit: string) {
    return unit
      ? (stringToFormattedNumberString(value) || '-') + ' ' + unit
      : stringToFormattedNumberString(value) || '-';
  }
}
