import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'esg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() class: 'primary' | 'secondary' | 'tertiary' | 'quaternary' = 'primary';
  @Input() size: 'medium' | 'standard' | 'small' | 'xsmall' = 'standard';
  @Input() disabled = false;
  @Input() iconButton = false;
  @Input() disabledHover = false;
  @Output() onClick = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  handleClick(event: Event) {
    if (!this.disabled) {
      this.onClick.emit(event);
    }
  }
}
