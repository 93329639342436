import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import {
  CreateEmployeeMarineCasualtyDataRecordCommandParams,
  DataRecordType,
  EmployeeMarineCasualtyDataRecord,
  InputType,
  MarineCasualtyType,
  UpdateEmployeeMarineCasualtyDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption, MarineCasualtyTypeOptions } from 'src/app/static-data/options';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';

interface MarineCasualtyRecordForm extends RecordForm {
  marineCasultyType: FormControl<IOption>;
  incidents: FormControl<string>;
}

@Component({
  selector: 'esg-marine-casualty-form-table',
  templateUrl: './marine-casualty-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: MarineCasualtyFormTableComponent }],
})
export class MarineCasualtyFormTableComponent extends RecordFormTableComponent {
  headerCells = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'marineCasualtyType', label: 'Type', description: 'Select', number: false },
    { columnId: 'incidents', label: 'Incidents', description: 'Number', number: true },
  ];

  recordFc?: MarineCasualtyRecordForm = undefined;
  recordFormgroup?: FormGroup<MarineCasualtyRecordForm> = undefined;

  marineCasualtyTypeOptions = MarineCasualtyTypeOptions;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as EmployeeMarineCasualtyDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'marineCasualtyType',
          value: this.marineCasualtyTypeOptions.find(c => c.value === record.marineCasualtyType)?.label || '',
          number: false,
        },
        {
          columnId: 'incidents',
          value: record.incidents?.toString() || '',
          number: true,
        },
      ],
    }));
  }

  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      marineCasultyType: new FormControl<IOption>(this.marineCasualtyTypeOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      incidents: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    };
    this.recordFormgroup = new FormGroup(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const incidentsRecord = this.dataRecords.find(record => record.id === id) as EmployeeMarineCasualtyDataRecord;
    if (incidentsRecord) {
      this.recordFc = {
        startDate: new FormControl<Date>(incidentsRecord.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(incidentsRecord.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === incidentsRecord.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        marineCasultyType: new FormControl<IOption>(
          this.marineCasualtyTypeOptions.find(opt => opt.value === incidentsRecord.marineCasualtyType) ||
            this.marineCasualtyTypeOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        incidents: new FormControl<string>(incidentsRecord.incidents.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
      };
      this.recordFormgroup = new FormGroup(this.recordFc);
    }
  }

  getParams():
    | CreateEmployeeMarineCasualtyDataRecordCommandParams
    | UpdateEmployeeMarineCasualtyDataRecordCommandParams
    | undefined {
    const marineCasualtyType = Object.values(MarineCasualtyType).find(
      type => type === this.recordFc?.marineCasultyType.value?.value
    );
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc?.asset.value?.value && marineCasualtyType) {
      const incidents = {
        type: DataRecordType.EmployeeMarineCasualty,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        marineCasualtyType: marineCasualtyType,
        incidents: formattedStringToNumber(this.recordFc.incidents.value) || 0,
      };
      if (this.editRecordId) {
        return new UpdateEmployeeMarineCasualtyDataRecordCommandParams({ id: this.editRecordId, ...incidents });
      } else {
        return new CreateEmployeeMarineCasualtyDataRecordCommandParams(incidents);
      }
    }
    return undefined;
  }
}
