import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AssetType,
  ScrubbersLoopSystem,
  UpdateVesselAssetCommandParams,
  VesselType,
  VesselAsset,
} from 'src/api-client/report-api.generated';
import { IOption, vesselTypeOptions, scrubbersLoopSystem } from 'src/app/static-data/options';
import { formattedStringToNumber, stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { AssetDetailsForm, AssetDetailsStateService } from '../../asset-details-state.service';

interface VesselDetailsForm extends AssetDetailsForm {
  imOnumber: FormControl<string>;
  type: FormControl<IOption | undefined>;
  installedPower: FormControl<string>;
  recycleYear: FormControl<string>;
  roRoVessel: FormControl<IOption | undefined>;
  deadWeightTonnage: FormControl<string>;
  grossTonnage: FormControl<string>;
  scrubbersInstalled: FormControl<IOption | undefined>;
  scrubbersSystem: FormControl<IOption | undefined>;
}

@Injectable()
export class VesselDetailsStateService extends AssetDetailsStateService {
  organizationCountryMap = new Map<string, string>();
  typeOptions: IOption[] = vesselTypeOptions;
  yesNoOptions: IOption[] = [
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes' },
  ];
  scrubberSystemOptions: IOption[] = scrubbersLoopSystem;

  fc?: VesselDetailsForm;

  async init(assetId: string, organizationOptions: IOption[], organizationCountryMap: Map<string, string>) {
    this.loading = true;
    this.assetId = assetId;
    this.organizationOptions = organizationOptions;
    this.organizationCountryMap = organizationCountryMap;
    await Promise.all([this.getAssetVersions(), this.getRecordsOverview()]);
    this.loading = false;
  }

  async getAssetVersion(versionId: string) {
    const response = await this.assetApiService.getAssetById(this.assetId, versionId);
    const vessel = response.result as VesselAsset;
    this.mainOrganizationId = vessel.organizationId;

    this.fc = {
      versionValidFrom: new FormControl<Date>(vessel.versionValidFrom, { nonNullable: true }),
      name: new FormControl<string>(vessel.name, { validators: [Validators.required], nonNullable: true }),
      imOnumber: new FormControl<string>(vessel.imOnumber?.toString() || '', { nonNullable: true }),
      type: new FormControl<IOption | undefined>(
        this.typeOptions.find(type => type.value === vessel?.vesselType),
        { validators: [Validators.required], nonNullable: true }
      ),
      organization: new FormControl<IOption | undefined>(
        this.organizationOptions?.find(org =>
          vessel?.subOrganizationId ? org.value === vessel?.subOrganizationId : org.value === vessel?.organizationId
        ),
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      installedPower: new FormControl<string>(stringToFormattedNumberString(vessel.installedPower.toString()), {
        nonNullable: true,
      }),
      recycleYear: new FormControl<string>(vessel.toBeRecycledYear?.toString() || '', {
        nonNullable: true,
      }),
      roRoVessel: new FormControl<IOption | undefined>(
        vessel.roRoVessel ? this.yesNoOptions[1] : this.yesNoOptions[0],
        {
          nonNullable: true,
        }
      ),
      deadWeightTonnage: new FormControl<string>(
        stringToFormattedNumberString(vessel.deadWeightTonnage?.toString() || ''),
        {
          nonNullable: true,
        }
      ),
      grossTonnage: new FormControl<string>(stringToFormattedNumberString(vessel.grossTonnage?.toString() || ''), {
        nonNullable: true,
      }),
      scrubbersInstalled: new FormControl<IOption | undefined>(
        vessel.scrubbersInstalled ? this.yesNoOptions[1] : this.yesNoOptions[0],
        {
          nonNullable: true,
        }
      ),
      scrubbersSystem: new FormControl<IOption | undefined>(
        this.scrubberSystemOptions.find(sys => sys.value === vessel?.scrubbersLoopSystem),
        {
          nonNullable: true,
        }
      ),
    };

    if (this.fc.organization.value?.value)
      this.defaultOrganizationCode = this.organizationCountryMap.get(this.fc.organization.value.value);

    this.formgroup = new FormGroup(this.fc);
  }

  setScrubbersInstalled(option: IOption) {
    if (!this.fc) return;
    if (this.fc.scrubbersInstalled.value?.value !== 'true' && option.value === 'true')
      this.fc.scrubbersSystem.setValue(this.scrubberSystemOptions[0]);
    else this.fc.scrubbersSystem.setValue(undefined);
    this.handleDropdownChange(this.fc.scrubbersInstalled, option);
  }

  setRoRoVessel(option: IOption) {
    if (!this.fc) return;
    if (this.fc.roRoVessel.value?.value !== 'true' && option.value === 'true') {
      this.fc.grossTonnage.setValue('');
    } else {
      this.fc.deadWeightTonnage.setValue('');
    }
    this.handleDropdownChange(this.fc.roRoVessel, option);
  }

  handleOrganizationChange(option: IOption) {
    if (!this.fc) return;
    this.defaultOrganizationCode = this.organizationCountryMap.get(option.value);
    this.handleDropdownChange(this.fc.organization, option);
  }

  async updateAsset(versionId: string) {
    const vesselType = Object.values(VesselType).find(type => type === this.fc?.type.value?.value);
    if (!this.fc || !vesselType) return;
    return await this.assetApiService.updateAsset(
      new UpdateVesselAssetCommandParams({
        id: this.assetId,
        imOnumber: formattedStringToNumber(this.fc.imOnumber.value || ''),
        type: AssetType.Vessel,
        vesselType: vesselType,
        name: this.fc.name.value,
        versionId: versionId,
        subOrganizationId:
          this.fc.organization.value?.value === this.mainOrganizationId ? undefined : this.fc.organization.value?.value,
        installedPower: formattedStringToNumber(this.fc.installedPower.value || '') || 0,
        roRoVessel: this.fc.roRoVessel.value?.value === 'true' ? true : false,
        deadWeightTonnage: formattedStringToNumber(this.fc.deadWeightTonnage.value || ''),
        grossTonnage: formattedStringToNumber(this.fc.grossTonnage.value || ''),
        scrubbersInstalled: this.fc.scrubbersInstalled.value?.value === 'true' ? true : false,
        scrubbersLoopSystem: Object.values(ScrubbersLoopSystem).find(
          type => type === this.fc?.scrubbersSystem.value?.value
        ),
        toBeRecycledYear: this.fc.recycleYear.value ? formattedStringToNumber(this.fc.recycleYear.value) : undefined,
        versionValidFrom: this.fc.versionValidFrom.value,
      })
    );
  }
}
