import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationLinkUi } from '../../ui/notification/notification.component';

export type ResponseError = { [key: number]: string[] };

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  showSuccess(title: string, message: string = '', link?: NotificationLinkUi) {
    const toast = this.toastr.success(message, title, { timeOut: 10000 });
    toast.toastRef.componentInstance.link = link;
  }

  showError(title: string, message: string = '', link?: NotificationLinkUi) {
    const toast = this.toastr.error(message, title, { disableTimeOut: true });
    toast.toastRef.componentInstance.link = link;
  }

  showInfo(title: string, message: string = '', link?: NotificationLinkUi) {
    const toast = this.toastr.info(message, title, { disableTimeOut: true });
    toast.toastRef.componentInstance.link = link;
  }

  showWarning(title: string, message: string = '', link?: NotificationLinkUi) {
    const toast = this.toastr.warning(message, title, { disableTimeOut: true });
    toast.toastRef.componentInstance.link = link;
  }
}
