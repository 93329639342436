import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

export type DialogComponentType = 'full-screen' | 'large-screen' | 'auto' | 'preview-mode';

@Component({
  selector: 'esg-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() showCloseBtn = true;
  @Input() class: DialogComponentType = 'large-screen';

  @Output() onClose = new EventEmitter();

  @HostListener('document:keydown.escape', ['$event']) handleKeyEvent(event: KeyboardEvent): void {
    this.onClose.emit();
  }

  closeClicked() {
    this.onClose.emit();
  }

  handleClickBackground(event: Event) {
    event.stopPropagation();
    this.onClose.emit();
  }
}
