import { Component } from '@angular/core';

@Component({
  selector: 'esg-icon-excel',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.90039 11.2349V12.2568H12.8647C14.8135 12.2568 15.9814 11.0825 15.9814 9.54639C15.9814 8.26416 15.2134 7.21045 13.9756 6.70898C13.9883 3.87793 11.9507 1.83398 9.35449 1.83398C7.63428 1.83398 6.40918 2.75439 5.64746 3.86523C4.20654 3.47803 2.38477 4.59521 2.35303 6.37891C0.918457 6.60107 0.0234375 7.77539 0.0234375 9.27344C0.0234375 10.9238 1.26123 12.2568 3.34326 12.2568H6.16162V11.2349H3.33691C1.83252 11.2349 1.04541 10.3652 1.04541 9.27344C1.04541 8.06104 1.81348 7.15967 3.15918 7.15967C3.24805 7.15967 3.27979 7.10889 3.27979 7.02637C3.27979 5.07764 4.68262 4.53809 5.99023 4.91895C6.06641 4.94434 6.11084 4.93164 6.14893 4.86182C6.76465 3.76367 7.71045 2.85596 9.35449 2.85596C11.4365 2.85596 12.8774 4.50635 12.9854 6.39795C13.0044 6.68359 12.9854 7.02637 12.96 7.32471C12.9536 7.40723 12.9854 7.45801 13.0615 7.4707C14.2168 7.72461 14.9595 8.44824 14.9595 9.54639C14.9595 10.5239 14.2485 11.2349 12.8584 11.2349H9.90039ZM8.03418 15.5068C8.30713 15.5068 8.53564 15.2847 8.53564 15.0181V9.06396L8.49756 7.88965L8.92285 8.34033L9.8877 9.29883C9.98291 9.39404 10.1099 9.45117 10.2305 9.45117C10.4907 9.45117 10.6938 9.26709 10.6938 9.00684C10.6938 8.87354 10.6431 8.75928 10.5415 8.67041L8.396 6.66455C8.2627 6.54395 8.16113 6.49951 8.03418 6.49951C7.90088 6.49951 7.79932 6.54395 7.66602 6.66455L5.52051 8.67041C5.41895 8.75928 5.36816 8.87354 5.36816 9.00684C5.36816 9.26709 5.55859 9.45117 5.8252 9.45117C5.9458 9.45117 6.0791 9.39404 6.17432 9.29883L7.13916 8.34033L7.56445 7.88965L7.52637 9.06396V15.0181C7.52637 15.2847 7.76123 15.5068 8.03418 15.5068Z"
        fill="#0071E3" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ExcelIconComponent {}
