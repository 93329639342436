import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-copy-link',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      (mouseenter)="toggleHover()"
      (mouseleave)="toggleHover()"
      [ngStyle]="{ fill: currentColor }"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.02704 23C7.73207 23.0002 7.43997 22.9421 7.16755 22.829C6.89513 22.7159 6.64777 22.55 6.4397 22.3409C6.02084 21.9184 5.78424 21.3486 5.78064 20.7536V8.01774C5.78043 7.72277 5.83855 7.43067 5.95166 7.15825C6.06477 6.88583 6.23063 6.63847 6.4397 6.4304C6.65036 6.22801 6.89921 6.06959 7.17174 5.9644C7.44428 5.85922 7.73503 5.80936 8.02704 5.81774H20.7443C21.3397 5.81934 21.9103 6.05625 22.3317 6.47681C22.5408 6.68488 22.7066 6.93224 22.8198 7.20466C22.9329 7.47708 22.991 7.76918 22.9908 8.06415V20.7536C22.9908 21.3494 22.7541 21.9208 22.3328 22.3421C21.9115 22.7633 21.3401 23 20.7443 23H8.02704ZM8.02704 7.08019C7.90422 7.0807 7.78275 7.10557 7.66962 7.15337C7.55648 7.20118 7.45395 7.27096 7.36797 7.35867C7.28026 7.44464 7.21048 7.54716 7.16268 7.66029C7.11487 7.77343 7.09001 7.89493 7.0895 8.01774V20.7536C7.09001 20.8764 7.11487 20.9979 7.16268 21.1111C7.21048 21.2242 7.28026 21.3267 7.36797 21.4127C7.45471 21.4987 7.5576 21.5668 7.67069 21.613C7.78379 21.6592 7.90487 21.6826 8.02704 21.6819H20.7443C20.8665 21.6826 20.9876 21.6592 21.1007 21.613C21.2138 21.5668 21.3167 21.4987 21.4034 21.4127C21.4911 21.3267 21.5609 21.2242 21.6087 21.1111C21.6565 20.9979 21.6814 20.8764 21.6819 20.7536V8.01774C21.6814 7.89493 21.6565 7.77343 21.6087 7.66029C21.5609 7.54716 21.4911 7.44464 21.4034 7.35867C21.3174 7.27096 21.2149 7.20118 21.1018 7.15337C20.9887 7.10557 20.8672 7.0807 20.7443 7.08019H8.02704Z" />
      <path
        d="M3.2464 18.2194C2.65061 18.2194 2.07924 17.9827 1.65796 17.5615C1.23667 17.1402 1 16.5688 1 15.973V3.24642C1 2.65063 1.23667 2.07924 1.65796 1.65796C2.07924 1.23667 2.65061 1 3.2464 1H15.9823C16.578 1 17.1494 1.23667 17.5707 1.65796C17.992 2.07924 18.2287 2.65063 18.2287 3.24642C18.2284 3.36089 18.1982 3.4733 18.1413 3.57259C18.0843 3.67188 18.0025 3.75462 17.9038 3.81266C17.8046 3.87337 17.6905 3.90551 17.5742 3.90551C17.4579 3.90551 17.3439 3.87337 17.2447 3.81266C17.1448 3.75607 17.0619 3.67366 17.0047 3.57404C16.9476 3.47442 16.9182 3.36126 16.9198 3.24642C16.9198 3.00023 16.822 2.76412 16.6479 2.59003C16.4738 2.41595 16.2377 2.31815 15.9916 2.31815H3.2464C3.12358 2.31866 3.00208 2.34353 2.88895 2.39134C2.77581 2.43914 2.67331 2.50892 2.58733 2.59663C2.5013 2.68336 2.43325 2.78623 2.38705 2.89933C2.34086 3.01243 2.31742 3.13353 2.31813 3.2557V15.973C2.31742 16.0952 2.34086 16.2163 2.38705 16.3294C2.43325 16.4425 2.5013 16.5453 2.58733 16.6321C2.67331 16.7198 2.77581 16.7896 2.88895 16.8374C3.00208 16.8852 3.12358 16.91 3.2464 16.9105C3.36208 16.9111 3.47571 16.9412 3.57645 16.9981C3.6772 17.0549 3.7617 17.1367 3.82192 17.2354C3.87636 17.3368 3.90487 17.45 3.90487 17.565C3.90487 17.68 3.87636 17.7932 3.82192 17.8945C3.76309 17.9946 3.6789 18.0773 3.57784 18.1343C3.47678 18.1914 3.36244 18.2207 3.2464 18.2194Z" />
      <path
        d="M17.7645 16.1679C17.6347 16.1672 17.508 16.1281 17.4003 16.0557C17.2926 15.9832 17.2087 15.8805 17.1591 15.7606C17.1096 15.6406 17.0965 15.5087 17.1217 15.3813C17.1468 15.254 17.209 15.1369 17.3004 15.0447L17.5881 14.7662C17.901 14.4518 18.1258 14.0607 18.2401 13.6321C18.3544 13.2036 18.3543 12.7525 18.2396 12.324C18.125 11.8955 17.8999 11.5046 17.5868 11.1904C17.2738 10.8761 16.8837 10.6496 16.4556 10.5333C16.2404 10.4776 16.0189 10.4495 15.7966 10.4498C15.4648 10.4475 15.136 10.5121 14.8298 10.6397C14.5236 10.7672 14.2462 10.9552 14.0143 11.1924L13.7265 11.4709C13.667 11.5307 13.596 11.5779 13.5179 11.6098C13.4398 11.6417 13.3561 11.6576 13.2717 11.6565H13.1046C12.9946 11.624 12.8946 11.5646 12.8135 11.4835C12.7324 11.4024 12.6729 11.3023 12.6404 11.1924C12.6099 11.0825 12.6084 10.9666 12.636 10.856C12.6637 10.7453 12.7196 10.6437 12.7982 10.5612L13.086 10.2827C13.4407 9.92502 13.863 9.6415 14.3284 9.44867C14.7937 9.25583 15.2928 9.15753 15.7966 9.15948C16.1318 9.16092 16.4655 9.20458 16.7898 9.28944C17.4398 9.4653 18.0322 9.80887 18.5077 10.2857C18.9831 10.7626 19.3249 11.356 19.4988 12.0066C19.6727 12.6571 19.6727 13.3419 19.4986 13.9924C19.3246 14.6429 18.9827 15.2363 18.5071 15.7131L18.2286 15.9916C18.1028 16.1086 17.9363 16.1719 17.7645 16.1679Z" />
      <path
        d="M12.9839 19.6304C12.4789 19.6315 11.9787 19.5329 11.5119 19.3401C11.0452 19.1474 10.6211 18.8643 10.2641 18.5072C9.54623 17.7838 9.14264 16.8065 9.14088 15.7874C9.13995 15.2838 9.23872 14.785 9.4315 14.3198C9.62428 13.8546 9.90723 13.4321 10.2641 13.0768L10.5518 12.7983C10.6733 12.6793 10.8366 12.6126 11.0067 12.6127H11.1831C11.2919 12.6453 11.3905 12.7052 11.4697 12.7866C11.549 12.868 11.6061 12.9682 11.6359 13.0778C11.6656 13.1875 11.6669 13.3028 11.6396 13.4131C11.6124 13.5234 11.5575 13.6249 11.4801 13.708L11.1923 13.9865C10.8808 14.3016 10.6569 14.6926 10.5429 15.1208C10.4289 15.549 10.4288 15.9996 10.5426 16.4279C10.6574 16.8566 10.8836 17.2473 11.1983 17.5603C11.5129 17.8733 11.9048 18.0975 12.3341 18.2101C12.5447 18.2741 12.7639 18.3054 12.9839 18.303C13.3153 18.3032 13.6435 18.2376 13.9494 18.1102C14.2553 17.9827 14.533 17.7958 14.7662 17.5604L15.054 17.2819C15.1135 17.2221 15.1845 17.1749 15.2626 17.143C15.3407 17.1111 15.4244 17.0952 15.5088 17.0962H15.6759C15.7859 17.1287 15.8859 17.1882 15.967 17.2693C16.048 17.3503 16.1076 17.4504 16.14 17.5604C16.1706 17.6702 16.1721 17.7862 16.1445 17.8968C16.1168 18.0074 16.0609 18.109 15.9822 18.1916L15.6945 18.4701C14.9779 19.1868 14.0067 19.5906 12.9932 19.5933L12.9839 19.6304Z" />
      <path
        d="M13.5502 15.8894C13.3758 15.8884 13.2089 15.8183 13.086 15.6945C13.0239 15.6343 12.9746 15.5622 12.941 15.4824C12.9075 15.4026 12.8906 15.3169 12.8911 15.2304C12.8895 15.1437 12.9059 15.0576 12.9395 14.9777C12.9731 14.8978 13.023 14.8257 13.086 14.7662L15.3325 12.5105C15.456 12.3947 15.618 12.3286 15.7873 12.3249C15.8458 12.316 15.9052 12.316 15.9637 12.3249C16.0725 12.3576 16.1711 12.4174 16.2503 12.4988C16.3296 12.5802 16.3867 12.6804 16.4165 12.7901C16.4462 12.8997 16.4475 13.0151 16.4202 13.1253C16.393 13.2356 16.3381 13.3371 16.2607 13.4203L14.0143 15.6667C13.8937 15.7975 13.7277 15.8772 13.5502 15.8894Z" />
    </svg>
  `,
  styles: [],
})
export class CopyLinkComponent {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  // TODO CANSU How can I see icon hover color on Figma?
  @Input() size: string = '16';
  @Input() color: string = '#183642';
  @Input() hoverColor: string = 'green';

  currentColor: string = this.color;
  hovered: boolean = false;

  toggleHover() {
    this.hovered = !this.hovered;
    this.currentColor = this.hovered ? this.hoverColor : this.color;
  }
}
