import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IOption,
  WasteClassOptions,
  WasteSubClassOptionsByClass,
  getWasteHandlingOptions,
} from 'src/app/static-data/options';
import {
  CreateWasteDataRecordCommandParams,
  DataRecordType,
  InputType,
  UpdateWasteDataRecordCommandParams,
  WasteClass,
  WasteDataRecord,
  WasteSubClass,
  WasteToDisposal,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface WasteRecordForm extends RecordForm {
  wasteClass: FormControl<IOption>;
  wasteSubClass: FormControl<IOption | undefined>;
  isHazardous: FormControl<IOption>;
  toDisposal: FormControl<IOption>;
  quantity: FormControl<string>;
  unit: FormControl<IOption>;
  cO2Measured_t: FormControl<string>;
}

@Component({
  selector: 'esg-waste-form-table',
  templateUrl: './waste-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: WasteFormTableComponent }],
})
export class WasteFormTableComponent extends RecordFormTableComponent {
  headerCells: RecordHeaderCell[] = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'class', label: 'Waste Class', description: '', number: false },
    { columnId: 'subclass', label: 'Sub Class', description: '', number: false },
    { columnId: 'isHazardous', label: 'Hazardous', description: 'Yes/No', number: false },
    { columnId: 'toDisposal', label: 'Waste Handling', description: '', number: false },
    { columnId: 'quantity', label: 'Quantity', description: 'Weight or Volume', number: true },
    { columnId: 'cO2Measured_t', label: 'Measured', description: 'mtCO<sub>2</sub>', number: true },
  ];

  wasteClassOptions: IOption[] = WasteClassOptions;
  wasteSubClassOptions: IOption[] = WasteSubClassOptionsByClass[WasteClass.A_Plastics];
  disposalOptions: IOption[] = getWasteHandlingOptions(WasteClass.A_Plastics);
  hazardiousOptions: IOption[] = [
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes' },
  ];

  unitOptions: IOption[] = [
    { value: 'mt', label: 'mt', optionLabel: 'Metric Tons (mt)' },
    { value: 'm3', label: 'm³', optionLabel: 'Cubic Metres (m³)' },
  ];

  recordFc?: WasteRecordForm = undefined;
  recordFormgroup?: FormGroup<WasteRecordForm> = undefined;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as WasteDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'class',
          value: this.wasteClassOptions.find(c => c.value === record.class)?.label || '',
          number: false,
        },
        {
          columnId: 'subclass',
          value: WasteSubClassOptionsByClass[record.class].find(c => c.value === record.subclass)?.label || 'n/a',
          number: false,
        },
        { columnId: 'isHazardous', value: record.isHazardous ? 'Yes' : 'No', number: false },
        {
          columnId: 'toDisposal',
          value: this.disposalOptions.find(c => c.value === record.toDisposal)?.label || '',
          number: false,
        },
        {
          columnId: 'quantity',
          value: record.quantity?.toString() || '',
          number: true,
          unit: this.unitOptions.find(c => c.value === record.unit)?.label || record.unit,
        },
        { columnId: 'cO2Measured_t', value: record.cO2Measured_t?.toString() || '', number: true },
      ],
    }));
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      wasteClass: new FormControl<IOption>(this.wasteClassOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      wasteSubClass: new FormControl<IOption | undefined>(this.wasteSubClassOptions[0], {
        nonNullable: true,
      }),
      isHazardous: new FormControl<IOption>(this.hazardiousOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      toDisposal: new FormControl<IOption>(this.disposalOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      quantity: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      unit: new FormControl<IOption>(this.unitOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      cO2Measured_t: new FormControl<string>('', {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup<WasteRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const record = this.dataRecords.find(record => record.id === id) as WasteDataRecord;
    this.wasteSubClassOptions = WasteSubClassOptionsByClass[record.class];
    this.disposalOptions = getWasteHandlingOptions(record.class, record.subclass);
    this.hazardiousOptions =
      record.class === WasteClass.K_CargoResidues_Harmful
        ? [{ value: 'true', label: 'Yes' }]
        : [
            { value: 'false', label: 'No' },
            { value: 'true', label: 'Yes' },
          ];
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        wasteClass: new FormControl<IOption>(
          this.wasteClassOptions.find(opt => opt.value === record.class) || this.wasteClassOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        wasteSubClass: new FormControl<IOption | undefined>(
          this.wasteSubClassOptions.find(opt => opt.value === record.subclass) || this.wasteSubClassOptions[0],
          {
            nonNullable: true,
          }
        ),
        isHazardous: new FormControl<IOption>(
          this.hazardiousOptions.find(opt => opt.value === (record.isHazardous ? 'true' : 'false')) ||
            this.hazardiousOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        toDisposal: new FormControl<IOption>(
          this.disposalOptions.find(opt => opt.value === record.toDisposal) || this.disposalOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        quantity: new FormControl<string>(record.quantity.toString(), {
          validators: [Validators.required],
          nonNullable: true,
        }),
        unit: new FormControl<IOption>(this.unitOptions.find(opt => opt.value === record.unit) || this.unitOptions[0], {
          validators: [Validators.required],
          nonNullable: true,
        }),
        cO2Measured_t: new FormControl<string>(record.cO2Measured_t?.toString() || '', {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup<WasteRecordForm>(this.recordFc);
    }
  }

  getParams(): CreateWasteDataRecordCommandParams | UpdateWasteDataRecordCommandParams | undefined {
    const wasteClass = Object.values(WasteClass).find(type => type === this.recordFc?.wasteClass.value.value);
    const wasteSubClass = Object.values(WasteSubClass).find(type => type === this.recordFc?.wasteSubClass.value?.value);
    const toDisposal = Object.values(WasteToDisposal).find(type => type === this.recordFc?.toDisposal.value.value);
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value && wasteClass && toDisposal) {
      const waste = {
        type: DataRecordType.Waste,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        class: wasteClass,
        subclass: wasteSubClass,
        isHazardous: this.recordFc.isHazardous.value.value === 'true' ? true : false,
        toDisposal: toDisposal,
        quantity: formattedStringToNumber(this.recordFc.quantity.value || '') || 0,
        unit: this.recordFc.unit.value.value,
        cO2Measured_t: formattedStringToNumber(this.recordFc.cO2Measured_t.value || ''),
      };
      if (this.editRecordId) {
        return new UpdateWasteDataRecordCommandParams({ id: this.editRecordId, ...waste });
      } else {
        return new CreateWasteDataRecordCommandParams(waste);
      }
    }
    return undefined;
  }

  handleClassChange(option: IOption) {
    const wasteClass = Object.values(WasteClass).find(type => type === option.value);
    if (this.recordFc && wasteClass) {
      this.wasteSubClassOptions = WasteSubClassOptionsByClass[wasteClass];
      if (wasteClass === WasteClass.K_CargoResidues_Harmful) {
        this.hazardiousOptions = [{ value: 'true', label: 'Yes' }];
        this.handleDropdownChange(this.recordFc.isHazardous, this.hazardiousOptions[0]);
      } else
        this.hazardiousOptions = [
          { value: 'false', label: 'No' },
          { value: 'true', label: 'Yes' },
        ];
      this.handleDropdownChange(this.recordFc.wasteClass, option);
      this.handleSubClassChange(
        this.wasteSubClassOptions.find(sub => sub.value === this.recordFc?.wasteSubClass.value?.value) ||
          this.wasteSubClassOptions[0]
      );
    }
  }

  handleSubClassChange(option: IOption) {
    const wasteClass = Object.values(WasteClass).find(type => type === this.recordFc?.wasteClass.value?.value);
    const wasteSubClass = Object.values(WasteSubClass).find(type => type === option?.value);
    if (this.recordFc && wasteClass) {
      this.disposalOptions = getWasteHandlingOptions(wasteClass, wasteSubClass);
      this.handleDropdownChange(this.recordFc.wasteSubClass, option);
      this.handleDropdownChange(
        this.recordFc.toDisposal,
        this.disposalOptions.find(opt => opt.value === this.recordFc?.toDisposal.value?.value && !opt.disabled) ||
          this.disposalOptions[0]
      );
    }
  }

  resetForm() {
    super.resetForm();
    this.wasteSubClassOptions = WasteSubClassOptionsByClass[WasteClass.A_Plastics];
    this.disposalOptions = getWasteHandlingOptions(WasteClass.A_Plastics);
    this.hazardiousOptions = [
      { value: 'false', label: 'No' },
      { value: 'true', label: 'Yes' },
    ];
  }
}
