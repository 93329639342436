import { Component, Input } from '@angular/core';
import { IOption } from 'src/app/static-data/options';
import { SupplierDetailsStateService } from './supplier-details-state.service';

@Component({
  selector: 'esg-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['../../asset-details.component.scss', './supplier-details.component.scss'],
  providers: [SupplierDetailsStateService],
})
export class SupplierDetailsComponent {
  @Input({ required: true }) assetId!: string;
  @Input() organizationOptions: IOption[] = [];
  @Input() countryOptions: IOption[] = [];
  @Input() sectorsOptions: IOption[] = [];

  constructor(public state: SupplierDetailsStateService) {}
  ngOnInit(): void {
    this.state.init(this.assetId, this.organizationOptions, this.countryOptions, this.sectorsOptions);
  }
}
