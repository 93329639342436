import { Component } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { RouteItemUi } from 'src/app/shared/components/route-selector/route-selector.component';

@Component({
  selector: 'esg-assets-page',
  templateUrl: './assets-page.component.html',
  styleUrls: ['./assets-page.component.scss'],
})
export class AssetsPageComponent {
  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(private appConfig: AppConfigurationService) {}
}

export const AssetCategoryRoutes: RouteItemUi[] = [
  {
    label: 'Vessels',
    link: ['/assets/vessels'],
  },
  {
    label: 'Vehicles, Crafts & Machinery',
    link: ['/assets/vehicles-crafts-machinery'],
  },
  {
    label: 'Facilities',
    link: ['/assets/facilities'],
  },
  {
    label: 'Organizations',
    link: ['/assets/organizations'],
  },
  {
    label: 'Suppliers',
    link: ['/assets/suppliers'],
  },
  {
    label: 'Clients',
    link: ['/assets/clients'],
  },
];
