import { Component, Input, OnInit } from '@angular/core';
import { VesselDetailsStateService } from './vessel-details-state.service';
import { IOption } from 'src/app/static-data/options';
import { VesselType } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-vessel-details',
  templateUrl: './vessel-details.component.html',
  styleUrls: ['../../asset-details.component.scss', './vessel-details.component.scss'],
  providers: [VesselDetailsStateService],
})
export class VesselDetailsComponent implements OnInit {
  vesselTypes = VesselType;
  @Input({ required: true }) assetId!: string;
  @Input() organizationCountryMap = new Map<string, string>();
  @Input() organizationOptions: IOption[] = [];

  constructor(public state: VesselDetailsStateService) {}
  ngOnInit(): void {
    this.state.init(this.assetId, this.organizationOptions, this.organizationCountryMap);
  }
}
