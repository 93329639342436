import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  TopicItemType,
  UpdateTextDisclosureTopicItemCommandParams,
  CreateTextDisclosureTopicItemCommandParams,
} from 'src/api-client/report-api.generated';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { TopicItemContentUi } from '../topic-item-details/topic-item-details-state.service';

interface TextDisclosureForm {
  title: FormControl<string>;
  description: FormControl<string>;
  references: FormControl<string>;
}

@Injectable()
export class TextDisclosureFormStateService {
  textDisclosureFc?: TextDisclosureForm = undefined;
  formgroup?: FormGroup = undefined;

  headerText = 'Create new content';
  submitText = 'Create';

  constructor(private topicItemApiService: TopicItemApiService) {}

  initForm(selectedIndicator: TopicItemContentUi | undefined) {
    this.textDisclosureFc = {
      title: new FormControl<string>(selectedIndicator?.name || '', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      description: new FormControl<string>(selectedIndicator?.description || '', {
        nonNullable: true,
      }),
      references: new FormControl<string>(selectedIndicator?.referenceStandards || '', {
        nonNullable: true,
      }),
    };
    this.formgroup = new FormGroup(this.textDisclosureFc);
    this.headerText = selectedIndicator ? 'Edit Text Disclosure' : 'Create Text Disclosure';
    this.submitText = selectedIndicator ? 'Save changes' : 'Create';
  }

  async submitForm(reportId: string, mainLevelId: string, topicId: string, topicItemId?: string) {
    this.formgroup?.markAsPristine();
    const textDisclosureParams = {
      type: TopicItemType.TextDisclosure,
      name: this.textDisclosureFc?.title.value || '',
      description: this.textDisclosureFc?.description.value || '',
      referenceStandards: this.textDisclosureFc?.references.value || '',
    };
    if (topicItemId) {
      await this.topicItemApiService.updateTopicItem(
        new UpdateTextDisclosureTopicItemCommandParams({
          topicItemId: topicItemId,
          ...textDisclosureParams,
        })
      );
    } else {
      await this.topicItemApiService.addTopicItem(
        new CreateTextDisclosureTopicItemCommandParams({
          reportId: reportId,
          mainLevelId: mainLevelId,
          topicId: topicId,
          ...textDisclosureParams,
        })
      );
    }
  }
}
