import { Component } from '@angular/core';
import { VesselsStateService } from './vessels-state.service';
import { AssetListComponent } from '../asset-list.component';
import { AssetListStateService } from '../asset-list-state.service';

@Component({
  selector: 'esg-vessels',
  templateUrl: './vessels.component.html',
  styleUrls: ['../asset-list.component.scss'],
  providers: [{ provide: AssetListStateService, useClass: VesselsStateService }],
})
export class VesselsComponent extends AssetListComponent {}
