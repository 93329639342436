import { Component } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { RouteItemUi } from 'src/app/shared/components/route-selector/route-selector.component';

@Component({
  selector: 'esg-data-collect-page',
  templateUrl: './data-collect-page.component.html',
  styleUrls: ['./data-collect-page.component.scss'],
})
export class DataCollectPageComponent {
  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(private appConfig: AppConfigurationService) {}
}

export const RecordCategoryRoutes: RouteItemUi[] = [
  {
    label: 'Fuel Combustion',
    link: ['/data-collect/fuel-combustion'],
  },
  {
    label: 'Electricity',
    link: ['/data-collect/electricity'],
  },
  {
    label: 'Waste',
    link: ['/data-collect/waste'],
  },
  {
    label: 'Business Travel',
    link: ['/data-collect/business-travel'],
  },
  {
    label: 'Employee commuting',
    link: ['/data-collect/employee-commuting'],
  },
  {
    label: 'Spills to environment',
    link: ['/data-collect/spills-to-environment'],
  },
  {
    label: 'Marine Protected Areas',
    link: ['/data-collect/marine-protected-areas'],
  },
  {
    label: 'Gender, Position and Age Profiles',
    link: ['/data-collect/gender-diversity-equality'],
  },
  {
    label: 'Parental Leave',
    link: ['/data-collect/parental-leave'],
  },
  {
    label: 'Sick Leave',
    link: ['/data-collect/sick-leave'],
  },
  {
    label: 'Work related injuries',
    link: ['/data-collect/work-related-injuries'],
  },
  {
    label: 'Marine Casualties',
    link: ['/data-collect/marine-casualties'],
  },
  {
    label: 'New Hires and Turnovers',
    link: ['/data-collect/new-hires-and-turnovers'],
  },
  {
    label: 'Corruption risk',
    link: ['/data-collect/corruption-risk'],
  },
  {
    label: 'Fines',
    link: ['/data-collect/fines'],
  },
];
