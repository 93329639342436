import { Component } from '@angular/core';
import { UnitsStateService } from './units-state.service';

export interface UnitUi {
  key: string;
  name: string;
  description: string;
  isSystem: boolean;
}

@Component({
  selector: 'esg-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss'],
  providers: [UnitsStateService],
})
export class UnitsComponent {
  constructor(public state: UnitsStateService) {}

  handleCreateUnitClick(event: Event) {
    event.stopPropagation();
    this.state.createUnit();
  }

  handleSubmitCreate() {
    this.state.createUnitSubmit();
  }

  handleCreateUnitCancel() {
    this.state.cancelCreate();
  }

  handleDelete(unitKey: string) {
    this.state.deleteUnit(unitKey);
  }

  handleSubmitDelete() {
    this.state.deleteUnitSubmit();
  }

  handleCancelDelete() {
    this.state.cancelDelete();
  }

  trackByUnitKey(index: number, unit: UnitUi): string {
    return unit.key;
  }
}
