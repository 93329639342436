import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'esg-table-input',
  templateUrl: './table-input.component.html',
  styleUrls: ['./table-input.component.scss'],
})
export class TableInputComponent implements OnInit {
  @Input({ required: true }) name!: string;
  @Input({ required: true }) fc!: FormControl<string | null>;
  @Input() autoFocus = true;

  @Output() onEnter = new EventEmitter();
  inputEl: HTMLInputElement | unknown;
  initialValue: string | null = '';

  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement>) {
    this.inputEl = element.nativeElement;

    if (this.inputEl && this.autoFocus) {
      (this.inputEl as HTMLInputElement).focus();
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.initialValue = this.fc.value;
  }

  handleEnter() {
    this.onEnter.emit();
    this.initialValue = this.fc.value;
    if (this.inputEl) (this.inputEl as HTMLInputElement).blur();
  }

  handleEscape() {
    if (this.initialValue === this.fc.defaultValue) {
      this.fc.reset();
    } else {
      this.fc.setValue(this.initialValue);
    }
    if (this.inputEl) (this.inputEl as HTMLInputElement).blur();
  }
}
