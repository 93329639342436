import { Injectable } from '@angular/core';
import { UsersStateService } from '../users-state.service';

@Injectable()
export class AllUsersStateService extends UsersStateService {
  initData() {
    this.loadUsers();
    this.loadOrganizationOptions();
  }

  ngOnDestroy() {
    this.userInfoSub.unsubscribe();
  }
}
