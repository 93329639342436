import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: '[esg-on-click-outside] [onClickOutside]',
  templateUrl: './on-click-outside.component.html',
  styleUrls: ['./on-click-outside.component.scss'],
})
export class OnClickOutsideComponent {
  constructor(private el: ElementRef<HTMLElement>) {}
  @Input() ignoreIfSelected: boolean = false;
  @Output() onClickOutside = new EventEmitter();

  selectedText?: string;

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    if (this.ignoreIfSelected)
      this.selectedText =
        document.getSelection()?.toString() === this.selectedText ? undefined : document.getSelection()?.toString();
    const clickInside = this.el.nativeElement.contains(target);
    if (!clickInside && !this.selectedText) {
      this.onClickOutside.emit(target);
    }
  }
}
