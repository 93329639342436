import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-info-icon-filled',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 18 18"
      fill="none">
      <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" attr.fill="{{ color }}" />
      <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" attr.stroke="{{ color }}" />
      <path
        d="M7.4155 13.5C7.22869 13.5 7.07107 13.4447 6.94264 13.334C6.81421 13.2178 6.75 13.0712 6.75 12.8942C6.75 12.7282 6.81421 12.5871 6.94264 12.471C7.07107 12.3492 7.22869 12.2884 7.4155 12.2884H8.52758V8.37967H7.56436C7.38339 8.37967 7.22869 8.32434 7.10026 8.21369C6.97183 8.09751 6.90762 7.9509 6.90762 7.77386C6.90762 7.60788 6.97183 7.46681 7.10026 7.35062C7.22869 7.22891 7.38339 7.16805 7.56436 7.16805H9.26313C9.49664 7.16805 9.67177 7.23997 9.78853 7.38382C9.91112 7.52766 9.97242 7.71577 9.97242 7.94813V12.2884H11.0845C11.2713 12.2884 11.4289 12.3492 11.5574 12.471C11.6858 12.5871 11.75 12.7282 11.75 12.8942C11.75 13.0712 11.6858 13.2178 11.5574 13.334C11.4289 13.4447 11.2713 13.5 11.0845 13.5H7.4155ZM8.91287 5.74896C8.58596 5.74896 8.30575 5.64108 8.07224 5.42531C7.84457 5.20401 7.73074 4.93845 7.73074 4.62863C7.73074 4.31328 7.84457 4.04772 8.07224 3.83195C8.30575 3.61065 8.58596 3.5 8.91287 3.5C9.24562 3.5 9.52291 3.61065 9.74475 3.83195C9.97242 4.04772 10.0863 4.31328 10.0863 4.62863C10.0863 4.93845 9.97242 5.20401 9.74475 5.42531C9.52291 5.64108 9.24562 5.74896 8.91287 5.74896Z"
        fill="white" />
    </svg>
  `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class InfoIconFilledComponent {
  @Input() size: string = '18';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
