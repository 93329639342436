import { Component, SimpleChanges } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import {
  CreateOrganizationCorruptionRiskDataRecordCommandParams,
  CurrencyCodes,
  DataRecordType,
  InputType,
  OrganizationCorruptionRiskDataRecord,
  UpdateOrganizationCorruptionRiskDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface CorruptionRiskRecordForm extends RecordForm {
  callsAtPort: FormControl<string>;
  netRevenue: FormControl<string>;
  facilitation: FormControl<string>;
}

@Component({
  selector: 'esg-corruption-risk-form-table',
  templateUrl: './corruption-risk-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: CorruptionRiskFormTableComponent }],
})
export class CorruptionRiskFormTableComponent extends RecordFormTableComponent {
  headerCells: RecordHeaderCell[] = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'callsAtPort', label: 'Calls At Port', description: 'Number', number: true },
    { columnId: 'netRevenue', label: 'Net Revenue', description: this.organizationCurrency, number: true },
    { columnId: 'facilitation', label: 'Facilitation', description: 'Number', number: true },
  ];

  recordFc?: CorruptionRiskRecordForm = undefined;
  recordFormgroup?: FormGroup<CorruptionRiskRecordForm> = undefined;

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.organizationCurrency) {
      this.setCurrencyDescription(changes.organizationCurrency.currentValue);
    }
  }

  setCurrencyDescription(currency: string) {
    const currencyHeaderCell = this.headerCells.findIndex(cell => cell.columnId === 'netRevenue');
    this.headerCells[currencyHeaderCell].description = currency;
  }

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as OrganizationCorruptionRiskDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        { columnId: 'callsAtPort', value: record.callsAtPort.toString(), number: true },
        { columnId: 'netRevenue', value: record.netRevenue.toString(), number: true, unit: record.currencyCode },
        { columnId: 'facilitation', value: record.facilitation.toString(), number: true },
      ],
    }));
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      callsAtPort: new FormControl<string>('', { nonNullable: true }),
      netRevenue: new FormControl<string>('', { nonNullable: true }),
      facilitation: new FormControl<string>('', { nonNullable: true }),
    };
    this.recordFormgroup = new FormGroup<CorruptionRiskRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const record = this.dataRecords.find(record => record.id === id) as OrganizationCorruptionRiskDataRecord;
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        callsAtPort: new FormControl<string>(record.callsAtPort.toString(), { nonNullable: true }),
        netRevenue: new FormControl<string>(record.netRevenue.toString(), { nonNullable: true }),
        facilitation: new FormControl<string>(record.facilitation.toString(), { nonNullable: true }),
      };
      this.recordFormgroup = new FormGroup<CorruptionRiskRecordForm>(this.recordFc);
    }
  }

  getParams():
    | CreateOrganizationCorruptionRiskDataRecordCommandParams
    | UpdateOrganizationCorruptionRiskDataRecordCommandParams
    | undefined {
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const corruption = {
        type: DataRecordType.OrganizationCorruptionRisk,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        callsAtPort: formattedStringToNumber(this.recordFc.callsAtPort.value || '') || 0,
        netRevenue: formattedStringToNumber(this.recordFc.netRevenue.value || '') || 0,
        facilitation: formattedStringToNumber(this.recordFc.facilitation.value || '') || 0,
        currencyCode: this.organizationCurrency,
      };
      if (this.editRecordId) {
        return new UpdateOrganizationCorruptionRiskDataRecordCommandParams({ id: this.editRecordId, ...corruption });
      } else {
        return new CreateOrganizationCorruptionRiskDataRecordCommandParams(corruption);
      }
    }
    return undefined;
  }
}
