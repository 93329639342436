<nav>
  <a [routerLink]="['/example']">Examples</a>
  <a [routerLink]="['ui-components']">Ui components</a>
  <a [routerLink]="['data-import-from-excel']">Data Import from Excel</a>
  <a [routerLink]="['onboarding']">Onboarding</a>
  <a [routerLink]="['feedback-examples']">Feedback examples</a>
  <a [routerLink]="['shared-components']">Shared components</a>
  <a [routerLink]="['generic-table']">Generic table</a>
</nav>

<router-outlet></router-outlet>
