<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading indicators"></esg-dot-flashing>

<ng-container *ngIf="!state.loading">
  <h2 class="header">Indicator Library</h2>
  <div
    class="table-observer"
    esgIntersectionObserver
    [threshold]="0"
    rootMargin="124px 0px 0px 0px"
    (onIntersection)="handleStickyHeaders($event)"></div>
  <table [class.sticky]="stickyHeaders">
    <thead>
      <tr>
        <th></th>
        <th>Indicator</th>
        <th>Description</th>
        <th>Sectors</th>
        <th>Disclosure reference</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let indicator of state.indicatorDefinitions; let i = index; trackBy: trackByIndicator">
        <td>
          <button
            *ngIf="!indicator.isAdded && state.hasAddPermission"
            class="add-button"
            (click)="handleAddIndicator(indicator.id)">
            <esg-icon-add></esg-icon-add>
          </button>
          <span *ngIf="indicator.isAdded" class="rounded-checkmark">
            <esg-checkmark></esg-checkmark>
          </span>
        </td>
        <td>
          <span class="indicator-name">{{ indicator.name }}</span>
          <span class="colored-divider" [style.background]="state.CategoryColors[indicator.category]"></span>
          <span class="indicator-category">{{ state.CategoryLabels[indicator.category] }}</span>
        </td>
        <td>
          {{ indicator.description }}
        </td>
        <td>
          {{ state.getSectorsStringList(indicator.sectors) }}
        </td>
        <td>
          {{ indicator.frameworkStandards }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
