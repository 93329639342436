<esg-dialog class="auto" (onClose)="handleClose()">
  <div class="video-container" (click)="handleVideoClick()">
    <video #video class="video" loop [poster]="trustedImageSrc">
      <source *ngIf="trustedVideoSrc" [src]="trustedVideoSrc" type="video/mp4" />
    </video>
    <esg-dot-flashing *ngIf="!isVideoLoaded" class="loader" label=""></esg-dot-flashing>

    <ng-container *ngIf="!isPlayingVideo">
      <esg-play-icon *ngIf="isVideoLoaded"></esg-play-icon>
      <div class="pause-overlay"></div>
    </ng-container>
  </div>
</esg-dialog>
