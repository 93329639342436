import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AssetType,
  CountryCodes,
  EnvironmentalRiskAssessment,
  RiskCategory,
  SectorType,
  SocialRiskAssessment,
  SupplierAsset,
  SupplierStatus,
  UpdateSupplierAssetCommandParams,
} from 'src/api-client/report-api.generated';
import { IOption, SupplierStatusOptions } from 'src/app/static-data/options';
import { AssetDetailsForm, AssetDetailsStateService } from '../../asset-details-state.service';

interface SuplierDetailsForm extends AssetDetailsForm {
  orgId: FormControl<string>;
  country: FormControl<IOption | undefined>;
  sector: FormControl<IOption | undefined>;
  status: FormControl<IOption | undefined>;
  address: FormControl<string>;
  postalNumber: FormControl<string>;
  city: FormControl<string>;
  contactPerson: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
}

@Injectable()
export class SupplierDetailsStateService extends AssetDetailsStateService {
  countryOptions: IOption[] = [];
  sectorOptions: IOption[] = [];
  statusOptions: IOption[] = SupplierStatusOptions;
  socialRiskAssessment?: SocialRiskAssessment;
  environmentalRiskAssessment?: EnvironmentalRiskAssessment;
  fc?: SuplierDetailsForm;

  RiskColors: { [key in RiskCategory]: string } = {
    [RiskCategory.Unknown]: '#999999',
    [RiskCategory.VeryHighRisk]: '#EA1F4C',
    [RiskCategory.HighRisk]: '#EA708A',
    [RiskCategory.MediumRisk]: '#FF9900',
    [RiskCategory.LowRisk]: '#72DC9B',
    [RiskCategory.NoRisk]: '#23D468',
  };

  RiskLabels: { [key in RiskCategory]: string } = {
    [RiskCategory.Unknown]: 'n/a*',
    [RiskCategory.VeryHighRisk]: 'Very High',
    [RiskCategory.HighRisk]: 'High',
    [RiskCategory.MediumRisk]: 'Medium',
    [RiskCategory.LowRisk]: 'Low',
    [RiskCategory.NoRisk]: 'No Risk',
  };

  async init(assetId: string, organizationOptions: IOption[], countryOptions: IOption[], sectorOptions: IOption[]) {
    this.loading = true;
    this.assetId = assetId;
    this.organizationOptions = organizationOptions;
    this.countryOptions = countryOptions;
    this.sectorOptions = sectorOptions;
    await this.getAssetVersions();
    this.loading = false;
  }

  async getAssetVersion(versionId: string) {
    const response = await this.assetApiService.getAssetById(this.assetId, versionId);
    const supplier = response.result as SupplierAsset;
    this.socialRiskAssessment = supplier.socialRiskAssessment;
    this.environmentalRiskAssessment = supplier.environmentalRiskAssessment;
    this.mainOrganizationId = supplier.organizationId;

    this.fc = {
      versionValidFrom: new FormControl<Date>(supplier.versionValidFrom, { nonNullable: true }),
      name: new FormControl<string>(supplier.name, { validators: [Validators.required], nonNullable: true }),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(country => country.value === supplier?.countryCode),
        { validators: [Validators.required], nonNullable: true }
      ),
      sector: new FormControl<IOption | undefined>(
        this.sectorOptions.find(s => s.value === supplier?.sector),
        { validators: [Validators.required], nonNullable: true }
      ),
      status: new FormControl<IOption | undefined>(
        this.statusOptions.find(s => s.value === supplier?.status),
        { validators: [Validators.required], nonNullable: true }
      ),
      organization: new FormControl<IOption | undefined>(
        this.organizationOptions?.find(org =>
          supplier?.subOrganizationId
            ? org.value === supplier?.subOrganizationId
            : org.value === supplier?.organizationId
        ),
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      orgId: new FormControl<string>(supplier.orgID || '', { validators: [Validators.required], nonNullable: true }),
      address: new FormControl<string>(supplier.address || '', { nonNullable: true }),
      postalNumber: new FormControl<string>(supplier.postalNumber || '', { nonNullable: true }),
      city: new FormControl<string>(supplier.city || '', { nonNullable: true }),
      contactPerson: new FormControl<string>(supplier.contactPerson || '', { nonNullable: true }),
      email: new FormControl<string>(supplier.email || '', { nonNullable: true }),
      phone: new FormControl<string>(supplier.phone || '', { nonNullable: true }),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  async updateAsset(versionId: string) {
    const countryCode = Object.values(CountryCodes).find(code => code === this.fc?.country?.value?.value);
    const sector = this.fc?.sector?.value?.value
      ? SectorType[this.fc.sector?.value?.value as keyof typeof SectorType]
      : undefined;
    const status = this.fc?.status?.value?.value
      ? SupplierStatus[this.fc.status?.value?.value as keyof typeof SupplierStatus]
      : undefined;
    if (this.fc && countryCode && sector && status) {
      return await this.assetApiService.updateAsset(
        new UpdateSupplierAssetCommandParams({
          id: this.assetId,
          type: AssetType.Supplier,
          name: this.fc.name.value,
          versionId: versionId,
          subOrganizationId:
            this.fc.organization.value?.value === this.mainOrganizationId
              ? undefined
              : this.fc.organization.value?.value,
          countryCode: countryCode,
          sector: sector,
          address: this.fc.address.value,
          postalNumber: this.fc.postalNumber.value,
          city: this.fc.city.value,
          contactPerson: this.fc.contactPerson.value,
          email: this.fc.email.value,
          phone: this.fc.phone.value,
          status: status,
          orgID: this.fc.orgId.value,
          versionValidFrom: this.fc.versionValidFrom.value,
        })
      );
    } else return undefined;
  }

  getScore(score: number) {
    return isNaN(score) ? 'n/a*' : score;
  }
}
