import { Injectable } from '@angular/core';
import {
  DataRecordType,
  CreateEmployeeGenderDiversityAndEqualityDataRecordCommandParams,
  DataRecordCategory,
  Gender,
  EmploymentType,
  PositionLevel,
  Location,
  ICreateEmployeeGenderDiversityAndEqualityDataRecordCommandParams,
  InputType,
  ContractType,
} from 'src/api-client/report-api.generated';
import {
  ContractOptions,
  EmploymentOptions,
  GenderOptions,
  IOption,
  LocationOptions,
  PositionLevelOptions,
  generateAgeOptions,
} from 'src/app/static-data/options';
import { RecordsStateService } from '../../../records/records-state.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegionClientApiService } from 'src/app/api-client/report-api/region-api-service';
import { sortOptionsAlphabetically } from 'src/app/shared/utils/array';
import { FieldConfig } from 'src/app/shared/components/data-import-from-excel/excel-exporter';
import {
  ExcelImportValidationMessage,
  ImportDataOutput,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { IntegrationApiService } from 'src/app/api-client/integration-api/integration-api-service';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class GenderDiversityEqualityDetailsRecordsStateService extends RecordsStateService {
  title = 'Employee profile records';
  recordCategory = DataRecordCategory.EmployeesGenderEqualityAndDiversity;

  profileId?: string;
  assetId?: string;

  regionOptions: IOption[] = [];
  ageOptions = generateAgeOptions();
  employmentOptions = EmploymentOptions;
  contractOptions = ContractOptions;
  genderOptions = GenderOptions;
  positionLevelOptions = PositionLevelOptions;
  locationOptions = LocationOptions;

  importFromExcelEnabled = true;
  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'employeeId', title: 'Employee', type: 'string' },
    { propertyName: 'yearOfBirth', title: 'Year Of Birth', type: 'number' },
    { propertyName: 'gender', title: 'Gender', type: 'string' },
    { propertyName: 'employment', title: 'Employment', type: 'string', optional: true },
    { propertyName: 'contract', title: 'Contract', type: 'string', optional: true },
    { propertyName: 'position', title: 'Position', type: 'string' },
    { propertyName: 'location', title: 'Location', type: 'string', optional: true },
    { propertyName: 'region', title: 'Region', type: 'string', optional: true },
    { propertyName: 'salary', title: 'Salary', type: 'number', optional: true },
  ];

  get intersectionObserverRootMargin() {
    if (this.appConfig.NewNavbarEnabled) {
      return 136;
    } else return 181;
  }

  importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    let validationMessages: ExcelImportValidationMessage[] = [];

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'gender',
      this.genderOptions,
      data.records,
      'Gender'
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'employment',
      this.employmentOptions,
      data.records,
      'Employment',
      false
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'contract',
      this.contractOptions,
      data.records,
      'Contract',
      false
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'position',
      this.positionLevelOptions,
      data.records,
      'Position Level'
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'location',
      this.locationOptions,
      data.records,
      'Location',
      false
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'region',
      this.regionOptions,
      data.records,
      'Region',
      false
    );

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    const addRecordPromises = data.records.map((record, index) => {
      const gender = this.getExcelOptionColumn('gender', this.genderOptions, record, 'Gender');
      const employment = this.employmentOptions.find(
        type =>
          type.label.toUpperCase() === record.employment?.value?.toUpperCase() ||
          type.value.toUpperCase() === record.employment?.value?.toUpperCase()
      );
      const contract = this.contractOptions.find(
        type =>
          type.label.toUpperCase() === record.contract?.value?.toUpperCase() ||
          type.value.toUpperCase() === record.contract?.value?.toUpperCase()
      );
      const positionLevel = this.getExcelOptionColumn('position', this.positionLevelOptions, record, 'Position level');
      const location = this.locationOptions.find(
        type =>
          type.label.toUpperCase() === record.location?.value?.toUpperCase() ||
          type.value.toUpperCase() === record.location?.value?.toUpperCase()
      );
      const region = this.regionOptions.find(
        region =>
          region.label.toUpperCase() === record.region?.value?.toUpperCase() ||
          region.value.toUpperCase() === record.region?.value?.toUpperCase()
      );

      const genderRecord: ICreateEmployeeGenderDiversityAndEqualityDataRecordCommandParams = {
        dataProfileId: this.profileId || '',
        dataSetId: dataSetId,
        assetId: this.assetId || '',
        inputType: InputType.ImportedFromExcel,
        type: DataRecordType.EmployeeGenderDiversityAndEquality,
        employee: record.employeeId?.value,
        yearOfBirth: record.yearOfBirth?.value || 0,
        gender: Gender[gender.value as keyof typeof Gender],
        employment: employment ? EmploymentType[employment.value as keyof typeof EmploymentType] : undefined,
        contract: contract ? ContractType[contract.value as keyof typeof ContractType] : undefined,
        positionLevel: PositionLevel[positionLevel.value as keyof typeof PositionLevel],
        location: location ? Location[location.value as keyof typeof Location] : undefined,
        regionId: region?.value,
        salary: record.salary?.value,
      };

      return this.dataRecordService.createDataRecord(
        new CreateEmployeeGenderDiversityAndEqualityDataRecordCommandParams({ ...genderRecord })
      );
    });
    Promise.all(addRecordPromises).then(async response => {
      response.forEach((res, index) => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });
      await this.loadRecords();
      this.importingInProgress = false;
      this.importDataFromExcelClose();
    });
  }

  public get showImportFromUniSeaButton() {
    return this.appInfo.userInfo.value?.features.importFromUniSea;
  }

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    assetApiService: AssetApiService,
    dataRecordService: DataRecordApiService,
    dataCategoryApiService: DataCategoryApiService,
    private integrationApiService: IntegrationApiService,
    notificationService: NotificationService,
    router: Router,
    route: ActivatedRoute,
    private regionApiService: RegionClientApiService
  ) {
    super(
      appInfo,
      appConfig,
      assetApiService,
      dataRecordService,
      dataCategoryApiService,
      notificationService,
      router,
      route
    );
  }

  setProfileId(profileId: string) {
    this.profileId = profileId;
  }

  setAssetId(assetId: string) {
    this.assetId = assetId;
  }

  async fetchStaticData() {
    await Promise.all([this.getRegions(), this.getDataCategoryDetails()]);
  }

  async fetchOptions() {
    await this.getRegions();
  }

  async getRegions() {
    const response = await this.regionApiService.getAllRegionNames();
    this.regionOptions = sortOptionsAlphabetically(
      response.result.map(region => ({ value: region.id, label: region.name }))
    );
  }

  async loadRecords() {
    await this.getDataRecords(DataRecordType.EmployeeGenderDiversityAndEquality, this.profileId);
  }
}
