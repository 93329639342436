<div class="content">
  <ng-container *ngIf="!state.loading">
    <h2 class="title">{{ state.fc?.name?.value }}</h2>
    <div class="asset-details">
      <esg-asset-version-form
        *ngIf="state.fc"
        [active]="state.isFormOpen"
        [saveDisabled]="(!state.newVersionDate && !state.formgroup?.dirty) || !state.formgroup?.valid"
        [deleteDisabled]="state.assetVersions.length < 2 || state.newVersionDate ? true : false"
        (onClick)="state.handleOnFormClick()"
        (onSave)="state.handleVersionSubmit()"
        (onDelete)="state.handleOnDeleteClick()"
        (onCancel)="state.handleCancel()">
        <div class="static-data" [class.active]="state.isFormOpen">
          <span class="input-label">Version valid from</span>
          <div class="input">
            <esg-datepicker
              [fc]="state.fc.versionValidFrom"
              variant="ghost"
              (onOpen)="state.handleValidFromOpen()"
              (onClose)="state.handleValidFromClose()"
              (onChange)="$event && state.handleValidFromChange($event)"></esg-datepicker>
          </div>
          <span class="input-label">Country</span>
          <div class="input">
            <esg-select
              [options]="countryOptions"
              variant="ghost"
              [disabled]="countryOptions.length === 1"
              size="small"
              [value]="state.fc.country.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.country, $event)"></esg-select>
          </div>
          <span class="input-label">Sector</span>
          <div class="input">
            <esg-select
              [options]="sectorsOptions"
              variant="ghost"
              [disabled]="sectorsOptions.length === 1"
              size="small"
              [value]="state.fc.sector.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.sector, $event)"></esg-select>
          </div>
          <span class="input-label">Status</span>
          <div class="input">
            <esg-select
              [options]="state.statusOptions"
              variant="ghost"
              [disabled]="state.statusOptions.length === 1"
              size="small"
              [value]="state.fc.status.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.status, $event)"></esg-select>
          </div>
          <span class="input-label">Supplier to</span>
          <div class="input">
            <esg-select
              [options]="organizationOptions"
              variant="ghost"
              [disabled]="organizationOptions.length === 1"
              size="small"
              [value]="state.fc.organization.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.organization, $event)"></esg-select>
          </div>

          <span class="input-label" style="margin-top: 20px">Org ID</span>
          <div class="input" style="margin-top: 20px">
            <esg-textbox-form
              [fc]="state.fc.orgId"
              type="text"
              variant="ghost"
              placeholder="NO 123 456 780 ABC"
              (onChange)="state.fc.orgId.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">Address</span>
          <div class="input">
            <esg-textbox-form
              [fc]="state.fc.address"
              type="text"
              variant="ghost"
              placeholder="Street"
              (onChange)="state.fc.address.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">Postal number</span>
          <div class="input">
            <esg-textbox-form
              [fc]="state.fc.postalNumber"
              type="text"
              variant="ghost"
              placeholder=" Postal Number"
              (onChange)="state.fc.postalNumber.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">City</span>
          <div class="input">
            <esg-textbox-form
              [fc]="state.fc.city"
              type="text"
              variant="ghost"
              placeholder="City"
              (onChange)="state.fc.city.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">Contact person</span>
          <div class="input">
            <esg-textbox-form
              [fc]="state.fc.contactPerson"
              type="text"
              variant="ghost"
              placeholder="e.g John Smith"
              (onChange)="state.fc.contactPerson.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">E-mail</span>
          <div class="input">
            <esg-textbox-form
              [fc]="state.fc.email"
              type="text"
              variant="ghost"
              placeholder="e.g john.smith@example.com"
              (onChange)="state.fc.email.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">Phone</span>
          <div class="input">
            <esg-textbox-form
              [fc]="state.fc.phone"
              type="text"
              variant="ghost"
              placeholder="+ 44 12 34 56 789"
              (onChange)="state.fc.phone.setValue($event)"></esg-textbox-form>
          </div>
        </div>
      </esg-asset-version-form>
      <esg-asset-version-menu
        *ngIf="state.fc && state.activeVersion"
        [assetVersions]="state.assetVersions"
        [activeVersion]="state.activeVersion"
        [newVersion]="state.newVersionDate"
        (onVersionClick)="state.handleVersionClick($event)"
        (onNewVersionClick)="state.handleNewVersion()"></esg-asset-version-menu>
    </div>
    <div class="risk">
      <div class="risk-overview">
        <div class="risk-title">Supply Chain Assessment</div>
        <div class="risk-sections">
          <div *ngIf="state.socialRiskAssessment" class="risk-section">
            <div class="risk-section-label active">Social Assessment</div>
            <div class="risk-section-score">
              <esg-risk-bar [score]="state.socialRiskAssessment.score"></esg-risk-bar>
            </div>
            <table class="risk-section-table">
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Score</th>
                  <th>Risk</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Labour Rights</td>
                  <td>{{ state.getScore(state.socialRiskAssessment.labourRightsScore) }}</td>
                  <td>
                    <span
                      class="risk-color-code"
                      [style.background]="state.RiskColors[state.socialRiskAssessment.labourRightsRisk]"></span>
                    {{ state.RiskLabels[state.socialRiskAssessment.labourRightsRisk] }}
                  </td>
                </tr>
                <tr>
                  <td>Child Labour</td>
                  <td>{{ state.getScore(state.socialRiskAssessment.childLabourScore) }}</td>
                  <td>
                    <span
                      class="risk-color-code"
                      [style.background]="state.RiskColors[state.socialRiskAssessment.childLabourRisk]"></span>
                    {{ state.RiskLabels[state.socialRiskAssessment.childLabourRisk] }}
                  </td>
                </tr>
                <tr>
                  <td>Freedom Rights</td>
                  <td>{{ state.getScore(state.socialRiskAssessment.freedomRightsScore) }}</td>
                  <td>
                    <span
                      class="risk-color-code"
                      [style.background]="state.RiskColors[state.socialRiskAssessment.freedomRightsRisk]"></span>
                    {{ state.RiskLabels[state.socialRiskAssessment.freedomRightsRisk] }}
                  </td>
                </tr>
                <tr>
                  <td>Corruption Risk</td>
                  <td>{{ state.getScore(state.socialRiskAssessment.corruptionRiskScore) }}</td>
                  <td>
                    <span
                      class="risk-color-code"
                      [style.background]="state.RiskColors[state.socialRiskAssessment.corruptionRisk]"></span>
                    {{ state.RiskLabels[state.socialRiskAssessment.corruptionRisk] }}
                  </td>
                </tr>
              </tbody>
            </table>
            <span *ngIf="state.socialRiskAssessment.calculationProblemDescription" class="risk-error">
              *Index is not available for the country of the supplier. Total social risk assessment is not available.
            </span>
          </div>
          <div *ngIf="state.environmentalRiskAssessment" class="risk-section">
            <div class="risk-section-label active">Environmental Assessment</div>
            <div class="risk-section-score">
              <esg-risk-bar [score]="state.environmentalRiskAssessment.score"></esg-risk-bar>
            </div>
            <table class="risk-section-table">
              <thead>
                <tr>
                  <th>Sector</th>
                  <th>Score</th>
                  <th>Risk</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ state.fc?.sector?.value?.label || '' }}</td>
                  <td>{{ state.getScore(state.environmentalRiskAssessment.score) }}</td>
                  <td>
                    <span
                      class="risk-color-code"
                      [style.background]="state.RiskColors[state.environmentalRiskAssessment.risk]"></span>
                    {{ state.RiskLabels[state.environmentalRiskAssessment.risk] }}
                  </td>
                </tr>
              </tbody>
            </table>
            <span *ngIf="state.environmentalRiskAssessment.calculationProblemDescription" class="risk-error">
              *Index is not available for the sector of the supplier. Total environment risk assessment is not
              available.
            </span>
          </div>
        </div>
        <div class="risk-sources">
          <p>
            <span class="bold">Sources:</span>
            <br />
            All indexes used for screening is converted to a 1-100 index score.
          </p>
          <p>
            <span class="bold italic">Social Screening</span>
            <br />
            <a
              href="https://www.etuc.org/sites/default/files/press-release/file/2023-06/ITUC-Global-Rights-Index-2023.pdf"
              target="_blank"
              rel="noopener noreferrer">
              Global rights Index
            </a>
            <br />
            <a href="https://freedomhouse.org/countries/freedom-world/scores" target="_blank" rel="noopener noreferrer">
              Freedom House
            </a>
            <br />
            <a
              href="https://data.unicef.org/resources/data_explorer/unicef_f/?ag=UNICEF&df=GLOBAL_DATAFLOW&ver=1.0&dq=.PT_CHLD_5-17_LBR_ECON._T.&startPeriod=2016&endPeriod=2022"
              target="_blank"
              rel="noopener noreferrer">
              UNICEF Child Labour Indicator
            </a>
            <br />
            <a href="https://www.transparency.org/en/cpi/2022" target="_blank" rel="noopener noreferrer">
              Transparency International Corruption Index
            </a>
          </p>

          <p>
            <span class="bold italic">Environmental Screening</span>
            <br />
            <a
              href="https://www.spglobal.com/ratings/en/research/articles/200722-environmental-social-and-governance-the-esg-risk-atlas-sector-and-regional-rationales-and-scores-11582800"
              target="_blank"
              rel="noopener noreferrer">
              SP Global Risk Atlas
            </a>
            <br />
            <a
              href="https://www.globalreporting.org/standards/sector-program/"
              target="_blank"
              rel="noopener noreferrer">
              GRI Sector Program
            </a>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
  <esg-dot-flashing *ngIf="state.loading" class="loader" label="We are loading asset details"></esg-dot-flashing>
</div>
<esg-confirm-dialog
  *ngIf="state.isDeleteDialogOpen"
  [message]="'Once you delete this version, you delete all its\n content, and there is no turning back.'"
  cancelText="No, keep it"
  (onCancel)="state.handleDeleteDialogClose()"
  (onSubmit)="state.handleVersionDelete()"></esg-confirm-dialog>
