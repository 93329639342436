<table>
  <thead>
    <tr>
      <th></th>
      <th>
        <div class="content">
          <span class="label">Unit</span>
          <span *ngIf="isCustomPeriod" class="description"></span>
        </div>
      </th>
      <th class="number">
        <div class="content">
          <span class="label target">{{ isCustomPeriod ? 'target' : 'target ' + getFigureTimeLabel(1) }}</span>
          <span *ngIf="isCustomPeriod" class="description">{{ getFigureTimeLabel(1) }}</span>
        </div>
      </th>
      <th class="number">
        <div class="content">
          <span class="label current">{{ isCustomPeriod ? 'current' : getFigureTimeLabel(0) }}</span>
          <span *ngIf="isCustomPeriod" class="description">{{ getFigureTimeLabel(0) }}</span>
        </div>
      </th>
      <th class="number">
        <div class="content">
          <span class="label">{{ isCustomPeriod ? 'previous' : getFigureTimeLabel(-1) }}</span>
          <span *ngIf="isCustomPeriod" class="description">{{ getFigureTimeLabel(-1) }}</span>
        </div>
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let value of indicatorValues; index as i">
      <tr
        *ngIf="value.metadata.isVisibleInReport"
        class="main-row"
        [class.active-input]="editIndex === i && isInputModalOpen"
        [class.expanded]="this.expandedRowIds.includes(i)">
        <td class="name" (dblclick)="value.substrateData && handleExpandRow(i)">{{ value.metadata.label }}</td>
        <td class="unit">
          <ng-container *ngIf="value.metadata.unitDescription">
            <esg-tooltip [text]="value.metadata.unitDescription | formatUnitWithUnicode">
              {{ value.currentValue.unitDetails || value.metadata.unit | formatUnitWithUnicode }}
            </esg-tooltip>
          </ng-container>
          <ng-container *ngIf="!value.metadata.unitDescription">
            {{ value.currentValue.unitDetails || value.metadata.unit | formatUnitWithUnicode }}
          </ng-container>
        </td>

        <td
          class="number"
          [class.empty]="!value.indicatorId && !value.targetValue.value?.toString()"
          [class.disabled-hover]="value.indicatorId">
          <esg-tooltip
            *ngIf="value.targetValue.errorMessage"
            class="warning-tooltip"
            [text]="value.targetValue.errorMessage"
            position="bottom"
            variant="dark"
            [underline]="false">
            <esg-warning-icon class="warning-icon"></esg-warning-icon>
          </esg-tooltip>
          <span
            *ngIf="!value.targetValue.errorMessage"
            tabindex="0"
            (focus)="!value.indicatorId && handleOnEditingTarget(i)"
            (click)="!value.indicatorId && handleOnEditingTarget(i)">
            {{
              formatNumberCell(value.targetValue.value?.toString() || '') ||
                (value.indicatorId ? 'Not set' : 'Type number')
            }}
          </span>
          <esg-indicator-input
            *ngIf="editIndex === i && targetValue"
            [isCalculated]="false"
            [fc]="targetValue"
            [header]="getFigureTimeLabel(1)"
            [unit]="value.targetValue.unitDetails || value.metadata.unit || ''"
            (onClose)="handleOnCloseInputModal()"
            (onSubmit)="handleOnSubmit(i)"></esg-indicator-input>
        </td>
        <td
          class="number"
          [class.empty]="
            value.isCurrentValueConnected &&
            !value.indicatorId &&
            !formatNumberCell(value.currentValue.value?.toString() || '')
          "
          [class.disabled-hover]="value.indicatorId">
          <esg-tooltip
            *ngIf="value.currentValue.errorMessage"
            class="warning-tooltip"
            [text]="value.currentValue.errorMessage"
            position="bottom"
            variant="dark"
            [underline]="false">
            <esg-warning-icon class="warning-icon"></esg-warning-icon>
          </esg-tooltip>
          <span
            *ngIf="!value.currentValue.errorMessage"
            tabindex="0"
            (focus)="!value.indicatorId && handleOnEditingCurrent(i)"
            (click)="!value.indicatorId && handleOnEditingCurrent(i)">
            {{
              formatNumberCell(value.currentValue.value?.toString() || '') ||
                (value.isCurrentValueConnected && !value.indicatorId ? 'Update data' : '-')
            }}
          </span>
          <esg-indicator-input
            *ngIf="editIndex === i && currentValue"
            [isCalculated]="isCalculated"
            [fc]="currentValue"
            [header]="getFigureTimeLabel(0)"
            [unit]="value.currentValue.unitDetails || value.metadata.unit || ''"
            (onClose)="handleOnCloseInputModal()"
            (onSubmit)="handleOnSubmit(i)"
            (onGoToDatacollect)="onGoToDatacollect.emit()"
            (onSetConnection)="
              handleOnSetConnection(i, value.isPreviousValueConnected, !value.isCurrentValueConnected)
            "></esg-indicator-input>
        </td>
        <td
          class="number"
          [class.empty]="
            value.isPreviousValueConnected &&
            !value.indicatorId &&
            !formatNumberCell(value.previousValue.value?.toString() || '')
          "
          [class.disabled-hover]="value.indicatorId">
          <esg-tooltip
            *ngIf="value.previousValue.errorMessage"
            class="warning-tooltip"
            [text]="value.previousValue.errorMessage"
            position="bottom"
            variant="dark"
            [underline]="false">
            <esg-warning-icon class="warning-icon"></esg-warning-icon>
          </esg-tooltip>
          <span
            *ngIf="!value.previousValue.errorMessage"
            tabindex="0"
            (focus)="!value.indicatorId && handleOnEditingPrevious(i)"
            (click)="!value.indicatorId && handleOnEditingPrevious(i)">
            {{
              formatNumberCell(value.previousValue.value?.toString() || '') ||
                (value.isPreviousValueConnected && !value.indicatorId ? 'Update data' : '-')
            }}
          </span>
          <esg-indicator-input
            *ngIf="editIndex === i && previousValue"
            [isCalculated]="isCalculated"
            [fc]="previousValue"
            [header]="getFigureTimeLabel(-1)"
            [unit]="value.previousValue.unitDetails || value.metadata.unit || ''"
            (onClose)="handleOnCloseInputModal()"
            (onSubmit)="handleOnSubmit(i)"
            (onGoToDatacollect)="onGoToDatacollect.emit()"
            (onSetConnection)="
              handleOnSetConnection(i, !value.isPreviousValueConnected, value.isCurrentValueConnected)
            "></esg-indicator-input>
        </td>
        <td class="button" (click)="value.substrateData && handleExpandRow(i)">
          <esg-icon-expanders *ngIf="value.substrateData"></esg-icon-expanders>
        </td>
      </tr>

      <tr *ngIf="value.substrateData && this.expandedRowIds.includes(i)" class="substrate-data">
        <td>
          <esg-substrate-data [substrateData]="value.substrateData"></esg-substrate-data>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
