import { Component } from '@angular/core';
import { RegionsStateService } from './regions-state.service';

export interface RegionUi {
  id: string;
  name: string;
}

export enum ViewModeEnums {
  Read,
  Create,
  Edit,
}

@Component({
  selector: 'esg-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss'],
  providers: [RegionsStateService],
})
export class RegionsComponent {
  public ViewModes = ViewModeEnums;

  constructor(public state: RegionsStateService) {}

  handleClickCreate(event: Event) {
    event.stopPropagation();
    this.state.create();
  }

  handleSubmitCreate() {
    this.state.submitCreate();
  }

  handleCancelCreate() {
    this.state.cancelCreate();
  }

  handleEditRegion(region: RegionUi) {
    this.state.editRegion(region);
  }

  handleSubmitEdit() {
    this.state.submitEdit();
  }

  handleCancelEdit() {
    this.state.cancelEdit();
  }

  handleDelete(region: RegionUi) {
    this.state.deleteRegion(region);
  }

  handleSubmitDelete() {
    this.state.deleteRegionSubmit();
  }

  handleCancelDelete() {
    this.state.cancelDelete();
  }
}
