import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { KeyFiguresComponent } from './pages/key-figures/key-figures.component';
import { MainTopicFeedComponent } from './pages/main-topic-feed/main-topic-feed.component';
import { ReportPreviewComponent } from './report-preview.component';
import { PreviewLayoutComponent } from './layout/preview-layout.component';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { DynamicSeperatorComponent } from './shared/components/dynamic-seperator/dynamic-seperator.component';
import { DynamicTableHeaderCellComponent } from './shared/components/dynamic-table-header-cell/dynamic-table-header-cell.component';
import { MsalGuard } from '@azure/msal-angular';
import { PreviewNavbarComponent } from './layout/preview-navbar/preview-navbar.component';
import { TableOfContentsComponent } from './shared/components/table-of-contents/table-of-contents.component';
import { PreviewSideMenuComponent } from './shared/components/preview-side-menu/preview-side-menu.component';
import { MobileNavigationComponent } from './shared/components/mobile-navigation/mobile-navigation.component';
import { DirectiveModule } from '../shared/directives/directive.module';
import { TopicTabsComponent } from './shared/components/topic-tabs/topic-tabs.component';
import { FormatUnitWithUnicodePipe } from '../shared/pipes/format-unit-with-unicode.pipe';
import { ReportShareDialogComponent } from './report-share-dialog/report-share-dialog.component';

export const RootPathOfTheSharePage = 'share';

@NgModule({
  declarations: [
    PreviewLayoutComponent,
    MainTopicFeedComponent,
    KeyFiguresComponent,
    ReportPreviewComponent,
    DynamicSeperatorComponent,
    DynamicTableHeaderCellComponent,
    PreviewNavbarComponent,
    TableOfContentsComponent,
    PreviewSideMenuComponent,
    MobileNavigationComponent,
    TopicTabsComponent,
    ReportShareDialogComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    DirectiveModule,
    SharedComponentsModule,
    FormatUnitWithUnicodePipe,
    RouterModule.forRoot([
      {
        path: 'preview/:id',
        component: ReportPreviewComponent,
        children: [
          { path: '', redirectTo: 'key-figures', pathMatch: 'full' },
          { path: 'key-figures', component: KeyFiguresComponent },
          { path: ':mainLevelId', component: MainTopicFeedComponent },
          { path: ':mainLevelId/:topicId', component: MainTopicFeedComponent },
        ],
        canActivate: [MsalGuard],
      },
      {
        path: `${RootPathOfTheSharePage}/:id`,
        component: ReportPreviewComponent,
        children: [
          { path: '', redirectTo: 'key-figures', pathMatch: 'full' },
          { path: 'key-figures', component: KeyFiguresComponent },
          { path: ':mainLevelId', component: MainTopicFeedComponent },
          { path: ':mainLevelId/:topicId', component: MainTopicFeedComponent },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class ReportPreviewModule {}
