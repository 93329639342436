import { Injectable } from '@angular/core';
import {
  DataRecordType,
  CountryCodes,
  CreateOrganizationBusinessTravelHotelNightsDataRecordCommandParams,
  ICreateOrganizationBusinessTravelHotelNightsDataRecordCommandParams,
  DataRecordCategory,
  AssetType,
  InputType,
} from 'src/api-client/report-api.generated';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  ExcelImportValidationMessage,
  FieldConfig,
  ImportDataOutput,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { RecordsStateService } from '../records-state.service';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class HotelNightsStateService extends RecordsStateService {
  recordCategory = DataRecordCategory.IndirectEmissionsBusinessTravel;

  assetCategories = [AssetType.Organization, AssetType.Facility];
  importFromExcelEnabled = true;

  title = 'Hotel Nights';

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    assetApiService: AssetApiService,
    dataRecordService: DataRecordApiService,
    dataCategoryApiService: DataCategoryApiService,
    notificationService: NotificationService,
    router: Router,
    route: ActivatedRoute,
    private countryApiService: CountryApiService
  ) {
    super(
      appInfo,
      appConfig,
      assetApiService,
      dataRecordService,
      dataCategoryApiService,
      notificationService,
      router,
      route
    );
  }

  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'country', title: 'Hotel Country', type: 'string' },
    { propertyName: 'numberOfNights', title: 'Hotel Nights', type: 'number' },
  ];

  importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) validationMessages.push(assetValidation);

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'country',
      this.countryOptions,
      data.records,
      'Country'
    );

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    const addRecordPromises = data.records.map((record, index) => {
      const countryCode = this.countryOptions.find(opt => opt.label === record.country?.value);
      if (!countryCode) {
        this.notificationService.showError(
          'Not valid imported data',
          `Country column is not valid for ${index + 1} row`
        );
        return;
      }
      const asset = this.getAssetOptionColumn(record.asset?.value);
      const hotelNights: ICreateOrganizationBusinessTravelHotelNightsDataRecordCommandParams = {
        type: DataRecordType.OrganizationBusinessTravelHotelNights,
        dataSetId: dataSetId,
        assetId: asset.value,
        inputType: InputType.ImportedFromExcel,
        startDate: record.startDate?.value,
        endDate: record.endDate?.value,
        countryCode: CountryCodes[countryCode.value as keyof typeof CountryCodes],
        numberOfNights: record.numberOfNights.value || 0,
      };

      return this.dataRecordService.createDataRecord(
        new CreateOrganizationBusinessTravelHotelNightsDataRecordCommandParams({ ...hotelNights })
      );
    });
    Promise.all(addRecordPromises).then(async response => {
      response.forEach(res => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });
      await this.loadRecords();
      this.importingInProgress = false;
      this.importDataFromExcelClose();
    });
  }

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getCountries(), this.getDataCategoryDetails()]);
  }

  async fetchOptions() {
    await Promise.all([this.getAssetOptions(), this.getCountries()]);
  }

  async getCountries() {
    const response = await this.countryApiService.getAllCountries();
    this.countryOptions = response.result.map(country => ({ value: country.code, label: country.name }));
  }

  async loadRecords() {
    await this.getDataRecords(DataRecordType.OrganizationBusinessTravelHotelNights);
  }

  handleDeleteAllFilteredRecordsSubmit() {
    this.handleOnDeleteAllFilteredRecords(DataRecordType.OrganizationBusinessTravelHotelNights);
  }
}
