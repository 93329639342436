import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { IOption, ProfileInputOptions } from 'src/app/static-data/options';

@Component({
  selector: 'esg-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent {
  @Input({ required: true }) inputTypeFc!: FormControl<IOption>;
  @Input({ required: true }) timePeriodFc!: FormControl<ITimePeriodData | undefined>;
  @Input() submitText: string = 'Create';
  @Input() header: string = 'Create new Profile';
  @Input() hideInputType: boolean = false;
  @Output() onCancel = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  formgroup!: FormGroup;
  inputOptions: IOption[] = ProfileInputOptions;

  ngOnInit() {
    this.formgroup = new FormGroup({
      inputTypeFc: this.inputTypeFc,
      timePeriodFc: this.timePeriodFc,
    });
    if (this.inputOptions.length < 2) this.inputTypeFc.disable();
  }

  handleOnSubmit() {
    this.formgroup.markAsPristine();
    this.onSubmit.emit();
  }

  handleOnCancel() {
    this.onCancel.emit();
  }

  handleTimePeriodChange(timePeriodData: ITimePeriodData) {
    this.timePeriodFc.setValue(timePeriodData);
    this.timePeriodFc.markAsDirty();
  }

  handleInputTypeChange(option: IOption) {
    this.inputTypeFc.setValue(option);
    this.inputTypeFc.markAsDirty();
  }
}
