import { Component } from '@angular/core';
import { WasteStateService } from './waste-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-waste',
  templateUrl: './waste.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: WasteComponent }, WasteStateService],
})
export class WasteComponent extends RecordsComponent {
  constructor(public state: WasteStateService) {
    super(state);
  }
}
