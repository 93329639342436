import { Component, Input } from '@angular/core';
import { DataCollectCategoryDetailsStateService } from './data-collect-category-details-state.service';
import { FormControl } from '@angular/forms';

export interface DataCategoryDetailsUi {
  name: string;
  dataRecordCategory: string;
  guidance: FormControl<string>;
}

@Component({
  selector: 'esg-data-collect-category-details',
  templateUrl: './data-collect-category-details.component.html',
  styleUrls: ['./data-collect-category-details.component.scss'],
  providers: [DataCollectCategoryDetailsStateService],
})
export class DataCollectCategoryDetailsComponent {
  @Input({ required: true }) selectedCategory!: string;

  textAreaStyles = {
    height: '204px',
    'font-size': '14px',
    color: '#333333',
  };

  constructor(public state: DataCollectCategoryDetailsStateService) {}

  ngOnInit(): void {
    this.state.init(this.selectedCategory);
  }
}
