import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-icon-group',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      (mouseenter)="toggleHover()"
      (mouseleave)="toggleHover()"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="2" rx="1" [ngStyle]="{ fill: currentColor }" />
      <rect y="6" width="24" height="2" rx="1" [ngStyle]="{ fill: currentColor }" />
      <rect y="12" width="24" height="2" rx="1" [ngStyle]="{ fill: currentColor }" />
    </svg>
  `,
  styles: [],
})
export class GroupIconComponent {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  // TODO CANSU How can I see icon hover color on Figma?
  @Input() size: string = '24';
  @Input() color: string = '#EEEEEE';
  @Input() hoverColor: string = this.color;

  currentColor: string = this.color;
  hovered: boolean = false;

  toggleHover() {
    this.hovered = !this.hovered;
    this.currentColor = this.hovered ? this.hoverColor : this.color;
  }
}
