import { Component } from '@angular/core';
import { IndicatorsPageStateService } from './indicators-page-state.service';
import { UnitService } from 'src/app/shared/services/unit/unit.service';

@Component({
  selector: 'esg-indicators-page',
  templateUrl: './indicators-page.component.html',
  styleUrls: ['./indicators-page.component.scss'],
  providers: [UnitService],
})
export class IndicatorsPageComponent {
  constructor(public state: IndicatorsPageStateService) {}
}
