import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateVesselDurationInProtectedAreasDataRecordCommandParams,
  DataRecordType,
  InputType,
  UpdateVesselDurationInProtectedAreasDataRecordCommandParams,
  VesselDurationInProtectedAreasDataRecord,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { IOption } from 'src/app/static-data/options';
import { datediff } from 'src/app/shared/utils/date';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface MarineProtectedAreasRecordForm extends RecordForm {
  days: FormControl<string>;
}

@Component({
  selector: 'esg-marine-protected-areas-form-table',
  templateUrl: './marine-protected-areas-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: MarineProtectedAreasFormTableComponent }],
})
export class MarineProtectedAreasFormTableComponent extends RecordFormTableComponent {
  headerCells: RecordHeaderCell[] = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'days', label: 'Days', description: 'Number', number: true },
  ];

  recordFc?: MarineProtectedAreasRecordForm = undefined;
  recordFormgroup?: FormGroup<MarineProtectedAreasRecordForm> = undefined;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as VesselDurationInProtectedAreasDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'days',
          value: record.days?.toString() || '',
          number: true,
        },
      ],
    }));
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      days: new FormControl<string>('', {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup<MarineProtectedAreasRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const record = this.dataRecords.find(record => record.id === id) as VesselDurationInProtectedAreasDataRecord;
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        days: new FormControl<string>(record.days.toString() || '', {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup<MarineProtectedAreasRecordForm>(this.recordFc);
    }
  }

  getParams():
    | CreateVesselDurationInProtectedAreasDataRecordCommandParams
    | UpdateVesselDurationInProtectedAreasDataRecordCommandParams
    | undefined {
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const protectedAreas = {
        type: DataRecordType.VesselDurationInProtectedAreas,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        days: formattedStringToNumber(this.recordFc.days.value || '') || 0,
      };
      if (this.editRecordId) {
        return new UpdateVesselDurationInProtectedAreasDataRecordCommandParams({
          id: this.editRecordId,
          ...protectedAreas,
        });
      } else {
        return new CreateVesselDurationInProtectedAreasDataRecordCommandParams(protectedAreas);
      }
    }
    return undefined;
  }

  handleDaysInProtectedAreasChange(value: string) {
    if (this.recordFc) {
      const numberOfDays = formattedStringToNumber(value);
      if (numberOfDays !== undefined) {
        const periodDays =
          datediff(this.recordFc.startDate.value || new Date(), this.recordFc.endDate.value || new Date()) + 1;
        this.recordFc.days.setValue(numberOfDays > periodDays ? periodDays.toString() : numberOfDays.toString());
      } else this.recordFc.days.setValue('');
    }
  }

  handlePeriodInProtectedAreasChange() {
    this.recordFc?.days.value && this.handleDaysInProtectedAreasChange(this.recordFc.days.value);
    this.editRecordId && this.handleOnSubmit();
  }
}
