import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Breakpoint } from 'src/app/static-data/breakpoints';

@Component({
  selector: '[esg-preview-navbar]',
  templateUrl: './preview-navbar.component.html',
  host: {
    '[class.has-content]': '!isMobile && hasContent',
    '[class.hide]': 'showOnlyContent && !hasContent',
    '[class.mobile]': 'isMobile',
  },
  styleUrls: ['./preview-navbar.component.scss'],
})
export class PreviewNavbarComponent implements OnInit {
  @Input() showShareButton = true;
  @Input() breakpoint: Breakpoint = Breakpoint.WideDesktop;
  @Input() showDetails: boolean = true;
  @Input() hasContent: boolean = false;
  @Input() open: boolean = false;
  @Input() organizationName: string = '';
  @Input() organizationLogoSquare: string = '';
  @Input() reportName: string = '';
  @Output() onMenuClick = new EventEmitter();
  @Output() onCopyLink = new EventEmitter();

  get isMobile() {
    return this.breakpoint === Breakpoint.Mobile;
  }

  get isTablet() {
    return this.breakpoint === Breakpoint.Tablet;
  }

  get showOnlyContent() {
    return this.breakpoint === Breakpoint.NarrowDesktop || this.breakpoint === Breakpoint.Tablet;
  }

  constructor() {}

  ngOnInit(): void {}

  handleOnCopyLink() {
    this.onCopyLink.emit();
  }

  handleOnMenuClick() {
    this.onMenuClick.emit();
  }
}
