import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { IOption, IGroupedOptions } from 'src/app/static-data/options';
import { findLongestLabelInOptions } from 'src/app/shared/utils/array';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { KeyboardShortcutsDirective } from '../../directives/keyboard-shortcuts.directive';
import { TabFocusDirective } from '../../directives/on-tab-focus.directive';

interface Style {
  [key: string]: string;
}

@Component({
  selector: 'esg-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  host: {
    '(onTabFocus)': 'handleFocus()',
    '(onTabBlur)': 'handleFocusOut()',
    '(onEscapeKey)': 'handleOnCancel()',
    '(onEnterKey)': 'handleOnCancel()',
    '[tabindex]': 'disabled? -1 : tabindex',
  },
  hostDirectives: [
    { directive: KeyboardShortcutsDirective, outputs: ['onEscapeKey', 'onEnterKey'] },
    { directive: TabFocusDirective, outputs: ['onTabFocus', 'onTabBlur'] },
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input() formControl?: FormControl<IOption | null>;
  @Input() value?: IOption;
  @Input() options: IOption[] = [];
  @Input() groupedOptions: IGroupedOptions[] = [];
  @Input() favouriteOptions: IOption[] = [];
  @Input() label = '';
  @Input() variant: 'standard' | 'ghost' = 'standard';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() placeholder = 'Please select';
  @Input() tabindex: number = 0;
  @Input() required: boolean = false;
  @Input() disabled = false;
  @Input() customDropdown: boolean = false;
  @Input() isCollapsed: boolean = true;
  @Input() fitLongestLabel: boolean = true;
  @Input() isPopup: boolean = false;
  @Output() onChange = new EventEmitter<IOption>();
  @Output() onToggleCollapse = new EventEmitter<boolean>();

  selectedValue: IOption | undefined = this.value;
  dropUp = false;
  focused = false;
  popUpStyle: Style = {};
  longestLabel: string = this.placeholder;
  maxHeight: string = 'unset';

  onModelChange?: (modelValue?: IOption) => void;
  onModelTouched?: () => void;

  @ViewChild('input') private input: ElementRef | undefined;

  get selectedValueAsArray() {
    return this.selectedValue ? [this.selectedValue] : [];
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMaxHeigth();
    if (this.isPopup && !this.isCollapsed) this.handlePopupPosition();
  }

  constructor(private hostRef: ElementRef) {}

  ngOnInit(): void {
    if ((this.value || this.onChange.observed) && this.formControl) {
      throw new Error('You cannot use both formControl and value/onChange');
    }

    if (this.fitLongestLabel) {
      this.longestLabel =
        findLongestLabelInOptions([
          ...this.options,
          ...this.favouriteOptions,
          ...this.groupedOptions?.flatMap(group => group.options),
        ]) || this.placeholder;
    }

    if (this.formControl && this.formControl.hasValidator(Validators.required)) this.required = true;
    if (this.isPopup) this.addScrollEventListener();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.selectedValue = this.value;
    }
    if (changes.isCollapsed && !changes.isCollapsed.currentValue) {
      this.setDropUp();
      this.setMaxHeigth();
      if (this.isPopup) {
        this.handlePopupPosition();
      }
    }
  }

  writeValue(value: IOption): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  handleChange(option: IOption) {
    this.handleOnCollapse(true);
    this.isCollapsed = true;
    if (this.onModelChange) {
      this.selectedValue = option;
      this.onModelChange(option);
    }
    if (this.onModelTouched) this.onModelTouched();
    this.onChange.emit(option);
  }

  handlePopupPosition() {
    if (!this.input) return;
    const inputRect = this.input.nativeElement.getBoundingClientRect();
    const top = this.dropUp ? inputRect.top : inputRect.bottom;
    const width = `${inputRect.width}px`;
    this.popUpStyle = { top: `${top}px`, left: `${inputRect.left}px`, minWidth: width };
  }

  toggleCollapse(): void {
    if (this.onModelTouched) this.onModelTouched();
    if (this.isCollapsed) {
      this.setDropUp();
      this.setMaxHeigth();
      if (this.isPopup) {
        this.handlePopupPosition();
      }
    }
    this.handleOnCollapse(!this.isCollapsed);
    this.isCollapsed = !this.isCollapsed;
  }

  collapse(): void {
    this.handleOnCollapse(true);
    this.isCollapsed = true;
  }

  handleOnCollapse(collapsed: boolean) {
    if (this.onToggleCollapse.observed) {
      this.onToggleCollapse.emit(collapsed);
    }

    if (this.focused) this.focused = false;
  }

  handleOnCancel() {
    this.collapse();
    this.hostRef.nativeElement.blur();
  }

  handleFocus() {
    if (!this.disabled) {
      this.focused = true;
      this.setDropUp();
      this.setMaxHeigth();
    }
  }

  handleFocusOut() {
    this.collapse();
  }

  setDropUp() {
    if (this.input && window.innerHeight - this.input.nativeElement.getBoundingClientRect().bottom < 200)
      this.dropUp = true;
    else this.dropUp = false;
  }

  setMaxHeigth() {
    if (this.input?.nativeElement) {
      const inputRect = this.input.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const remainingHeight = this.dropUp ? inputRect.top - 160 : windowHeight - inputRect.bottom - 100;
      this.maxHeight = `${remainingHeight}px`;
    } else {
      this.maxHeight = 'unset';
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addScrollEventListener(): void {
    document.addEventListener(
      'scroll',
      () => {
        if (!this.isCollapsed) this.handlePopupPosition();
      },
      true
    );
  }
}
