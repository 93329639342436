import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'esg-collapsible-section',
  templateUrl: './collapsible-section.component.html',
  styleUrls: ['./collapsible-section.component.scss'],
})
export class CollapsibleSectionComponent implements OnInit {
  @Input({ required: true }) title!: string;
  @Input() collapsedByDefault = false;
  collapsed = true;
  constructor() {}

  ngOnInit(): void {
    this.collapsed = this.collapsedByDefault;
  }

  handleCollpase() {
    this.collapsed = !this.collapsed;
  }
}
