import {
  UpdateIndicatorDataCommandParams,
  CreateIndicatorDataCommandParams,
  IndicatorValueType,
  IndicatorTableValue,
  GetIndicatorDataByQueryVm,
  ICreateIndicatorDataCommandParams,
  TimePeriod,
  TimePeriodType,
  RecordDuration,
} from 'src/api-client/report-api.generated';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { Injectable } from '@angular/core';
import { AssetCategoryLabel } from 'src/app/static-data/enum-mappings';
import { IndicatorDataApiService } from 'src/app/api-client/report-api/indicator-data-api-service';
import { CurrencyApiService } from 'src/app/api-client/report-api/currency-api-service';
import { sortOptionsAlphabetically } from 'src/app/shared/utils/array';
import { Subscription } from 'rxjs';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { RecordSource } from 'src/app/shared/components/records-table/records-table.component';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { DataRecordOutput, ExcelImportValidationMessage, FieldConfig, ImportDataOutput } from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { ExcelTimePeriodType } from 'src/app/static-data/constants';

export interface IndicatorRecordData {
  id: string;
  assetId: string;
  assetName: string;
  timePeriod: ITimePeriodData;
  description?: string;
  numericValue?: number;
  tableValue?: IndicatorTableValue;
  recordSource?: RecordSource;
}

@Injectable()
export class IndicatorDataStateService {
  readonly valueTypeEnum = IndicatorValueType;
  private unitOptionsSubscription?: Subscription;

  indicatorId?: string;
  indicatorRecordDuration?: RecordDuration;

  indicatorRecords: IndicatorRecordData[] = [];
  totalCount: number = 0;
  newlyAddedRowsIds: string[] = [];

  loading = false;
  isAllRecordsDialogOpen = false;

  assetOptions: IGroupedOptions[] = [];
  currencyOptions: IOption[] = [];
  unitOptions: IOption[] = [];

  get getDisplayedItemsRange() {
    if (this.totalCount === 0) {
      return '0 – 0';
    }
    return 1 + ' – ' + Math.min(10, this.totalCount);
  }

  get intersectionObserverRootMargin() {
    if (this.appConfig.NewNavbarEnabled) {
      return 136;
    } else return 181;
  }

  constructor(
    private appConfig: AppConfigurationService,
    private assetApiService: AssetApiService,
    private indicatorDataService: IndicatorDataApiService,
    private notificationService: NotificationService,
    private currencyApiService: CurrencyApiService,
    private unitService: UnitService
  ) {
    this.unitOptionsSubscription = this.unitService.units$.subscribe(units => {
      this.unitOptions = Object.values(units).map(unit => ({
        value: unit.key,
        label: unit.name,
      }));
    });
  }

  excelTitle= 'Single value indicator'
  get importFromExcelFieldConfigs(): FieldConfig[] {
    switch (this.indicatorRecordDuration) {
      case RecordDuration.SingleDate:
        return [
          { propertyName: 'date', title: 'Date', type: 'date' },
          { propertyName: 'asset', title: 'Asset', type: 'string' },
          { propertyName: 'description', title: 'Description', type: 'string' },
          { propertyName: 'emissions', title: 'Emissions', type: 'number', optional: true },
        ];
      case RecordDuration.Period:
        return [
          { propertyName: 'periodType', title: 'Period Type', type: 'string' },
          { propertyName: 'year', title: 'Year', type: 'number' },
          { propertyName: 'quarterOrMonth', title: 'Quarter/Month', type: 'number'},
          { propertyName: 'asset', title: 'Asset', type: 'string' },
          { propertyName: 'description', title: 'Description', type: 'string' },
          { propertyName: 'emissions', title: 'Emissions', type: 'number', optional: true },
        ];
      default:
        return [
          { propertyName: 'startDate', title: 'Start Date', type: 'date' },
          { propertyName: 'endDate', title: 'End Date', type: 'date' },
          { propertyName: 'asset', title: 'Asset', type: 'string' },
          { propertyName: 'description', title: 'Description', type: 'string' },
          { propertyName: 'emissions', title: 'Emissions', type: 'number', optional: true },
        ];
    }
  }
  importFromExcelDialog = false;
  importFromExcelEnabled = true;
  importingInProgress = false;
  importingInProgressLabel = 'Importing...';

  async init(indicatorId: string, indicatorRecordDuration: RecordDuration) {
    this.indicatorId = indicatorId;
    this.indicatorRecordDuration = indicatorRecordDuration;
    this.loading = true;
    await Promise.all([this.getAssetOptions(), this.getDataRecords(), this.getCurrencies()]);
    this.loading = false;
  }

  importDataFromExcelClose() {
    this.importFromExcelDialog = false;
  }

  handleOnImportAssets() {
    this.importFromExcelDialog = true;
  }

  validateExcelAssets(records: DataRecordOutput[]): ExcelImportValidationMessage | undefined {
    const assets = this.assetOptions.flatMap(group => group.options);
    const notMappedRecordAssets = records.filter(
      record => typeof record.asset?.value  !== 'string' || !assets.some(asset => asset.label.trim() === record.asset?.value.trim())
    );

    if (notMappedRecordAssets.length) {
      const notMappedAssetsString = notMappedRecordAssets.map(record => record.asset.value).join(', ');
      return {
        title: 'Not valid imported data',
        description: `The following assets are not mapped to any of your assets: ${notMappedAssetsString}`,
      };
    }
    return undefined;
  }

  validateExcelTimePeriodType(records: DataRecordOutput[]): ExcelImportValidationMessage | undefined {
    const validTimePeriodTypes = Object.values(ExcelTimePeriodType);
    
    const notMappedPeriodTypes = records.filter(
      record => typeof record.periodType?.value  !== 'string' || !validTimePeriodTypes.includes(record.periodType?.value)
    );

    if (notMappedPeriodTypes.length) {
      const notMappedPeriodTypesString = notMappedPeriodTypes.map(record => record.periodType.value).join(', ');
      return {
        title: 'Not valid imported data',
        description: `The following time period are not mapped to any of your time period: ${notMappedPeriodTypesString}`,
      };
    }
    return undefined;
  }

  validateExcelTimePeriod(records: DataRecordOutput[]): ExcelImportValidationMessage | undefined {
    const notMappedPeriod = records.filter(record => {
      const periodType = record.periodType?.value;
      const periodValue = record.quarterOrMonth?.value;

      if (periodType === ExcelTimePeriodType.Month) {
        return periodValue < 1 || periodValue > 12;
      } else if (periodType === ExcelTimePeriodType.Quarter) {
        return periodValue < 1 || periodValue > 4;
      }

      return false;
    });

    if (notMappedPeriod.length) {
      const notMappedPeriodString = notMappedPeriod.map(record => record.quarterOrMonth?.value).join(', ');
      return {
        title: 'Not valid imported data',
        description: `The following Quarter/Month have invalid values: ${notMappedPeriodString}`,
      };
    }
    return undefined;
  }

  getAssetOptionColumn(assetName: string) {
    const findAsset = this.assetOptions
      .flatMap(group => group.options)
      .find(asset => asset.label.trim() === assetName.trim());
    if (!findAsset) {
      this.notificationService.showError('Not mapped asset', `Not mapped asset for ${assetName}`);
      throw Error();
    }
    return findAsset;
  }

  createTimePeriod(record: DataRecordOutput): TimePeriod {
    const periodType = record.periodType?.value;

    if (this.indicatorRecordDuration === RecordDuration.SingleDate) {
      return TimePeriod.fromJS({
        type: TimePeriodType.Custom,
        customStart: record.date?.value,
        customEnd: record.date?.value,
      });
    } else if (this.indicatorRecordDuration === RecordDuration.Period) {
      if (periodType === ExcelTimePeriodType.Annual) {
        return TimePeriod.fromJS({
          type: TimePeriodType.Annual,
          year: record.year?.value,
          period: 1,
        });
      } else if (periodType === ExcelTimePeriodType.Month) {
        return TimePeriod.fromJS({
          type: TimePeriodType.Monthly,
          year: record.year?.value,
          period: record.quarterOrMonth?.value,
        });
      } else if (periodType === ExcelTimePeriodType.Quarter) {
        return TimePeriod.fromJS({
          type: TimePeriodType.Quarterly,
          year: record.year?.value,
          period: record.quarterOrMonth?.value,
        });
      }
    }

    return TimePeriod.fromJS({
      type: TimePeriodType.Custom,
      customStart: record.startDate?.value,
      customEnd: record.endDate?.value,
    });
  }

  async importDataFromExcel(data: ImportDataOutput) {
    this.importingInProgress = true;
    this.importingInProgressLabel = 'Importing...';
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) {
      validationMessages.push(assetValidation);
    }

    if(this.indicatorRecordDuration === RecordDuration.Period) {
      const timePeriodTypeValidation = this.validateExcelTimePeriodType(data.records);
      if(timePeriodTypeValidation) {
        validationMessages.push(timePeriodTypeValidation);
      }

      const timePeriodValidation = this.validateExcelTimePeriod(data.records);
      if(timePeriodValidation) {
        validationMessages.push(timePeriodValidation);
      }
    }

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    var recordsToAdd = data.records.length;
    var recordsAdded = 0;
    this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
    try {
      const addRecordPromises = data.records.map(async (record, index) => {
        try {
          const asset = this.getAssetOptionColumn(record.asset?.value);
          const timePeriod = this.createTimePeriod(record);

          const indicator: ICreateIndicatorDataCommandParams = {
            indicatorId: this.indicatorId ?? '',
            assetId: asset.value,
            timePeriod: timePeriod,
            description: record.description?.value,
            valueType: IndicatorValueType.Numeric,
            numericValue: record.emissions?.value,
          };
          var result = await this.indicatorDataService.createIndicatorData(new CreateIndicatorDataCommandParams(indicator));
          if (!result.success) {
            this.notificationService.showError(
              `Indicator record from row ${index + 1} not added`,
              result.message || 'Unknown error'
            );
          }
          ++recordsAdded;
          this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
          return result;
        } catch (err) {
          console.error(`Somthing went wrong for record in row ${index + 1} `, record);
          throw err;
        }
      });

      var response = await Promise.all(addRecordPromises);
      response.forEach((res, index) => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });

      await this.getDataRecords();
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
      this.importDataFromExcelClose();
    } catch (e) {
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
    }
  }

  async getAssetOptions() {
    const response = await this.assetApiService.getAllAssetOptions();
    const assets = response.result;

    const groupedAssets: { [key: string]: IGroupedOptions } = {};

    assets.map(a => {
      if (!groupedAssets[a.assetType]) {
        groupedAssets[a.assetType] = { label: AssetCategoryLabel[a.assetType], options: [] };
      }
      groupedAssets[a.assetType].options.push({ value: a.id, label: a.name });
    });

    this.assetOptions = Object.values(groupedAssets);
  }

  async getDataRecords() {
    if (this.indicatorId) {
      const response = await this.indicatorDataService.getIndicatorDataBy(
        this.indicatorId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1,
        10
      );
      const assetMap = new Map(
        this.assetOptions.flatMap(group => group.options.map(option => [option.value, option.label]))
      );

      this.indicatorRecords = response.result.items.map(record => {
        const recordSource = record.dataRecordId
          ? RecordSource.RawData
          : record.indicatorDataId
          ? RecordSource.IndicatorDefinition
          : undefined;
        return {
          id: record.id,
          assetId: record.assetId,
          assetName: assetMap.get(record.assetId) || '',
          timePeriod: record.timePeriod,
          description: GetIndicatorDataDescription(record),
          numericValue: record.numericValue,
          tableValue: record.tableValue,
          recordSource: recordSource,
        };
      });

      this.totalCount = response.result.totalCount;
    }
  }

  async getCurrencies() {
    const response = await this.currencyApiService.getAllCurrencies();
    this.currencyOptions = sortOptionsAlphabetically(
      response.result.map(currency => ({ value: currency.code, label: currency.code }))
    );
  }

  async delayRemoveAddedId(addedId: string, ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms));
    this.newlyAddedRowsIds = this.newlyAddedRowsIds.filter(id => id !== addedId);
  }

  async handleAddRecord(params: CreateIndicatorDataCommandParams) {
    const response = await this.indicatorDataService.createIndicatorData(params);

    if (response.success) {
      this.getDataRecords();
      const addedId = response.result.id;
      this.newlyAddedRowsIds.push(addedId);
      this.delayRemoveAddedId(addedId, 15000);
    }
  }

  async handleUpdateRecord(params: UpdateIndicatorDataCommandParams) {
    const response = await this.indicatorDataService.updateIndicatorData(params);

    if (response.success) {
      this.getDataRecords();
    }
  }

  handleDeleteRecord(id: string) {
    this.indicatorDataService.deleteIndicatorData(id).then(response => {
      if (response.success) {
        this.getDataRecords();
      }
    });
  }

  handleOpenAllRecordsDialog() {
    this.isAllRecordsDialogOpen = true;
  }

  handleCloseAllRecordsDialog() {
    this.isAllRecordsDialogOpen = false;
  }

  ngOnDestroy() {
    if (this.unitOptionsSubscription) {
      this.unitOptionsSubscription.unsubscribe();
    }
  }
}

export function GetIndicatorDataDescription(record: GetIndicatorDataByQueryVm) {
  if (record.indicatorDataId) {
    return `Indicator / ${record.indicatorName}` + (record.description ? ` / ${record.description}` : '');
  } else if (record.dataRecordId) {
    return `RawData / ${record.dataRecordTypeName}`;
  } else {
    return record.description;
  }
}
