import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-icon-clear',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      (mouseenter)="toggleHover()"
      (mouseleave)="toggleHover()"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 15C11.8294 15 15 11.8225 15 8C15 4.17059 11.8225 1 7.99314 1C4.17059 1 1 4.17059 1 8C1 11.8225 4.17745 15 8 15ZM5.69412 10.8824C5.37843 10.8824 5.13137 10.6284 5.13137 10.3127C5.13137 10.1618 5.18627 10.0176 5.29608 9.91471L7.19706 8.00686L5.29608 6.10588C5.18627 5.99608 5.13137 5.85882 5.13137 5.70784C5.13137 5.38529 5.37843 5.1451 5.69412 5.1451C5.85196 5.1451 5.97549 5.2 6.08529 5.30294L8 7.21078L9.92843 5.29608C10.0451 5.17941 10.1686 5.13137 10.3196 5.13137C10.6353 5.13137 10.8892 5.37843 10.8892 5.69412C10.8892 5.85196 10.8412 5.97549 10.7176 6.09902L8.8098 8.00686L10.7108 9.90784C10.8275 10.0108 10.8824 10.1549 10.8824 10.3127C10.8824 10.6284 10.6284 10.8824 10.3059 10.8824C10.148 10.8824 10.0039 10.8275 9.90098 10.7176L8 8.8098L6.10588 10.7176C5.99608 10.8275 5.85196 10.8824 5.69412 10.8824Z"
        [ngStyle]="{ fill: currentColor }" />
    </svg>
  `,
  styles: [],
})
export class ClearIconComponent {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  @Input() size: string = '16';
  @Input() color: string = '#666666';
  @Input() hoverColor: string = '#183642';

  currentColor: string = this.color;
  hovered: boolean = false;

  toggleHover() {
    this.hovered = !this.hovered;
    this.currentColor = this.hovered ? this.hoverColor : this.color;
  }
}
