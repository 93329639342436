import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-window-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M0 12.0641V3.93591C0 3.29503 0.168517 2.81326 0.505551 2.49061C0.842585 2.16354 1.34358 2 2.00854 2H13.9846C14.6541 2 15.1574 2.16354 15.4944 2.49061C15.8315 2.81326 16 3.29503 16 3.93591V12.0641C16 12.7006 15.8315 13.1823 15.4944 13.5094C15.1574 13.8365 14.6541 14 13.9846 14H2.00854C1.34358 14 0.842585 13.8365 0.505551 13.5094C0.168517 13.1867 0 12.705 0 12.0641ZM2.00171 4.09503C2.14745 4.09503 2.27498 4.04199 2.38429 3.93591C2.4936 3.82983 2.54825 3.70829 2.54825 3.57127C2.54825 3.43425 2.4936 3.31271 2.38429 3.20663C2.27498 3.10055 2.14745 3.04751 2.00171 3.04751C1.85141 3.04751 1.72161 3.10055 1.6123 3.20663C1.50754 3.31271 1.45517 3.43425 1.45517 3.57127C1.45517 3.70829 1.50754 3.82983 1.6123 3.93591C1.72161 4.04199 1.85141 4.09503 2.00171 4.09503ZM3.79163 4.09503C3.94193 4.09503 4.06946 4.0442 4.17421 3.94254C4.28352 3.83646 4.33817 3.71271 4.33817 3.57127C4.33817 3.43425 4.28352 3.31271 4.17421 3.20663C4.06946 3.10055 3.94193 3.04751 3.79163 3.04751C3.64589 3.04751 3.51836 3.10055 3.40905 3.20663C3.3043 3.31271 3.25192 3.43425 3.25192 3.57127C3.25192 3.71271 3.3043 3.83646 3.40905 3.94254C3.51836 4.0442 3.64589 4.09503 3.79163 4.09503ZM5.58839 4.09503C5.73868 4.09503 5.86621 4.0442 5.97096 3.94254C6.08027 3.83646 6.13493 3.71271 6.13493 3.57127C6.13493 3.43425 6.08027 3.31271 5.97096 3.20663C5.86621 3.10055 5.73868 3.04751 5.58839 3.04751C5.43809 3.04751 5.31056 3.10055 5.20581 3.20663C5.10105 3.31271 5.04868 3.43425 5.04868 3.57127C5.04868 3.71271 5.10105 3.83646 5.20581 3.94254C5.31056 4.0442 5.43809 4.09503 5.58839 4.09503ZM0.840307 12.0243C0.840307 12.4088 0.940507 12.6983 1.14091 12.8928C1.34586 13.0873 1.6419 13.1845 2.02904 13.1845H13.971C14.349 13.1845 14.6405 13.0873 14.8454 12.8928C15.0504 12.6983 15.1529 12.4088 15.1529 12.0243V5.14254H0.840307V12.0243Z"
        fill="#183642" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  },
})
export class WindowIconComponent {
  @Input() size: number = 16;
}
