import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-icon-ext-link',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      (mouseenter)="toggleHover()"
      (mouseleave)="toggleHover()"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.35659 8.05219C8.41704 8.05233 8.47692 8.04053 8.5328 8.01746C8.58869 7.99439 8.63946 7.96051 8.68223 7.91776L13.0223 3.57447V5.81952C13.0223 5.94187 13.0708 6.0592 13.1573 6.14571C13.2438 6.23222 13.3611 6.28082 13.4833 6.28082C13.6056 6.28082 13.7229 6.23222 13.8094 6.14571C13.8958 6.0592 13.9444 5.94187 13.9444 5.81952V2.4613C13.9444 2.33895 13.8958 2.22162 13.8094 2.13511C13.7229 2.0486 13.6056 2 13.4833 2H10.1268C10.0045 2 9.88725 2.0486 9.80078 2.13511C9.71432 2.22162 9.66574 2.33895 9.66574 2.4613C9.66574 2.58364 9.71432 2.70097 9.80078 2.78748C9.88725 2.87399 10.0045 2.92259 10.1268 2.92259H12.3707L7.99986 7.26483C7.93542 7.32934 7.89155 7.41151 7.87378 7.50097C7.856 7.59042 7.86514 7.68313 7.90001 7.7674C7.93489 7.85166 7.99395 7.92369 8.06973 7.97438C8.14551 8.02507 8.23461 8.05215 8.32576 8.05219H8.35659Z"
        [ngStyle]="{ fill: currentColor }" />
      <path
        d="M13.5326 8.49508C13.4103 8.49508 13.2931 8.54368 13.2066 8.63019C13.1201 8.7167 13.0716 8.83403 13.0716 8.95637V12.7698C13.0716 12.8094 13.0638 12.8486 13.0487 12.8852C13.0336 12.9217 13.0114 12.955 12.9834 12.983C12.9555 13.0109 12.9222 13.0331 12.8857 13.0483C12.8491 13.0634 12.81 13.0712 12.7704 13.0711H3.22959C3.19002 13.0712 3.15083 13.0634 3.11426 13.0483C3.0777 13.0331 3.04447 13.011 3.01648 12.983C2.98849 12.955 2.96628 12.9218 2.95113 12.8852C2.93598 12.8486 2.92818 12.8094 2.92818 12.7698V3.22419C2.92818 3.1846 2.93598 3.1454 2.95113 3.10883C2.96628 3.07226 2.98849 3.03903 3.01648 3.01105C3.04447 2.98307 3.0777 2.96088 3.11426 2.94575C3.15083 2.93063 3.19002 2.92286 3.22959 2.9229H6.83905C6.96133 2.9229 7.0786 2.87429 7.16507 2.78779C7.25153 2.70128 7.30011 2.58394 7.30011 2.4616C7.30011 2.33926 7.25153 2.22193 7.16507 2.13542C7.0786 2.04891 6.96133 2.00031 6.83905 2.00031H3.22959C3.06813 2.00027 2.90824 2.03206 2.75906 2.09385C2.60989 2.15565 2.47433 2.24624 2.36015 2.36045C2.24597 2.47467 2.1554 2.61027 2.0936 2.75951C2.03181 2.90875 2 3.06871 2 3.23025V12.7698C2 13.0961 2.12955 13.409 2.36014 13.6397C2.59073 13.8704 2.90348 14 3.22959 14H12.7704C12.9319 14 13.0918 13.9682 13.2409 13.9064C13.3901 13.8446 13.5256 13.7539 13.6398 13.6397C13.7539 13.5255 13.8445 13.3898 13.9062 13.2406C13.968 13.0913 13.9998 12.9314 13.9997 12.7698V8.95637C13.9997 8.89528 13.9876 8.8348 13.9641 8.77843C13.9405 8.72206 13.906 8.67094 13.8626 8.62803C13.8191 8.58511 13.7676 8.55126 13.7109 8.52844C13.6543 8.50561 13.5937 8.49427 13.5326 8.49508Z"
        [ngStyle]="{ fill: currentColor }" />
    </svg>
  `,
  styles: [],
})
export class ExtLinkIconComponent {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  @Input() size: string = '16';
  @Input() color: string = '#999999';
  @Input() hoverColor: string = '#183642';

  currentColor: string = this.color;
  hovered: boolean = false;

  toggleHover() {
    this.hovered = !this.hovered;
    this.currentColor = this.hovered ? this.hoverColor : this.color;
  }
}
