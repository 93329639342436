<ng-container *ngIf="state.article">
  <div class="content" [class.fade]="state.contentFade">
    <div class="article-info">
      <span class="name">{{ state.article.name || '' }}</span>
    </div>
  </div>
  <div
    *ngIf="!readMode"
    class="content-menu-observer"
    esgIntersectionObserver
    [root]="hostRef.nativeElement.parentNode"
    rootMargin="-56px 0px 0px 0px"
    (onIntersection)="handleStickyComponentMenu($event)"></div>
  <esg-content
    [contentItems]="state.article.components || []"
    [readMode]="readMode"
    [stickyMenu]="stickyComponentsToolbar"
    [stickyTopMargin]="56"
    (onAddContent)="state.handleOnAddComponent($event)"
    (onMoveContent)="state.moveComponent($event)"
    (onDeleteContent)="state.deleteComponent($event)"
    (onReorder)="handleOnReorder($event)"
    [onChange]="state.onDataChangeComponent"></esg-content>
</ng-container>

<esg-dot-flashing *ngIf="!state.article" class="loader" label="We are loading content details"></esg-dot-flashing>
