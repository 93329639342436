<esg-records-table
  [headerCells]="headerCells"
  [bodyRows]="dataRecordsRows"
  [newlyAddedRowsIds]="newlyAddedRowsIds"
  [isAdding]="recordFormgroup !== undefined && !editRecordId"
  [hideAddButton]="!isAddEnabled"
  [editRecordId]="editRecordId"
  [stickyHeaderTopOffset]="stickyHeaderTopOffset"
  [intersectionObserverRootMargin]="intersectionObserverRootMargin"
  [stickyHeaderPadding]="stickyHeaderPadding"
  (onAdding)="handleOnIniateAddRecord()"
  (onFormClose)="handleCloseForm()"
  (onEditing)="handleOnInitateEditRecord($event)"
  (onSubmit)="handleOnSubmit()"
  (onDelete)="handleOnDelete($event)">
  <ng-container *ngIf="recordFc">
    <ng-container *ngIf="!showPeriodSelector">
      <td
        class="datepicker-cell"
        [class.focus]="startDateOpen"
        [style.grid-column]="indicatorRecordDuration === recordDurationEnum.SingleDate ? 'span 2' : 'span 1'">
        <esg-datepicker
          *ngIf="recordFc.startDate"
          class="input-text"
          [fc]="recordFc.startDate"
          variant="ghost-table"
          [maxDate]="recordFc.endDate.value || undefined"
          [inputIndent]="15"
          [autoFocus]="startDateOpen"
          (onOpen)="startDateOpen = true"
          (onClose)="onStartDateClose()"></esg-datepicker>
      </td>
      <td
        *ngIf="indicatorRecordDuration === recordDurationEnum.DateFromTo"
        class="datepicker-cell"
        [class.focus]="endDateOpen">
        <span class="date-divider" [class.focus]="startDateOpen"><span class="triangle"></span></span>
        <esg-datepicker
          *ngIf="recordFc.endDate"
          class="input-text"
          [fc]="recordFc.endDate"
          variant="ghost-table"
          [minDate]="recordFc.startDate.value || undefined"
          [inputIndent]="13"
          [autoFocus]="endDateOpen"
          (onOpen)="endDateOpen = true"
          (onClose)="endDateOpen = false"></esg-datepicker>
      </td>
    </ng-container>

    <td *ngIf="showPeriodSelector" class="input-cell" [style.grid-column]="'span 2'">
      <esg-time-period-selector
        [formControl]="recordFc.timePeriod"
        variant="ghost-table"
        size="large"></esg-time-period-selector>
    </td>
    <td class="input-cell" [class.disabled]="isAssetsDisabled">
      <esg-table-select
        *ngIf="recordFc.asset"
        class="input-dropdown"
        [groupedOptions]="assetOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.asset.value || undefined"
        [disabled]="isAssetsDisabled"
        (onChange)="handleDropdownChange(recordFc.asset, $event)"></esg-table-select>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.description.valid">
      <esg-textbox-form
        *ngIf="recordFc.description"
        class="input-text"
        placeholder="Description"
        [fc]="recordFc.description"
        variant="ghost-table"
        (onChange)="recordFc.description.setValue($event)"></esg-textbox-form>
    </td>
    <td class="input-cell" [class.error]="hasSubmitted && !recordFc.singleValue.valid">
      <esg-textbox-form
        *ngIf="recordFc.singleValue"
        class="input-text"
        type="number"
        [placeholder]="indicatorUnit.description"
        [fc]="recordFc.singleValue"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.singleValue.setValue($event)"></esg-textbox-form>
    </td>
  </ng-container>
</esg-records-table>
