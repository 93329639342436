import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { debounce } from 'lodash';

@Directive({
  selector: '[esgIntersectionObserver]',
})
export class IntersectionObserverDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() root: Element | Document | null | undefined = null;
  @Input() threshold: number | number[] = [...Array(100).keys()].map(x => x / 100); //[0.00, 0.01, 0.02, /*…,*/ 0.99, 1.00]
  @Input() rootMargin: string = '0px'; // 0px 0px 0px 0px
  @Input() observe: boolean = true;
  @Input() debounceTime?: number;
  @Output() onInit = new EventEmitter();
  @Output() onIntersection = new EventEmitter<IntersectionObserverEntry>();

  private observer?: IntersectionObserver;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.onInit.emit();
    this.observer = new IntersectionObserver(
      this.debounceTime
        ? debounce(entries => this.onIntersection.emit(entries[0]), this.debounceTime)
        : entries => this.onIntersection.emit(entries[0]),
      {
        root: this.root,
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      }
    );

    this.observe && this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('observe' in changes && this.observer) {
      if (changes['observe'].currentValue) {
        this.observer.observe(this.elementRef.nativeElement);
      } else {
        this.observer.unobserve(this.elementRef.nativeElement);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
