import {
  CalculateIndicatorDataCommandParams,
  CalculateIndicatorDataCommandResponse,
  CreateIndicatorDataCommandParams,
  CreateIndicatorDataCommandResponse,
  DeleteIndicatorDataCommandResponse,
  GetByIdIndicatorDataQueryResponse,
  GetIndicatorDataByQueryResponse,
  IndicatorDataClient,
  UpdateIndicatorDataCommandParams,
  UpdateIndicatorDataCommandResponse,
} from '../../../api-client/report-api.generated';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AssetType } from 'src/api-client/integration-api.generated';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class IndicatorDataApiService {
  client: IndicatorDataClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new IndicatorDataClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createIndicatorData(body: CreateIndicatorDataCommandParams): Promise<CreateIndicatorDataCommandResponse> {
    const observable$ = this.client.createIndicatorData(body);
    return await lastValueFrom(observable$);
  }

  async updateIndicatorData(body: UpdateIndicatorDataCommandParams): Promise<UpdateIndicatorDataCommandResponse> {
    const observable$ = this.client.updateIndicatorData(body);
    return await lastValueFrom(observable$);
  }

  async deleteIndicatorData(id: string): Promise<DeleteIndicatorDataCommandResponse> {
    const observable$ = this.client.deleteIndicatorData(id);
    return await lastValueFrom(observable$);
  }

  async getIndicatorDataById(indicatorDataId: string): Promise<GetByIdIndicatorDataQueryResponse> {
    const observable$ = this.client.getIndicatorDataById(indicatorDataId);
    return await lastValueFrom(observable$);
  }

  async calculateIndicatorData(
    body: CalculateIndicatorDataCommandParams
  ): Promise<CalculateIndicatorDataCommandResponse> {
    const observable$ = this.client.calculateIndicatorData(body);
    return await lastValueFrom(observable$);
  }

  async getIndicatorDataBy(
    indicatorId: string,
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
    assetIds: string[] | undefined,
    assetTypes: AssetType[] | undefined,
    orderBy: string | undefined,
    pageNumber: number | undefined,
    pageSize: number | undefined
  ): Promise<GetIndicatorDataByQueryResponse> {
    const observable$ = this.client.getIndicatorDataBy(
      indicatorId,
      dateFrom,
      dateTo,
      assetIds,
      assetTypes,
      orderBy,
      pageNumber,
      pageSize
    );
    return await lastValueFrom(observable$);
  }
}
