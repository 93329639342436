<svg xmlns="http://www.w3.org/2000/svg" width="36" height="14" viewBox="0 0 36 14" fill="none">
  <rect width="36" height="14" rx="4" attr.fill="{{ backgroundColor }}" />
  <path
    d="M6 10.5V3.5H8.30596C8.77473 3.5 9.19525 3.55368 9.56751 3.66104C9.94667 3.76125 10.2431 3.94018 10.4568 4.19785C10.6774 4.44836 10.7877 4.7955 10.7877 5.23926C10.7877 5.58998 10.7119 5.90491 10.5602 6.18405C10.4085 6.45603 10.1914 6.6457 9.90875 6.75307V6.79601C10.2879 6.8819 10.5843 7.05368 10.798 7.31135C11.0118 7.56186 11.1186 7.91258 11.1186 8.3635C11.1186 8.85736 11.0014 9.26176 10.767 9.57669C10.5326 9.89162 10.2155 10.1242 9.81569 10.2745C9.42274 10.4248 8.98499 10.5 8.50243 10.5H6ZM7.52007 6.30215H8.23357C8.60583 6.30215 8.87469 6.22699 9.04014 6.07669C9.21248 5.92638 9.29866 5.72597 9.29866 5.47546C9.29866 5.20348 9.21248 5.01022 9.04014 4.89571C8.8678 4.78119 8.60239 4.72393 8.24391 4.72393H7.52007V6.30215ZM7.52007 9.27607H8.37834C8.79197 9.27607 9.10218 9.20092 9.309 9.05061C9.5227 8.89315 9.62956 8.65337 9.62956 8.33129C9.62956 8.03067 9.5227 7.81237 9.309 7.67638C9.10218 7.54039 8.79197 7.47239 8.37834 7.47239H7.52007V9.27607Z"
    attr.fill="{{ textColor }}" />
  <path
    d="M12.7779 10.5V3.5H16.9969V4.83129H14.298V6.23773H16.5936V7.55828H14.298V9.16871H17.1003V10.5H12.7779Z"
    attr.fill="{{ textColor }}" />
  <path d="M20.2734 10.5V4.83129H18.4224V3.5H23.6547V4.83129H21.8038V10.5H20.2734Z" attr.fill="{{ textColor }}" />
  <path
    d="M23.9507 10.5L26.0602 3.5H27.8802L30 10.5H28.3869L27.5079 6.95705C27.4114 6.60634 27.3183 6.23415 27.2287 5.84049C27.1391 5.44683 27.0495 5.06748 26.9599 4.70245H26.9185C26.8427 5.06748 26.7599 5.44683 26.6703 5.84049C26.5807 6.23415 26.4876 6.60634 26.3911 6.95705L25.5018 10.5H23.9507ZM25.3364 8.83589V7.60123H28.5833V8.83589H25.3364Z"
    attr.fill="{{ textColor }}" />
</svg>
