import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-chart-icon',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 13.5647V2.40282C1 2.29886 1.03938 2.20574 1.11814 2.12344C1.19691 2.04115 1.29536 2 1.4135 2C1.52289 2 1.61697 2.04115 1.69573 2.12344C1.7745 2.20574 1.81388 2.29886 1.81388 2.40282V13.0579C1.81388 13.1576 1.8642 13.2074 1.96484 13.2074H14.5996C14.7046 13.2074 14.7965 13.2442 14.8753 13.3178C14.9584 13.3958 15 13.4911 15 13.6037C15 13.7206 14.9584 13.8159 14.8753 13.8896C14.7965 13.9632 14.7046 14 14.5996 14H1.44632C1.31067 14 1.20128 13.9589 1.11814 13.8766C1.03938 13.7986 1 13.6946 1 13.5647ZM1.72199 9.99134L1.11814 9.39361L3.97328 6.5739C4.10892 6.4353 4.24238 6.33568 4.37365 6.27504C4.50492 6.2144 4.64057 6.18408 4.78059 6.18408C4.91624 6.18408 5.0497 6.21657 5.18097 6.28154C5.31661 6.34218 5.45007 6.43963 5.58134 6.5739L8.12799 9.10774C8.22425 9.1987 8.31396 9.24418 8.39709 9.24418C8.48461 9.24418 8.57431 9.1987 8.6662 9.10774L11.4885 6.26854L10.3202 5.11207C10.2589 5.0471 10.2261 4.98213 10.2218 4.91716C10.2218 4.85219 10.2458 4.79372 10.294 4.74174C10.3421 4.68544 10.4099 4.64645 10.4974 4.6248L14.2583 3.76719C14.3371 3.74986 14.4093 3.75636 14.4749 3.78668C14.5406 3.81267 14.5887 3.85815 14.6193 3.92312C14.6499 3.98376 14.6565 4.05522 14.639 4.13752L13.7661 7.86681C13.7442 7.95344 13.7048 8.02057 13.6479 8.06822C13.5954 8.11153 13.5363 8.13319 13.4707 8.13319C13.4051 8.13319 13.3416 8.10287 13.2804 8.04223L12.0989 6.86627L9.20441 9.75744C8.94187 10.013 8.67276 10.1408 8.39709 10.1408C8.25707 10.1408 8.11924 10.1104 7.98359 10.0498C7.85232 9.98484 7.72324 9.88738 7.59634 9.75744L5.04313 7.21711C4.95124 7.12615 4.86373 7.08067 4.78059 7.08067C4.69308 7.08067 4.60338 7.12615 4.51149 7.21711L1.72199 9.99134Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ChartIconComponent {
  @Input() color: string = '#183642';
}
