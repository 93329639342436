import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'esg-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
})
export class FormDialogComponent {
  @Input() submitText: string = '';
  @Input() submitDisabled: boolean = false;
  @Output() onCancel = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  expandStickyDivider = false;

  @ViewChild('container') public container!: ElementRef<HTMLElement>;

  handleOnCancel() {
    this.onCancel.emit();
  }

  handleOnSubmit() {
    !this.submitDisabled && this.onSubmit.emit();
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.rootBounds?.bottom) {
      this.expandStickyDivider = tabEntry.boundingClientRect.bottom > tabEntry.rootBounds.bottom;
    } else this.expandStickyDivider = false;
  }
}
