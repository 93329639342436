import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';

@Component({
  selector: 'esg-topic-tabs',
  templateUrl: './topic-tabs.component.html',
  styleUrls: ['./topic-tabs.component.scss'],
})
export class TopicTabsComponent implements OnInit {
  @Input() tabs: ITabItem[] = [];
  @Output() onTabClick = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  handleOnTabClick(event: Event, tabValue: string) {
    event.stopPropagation();
    event.preventDefault();
    this.onTabClick.emit(tabValue);
  }
}
