<div tabindex="0" class="container" esg-on-click-outside (onClickOutside)="closeOptions()">
  <button #button class="ellipsis" [class.active]="open" (click)="toggleOpenOptions()">
    <esg-ellipsis-horizontal class="ellipsis-icon"></esg-ellipsis-horizontal>
  </button>
  <esg-select-options
    *ngIf="open"
    class="options"
    [selectedValues]="fc.value"
    [options]="options"
    [dropUp]="dropUp"
    headerLabel="Show/Hide columns"
    [maxHeight]="maxHeight"
    (onChange)="handleSelectOption($event)"></esg-select-options>
</div>
