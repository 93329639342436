import { Component } from '@angular/core';
import { OrganizationsStateService } from '../organizations-state.service';
import { OrganizationsComponent } from '../organizations.component';
import { AllOrganizationsStateService } from './all-organizations-state.service';

@Component({
  selector: 'esg-organizations',
  templateUrl: '../organizations.component.html',
  styleUrls: ['../organizations.component.scss'],
  providers: [{ provide: OrganizationsStateService, useClass: AllOrganizationsStateService }],
})
export class AllOrganizationsComponent extends OrganizationsComponent {
  handleSubmitOrganization() {
    if (this.state.selectedOrganization) {
      this.showOrganizationForm = false;
      this.state.selectedOrganization = undefined;
      this.state.loadOrganizations();
    } else {
      this.showOrganizationForm = false;
      this.state.loadOrganizations();
    }
  }
}
