import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'esg-text-view-editor',
  templateUrl: './text-view-editor.component.html',
  styleUrls: ['./text-view-editor.component.scss'],
})
export class TextViewEditorComponent implements OnInit {
  @Input() content?: string = '';
  @Input() typography: 'html-report' | 'standard' = 'html-report';

  constructor() {}

  ngOnInit(): void {}
}
