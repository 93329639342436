<div #scrollWrapper class="scroll-wrapper" [style.max-height]="maxHeight">
  <span *ngIf="headerLabel" class="header">
    {{ headerLabel }}
  </span>
  <ng-container *ngTemplateOutlet="optionsTemplate; context: { options: favouriteOptions }"></ng-container>
  <div *ngIf="favouriteOptions?.length" class="line"></div>

  <label
    *ngIf="allowReset"
    class="option"
    (click)="handleOnReset($event)"
    [class.selected]="!selectedValues.length ? true : false">
    <esg-checkmark></esg-checkmark>
    <span class="option-label">
      {{ resetLabel }}
    </span>
  </label>

  <ng-container *ngFor="let group of groupedOptions; let index = index">
    <span *ngIf="groupedOptions.length > 1" class="group">
      {{ group.label }}
      <span class="group-divider"></span>
    </span>
    <ng-container *ngTemplateOutlet="optionsTemplate; context: { options: group.options }"></ng-container>
  </ng-container>

  <ng-container *ngTemplateOutlet="optionsTemplate; context: { options: options }"></ng-container>

  <ng-template #optionsTemplate let-options="options">
    <ng-container *ngFor="let option of options">
      <label
        class="option"
        [class.disabled]="option.disabled"
        [class.selected]="isOptionSelected(option) ? true : false"
        (click)="handleChange($event, option)">
        <esg-checkmark></esg-checkmark>
        <span class="option-label">
          {{ option.optionLabel ? option.optionLabel : option.label }}
        </span>
      </label>
    </ng-container>
  </ng-template>
</div>
