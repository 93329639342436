import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VesselAsset, UpdateAssetCommandParams } from 'src/api-client/report-api.generated';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { IOption } from 'src/app/static-data/options';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';
import {
  RecordSubCategoryLabel,
  RecordCategoryLabel,
  RecordCategoryPath,
  RecordSubCategoryPath,
} from 'src/app/static-data/enum-mappings';
import { RecordsOverviewUi } from '../shared/records-overview/records-overview.component';
import { AssetVersionUi } from '../shared/asset-version-menu/asset-version-menu.component';
import { v4 as uuidv4 } from 'uuid';

export interface AssetDetailsForm {
  versionValidFrom: FormControl<Date>;
  name: FormControl<string>;
  organization: FormControl<IOption | undefined>;
}

@Injectable()
export class AssetDetailsStateService {
  assetId!: string;
  assetVersions: AssetVersionUi[] = [];
  activeVersion?: string;
  isFormOpen = false;
  newVersionDate?: Date;
  isValidFromDateOpen = false;
  isDeleteDialogOpen = false;
  recordsOverview: RecordsOverviewUi[] = [];
  mainOrganizationId?: string;
  organizationOptions?: IOption[];
  defaultOrganizationCode?: string;

  loading: boolean = false;

  fc?: AssetDetailsForm;

  formgroup?: FormGroup;

  tabs: ITabItem[] = [{ value: 'recordOverview', label: 'Record Overview' }];
  activeTab = this.tabs[0].value;

  constructor(protected assetApiService: AssetApiService, protected dataRecordService: DataRecordApiService) {}

  async getAssetVersions() {
    if (this.assetId) {
      const response = await this.assetApiService.getAssetVersions(this.assetId);
      this.assetVersions =
        response.result?.map(version => ({ id: version.versionId, value: version.versionValidFrom })) || [];
      this.assetVersions.sort((a, b) => b.value.getTime() - a.value.getTime());
      if (this.assetVersions.length) {
        if (!this.activeVersion || !this.assetVersions.some(version => version.id === this.activeVersion)) {
          this.activeVersion = this.assetVersions[0].id;
        }
        await this.getAssetVersion(this.activeVersion);
      }
    }
  }

  async getAssetVersion(versionId: string) {
    const response = await this.assetApiService.getAssetById(this.assetId, versionId);
    const vessel = response.result as VesselAsset;
    this.mainOrganizationId = vessel.organizationId;

    this.fc = {
      versionValidFrom: new FormControl<Date>(vessel.versionValidFrom, { nonNullable: true }),
      name: new FormControl<string>(vessel.name, { validators: [Validators.required], nonNullable: true }),
      organization: new FormControl<IOption | undefined>(
        this.organizationOptions?.find(org =>
          vessel?.subOrganizationId ? org.value === vessel?.subOrganizationId : org.value === vessel?.organizationId
        ),
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
    };

    this.formgroup = new FormGroup(this.fc);
  }

  handleDropdownChange(formControl: FormControl<IOption | null | undefined>, option: IOption) {
    if (option !== formControl.value) {
      formControl.setValue(option);
      formControl.markAsDirty();
    }
  }

  handleValidFromChange(date: Date) {
    if (this.newVersionDate) {
      this.newVersionDate = date;
    } else {
      const activeVersionIndex = this.assetVersions.findIndex(version => version.id === this.activeVersion);
      if (date && activeVersionIndex !== -1) {
        this.assetVersions[activeVersionIndex].value = date;
        this.assetVersions.sort((a, b) => b.value.getTime() - a.value.getTime());
      }
    }
  }

  handleValidFromOpen() {
    this.isValidFromDateOpen = true;
  }

  handleValidFromClose() {
    this.isValidFromDateOpen = false;
  }

  handleVersionClick(versionId: string) {
    this.activeVersion = versionId;
    this.isFormOpen = false;
    this.newVersionDate = undefined;
    this.getAssetVersion(versionId);
  }

  async handleNewVersion() {
    const newVersionDate = new Date();
    const newestVersion = this.assetVersions[0].id;
    await this.getAssetVersion(newestVersion);
    this.activeVersion = newestVersion;
    this.newVersionDate = newVersionDate;
    this.fc?.versionValidFrom.setValue(newVersionDate);
    this.isFormOpen = true;
  }

  async handleVersionSubmit() {
    if (this.formgroup?.valid && this.fc?.versionValidFrom) {
      const wasNewVersion = this.newVersionDate ? true : false;
      const versionId = wasNewVersion ? uuidv4() : this.activeVersion;
      this.isFormOpen = false;
      this.newVersionDate = undefined;
      if (!versionId) return;
      const response = await this.updateAsset(versionId);
      if (response?.success) {
        this.activeVersion = versionId;
        this.getAssetVersions();
      }
    }
  }

  async updateAsset(versionId: string) {
    if (!this.fc) return;
    return await this.assetApiService.updateAsset(
      new UpdateAssetCommandParams({
        id: this.assetId,
        name: this.fc.name.value,
        versionId: versionId,
        subOrganizationId:
          this.fc.organization.value?.value === this.mainOrganizationId ? undefined : this.fc.organization.value?.value,
        versionValidFrom: this.fc.versionValidFrom.value,
      })
    );
  }

  handleOnDeleteClick() {
    this.isDeleteDialogOpen = true;
  }

  handleDeleteDialogClose() {
    this.isDeleteDialogOpen = false;
  }

  async handleVersionDelete() {
    this.isDeleteDialogOpen = false;
    if (this.assetId && this.activeVersion) {
      this.isFormOpen = false;
      const response = await this.assetApiService.deleteAssetVersion(this.assetId, this.activeVersion);
      if (response.success) {
        this.activeVersion = undefined;
        this.getAssetVersions();
      }
    }
  }

  handleOnFormClick() {
    this.isFormOpen = true;
  }

  handleCancel() {
    if (!this.isValidFromDateOpen) {
      this.formgroup?.reset();
      if (!this.newVersionDate && this.fc?.versionValidFrom.value) {
        this.handleValidFromChange(this.fc?.versionValidFrom.value);
      }
      this.isFormOpen = false;
      this.newVersionDate = undefined;
    }
  }

  async getRecordsOverview() {
    if (this.assetId) {
      const response = await this.dataRecordService.getAssetRecordsOverview(this.assetId);
      this.recordsOverview = response.result.map(row => {
        const subCategory = RecordSubCategoryLabel[row.dataRecordType];
        const categoryLabel = RecordCategoryLabel[row.category] || row.category;
        const link = ['/', 'data-collect', RecordCategoryPath[row.category] || ''];
        const subCategoryPath = RecordSubCategoryPath[row.dataRecordType] || '';

        if (subCategoryPath) {
          link.push(subCategoryPath);
        }

        const category = subCategory ? `${categoryLabel} / ${subCategory}` : categoryLabel;

        return {
          category,
          count: row.recordCount,
          firstDate: row.firstRecordDate,
          lastDate: row.lastRecordDate,
          link,
          recordType: subCategoryPath ? undefined : row.dataRecordType,
        };
      });
    }
  }
}
