import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-icon-minus',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      (mouseenter)="toggleHover()"
      (mouseleave)="toggleHover()"
      viewBox="0 0 11 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="11" height="1" fill="#333333" />
    </svg>
  `,
  styles: [],
})
export class MinusIconComponent {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  // TODO CANSU How can I see icon hover color on Figma?
  @Input() size: string = '16';
  @Input() color: string = '#EEEEEE';
  @Input() hoverColor: string = 'green';

  currentColor: string = this.color;
  hovered: boolean = false;

  toggleHover() {
    this.hovered = !this.hovered;
    this.currentColor = this.hovered ? this.hoverColor : this.color;
  }
}
