import { Component } from '@angular/core';
@Component({
  selector: 'esg-icon-expanders',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 10.5714C8.11521 10.5714 8.2212 10.5247 8.30415 10.4359L11.8802 6.73386C11.9585 6.64972 12 6.54689 12 6.42536C12 6.18697 11.8203 6 11.5806 6C11.4654 6 11.3594 6.04674 11.2811 6.12153L8 9.52439L4.71889 6.12153C4.64055 6.04674 4.53917 6 4.41935 6C4.17972 6 4 6.18697 4 6.42536C4 6.54689 4.04147 6.64972 4.12442 6.73386L7.69585 10.4359C7.78341 10.5247 7.88479 10.5714 8 10.5714Z"
        fill="#999999" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ExpanderIconComponent {}
