import { Component, Input } from '@angular/core';

export enum RiskCategory {
  Unknown = 'Unknown',
  VeryHighRisk = 'VeryHighRisk',
  HighRisk = 'HighRisk',
  MediumRisk = 'MediumRisk',
  LowRisk = 'LowRisk',
  NoRisk = 'NoRisk',
}

export interface IRiskBarLabel {
  riskCategory: RiskCategory;
  label: string;
  color: string;
}

const RiskLabels: { [key in RiskCategory]: IRiskBarLabel } = {
  [RiskCategory.Unknown]: {
    riskCategory: RiskCategory.Unknown,
    label: 'n/a*',
    color: '#999999',
  },
  [RiskCategory.VeryHighRisk]: {
    riskCategory: RiskCategory.VeryHighRisk,
    label: 'Very High',
    color: '#EA1F4C',
  },
  [RiskCategory.HighRisk]: {
    riskCategory: RiskCategory.HighRisk,
    label: 'High',
    color: '#EA708A',
  },
  [RiskCategory.MediumRisk]: {
    riskCategory: RiskCategory.MediumRisk,
    label: 'Medium',
    color: '#FF9900',
  },
  [RiskCategory.LowRisk]: {
    riskCategory: RiskCategory.LowRisk,
    label: 'Low',
    color: '#72DC9B',
  },
  [RiskCategory.NoRisk]: {
    riskCategory: RiskCategory.NoRisk,
    label: 'No Risk',
    color: '#23D468',
  },
};

@Component({
  selector: 'esg-risk-bar',
  templateUrl: './risk-bar.component.html',
  styleUrls: ['./risk-bar.component.scss'],
})
export class RiskBarComponent {
  @Input({ required: true }) score!: number;

  get isUnknown(): boolean {
    return this.riskLabel.riskCategory === RiskCategory.Unknown;
  }

  get riskLabel(): IRiskBarLabel {
    switch (true) {
      case isNaN(this.score):
        return RiskLabels[RiskCategory.Unknown];
      case this.score <= 10:
        return RiskLabels[RiskCategory.VeryHighRisk];
      case this.score > 10 && this.score <= 35:
        return RiskLabels[RiskCategory.HighRisk];
      case this.score > 35 && this.score <= 75:
        return RiskLabels[RiskCategory.MediumRisk];
      case this.score > 75 && this.score <= 90:
        return RiskLabels[RiskCategory.LowRisk];
      case this.score > 90 && this.score <= 100:
        return RiskLabels[RiskCategory.NoRisk];
      default:
        throw new Error('Invalid score');
    }
  }
}
