import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AssetType,
  UpdateFacilityAssetCommandParams,
  FacilityAsset,
  CountryCodes,
  FacilityType,
} from 'src/api-client/report-api.generated';
import { IOption, facilityTypeOptions } from 'src/app/static-data/options';
import { formattedStringToNumber, stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { AssetDetailsForm, AssetDetailsStateService } from '../../asset-details-state.service';

interface FacilityDetailsForm extends AssetDetailsForm {
  type: FormControl<IOption | undefined>;
  country: FormControl<IOption | undefined>;
  address: FormControl<string>;
  sqm_m2: FormControl<string>;
}

@Injectable()
export class FacilityDetailsStateService extends AssetDetailsStateService {
  countryOptions: IOption[] = [];
  typeOptions: IOption[] = facilityTypeOptions;

  fc?: FacilityDetailsForm;

  async init(assetId: string, organizationOptions: IOption[], countryOptions: IOption[]) {
    this.loading = true;
    this.assetId = assetId;
    this.organizationOptions = organizationOptions;
    this.countryOptions = countryOptions;
    await Promise.all([this.getAssetVersions(), this.getRecordsOverview()]);
    this.loading = false;
  }

  async getAssetVersion(versionId: string) {
    const response = await this.assetApiService.getAssetById(this.assetId, versionId);
    const facility = response.result as FacilityAsset;
    this.mainOrganizationId = facility.organizationId;

    this.fc = {
      versionValidFrom: new FormControl<Date>(facility.versionValidFrom, { nonNullable: true }),
      name: new FormControl<string>(facility.name, { validators: [Validators.required], nonNullable: true }),
      organization: new FormControl<IOption | undefined>(
        this.organizationOptions?.find(org =>
          facility?.subOrganizationId
            ? org.value === facility?.subOrganizationId
            : org.value === facility?.organizationId
        ),
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      type: new FormControl<IOption | undefined>(
        this.typeOptions.find(type => type.label === facility.subType || type.value === facility.subType) ||
          this.typeOptions[0],
        { validators: [Validators.required], nonNullable: true }
      ),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(country => country.value === facility?.countryCode),
        { validators: [Validators.required], nonNullable: true }
      ),
      address: new FormControl<string>(facility.address || '', { nonNullable: true }),
      sqm_m2: new FormControl<string>(
        facility.sqm_m2 ? stringToFormattedNumberString(facility.sqm_m2.toString()) : '',
        {
          nonNullable: true,
        }
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  async updateAsset(versionId: string) {
    const countryCode = Object.values(CountryCodes).find(code => code === this.fc?.country?.value?.value);
    const type = Object.values(FacilityType).find(type => type === this.fc?.type?.value?.value);
    if (this.fc && countryCode && type) {
      return await this.assetApiService.updateAsset(
        new UpdateFacilityAssetCommandParams({
          id: this.assetId,
          type: AssetType.Facility,
          subType: type,
          name: this.fc.name.value,
          versionId: versionId,
          subOrganizationId:
            this.fc.organization.value?.value === this.mainOrganizationId
              ? undefined
              : this.fc.organization.value?.value,
          address: this.fc.address.value || undefined,
          countryCode: countryCode,
          sqm_m2: formattedStringToNumber(this.fc.sqm_m2.value || ''),
          versionValidFrom: this.fc.versionValidFrom.value,
        })
      );
    } else return undefined;
  }
}
