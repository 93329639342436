<div *ngIf="state.dataCategory" class="content">
  <h2 class="title">{{ state.dataCategory.name }}</h2>
  <esg-text-editor
    name="guidanceValue"
    [fc]="state.dataCategory.guidance"
    placeholder="Guidance text here…"
    classes="ql-resize"
    [editorStyles]="textAreaStyles"
    [hasParagraphStyles]="false"></esg-text-editor>
</div>
