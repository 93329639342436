<div class="container">
  <header *ngIf="!isLoading">Select a template</header>

  <div *ngIf="!isLoading" class="list">
    <esg-template-card
      [blankReport]="true"
      title="Empty blank"
      shortDescription="An empty blank template for users that need to create customized topics and indicators."
      longDescription="This is an empty blank template. You’ll need to create every topic and indicator from scratch.

      Use this template if there are no other templates that covers your needs or if you need to create fully customized topics and indicators
      "
      (onClick)="onSelectTemplate.emit()"></esg-template-card>

    <div class="custom-templates" *ngFor="let item of templates">
      <esg-template-card
        [blankReport]="false"
        [title]="item.title || ''"
        (onClick)="onSelectTemplate.emit(item)"
        [backgroundPictureUrl]="appApiService.getBlobUrl(item.coverPhoto.blobThumbnailName || item.coverPhoto.blobName)"
        [shortDescription]="item.shortDescription || ''"
        [longDescription]="item.description || ''"></esg-template-card>
    </div>
  </div>
  <esg-dot-flashing *ngIf="isLoading" class="loader" label="We are gathering your templates"></esg-dot-flashing>
</div>
