export function getInitials(name: string): string {
  if (!name) {
    return '';
  }
  let words = name.split(' ');
  if (words.length < 2) {
    return words[0][0].toUpperCase();
  }
  let firstInitial = words[0][0].toUpperCase();
  let lastInitial = words[words.length - 1][0].toUpperCase();
  return firstInitial + lastInitial;
}
