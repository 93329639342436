import { Directive, HostListener, Output, EventEmitter, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[esgKeyboardShortcuts]',
  standalone: true,
})
export class KeyboardShortcutsDirective {
  @Input() keyOnlyOnFocus: boolean = false;
  @Output() onEscapeKey = new EventEmitter<Event>();
  @Output() onEnterKey = new EventEmitter<Event>();

  constructor(private hostRef: ElementRef) {}

  @HostListener('document:keydown.escape', ['$event'])
  handleOnEscapeKey(event: Event) {
    if (this.keyOnlyOnFocus && !this.hostRef.nativeElement.contains(event.target)) return;
    this.onEscapeKey.emit(event);
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleOnEnterKey(event: Event) {
    if (this.keyOnlyOnFocus && !this.hostRef.nativeElement.contains(event.target)) return;
    this.onEnterKey.emit(event);
  }
}
