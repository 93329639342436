import { Component } from '@angular/core';
@Component({
  selector: 'esg-icon-delete-xmark',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.16563 12.836C3.24127 12.9075 3.32743 12.9558 3.42409 12.9811C3.52076 13.0063 3.61742 13.0063 3.71408 12.9811C3.81074 12.9558 3.8948 12.9075 3.96624 12.836L8.00086 8.79811L12.0355 12.836C12.1069 12.9075 12.191 12.9558 12.2876 12.9811C12.3843 13.0063 12.481 13.0063 12.5776 12.9811C12.6785 12.96 12.7646 12.9117 12.8361 12.836C12.9075 12.7645 12.9538 12.6803 12.9748 12.5836C13 12.4869 13 12.3901 12.9748 12.2934C12.9538 12.1966 12.9075 12.1125 12.8361 12.041L8.80148 7.99685L12.8361 3.95899C12.9075 3.88749 12.9559 3.80336 12.9811 3.70662C13.0063 3.60988 13.0063 3.51314 12.9811 3.4164C12.9559 3.31966 12.9075 3.23554 12.8361 3.16404C12.7604 3.08833 12.6743 3.03996 12.5776 3.01893C12.481 2.99369 12.3843 2.99369 12.2876 3.01893C12.191 3.03996 12.1069 3.08833 12.0355 3.16404L8.00086 7.20189L3.96624 3.16404C3.8948 3.08833 3.80864 3.03996 3.70778 3.01893C3.61111 2.99369 3.51445 2.99369 3.41779 3.01893C3.32113 3.03996 3.23707 3.08833 3.16563 3.16404C3.09418 3.23554 3.04585 3.31966 3.02063 3.4164C2.99962 3.51314 2.99962 3.60988 3.02063 3.70662C3.04585 3.80336 3.09418 3.88749 3.16563 3.95899L7.20024 7.99685L3.16563 12.041C3.09418 12.1125 3.04585 12.1966 3.02063 12.2934C2.99542 12.3901 2.99331 12.4869 3.01433 12.5836C3.03954 12.6803 3.08998 12.7645 3.16563 12.836Z"
        fill="#333333" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class DeleteXmarkIconComponent {}
