import { Injectable } from '@angular/core';
import {
  DataRecordCategory,
  DataProfile,
  TimePeriod,
  CreateDataProfileCommandParams,
  UpdateDataProfileCommandParams,
} from 'src/api-client/report-api.generated';
import { DataProfileApiService } from 'src/app/api-client/report-api/data-profile-api-service';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { IOption, ProfileInputOptions } from 'src/app/static-data/options';
import { InputType } from 'src/api-client/report-api.generated';
import { Subscription } from 'rxjs';
import { AppInfoService } from 'src/app/core/app-info.service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { RecordCategoryRoutes } from '../data-collect-page.component';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

export interface ProfileForm {
  inputType: FormControl<IOption>;
  timePeriod: FormControl<ITimePeriodData | undefined>;
}

export interface ProfileUi {
  id: string;
  name: string;
  description: string;
  type: InputType;
  timePeriod: TimePeriod;
}

interface CategoryDetailsUi {
  name: string;
  guidance: string;
}

@Injectable()
export class ProfilesStateService {
  private organizationContextSub!: Subscription;

  recordCategoryRoutes = RecordCategoryRoutes;
  selectedProfile?: ProfileUi;
  categoryDetails: CategoryDetailsUi | undefined = undefined;
  profiles: ProfileUi[] = [];
  loading: boolean = false;
  updating: boolean = false;
  showFormDialog: boolean = false;
  showRemoveDialog: boolean = false;
  profileFormgroup?: FormGroup = undefined;
  organizationId?: string;

  constructor(
    private appInfo: AppInfoService,
    private appConfig: AppConfigurationService,
    protected dataProfileApiService: DataProfileApiService,
    protected dataCategoryApiService: DataCategoryApiService
  ) {
    this.organizationContextSub = this.appInfo.organizationContext$.subscribe(organizationId => {
      if (organizationId) {
        this.organizationId = organizationId;
        this.init();
      }
    });
  }

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  async init() {
    this.loading = true;
    await Promise.all([this.getProfiles(), this.getDataCategoryDetails()]);
    this.loading = false;
  }

  async getProfiles() {
    const response = await this.dataProfileApiService.getAllDataProfiles();
    if (response.success)
      this.profiles = response.result.map(profile => {
        const commutingProfile = profile as DataProfile;
        return {
          id: commutingProfile.id,
          name: commutingProfile.timePeriod.period
            ? getTimeLabelFromPeriod(commutingProfile.timePeriod)
            : 'Annual ' + getTimeLabelFromPeriod(commutingProfile.timePeriod),
          description: commutingProfile.description,
          timePeriod: commutingProfile.timePeriod,
          type: commutingProfile.inputType,
        };
      });
  }

  async getDataCategoryDetails() {
    const { result } = await this.dataCategoryApiService.getDataCategoryByDataRecordCategory(
      DataRecordCategory.IndirectEmissionsEmployeeCommuting
    );
    this.categoryDetails = {
      name: result.name,
      guidance: result.guidance,
    };
  }

  initateAdd() {
    this.showFormDialog = true;
    this.profileFormgroup = new FormGroup({
      timePeriod: new FormControl<ITimePeriodData | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      inputType: new FormControl<IOption>(ProfileInputOptions[0], {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  handleSelectProfile(profile: ProfileUi) {
    this.selectedProfile = profile;
    this.profileFormgroup = new FormGroup({
      timePeriod: new FormControl<ITimePeriodData | undefined>(profile.timePeriod, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      inputType: new FormControl<IOption>(
        ProfileInputOptions.find(opt => opt.value === profile.type) || ProfileInputOptions[0],
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
    });
  }

  handleInitiateEdit() {
    if (this.selectedProfile && this.profileFormgroup) {
      this.showFormDialog = true;
    }
  }

  handleCancelForm() {
    if (!this.selectedProfile) this.profileFormgroup = undefined;
    this.showFormDialog = false;
  }

  handleCloseDetails() {
    this.selectedProfile = undefined;
    this.profileFormgroup = undefined;
    this.getProfiles();
  }

  async formSubmit() {
    if (!this.profileFormgroup) {
      return;
    }

    const formOpen = this.showFormDialog;
    const timePeriod = this.profileFormgroup.controls.timePeriod.value;
    const inputType = Object.values(InputType).find(
      type => type === this.profileFormgroup?.controls.inputType.value.value
    );

    if (!inputType || !timePeriod || !this.organizationId) {
      return;
    }

    this.handleCancelForm();
    if (this.selectedProfile) {
      await this.dataProfileApiService.updateDataProfile(
        new UpdateDataProfileCommandParams({
          id: this.selectedProfile.id,
          description: '',
          inputType: inputType,
          timePeriodType: timePeriod.type,
          year: timePeriod.year,
          period: timePeriod.period,
        })
      );
      if (formOpen) {
        await this.getProfiles();
        this.selectedProfile = this.profiles.find(profile => profile.id === this.selectedProfile?.id);
      }
    } else {
      this.updating = true;
      await this.dataProfileApiService.createDataProfile(
        new CreateDataProfileCommandParams({
          description: '',
          inputType: inputType,
          timePeriodType: timePeriod.type,
          year: timePeriod.year,
          period: timePeriod.period,
        })
      );
      await this.getProfiles();
      this.updating = false;
    }
  }

  handleInitateDelete() {
    this.showRemoveDialog = true;
  }

  handleCloseDeleteDialog() {
    this.showRemoveDialog = false;
  }

  async handleDelete() {
    this.showRemoveDialog = false;
    if (this.selectedProfile) {
      await this.dataProfileApiService.deleteDataProfile(this.selectedProfile.id);
      this.handleCloseDetails();
      this.getProfiles();
    }
  }

  menuProvider = (): IMenuItem[] => [
    { id: 'edit', label: 'Edit', onClick: () => this.handleInitiateEdit() },
    { id: 'delete', label: 'Delete', onClick: () => this.handleInitateDelete() },
  ];

  ngOnDestroy() {
    this.organizationContextSub.unsubscribe();
  }
}
