import { Injectable } from '@angular/core';
import { RegionClientApiService } from 'src/app/api-client/report-api/region-api-service';
import { RegionUi, ViewModeEnums } from './regions.component';
import { FormControl } from '@angular/forms';
import { CreateRegionCommandParams, UpdateRegionCommandParams } from 'src/api-client/report-api.generated';
import { AppInfoService } from 'src/app/core/app-info.service';
import { Subscription } from 'rxjs';

@Injectable()
export class RegionsStateService {
  private organizationContextSub!: Subscription;
  regions: RegionUi[] = [];
  loading: boolean = false;
  viewMode?: ViewModeEnums;
  regionNameFormControl = new FormControl<string>('');
  organizationId?: string;

  editedRegion?: RegionUi;
  showRemoveRegionFor?: RegionUi;
  showStickyDivider = false;

  constructor(private appInfoState: AppInfoService, private regionApiService: RegionClientApiService) {
    this.organizationContextSub = this.appInfoState.organizationContext$.subscribe(organizationId => {
      if (organizationId) {
        this.organizationId = organizationId;
        this.init();
      }
    });
  }

  async init() {
    this.loading = true;
    await this.getRegions();
    this.loading = false;
  }

  async getRegions() {
    try {
      const response = await this.regionApiService.getAllRegions();
      this.regions = response.result.map(region => ({
        id: region.id,
        name: region.name,
      }));
    } catch (error) {
      this.loading = false;
    }
  }

  create() {
    this.viewMode = ViewModeEnums.Create;
  }

  async submitCreate() {
    const regionName = this.regionNameFormControl.value;
    this.viewMode = undefined;
    this.regionNameFormControl.setValue('');

    if (regionName && this.organizationId) {
      await this.regionApiService.create(
        new CreateRegionCommandParams({
          organizationId: this.organizationId,
          name: regionName,
        })
      );
      this.getRegions();
    }
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    this.showStickyDivider = tabEntry.intersectionRatio === 0;
  }

  cancelCreate() {
    this.viewMode = undefined;
    this.regionNameFormControl.setValue('');
  }

  editRegion(region: RegionUi) {
    this.viewMode = ViewModeEnums.Edit;
    this.regionNameFormControl.setValue(region.name);
    this.regionNameFormControl.markAsDirty();

    this.editedRegion = region;
  }

  async submitEdit() {
    const region = this.editedRegion;
    const newName = this.regionNameFormControl.value;
    this.viewMode = undefined;
    this.regionNameFormControl.setValue('');
    this.editedRegion = undefined;

    if (!region || !newName) {
      return;
    }

    if (region.name !== newName) {
      await this.regionApiService.update(
        new UpdateRegionCommandParams({
          id: region.id,
          name: newName,
        })
      );
      this.getRegions();
    }
  }

  cancelEdit() {
    this.viewMode = undefined;
    this.regionNameFormControl.setValue('');
    this.editedRegion = undefined;
  }

  deleteRegion(region: RegionUi) {
    this.showRemoveRegionFor = region;
  }

  async deleteRegionSubmit() {
    const regionId = this.showRemoveRegionFor?.id;
    this.showRemoveRegionFor = undefined;
    if (regionId) {
      await this.regionApiService.delete(regionId);
      this.getRegions();
    }
  }

  cancelDelete() {
    this.showRemoveRegionFor = undefined;
  }

  ngOnDestroy() {
    this.organizationContextSub.unsubscribe();
  }
}
