<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading categories"></esg-dot-flashing>
<ng-container *ngIf="!state.loading">
  <h2>Data Collect Categories</h2>
  <table>
    <thead>
      <tr>
        <th>CATEGORY</th>
        <th>MAIN TOPIC</th>
        <th>LAST UPDATED</th>
        <th>EDITED BY</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let category of state.categories"
        [class.active]="state.selectedCategory === category.dataRecordCategory"
        (click)="handleSelectCategory(category.dataRecordCategory)">
        <td>
          {{ category.name }}
        </td>
        <td>
          {{ category.mainTopic }}
        </td>
        <td>
          {{ category.lastUpdated | date : 'dd.MM.yyyy' }}
        </td>
        <td>
          {{ category.editedBy }}
        </td>
        <td>
          <div class="arrow-cell">
            Details
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 8C13 7.79839 12.9182 7.6129 12.7628 7.46774L6.28425 1.20968C6.13701 1.07258 5.95706 1 5.74438 1C5.3272 1 5 1.31452 5 1.73387C5 1.93548 5.0818 2.12097 5.21268 2.25806L11.1677 8L5.21268 13.7419C5.0818 13.879 5 14.0565 5 14.2661C5 14.6855 5.3272 15 5.74438 15C5.95706 15 6.13701 14.9274 6.28425 14.7823L12.7628 8.53226C12.9182 8.37903 13 8.20161 13 8Z"
                fill="#999999" />
            </svg>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
<esg-details-dialog [open]="state.selectedCategory ? true : false" (onClose)="handleCloseCategory()">
  <esg-data-collect-category-details
    *ngIf="state.selectedCategory"
    [selectedCategory]="state.selectedCategory"></esg-data-collect-category-details>
</esg-details-dialog>
