<div class="tab-container">
  <a
    *ngFor="let tab of tabs"
    class="tab-item"
    [class.disabled]="tab.disabled"
    [routerLink]="tab.link"
    queryParamsHandling="preserve"
    routerLinkActive="active">
    {{ tab.label ? tab.label : tab.value }}
    <button class="tab-button" (click)="handleOnTabClick($event, tab.value)"></button>
    <span class="tab-underline"></span>
  </a>
</div>
