import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentDataTypes, ContentStatesTypes, ContentTypes } from 'src/app/content/content-configuration';
import { DynamicContent } from 'src/app/content/dynamic-content';
import { IPictureContentData } from 'src/app/content/content-item/picture/picture.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { DynamicContentMode, IMovedContent } from 'src/app/content/dynamic-content-data';

@Injectable()
export class ContentExampleStateService {
  constructor(private domSanitizer: DomSanitizer) {}

  contentList: DynamicContent[] = [];
  reordering: boolean = false;

  stickyComponentsToolbar: boolean = false;

  handleStickyComponentMenu(tabEntry: IntersectionObserverEntry) {
    this.stickyComponentsToolbar = tabEntry.boundingClientRect.top < 0;
  }

  handleOnAddContent(type: ContentTypes) {
    switch (type) {
      case ContentTypes.Text:
        this.addTextContent();
        break;
      case ContentTypes.Picture:
        this.addImageContent();
        break;
      case ContentTypes.ImportFromExcel:
        this.addImportFromExcelContent();
        break;
      default:
        break;
    }
  }

  addTextContent() {
    this.contentList = [
      ...this.contentList,
      new DynamicContent(
        this.contentList.length.toString(),
        ContentTypes.Text,
        {
          generateText: this.handleGetBotAnswer,
        },
        {},
        undefined
      ),
    ];
  }

  addImageContent() {
    this.contentList = [
      ...this.contentList,
      new DynamicContent(
        this.contentList.length.toString(),
        ContentTypes.Picture,
        {
          uploadPhoto: this.handleUploadPhoto(this.contentList.length.toString()),
        },
        {},
        undefined
      ),
    ];
  }

  addImportFromExcelContent() {
    this.contentList = [
      ...this.contentList,
      new DynamicContent(this.contentList.length.toString(), ContentTypes.ImportFromExcel, {}, {}, undefined),
    ];
  }

  onContentDataChange = (contentId: string, states: ContentStatesTypes, data?: ContentDataTypes) => {
    const component = this.contentList.find(c => c.contentId === contentId);
    if (component) {
      this.contentList = this.contentList.map(c =>
        c.contentId !== component.contentId
          ? c
          : {
              ...component,
              states: states,
              data,
            }
      );
    }
  };

  handleReorder() {
    this.reordering = !this.reordering;
    this.contentList = this.contentList.map(c => ({
      ...c,
      states: {
        ...c?.states,
        open: !this.reordering,
      },
    }));
  }

  handleGetBotAnswer = async (question: string): Promise<string> => {
    return 'Your prompt was ' + question;
  };

  moveContent(movedContent: IMovedContent) {
    const newList = [...this.contentList];
    moveItemInArray(newList, movedContent.prevIndex, movedContent.newIndex);
    this.contentList = [...newList];
  }

  deleteContent(contentId: string) {
    const newList = [...this.contentList];
    newList.splice(
      this.contentList.findIndex(comp => comp.contentId === contentId),
      1
    );
    this.contentList = [...newList];
  }

  handleUploadPhoto = (componentId: string) => async (file?: File) => {
    const component = this.contentList.find(c => c.contentId === componentId);
    if (file && component) {
      var objectUrl = URL.createObjectURL(file);
      const photo = {
        url: this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl),
        fileName: 'test',
      };

      const updatedContent: IPictureContentData = {
        altText: '',
        blobName: 'test',
      };

      this.onContentDataChange(
        componentId,
        {
          ...component.states,
          uploadedPhoto: photo,
        },
        updatedContent
      );
    }
  };
}
