import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { IOption } from 'src/app/static-data/options';

export interface RecordHeaderCell {
  columnId: string;
  label: string;
  description: string;
  number?: boolean;
  optional?: boolean;
  possibleToHide?: boolean;
}

export interface RecordBodyRow {
  id: string;
  cells: RecordBodyCell[];
  recordSource?: RecordSource;
}

export interface RecordBodyCell {
  columnId: string;
  value: string;
  unit?: string;
  number: boolean;
}

export enum RecordSource {
  RawData = 'RawData',
  IndicatorDefinition = 'IndicatorDefinition',
}

@Component({
  selector: 'esg-records-table',
  templateUrl: './records-table.component.html',
  styleUrls: ['./records-table.component.scss'],
})
export class RecordsTableComponent {
  @Input() headerCells: RecordHeaderCell[] = [];
  @Input() bodyRows: RecordBodyRow[] = [];
  @Input() newlyAddedRowsIds: string[] = [];
  @Input() columnFilter?: FormControl<IOption[]>;
  @Input() readOnly: boolean = false;
  @Input() addOnly: boolean = false;
  @Input() isAdding: boolean = false;
  @Input() hideAddButton: boolean = false;
  @Input() editRecordId?: string;
  @Input() stickyHeaderTopOffset: number = 0;
  @Input() intersectionObserverRootMargin: number = 0;
  @Input() stickyHeaderPadding: number = 0;

  @Output() onAdding = new EventEmitter();
  @Output() onFormClose = new EventEmitter();
  @Output() onEditing = new EventEmitter<string>();
  @Output() onSubmit = new EventEmitter();
  @Output() onDelete = new EventEmitter<string>();

  readonly RecordSourceEnum = RecordSource;
  hasActiveCell: boolean = false;
  stickyHeaders: boolean = false;

  hidableColumnOptions: IOption[] = [];

  get rootObserverMargin() {
    return `${-this.intersectionObserverRootMargin}px 0px 0px 0px`;
  }

  constructor() {}

  get numberOfVisibleColumns() {
    return this.columnFilter ? this.columnFilter.value.length : this.headerCells.length;
  }

  get gridTemplateColumns() {
    if (this.addOnly) {
      if (this.numberOfVisibleColumns === 2) return '136px 124px minmax(150px, min-content) 1fr';
      else return '136px 124px repeat(' + (this.numberOfVisibleColumns - 1) + ', minmax(min-content, 1fr))';
    } else {
      if (this.numberOfVisibleColumns === 2) return '136px 124px minmax(150px, min-content) 1fr 34px';
      else return '136px 124px repeat(' + (this.numberOfVisibleColumns - 1) + ', minmax(min-content, 1fr)) 34px';
    }
  }

  get fullGridColumn() {
    if (this.numberOfVisibleColumns === 2) return 'span ' + (this.numberOfVisibleColumns + 3);
    return 'span ' + (this.numberOfVisibleColumns + 2);
  }

  get isAddButtonVisible() {
    return !this.hideAddButton && !this.readOnly && !this.isAdding;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.headerCells) {
      this.updateColumnOptions();
    }
  }

  private updateColumnOptions() {
    this.hidableColumnOptions = this.headerCells
      .filter(cell => cell.possibleToHide)
      .map(cell => ({ value: cell.columnId, label: cell.label }));
  }

  isColumnVisible(id: string): boolean {
    if (!this.columnFilter) return true;
    return this.columnFilter.value.some(column => column.value === id);
  }

  trackByRecord(index: number, record: RecordBodyRow) {
    return record.id;
  }

  trackByHeaderCell(index: number, cell: RecordHeaderCell) {
    return cell.columnId;
  }

  handleFocusInside() {
    this.hasActiveCell = true;
  }

  handleOnRowClick(event: Event, id: string) {
    if (this.hasActiveCell || this.readOnly) return;
    event.stopPropagation();
    this.onEditing.emit(id);
  }

  handleOnSubmit() {
    if (!this.hasActiveCell) this.onSubmit.emit();
    else this.hasActiveCell = false;
  }

  handleOnFormClose() {
    if (!this.hasActiveCell) this.onFormClose.emit();
    else this.hasActiveCell = false;
  }

  handleOnEnter() {
    this.hasActiveCell = false;
    this.onSubmit.emit();
  }

  handleOnEscape() {
    this.hasActiveCell = false;
    this.onFormClose.emit();
  }

  formatNumberCell(value: string) {
    return stringToFormattedNumberString(value) || '-';
  }

  handleOnAddRecord(event: Event) {
    event.stopPropagation();
    this.onAdding.emit();
  }

  userContextMenuProvider = (id: string, connected: boolean = false): IMenuItem[] => [
    {
      id: 'history',
      label: 'History',
      onClick: () => {},
      disabled: true,
    },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => {
        this.onDelete.emit(id);
      },
      disabled: this.readOnly || connected,
    },
  ];

  handleStickyHeaders(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.rootBounds?.top) {
      this.stickyHeaders = tabEntry.boundingClientRect.top < tabEntry.rootBounds.top;
    } else this.stickyHeaders = false;
  }
}
