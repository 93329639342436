import { AppConfigurationService } from './../../core/app-configuration.service';
import { Injectable } from '@angular/core';
import {
  IReport,
  IReportTopic,
  Report,
  ReportMainLevel,
  ReportTopic,
  MoveMainSubjectCommandParams,
  MoveTopicCommandParams,
  TopicItemType,
} from 'src/api-client/report-api.generated';
import { ReportApiService } from 'src/app/api-client/report-api/report-api-service';
import { ReportPdfService } from './report-pdf/report-pdf-service';
import { AddTopicData, UpdateMainLevelData } from './report-structure/main-level-item/main-level-item.component';
import { IReportPdf } from './report-pdf/types';
import { openFileInNewTab } from './../../shared/utils/file';
import { DeactivateTopicData } from './report-structure/topic-item/topic-item.component';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { PdfGenerationError } from 'src/app/pdfmake-common/generate-report';
import { Router } from '@angular/router';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';

@Injectable()
export class ReportDetailsStateService {
  isLoadingReport = false;
  isLoadingIndicators = false;
  isCalculatingIndicators = false;
  isCalculatingIndicatorsLabel = 'Updating Indicators';
  isCreatingPdf = false;
  loadedMainTopics: string[] = [];
  loadedTopics: string[] = [];
  reportData?: IReport;
  selectedTopic?: IReportTopic;
  freshReport: boolean = false;

  constructor(
    private router: Router,
    private appConfigurationService: AppConfigurationService,
    private reportApiService: ReportApiService,
    private topicItemApiService: TopicItemApiService,
    private pdfService: ReportPdfService,
    private notificationService: NotificationService
  ) {}

  async loadReportData(reportId: string) {
    this.isCreatingPdf = false;
    this.isLoadingReport = true;

    var response = await this.reportApiService.getByReportId(reportId);
    this.isLoadingReport = false;
    if (!response.success) {
      this.notificationService.showError('Failed to load report', response.message);
      this.router.navigate(['/home']);
      return;
    }
    this.reportData = response.result;
    if (this.reportData.status === 'Planned') this.freshReport = true;
    else this.freshReport = false;
  }

  setTopicDetails(topicId: string) {
    if (!this.reportData) {
      throw Error('Reporta data has to be fetch before you use try set a topic details.');
    }
    this.selectedTopic = (this.reportData.mainLevels || [])
      .reduce<IReportTopic[]>((prev, curr) => [...prev, ...(curr.topics || [])], [])
      .find(t => t.id === topicId);
  }

  clearTopicDetails() {
    this.selectedTopic = undefined;
  }

  clearReportData() {
    this.reportData = undefined;
  }

  addMainLevel(name: string) {
    if (!this.reportData) {
      throw Error('Reporta data has to be fetch before you use try set a topic details.');
    }

    if (this.reportData?.id && name) {
      this.reportApiService.addMainLevel(this.reportData?.id, name).subscribe(result => {
        if (!this.reportData) {
          throw Error('Reporta data has to be fetch before you use try set a topic details.');
        }

        this.reportData = new Report({
          ...this.reportData,
          mainLevels: [...(this.reportData?.mainLevels || []), result],
        });
      });
    }
  }

  updateMainLevel(data: UpdateMainLevelData) {
    this.loadedMainTopics.push(data.id);

    this.reportApiService.updateMainLevel(this.reportData?.id || '', data.id, data.name).subscribe(
      result => {
        if (!this.reportData) {
          throw Error('Reporta data has to be fetch before you use try set a topic details.');
        }

        this.reportData = {
          ...this.reportData,
          mainLevels:
            this.reportData?.mainLevels?.map(ml =>
              ml.id !== data.id
                ? ml
                : new ReportMainLevel({
                    ...ml,
                    name: data.name,
                  })
            ) || [],
        };
      },
      error => {},
      () => {
        this.loadedMainTopics = this.loadedMainTopics.filter(id => id !== data.id);
      }
    );
  }

  deleteMainLevel(mainLevelId: string) {
    this.loadedMainTopics.push(mainLevelId);
    this.reportApiService.deleteMainLevel(this.reportData?.id || '', mainLevelId).subscribe(
      result => {
        if (!this.reportData) {
          throw Error('Reporta data has to be fetch before you use try set a topic details.');
        }

        if (result.success) {
          this.reportData = new Report({
            ...this.reportData,
            mainLevels: this.reportData?.mainLevels?.filter(m => m.id !== mainLevelId),
          });
        }
      },
      error => {},
      () => {
        this.loadedMainTopics = this.loadedMainTopics.filter(id => id !== mainLevelId);
      }
    );
  }

  addTopic(data: AddTopicData) {
    if (data.name) {
      this.reportApiService.addTopic(this.reportData?.id || '', data.mainLevelId, data.name).subscribe(result => {
        if (!this.reportData) {
          throw Error('Reporta data has to be fetch before you use try set a topic details.');
        }

        this.reportData = {
          ...this.reportData,
          mainLevels:
            this.reportData?.mainLevels?.map(ml =>
              ml.id !== data.mainLevelId
                ? ml
                : new ReportMainLevel({
                    ...ml,
                    topics: [...(ml.topics || []), result],
                  })
            ) || [],
        };
      });
    }
  }

  editTopicName(mainLevelId: string, topicId: string, name: string) {
    this.loadedTopics.push(topicId);
    if (this.reportData?.id) {
      this.reportApiService.editTopicName(this.reportData?.id, mainLevelId, topicId, name).subscribe(
        result => {
          if (!this.reportData) {
            throw Error('Reporta data has to be fetch before you use try set a topic details.');
          }

          this.reportData = {
            ...this.reportData,
            mainLevels:
              this.reportData?.mainLevels?.map(ml =>
                ml.id !== mainLevelId
                  ? ml
                  : new ReportMainLevel({
                      ...ml,
                      topics: ml.topics?.map(t =>
                        t.id !== topicId
                          ? t
                          : new ReportTopic({
                              ...t,
                              name: name,
                            })
                      ),
                    })
              ) || [],
          };
          if (this.selectedTopic?.id === topicId) this.selectedTopic = { ...this.selectedTopic, name: name };
        },
        error => {},
        () => {
          this.loadedTopics = this.loadedTopics.filter(id => id !== topicId);
        }
      );
    }
  }

  deleteTopic(mainLevelId: string, topicId: string) {
    if (!this.reportData?.id) {
      throw Error('Report id not set.');
    }

    this.loadedTopics.push(topicId);
    this.reportApiService.deleteTopic(this.reportData?.id || '', mainLevelId, topicId).subscribe(
      result => {
        if (!this.reportData) {
          throw Error('Reporta data has to be fetch before you use try set a topic details.');
        }

        if (result.success) {
          this.reportData = {
            ...this.reportData,
            mainLevels:
              this.reportData?.mainLevels?.map(ml =>
                ml.id !== mainLevelId
                  ? ml
                  : new ReportMainLevel({
                      ...ml,
                      topics: ml.topics?.filter(t => t.id !== topicId),
                    })
              ) || [],
          };
        }
      },
      error => {},
      () => {
        this.loadedTopics = this.loadedTopics.filter(id => id !== topicId);
      }
    );
  }

  deactivateTopic(data: DeactivateTopicData) {
    if (!this.reportData?.id) {
      throw Error('Report id not set.');
    }

    this.reportApiService
      .deactivateTopic(this.reportData.id, data.mainLevelId, data.topicId, data.deactivated)
      .subscribe(result => {
        if (!this.reportData) {
          throw Error('Reporta data has to be fetch before you use try set a topic details.');
        }

        this.reportData = {
          ...this.reportData,
          mainLevels:
            this.reportData?.mainLevels?.map(ml =>
              ml.id !== data.mainLevelId
                ? ml
                : new ReportMainLevel({
                    ...ml,
                    topics: ml.topics?.map(t =>
                      t.id !== data.topicId
                        ? t
                        : new ReportTopic({
                            ...t,
                            deactivated: data.deactivated,
                          })
                    ),
                  })
            ) || [],
        };
      });
  }

  moveMainLevel(mainLevelId: string, prevIndex: number, newIndex: number) {
    if (!this.reportData?.id) {
      throw Error('Report id not set.');
    }

    this.reportApiService
      .moveMainLevel(
        new MoveMainSubjectCommandParams({
          reportId: this.reportData?.id,
          mainLevelId: mainLevelId,
          prevIndex: prevIndex,
          newIndex: newIndex,
        })
      )
      .subscribe(result => {
        if (!result.success) {
          console.error(result);
        }
      });
  }

  moveTopic(topicId: string, mainLevelId: string, prevIndex: number, newIndex: number) {
    this.isLoadingReport = true;

    if (!this.reportData?.id) {
      throw Error('Report id not set.');
    }

    this.reportApiService
      .moveTopic(
        new MoveTopicCommandParams({
          reportId: this.reportData?.id,
          mainLevelId: mainLevelId,
          topicId: topicId,
          prevIndex: prevIndex,
          newIndex: newIndex,
        })
      )
      .subscribe(result => {
        if (!result.success) {
          console.error(result);
        }
        this.isLoadingReport = false;
      });
  }

  async calculateAllIndicators() {
    if (this.reportData?.id) {
      var reportAllTopicItemsResponse = await this.topicItemApiService.allReportTopicItems(this.reportData?.id);
      var allDifferentThenArticleTopicItems = reportAllTopicItemsResponse.filter(
        t => t.type === TopicItemType.NewIndicator
      );
      this.isCalculatingIndicators = true;

      let updatedCount = 0;

      const updateProgressLabel = () => {
        this.isCalculatingIndicatorsLabel = `Updating Indicators: ${updatedCount}/${allDifferentThenArticleTopicItems.length}`;
      };
      for (let i = 0; i < allDifferentThenArticleTopicItems.length; i++) {
        const topicItem = allDifferentThenArticleTopicItems[i];
        try {
          console.log('Calculating indicator for topic item: ', topicItem);
          var response = await this.topicItemApiService.refreshTopicItemNewIndicatorValues(topicItem.id);

          if (!response.success) {
            this.notificationService.showError(`Failed to update indicator for topic item ${topicItem.name}`);
            continue;
          }

          updatedCount++;
          updateProgressLabel();
        } catch (error) {
          this.notificationService.showError(`Failed to update indicator for topic item ${topicItem.name}`);
          continue;
        }
      }

      updateProgressLabel();

      try {
        if (updatedCount === allDifferentThenArticleTopicItems.length) {
          this.notificationService.showSuccess('All indicators updated successfully');
        } else {
          this.notificationService.showWarning(
            `${updatedCount}/${allDifferentThenArticleTopicItems.length} of the indicators updated successfully'`
          );
        }
      } catch (error) {
        this.notificationService.showError('Failed to update some indicators');
      } finally {
        this.isCalculatingIndicators = false;
        this.isCalculatingIndicatorsLabel = 'Updating Indicators';
      }
    }
  }

  async createPdf(isExecutiveSummaryReport: boolean = false) {
    if (this.reportData?.id) {
      this.isCreatingPdf = true;
      const reportContent = await this.reportApiService.getCompleteReportById(this.reportData?.id);

      try {
        const pdfUrl = await this.pdfService.generatePdf(
          {
            ...reportContent,
            organizationDetails: {
              organizationName: reportContent.organizationName,
              logo: reportContent.organizationLogo?.blobName,
              brandColor: reportContent.brandColor,
              tableHeadColor: reportContent.tableHeadColor,
            },
          } as IReportPdf,
          this.appConfigurationService.AppBaseUrl,
          {
            writingSystem: reportContent.pdfWritingSystem,
            isExecutiveSummary: isExecutiveSummaryReport,
            showMainContent: !isExecutiveSummaryReport,
          }
        );
        openFileInNewTab(pdfUrl);
        this.isCreatingPdf = false;
      } catch (e) {
        if (e instanceof PdfGenerationError) {
          this.notificationService.showError('Failed to Generate PDF', e.message);
          console.error(e);
        } else {
          throw e;
        }
        this.isCreatingPdf = false;
      }
    }
  }
}
