import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-header-with-guidance',
  templateUrl: './header-with-guidance.component.html',
  styleUrls: ['./header-with-guidance.component.scss'],
})
export class HeaderWithGuidanceComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) guidance!: string;
  @Input() collapsedByDefault = true;
  @Input() hideGuidance = false;
  collapsed = true;

  constructor() {}

  ngOnInit(): void {
    this.collapsed = this.collapsedByDefault;
  }

  handleCollapse() {
    this.collapsed = !this.collapsed;
  }
}
