import { Injectable } from '@angular/core';
import {
  DataRecordType,
  ConsumptionValueType,
  DistanceType,
  CreateOrganizationBusinessAirTravelDataRecordCommandParams,
  DataRecordCategory,
  ICreateOrganizationBusinessAirTravelDataRecordCommandParams,
  AssetType,
  InputType,
} from 'src/api-client/report-api.generated';
import { ConsumptionValueTypeOptions, DistanceTypeOptions, IOption } from 'src/app/static-data/options';
import {
  ExcelImportValidationMessage,
  FieldConfig,
  ImportDataOutput,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import { RecordsStateService } from '../records-state.service';
import { DataRecordOutput } from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class AirTravelStateService extends RecordsStateService {
  title = 'Air Travel';
  recordCategory = DataRecordCategory.IndirectEmissionsBusinessTravel;

  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'consumptionValueType', title: 'Method', type: 'string', optional: true },
    { propertyName: 'distanceType', title: 'Haul Type', type: 'string', optional: true },
    { propertyName: 'numberOfFlights', title: 'Flights', type: 'number', optional: true },
    { propertyName: 'consumptionValue', title: 'Consumption', type: 'number', optional: true },
    { propertyName: 'cO2Measured_kg', title: 'Measured', type: 'number', optional: true },
  ];

  assetCategories = [AssetType.Organization, AssetType.Facility];
  distanceTypeOptions: IOption[] = DistanceTypeOptions;
  consumptionValueTypeOptions: IOption[] = ConsumptionValueTypeOptions;

  importFromExcelEnabled = true;

  importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) validationMessages.push(assetValidation);

    const notMeasuredRecords = data.records.filter(record => !record.cO2Measured_kg?.value);

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'consumptionValueType',
      this.consumptionValueTypeOptions,
      notMeasuredRecords,
      'Method',
      false
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'distanceType',
      this.distanceTypeOptions,
      notMeasuredRecords,
      'Haul Type',
      false
    );

    validationMessages = this.validateNotMeasuredData(validationMessages, notMeasuredRecords);

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    const addRecordPromises = data.records.map((record, index) => {
      const consumptionValueType =
        this.consumptionValueTypeOptions.find(type => type.label === record.consumptionValueType?.value) ??
        this.consumptionValueTypeOptions[0];

      const distanceType =
        this.distanceTypeOptions.find(region => region.label === record.distanceType?.value) ??
        this.distanceTypeOptions[0];

      const asset = this.getAssetOptionColumn(record.asset?.value);
      const airTravel: ICreateOrganizationBusinessAirTravelDataRecordCommandParams = {
        type: DataRecordType.OrganizationBusinessAirTravel,
        dataSetId: dataSetId,
        assetId: asset.value,
        inputType: InputType.ImportedFromExcel,
        startDate: record.startDate?.value,
        endDate: record.endDate?.value,
        consumptionValueType: ConsumptionValueType[consumptionValueType.value as keyof typeof ConsumptionValueType],
        distanceType: DistanceType[distanceType.value as keyof typeof DistanceType],
        numberOfFlights: record.numberOfFlights?.value ?? 0,
        consumptionValue: record.consumptionValue?.value ?? 0,
        cO2Measured_kg: record.cO2Measured_kg?.value,
      };

      return this.dataRecordService.createDataRecord(
        new CreateOrganizationBusinessAirTravelDataRecordCommandParams({ ...airTravel })
      );
    });
    Promise.all(addRecordPromises).then(async response => {
      response.forEach(res => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });
      await this.loadRecords();
      this.importingInProgress = false;
      this.importDataFromExcelClose();
    });
  }

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getDataCategoryDetails()]);
  }

  async loadRecords() {
    await this.getDataRecords(DataRecordType.OrganizationBusinessAirTravel);
  }

  handleDeleteAllFilteredRecordsSubmit() {
    this.handleOnDeleteAllFilteredRecords(DataRecordType.OrganizationBusinessAirTravel);
  }

  validateNotMeasuredData(
    validationMessages: ExcelImportValidationMessage[],
    records: DataRecordOutput[]
  ): ExcelImportValidationMessage[] {
    const notMappedColumns = records
      .filter(
        record =>
          !record.consumptionValueType?.value ||
          !record.distanceType?.value ||
          !record.numberOfFlights?.value ||
          !record.consumptionValue?.value
      )
      .map(record => record.asset.rowNo);

    if (notMappedColumns.length) {
      return [
        ...validationMessages,
        {
          title: 'Not valid imported data',
          description: `Missing data for rows: ${notMappedColumns.join(
            ','
          )}\n\nThe columns Method, Haul Type, Flights, and Consumption are required for rows with empty values in the Measured column.`,
        },
      ];
    }
    return validationMessages;
  }
}
