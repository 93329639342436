import { Inject, Injectable } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, InteractionType, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { AppConfigurationService } from './app-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class AppAuthService {
  public interactionEnded = false;
  public userInitialized = false;
  public readonly accountInfo = new BehaviorSubject<AccountInfo | undefined>(undefined);
  accountInfo$ = this.accountInfo.asObservable();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appConfiguration: AppConfigurationService
  ) {}

  setUserAccount() {
    var user =
      this.authService.instance.getAllAccounts().length > 0 ? this.authService.instance.getAllAccounts()[0] : undefined;
    if (user) {
      this.accountInfo.next(user);
      this.userInitialized = true;
    } else {
      this.accountInfo.next(undefined);
      this.userInitialized = false;
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  redirectToUserFlow(userFlowRequest?: RedirectRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      console.log(userFlowRequest, this.msalGuardConfig);
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  changePassword() {
    let resetPasswordFlowRequest = {
      scopes: ['openid'],
      authority: this.appConfiguration.B2CResetPasswordAuthority,
    };

    this.redirectToUserFlow(resetPasswordFlowRequest);
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  }
}
