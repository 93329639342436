import { Component, Input, OnInit } from '@angular/core';
import { VehicleCraftMachineryDetailsStateService } from './vehicle-craft-machinery-details-state.service';
import { IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-vehicle-craft-machinery-details',
  templateUrl: './vehicle-craft-machinery-details.component.html',
  styleUrls: ['../../asset-details.component.scss', './vehicle-craft-machinery-details.component.scss'],
  providers: [VehicleCraftMachineryDetailsStateService],
})
export class VehicleCraftMachineryDetailsComponent implements OnInit {
  @Input({ required: true }) assetId!: string;
  @Input() organizationOptions: IOption[] = [];

  constructor(public state: VehicleCraftMachineryDetailsStateService) {}
  ngOnInit(): void {
    this.state.init(this.assetId, this.organizationOptions);
  }
}
