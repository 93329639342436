import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReportDetailsStateService } from './report-details-state.service';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import { TopicDetailsStateService } from './topic-details/topic-details-state.service';
import { FormatUnitWithUnicodePipe } from 'src/app/shared/pipes/format-unit-with-unicode.pipe';

@Component({
  selector: 'esg-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss'],
  providers: [TopicDetailsStateService, ReportDetailsStateService, UnitService, FormatUnitWithUnicodePipe],
})
export class ReportDetailsComponent implements OnInit, OnDestroy {
  sub?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private indicatorsState: TopicDetailsStateService,
    private router: Router,
    public state: ReportDetailsStateService
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      var reportId = params['id'];

      if (reportId !== this.state.reportData?.id) {
        this.state.clearTopicDetails();
        this.state.clearReportData();
        await this.state.loadReportData(reportId);
      }
    });
  }

  handleAddMainLevel(value: string) {
    this.state.addMainLevel(value);
  }

  handleSelectTopic(topicId: string) {
    this.router.navigate(['/report', this.state.reportData?.id, topicId]);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
