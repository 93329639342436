import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

export interface IChangedValueUi {
  name: string;
  value?: any | undefined;
}

export interface IChangeHistoryItemUi {
  date: Date;
  userId: string;
  userName: string;
  changes: IChangedValueUi[];
}

@Component({
  selector: 'esg-asset-history-dialog',
  templateUrl: './asset-history-dialog.component.html',
  styleUrls: ['./asset-history-dialog.component.scss'],
})
export class AssetHistoryDialogComponent {
  @Input() title = 'Asset history';
  @Input() historyItems: IChangeHistoryItemUi[] = [];
  @Output() onClose = new EventEmitter();

  openDetailsIndex?: number;

  constructor(public hostRef: ElementRef) {}

  handleOpenDetails(index: number) {
    this.openDetailsIndex = index;
  }
}
