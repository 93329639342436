<div class="datepicker-header">
  <button class="left arrow-button" [disabled]="isPreviousYearDisabled" (click)="previousClicked('year')">
    <esg-double-chevron></esg-double-chevron>
  </button>
  <div class="center-container">
    <button class="left arrow-button" [disabled]="isPreviousMonthDisabled" (click)="previousClicked('month')">
      <esg-chevron></esg-chevron>
    </button>
    <span class="header-label">{{ periodLabel }}</span>
    <button class="arrow-button" [disabled]="isNextMonthDisabled" (click)="nextClicked('month')">
      <esg-chevron></esg-chevron>
    </button>
  </div>
  <button class="arrow-button" [disabled]="isNextYearDisabled" (click)="nextClicked('year')">
    <esg-double-chevron></esg-double-chevron>
  </button>
</div>
