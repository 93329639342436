import { Component, OnInit, Input } from '@angular/core';
import { IOrganizationDetails } from '../organizations-types';

@Component({
  selector: 'esg-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss'],
})
export class OrganizationDetailsComponent implements OnInit {
  @Input({ required: true }) organization!: IOrganizationDetails;

  constructor() {}

  ngOnInit(): void {}
}
