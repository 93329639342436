import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AssetType, AssetTypeVm } from 'src/api-client/report-api.generated';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { ObjectArray, toHashTableBy } from 'src/app/shared/utils/object-array';

@Injectable()
export class AssetsMenuStateService {
  public readonly newAssetSubject$ = new Subject<AssetType>();
  assetTypeCounters: ObjectArray<AssetTypeVm> = {};
  constructor(private assetsApiService: AssetApiService) {}

  loadAssetTypeCounters() {
    this.assetsApiService.getAssetTypesByOrganization(undefined).then(response => {
      this.assetTypeCounters = toHashTableBy(response.result, 'assetType');
    });
  }

  notifyOfNewAsset(type: AssetType) {
    this.newAssetSubject$.next(type);
  }
}
