<div class="content">
  <ng-container *ngIf="!state.loading">
    <h2 class="title">{{ state.fc?.name?.value }}</h2>
    <div class="asset-details">
      <esg-asset-version-form
        *ngIf="state.fc"
        [active]="state.isFormOpen"
        [saveDisabled]="!state.newVersionDate && !state.formgroup?.dirty"
        [deleteDisabled]="state.assetVersions.length < 2 || state.newVersionDate ? true : false"
        (onClick)="state.handleOnFormClick()"
        (onSave)="state.handleVersionSubmit()"
        (onDelete)="state.handleOnDeleteClick()"
        (onCancel)="state.handleCancel()">
        <div class="static-data" [class.active]="state.isFormOpen">
          <span class="input-label">Version valid from</span>
          <div class="input">
            <esg-datepicker
              [fc]="state.fc.versionValidFrom"
              variant="ghost"
              (onOpen)="state.handleValidFromOpen()"
              (onClose)="state.handleValidFromClose()"
              (onChange)="$event && state.handleValidFromChange($event)"></esg-datepicker>
          </div>
          <span class="input-label">Registration number</span>
          <div class="input">
            <esg-textbox-form
              class="regNumber-input"
              [fc]="state.fc.registrationNumber"
              type="text"
              variant="ghost"
              placeholder="Number"
              (onChange)="state.fc.registrationNumber.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">Organization</span>
          <div class="input">
            <esg-select
              [options]="organizationOptions"
              variant="ghost"
              [disabled]="organizationOptions.length === 1"
              size="small"
              [value]="state.fc.organization.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.organization, $event)"></esg-select>
          </div>
          <span class="input-label">Model year</span>
          <div class="input">
            <esg-textbox-form
              class="year-input"
              [fc]="state.fc.modelYear"
              type="digit"
              [maxLength]="4"
              variant="ghost"
              placeholder="Year"
              (onChange)="state.fc.modelYear.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">Power Source</span>
          <div class="input">
            <esg-select
              [options]="state.powerSourceOptions"
              variant="ghost"
              [disabled]="state.powerSourceOptions.length === 1"
              size="small"
              [value]="state.fc.powerSource.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.powerSource, $event)"></esg-select>
          </div>
        </div>
      </esg-asset-version-form>
      <esg-asset-version-menu
        *ngIf="state.fc && state.activeVersion"
        [assetVersions]="state.assetVersions"
        [activeVersion]="state.activeVersion"
        [newVersion]="state.newVersionDate"
        (onVersionClick)="state.handleVersionClick($event)"
        (onNewVersionClick)="state.handleNewVersion()"></esg-asset-version-menu>
    </div>
    <div class="records">
      <esg-tabs class="records-tab" [activeTab]="state.activeTab" [tabs]="state.tabs" [tabIndent]="100"></esg-tabs>
      <esg-records-overview
        class="records-overview"
        [assetId]="assetId"
        [data]="state.recordsOverview"></esg-records-overview>
    </div>
  </ng-container>
  <esg-dot-flashing *ngIf="state.loading" class="loader" label="We are loading asset details"></esg-dot-flashing>
</div>
<esg-confirm-dialog
  *ngIf="state.isDeleteDialogOpen"
  [message]="'Once you delete this version, you delete all its\n content, and there is no turning back.'"
  cancelText="No, keep it"
  (onCancel)="state.handleDeleteDialogClose()"
  (onSubmit)="state.handleVersionDelete()"></esg-confirm-dialog>
