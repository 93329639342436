import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IGroupedOptions } from 'src/app/static-data/options';

export interface IOption {
  value: string;
  label: string;
}

@Component({
  selector: 'esg-dropdown-multiple-form',
  templateUrl: './dropdown-multiple-form.component.html',
  styleUrls: ['./dropdown-multiple-form.component.scss'],
})
export class DropdownMultipleFormComponent {
  @Input() placeholder: string = 'Select';
  @Input() variant: 'standard' | 'ghost' = 'standard';
  @Input() size: 'small' | 'medium' = 'medium';
  @Input({ required: true }) fc!: FormControl<IOption[]>;
  @Input() options: IOption[] = [];
  @Input() groupedOptions: IGroupedOptions[] = [];
  @Input() isFilter: boolean = false;
  @Input() isPopup: boolean = false;
  @Output() onChange = new EventEmitter();
  private formSubscription?: Subscription;
  open = false;
  dropUp = false;
  popUpStyle: { [key: string]: string } = {};
  maxHeight: string = 'unset';

  @ViewChild('input') private input: ElementRef | undefined;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMaxHeigth();
    if (this.isPopup && this.open) this.handlePopupPosition();
  }

  ngOnInit(): void {
    if (this.isPopup) this.addScrollEventListener();
    this.formSubscription = this.fc.valueChanges.subscribe(() => {
      this.setDropUp();
      this.setMaxHeigth();
      if (this.isPopup) {
        this.handlePopupPosition();
      }
    });
  }

  toggleOpenOptions() {
    if (this.fc.disabled) return;
    this.open = !this.open;
    if (this.open) {
      this.setDropUp();
      this.setMaxHeigth();
      if (this.isPopup) {
        this.handlePopupPosition();
      }
    }
  }

  handleSelectOption(option: IOption) {
    if (this.fc.value.some(o => o.value == option.value)) {
      this.fc.setValue(this.fc.value.filter(o => o.value !== option.value));
    } else {
      this.fc.setValue([...this.fc.value, option]);
    }
    this.fc.markAsDirty();
    this.onChange.emit();
  }

  optionSelected(option: IOption) {
    return this.fc.value.some(o => o.value == option.value);
  }

  deleteOption(event: Event, option: IOption) {
    event.stopPropagation();
    this.fc.setValue(this.fc.value.filter(o => o.value !== option.value));
    this.fc.markAsDirty();
    this.onChange.emit();
  }

  deleteAll() {
    this.fc.setValue([]);
    this.fc.markAsDirty();
    this.onChange.emit();
  }

  closeOptions() {
    this.open = false;
  }

  setDropUp() {
    if (this.input && window.innerHeight - this.input.nativeElement.getBoundingClientRect().bottom < 200)
      this.dropUp = true;
    else this.dropUp = false;
  }

  setMaxHeigth() {
    if (this.input?.nativeElement) {
      const inputRect = this.input.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const remainingHeight = this.dropUp ? inputRect.top - 160 : windowHeight - inputRect.bottom - 100;
      this.maxHeight = `${remainingHeight}px`;
    } else {
      this.maxHeight = 'unset';
    }
  }

  handlePopupPosition() {
    if (!this.input) return;
    const inputRect = this.input.nativeElement.getBoundingClientRect();
    const top = this.dropUp ? inputRect.top : inputRect.bottom;
    const width = `${inputRect.width}px`;
    this.popUpStyle = { top: `${top}px`, left: `${inputRect.left}px`, minWidth: width };
  }

  addScrollEventListener(): void {
    document.addEventListener(
      'scroll',
      () => {
        if (this.open) this.handlePopupPosition();
      },
      true
    );
  }

  ngOnDestroy(): void {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }
}
