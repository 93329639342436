import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-play-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 84 84"
      fill="none">
      <g clip-path="url(#clip0_3606_15)">
        <path
          d="M11.7143 84C9.96825 84 8.57143 83.3385 7.52381 82.0154C6.50794 80.7253 6 79.0053 6 76.8553V7.0951C6 4.94507 6.50794 3.22504 7.52381 1.93503C8.57143 0.645009 9.96825 0 11.7143 0C12.6667 0 13.5714 0.181926 14.4286 0.545777C15.2857 0.87655 16.1905 1.3231 17.1429 1.88541L72.6667 35.3763C74.6667 36.567 76.0476 37.6421 76.8095 38.6013C77.6032 39.5275 78 40.6521 78 41.9752C78 43.2983 77.6032 44.4395 76.8095 45.3987C76.0476 46.3249 74.6667 47.3999 72.6667 48.6237L17.1429 82.065C16.1905 82.6604 15.2857 83.1235 14.4286 83.4542C13.5714 83.8181 12.6667 84 11.7143 84Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3606_15">
          <rect width="84" height="84" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  },
})
export class PlayIconComponent {
  @Input() size: number = 84;
}
