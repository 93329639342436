import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITreeOption } from './select-tree-options/select-tree-options.component';

@Component({
  selector: 'esg-select-tree',
  templateUrl: './select-tree.component.html',
  styleUrl: './select-tree.component.scss',
})
export class SelectTreeComponent {
  open = false;
  @Input() label = '';
  @Input() required: boolean = false;
  @Input() variant: 'standard' | 'ghost' = 'standard';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() placeholder = 'Please select';
  @Input() disabled = false;
  @Input({ required: true }) value?: ITreeOption;
  @Input({ required: true }) options: ITreeOption[] = [];
  @Output() onChange = new EventEmitter<ITreeOption>();

  handleOpen() {
    this.open = !this.open;
  }

  handleSelectOption(option: ITreeOption) {
    console.log(option);
    this.onChange.emit(option);
    this.open = false;
  }

  handleClickOutside() {
    this.open = false;
  }
}
