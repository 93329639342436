import { Component } from '@angular/core';
import { AccessTokenStateService } from './access-token-state.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

@Component({
  selector: 'esg-access-token-page',
  templateUrl: './access-token-page.component.html',
  styleUrl: './access-token-page.component.scss',
})
export class AccessTokenPageComponent {
  constructor(public state: AccessTokenStateService, private notification: NotificationService) {
    state.initAccessTokenPage();
  }

  copyText(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    // Optional: Display some notification that the text was copied
    this.notification.showSuccess('Text copied!');
  }
}
