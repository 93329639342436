import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import {
  AssetType,
  CountryCodes,
  CreateElectricityConsumptionDataRecordCommandParams,
  DataRecordType,
  ElectricityConsumptionDataRecord,
  InputType,
  UpdateElectricityConsumptionDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface ElectricityRecordForm extends RecordForm {
  country: FormControl<IOption>;
  electricity_kwh: FormControl<string>;
}

@Component({
  selector: 'esg-electricity-form-table',
  templateUrl: './electricity-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: ElectricityFormTableComponent }],
})
export class ElectricityFormTableComponent extends RecordFormTableComponent {
  headerCells: RecordHeaderCell[] = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'country', label: 'Country', description: 'Origin of consumption', number: false },
    { columnId: 'electricity_kwh', label: 'Consumption', description: 'kwh', number: true },
  ];

  recordFc?: ElectricityRecordForm = undefined;
  recordFormgroup?: FormGroup<ElectricityRecordForm> = undefined;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as ElectricityConsumptionDataRecord[]).map(record => {
      const countryCode = record.countryCode ? record.countryCode : this.assetCountryMap.get(record.assetId);
      return {
        id: record.id,
        cells: [
          {
            columnId: 'period',
            value:
              formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
              ' - ' +
              formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
            number: false,
          },
          {
            columnId: 'asset',
            value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
            number: false,
          },
          {
            columnId: 'country',
            value: this.countryOptions.find(country => country.value === countryCode)?.label || '',
            number: false,
          },
          {
            columnId: 'electricity_kwh',
            value: record.electricity_kwh?.toString() || '',
            number: true,
          },
        ],
      };
    });
  }

  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      country: new FormControl<IOption>(
        {
          value: this.getCountryOptionByAssetId(asset?.value),
          disabled: asset?.group === AssetType.Facility || asset?.group === AssetType.Organization,
        },
        {
          nonNullable: true,
        }
      ),
      electricity_kwh: new FormControl<string>('', {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup<ElectricityRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const record = this.dataRecords.find(record => record.id === id) as ElectricityConsumptionDataRecord;
    if (record) {
      const asset =
        this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
        this.getDefaultAssetOption();
      const country = record.countryCode
        ? this.countryOptions.find(type => type.value === record.countryCode)
        : this.getCountryOptionByAssetId(record.assetId);
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(asset, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        country: new FormControl<IOption>(
          {
            value: country || this.countryOptions[0],
            disabled: asset?.group === AssetType.Facility || asset?.group === AssetType.Organization,
          },
          {
            nonNullable: true,
          }
        ),
        electricity_kwh: new FormControl<string>(record.electricity_kwh?.toString() || '', {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup<ElectricityRecordForm>(this.recordFc);
    }
  }

  handleOnAssetChange(
    assetFormControl: FormControl<IOption | undefined>,
    countryFormControl: FormControl<IOption>,
    assetOption: IOption
  ) {
    const countryOption = this.countryOptions.find(opt => opt.value === this.assetCountryMap.get(assetOption.value));
    this.handleDropdownChange(assetFormControl, assetOption);
    if (countryOption) this.handleDropdownChange(countryFormControl, countryOption);
    if (assetOption.group === AssetType.Facility || assetOption.group === AssetType.Organization) {
      countryFormControl.disable();
    } else {
      countryFormControl.enable();
    }
  }

  getParams():
    | CreateElectricityConsumptionDataRecordCommandParams
    | UpdateElectricityConsumptionDataRecordCommandParams
    | undefined {
    const countryCode = Object.values(CountryCodes).find(code => code === this.recordFc?.country.value.value);
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value && countryCode) {
      const electricity = {
        type: DataRecordType.ElectricityConsumption,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        countryCode: countryCode,
        electricity_kwh: formattedStringToNumber(this.recordFc.electricity_kwh.value || '') || 0,
      };
      if (this.editRecordId) {
        return new UpdateElectricityConsumptionDataRecordCommandParams({ id: this.editRecordId, ...electricity });
      } else {
        return new CreateElectricityConsumptionDataRecordCommandParams(electricity);
      }
    }
    return undefined;
  }
}
