import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IOption } from 'src/app/static-data/options';
import { GenericColumnType, GenericColumnUi, GenericRowUi, HoverActionStateType } from './generic-table.types';

@Component({
  selector: 'esg-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrl: './generic-table.component.scss',
})
export class GenericTableComponent {
  @Input() columns: GenericColumnUi[] = [];
  @Input() rows: GenericRowUi[] = [];
  @Input() isConfigurable: boolean = true;
  @Input() hideColumns: boolean = false;
  @Input() currencyOptions: IOption[] = [];
  @Output() onRowChange = new EventEmitter<GenericRowUi>();
  @Output() onAddRowAfter = new EventEmitter<string | undefined>();
  @Output() onDeleteRow = new EventEmitter<string>();
  @Output() onAddColumnAfter = new EventEmitter<string | undefined>();
  @Output() onDeleteColumn = new EventEmitter<string>();
  @Output() onColumnsChange = new EventEmitter<GenericColumnUi[]>();

  @ViewChild('table') table!: ElementRef<HTMLTableElement>;

  columnTypeMap: Map<string, GenericColumnType> = new Map();
  optionsMap: Map<string, IOption[]> = new Map();

  selectedColumns: string[] = [];
  selectedRows: string[] = [];

  hoveredRowId?: string;
  hoveredColumnId?: string;

  isSelecting: boolean = false;
  resizingForColumn?: string;

  readonly inputTypeEnum = GenericColumnType;
  readonly hoverActionStateTypeEnum = HoverActionStateType;
  hoverActionState: HoverActionStateType = HoverActionStateType.None;

  get isAddRowHovered(): boolean {
    return this.hoverActionState === HoverActionStateType.AddRow;
  }

  get isAddColumnHovered(): boolean {
    return this.hoverActionState === HoverActionStateType.AddColumn;
  }

  get isDeleteRowHovered(): boolean {
    return this.hoverActionState === HoverActionStateType.DeleteRow;
  }

  get isDeleteColumnHovered(): boolean {
    return this.hoverActionState === HoverActionStateType.DeleteColumn;
  }

  get isFirstHeaderHovered(): boolean {
    return this.hoveredColumnId === this.columns[0].columnId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.updateColumnTypeMap();
      this.updateOptionsMap();
    }
  }

  updateColumnTypeMap(): void {
    this.columnTypeMap.clear();
    this.columns.forEach(column => {
      this.columnTypeMap.set(column.columnId, column.type);
    });
  }

  updateOptionsMap(): void {
    this.optionsMap.clear();
    this.columns.forEach(column => {
      if (column.options) {
        this.optionsMap.set(column.columnId, column.options);
      }
    });
  }

  getColumnType(columnId: string): GenericColumnType | undefined {
    return this.columnTypeMap.get(columnId);
  }

  getColumnOptions(columnId: string): IOption[] | undefined {
    return this.optionsMap.get(columnId);
  }

  getOptionValue(columnId: string, cellValue: string | number): IOption | undefined {
    const options = this.optionsMap.get(columnId);
    return options?.find(option => option.value === cellValue.toString());
  }

  getCurrencyOption(cellValue: string | number): IOption | undefined {
    return this.currencyOptions.find(option => option.value === cellValue.toString());
  }

  handleOnColumnsChange() {
    this.onColumnsChange.emit(this.columns);
  }

  handleOnDropdownChange(rowId: string, columnId: string, option: IOption) {
    const row = this.rows.find(r => r.rowId === rowId);
    if (row) {
      const valueIndex = row.values.findIndex(v => v.columnId === columnId);
      if (valueIndex !== -1) {
        row.values[valueIndex].value = option.value;
      }
      this.onRowChange.emit(row);
    }
  }

  handleOnTextChange(rowId: string, columnId: string, value: string) {
    const row = this.rows.find(r => r.rowId === rowId);
    if (row) {
      const valueIndex = row.values.findIndex(v => v.columnId === columnId);
      if (valueIndex !== -1 && value !== row.values[valueIndex].value) {
        row.values[valueIndex].value = value;
      }
      this.onRowChange.emit(row);
    }
  }

  handleOnNumberChange(rowId: string, columnId: string, value: string) {
    const row = this.rows.find(r => r.rowId === rowId);
    if (row) {
      const valueIndex = row.values.findIndex(v => v.columnId === columnId);
      if (valueIndex !== -1 && value !== row.values[valueIndex].value) {
        row.values[valueIndex].value = value;
      }
      this.onRowChange.emit(row);
    }
  }

  handleOnCurrencyChange(rowId: string, columnId: string, option: IOption) {
    const row = this.rows.find(r => r.rowId === rowId);
    if (row) {
      const valueIndex = row.values.findIndex(v => v.columnId === columnId);
      if (valueIndex !== -1) {
        row.values[valueIndex].unit = option.value;
      }
      this.onRowChange.emit(row);
    }
  }

  handleOnHeaderChange(columnId: string, event: Event) {
    const column = this.columns.find(c => c.columnId === columnId);
    if (column) {
      column.header = (event.target as HTMLInputElement).value;
      this.handleOnColumnsChange();
    }
  }

  handleOnDescriptionChange(columnId: string, event: Event) {
    const column = this.columns.find(c => c.columnId === columnId);
    if (column) {
      column.description = (event.target as HTMLInputElement).value;
      this.handleOnColumnsChange();
    }
  }

  handleOnRowAddAfter(rowId?: string) {
    this.onAddRowAfter.emit(rowId);
  }

  handleOnRowDelete(rowId: string) {
    this.onDeleteRow.emit(rowId);
  }

  handleOnColumnAddAfter(columnId?: string) {
    this.onAddColumnAfter.emit(columnId);
  }

  handleOnColumnDelete(columnId: string) {
    this.onDeleteColumn.emit(columnId);
  }

  onRowMouseEnter(rowId: string) {
    this.hoveredRowId = rowId;
  }

  onColumnMouseEnter(columnId: string) {
    this.hoveredColumnId = columnId;
  }

  clearHoverState() {
    this.hoveredRowId = undefined;
    this.hoveredColumnId = undefined;
    this.hoverActionState = HoverActionStateType.None;
  }

  clearHoverActionState() {
    this.hoverActionState = HoverActionStateType.None;
  }

  setHoverActionState(state: HoverActionStateType) {
    this.hoverActionState = state;
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest(this.table.nativeElement.tagName)) {
      this.clearCellSelection();
    } else {
      // Disabling selection for now
      this.isSelecting = false;
      this.clearCellSelection();
      this.selectCell(target);
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.isSelecting = false;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isSelecting) {
      let target = event.target as HTMLElement;
      const parentCell = target.closest('td');

      if (parentCell) {
        this.selectCell(parentCell as HTMLElement);
      }
    }
  }

  selectCell(target: HTMLElement) {
    const rowId = target.getAttribute('data-row-id');
    const columnId = target.getAttribute('data-column-id');
    if (rowId && columnId) {
      if (!this.selectedRows.includes(rowId)) {
        this.selectedRows.push(rowId);
      } else {
        this.selectedRows.splice(this.selectedRows.indexOf(rowId) + 1);
      }
      if (!this.selectedColumns.includes(columnId)) {
        this.selectedColumns.push(columnId);
      } else {
        this.selectedColumns.splice(this.selectedColumns.indexOf(columnId) + 1);
      }
    }
  }

  clearCellSelection() {
    this.selectedRows = [];
    this.selectedColumns = [];
  }

  isSelectedCell(rowId: string, columnId: string) {
    return this.selectedRows.includes(rowId) && this.selectedColumns.includes(columnId);
  }

  startResizing(event: MouseEvent, columnId: string): void {
    const target = event.target as HTMLElement;
    const columnHeader = target.parentElement;

    if (!columnHeader) return;

    const startPageX = event.pageX;
    const originalWidth = columnHeader.offsetWidth;
    event.preventDefault();

    const mouseMoveHandler = (moveEvent: MouseEvent) => {
      const tableWidth = this.table.nativeElement.offsetWidth;
      const movementX = moveEvent.pageX - startPageX;
      let newWidth = originalWidth + movementX;
      let newWidthPercent = (newWidth / tableWidth) * 100;
      columnHeader.style.width = `${newWidthPercent}%`;
      this.resizingForColumn = columnId;
    };

    const stopResizing = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', stopResizing);
      const column = this.columns.find(c => c.columnId === columnId);
      if (column) {
        column.width = columnHeader.style.width;
        this.handleOnColumnsChange();
      }
      this.resizingForColumn = undefined;
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', stopResizing);
  }

  handleResizeMouseEnter(columnId: string) {
    this.resizingForColumn = columnId;
  }

  handleResizeMouseLeave() {
    this.resizingForColumn = undefined;
  }

  trackByColumnId(index: number, column: GenericColumnUi): string {
    return column.columnId;
  }

  trackByRowId(index: number, row: GenericRowUi): string {
    return row.rowId;
  }
}
