import { formatNumber } from '@angular/common';

export function formatNumbersInString(value: string, minIntDigits: number = 0, maxDecDigits: number = 3): string {
  const parts = value.split(/\s+/);
  const formattedParts = parts.map(part => {
    let number = part.replace(/[^0-9.]/g, '');
    if (number && !isNaN(Number(number))) {
      return formatNumber(Number(number), 'en_US', minIntDigits + '.0-' + maxDecDigits).replace(/\,/g, ' ');
    } else return part;
  });
  return formattedParts.join(' ');
}

export function stringToFormattedNumberString(
  value: string,
  minIntDigits: number = 0,
  maxDecDigits: number = 3
): string {
  let number = value.replace(/[^0-9.]/g, '');
  if (number && !isNaN(Number(number))) {
    number = formatNumber(Number(number), 'en_US', minIntDigits + '.0-' + maxDecDigits).replace(/\,/g, ' ');
    return number;
  } else return '';
}

export function numberToFormattedNumberString(
  value: number,
  minIntDigits: number = 0,
  maxDecDigits: number = 3
): string {
  if (isNaN(value)) return '';
  let numberString = formatNumber(value, 'en_US', minIntDigits + '.0-' + maxDecDigits).replace(/\,/g, ' ');
  return numberString;
}

export function stringToNumberString(value: string): string {
  let number = value.replace(',', '.').replace(/[^0-9. ]/g, '');
  let dot_index = number.indexOf('.');
  number = dot_index > -1 ? number.substr(0, dot_index + 1) + number.slice(dot_index).replace(/\./g, '') : number;
  return number;
}

export function formattedStringToNumber(value: string): number | undefined {
  let number = value.replace(/\ /g, '');
  if (number && !isNaN(Number(number))) {
    return Number(number);
  } else return undefined;
}
