<div class="container" [class.not-valid]="fc ? fc.errors : !valid">
  <label *ngIf="label" for="input">
    {{ label }}
    <span *ngIf="!required && showOptionalText">(OPTIONAL)</span>
  </label>
  <textarea
    *ngIf="fc"
    #input
    esgKeyboardShortcuts
    (onEscapeKey)="handleEscape()"
    [keyOnlyOnFocus]="true"
    [class]="variant"
    autocomplete="off"
    [formControl]="fc"
    [placeholder]="placeholder"
    [ngStyle]="inputStyle"
    [attr.maxlength]="maxlength"
    (focusout)="handleFocusOut()"></textarea>
</div>
