import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-convert-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M1.10849 6.83425C1.03255 6.76059 0.996474 6.67772 1.00027 6.58564C1.00407 6.49355 1.04394 6.41068 1.11988 6.33702L3.13607 4.38674C3.28036 4.25046 3.42844 4.18232 3.58032 4.18232C3.73599 4.18232 3.88408 4.25046 4.02456 4.38674L6.04645 6.33702C6.12239 6.41068 6.16036 6.49355 6.16036 6.58564C6.16416 6.67772 6.12809 6.76059 6.05215 6.83425C5.97621 6.90792 5.89268 6.94107 5.80155 6.9337C5.71042 6.92634 5.62689 6.88766 5.55095 6.81768L4.91306 6.20994L3.92774 5.20442V11.3536C3.92774 11.674 4.01317 11.9153 4.18404 12.0773C4.3549 12.2394 4.59981 12.3204 4.91875 12.3204H9.86242C9.98392 12.3204 10.0769 12.3499 10.1415 12.4088C10.2098 12.4715 10.244 12.5562 10.244 12.663C10.2478 12.7661 10.2155 12.8471 10.1472 12.9061C10.0788 12.9687 9.98392 13 9.86242 13H4.90736C4.353 13 3.93344 12.8637 3.64866 12.5912C3.36769 12.3223 3.2272 11.9208 3.2272 11.3867V5.21547L2.24758 6.20994L1.60969 6.81768C1.53754 6.89134 1.45401 6.93002 1.35909 6.9337C1.26416 6.93739 1.18063 6.90424 1.10849 6.83425ZM5.75599 3.33702C5.75219 3.23389 5.78446 3.15285 5.85281 3.09392C5.92495 3.03131 6.01988 3 6.13758 3H11.0983C11.6527 3 12.0704 3.13628 12.3513 3.40884C12.6323 3.67772 12.7728 4.07919 12.7728 4.61326V10.7845L13.7524 9.78453L14.3903 9.1768C14.4663 9.10681 14.5498 9.06998 14.6409 9.0663C14.7358 9.05893 14.8194 9.09024 14.8915 9.16022C14.9675 9.23757 15.0035 9.32228 14.9997 9.41436C14.9959 9.50276 14.9561 9.58379 14.8801 9.65746L12.8696 11.6077C12.7253 11.744 12.5754 11.8122 12.4197 11.8122C12.264 11.8122 12.1159 11.744 11.9754 11.6077L9.95354 9.65746C9.8814 9.58379 9.84343 9.50276 9.83964 9.41436C9.83584 9.32228 9.87191 9.23757 9.94785 9.16022C10.0238 9.09024 10.1073 9.05893 10.1985 9.0663C10.2934 9.07367 10.3788 9.1105 10.4547 9.1768L11.0869 9.78453L12.0723 10.7901V4.64641C12.0723 4.32597 11.9868 4.08471 11.816 3.92265C11.6489 3.76059 11.404 3.67956 11.0812 3.67956H6.13758C6.01988 3.67956 5.92685 3.65009 5.8585 3.59116C5.79396 3.52855 5.75978 3.44383 5.75599 3.33702Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class ConvertIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
