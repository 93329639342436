import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-import-from-excel-2-icon',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.5 5V5.5H11H15.5V14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2C0.5 1.17157 1.17157 0.5 2 0.5H10.5V5Z"
        attr.stroke="{{ color }}" />
      <path d="M11 5L11 2.12012e-07L15.9971 5.00001L15 5L11 5Z" attr.fill="{{ color }}" />
      <path
        d="M4.00561 12H6.51717L8.42327 8.67914L6.84233 9.58836H7.94674L9.34268 12H11.9888L8.93903 7.11019L9.49404 9.05059V7.95842L12 4H9.3651L7.61598 7.48718L9.14646 6.55024H8.04205L6.76945 4H4L6.38823 7.94733V9.0395L6.92642 7.08801L4.00561 12Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ImportFromExcel2IconComponent {
  @Input() color: string = '#183642';
}
