import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { TopicItemContentStateService } from './topic-item-content-state.service';
import { TopicItemUi } from '../topic-content-state.service';
import { IndicatorPeriodLabelsUi } from 'src/app/content/content-item/indicator-content/indicator-content.component';

@Component({
  selector: 'esg-topic-item-content',
  templateUrl: './topic-item-content.component.html',
  styleUrl: './topic-item-content.component.scss',
  providers: [TopicItemContentStateService],
  host: {
    '[class.reordering]': 'reorderMode',
  },
})
export class TopicItemContentComponent {
  @Input({ required: true }) topicItem!: TopicItemUi;
  @Input({ required: true }) reportId!: string;
  @Input() indicatorOptions: IGroupedOptions[] = [];
  @Input() indicatorSingleValueOptions: IGroupedOptions[] = [];
  @Input() responsiblePersonOptions: IOption[] = [];
  @Input() indicatorPeriodLabels: IndicatorPeriodLabelsUi = {
    targetLabel: 'Target',
    currentLabel: 'Current',
    previousLabel: 'Previous',
  };
  @Input() reorderMode: boolean = false;
  @Input() showEmptyText: boolean = false;
  @Input() scrollIntoViewOnInit: boolean = false;
  @Input() isLastItem: boolean = false;
  @Output() onDeleteHeader = new EventEmitter<void>();
  @Output() onResponsiblePersonChange = new EventEmitter<IOption>();
  @Output() onUpdateVisibility = new EventEmitter<boolean>();

  constructor(private hostRef: ElementRef, public state: TopicItemContentStateService) {}

  ngOnInit(): void {
    this.state.setIndicatorPeriodLabels(this.indicatorPeriodLabels);
    this.state.setIndicatorOptions(this.indicatorOptions, this.indicatorSingleValueOptions);
    this.state.getTopicItemComponents(this.reportId, this.topicItem.id);
  }

  ngAfterViewInit() {
    if (this.scrollIntoViewOnInit) {
      this.hostRef.nativeElement.scrollIntoView();
    }
  }

  handleOnDeleteHeader() {
    this.onDeleteHeader.emit();
  }

  handleOnResponsiblePersonChange(option: IOption) {
    this.onResponsiblePersonChange.emit(option);
  }

  handleOnUpdateVisibility(visible: boolean) {
    this.onUpdateVisibility.emit(visible);
  }

  handleOnEditHeader(value: string) {
    this.state.handleTopicItemNameChange(value);
  }
}
