import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetForm, AssetUi } from '../../asset-list/asset-list.component';
import { IOption } from 'src/app/static-data/options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateAssetCommandParams } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-asset-table',
  templateUrl: './asset-table.component.html',
  styleUrls: ['./asset-table.component.scss'],
})
export class AssetTableComponent {
  @Input() assets: AssetUi[] = [];
  @Input() organizationId?: string;
  @Input() selectedAsset?: AssetUi;
  @Input() organizationOptions: IOption[] = [];
  @Input() countryOptions: IOption[] = [];
  @Input() sectorOptions: IOption[] = [];
  @Input() organizationCountryMap = new Map<string, string>();
  @Input() addOnly = false;
  @Input() isUpdating = false;
  @Output() onDetailsClick = new EventEmitter<AssetUi>();
  @Output() onSelectedAssetChange = new EventEmitter<AssetUi | undefined>();
  @Output() onFormSubmit = new EventEmitter<CreateAssetCommandParams>();
  @Output() onDelete = new EventEmitter();

  headers: string[] = ['ASSET', 'ORGANIZATION'];
  showErrors: boolean = false;
  fc?: AssetForm;
  formgroup?: FormGroup<AssetForm>;
  defaultOrganization?: IOption;
  defaultCountry?: IOption;

  showStickyDivider: boolean = false;

  ngOnInit(): void {
    this.defaultCountry = this.countryOptions.find(
      country => country.value === this.organizationCountryMap.get(this.organizationId || '')
    );
    this.defaultOrganization = this.organizationOptions.find(org => org.value === this.organizationId);
    if (this.addOnly) this.initateAdd();
  }

  handleOnSingleClick(asset: AssetUi) {
    this.resetForm();
    this.onDetailsClick.emit(asset);
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    this.showStickyDivider = tabEntry.intersectionRatio === 0;
  }

  handleOnFormSubmit() {
    if (this.fc && this.formgroup?.valid) {
      const subOrganizationId =
        this.organizationId === this.fc.organization.value?.value ? undefined : this.fc.organization.value?.value;
      const params = new CreateAssetCommandParams({
        name: this.fc.name.value || '',
        organizationId: this.organizationId || '',
        subOrganizationId: subOrganizationId,
        versionValidFrom: new Date(),
      });
      this.onFormSubmit.emit(params);
      this.resetForm();
    } else this.handleSubmitRejection();
  }

  handleSubmitRejection() {
    if (this.showErrors && this.selectedAsset) this.handleOnCancel();
    else this.showErrors = true;
  }

  handleOnDelete() {
    this.onDelete.emit();
    this.resetForm();
  }

  handleOnCancel() {
    this.resetForm();
    this.onSelectedAssetChange.emit(undefined);
  }

  resetForm() {
    this.fc = undefined;
    this.formgroup = undefined;
    this.showErrors = false;
  }

  initateAdd(event?: Event) {
    event?.stopPropagation();
    this.fc = {
      name: new FormControl<string>('', [Validators.required]),
      organization: new FormControl<IOption | undefined>(
        {
          value: this.defaultOrganization,
          disabled: this.defaultOrganization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  iniateEdit(asset: AssetUi) {
    if (this.fc) return;

    this.onSelectedAssetChange.emit(asset);

    const organization =
      this.organizationOptions.find(org => org.label === asset.organizationName) || this.defaultOrganization;
    this.fc = {
      name: new FormControl<string>(asset.name, [Validators.required]),
      organization: new FormControl<IOption | undefined>(
        {
          value: organization,
          disabled: organization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  handleOrganizationChange(organization: IOption, countryCode?: string) {
    if (this.fc) {
      this.fc.organization.setValue(organization);
      if (countryCode) {
        const countryOption = this.countryOptions.find(opt => opt.value === countryCode);
        this.fc.country && countryOption && this.fc.country.setValue(countryOption);
      }
    }
  }
}
