import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-arrow-right-top',
  template: `
    <svg 
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 129 105" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
      <path d="M125.352 0.644513C125.156 0.450188 124.839 0.451706 124.645 0.647897L121.478 3.84507C121.284 4.04126 121.285 4.35785 121.481 4.55217C121.678 4.7465 121.994 4.74498 122.189 4.54879L125.003 1.70686L127.845 4.52171C128.042 4.71604 128.358 4.71452 128.552 4.51833C128.747 4.32213 128.745 4.00556 128.549 3.81123L125.352 0.644513ZM0.492111 104C63.1627 105.002 94.4582 79.2916 110.054 53.2566C117.842 40.2564 121.702 27.1978 123.616 17.3924C124.573 12.4888 125.044 8.39577 125.276 5.52633C125.392 4.09152 125.448 2.96235 125.475 2.19039C125.488 1.80441 125.495 1.5077 125.498 1.30672C125.499 1.20623 125.5 1.12967 125.5 1.07784C125.5 1.05193 125.5 1.0322 125.5 1.01875C125.5 1.01203 125.5 1.00688 125.5 1.00331C125.5 1.00152 125.5 1.00004 125.5 0.999139C125.5 0.998056 125.5 0.997365 125 0.999756C124.5 1.00215 124.5 1.00226 124.5 1.00276C124.5 1.00346 124.5 1.00436 124.5 1.00574C124.5 1.00853 124.5 1.01289 124.5 1.01883C124.5 1.03069 124.5 1.04883 124.5 1.07314C124.5 1.12175 124.499 1.19504 124.498 1.29221C124.495 1.48656 124.489 1.7764 124.475 2.15531C124.449 2.91314 124.394 4.02713 124.279 5.44583C124.05 8.28342 123.583 12.3389 122.634 17.2008C120.736 26.9267 116.909 39.868 109.196 52.7428C93.792 78.4578 62.8375 103.997 0.508109 103L0.492111 104Z" 
      attr.fill="{{ color }}" />
    </svg>

  `,
  styles: [],
})
export class ArrowRightTopComponent {
  @Input() size: string = '120';
  @Input() color: string = 'black';
}
