import { Injectable } from '@angular/core';
import { AssetDetailsStateService } from '../../asset-details-state.service';

@Injectable()
export class OrganizationAssetDetailsStateService extends AssetDetailsStateService {
  async init(organizationId: string) {
    this.loading = true;
    this.assetId = organizationId;
    await this.getRecordsOverview();
    this.loading = false;
  }
}
