import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'esg-dot-flashing',
  templateUrl: './dot-flashing.component.html',
  styleUrls: ['./dot-flashing.component.scss'],
})
export class DotFlashingComponent implements OnInit {
  @Input() label?: string = 'We are loading good stuff';

  constructor() {}

  ngOnInit(): void {}
}
