import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';

@Component({
  selector: 'esg-segmented-control',
  templateUrl: './segmented-control.component.html',
  styleUrls: ['./segmented-control.component.scss'],
})
export class SegmentedControlComponent {
  @Input() tabs: ITabItem[] = [];
  @Input() activeTab?: string;
  @Output() onTabClick = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  handleOnTabClick(tabValue: string) {
    this.tabs.length > 1 && this.onTabClick.emit(tabValue);
  }
}
