import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  GetResponsiblePersonTopicItemsQueryResponse,
  CreateTopicItemCommandResponse,
  CreateTopicItemCommandParams,
  TopicItemClient,
  ReportTopicItemVm,
  TopicItemVm,
  DeleteTopicItemCommandParams,
  DeleteTopicItemCommandResponse,
  MoveTopicItemCommandParams,
  MoveTopicItemCommandResponse,
  UpdateTopicItemStatusCommandResponse,
  UpdateTopicItemCommandParams,
  UpdateTopicItemCommandResponse,
  CreateComponentCommandParams,
  CreateComponentCommandResponse,
  UpdateTopicItemStatusCommandParams,
  GetTopicItemsComponentsVm,
  UpdateComponentCommandParams,
  UpdateComponentCommandResponse,
  MoveComponentCommandParams,
  MoveComponentCommandResponse,
  DeleteComponentCommandParams,
  DeleteComponentCommandResponse,
  UpdateTopicItemResponsiblePersonCommandParams,
  UpdateTopicItemResponsiblePersonCommandResponse,
  UpdateTopicItemVisibilityCommandParams,
  UpdateTopicItemVisibilityCommandResponse,
  RefreshTopicItemNewIndicatorValuesCommandResponse,
  ReportAllTopicItemVm,
  AddIndicatorToComponentCommandResponse,
  AddIndicatorToComponentCommandParams,
  RemoveIndicatorFromComponentCommandResponse,
  RemoveIndicatorFromComponentCommandParams,
  RemoveIndicatorComponentCommandResponse,
  RemoveIndicatorComponentCommandParams,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class TopicItemApiService {
  client: TopicItemClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new TopicItemClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async addTopicItem(body: CreateTopicItemCommandParams): Promise<CreateTopicItemCommandResponse> {
    const observable$ = this.client.createTopicItem(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async updateTopicItem(body: UpdateTopicItemCommandParams): Promise<UpdateTopicItemCommandResponse> {
    const observable$ = this.client.updateTopicItem(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  deleteTopicItem(body: DeleteTopicItemCommandParams): Observable<DeleteTopicItemCommandResponse> {
    return this.client.removeTopicItem(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  async allReportTopicItems(reportId: string): Promise<ReportAllTopicItemVm[]> {
    const observable$ = this.client.getReportAllTopicItems(reportId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  allTopicItems(reportId: string, mainLevelId: string, topicId: string): Observable<ReportTopicItemVm[]> {
    return this.client.getTopicTopicItems(reportId, mainLevelId, topicId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  getTopicItemDetails(topicItemId: string): Observable<TopicItemVm> {
    return this.client.getTopicItem(topicItemId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  async getResponsiblePersonTopicItems(): Promise<GetResponsiblePersonTopicItemsQueryResponse> {
    const observable$ = this.client.getYourTopicItemsYouAreResponsibleFor();
    return await lastValueFrom(observable$);
  }

  moveTopicItem(body: MoveTopicItemCommandParams): Observable<MoveTopicItemCommandResponse> {
    return this.client.moveTopicItem(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  updateStatus(body: UpdateTopicItemStatusCommandParams): Observable<UpdateTopicItemStatusCommandResponse> {
    return this.client.updateStatus(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  updateResponsiblePerson(
    body: UpdateTopicItemResponsiblePersonCommandParams
  ): Observable<UpdateTopicItemResponsiblePersonCommandResponse> {
    return this.client.updateResponsiblePerson(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  updateVisibility(body: UpdateTopicItemVisibilityCommandParams): Observable<UpdateTopicItemVisibilityCommandResponse> {
    return this.client.updateVisibility(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  addComponent(body: CreateComponentCommandParams): Observable<CreateComponentCommandResponse> {
    return this.client.addComponent(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  updateComponent(body: UpdateComponentCommandParams): Observable<UpdateComponentCommandResponse> {
    return this.client.updateComponent(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  moveComponent(body: MoveComponentCommandParams): Observable<MoveComponentCommandResponse> {
    return this.client.moveComponent(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  removeComponent(body: DeleteComponentCommandParams): Observable<DeleteComponentCommandResponse> {
    return this.client.removeComponent(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  getTopicItemComponents(topicItemId: string, reportId: string): Observable<GetTopicItemsComponentsVm[]> {
    return this.client.getTopicItemComponents(topicItemId, reportId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  async refreshTopicItemNewIndicatorValues(
    topicItemId: string
  ): Promise<RefreshTopicItemNewIndicatorValuesCommandResponse> {
    const observable$ = this.client.refreshTopicItemNewIndicatorValues(topicItemId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async addIndicatorToComponent(
    topicItemId: string,
    componentId: string,
    indicatorId: string
  ): Promise<AddIndicatorToComponentCommandResponse> {
    const observable$ = this.client
      .addIndicatorToComponent(
        new AddIndicatorToComponentCommandParams({
          topicItemId: topicItemId,
          componentId: componentId,
          indicatorId: indicatorId,
        })
      )
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );

    return await lastValueFrom(observable$);
  }

  async removeIndicatorFromComponent(
    topicItemId: string,
    componentId: string,
    indicatorId: string
  ): Promise<RemoveIndicatorFromComponentCommandResponse> {
    const observable$ = this.client
      .removeIndicatorFromComponent(
        new RemoveIndicatorFromComponentCommandParams({
          topicItemId: topicItemId,
          componentId: componentId,
          indicatorId: indicatorId,
        })
      )
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );

    return await lastValueFrom(observable$);
  }

  async removeIndicatorComponent(
    topicItemId: string,
    componentId: string
  ): Promise<RemoveIndicatorComponentCommandResponse> {
    const observable$ = this.client
      .removeIndicatorComponent(
        new RemoveIndicatorComponentCommandParams({
          topicItemId: topicItemId,
          componentId: componentId,
        })
      )
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );

    return await lastValueFrom(observable$);
  }
}
