import { Injectable } from '@angular/core';
import { AssetType, DataRecordCategory } from 'src/api-client/report-api.generated';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { RecordsStateService } from '../records-state.service';
import { AssetCategoryLabel } from 'src/app/static-data/enum-mappings';

@Injectable()
export class LeasedAssetsStateService extends RecordsStateService {
  recordCategory = DataRecordCategory.EmbeddedEmissionsLeasedAssets;

  assetCategories = [AssetType.Vessel, AssetType.VehicleCraftMachinery, AssetType.Facility];
  clientOptions: IOption[] = [];

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getClientOptions(), this.getDataCategoryDetails()]);
  }

  async fetchOptions() {
    await Promise.all([this.getAssetOptions(), this.getClientOptions()]);
  }

  async getAssetOptions() {
    const response = await this.assetApiService.getAssetsByOrganizationAndAvailableForLeasing();

    const assetOptions: IGroupedOptions[] = this.assetCategories.map(category => ({
      label: AssetCategoryLabel[category],
      options: [],
    }));
    this.assetTypeOptions = this.assetCategories.map(assetType => ({
      value: assetType,
      label: AssetCategoryLabel[assetType],
    }));
    if (this.assetTypeOptions.length === 1) this.filteredAssetTypes.setValue([this.assetTypeOptions[0]]);

    response.result.forEach(asset => {
      const group = assetOptions.find(group => group.label === AssetCategoryLabel[asset.assetType]);
      if (group) {
        group.options.push({ value: asset.id, label: asset.name, group: asset.assetType });
      }
    });

    this.assetOptions = assetOptions;

    this.assetFilterOptions = this.assetOptions;
    this.isAssetsDisabled = this.assetOptions.flatMap(group => group.options).length < 2;
  }

  async getClientOptions() {
    const response = await this.assetApiService.getAssetsByOrganizationAndType(undefined, AssetType.Client);
    this.clientOptions = response.result.map(client => ({ value: client.id, label: client.name }));
  }
}
