import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ContractType,
  CreateEmployeeGenderDiversityAndEqualityDataRecordCommandParams,
  CurrencyCodes,
  DataRecordType,
  EmployeeGenderDiversityAndEqualityDataRecord,
  EmploymentType,
  Gender,
  InputType,
  Location,
  PositionLevel,
  UpdateEmployeeGenderDiversityAndEqualityDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import {
  RecordForm,
  RecordFormTableComponent,
} from 'src/app/pages/data-collect/shared/record-form-table/record-form-table.component';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import {
  ContractOptions,
  EmploymentOptions,
  GenderOptions,
  IOption,
  LocationOptions,
  PositionLevelOptions,
  generateAgeOptions,
} from 'src/app/static-data/options';

interface GenderDiversityEqualityRecordForm extends RecordForm {
  employee: FormControl<string>;
  yearOfBirth: FormControl<IOption>;
  gender: FormControl<IOption>;
  employment: FormControl<IOption | undefined>;
  contract: FormControl<IOption | undefined>;
  positionLevel: FormControl<IOption>;
  location: FormControl<IOption | undefined>;
  region: FormControl<IOption | undefined>;
  salary: FormControl<string>;
}

@Component({
  selector: 'esg-gender-diversity-equality-form-table',
  templateUrl: './gender-diversity-equality-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
})
export class GenderDiversityEqualityFormTableComponent extends RecordFormTableComponent {
  @Input({ required: true }) profileId!: string;
  @Input({ required: true }) assetId!: string;
  @Input() currencyCode: string = CurrencyCodes.USD;
  @Input() regionOptions: IOption[] = [];

  headerCells: RecordHeaderCell[] = [
    { columnId: 'employee', label: 'Employee', description: 'Name or ID', number: false },
    { columnId: 'yearOfBirth', label: 'Year of birth', description: '', number: false },
    { columnId: 'gender', label: 'Gender', description: '', number: false },
    { columnId: 'employment', label: 'Employment', description: '', number: false },
    {
      columnId: 'contract',
      label: 'Contract',
      description: 'Contract type',
      number: false,
    },
    { columnId: 'positionLevel', label: 'Position', description: 'Position Level', number: false },
    { columnId: 'location', label: 'Location', description: 'Onshore/Offshore', number: false },
    { columnId: 'region', label: 'Region', description: 'Region', number: false },
    { columnId: 'salary', label: 'Salary', description: 'Annual', number: true },
  ];

  recordFc?: GenderDiversityEqualityRecordForm = undefined;
  recordFormgroup?: FormGroup<GenderDiversityEqualityRecordForm> = undefined;

  ageOptions = generateAgeOptions();
  employmentOptions = EmploymentOptions;
  contractOptions = ContractOptions;
  genderOptions = GenderOptions;
  positionLevelOptions = PositionLevelOptions;
  locationOptions = LocationOptions;

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.currencyCode) {
      this.setSalaryDescription(changes.currencyCode.currentValue);
    }
  }

  setSalaryDescription(currency: string) {
    this.headerCells[this.headerCells.length - 1].description = 'Annual ' + currency;
  }

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as EmployeeGenderDiversityAndEqualityDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'employee',
          value: record.employee.toString(),
          number: false,
        },
        {
          columnId: 'yearOfBirth',
          value: record.yearOfBirth.toString(),
          number: false,
        },
        {
          columnId: 'gender',
          value: this.genderOptions.find(c => c.value === record.gender)?.label || '-',
          number: false,
        },
        {
          columnId: 'employment',
          value: this.employmentOptions.find(c => c.value === record.employment)?.label || '-',
          number: false,
        },
        {
          columnId: 'contract',
          value: this.contractOptions.find(c => c.value === record.contract)?.label || '-',
          number: false,
        },
        {
          columnId: 'positionLevel',
          value: this.positionLevelOptions.find(c => c.value === record.positionLevel)?.label || '-',
          number: false,
        },
        {
          columnId: 'location',
          value: this.locationOptions.find(c => c.value === record.location)?.label || '-',
          number: false,
        },
        {
          columnId: 'region',
          value: this.regionOptions.find(c => c.value === record.regionId)?.label || '-',
          number: false,
        },
        {
          columnId: 'salary',
          value: record.salary?.toString() || '-',
          number: true,
        },
      ],
    }));
  }

  handleOnIniateAddRecord() {
    if (this.editRecordId) this.handleCloseForm();
    this.setAddRecordForm();
  }

  setAddRecordForm() {
    const defaultBirthYear = new Date().getFullYear() - 40;
    const defaultYearOfBirthOption =
      this.ageOptions.find(option => option.label === defaultBirthYear.toString()) || this.ageOptions[0];

    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(undefined, {
        nonNullable: true,
      }),
      employee: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      yearOfBirth: new FormControl<IOption>(defaultYearOfBirthOption, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      gender: new FormControl<IOption>(this.genderOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      employment: new FormControl<IOption | undefined>(undefined, {
        nonNullable: true,
      }),
      contract: new FormControl<IOption | undefined>(undefined, {
        nonNullable: true,
      }),
      positionLevel: new FormControl<IOption>(this.positionLevelOptions[2], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      location: new FormControl<IOption | undefined>(this.locationOptions[0], {
        nonNullable: true,
      }),
      region: new FormControl<IOption | undefined>(this.regionOptions.length ? this.regionOptions[0] : undefined, {
        nonNullable: true,
      }),
      salary: new FormControl<string>('', {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup<GenderDiversityEqualityRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    this.editRecordId = id;
    const record = this.dataRecords.find(record => record.id === id) as EmployeeGenderDiversityAndEqualityDataRecord;
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.endDate, {
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(undefined, {
          nonNullable: true,
        }),
        employee: new FormControl<string>(record.employee, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        yearOfBirth: new FormControl<IOption>(
          this.ageOptions.find(opt => opt.value === record.yearOfBirth.toString()) || this.ageOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        gender: new FormControl<IOption>(
          this.genderOptions.find(opt => opt.value === record.gender) || this.genderOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        employment: new FormControl<IOption | undefined>(
          this.employmentOptions.find(opt => opt.value === record.employment),
          {
            nonNullable: true,
          }
        ),
        contract: new FormControl<IOption | undefined>(
          this.contractOptions.find(opt => opt.value === record.contract),
          {
            nonNullable: true,
          }
        ),
        positionLevel: new FormControl<IOption>(
          this.positionLevelOptions.find(opt => opt.value === record.positionLevel) || this.positionLevelOptions[2],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        location: new FormControl<IOption | undefined>(
          this.locationOptions.find(opt => opt.value === record.location),
          {
            nonNullable: true,
          }
        ),
        region: new FormControl<IOption | undefined>(
          this.regionOptions.find(opt => opt.value === record.regionId),
          {
            nonNullable: true,
          }
        ),
        salary: new FormControl<string>(record.salary?.toString() || '', {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup<GenderDiversityEqualityRecordForm>(this.recordFc);
    }
  }

  getParams():
    | CreateEmployeeGenderDiversityAndEqualityDataRecordCommandParams
    | UpdateEmployeeGenderDiversityAndEqualityDataRecordCommandParams
    | undefined {
    const gender = Object.values(Gender).find(type => type === this.recordFc?.gender.value?.value);
    const employment = Object.values(EmploymentType).find(type => type === this.recordFc?.employment.value?.value);
    const contract = Object.values(ContractType).find(type => type === this.recordFc?.contract.value?.value);
    const positionLevel = Object.values(PositionLevel).find(type => type === this.recordFc?.positionLevel.value.value);
    const location = Object.values(Location).find(type => type === this.recordFc?.location.value?.value);
    const validOptions = gender && positionLevel;

    if (this.recordFormgroup?.valid && this.recordFc && this.profileId && this.assetId && validOptions) {
      const genderRecord = {
        dataProfileId: this.profileId,
        type: DataRecordType.EmployeeGenderDiversityAndEquality,
        assetId: this.assetId,
        inputType: InputType.Manual,
        employee: this.recordFc.employee.value,
        yearOfBirth: Number(this.recordFc.yearOfBirth.value.value),
        gender: gender,
        employment: employment,
        contract: contract,
        positionLevel: positionLevel,
        location: location,
        regionId: this.recordFc.region.value?.value,
        salary: formattedStringToNumber(this.recordFc.salary.value || ''),
      };
      if (this.editRecordId) {
        return new UpdateEmployeeGenderDiversityAndEqualityDataRecordCommandParams({
          id: this.editRecordId,
          ...genderRecord,
        });
      } else {
        return new CreateEmployeeGenderDiversityAndEqualityDataRecordCommandParams(genderRecord);
      }
    }
    return undefined;
  }
}
