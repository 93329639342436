import { Directive, HostListener, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
  selector: '[esgTabFocus]',
  standalone: true,
})
export class TabFocusDirective {
  private isTabPressed = false;

  @Output() onTabFocus = new EventEmitter<Event>();
  @Output() onTabBlur = new EventEmitter<Event>();

  constructor(private hostRef: ElementRef) {}

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.isTabPressed = true;
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyup(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.isTabPressed = false;
    }
  }

  @HostListener('focus', ['$event'])
  handleFocusIn(event: Event) {
    if (this.isTabPressed && this.hostRef.nativeElement.contains(event.target)) {
      this.onTabFocus.emit(event);
    }
  }

  @HostListener('focusout', ['$event'])
  handleFocusOut(event: Event) {
    if (this.isTabPressed && this.hostRef.nativeElement.contains(event.target)) {
      this.onTabBlur.emit(event);
    }
  }
}
