<esg-textarea [fc]="jsonInput" [placeholder]="jsonPlaceholder"></esg-textarea>
<esg-button (onClick)="parseJson()">Update Table</esg-button>
<esg-generic-table
  [columns]="columns"
  [rows]="rows"
  (onRowChange)="handleOnRowChange($event)"
  (onAddRowAfter)="handleOnAddRow($event)"
  (onDeleteRow)="handleOnDeleteRow($event)"
  (onAddColumnAfter)="handleOnAddColumn($event)"
  (onDeleteColumn)="handleOnDeleteColumn($event)"
  (onColumnsChange)="handleOnColumnsChange($event)"></esg-generic-table>
