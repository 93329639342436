import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportPageComponent } from './support-page.component';
import { RouterModule } from '@angular/router';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { SupportContentListComponent } from './support-content-list/support-content-list.component';
import { TableModule } from 'src/app/shared/table/table.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { DirectiveModule } from 'src/app/shared/directives/directive.module';
import { SupportArticleDetailsComponent } from './support-content-list/support-article-details/support-article-details.component';
import { ContentModule } from 'src/app/content/content.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { TutorialDialogComponent } from './tutorials/tutorial-dialog/tutorial-dialog.component';

@NgModule({
  declarations: [
    SupportPageComponent,
    SupportContentListComponent,
    SupportArticleDetailsComponent,
    TutorialsComponent,
    TutorialDialogComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    UiModule,
    SharedComponentsModule,
    DirectiveModule,
    ContentModule,
    RouterModule.forChild([
      {
        path: `support`,
        component: AppLayoutComponent,
        children: [
          {
            path: ``,
            component: SupportPageComponent,
            children: [
              {
                path: ``,
                component: SupportContentListComponent,
              },
              {
                path: 'tutorials',
                component: TutorialsComponent,
              },
              {
                path: `:topicId`,
                component: SupportContentListComponent,
              },
            ],
          },
        ],
        canActivate: [MsalGuard],
      },
    ]),
  ],
})
export class SupportModule {}
