<esg-dialog class="auto" [showCloseBtn]="false" (onClose)="handleCancel()">
  <div class="content">
    <div class="title">{{ title }}</div>
    <div class="message">{{ message }}</div>
    <div class="btns">
      <esg-button class="tertiary" (onClick)="handleCancel()">{{ cancelText }}</esg-button>
      <esg-button (onClick)="handleSubmit()">{{ submitText }}</esg-button>
    </div>
  </div>
</esg-dialog>
