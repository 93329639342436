<div class="content">
  <esg-description-and-reference [description]="state.categoryDetails?.guidance || ''">
    <h2 class="title">
      {{ profile.name }}
    </h2>
  </esg-description-and-reference>
  <div class="static-data">
    <span class="input-label">Profile type</span>
    <div class="input">
      <esg-select
        [options]="inputOptions"
        variant="ghost"
        size="small"
        [disabled]="inputOptions.length === 1"
        [value]="formgroup.controls.inputType.value || undefined"
        (onChange)="handleDropdownChange(formgroup.controls.inputType, $event)"></esg-select>
    </div>
    <span class="input-label">Total Employees</span>
    <div class="input">
      <esg-textbox-form
        [fc]="formgroup.controls.totalEmployees"
        type="number"
        variant="ghost"
        placeholder="Number"
        (onChange)="formgroup.controls.totalEmployees.setValue($event)"
        (onEnter)="onUpdate.emit()"></esg-textbox-form>
    </div>
    <span class="input-label">Total Home Office Days</span>
    <div class="input">
      <esg-textbox-form
        [fc]="formgroup.controls.totalHomeOfficeDays"
        type="number"
        variant="ghost"
        placeholder="Number"
        (onChange)="formgroup.controls.totalHomeOfficeDays.setValue($event)"
        (onEnter)="onUpdate.emit()"></esg-textbox-form>
    </div>
    <span class="input-label">Total Commuting Days</span>
    <div class="input">
      <esg-textbox-form
        [fc]="formgroup.controls.totalCommutingDays"
        type="number"
        variant="ghost"
        placeholder="Number"
        (onChange)="formgroup.controls.totalCommutingDays.setValue($event)"
        (onEnter)="onUpdate.emit()"></esg-textbox-form>
    </div>
  </div>
  <esg-employee-commuting-records [profileId]="profile.id"></esg-employee-commuting-records>
</div>
