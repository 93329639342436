import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-share-icon',
  template: `
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.8252 19.7949H12.9502C13.8057 19.7949 14.4502 19.5752 14.8838 19.1357C15.3174 18.7021 15.5342 18.0664 15.5342 17.2285V8.2373C15.5342 7.39355 15.3174 6.75488 14.8838 6.32129C14.4502 5.8877 13.8057 5.6709 12.9502 5.6709H10.4365V6.75195H12.9238C13.4102 6.75195 13.7852 6.88379 14.0488 7.14746C14.3184 7.40527 14.4531 7.78906 14.4531 8.29883V17.167C14.4531 17.6768 14.3184 18.0605 14.0488 18.3184C13.7852 18.582 13.4102 18.7139 12.9238 18.7139H2.85156C2.35352 18.7139 1.97266 18.582 1.70898 18.3184C1.45117 18.0605 1.32227 17.6768 1.32227 17.167V8.29883C1.32227 7.78906 1.45117 7.40527 1.70898 7.14746C1.97266 6.88379 2.35352 6.75195 2.85156 6.75195H5.34766V5.6709H2.8252C1.96973 5.6709 1.3252 5.8877 0.891602 6.32129C0.458008 6.74902 0.241211 7.3877 0.241211 8.2373V17.2285C0.241211 18.0781 0.458008 18.7168 0.891602 19.1445C1.3252 19.5781 1.96973 19.7949 2.8252 19.7949ZM7.8877 13.0273C8.03418 13.0273 8.16016 12.9775 8.26562 12.8779C8.37109 12.7725 8.42383 12.6465 8.42383 12.5V3.03418L8.38867 1.75098L9.1709 2.56836L10.665 4.12402C10.7646 4.23535 10.8877 4.29102 11.0342 4.29102C11.1748 4.29102 11.292 4.24414 11.3857 4.15039C11.4795 4.05664 11.5264 3.94238 11.5264 3.80762C11.5264 3.67871 11.4736 3.56152 11.3682 3.45605L8.27441 0.432617C8.20996 0.362305 8.14551 0.31543 8.08105 0.291992C8.02246 0.262695 7.95801 0.248047 7.8877 0.248047C7.81738 0.248047 7.75 0.262695 7.68555 0.291992C7.62695 0.31543 7.56543 0.362305 7.50098 0.432617L4.39844 3.45605C4.29883 3.56152 4.24902 3.67871 4.24902 3.80762C4.24902 3.94238 4.29297 4.05664 4.38086 4.15039C4.47461 4.24414 4.5918 4.29102 4.73242 4.29102C4.88477 4.29102 5.00781 4.23535 5.10156 4.12402L6.5957 2.56836L7.39551 1.75098L7.35156 3.03418V12.5C7.35156 12.6465 7.4043 12.7725 7.50977 12.8779C7.61523 12.9775 7.74121 13.0273 7.8877 13.0273Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class ShareIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
