import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-icon-close',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.22095"
        y="2.80762"
        width="24"
        height="2"
        rx="1"
        transform="rotate(45 4.22095 2.80762)"
        [ngStyle]="{ fill: color }" />
      <rect
        x="2.80762"
        y="19.7783"
        width="24"
        height="2"
        rx="1"
        transform="rotate(-45 2.80762 19.7783)"
        [ngStyle]="{ fill: color }" />
    </svg>
  `,
  styles: [],
})
export class CloseIconComponent {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  // TODO CANSU How can I see icon hover color on Figma?
  @Input() size: string = '24';
  @Input() color: string = '#183642';
}