<table [ngStyle]="{ 'grid-template-columns': columnsCount }">
  <thead>
    <tr>
      <th *ngFor="let header of config.headers">{{ header.label }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rows">
      <td *ngFor="let item of row">
        {{ item }}
      </td>
    </tr>
  </tbody>
</table>
