<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading indicators"></esg-dot-flashing>
<esg-dot-flashing
  *ngIf="state.updatingAllIndicators"
  class="loader"
  [label]="state.updatingAllIndicatorsLabel"></esg-dot-flashing>

<ng-container *ngIf="!state.loading && !state.updatingAllIndicators">
  <div class="top-section">
    <h2>{{ state.currentCategory || 'Indicators' }}</h2>
    <button class="update-button" (click)="state.updateAllIndicators()" [disabled]="state.updatingAllIndicators">
      <esg-calculation-icon></esg-calculation-icon>
      {{ updateAllIndicatorsButtonText }}
    </button>
  </div>

  <table>
    <thead>
      <tr>
        <th>INDICATOR</th>
        <th>LAST CALCULATED</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let indicator of state.indicators; let i = index; trackBy: trackByIndicator">
        <tr
          *ngIf="indicator.category === state.currentCategory || !state.currentCategory"
          [class.active]="state.selectedIndicatorId === indicator.id"
          (click)="handleIndicatorClick(indicator.id)">
          <td>
            {{ indicator.name }}
          </td>
          <td>
            <span>{{ indicator.lastCalculated | date : 'dd.MMM' }}&nbsp;</span>
            <span class="italic">{{ indicator.lastCalculated | date : 'HH:mm' }}</span>
          </td>
          <td>
            <esg-table-button label="Details" (onClick)="handleIndicatorClick(indicator.id)"></esg-table-button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>

<esg-details-dialog
  [open]="isIndicatorDetailsOpen"
  (onClose)="closeIndicatorDetails()"
  [options]="state.detailsMenuProvider()">
  <div action class="calculation-details">
    <button
      class="update-button"
      (click)="state.updateSelectedIndicator()"
      [disabled]="state.updatingSelectedIndicator">
      <esg-calculation-icon></esg-calculation-icon>
      Update Calculation
    </button>
    <span class="update-date">
      Last update:
      {{
        state.selectedIndicatorLastCalculated
          ? (state.selectedIndicatorLastCalculated | date : 'dd. MMM yyyy, HH:mm:ss')
          : ''
      }}
    </span>
  </div>
  <esg-indicator-details
    *ngIf="state.selectedIndicatorId"
    [indicatorId]="state.selectedIndicatorId"
    [organizationCurrency]="state.organizationCurrency"
    [sectorTypes]="state.sectorTypes"
    [updatingCalculation]="state.updatingSelectedIndicator"></esg-indicator-details>
</esg-details-dialog>
<esg-confirm-dialog
  *ngIf="state.showRemoveDialog && state.selectedIndicatorId"
  [message]="
    'Once you remove this indicator,\n you delete all the data attached to it,\n and there is no turning back.'
  "
  cancelText="No, keep it"
  submitText="Remove"
  (onCancel)="state.handleCloseRemoveDialog()"
  (onSubmit)="state.removeIndicator()"></esg-confirm-dialog>
<esg-alert-dialog
  *ngIf="state.showNotPossibleToRemoveDialog && state.selectedIndicatorId"
  title=""
  variant="error"
  [message]="
    'You can’t remove this indicator because it is used by a report. \nFirst remove the indicator from all your reports and then remove it from your KPIs'
  "
  buttonText="Ok, got it"
  (onClose)="state.handleCloseNotPossibleToRemoveDialog()"></esg-alert-dialog>
