import { Component } from '@angular/core';
import { IndicatorsPageStateService } from 'src/app/pages/indicators/indicators-page-state.service';

@Component({
  selector: 'esg-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrl: './side-navigation.component.scss',
})
export class SideNavigationComponent {
  constructor(public state: IndicatorsPageStateService) {}
}
