import { Component, Input, OnInit } from '@angular/core';

export interface HeaderColumn {
  label: string;
}

export interface TableDataConfig {
  headers: HeaderColumn[];
}

@Component({
  selector: 'esg-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss'],
})
export class TableDataComponent implements OnInit {
  @Input({ required: true }) editMode!: boolean;
  @Input({ required: true }) config!: TableDataConfig;
  @Input({ required: true }) rows!: string[][];

  get columnsCount() {
    return `1fr repeat(${this.config.headers.length - 1}, auto)`;
  }

  constructor() {}

  ngOnInit(): void {}
}
