import { Component, Input, OnInit } from '@angular/core';
import { FacilityDetailsStateService } from './facility-details-state.service';
import { IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-facility-details',
  templateUrl: './facility-details.component.html',
  styleUrls: ['../../asset-details.component.scss', './facility-details.component.scss'],
  providers: [FacilityDetailsStateService],
})
export class FacilityDetailsComponent implements OnInit {
  @Input({ required: true }) assetId!: string;
  @Input() organizationOptions: IOption[] = [];
  @Input() countryOptions: IOption[] = [];

  constructor(public state: FacilityDetailsStateService) {}
  ngOnInit(): void {
    this.state.init(this.assetId, this.organizationOptions, this.countryOptions);
  }
}
