import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page/login-page.component';
import { UiModule } from '../shared/ui/ui.module';
import { LoadingUserComponent } from './loading-user/loading-user.component';

@NgModule({
  declarations: [LoginPageComponent, LoadingUserComponent],
  imports: [CommonModule, UiModule],
  exports: [LoginPageComponent, LoadingUserComponent],
})
export class PagesAuthModule {}
