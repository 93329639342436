import { Component, Input } from '@angular/core';
import { IOption } from 'src/app/static-data/options';
import { ClientDetailsStateService } from './client-details-state.service';

@Component({
  selector: 'esg-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['../../asset-details.component.scss'],
  providers: [ClientDetailsStateService],
})
export class ClientDetailsComponent {
  @Input({ required: true }) assetId!: string;
  @Input() organizationOptions: IOption[] = [];
  @Input() countryOptions: IOption[] = [];

  constructor(public state: ClientDetailsStateService) {}
  ngOnInit(): void {
    this.state.init(this.assetId, this.organizationOptions, this.countryOptions);
  }
}
