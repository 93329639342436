<div
  tabindex="0"
  class="container"
  [class.popup]="isPopup"
  [ngClass]="fc.errors ? 'not-valid' : ''"
  esg-on-click-outside
  (onClickOutside)="closeOptions()">
  <div
    #input
    class="container-input"
    [class]="[variant, size]"
    [class.disabled]="fc.disabled"
    (click)="toggleOpenOptions()">
    <div class="input-content">
      <div *ngIf="!fc.value.length" class="nothing-selected">{{ placeholder }}</div>
      <esg-tag
        *ngFor="let selectedOption of fc.value"
        [label]="selectedOption.label"
        [size]="size"
        (onClose)="deleteOption($event, selectedOption)"></esg-tag>
    </div>
    <div class="input-icon">
      <esg-arrow-down></esg-arrow-down>
    </div>
  </div>
  <esg-select-options
    *ngIf="open"
    class="options"
    [ngStyle]="popUpStyle"
    [selectedValues]="fc.value"
    [size]="size"
    [options]="options"
    [groupedOptions]="groupedOptions"
    [dropUp]="dropUp"
    [allowReset]="isFilter"
    [resetLabel]="placeholder"
    [maxHeight]="maxHeight"
    (onChange)="handleSelectOption($event)"
    (onReset)="deleteAll()"></esg-select-options>
</div>
