import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOption } from 'src/app/static-data/options';
import {
  IOrganization,
  ISubOrganizationCreate,
  ISubOrganizationDelete,
  ISubOrganizationUpdate,
} from '../../organizations-types';
import { SubOrganizationForm } from '../sub-organization-form/sub-organization-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryCodes } from 'src/api-client/report-api.generated';

@Component({
  selector: '[esg-organization-tbody]',
  templateUrl: './organization-tbody.component.html',
  styleUrls: ['./organization-tbody.component.scss'],
})
export class OrganizationTbodyComponent implements OnInit {
  @Input({ required: true }) organization!: IOrganization;
  @Input() countryOptions: IOption[] = [];
  @Input() active: boolean = false;
  @Output() onOrganizationClick = new EventEmitter<string>();
  @Output() onAddSubOrganization = new EventEmitter<ISubOrganizationCreate>();
  @Output() onEditSubOrganization = new EventEmitter<ISubOrganizationUpdate>();
  @Output() onDeleteSubOrganization = new EventEmitter<ISubOrganizationDelete>();
  expanded: boolean = true;

  subOrgFc?: SubOrganizationForm;
  subOrgFormGroup?: FormGroup<SubOrganizationForm>;

  constructor() {}

  ngOnInit(): void {}

  handleExpand(event: Event) {
    if (this.organization.subOrganizations.length === 0) return;

    event.stopPropagation();
    this.expanded = !this.expanded;
  }

  handleOnAddClick(event: Event) {
    event.stopPropagation();
    this.expanded = true;
    this.subOrgFc = {
      name: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      orgID: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(opt => opt.value === this.organization.countryCode),
        {
          nonNullable: true,
        }
      ),
    };

    this.subOrgFormGroup = new FormGroup(this.subOrgFc);
  }

  handleOnSubOrgSubmit() {
    if (this.subOrgFormGroup && this.subOrgFc) {
      const countryCode = Object.values(CountryCodes).find(code => code === this.subOrgFc?.country.value?.value);
      if (this.subOrgFormGroup.valid && countryCode)
        this.onAddSubOrganization.emit({
          subOrganizationName: this.subOrgFc.name.value,
          orgID: this.subOrgFc.orgID.value,
          countryCode: countryCode,
        });
      this.handleOnSubOrgCancel();
    }
  }

  handleOnSubOrgCancel() {
    this.subOrgFc = undefined;
    this.subOrgFormGroup = undefined;
  }
}
