import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IOption, ProfileInputOptions } from 'src/app/static-data/options';
import { CommutingProfileForm, CommutingProfileUi } from '../employee-commuting-state.service';
import { EmployeeCommutingDetailsStateService } from '../employee-commuting-records/employee-commuting-details-state.service';

@Component({
  selector: 'esg-employee-commuting-details',
  templateUrl: './employee-commuting-details.component.html',
  styleUrls: ['./employee-commuting-details.component.scss'],
  providers: [EmployeeCommutingDetailsStateService],
})
export class EmployeeCommutingDetailsComponent {
  @Input({ required: true }) profile!: CommutingProfileUi;
  @Input({ required: true }) formgroup!: FormGroup<CommutingProfileForm>;
  @Output() onUpdate = new EventEmitter();

  inputOptions: IOption[] = ProfileInputOptions;

  constructor(public state: EmployeeCommutingDetailsStateService) {}

  handleDropdownChange(formControl: FormControl<IOption | undefined>, option: IOption) {
    if (option !== formControl.value) {
      formControl.setValue(option);
      this.onUpdate.emit();
    }
  }
}
