<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading units"></esg-dot-flashing>
<ng-container *ngIf="!state.loading">
  <h2>Units</h2>
  <table>
    <thead>
      <tr>
        <th>UNIT</th>
        <th>FULL NAME (TOOLTIP)</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let unit of state.units; trackBy: trackByUnitKey">
        <td><span [innerHTML]="unit.name | formatUnitWithHtml"></span></td>
        <td>
          {{ unit.description }}
        </td>
        <td>
          <esg-table-delete-button
            *ngIf="!unit.isSystem"
            label="Delete"
            (onClick)="handleDelete(unit.key)"></esg-table-delete-button>
        </td>
      </tr>
      <tr *ngIf="!state.unitForm" class="add-line">
        <td [class.expand-line]="state.showStickyDivider">
          <div
            esgIntersectionObserver
            [threshold]="0"
            rootMargin="0px 0px -72px 0px"
            (onIntersection)="state.handleStickyDivider($event)"></div>
          <esg-add-button label="Create New" (onClick)="handleCreateUnitClick($event)"></esg-add-button>
        </td>
      </tr>
      <tr
        *ngIf="state.unitForm"
        class="create-row"
        esg-on-click-outside
        (onClickOutside)="handleSubmitCreate()"
        esgKeyboardShortcuts
        (onEscapeKey)="handleCreateUnitCancel()"
        (onEnterKey)="handleSubmitCreate()">
        <ng-container *ngTemplateOutlet="unitForm"></ng-container>
        <td>
          <esg-table-cancel-button label="Cancel" (onClick)="handleCreateUnitCancel()"></esg-table-cancel-button>
        </td>
      </tr>
      <ng-template #unitForm>
        <ng-container *ngIf="state.unitForm">
          <td class="input-cell" [class.error]="state.hasSubmitted && !state.unitForm.name.valid">
            <esg-textbox-form
              class="input-text"
              type="text"
              [autoFocus]="true"
              [fc]="state.unitForm.name"
              variant="ghost-table"
              placeholder="Unit"></esg-textbox-form>
          </td>
          <td class="input-cell" [class.error]="state.hasSubmitted && !state.unitForm.description.valid">
            <esg-textbox-form
              class="input-text"
              type="text"
              [autoFocus]="false"
              [fc]="state.unitForm.description"
              variant="ghost-table"
              placeholder="Full name"></esg-textbox-form>
          </td>
        </ng-container>
      </ng-template>
    </tbody>
  </table>
</ng-container>
<esg-confirm-dialog
  *ngIf="state.showRemoveForUnitKey"
  (onCancel)="handleCancelDelete()"
  (onSubmit)="handleSubmitDelete()"></esg-confirm-dialog>
