<h2>Tutorials</h2>

<div class="tutorial-list">
  <div *ngFor="let tutorial of tutorials" class="tutorial-card" (click)="handleOnVideoClick(tutorial)">
    <div class="thumbnail-container">
      <img class="thumbnail" [src]="getTrustedImageSrc(tutorial.thumbnailSrc)" />
      <esg-play-icon [size]="48"></esg-play-icon>
      <div class="pause-overlay"></div>
    </div>
    <span class="title">{{ tutorial.title }}</span>
    <span class="description">{{ tutorial.description }}</span>
  </div>
</div>

<esg-tutorial-dialog
  *ngIf="selectedTutorial"
  [tutorial]="selectedTutorial"
  (onClose)="handleOnDialogClose()"></esg-tutorial-dialog>
