export function datediff(startDate: Date, endDate: Date): number {
  return Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
}

export function addDaysToDate(date: Date, daysToAdd: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + daysToAdd);
  return result;
}

export function convertToLocalDateOnlyUTC(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export function greetingsByCurrentTime(): string {
  const current = new Date();
  const hrs = current.getHours();

  if (hrs >= 12 && hrs <= 17) return 'Good afternoon';
  else if (hrs >= 17 && hrs <= 24) return 'Good evening';
  else return 'Good morning';
}
