import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TimePeriod, TimePeriodType } from 'src/api-client/report-api.generated';
import { stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { DataValuesUi } from '../topic-item-details-state.service';

export interface IIndicatorValueForm {
  targetValue: FormControl<string>;
  currentValue: FormControl<string>;
  previousValue: FormControl<string>;
}

export interface IIndicatorValueConnection {
  index: number;
  isCurrentValueConnected: boolean;
  isPreviousValueConnected: boolean;
}

export interface IIndicatorValueRow extends IIndicatorValueConnection {
  targetValue: string;
  currentValue: string;
  previousValue: string;
}

// Currently not used

@Component({
  selector: 'esg-indicator-values',
  templateUrl: './indicator-values.component.html',
  styleUrls: ['./indicator-values.component.scss'],
})
export class IndicatorValuesComponent implements OnInit {
  @Input({ required: true }) indicatorValues!: DataValuesUi[];
  @Input() timePeriod?: TimePeriod;
  @Input() isCalculated: boolean = false;
  @Output() onSubmit = new EventEmitter<IIndicatorValueRow>();
  @Output() onGoToDatacollect = new EventEmitter();
  @Output() onSetConnection = new EventEmitter<IIndicatorValueConnection>();

  targetValue?: FormControl<string>;
  currentValue?: FormControl<string>;
  previousValue?: FormControl<string>;

  editIndex?: number;
  expandedRowIds: number[] = [];

  get isInputModalOpen() {
    return this.targetValue || this.currentValue || this.previousValue ? true : false;
  }

  get isCustomPeriod() {
    return this.timePeriod?.type === TimePeriodType.Custom;
  }

  constructor() {}

  ngOnInit(): void {}

  handleOnEditingTarget(index: number) {
    this.handleOnCloseInputModal();
    this.editIndex = index;
    this.targetValue = new FormControl(
      this.formatNumberCell(this.indicatorValues[index].targetValue.value?.toString() || ''),
      { nonNullable: true }
    );
  }

  handleOnEditingCurrent(index: number) {
    this.handleOnCloseInputModal();
    this.editIndex = index;
    this.currentValue = new FormControl(
      {
        value: this.formatNumberCell(this.indicatorValues[index].currentValue.value?.toString() || ''),
        disabled: this.indicatorValues[index].isCurrentValueConnected,
      },
      { nonNullable: true }
    );
  }

  handleOnEditingPrevious(index: number) {
    this.handleOnCloseInputModal();
    this.editIndex = index;
    this.previousValue = new FormControl(
      {
        value: this.formatNumberCell(this.indicatorValues[index].previousValue.value?.toString() || ''),
        disabled: this.indicatorValues[index].isPreviousValueConnected,
      },
      { nonNullable: true }
    );
  }

  handleOnSubmit(index: number) {
    const value = this.indicatorValues[index];
    this.onSubmit.emit({
      index: index,
      targetValue: this.targetValue
        ? this.formatNumberCell(this.targetValue.value.toString())
        : this.formatNumberCell(value.targetValue.value?.toString() || ''),
      currentValue: this.currentValue
        ? this.formatNumberCell(this.currentValue.value.toString())
        : this.formatNumberCell(value.currentValue.value?.toString() || ''),
      previousValue: this.previousValue
        ? this.formatNumberCell(this.previousValue.value.toString())
        : this.formatNumberCell(value.previousValue.value?.toString() || ''),
      isCurrentValueConnected: value.isCurrentValueConnected,
      isPreviousValueConnected: value.isPreviousValueConnected,
    });
    this.handleOnCloseInputModal();
  }

  handleOnSetConnection(index: number, isPreviousValueConnected: boolean, isCurrentValueConnected: boolean) {
    this.onSetConnection.emit({
      index: index,
      isPreviousValueConnected: isPreviousValueConnected,
      isCurrentValueConnected: isCurrentValueConnected,
    });
    this.handleOnCloseInputModal();
  }

  handleOnCloseInputModal() {
    this.editIndex = undefined;
    this.targetValue = undefined;
    this.currentValue = undefined;
    this.previousValue = undefined;
  }

  formatNumberCell(value: string) {
    return stringToFormattedNumberString(value, 0, 2) || '';
  }

  getFigureTimeLabel(shift: number) {
    if (this.timePeriod) {
      return getTimeLabelFromPeriod(this.timePeriod, shift);
    }
    return '';
  }

  handleExpandRow(index: number) {
    var exists = this.expandedRowIds.some(i => i === index);
    if (exists) {
      this.expandedRowIds = this.expandedRowIds.filter(i => i !== index);
    } else {
      this.expandedRowIds = [...this.expandedRowIds, index];
    }
  }
}
