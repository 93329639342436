<ng-container *ngIf="state.topicId">
  <span class="topic">{{ state.topicName }}</span>

  <esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading content"></esg-dot-flashing>

  <table *ngIf="!state.loading" [class.read]="!state.editPermisson" [class.edit]="state.editPermisson">
    <thead>
      <tr>
        <th *ngIf="state.editPermisson"></th>
        <th><esg-table-header-text>CONTENT</esg-table-header-text></th>
        <th *ngIf="state.editPermisson"><esg-table-header-text>LAST EDITED</esg-table-header-text></th>
        <th *ngIf="state.editPermisson"><esg-table-header-text>EDITED BY</esg-table-header-text></th>
        <th></th>
      </tr>
    </thead>
    <tbody class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let item of state.articles">
        <tr
          class="read-mode"
          *ngIf="!state.editPermisson"
          [class.active]="state.selectedArticleId === item.id"
          (click.single)="handleSelectArticle(item.id)">
          <td>
            <esg-table-text>{{ item.name }}</esg-table-text>
          </td>
          <td>
            <esg-table-button label="Details" (onClick)="state.selectArticle(item.id)"></esg-table-button>
          </td>
        </tr>
        <tr
          class="read-mode"
          *ngIf="state.editPermisson && state.editedArticle?.id !== item.id"
          [class.active]="state.selectedArticleId === item.id"
          cdkDrag
          cdkDragPreview
          [cdkDragPreviewContainer]="'parent'"
          (click.single)="handleSelectArticle(item.id)"
          (click.double)="handleEditArticle(item)">
          <td cdkDragHandle class="move-icon-cell">
            <esg-icon-move></esg-icon-move>
          </td>
          <td>
            <esg-table-text>{{ item.name }}</esg-table-text>
          </td>
          <td>
            <esg-table-text class="nowrap">{{ item.lastUpdated | date }}</esg-table-text>
          </td>
          <td>
            <esg-table-text class="nowrap">{{ item.editedBy }}</esg-table-text>
          </td>
          <td>
            <esg-table-button label="Details" (onClick)="state.selectArticle(item.id)"></esg-table-button>
          </td>
        </tr>
        <tr
          class="edit-row"
          *ngIf="state.editPermisson && state.editedArticle?.id === item.id"
          esg-on-click-outside
          (onClickOutside)="handleSubmitEdit()">
          <td></td>
          <td class="name-input">
            <esg-table-input
              class="input"
              name="articleName"
              [fc]="state.articleNameFormControl"
              (onEnter)="handleSubmitEdit()"></esg-table-input>
          </td>
          <td></td>
          <td></td>
          <td>
            <esg-table-cancel-button label="Cancel" (onClick)="handleCancelEdit()"></esg-table-cancel-button>
          </td>
        </tr>
      </ng-container>
      <tr
        class="create-row edit-permision"
        *ngIf="state.editPermisson && state.viewMode === ViewModes.Create"
        esg-on-click-outside
        (onClickOutside)="handleSubmitCreate()">
        <td></td>
        <td class="name-input">
          <esg-table-input
            class="input"
            name="articleName"
            [fc]="state.articleNameFormControl"
            (onEnter)="handleSubmitCreate()"></esg-table-input>
        </td>
        <td></td>
        <td></td>
        <td>
          <esg-table-cancel-button label="Cancel" (onClick)="handleCancelCreate()"></esg-table-cancel-button>
        </td>
      </tr>
    </tbody>
  </table>
  <esg-add-button
    *ngIf="state.editPermisson && !state.viewMode && !state.loading"
    class="add-button"
    label="Create new article"
    (onClick)="handleClickCreate($event)"></esg-add-button>

  <esg-details-dialog
    [open]="state.selectedArticleId ? true : false"
    [fade]="state.articleFade"
    [options]="userMenuProvider()"
    (onClose)="handleCloseSelectedArticle()">
    <esg-support-article-details
      *ngIf="state.selectedArticleId"
      [selectedArticleId]="state.selectedArticleId"
      [readMode]="!state.editPermisson"
      (onClose)="handleCloseSelectedArticle()"
      (onReorder)="state.onDetailsReorder($event)"></esg-support-article-details>
  </esg-details-dialog>

  <esg-confirm-dialog
    *ngIf="state.showDeleteDialog && state.selectedArticleId"
    [message]="removeArticleMessage"
    (onCancel)="handleCancelDelete()"
    (onSubmit)="handleDeleteSubmit()"></esg-confirm-dialog>
</ng-container>
