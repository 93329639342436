import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'esg-content-topbar',
  templateUrl: './content-topbar.component.html',
  styleUrls: ['./content-topbar.component.scss'],
})
export class ContentTopbarComponent implements OnInit {
  @Input() text: string = '';
  @Input() imgSrc?: SafeResourceUrl;
  @Input() showTypeIcon: boolean = true;
  @Output() onDelete = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleDelete() {
    this.onDelete.emit();
  }
}
