import { Component, OnInit } from '@angular/core';

import _ from 'lodash';
import { ContentExampleStateService } from './examples/content-example-state.service';

@Component({
  selector: 'esg-example',
  templateUrl: './example.component.html',
  styleUrls: ['./example.component.scss'],
  providers: [ContentExampleStateService],
})
export class ExampleComponent {}
