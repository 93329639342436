import { Component } from '@angular/core';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { OrganizationsStateService } from '../organizations/organizations-state.service';

@Component({
  selector: 'esg-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  providers: [OrganizationsStateService],
})
export class OrganizationsComponent {
  showOrganizationForm: boolean = false;
  showOrganizationDetails: boolean = false;
  showRemoveOrganization: boolean = false;

  removeOrganizationMessage: string = 'Once you delete this organization\nthere is no turning back.';
  removeAlertTitle: string = 'Can’t delete organization';
  removeAlertMessage: string = 'You cannot delete this organization\nbecause it has users assigned to it.';

  constructor(public state: OrganizationsStateService) {}

  handleSelectOrganization(orgId: string) {
    this.showOrganizationDetails = true;
    this.state.setOrganizationDetails(orgId);
  }

  handleFormDialogClose() {
    this.showOrganizationForm = false;
  }

  handleDialogClose() {
    this.showOrganizationForm = false;
    this.showRemoveOrganization = false;
    if (this.state.selectedOrganization) this.showOrganizationDetails = true;
  }

  handleDetailsDialogClose() {
    this.showOrganizationDetails = false;
    this.state.selectedOrganization = undefined;
  }

  handleAddOrganization() {
    this.showOrganizationForm = true;
  }

  handleEditOrganization() {
    this.showOrganizationDetails = false;
    this.showOrganizationForm = true;
  }

  handleSubmitOrganization() {
    if (this.state.selectedOrganization) {
      this.showOrganizationForm = false;
      this.state.loadOrganizations(this.state.selectedOrganization.id);
      this.state.selectedOrganization = undefined;
    }
  }

  handleDeleteOrganization() {
    this.showRemoveOrganization = true;
  }

  handleDeleteOrganizationSubmit(orgId: string | undefined) {
    if (orgId) {
      this.state.deleteOrganization(orgId);
      this.state.selectedOrganization = undefined;
      this.showOrganizationDetails = false;
      this.showRemoveOrganization = false;
    }
  }

  hasUsers(): boolean {
    if (this.state.selectedOrganization?.users && this.state.selectedOrganization.users.length > 0) return true;
    else return false;
  }

  organizationMenuProvider = (): IMenuItem[] => [
    {
      id: 'edit',
      label: 'Edit',
      onClick: () => this.handleEditOrganization(),
      disabled: !this.state.hasEditPermission,
    },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => this.handleDeleteOrganization(),
      disabled: !this.state.hasDeletePermission,
    },
  ];
}
