import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IUser } from '../users-state.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserRoleOptions, IOption } from '../../../../static-data/options';
import { UserRole } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Input() selectedUser?: IUser;
  @Input({ required: true }) organizationOptions!: IOption[];
  @Input() isSuperAdmin: boolean = false;
  @Output() onCancel = new EventEmitter();
  @Output() onAddSubmit = new EventEmitter<IUser>();
  @Output() onEditSubmit = new EventEmitter<IUser>();

  fc = {
    organizationIdControl: new FormControl<IOption | undefined>(undefined, [Validators.required]),
    firstNameFormControl: new FormControl<string>('', [Validators.required]),
    lastNameFormControl: new FormControl<string>('', [Validators.required]),
    emailFormControl: new FormControl<string>('', [Validators.required]),
    roleFormControl: new FormControl<IOption | undefined>(undefined, [Validators.required]),
  };
  formgroup = new FormGroup(this.fc);

  userRoleOptions = UserRoleOptions;

  id?: string;
  organizationId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;

  headerText = 'Create new User';
  submitText = 'Create';

  constructor() {}

  ngOnInit(): void {
    if (!this.isSuperAdmin) {
      this.userRoleOptions = this.userRoleOptions.filter(opt => opt.value !== UserRole.SuperAdmin);
    }
    if (this.organizationOptions.length === 1) {
      this.fc.organizationIdControl.setValue(this.organizationOptions[0]);
      this.fc.organizationIdControl.disable();
    }
    if (this.selectedUser) {
      this.fc.organizationIdControl.setValue(
        this.organizationOptions.find(option => option.value === this.selectedUser?.organizationId)
      );
      this.fc.firstNameFormControl.setValue(this.selectedUser?.firstName || '');
      this.fc.lastNameFormControl.setValue(this.selectedUser?.lastName || '');
      this.fc.emailFormControl.setValue(this.selectedUser?.email || '');
      this.fc.emailFormControl.disable();
      this.fc.roleFormControl.setValue(this.userRoleOptions.find(option => option.value === this.selectedUser?.role));
      this.headerText = 'Edit User';
      this.submitText = 'Save changes';
    }
  }

  handleOrganizationChange(value: IOption) {
    this.fc.organizationIdControl.markAsDirty();
    this.fc.organizationIdControl.setValue(value);
  }

  handleRoleChange(value: IOption) {
    this.fc.roleFormControl.markAsDirty();
    this.fc.roleFormControl.setValue(value);
  }

  handleSubmit() {
    if (this.selectedUser) {
      this.onEditSubmit.emit({
        id: this.selectedUser?.id,
        organizationId: this.fc.organizationIdControl.value?.value,
        organizationName: this.fc.organizationIdControl.value?.label,
        firstName: this.fc.firstNameFormControl.value || '',
        lastName: this.fc.lastNameFormControl.value || '',
        email: this.fc.emailFormControl.value || '',
        role: this.fc.roleFormControl.value?.value,
      });
    } else {
      this.onAddSubmit.emit({
        organizationId: this.fc.organizationIdControl.value?.value,
        organizationName: this.fc.organizationIdControl.value?.label,
        firstName: this.fc.firstNameFormControl.value || '',
        lastName: this.fc.lastNameFormControl.value || '',
        email: this.fc.emailFormControl.value || '',
        role: this.fc.roleFormControl.value?.value,
      });
    }
  }
}
