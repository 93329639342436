import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IOption } from 'src/app/static-data/options';
import { OrganizationsStateService } from '../organizations-state.service';

@Component({
  selector: 'esg-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent implements OnInit {
  @Input() countryOptions: IOption[] = [];
  @Output() onOrganizationClick = new EventEmitter<string>();

  alertTitle: string = 'Can’t delete sub organization';
  alertMessage: string =
    'You can’t delete this Sub Organization\n because it has data connected to it.\n\n If you want to remove this Sub Organization\n you must delete all data connected to it\n in Data Collect before you delete it.';
  alertButtonText: string = 'Got it';

  constructor(public state: OrganizationsStateService) {}

  ngOnInit(): void {}

  handleOrganizationClick(orgId: string | undefined) {
    if (orgId) {
      this.onOrganizationClick.emit(orgId);
    }
  }
}
