import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'esg-table-button [onClick]',
  templateUrl: './table-button.component.html',
  styleUrls: ['./table-button.component.scss'],
})
export class TableButtonComponent implements OnInit {
  @Input({ required: true }) label!: string;
  @Output() onClick = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  handleClick(event: Event) {
    event.stopPropagation();
    this.onClick.emit();
  }
}
