import { IOption } from '../../../../shared/ui/dropdown-multiple/dropdown-multiple-form.component';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IUploadPhotoComponentTexts, IUploadedPhoto } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { ITimePeriodData } from '../../../../shared/components/time-period/time-period.component';
import { FormControl } from '@angular/forms';
import { ReportFormStateService } from '../report-form-state.service';
import { ReportVersion, TimePeriodType } from 'src/api-client/report-api.generated';
import { ITreeOption } from 'src/app/shared/ui/select/select-tree-options/select-tree-options.component';

export interface ReportDataState {
  id?: string;
  title: string;
  pdfWritingSystem?: IOption;
  timePeriod?: ITimePeriodData;
  coverPhoto?: IUploadedPhoto;
}

export interface IReportDataUi {
  title: FormControl<string>;
  reportScope: FormControl<ITreeOption | null>;
  inludeSubOrgs: FormControl<boolean>;
  pdfWritingSystem: FormControl<IOption | null>;
  timePeriod: FormControl<ITimePeriodData | null>;
  coverPhoto: FormControl<IUploadedPhoto | null>;
  coverPhotoSquare: FormControl<IUploadedPhoto | null>;
}

export interface TemplateDataState {
  templateId: string;
  title: string;
  timePeriod?: ITimePeriodData;
  coverPhoto?: IUploadedPhoto;
}

@Component({
  selector: 'esg-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss'],
})
export class EditFormComponent implements OnInit {
  reportVersionEnum = ReportVersion;
  timePeriodTypeEnum = TimePeriodType;
  submitted = false;
  edited = false;
  @Input() reportId?: string;
  @Input() isLoading = true;
  @Output() onBackBtnClick = new EventEmitter();
  @Output() onAddReportBtnClick = new EventEmitter();
  @Output() onEditReportBtnClick = new EventEmitter();
  @Output() onUplodCoverPhoto = new EventEmitter<File>();
  @Output() onSubmit = new EventEmitter();

  @ViewChild('main') public main!: ElementRef<HTMLElement>;

  componentTextsForHtml: IUploadPhotoComponentTexts = {
    firstLineP1: 'Drag and drop a logo here or ',
    firstLineP2: ' a photo',
    firstLineP1UploadedPhoto: 'Drag and drop or ',
    firstLineP2UploadedPhoto: ' to replace photo',
    secondLine:
      'Photo must be PNG or JPG. We recommend to use a wide screen format with the minimum size of 1600px width x 660px height.',
  };

  showStickyDivider = false;

  constructor(public hostRef: ElementRef, public state: ReportFormStateService) {}

  ngOnInit(): void {}

  handleWritingSystem(option: IOption) {
    this.state.fc.pdfWritingSystem.setValue(option);
    this.state.fc.pdfWritingSystem.markAsDirty();
  }

  handleTitleChange(value: string) {
    this.state.fc.title.setValue(value);
    this.state.fc.title.markAsDirty();
  }

  handleReportScopeChange(value: ITreeOption | null) {
    this.state.fc.reportScope.setValue(value);
    this.state.fc.reportScope.markAsDirty();
    if (value?.children?.length) {
      this.state.fc.inludeSubOrgs.setValue(true);
      this.state.fc.inludeSubOrgs.enable();
    } else {
      this.state.fc.inludeSubOrgs.setValue(false);
      this.state.fc.inludeSubOrgs.disable();
    }
  }

  handleIncludeSubOrgsChange() {
    this.state.fc.inludeSubOrgs.setValue(!this.state.fc.inludeSubOrgs.value);
    this.state.fc.inludeSubOrgs.markAsDirty();
  }

  handleTimePeriodChange(timePeriodData: ITimePeriodData) {
    if (timePeriodData.type === TimePeriodType.Custom && (!timePeriodData.customStart || !timePeriodData.customEnd)) {
      this.state.fc.timePeriod.setValue(null);
    } else {
      this.state.fc.timePeriod.setValue(timePeriodData);
    }
    this.state.fc.timePeriod.markAsDirty();
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.rootBounds?.bottom) {
      this.showStickyDivider = tabEntry.boundingClientRect.bottom > tabEntry.rootBounds.bottom;
    } else this.showStickyDivider = false;
  }

  async submitAddReport() {
    if (this.submitted) return;
    this.submitted = true;
    await this.state.saveReportFromTemplate();
    this.onSubmit.emit();
  }

  async submitEditReport() {
    if (this.edited) return;
    this.edited = true;
    await this.state.editReport();
    this.onSubmit.emit();
  }
}
