<esg-form-dialog
  [submitText]="submitText"
  [submitDisabled]="!state.formgroup.valid || !state.formgroup.dirty"
  (onCancel)="handleDialogClose()"
  (onSubmit)="handleSubmit()">
  <header>{{ headerText }}</header>
  <div class="content">
    <header>1. Details</header>
    <div class="organization-details">
      <esg-textbox-form label="ORGANIZATION NAME" placeholder="Company Ltd." [fc]="state.fc.name"></esg-textbox-form>
      <esg-textbox-form
        label="ORGANIZATION ID"
        placeholder="NO 123 456 780 ABC"
        [fc]="state.fc.orgID"></esg-textbox-form>
      <esg-select
        label="COUNTRY"
        [options]="countryOptions"
        [value]="state.fc.countryCode.value || undefined"
        [isPopup]="true"
        (onChange)="state.handleCountryChange($event)"></esg-select>
      <esg-select
        label="CURRENCY"
        [options]="state.currencyOptions"
        [favouriteOptions]="state.currencyFavouriteOptions"
        [value]="state.fc.currencyCode.value || undefined"
        [isPopup]="true"
        (onChange)="state.handleCurrencyChange($event)"></esg-select>
    </div>
    <header>2. Visual preferences for report presentation</header>
    <div class="colors">
      <esg-textbox-form
        label="BRAND COLOR"
        type="color"
        [valid]="state.fc.brandColor.valid"
        [placeholder]="state.fc.brandColor.value || '#4AD1DA'"
        errorMessage="Invalid color value. Please insert a valid
        hex code, starting with hashtag (#) and
        followed by six letters/numbers."
        [fc]="state.fc.brandColor"
        (onChange)="state.onColorChange(state.fc.brandColor, $event)"></esg-textbox-form>
      <esg-textbox-form
        label="TABLE HEAD COLOR"
        type="color"
        [valid]="state.fc.tableHeadColor.valid"
        [placeholder]="state.fc.tableHeadColor.value || '#BEDDE0'"
        errorMessage="Invalid color value. Please insert a valid
        hex code, starting with hashtag (#) and
        followed by six letters/numbers."
        [fc]="state.fc.tableHeadColor"
        (onChange)="state.onColorChange(state.fc.tableHeadColor, $event)"></esg-textbox-form>
    </div>
    <div class="upload-picture">
      <span>Logo for pdf report</span>
      <esg-upload-picture
        accepted="image/png,image/svg+xml"
        [uploadedPhotos]="state.fc.logo.value ? [state.fc.logo.value] : []"
        [texts]="componentTexts"
        (onSelectFile)="state.uploadLogo($event)"
        (onClearUploadedPhoto)="handleRemoveLogo()"></esg-upload-picture>
    </div>
    <div class="upload-picture">
      <span>Logo for website report (square only)</span>
      <esg-upload-picture
        accepted="image/png,image/svg+xml"
        [uploadedPhotos]="state.fc.logoSquare.value ? [state.fc.logoSquare.value] : []"
        [texts]="componentTextsSquareUpload"
        (onSelectFile)="state.uploadLogoSquare($event)"
        (onClearUploadedPhoto)="handleRemoveLogoSquare()"></esg-upload-picture>
    </div>
  </div>
</esg-form-dialog>
<esg-confirm-dialog
  *ngIf="state.showCurrencyWarning"
  [message]="
    'Changing the currency will affect all your data with monetary values. Existing data will not be recalculated into the new currency. \n\nDo you want to proceed?'
  "
  submitText="Yes"
  cancelText="No, leave as is"
  (onSubmit)="handleCurrencyWarningConfirm()"
  (onCancel)="handleCurrencyWarningClose()"></esg-confirm-dialog>
