<div esg-on-click-outside (onClickOutside)="handleClickOutside()">
  <div class="content-container" (click)="handleOpen()">
    <ng-content></ng-content>
  </div>
  @if(open){
  <div class="options-container">
    <div class="options">
      @for (item of options; track item.value) {
      <div class="option" (click)="handleSelectOption(item)">
        <esg-checkmark [class.selected]="item.value === value?.value"></esg-checkmark>
        <span class="option-label">{{ item.label }}</span>
      </div>
      }
    </div>
  </div>
  }
</div>
