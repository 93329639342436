import { Content, TableCell } from 'pdfmake/interfaces';
import { IImportFromExcelContentData } from 'src/app/content/content-item/import-from-excel/import-from-excel.component';
import { TableDataConfig } from 'src/app/shared/components/table-data/table-data.component';

export async function importFromExcelTable(content: IImportFromExcelContentData): Promise<Content> {
  if (!content?.tableConfig || !content?.rows) return { text: '' };
  const numberOfColumns = content.tableConfig.headers.length;
  const rightColumnsWidth = numberOfColumns > 1 ? Array(numberOfColumns - 1).fill('auto') : [];
  return Promise.resolve({
    columns: [
      {
        table: {
          headerRows: 1,
          widths: ['*'].concat(rightColumnsWidth),
          body: [
            headerColumns(content.tableConfig),
            ...content.rows.map(row => {
              return rowsColumns(row, numberOfColumns);
            }),
          ],
        },
        margin: [122, 0, 14, 0],
        layout: {
          hLineColor: '#CCCCCC',
          vLineColor: '#CCCCCC',
          hLineWidth: function () {
            return 0.5;
          },
          vLineWidth: function () {
            return 0.5;
          },
        },
      },
    ],
  });
}

function headerColumns(tableConfig: TableDataConfig) {
  return tableConfig.headers.map<TableCell>((h, index) => {
    return {
      text: h.label,
      border: [false, false, false, false],
      style: 'excelHeader',
      margin: [0, 4, 0, 0],
      alignment: index !== 0 ? 'right' : undefined,
    };
  });
}

function rowsColumns(rowColumns: string[], numberOfColumns: number): TableCell[] {
  for (let i = rowColumns.length; i < numberOfColumns; i++) {
    rowColumns.push('');
  }

  return rowColumns.map<TableCell>((columnValue, index) => {
    var rowCell: TableCell = {
      text: columnValue ?? ' ',
      border: [false, true, false, true],
      style: 'excelBody',
      margin: [0, 4, 0, 0],
      alignment: index !== 0 ? 'right' : undefined,
    };
    return rowCell;
  });
}
