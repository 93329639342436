import { Type } from '@angular/core';
import { IDynamicContentData } from './dynamic-content-data';
import {
  IPictureContentActions,
  IPictureContentData,
  IPictureContentStates,
  PictureComponent,
} from './content-item/picture/picture.component';
import {
  ITextAreaContentActions,
  ITextAreaContentData,
  ITextAreaContentStates,
  TextAreaComponent,
} from './content-item/text-area/text-area.component';
import {
  IImportFromExcelContentActions,
  IImportFromExcelContentData,
  IImportFromExcelContentStates,
  ImportFromExcelComponent,
} from './content-item/import-from-excel/import-from-excel.component';
import {
  IIndicatorContentActions,
  IIndicatorContentData,
  IIndicatorContentStates,
  IndicatorContentComponent,
} from './content-item/indicator-content/indicator-content.component';

export enum ContentTypes {
  Text = 'Text',
  Picture = 'Picture',
  ImportFromExcel = 'ImportFromExcel',
  Indicator = 'Indicator',
}

export type ContentActionsTypes =
  | IPictureContentActions
  | ITextAreaContentActions
  | IImportFromExcelContentActions
  | IIndicatorContentActions;
export type ContentStatesTypes =
  | IPictureContentStates
  | ITextAreaContentStates
  | IImportFromExcelContentStates
  | IIndicatorContentStates;
export type ContentDataTypes =
  | IPictureContentData
  | ITextAreaContentData
  | IImportFromExcelContentData
  | IIndicatorContentData;

export const ContentConfigurations: {
  [key: string]: any;
} = {
  [ContentTypes.Text]: TextAreaComponent,
  [ContentTypes.Picture]: PictureComponent,
  [ContentTypes.ImportFromExcel]: ImportFromExcelComponent,
  [ContentTypes.Indicator]: IndicatorContentComponent,
};

export function ComponentFactory(
  type: ContentTypes
): Type<IDynamicContentData<ContentActionsTypes, ContentStatesTypes, ContentDataTypes>> {
  const component = ContentConfigurations[type];
  if (!component) {
    throw Error('lacking component configuration for compoent type: ' + type);
  }
  return component;
}
