import { Injectable } from '@angular/core';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { DataCategoriesUi } from './data-collect-categories.component';
import { DataCategoryToMainTopic } from 'src/app/static-data/enum-mappings';
import * as _ from 'lodash';

@Injectable()
export class DataCollectCategoriesStateService {
  categories: DataCategoriesUi[] = [];
  selectedCategory?: string;
  loading: boolean = false;

  constructor(private dataCategoryApiService: DataCategoryApiService) {
    this.init();
  }

  async init() {
    this.loading = true;
    await this.getCategories();
    this.loading = false;
  }

  async getCategories() {
    const response = await this.dataCategoryApiService.getAllDataCategories();
    this.categories = _.sortBy(response.result, [pi => pi.name.toLowerCase()]).map<DataCategoriesUi>(category => ({
      dataRecordCategory: category.dataRecordCategory,
      name: category.name,
      mainTopic: DataCategoryToMainTopic[category.dataRecordCategory],
      lastUpdated: category.lastUpdated,
      editedBy: category.editedBy,
    }));
  }

  selectCategory(dataRecordCategory: string) {
    this.selectedCategory = dataRecordCategory;
  }

  closeSelectedCategory() {
    this.getCategories();
    this.selectedCategory = undefined;
  }
}
