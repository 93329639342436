import {
  AgeGroup,
  AssetType,
  DataRecordCategory,
  DataRecordType,
  EmploymentType,
  PositionLevel,
  VehicleCraftMachineryType,
} from 'src/api-client/report-api.generated';

export const AssetCategoryLabel: { [key in string]: string } = {
  [AssetType.Organization]: 'Organizations',
  [AssetType.Facility]: 'Facilities',
  [AssetType.Vessel]: 'Vessels',
  [AssetType.VehicleCraftMachinery]: 'Vehicles, Crafts & Machinery',
  [AssetType.Supplier]: 'Suppliers',
  [AssetType.Client]: 'Clients',
};

export const VehicleCraftMachineryTypeLabel: { [key in string]: string } = {
  [VehicleCraftMachineryType.Car]: 'Cars',
  [VehicleCraftMachineryType.Truck]: 'Trucks',
  [VehicleCraftMachineryType.Forklift]: 'Forklifts',
  [VehicleCraftMachineryType.SmallCrafts]: 'Small Crafts',
  [VehicleCraftMachineryType.Cranes]: 'Cranes',
  [VehicleCraftMachineryType.Other]: 'other',
};

export const DataCategoryToMainTopic: { [key in string]: string } = {
  [DataRecordCategory.DirectEmissionFuelCombustion]: 'Direct Emission',

  [DataRecordCategory.IndirectEmissionsElectricity]: 'Indirect Emission',
  [DataRecordCategory.IndirectEmissionsBusinessTravel]: 'Indirect Emission',
  [DataRecordCategory.IndirectEmissionsWaste]: 'Indirect Emission',
  [DataRecordCategory.IndirectEmissionsEmployeeCommuting]: 'Indirect Emission',
  [DataRecordCategory.IndirectEmissionsTransportAndDistribution]: 'Indirect Emission',
  [DataRecordCategory.IndirectEmissionsLeasedAssets]: 'Indirect Emission',
  [DataRecordCategory.IndirectEmissionsPurchasedGoodsAndServices]: 'Indirect Emission',
  [DataRecordCategory.IndirectEmissionsCapitalGoods]: 'Indirect Emission',
  [DataRecordCategory.IndirectEmissionsFuelAndEnergyRelatedActivities]: 'Indirect Emission',

  [DataRecordCategory.EmbeddedEmissionsLeasedAssets]: 'Embedded Emission',
  [DataRecordCategory.EmbeddedEmissionsTransportationAndDistribution]: 'Embedded Emission',
  [DataRecordCategory.EmbeddedEmissionsProcessingOfSoldProducts]: 'Embedded Emission',
  [DataRecordCategory.EmbeddedEmissionsUseOfSoldProducts]: 'Embedded Emission',
  [DataRecordCategory.EmbeddedEmissionsEndOfLifeTreatment]: 'Embedded Emission',
  [DataRecordCategory.EmbeddedEmissionsFranchises]: 'Embedded Emission',
  [DataRecordCategory.EmbeddedEmissionsInvestments]: 'Embedded Emission',

  [DataRecordCategory.BiodiversitySpillsToEnvironment]: 'Biodiversity',
  [DataRecordCategory.BiodiversityProtectedAreas]: 'Biodiversity',

  [DataRecordCategory.EmployeesGenderEqualityAndDiversity]: 'Employees',
  [DataRecordCategory.EmployeesNewHiresAndTurnovers]: 'Employees',
  [DataRecordCategory.EmployeesLeavesAndInjuries]: 'Employees',

  [DataRecordCategory.BusinessEthicsCorruptionRisk]: 'Business Ethics',
  [DataRecordCategory.BusinessEthicsFines]: 'Business Ethics',
  [DataRecordCategory.BusinessEthicsLobbyPoliticalContribution]: 'Business Ethics',
  [DataRecordCategory.BusinessEthicsWhistleblowing]: 'Business Ethics',
};

export const RecordCategoryLabel: { [key in string]: string } = {
  [DataRecordCategory.DirectEmissionFuelCombustion]: 'Fuel Combustion',

  [DataRecordCategory.IndirectEmissionsBusinessTravel]: 'Business Travel',
  [DataRecordCategory.IndirectEmissionsElectricity]: 'Electricity',
  [DataRecordCategory.IndirectEmissionsEmployeeCommuting]: 'Employee Commuting',
  [DataRecordCategory.IndirectEmissionsWaste]: 'Waste',

  [DataRecordCategory.EmbeddedEmissionsLeasedAssets]: 'Leased Assets',

  [DataRecordCategory.BiodiversityProtectedAreas]: 'Marine Protected Areas',
  [DataRecordCategory.BiodiversitySpillsToEnvironment]: 'Spills to Environment',

  [DataRecordCategory.EmployeesGenderEqualityAndDiversity]: 'Gender, Position and Age Profiles',
  [DataRecordCategory.EmployeesParentalLeave]: 'Parental Leave',
  [DataRecordCategory.EmployeesSickLeave]: 'Sick Leave',
  [DataRecordCategory.EmployessWorkRelatedInjuries]: 'Work Related Injuries',
  [DataRecordCategory.EmployessMarineCasualties]: 'Marine Casualties',
  [DataRecordCategory.EmployeesNewHiresAndTurnovers]: 'New Hires & Turnover',

  [DataRecordCategory.BusinessEthicsCorruptionRisk]: 'Corruption Risk',
  [DataRecordCategory.BusinessEthicsFines]: 'Fines',
  [DataRecordCategory.BusinessEthicsLobbyPoliticalContribution]: 'Lobby/Political Contribution',
  [DataRecordCategory.BusinessEthicsWhistleblowing]: 'Whistleblowing',
};

export const RecordSubCategoryLabel: { [key in string]: string } = {
  [DataRecordType.VesselConsumption]: 'Vessels',
  [DataRecordType.VesselEmissions]: 'Vessels (Imported)',
  [DataRecordType.VehicleConsumption]: 'Vehicles, Crafts & Machinery',

  [DataRecordType.OrganizationBusinessAirTravel]: 'Air Travel',
  [DataRecordType.OrganizationBusinessTravelHotelNights]: 'Hotel Nights',
};

export const RecordCategoryPath: { [key in string]: string } = {
  [DataRecordCategory.DirectEmissionFuelCombustion]: 'fuel-combustion',

  [DataRecordCategory.IndirectEmissionsBusinessTravel]: 'business-travel',
  [DataRecordCategory.IndirectEmissionsElectricity]: 'electricity',
  [DataRecordCategory.IndirectEmissionsEmployeeCommuting]: 'employee-commuting',
  [DataRecordCategory.IndirectEmissionsWaste]: 'waste',

  [DataRecordCategory.EmbeddedEmissionsLeasedAssets]: 'leased-assets',

  [DataRecordCategory.BiodiversitySpillsToEnvironment]: 'spills-to-environment',
  [DataRecordCategory.BiodiversityProtectedAreas]: 'marine-protected-areas',

  [DataRecordCategory.EmployeesGenderEqualityAndDiversity]: 'gender-diversity-equality',
  [DataRecordCategory.EmployeesParentalLeave]: 'parental-leave',
  [DataRecordCategory.EmployeesSickLeave]: 'sick-leave',
  [DataRecordCategory.EmployessWorkRelatedInjuries]: 'work-related-injuries',
  [DataRecordCategory.EmployessMarineCasualties]: 'marine-casualties',
  [DataRecordCategory.EmployeesNewHiresAndTurnovers]: 'new-hires-and-turnovers',

  [DataRecordCategory.BusinessEthicsCorruptionRisk]: 'corruption-risk',
  [DataRecordCategory.BusinessEthicsFines]: 'fines',
  [DataRecordCategory.BusinessEthicsLobbyPoliticalContribution]: 'lobby-political-contribution',
  [DataRecordCategory.BusinessEthicsWhistleblowing]: 'whistleblowing',
};

export const RecordSubCategoryPath: { [key in string]: string } = {
  [DataRecordType.VesselConsumption]: 'vessels',
  [DataRecordType.VehicleConsumption]: 'vehicles-crafts-machinery',

  [DataRecordType.OrganizationBusinessAirTravel]: 'air-travel',
  [DataRecordType.OrganizationBusinessTravelHotelNights]: 'hotel-nights',
};

export const AgeGroupLabel: { [key in string]: string } = {
  [AgeGroup.LessThan30]: 'Age <30',
  [AgeGroup.Between30_50]: 'Age 30 - 50',
  [AgeGroup.Over50]: 'Age 50>',
};

export const EmployementTypeLabel: { [key in string]: string } = {
  [EmploymentType.PermanentFullTime]: 'Permanent Full time',
  [EmploymentType.PermanentPartTime]: 'Permanent Part Time',
  [EmploymentType.Contract]: 'Contractors',
};

export const PositionLevelLabel: { [key in string]: string } = {
  [PositionLevel.BoardMember]: 'Board Member',
  [PositionLevel.PositionLevel1]: 'Position Level 1',
  [PositionLevel.PositionLevel2]: 'Position Level 2',
  [PositionLevel.PositionLevel3]: 'Position Level 3',
  [PositionLevel.PositionLevel4]: 'Position Level 4',
  [PositionLevel.PositionLevel5]: 'Position Level 5',
  [PositionLevel.PositionLevel6]: 'Position Level 6',
};
