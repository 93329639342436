import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TopicItemContentUi } from '../topic-item-details/topic-item-details-state.service';
import { TextDisclosureFormStateService } from './text-disclosure-form-state.service';

@Component({
  selector: 'esg-text-disclosure-form',
  templateUrl: './text-disclosure-form.component.html',
  styleUrls: ['./text-disclosure-form.component.scss'],
  providers: [TextDisclosureFormStateService],
})
export class TextDisclosureFormComponent implements OnInit {
  @Input({ required: true }) reportId!: string;
  @Input({ required: true }) mainLevelId!: string;
  @Input({ required: true }) topicId!: string;
  @Input() selectedTopicItem?: TopicItemContentUi;

  @Output() onCancel = new EventEmitter();
  @Output() onAddSubmit = new EventEmitter();
  @Output() onEditSubmit = new EventEmitter();

  constructor(public state: TextDisclosureFormStateService) {}

  ngOnInit(): void {
    this.state.initForm(this.selectedTopicItem);
  }

  handleFormSubmit() {
    this.state
      .submitForm(this.reportId, this.mainLevelId, this.topicId, this.selectedTopicItem?.id)
      .then(() => (this.selectedTopicItem ? this.onEditSubmit.emit() : this.onAddSubmit.emit()));
  }
}
