import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-menu-icon',
  template: `
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="2" rx="1" attr.fill="{{ color }}" />
      <rect x="5" y="7" width="19" height="2" rx="1" attr.fill="{{ color }}" />
      <rect y="14" width="24" height="2" rx="1" attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class MenuIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
