<div
  tabindex="0"
  class="container"
  [class.disabled]="disabled"
  [ngClass]="fc.errors ? 'not-valid' : ''"
  esg-on-click-outside
  (onClickOutside)="closeOptions()">
  <div class="selected-option" (click)="toggleOpenOptions()">
    <div>
      {{ fc.value?.label }}
    </div>
    <esg-arrow-down></esg-arrow-down>
  </div>
  <div class="options" *ngIf="open">
    <div class="option" *ngFor="let option of options" (click)="handleSelectOption(option)">
      <div>{{ option.label }}</div>
      <esg-checkmark [style.visibility]="option.value === fc.value?.value ? 'visible' : 'hidden'"></esg-checkmark>
    </div>
  </div>
</div>
