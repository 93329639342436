import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IOption, ProfileInputOptions } from 'src/app/static-data/options';
import { GenderProfileForm, GenderProfileUi } from '../gender-diversity-equality-state.service';
import { timePeriodToDates } from 'src/app/static-data/time-labels';
import { GenderDiversityEqualityDetailsRecordsStateService } from './gender-diversity-equality-details-state.service';
import { CurrencyCodes } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-gender-diversity-equality-details',
  templateUrl: './gender-diversity-equality-details.component.html',
  styleUrls: ['./gender-diversity-equality-details.component.scss'],
  providers: [GenderDiversityEqualityDetailsRecordsStateService],
})
export class GenderDiversityEqualityDetailsComponent {
  @Input({ required: true }) profile!: GenderProfileUi;
  @Input({ required: true }) formgroup!: FormGroup<GenderProfileForm>;
  @Input() currencyCode: string = CurrencyCodes.USD;
  @Input() assetOptions: IOption[] = [];
  @Output() onUpdate = new EventEmitter();

  inputOptions: IOption[] = ProfileInputOptions;
  startDate?: Date;
  endDate?: Date;

  constructor(public state: GenderDiversityEqualityDetailsRecordsStateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profile && changes.profile.currentValue) {
      [this.startDate, this.endDate] = timePeriodToDates(changes.profile.currentValue.timePeriod);
    }
  }

  handleDropdownChange(formControl: FormControl<IOption | undefined>, option: IOption) {
    if (option !== formControl.value) {
      formControl.setValue(option);
      this.onUpdate.emit();
    }
  }
}
