import { Component, Input } from '@angular/core';
import { IDataSeriesUi } from 'src/app/shared/components/chart/types';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';

export interface indicatorGraphViewData {
  valueSeriesArray: IDataSeriesUi[];
  targetSeries: IDataSeriesUi;
}

@Component({
  selector: 'esg-indicator-graph-view',
  templateUrl: './indicator-graph-view.component.html',
  styleUrl: './indicator-graph-view.component.scss',
})
export class IndicatorGraphViewComponent {
  @Input({ required: true }) name!: string;
  @Input({ required: true }) graphData!: indicatorGraphViewData;
  @Input() unit: string = '';
  @Input() isAnnual: boolean = true;
  chart?: Highcharts.Chart;

  handleChartInstanceHandler(chart?: Highcharts.Chart) {
    this.chart = chart;
  }

  contextMenuOptionsProvider = (): IMenuItem[] => [
    {
      id: '2',
      label: 'Export as PNG',
      onClick: () => {
        this.exportToPng();
      },
    },
    {
      id: '3',
      label: 'Export as SVG',
      onClick: () => {
        this.exportToSvg();
      },
    },
  ];

  exportToPng = () => {
    if (this.chart) {
      this.chart.exportChart(
        {
          type: 'image/png',
          filename: this.name,
        },
        {
          chart: {
            backgroundColor: 'white',
          },
        }
      );
    } else {
      console.error('Chart is not initialized');
    }
  };

  exportToSvg = () => {
    if (this.chart) {
      this.chart.exportChart(
        {
          type: 'image/svg+xml',
          filename: this.name,
        },
        {
          chart: {
            backgroundColor: 'white',
          },
        }
      );
    } else {
      console.error('Chart is not initialized');
    }
  };
}
