import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportShareDialogStateService } from './report-share-dialog-state.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

@Component({
  selector: 'esg-report-share-dialog',
  templateUrl: './report-share-dialog.component.html',
  styleUrl: './report-share-dialog.component.scss',
})
export class ReportShareDialogComponent implements OnInit {
  @Input({ required: true }) reportId!: string;
  @Output() onClose = new EventEmitter();
  constructor(public state: ReportShareDialogStateService, private notification: NotificationService) {}

  ngOnInit(): void {
    this.state.init(this.reportId);
  }

  copyText(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    // Optional: Display some notification that the text was copied
    this.notification.showSuccess('Report URL copied!');
  }
}
