import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'esg-letter-avatar',
  templateUrl: './letter-avatar.component.html',
  host: {
    '[style.background-color]': 'background',
    '[style.color]': 'color',
  },
  styleUrls: ['./letter-avatar.component.scss'],
})
export class LetterAvatarComponent implements OnInit {
  @Input() label: string = '';
  @Input() background: string = '#4AD1DA';
  @Input() color: string = '#333333';

  constructor() {}

  ngOnInit(): void {}
}
