import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AssetType,
  UpdateVehicleCraftMachineryAssetCommandParams,
  VehicleCraftMachineryAsset,
  PowerSource,
  VehicleCraftMachineryType,
} from 'src/api-client/report-api.generated';
import { IOption, powerSourceOptions } from 'src/app/static-data/options';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { AssetDetailsForm, AssetDetailsStateService } from '../../asset-details-state.service';

interface VehicleCraftMachineryDetailsForm extends AssetDetailsForm {
  registrationNumber: FormControl<string>;
  modelYear: FormControl<string>;
  powerSource: FormControl<IOption | undefined>;
}

@Injectable()
export class VehicleCraftMachineryDetailsStateService extends AssetDetailsStateService {
  powerSourceOptions: IOption[] = powerSourceOptions;
  subType?: VehicleCraftMachineryType;

  fc?: VehicleCraftMachineryDetailsForm;

  async init(assetId: string, organizationOptions: IOption[]) {
    this.loading = true;
    this.assetId = assetId;
    this.organizationOptions = organizationOptions;
    await Promise.all([this.getAssetVersions(), this.getRecordsOverview()]);
    this.loading = false;
  }

  async getAssetVersion(versionId: string) {
    const response = await this.assetApiService.getAssetById(this.assetId, versionId);
    const asset = response.result as VehicleCraftMachineryAsset;
    this.mainOrganizationId = asset.organizationId;
    this.subType = asset.subType;

    this.fc = {
      versionValidFrom: new FormControl<Date>(asset.versionValidFrom, { nonNullable: true }),
      name: new FormControl<string>(asset.name, { validators: [Validators.required], nonNullable: true }),
      organization: new FormControl<IOption | undefined>(
        this.organizationOptions?.find(org =>
          asset?.subOrganizationId ? org.value === asset?.subOrganizationId : org.value === asset?.organizationId
        ),
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      modelYear: new FormControl<string>(asset.modelYear?.toString() || '', { nonNullable: true }),
      registrationNumber: new FormControl<string>(asset.registrationNumber || '', { nonNullable: true }),
      powerSource: new FormControl<IOption | undefined>(
        this.powerSourceOptions.find(opt => opt.value === asset.powerSource),
        { validators: [Validators.required], nonNullable: true }
      ),
    };

    this.formgroup = new FormGroup(this.fc);
  }

  async updateAsset(versionId: string) {
    const powerSource = Object.values(PowerSource).find(type => type === this.fc?.powerSource.value?.value);
    if (this.fc && powerSource && this.subType) {
      return await this.assetApiService.updateAsset(
        new UpdateVehicleCraftMachineryAssetCommandParams({
          id: this.assetId,
          type: AssetType.VehicleCraftMachinery,
          subType: this.subType,
          name: this.fc.name.value,
          versionId: versionId,
          subOrganizationId:
            this.fc.organization.value?.value === this.mainOrganizationId
              ? undefined
              : this.fc.organization.value?.value,
          registrationNumber: this.fc.registrationNumber.value || undefined,
          modelYear: this.fc.modelYear.value ? formattedStringToNumber(this.fc.modelYear.value) : undefined,
          powerSource: powerSource,
          versionValidFrom: this.fc.versionValidFrom.value,
        })
      );
    } else return undefined;
  }
}
