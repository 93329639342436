<div class="container" *ngIf="isUnknown || (score && score >= 0 && score <= 100)">
  <div class="first-line">
    <div class="score">
      <div class="line" [style.width.%]="score" [class.unknown]="isUnknown">
        <span class="line-label">
          <span class="value" [style.color]="riskLabel.color">{{ isUnknown ? riskLabel.label : score }}</span>
          <span *ngIf="!isUnknown" class="total">/100</span>
        </span>
      </div>
    </div>
  </div>
  <div class="second-line">
    <div class="progress-bar">
      <div
        *ngIf="!isUnknown"
        class="progress"
        [class.max]="score === 100"
        [style.backgroundColor]="riskLabel.color"
        [style.width.%]="score"></div>
    </div>
  </div>
  <div class="third-line">
    <div class="risk-label" [style.width.%]="10">
      <span class="range">&lt; 10</span>
      <span class="label">VERY HIGH RISK</span>
    </div>
    <div class="risk-label" [style.width.%]="25">
      <span class="range">11 - 35</span>
      <span class="label">HIGH RISK</span>
    </div>
    <div class="risk-label" [style.width.%]="40">
      <span class="range">36 - 75</span>
      <span class="label">MEDIUM RISK</span>
    </div>
    <div class="risk-label" [style.width.%]="15">
      <span class="range">76 - 90</span>
      <span class="label">LOW RISK</span>
    </div>
    <div class="risk-label" [style.width.%]="10">
      <span class="range">91 &gt;</span>
      <span class="label">NO RISK</span>
    </div>
  </div>
</div>
