import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TextboxComponent } from './textbox.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'esg-textbox-form',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxFormComponent extends TextboxComponent {
  @Input({ required: true }) fc!: FormControl<string | null>;
  @Input() valid?: boolean = undefined;

  private valueChangesSubscription: Subscription | undefined;

  get getValue() {
    return this.fc.value || '';
  }

  get isRequired() {
    return this.fc.hasValidator(Validators.required);
  }

  get isValid() {
    return this.valid !== undefined ? this.valid : !this.fc.errors;
  }

  get isDisabled() {
    return this.fc.disabled;
  }

  ngOnInit() {
    super.ngOnInit();
    this.fc.markAsPristine();
    this.valueChangesSubscription = this.fc.valueChanges.subscribe(value => {
      this.setElementValue(value || '');
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fc) {
      this.initialValue = this.getValue;
    }
  }

  resetValue(): void {
    if (this.initialValue === this.fc.defaultValue) {
      this.fc.reset();
    } else {
      this.setValue(this.initialValue);
    }
  }

  setValue(value: string) {
    this.fc.setValue(value);
    if (value !== this.fc.defaultValue && this.fc.pristine) {
      this.fc.markAsDirty();
    }
  }

  setElementValue(value: string) {
    if (this.inputEl instanceof HTMLInputElement) {
      this.inputEl.value = value;
    }
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
