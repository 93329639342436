import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-section-dot-flashing',
  templateUrl: './section-dot-flashing.component.html',
  styleUrls: ['./section-dot-flashing.component.scss'],
})
export class SectionDotFlashingComponent {
  @Input() active? = false;
  @Input() label?: string;
}
