import { Component, Input } from '@angular/core';
import { IndicatorDefinitionDetailsStateService } from './indicator-definition-details-state.service';
import { FormatUnitWithUnicodePipe } from 'src/app/shared/pipes/format-unit-with-unicode.pipe';

@Component({
  selector: 'esg-indicator-definition-details',
  templateUrl: './indicator-definition-details.component.html',
  styleUrl: './indicator-definition-details.component.scss',
  providers: [FormatUnitWithUnicodePipe, IndicatorDefinitionDetailsStateService],
})
export class IndicatorDefinitionDetailsComponent {
  @Input({ required: true }) selectedIndicatorId!: string;

  constructor(public state: IndicatorDefinitionDetailsStateService) {}

  ngOnInit(): void {
    this.state.init(this.selectedIndicatorId);
  }

  onTableSetupClick() {
    this.state.openTableDialog();
  }

  onTableSetupClose() {
    this.state.closeTableDialog();
  }
}
