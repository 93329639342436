<div class="summary-column">
  <span class="summary-label">
    {{ isAverage ? 'Average (Mean)' : 'Total' }}
    <span class="summary-interval">{{ yearLabel }}</span>
  </span>
  <span class="summary-value">
    {{ getFormattedNumber(summary.value) || '-' }}
    <span class="summary-unit" [innerHTML]="(unit === 'Number' ? '' : unit) | formatUnitWithHtml"></span>
  </span>
</div>
<div class="summary-column">
  <span class="summary-label">
    {{ isAverage ? 'Average (Mean) Target' : 'Total Target' }}
    <span class="summary-interval">{{ yearLabel }}</span>
  </span>
  <span class="summary-value">
    {{ getFormattedNumber(summary.targetValue) || 'Not set' }}
    <span class="summary-unit" [innerHTML]="(unit === 'Number' ? '' : unit) | formatUnitWithHtml"></span>
  </span>
</div>
<div class="summary-column">
  <span class="summary-label">
    Performance to target
    <span class="summary-interval">{{ yearLabel }}</span>
  </span>
  <span
    class="summary-value"
    [class.value-negative]="summary.performanceToTarget && summary.performanceToTarget < 0"
    [class.value-positive]="summary.performanceToTarget && summary.performanceToTarget >= 0">
    {{ getFormattedNumber(summary.performanceToTarget) || 'n/a' }}
    <ng-container *ngIf="getFormattedNumber(summary.performanceToTarget)">
      <span class="summary-percentage">%</span>
      <esg-arrow-right-long [class.above-target]="isAboveTarget"></esg-arrow-right-long>
      <span class="summary-description">
        {{ isAboveTarget ? 'Above target' : 'Below target' }}
      </span>
    </ng-container>
  </span>
</div>
