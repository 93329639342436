import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
  CreateDataRecordCommandParams,
  CurrencyCodes,
  DataRecord,
  InputType,
  UpdateDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { formatDate } from '@angular/common';
import { RecordBodyRow, RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';
import { Subscription } from 'rxjs';

export interface RecordForm {
  startDate: FormControl<Date>;
  endDate: FormControl<Date>;
  asset: FormControl<IOption | undefined>;
}

@Component({
  selector: 'esg-record-form-table',
  templateUrl: './record-form-table.component.html',
  styleUrls: ['./record-form-table.component.scss'],
})
export class RecordFormTableComponent {
  private columnFilterSubscription?: Subscription;

  @Input() dataRecords: DataRecord[] = [];
  @Input() newlyAddedRowsIds: string[] = [];
  @Input() readOnly: boolean = false;
  @Input() addOnly: boolean = false;
  @Input() stickyHeaderTopOffset: number = 0;
  @Input() intersectionObserverRootMargin: number = 0;
  @Input() stickyHeaderPadding: number = 0;
  @Input() assetOptions: IGroupedOptions[] = [];
  @Input() organizationCurrency: CurrencyCodes = CurrencyCodes.USD;
  @Input() countryOptions: IOption[] = [];
  @Input() assetCountryMap = new Map<string, string>();

  @Output() onSubmit = new EventEmitter<CreateDataRecordCommandParams | UpdateDataRecordCommandParams>();
  @Output() onDelete = new EventEmitter<string>();

  headerCells: RecordHeaderCell[] = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
  ];
  dataRecordsRows: RecordBodyRow[] = [];
  startDateOpen = false;
  endDateOpen = false;

  recordFc?: RecordForm = undefined;
  recordFormgroup?: FormGroup = undefined;
  hasSubmitted: boolean = false;
  submitOnPristine: boolean = false;

  editRecordId?: string;

  columnFilter: FormControl<IOption[]> = new FormControl([], { nonNullable: true });
  columnFilterStorageKey?: string = '';

  get isAssetsDisabled() {
    return this.assetOptions.flatMap(group => group.options).length < 2;
  }

  ngOnInit() {
    if (this.addOnly) {
      this.setAddRecordForm();
    }
    this.loadColumnFilter();

    if (this.columnFilterStorageKey) {
      this.columnFilterSubscription = this.columnFilter.valueChanges.subscribe(selectedColumns => {
        localStorage.setItem(this.columnFilterStorageKey || '', JSON.stringify(selectedColumns));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataRecords) {
      this.setRecordsRows();
    }
  }

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as DataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
      ],
    }));
  }

  loadColumnFilter() {
    if (this.columnFilterStorageKey) {
      const storedFilters = localStorage.getItem(this.columnFilterStorageKey);
      if (storedFilters) {
        this.columnFilter.setValue(JSON.parse(storedFilters), { emitEvent: false });
      } else {
        this.setColumnFilter();
      }
    }
  }

  setColumnFilter() {
    this.columnFilter.setValue(
      this.headerCells.map(cell => ({ value: cell.columnId || '', label: cell.label })),
      { emitEvent: false }
    );
  }

  isColumnVisible(id: string): boolean {
    return this.columnFilter.value.some(column => column.value === id);
  }

  handleOnIniateAddRecord() {
    if (this.editRecordId) this.handleCloseForm();
    this.startDateOpen = true;
    this.setAddRecordForm();
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup<RecordForm>(this.recordFc);
  }

  handleOnInitateEditRecord(id: string) {
    this.editRecordId = id;
    this.setEditRecordForm(id);
  }

  setEditRecordForm(id: string) {
    const record = this.dataRecords.find(record => record.id === id) as DataRecord;
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
      };
      this.recordFormgroup = new FormGroup<RecordForm>(this.recordFc);
    }
  }

  getParams(): CreateDataRecordCommandParams | UpdateDataRecordCommandParams | undefined {
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const record = {
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
      };
      if (this.editRecordId) {
        return new UpdateDataRecordCommandParams({ id: this.editRecordId, ...record });
      } else {
        return new CreateDataRecordCommandParams(record);
      }
    }
    return undefined;
  }

  handleOnSubmit() {
    if (this.startDateOpen || this.endDateOpen) return;

    this.hasSubmitted = true;
    if (!this.submitOnPristine && this.recordFormgroup?.pristine && this.recordFormgroup?.valid) {
      this.handleCloseForm();
      return;
    }
    const record = this.getParams();
    if (record instanceof CreateDataRecordCommandParams) {
      this.handleCloseForm();
      this.onSubmit.emit(record);
    } else if (record instanceof UpdateDataRecordCommandParams) {
      this.onSubmit.emit(record);
    }
  }

  handleOnDelete(id: string) {
    this.onDelete.emit(id);
  }

  handleDropdownChange(formControl: FormControl<IOption | undefined>, option: IOption) {
    if (option !== formControl.value) {
      formControl.setValue(option);
      formControl.markAsDirty();
    }
  }

  handleOnAssetChange(
    assetFormControl: FormControl<IOption | undefined>,
    countryFormControl: FormControl<IOption>,
    assetOption: IOption
  ) {
    const countryOption = this.countryOptions.find(opt => opt.value === this.assetCountryMap.get(assetOption.value));
    this.handleDropdownChange(assetFormControl, assetOption);
    if (countryOption) this.handleDropdownChange(countryFormControl, countryOption);
  }

  getCountryOptionByAssetId(assetId: string | undefined) {
    if (assetId) {
      const countryOption = this.countryOptions.find(opt => opt.value === this.assetCountryMap.get(assetId));
      return countryOption || this.countryOptions[0];
    } else return this.countryOptions[0];
  }

  getDefaultAssetOption(): IOption | undefined {
    for (const group of this.assetOptions) {
      if (group.options.length > 0) {
        return group.options[0];
      }
    }
    return undefined;
  }

  handleCloseForm() {
    if (!this.startDateOpen && !this.endDateOpen) {
      if (this.editRecordId) {
        if (this.recordFormgroup?.dirty) this.handleOnSubmit();
        this.editRecordId = undefined;
      }
      this.resetForm();
    }
  }

  onStartDateClose() {
    this.startDateOpen = false;
    const endDateControl = this.recordFormgroup?.controls['endDate'];
    const startDateControl = this.recordFormgroup?.controls['startDate'];
    if (!this.editRecordId && startDateControl && endDateControl && !endDateControl.touched) {
      endDateControl.setValue(startDateControl.value);
      if (endDateControl.hasValidator(Validators.required)) this.endDateOpen = true;
    }
  }

  resetForm() {
    this.startDateOpen = false;
    this.endDateOpen = false;

    this.recordFc = undefined;
    this.recordFormgroup = undefined;

    this.hasSubmitted = false;
  }

  ngOnDestroy() {
    this.columnFilterSubscription?.unsubscribe();
  }
}
