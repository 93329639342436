import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  CreateOrganizationCommandResponse,
  DeleteOrganizationCommandParams,
  DeleteOrganizationCommandResponse,
  GetByIdQueryResponse,
  OrganizationClient,
  UpdateOrganizationCommandResponse,
  CreateSubOrganizationCommandParams,
  CreateSubOrganizationCommandResponse,
  UpdateSubOrganizationCommandParams,
  UpdateSubOrganizationCommandResponse,
  DeleteSubOrganizationCommandParams,
  DeleteSubOrganizationCommandResponse,
  GetOrgWithSuborgFlattenedListQueryResponse,
  GetOrganizationListQueryResponse,
  CreateOrganizationCommandParams,
  UpdateOrganizationCommandParams,
  GetOrganizationsAndSuborganizationsFilteredByQueryResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class OrganizationApiService {
  client: OrganizationClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new OrganizationClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createOrganization(body: CreateOrganizationCommandParams): Promise<CreateOrganizationCommandResponse> {
    const observable$ = this.client.createOrganization(body);
    return await lastValueFrom(observable$);
  }
  async updateOrganization(body: UpdateOrganizationCommandParams): Promise<UpdateOrganizationCommandResponse> {
    const observable$ = this.client.updateOrganization(body);
    return await lastValueFrom(observable$);
  }
  async deleteOrganization(data: DeleteOrganizationCommandParams): Promise<DeleteOrganizationCommandResponse> {
    const observable$ = this.client.deleteOrganization(data);
    return await lastValueFrom(observable$);
  }

  async getOrganizationById(id: string): Promise<GetByIdQueryResponse> {
    const observable$ = this.client.getOrganizationById(id);
    return await lastValueFrom(observable$);
  }

  async getOrganizationsAndSuborganizationsFilteredBy(
    organizationId?: string
  ): Promise<GetOrganizationsAndSuborganizationsFilteredByQueryResponse> {
    const observable$ = this.client.getOrganizationsAndSuborganizationsFilteredBy(organizationId);
    return await lastValueFrom(observable$);
  }

  async getOrganizationList(): Promise<GetOrganizationListQueryResponse> {
    const observable$ = this.client.getOrganizationList();
    return await lastValueFrom(observable$);
  }

  async getSubOrganizationList(id: string): Promise<GetOrgWithSuborgFlattenedListQueryResponse> {
    const observable$ = this.client.getOrgWithSuborgFlattenedList(id);
    return await lastValueFrom(observable$);
  }

  async getSubOrganizationTree(id: string): Promise<GetOrganizationsAndSuborganizationsFilteredByQueryResponse> {
    const observable$ = this.client.getOrganizationsAndSuborganizationsFilteredBy(id);
    return await lastValueFrom(observable$);
  }

  createSubOrganization(data: CreateSubOrganizationCommandParams): Observable<CreateSubOrganizationCommandResponse> {
    return this.client.createSubOrganization(data);
  }

  updateSubOrganization(data: UpdateSubOrganizationCommandParams): Observable<UpdateSubOrganizationCommandResponse> {
    return this.client.updateSubOrganization(data);
  }

  deleteSubOrganization(data: DeleteSubOrganizationCommandParams): Observable<DeleteSubOrganizationCommandResponse> {
    return this.client.deleteSubOrganization(data);
  }
}
