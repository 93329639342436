import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { DatepickerHeaderComponent } from './datepicker-header/datepicker-header.component';

class CustomDateAdapter extends NativeDateAdapter {
  getDayOfWeekNames() {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
  getFirstDayOfWeek(): number {
    return 1;
  }
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd. MMM yyyy', 'en_US');
    } else if (displayFormat === 'monthYear') {
      return formatDate(date, 'MMMM yyyy', 'en_US');
    }

    return date.toDateString();
  }
}

const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: { day: 'numeric', month: 'short', year: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'monthYear',
    dateA11yLabel: { day: 'numeric', month: 'short', year: 'numeric' },
    monthYearA11yLabel: { month: 'long', year: 'numeric' },
  },
};

@Component({
  selector: 'esg-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class DatepickerComponent {
  @Input({ required: true }) fc!: FormControl<Date | null>;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() placeholder = 'DD. MMM YYYY';
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() variant: 'standard' | 'ghost' | 'ghost-table' = 'standard';
  @Input() isFilter: boolean = false;
  @Input() autoFocus = false;
  @Input() inputIndent: number = 0;
  @Output() onChange = new EventEmitter<Date | null>();
  @Output() onOpen = new EventEmitter();
  @Output() onClose = new EventEmitter();

  header = DatepickerHeaderComponent;
  open: boolean = false;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.autoFocus && this.autoFocus) {
      this.open = this.autoFocus;
    }
  }

  handleChange(event: MatDatepickerInputEvent<Date>) {
    if (this.onChange.observed) {
      this.onChange.emit(event.value);
    }
  }

  handleOpen() {
    this.open = true;
    if (this.onOpen.observed) {
      this.onOpen.emit();
    }
  }

  handleClose() {
    this.open = false;
    if (this.onClose.observed) {
      this.onClose.emit();
    }
  }

  handleClearFilter(event: Event) {
    event.stopPropagation();
    this.fc.reset(null);
    this.onChange.emit(null);
  }
}
