import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ITabItem {
  value: string;
  label?: string;
  disabled?: boolean;
  link?: string[];
  fragment?: string;
}

@Component({
  selector: 'esg-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() tabs: ITabItem[] = [];
  @Input() activeTab?: string;
  @Input() tabIndent: number = 0;
  @Output() onTabClick = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  handleOnTabClick(tabValue: string) {
    this.tabs.length > 1 && this.onTabClick.emit(tabValue);
  }
}
