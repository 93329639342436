import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'esg-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
