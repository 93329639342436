import { Component } from '@angular/core';
@Component({
  selector: 'esg-info-icon',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.1655 12.5C5.97869 12.5 5.82107 12.4447 5.69264 12.334C5.56421 12.2178 5.5 12.0712 5.5 11.8942C5.5 11.7282 5.56421 11.5871 5.69264 11.471C5.82107 11.3492 5.97869 11.2884 6.1655 11.2884H7.27758V7.37967H6.31436C6.13339 7.37967 5.97869 7.32434 5.85026 7.21369C5.72183 7.09751 5.65762 6.9509 5.65762 6.77386C5.65762 6.60788 5.72183 6.46681 5.85026 6.35062C5.97869 6.22891 6.13339 6.16805 6.31436 6.16805H8.01313C8.24664 6.16805 8.42177 6.23997 8.53853 6.38382C8.66112 6.52766 8.72242 6.71577 8.72242 6.94813V11.2884H9.8345C10.0213 11.2884 10.1789 11.3492 10.3074 11.471C10.4358 11.5871 10.5 11.7282 10.5 11.8942C10.5 12.0712 10.4358 12.2178 10.3074 12.334C10.1789 12.4447 10.0213 12.5 9.8345 12.5H6.1655ZM7.66287 4.74896C7.33596 4.74896 7.05575 4.64108 6.82224 4.42531C6.59457 4.20401 6.48074 3.93845 6.48074 3.62863C6.48074 3.31328 6.59457 3.04772 6.82224 2.83195C7.05575 2.61065 7.33596 2.5 7.66287 2.5C7.99562 2.5 8.27291 2.61065 8.49475 2.83195C8.72242 3.04772 8.83625 3.31328 8.83625 3.62863C8.83625 3.93845 8.72242 4.20401 8.49475 4.42531C8.27291 4.64108 7.99562 4.74896 7.66287 4.74896Z"
        fill="white" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class InfoIconComponent {}
