import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

export interface CarouselSlideUi {
  header: string;
  content: string;
  videoSrc?: string;
}

@Component({
  selector: 'esg-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrl: './carousel-slide.component.scss',
})
export class CarouselSlideComponent {
  @Input({ required: true }) slide!: CarouselSlideUi;
  @ViewChild('video') video!: ElementRef;

  isPlayingVideo = false;
  isVideoLoaded = false;

  get sanitizedHtml(): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(this.slide.content);
  }

  get trustedVideoSrc(): SafeResourceUrl {
    if (this.slide.videoSrc) return this.domSanitizer.bypassSecurityTrustResourceUrl(this.slide.videoSrc);
    else return '';
  }

  constructor(private domSanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    this.video.nativeElement.addEventListener('loadedmetadata', () => this.videoIsLoaded());
  }

  videoIsLoaded() {
    this.isVideoLoaded = true;
  }

  handleVideoClick(): void {
    if (!this.isVideoLoaded) return;

    if (this.isPlayingVideo) {
      this.video.nativeElement.pause();
      this.isPlayingVideo = false;
    } else {
      this.video.nativeElement.play();
      this.isPlayingVideo = true;
    }
  }

  handleOutOfViewport(tabEntry: IntersectionObserverEntry) {
    if (this.isPlayingVideo && tabEntry.intersectionRatio === 0) {
      this.video.nativeElement.pause();
      this.isPlayingVideo = false;
    }
  }

  ngOnDestroy() {
    this.video.nativeElement.removeEventListener('loadedmetadata', () => this.videoIsLoaded());
  }
}
