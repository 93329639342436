import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppClient, AppInfo } from '../../../api-client/app-api.generated';

@Injectable({
  providedIn: 'root',
})
export class WebAppApiService {
  client: AppClient;

  constructor(readonly httpClient: HttpClient) {
    this.client = new AppClient(httpClient);
  }

  async getAppInfo(): Promise<AppInfo> {
    const appInfo$ = this.client.getAppInfo().pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(error);
      })
    );
    return await lastValueFrom(appInfo$);
  }
}
