import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { ITabItem } from '../../../../shared/ui/tabs/tabs.component';

export interface IToCList {
  label: string;
  items: ITabItem[];
}

@Component({
  selector: 'esg-table-of-contents',
  templateUrl: './table-of-contents.component.html',
  styleUrls: ['./table-of-contents.component.scss'],
})
export class TableOfContentsComponent implements OnInit {
  @Input({ required: true }) list!: IToCList;
  @Input() activeItem?: string;
  @Output() onItemClick = new EventEmitter<string>();

  fragmentActiveOptions: IsActiveMatchOptions = {
    matrixParams: 'exact',
    queryParams: 'exact',
    paths: 'exact',
    fragment: 'exact',
  };

  constructor() {}

  ngOnInit(): void {}

  handleOnItemClick(event: Event, itemValue: string) {
    event.stopPropagation();
    event.preventDefault();
    this.list.items.length > 1 && this.onItemClick.emit(itemValue);
  }
}
