import { Injectable } from '@angular/core';
import {
  DataRecordType,
  AssetType,
  AssetVm,
  VehicleCraftMachineryAssetVm,
  PowerSource,
  DataRecordCategory,
} from 'src/api-client/report-api.generated';
import { IGroupedOptions, vehicleCraftMachineryTypeOptions } from 'src/app/static-data/options';
import { VehicleCraftMachineryTypeLabel } from 'src/app/static-data/enum-mappings';
import { RecordsStateService } from '../records-state.service';

@Injectable()
export class VehicleCombustionStateService extends RecordsStateService {
  recordCategory = DataRecordCategory.DirectEmissionFuelCombustion;
  assetCategories = [AssetType.VehicleCraftMachinery];

  async loadRecords() {
    await this.getDataRecords(DataRecordType.VehicleConsumption);
  }

  handleDeleteAllFilteredRecordsSubmit() {
    this.handleOnDeleteAllFilteredRecords(DataRecordType.VehicleConsumption);
  }

  async getAssetOptions() {
    this.assetOptions = [];

    const response = await this.assetApiService.getAssetsByOrganizationAndType(
      undefined,
      AssetType.VehicleCraftMachinery
    );

    this.getVehicleCraftsMachineryOptions(response.result);

    this.assetFilterOptions = this.assetOptions;
    this.isAssetsDisabled = this.assetOptions.flatMap(group => group.options).length < 2;
  }

  getVehicleCraftsMachineryOptions(assets: AssetVm[]) {
    const assetOptions: IGroupedOptions[] = vehicleCraftMachineryTypeOptions.map(subType => ({
      label: VehicleCraftMachineryTypeLabel[subType.value],
      options: [],
    }));
    assets.forEach(a => {
      const asset = a as VehicleCraftMachineryAssetVm;
      if (asset.powerSource === PowerSource.Electric) return;
      const group = assetOptions.find(group => group.label === VehicleCraftMachineryTypeLabel[asset.subType]);
      if (group) group.options.push({ value: asset.id, label: asset.name, group: asset.subType });
    });
    this.assetOptions = assetOptions.filter(group => group.options.length > 0);
  }
}
