import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NotificationService } from './notification.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationWrapperComponent } from './notification-wrapper.component';
import { UiModule } from '../../ui/ui.module';
export { NotificationService };

@NgModule({
  declarations: [NotificationWrapperComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    UiModule,
    ToastrModule.forRoot({
      toastComponent: NotificationWrapperComponent,
      toastClass: '',
      preventDuplicates: true,
      iconClasses: {
        error: 'notification-error',
        info: 'notification-info',
        success: 'notification-success',
        warning: 'notification-warning',
      },
    }),
  ],
  providers: [NotificationService],
})
export class NotificationModule {}
