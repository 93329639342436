import { Component, Input } from '@angular/core';

export enum RiskCategory {
  Unknown = 'Unknown',
  VeryHighRisk = 'VeryHighRisk',
  HighRisk = 'HighRisk',
  MediumRisk = 'MediumRisk',
  LowRisk = 'LowRisk',
  NoRisk = 'NoRisk',
}

export interface IRiskLabel {
  riskCategory: RiskCategory;
  label: string;
  color: string;
}

const RiskLabels: { [key in RiskCategory]: IRiskLabel } = {
  [RiskCategory.Unknown]: {
    riskCategory: RiskCategory.Unknown,
    label: 'n/a',
    color: '#DDDDDD',
  },
  [RiskCategory.VeryHighRisk]: {
    riskCategory: RiskCategory.VeryHighRisk,
    label: 'Very High',
    color: '#EA1F4C',
  },
  [RiskCategory.HighRisk]: {
    riskCategory: RiskCategory.HighRisk,
    label: 'High',
    color: '#EA708A',
  },
  [RiskCategory.MediumRisk]: {
    riskCategory: RiskCategory.MediumRisk,
    label: 'Medium',
    color: '#FF9900',
  },
  [RiskCategory.LowRisk]: {
    riskCategory: RiskCategory.LowRisk,
    label: 'Low',
    color: '#72DC9B',
  },
  [RiskCategory.NoRisk]: {
    riskCategory: RiskCategory.NoRisk,
    label: 'No Risk',
    color: '#23D468',
  },
};

@Component({
  selector: 'esg-risk-label',
  template:
    '<span [style.backgroundColor]="getRiskLabel.color" [class.unknown]="riskCategory === RiskCategoryEnum.Unknown">{{ getRiskLabel.label }}</span>',
  styleUrls: ['./risk-label.component.scss'],
})
export class RiskLabelComponent {
  @Input() riskCategory?: RiskCategory;
  readonly RiskCategoryEnum = RiskCategory;

  get getRiskLabel(): IRiskLabel {
    return this.riskCategory ? RiskLabels[this.riskCategory] : RiskLabels[RiskCategory.Unknown];
  }
}
