<div *ngIf="!useNewMainNavigation" class="no-category">
  <img
    class="no-category-image"
    src="/assets/images/records-and-assets/select-records.svg"
    width="579"
    height="240"
    alt="Arrow pointed towards menu, and a woman adding data." />
  <span class="no-category-text">{{ noCategoryText }}</span>
</div>
<div *ngIf="useNewMainNavigation" class="select-category">
  <h2>Raw Data</h2>

  <div class="category-links">
    <div class="category-set">
      <span class="category-label">Emissions</span>
      <a routerLink="fuel-combustion">Fuel Combustion</a>
      <a routerLink="electricity">Electricity</a>
      <a routerLink="business-travel">Business Travel</a>
      <a routerLink="waste">Waste</a>
      <a routerLink="employee-commuting">Employee commuting</a>
    </div>
    <div class="category-set">
      <span class="category-label">Biodiversity</span>
      <a routerLink="spills-to-environment">Spills to environment</a>
      <a routerLink="marine-protected-areas">Marine Protected Areas</a>
    </div>
    <div class="category-set">
      <span class="category-label">Employees, health and Safety</span>
      <a routerLink="gender-diversity-equality">Gender, Position and Age Profiles</a>
      <a routerLink="work-related-injuries">Work related injuries</a>
      <a routerLink="marine-casualties">Marine Casualties</a>
      <a routerLink="sick-leave">Sick Leave</a>
      <a routerLink="parental-leave">Parental Leave</a>
      <a routerLink="new-hires-and-turnovers">New Hires and Turnovers</a>
    </div>
    <div class="category-set">
      <span class="category-label">Business Ethics</span>
      <a routerLink="corruption-risk">Corruption risk</a>
      <a routerLink="fines">Fines</a>
    </div>
  </div>
</div>
