<div class="menu-container">
  <div class="menu-section">
    <a routerLink="vessels" routerLinkActive="active">
      <span class="label">Vessels</span>
      <span class="counter">({{ getAssetTypeCount(assetTypeEnum.Vessel) }})</span>
    </a>
    <a routerLink="vehicles-crafts-machinery" routerLinkActive="active">
      <span class="label">Vehicles, Crafts & Machinery</span>
      <span class="counter">({{ getAssetTypeCount(assetTypeEnum.VehicleCraftMachinery) }})</span>
    </a>
    <a routerLink="facilities" routerLinkActive="active">
      <span class="label">Facilities</span>
      <span class="counter">({{ getAssetTypeCount(assetTypeEnum.Facility) }})</span>
    </a>
    <a routerLink="organizations" routerLinkActive="active">
      <span class="label">Organizations</span>
      <span class="counter">({{ getAssetTypeCount(assetTypeEnum.Organization) }})</span>
    </a>
    <a routerLink="suppliers" routerLinkActive="active">
      <span class="label">Suppliers</span>
      <span class="counter">({{ getAssetTypeCount(assetTypeEnum.Supplier) }})</span>
    </a>
    <a routerLink="clients" routerLinkActive="active">
      <span class="label">Clients</span>
      <span class="counter">({{ getAssetTypeCount(assetTypeEnum.Client) }})</span>
    </a>
  </div>
</div>
