import { Component, Input } from '@angular/core';
import { stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';

export interface RecordsOverviewUi {
  category: string;
  count: number;
  firstDate: Date;
  lastDate: Date;
  link?: string[];
  recordType?: string;
}

@Component({
  selector: 'esg-records-overview',
  templateUrl: './records-overview.component.html',
  styleUrls: ['./records-overview.component.scss'],
})
export class RecordsOverviewComponent {
  @Input({ required: true }) assetId!: string;
  @Input({ required: true }) data!: RecordsOverviewUi[];
  getFormattedNumber = stringToFormattedNumberString;
}
