import { Content, TableCell } from 'pdfmake/interfaces';
import {
  IndicatorDataValueType,
  TimePeriod,
  TimePeriodType,
  TopicItemType,
} from '../../../../api-client/report-api.generated';
import { getTimeLabelFromPeriod } from '../../../static-data/time-labels';
import { numberToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { pickTextColorBasedOnBgColor } from 'src/app/shared/utils/colors';
import { IKeyfigures, IMainLevel } from './types';

export interface keyFiguresTable {
  mainTopic?: string;
  keyFigures?: keyFigureRows[];
}

export interface keyFigureRows {
  topicItem?: string;
  references?: string;
  rows: keyFigureRow[];
}

export interface keyFigureRow {
  label?: string;
  unit?: string;
  target?: number;
  current?: number;
  previous?: number;
}

export const mapKeyFiguresToRow = function (keyFigures: IKeyfigures): keyFigureRow {
  return {
    label: keyFigures.metadata.label,
    unit: keyFigures.currentValue.unitDetails || keyFigures.metadata.unit,
    target:
      keyFigures.targetValue.value || keyFigures.targetValue.value === 0
        ? Number(keyFigures.targetValue.value)
        : undefined,
    current:
      keyFigures.currentValue.value || keyFigures.currentValue.value === 0
        ? Number(keyFigures.currentValue.value)
        : undefined,
    previous:
      keyFigures.previousValue.value || keyFigures.previousValue.value === 0
        ? Number(keyFigures.previousValue.value)
        : undefined,
  };
};

export const getKeyFigureFromMainLevels = function (mainLevels: IMainLevel[]): keyFiguresTable[] {
  const table: keyFiguresTable[] = [];
  mainLevels.map(async mainTopic => {
    const keyFigures: keyFigureRows[] = [];
    const topics = mainTopic.topics || [];
    topics.map(async topic => {
      const topicItems = topic.topicItems || [];
      topicItems.map(async topicItem => {
        let referenceStandards = '';
        if (topicItem.type === TopicItemType.NewIndicator) {
          referenceStandards = Array.from(
            new Set(
              topicItem.dataValues?.flatMap(value =>
                value.referenceStandards?.replace(/(\r\n|\n|\r)/gm, '\n').split('\n')
              )
            )
          ).join('\n');
        }

        const keyFigure = {
          topicItem: topicItem.name,
          rows:
            topicItem.dataValues
              ?.filter(
                value => value.metadata.type === IndicatorDataValueType.Numeric && value.metadata.isVisibleInReport
              )
              .map(value => mapKeyFiguresToRow(value)) || [],
          references: referenceStandards,
        };
        if (keyFigure.rows.length) keyFigures.push(keyFigure);
      });
    });
    keyFigures.length && table.push({ mainTopic: mainTopic.name, keyFigures: keyFigures });
  });
  return table;
};

export const generateKeyFiguresTable = function (
  keyFiguresTables: keyFiguresTable[],
  timePeriod?: TimePeriod,
  organizationName?: string,
  tableHeadColor?: string
): Content[] {
  const isCustomPeriod = timePeriod?.type === TimePeriodType.Custom;
  const tableBody: TableCell[][] = [
    [
      { text: '', bold: true, border: [false, false, false, false], style: 'keyFiguresTableCell' },
      { text: '', bold: true, border: [false, false, false, false], style: 'keyFiguresTableCell' },
      { text: 'Unit', bold: true, border: [false, false, false, false], style: 'keyFiguresTableCell' },
      {
        text: isCustomPeriod || !timePeriod ? 'Target' : 'Target ' + getTimeLabelFromPeriod(timePeriod, 1),
        bold: true,
        border: [false, false, false, false],
        style: 'keyFiguresTableCell',
        alignment: 'right',
      },
      {
        text: isCustomPeriod || !timePeriod ? 'Current' : getTimeLabelFromPeriod(timePeriod, 0),
        bold: true,
        border: [false, false, false, false],
        style: 'keyFiguresTableCell',
        alignment: 'right',
      },
      {
        text: isCustomPeriod || !timePeriod ? 'Previous' : getTimeLabelFromPeriod(timePeriod, -1),
        bold: true,
        border: [false, false, false, false],
        style: 'keyFiguresTableCell',
        alignment: 'right',
      },
      {
        text: 'Disclosure Reference',
        bold: true,
        border: [true, false, false, false],
        style: 'keyFiguresTableCell',
      },
    ],
  ];

  if (isCustomPeriod) {
    tableBody.push([
      {
        text: '',
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
      },
      {
        text: '',
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
      },
      {
        text: '',
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, 1),
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
        alignment: 'right',
        italics: true,
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, 0),
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
        alignment: 'right',
        italics: true,
      },
      {
        text: getTimeLabelFromPeriod(timePeriod, -1),
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
        alignment: 'right',
        italics: true,
      },
      {
        text: '',
        border: [true, true, false, true],
        style: 'keyFiguresTableCell',
      },
    ]);
  }

  keyFiguresTables.map(table => {
    tableBody.push([
      {
        text: table.mainTopic,
        border: [false, true, false, true],
        style: 'keyFiguresTableCell',
        bold: true,
        colSpan: 7,
        fillColor: tableHeadColor ? tableHeadColor : '#bedde0',
        color: tableHeadColor ? pickTextColorBasedOnBgColor(tableHeadColor) : 'black',
      },
    ]);
    table.keyFigures?.map(figures => {
      figures.rows.map(indictorValues =>
        tableBody.push([
          {
            text: figures.topicItem || '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            rowSpan: figures.rows.length,
            colSpan: figures.rows.length > 1 ? 1 : 2,
          },
          {
            text: indictorValues.label || '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            italics: true,
          },
          {
            text: indictorValues.unit || '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            italics: true,
          },
          {
            text: indictorValues.target !== undefined ? numberToFormattedNumberString(indictorValues.target, 0, 2) : '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            alignment: 'right',
          },
          {
            text:
              indictorValues.current !== undefined ? numberToFormattedNumberString(indictorValues.current, 0, 2) : '',
            border: [false, true, false, true],
            bold: true,
            style: 'keyFiguresTableCell',
            alignment: 'right',
          },
          {
            text:
              indictorValues.previous !== undefined ? numberToFormattedNumberString(indictorValues.previous, 0, 2) : '',
            border: [false, true, false, true],
            style: 'keyFiguresTableCell',
            alignment: 'right',
          },
          {
            text: figures.references || '',
            border: [true, true, false, true],
            style: 'keyFiguresTableCell',
            italics: true,
            rowSpan: figures.rows.length,
          },
        ])
      );
    });
  });

  return [
    {
      text: organizationName?.toUpperCase() || '',
      style: 'mainTopicTitleLeft',
      margin: [23, 0, 0, 11],
      pageBreak: 'before',
    },
    {
      text: isCustomPeriod || !timePeriod ? 'Key Figures' : 'Key Figures ' + getTimeLabelFromPeriod(timePeriod, 0),
      style: 'keyFigureTitle',
      margin: [23, 0, 0, 20],
    },
    {
      table: {
        headerRows: 1,
        widths: ['15%', '15%', '10%', '15%', '15%', '15%', '15%'],
        body: tableBody,
      },
      margin: [23, 0, 0, 0],
      layout: {
        hLineColor: '#CCCCCC',
        vLineColor: '#CCCCCC',
        hLineWidth: function () {
          return 0.5;
        },
        vLineWidth: function () {
          return 0.5;
        },
      },
    },
  ];
};
