import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-icon-move',
  template: `
    <svg
      class="move-icon"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="1.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="9" y="1.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="9" y="6.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="4" y="6.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="4" y="11.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
      <rect x="9" y="11.5" width="3" height="3" rx="1" attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class MoveIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#999999';
}
