import { Component } from '@angular/core';
import { MarineProtectedAreasStateService } from './marine-protected-areas-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-marine-protected-areas',
  templateUrl: './marine-protected-areas.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [
    { provide: RecordsComponent, useExisting: MarineProtectedAreasComponent },
    MarineProtectedAreasStateService,
  ],
})
export class MarineProtectedAreasComponent extends RecordsComponent {
  constructor(public state: MarineProtectedAreasStateService) {
    super(state);
  }
}
