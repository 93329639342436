<ng-container *ngIf="!addOnly">
  <span *ngIf="state.useNewMainNavigation" class="category-selector-label">Select category</span>
  <esg-description-and-reference
    [description]="state.categoryDetails?.guidance || ''"
    [class.is-selector]="state.useNewMainNavigation">
    <h2 *ngIf="!state.useNewMainNavigation" class="title">
      {{ state.categoryDetails && state.categoryDetails.name ? state.categoryDetails.name : 'Fines' }}
    </h2>
    <esg-route-selector
      *ngIf="state.useNewMainNavigation"
      [selectedLabel]="state.categoryDetails?.name || 'Fines'"
      [items]="state.recordCategoryRoutes"></esg-route-selector>
  </esg-description-and-reference>
  <esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading records"></esg-dot-flashing>
  <esg-filter-pagination-bar
    *ngIf="!state.loading"
    class="filter"
    [paginationData]="state.currentPagination"
    [pageSizeOptions]="state.pageSizeOptions"
    [menuItems]="state.filterMenuProvider()"
    (onPaginationChange)="state.handlePaginationChange($event)">
    <div class="params">
      <esg-datepicker
        [fc]="state.startDateFilter"
        [isFilter]="true"
        size="small"
        placeholder="Date From"
        [maxDate]="state.endDateFilter.value || undefined"
        (onChange)="state.handleFilterChange()"></esg-datepicker>
      <esg-datepicker
        [fc]="state.endDateFilter"
        [isFilter]="true"
        size="small"
        placeholder="Date To"
        [minDate]="state.startDateFilter.value || undefined"
        (onChange)="state.handleFilterChange()"></esg-datepicker>
      <esg-dropdown-multiple-form
        *ngIf="state.assetTypeOptions.length > 1"
        [fc]="state.filteredAssetTypes"
        size="small"
        [options]="state.assetTypeOptions"
        [isFilter]="true"
        placeholder="All asset types"
        (onChange)="state.handleAssetTypeFilterChange()"></esg-dropdown-multiple-form>
      <esg-dropdown-multiple-form
        [fc]="state.filteredAssets"
        size="small"
        [groupedOptions]="state.assetFilterOptions"
        [isFilter]="true"
        placeholder="All assets"
        (onChange)="state.handleFilterChange()"></esg-dropdown-multiple-form>
      <esg-select
        size="small"
        [value]="state.filteredInputType.value"
        [options]="state.inputTypeOptions"
        (onChange)="state.handleInputTypeChange($event)"></esg-select>
    </div>
  </esg-filter-pagination-bar>
  <esg-confirm-dialog
    *ngIf="state.isDeleteDialogOpen"
    [message]="state.deleteDialogMessage"
    cancelText="Cancel"
    (onSubmit)="state.handleDeleteAllFilteredRecordsSubmit()"
    (onCancel)="state.handleDeleteAllFilteredRecordsClose()"></esg-confirm-dialog>
</ng-container>
<esg-fines-form-table
  *ngIf="!state.loading"
  [dataRecords]="state.dataRecords"
  [newlyAddedRowsIds]="state.newlyAddedRowsIds"
  [readOnly]="state.isReadonly"
  [addOnly]="addOnly"
  [stickyHeaderTopOffset]="-65"
  [intersectionObserverRootMargin]="state.intersectionObserverRootMargin"
  [stickyHeaderPadding]="90"
  [assetOptions]="state.assetOptions"
  [organizationCurrency]="state.organizationCurrency"
  [countryOptions]="state.countryOptions"
  [assetCountryMap]="state.assetCountryMap"
  (onSubmit)="state.handleRecordFormSubmit($event)"
  (onDelete)="state.handleDeleteRecord($event)"></esg-fines-form-table>
