import { Injectable } from '@angular/core';
import {
  AssetType,
  VehicleCraftMachineryAssetVm,
  CreateVehicleCraftMachineryAssetCommandParams,
  UpdateVehicleCraftMachineryAssetCommandParams,
  VehicleCraftMachineryAsset,
  VehicleCraftMachineryType,
  PowerSource,
  UpdateVehicleCraftMachineryAssetBasePropertiesCommandParams,
} from 'src/api-client/report-api.generated';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { IOption, vehicleCraftMachineryTypeOptions, powerSourceOptions } from 'src/app/static-data/options';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { AssetListStateService } from '../asset-list-state.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { AssetsMenuStateService } from '../../assets-menu/assets-menu-state.service';
import { SectorApiService } from 'src/app/api-client/report-api/sector-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  DataRecordOutput,
  ExcelImportValidationMessage,
  FieldConfig,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable()
export class VehiclesCraftsMachineryStateService extends AssetListStateService {
  assetType = AssetType.VehicleCraftMachinery;
  typeOptions: IOption[] = vehicleCraftMachineryTypeOptions;
  subTypeOptions: IOption[] = powerSourceOptions;

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    assetApiService: AssetApiService,
    countryApiService: CountryApiService,
    organizationApiService: OrganizationApiService,
    sectorApiService: SectorApiService,
    notificationService: NotificationService,
    assetsMenuState: AssetsMenuStateService
  ) {
    super(
      appInfo,
      appConfig,
      assetApiService,
      countryApiService,
      organizationApiService,
      sectorApiService,
      notificationService,
      assetsMenuState
    );
  }

  excelTitle = 'Vehicle, Craft & Machinery';
  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'name', title: 'Asset', type: 'string' },
    { propertyName: 'type', title: 'Asset type', type: 'string' },
    { propertyName: 'subType', title: 'Power source', type: 'string' },
    { propertyName: 'organization', title: 'Organization', type: 'string' },
    { propertyName: 'versionValidFrom', title: 'Version valid from', type: 'date', optional: true },
  ];

  importFromExcelEnabled = true;

  async getAssets() {
    const response = await this.assetApiService.getAssetsByOrganizationAndType(undefined, this.assetType);
    const result = response.result as VehicleCraftMachineryAssetVm[];
    this.assets = result.map(asset => ({
      id: asset.id,
      name: asset.name,
      type: this.typeOptions.find(type => type.value === asset.subType)?.label || asset.subType,
      subType: this.subTypeOptions.find(type => type.value === asset.powerSource)?.label || asset.powerSource,
      organizationName: asset.orgOrSuborgName,
      versionValidFrom: asset.versionValidFrom,
    }));
  }

  async formSubmit(params: CreateVehicleCraftMachineryAssetCommandParams) {
    if (this.selectedAsset) {
      const selected = this.selectedAsset;
      this.setSelectedAsset(undefined);

      const updateParams = new UpdateVehicleCraftMachineryAssetBasePropertiesCommandParams({
        id: selected.id,
        type: this.assetType,
        subType: params.subType,
        name: params.name,
        subOrganizationId: params.subOrganizationId,
        powerSource: params.powerSource,
      });
      const response = await this.assetApiService.updateAssetBaseProperties(updateParams);
      if (response.success) {
        this.getAssets();
      } else {
        this.notificationService.showError(
          `Unable to update ${this.assetType}`,
          response.validationErrors?.join('\n') || response.message || 'Unknown error'
        );
      }
    } else {
      const createParams = new CreateVehicleCraftMachineryAssetCommandParams({
        type: this.assetType,
        subType: params.subType,
        name: params.name,
        powerSource: params.powerSource,
        organizationId: this.organizationId || '',
        subOrganizationId: params.subOrganizationId,
        versionValidFrom: this.defaultVersionDate,
      });
      this.updating = true;
      const response = await this.assetApiService.createAsset(createParams);
      if (response.success) {
        await this.getAssets();
        this.assetsMenuState.loadAssetTypeCounters();
      } else {
        this.notificationService.showError(
          `Unable to add ${this.assetType}`,
          response.validationErrors?.join('\n') || response.message || 'Unknown error'
        );
      }
      this.updating = false;
    }
  }

  createAssetFromExcel(asset: DataRecordOutput) {
    const organizationOption = this.getExcelOptionColumn(
      'organization',
      this.organizationOptions,
      asset,
      'Organization'
    );
    const typeOption = this.getExcelOptionColumn('type', this.typeOptions, asset, 'Asset type');
    const subTypeOption = this.getExcelOptionColumn('subType', this.subTypeOptions, asset, 'Power source');

    const body = new CreateVehicleCraftMachineryAssetCommandParams({
      type: this.assetType,
      organizationId: this.organizationId || '',
      subOrganizationId: this.organizationId === organizationOption.value ? undefined : organizationOption.value,
      name: asset.name?.value || '',
      subType: VehicleCraftMachineryType[typeOption.value as keyof typeof VehicleCraftMachineryType],
      powerSource: PowerSource[subTypeOption.value as keyof typeof PowerSource],
      versionValidFrom: asset.versionValidFrom?.value || this.defaultVersionDate,
    });
    return this.assetApiService.createAsset(body);
  }

  validateOptionColumns(records: DataRecordOutput[]): ExcelImportValidationMessage[] {
    let validationMessages: ExcelImportValidationMessage[] = [];

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'organization',
      this.organizationOptions,
      records,
      'Organization'
    );

    validationMessages = validateExcelOptionColumn(validationMessages, 'type', this.typeOptions, records, 'Asset type');

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'subType',
      this.subTypeOptions,
      records,
      'Power source'
    );

    return validationMessages;
  }
}
