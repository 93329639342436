import { Component } from '@angular/core';
import { BusinessTravelComponent } from './business-travel.component';
import { HotelNightsStateService } from './hotel-nights-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-hotel-nights',
  templateUrl: './hotel-nights.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: HotelNightsComponent }, HotelNightsStateService],
})
export class HotelNightsComponent extends BusinessTravelComponent {
  constructor(public state: HotelNightsStateService) {
    super(state);
  }
}
