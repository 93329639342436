import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomFormValidators {
  static numeric(control: AbstractControl): ValidationErrors | null {
    if (control.value && isNaN(Number(control.value))) {
      return { numeric: true };
    }
    return null;
  }

  static hexColor(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    if (typeof control.value === 'string' || control.value instanceof String) {
      if (checkStringHex(control.value as string)) return null;
      return { hexColor: true };
    } else return { hexColor: true };
  }
}

export const checkStringHex = (value: string) => {
  return !!value.match(/^#([A-F0-9]{6})$/i)?.length;
};
