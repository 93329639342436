import { Component } from '@angular/core';

@Component({
  selector: 'esg-icon-download',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.2483 14C3.5822 14 3.08154 13.8348 2.74631 13.5044C2.41544 13.174 2.25 12.6806 2.25 12.0242V5.45133C2.25 4.79491 2.41544 4.30151 2.74631 3.97112C3.08154 3.64074 3.5822 3.47555 4.2483 3.47555H6.08986V4.42757H4.26136C3.91742 4.42757 3.65403 4.51886 3.47118 4.70144C3.29268 4.88402 3.20344 5.15137 3.20344 5.50349V11.9721C3.20344 12.3285 3.29268 12.5959 3.47118 12.7741C3.65403 12.9567 3.91742 13.048 4.26136 13.048H11.7321C12.0673 13.048 12.3286 12.9567 12.5158 12.7741C12.703 12.5959 12.7966 12.3285 12.7966 11.9721V5.50349C12.7966 5.15137 12.703 4.88402 12.5158 4.70144C12.3286 4.51886 12.0673 4.42757 11.7321 4.42757H9.91014V3.47555H11.7517C12.4178 3.47555 12.9163 3.64291 13.2472 3.97764C13.5824 4.30803 13.75 4.79925 13.75 5.45133V12.0242C13.75 12.6763 13.5824 13.1675 13.2472 13.4979C12.9163 13.8326 12.4178 14 11.7517 14H4.2483ZM7.99673 9.74849C7.94014 9.74849 7.88354 9.73762 7.82694 9.71588C7.7747 9.69415 7.71811 9.65285 7.65716 9.59199L5.4107 7.41407C5.31928 7.32278 5.27357 7.22062 5.27357 7.10759C5.27357 6.98587 5.31275 6.88589 5.39111 6.80764C5.46948 6.72939 5.57179 6.69027 5.69804 6.69027C5.833 6.69027 5.94184 6.73808 6.02456 6.83372L7.06289 7.92268L7.56573 8.44434L7.53308 7.47275V0.462972C7.53308 0.336904 7.57879 0.228225 7.67022 0.136935C7.76164 0.0456451 7.87048 0 7.99673 0C8.12734 0 8.23836 0.0456451 8.32978 0.136935C8.42556 0.228225 8.47345 0.336904 8.47345 0.462972V7.47275L8.43427 8.44434L8.93058 7.92268L9.97544 6.83372C10.0582 6.73808 10.1648 6.69027 10.2954 6.69027C10.4173 6.69027 10.5196 6.72939 10.6024 6.80764C10.6851 6.88589 10.7264 6.98587 10.7264 7.10759C10.7264 7.22062 10.6785 7.32278 10.5828 7.41407L8.34284 9.59199C8.28189 9.65285 8.2253 9.69415 8.17305 9.71588C8.12081 9.73762 8.06204 9.74849 7.99673 9.74849Z"
        fill="#0071E3" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class DownloadIconComponent {}
