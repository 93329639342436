import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  AppClient,
  AppInfo,
  FileParameter,
  GetUserInfoQueryResponse,
  InitializeUserCommandResponse,
  UploadImageVm,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class ReportAppApiService {
  client: AppClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new AppClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async getAppInfo(): Promise<AppInfo> {
    const observable$ = this.client.getAppInfo();
    return await lastValueFrom(observable$);
  }

  async getUserInfo(): Promise<GetUserInfoQueryResponse> {
    const observable$ = this.client.getUserInfoVm();
    return await lastValueFrom(observable$);
  }

  async setUserCurrentContextOrganizationId(organizationId: string) {
    const observable$ = this.client.setUserCurrentContextOrganizationId(organizationId);
    return await lastValueFrom(observable$);
  }

  async initilizeUser(): Promise<InitializeUserCommandResponse> {
    const observable$ = this.client.initializeAuthenticatgedUser();
    return await lastValueFrom(observable$);
  }

  uploadImage(file: FileParameter): Observable<UploadImageVm> {
    return this.client.uploadImage(file);
  }
  async removeImage(blobName: string): Promise<void> {
    const observable$ = this.client.removeImage(blobName);
    return await lastValueFrom(observable$);
  }

  getBlobUrl(blobName: string) {
    return `${this.appSettings.ReportApiBaseUrl}/app/image/${blobName}`;
  }
}
