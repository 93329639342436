import { Component } from '@angular/core';
import { AssetTableComponent } from '../../../shared/asset-table/asset-table.component';
import { IOption, facilityTypeOptions } from 'src/app/static-data/options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetUi } from '../../asset-list.component';
import {
  AssetType,
  CountryCodes,
  CreateFacilityAssetCommandParams,
  FacilityType,
} from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-facilities-table',
  templateUrl: './facilities-table.component.html',
  styleUrls: ['../../../shared/asset-table/asset-table.component.scss', './facilities-table.component.scss'],
  providers: [{ provide: AssetTableComponent, useExisting: FacilitiesTableComponent }],
})
export class FacilitiesTableComponent extends AssetTableComponent {
  headers = ['FACILITY', 'FACILITY TYPE', 'ORGANIZATION', 'COUNTRY'];
  typeOptions: IOption[] = facilityTypeOptions;

  initateAdd(event?: Event) {
    event?.stopPropagation();
    this.fc = {
      name: new FormControl<string>('', [Validators.required]),
      type: new FormControl<IOption | undefined>(this.typeOptions[0], [Validators.required]),
      country: new FormControl<IOption | undefined>(this.defaultCountry),
      organization: new FormControl<IOption | undefined>(
        {
          value: this.defaultOrganization,
          disabled: this.defaultOrganization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  iniateEdit(asset: AssetUi) {
    if (this.fc) return;

    this.onSelectedAssetChange.emit(asset);

    const organization =
      this.organizationOptions.find(org => org.label === asset.organizationName) || this.defaultOrganization;
    this.fc = {
      name: new FormControl<string>(asset.name, [Validators.required]),
      type: new FormControl<IOption | undefined>(
        this.typeOptions.find(type => type.label === asset.type || type.value === asset.type) || this.typeOptions[0],
        [Validators.required]
      ),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(country => country.label === asset.country) || this.defaultCountry,
        [Validators.required]
      ),
      organization: new FormControl<IOption | undefined>(
        {
          value: organization,
          disabled: organization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  handleOnFormSubmit() {
    const countryCode = Object.values(CountryCodes).find(code => code === this.fc?.country?.value?.value);
    const type = Object.values(FacilityType).find(type => type === this.fc?.type?.value?.value);
    if (this.fc && this.formgroup?.valid && countryCode && type) {
      const subOrganizationId =
        this.organizationId === this.fc.organization.value?.value ? undefined : this.fc.organization.value?.value;
      const params = new CreateFacilityAssetCommandParams({
        type: AssetType.Facility,
        name: this.fc.name.value || '',
        subType: type,
        organizationId: this.organizationId || '',
        subOrganizationId: subOrganizationId,
        countryCode: countryCode,
        versionValidFrom: new Date(),
      });
      this.onFormSubmit.emit(params);
      this.resetForm();
    } else this.handleSubmitRejection();
  }
}
