import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  DataProfileClient,
  CreateDataProfileCommandParams,
  CreateDataProfileCommandResponse,
  UpdateDataProfileCommandParams,
  UpdateDataProfileCommandResponse,
  DeleteDataProfileCommandResponse,
  DataProfileType,
  GetDataProfilesByTypeQueryResponse,
  GetAllDataProfilesQueryResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class DataProfileApiService {
  client: DataProfileClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new DataProfileClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createDataProfile(body: CreateDataProfileCommandParams): Promise<CreateDataProfileCommandResponse> {
    const observable$ = this.client.createDataProfile(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async updateDataProfile(body: UpdateDataProfileCommandParams): Promise<UpdateDataProfileCommandResponse> {
    const observable$ = this.client.updateDataProfile(body).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async deleteDataProfile(profileId: string): Promise<DeleteDataProfileCommandResponse> {
    const observable$ = this.client.deleteDataProfile(profileId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getDataProfilesByType(dataRecordType: DataProfileType): Promise<GetDataProfilesByTypeQueryResponse> {
    const observable$ = this.client.getDataProfilesByType(dataRecordType).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getAllDataProfiles(): Promise<GetAllDataProfilesQueryResponse> {
    const observable$ = this.client.getAllDataProfiles().pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }
}
