import { Component } from '@angular/core';
import { UsersStateService } from '../users-state.service';
import { UsersComponent } from '../users.component';
import { AllUsersStateService } from './all-users-state.service';

@Component({
  selector: 'esg-users',
  templateUrl: '../users.component.html',
  styleUrls: ['../users.component.scss'],
  providers: [{ provide: UsersStateService, useClass: AllUsersStateService }],
})
export class AllUsersComponent extends UsersComponent {}
