<table [style.grid-template-columns]="gridTemplateColumns">
  <thead>
    <tr>
      <th class="name">{{ sheet.name }}</th>
      <th *ngFor="let cell of sheet.headers; let isFirst = first" [class.number]="cell.number || false">
        <div class="header-cell-content">
          <span class="header-cell-label">{{ cell.label }}</span>
          <span class="header-cell-desc" [innerHTML]="sanitizeHtml(cell.description)"></span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let row of sheet.bodyRows">
      <tr *ngIf="editRecordId !== row.id">
        <td class="body-label" (dblclick)="!readonly && onEditing.emit(row.id)">{{ row.label }}</td>
        <td
          *ngFor="let cell of row.cells"
          [class.number]="cell.number || false"
          (dblclick)="!readonly && onEditing.emit(row.id)">
          {{ cell.number ? formatNumberCell(cell.value, cell.unit || '') : cell.value }}
        </td>
      </tr>
      <tr
        *ngIf="!readonly && editRecordId === row.id"
        class="record-form"
        esg-on-click-outside
        (onClickOutside)="handleOnFormClose()"
        (focusin)="handleFocusInside()">
        <td class="body-label">{{ row.label }}</td>
        <ng-container *ngTemplateOutlet="recordForm"></ng-container>
      </tr>
    </ng-container>
    <ng-template #recordForm><ng-content></ng-content></ng-template>
  </tbody>
</table>
