import { formatDate } from '@angular/common';
import { ITimePeriod, TimePeriod, TimePeriodType } from '../../api-client/report-api.generated';
import { addDaysToDate, datediff } from '../shared/utils/date';
import { globalQuarterOptions, globalMonthOptions } from './options';

export function getTimeLabelFromPeriod(timePeriod: TimePeriod, shift: number = 0): string {
  switch (timePeriod?.type) {
    case TimePeriodType.Annual: {
      return timePeriod?.year ? (timePeriod.year + shift).toString() : '';
    }
    case TimePeriodType.Quarterly: {
      if (!timePeriod?.period || !timePeriod?.year) return '';

      const period = mod(timePeriod.period + shift - 1, globalQuarterOptions.length) + 1;
      const yearShift = Math.floor((timePeriod.period + shift - 1) / globalQuarterOptions.length);
      const year = (timePeriod.year + yearShift).toString();
      const quarter = globalQuarterOptions.find(option => option.value === period?.toString())?.label || '';
      return quarter + ' ' + year;
    }
    case TimePeriodType.Monthly: {
      if (!timePeriod?.period || !timePeriod?.year) return '';

      const period = mod(timePeriod.period + shift - 1, globalMonthOptions.length) + 1;
      const yearShift = Math.floor((timePeriod.period + shift - 1) / globalMonthOptions.length);
      const year = (timePeriod.year + yearShift).toString();
      const month = globalMonthOptions.find(option => option.value === period?.toString())?.label || '';
      return month + ' ' + year;
    }
    case TimePeriodType.Custom: {
      const startDate = timePeriod?.customStart;
      const endDate = timePeriod?.customEnd;
      if (!startDate || !endDate) {
        return '';
      }
      const daysDifference = datediff(startDate, endDate) + 1;
      const daysToShift = daysDifference * shift;

      const shiftedStartDate = addDaysToDate(startDate, daysToShift);
      const shiftedEndDate = addDaysToDate(endDate, daysToShift);
      return (
        formatDate(shiftedStartDate, 'dd MMM yyyy', 'en_US') +
        ' \n- ' +
        formatDate(shiftedEndDate, 'dd MMM yyyy', 'en_US')
      );
    }
    default:
      return '';
  }
}

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function timePeriodToDates(timePeriod: ITimePeriod): [Date, Date] {
  const { type, year, period, customStart, customEnd } = timePeriod;

  switch (type) {
    case TimePeriodType.Annual: {
      if (!year) {
        throw new Error('Invalid year');
      }

      const startDate = new Date(year, 0, 1);
      const endDate = new Date(year, 11, 31);
      return [startDate, endDate];
    }
    case TimePeriodType.Quarterly: {
      if (!year) {
        throw new Error('Invalid year');
      }

      if (period === undefined || period < 1 || period > 4) {
        throw new Error('Invalid quarter period');
      }

      const quarterStartMonths = [0, 3, 6, 9];
      const quarterEndMonths = [2, 5, 8, 11];
      const quarterEndDays = [31, 30, 30, 31];

      const startDate = new Date(year, quarterStartMonths[period - 1], 1);
      const endDate = new Date(year, quarterEndMonths[period - 1], quarterEndDays[period - 1]);
      return [startDate, endDate];
    }
    case TimePeriodType.Monthly: {
      if (!year) {
        throw new Error('Invalid year');
      }

      if (period === undefined || period < 1 || period > 12) {
        throw new Error('Invalid month period');
      }

      const startDate = new Date(year, period - 1, 1);
      const endDate = new Date(year, period - 1, new Date(year, period, 0).getDate());
      return [startDate, endDate];
    }
    case TimePeriodType.Custom: {
      if (!customStart || !customEnd) {
        throw new Error('Invalid custom dates');
      }
      return [customStart, customEnd];
    }
    default:
      throw new Error('Invalid time period type');
  }
}
