<table>
  <thead>
    <tr>
      <th></th>
      <th>CONTENT</th>
      <th>STATUS</th>
      <th>VISIBLE</th>
      <th>RESPONSIBLE</th>
      <th>LAST EDITED</th>
      <th></th>
    </tr>
  </thead>
  <tbody cdkDropList class="drop-item-list" (cdkDropListDropped)="drop($event)">
    <tr
      *ngFor="let item of state.topicTopicItems"
      class="drop-item"
      [class.active]="state.selectedTopicItemId === item.id"
      cdkDrag
      (click)="handleTopicItemClick(item.id)">
      <td cdkDragHandle>
        <svg
          class="move-icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="1.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="9" y="1.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="9" y="6.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="4" y="6.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="4" y="11.5" width="3" height="3" rx="1" fill="#999999" />
          <rect x="9" y="11.5" width="3" height="3" rx="1" fill="#999999" />
        </svg>
      </td>
      <td [class.deactivated]="!item.visible">
        {{ item.name }}
      </td>
      <td>
        <esg-status-select
          class="status-dropdown"
          [options]="statusOptions"
          [value]="getInitialStatus(item.status)"
          (onChange)="updateStatus(item.id, $event)"></esg-status-select>
      </td>
      <td>
        <esg-toggle-switch
          class="visible-toggle"
          [checked]="item.visible"
          (onCheck)="updateVisibility(item.id, !item.visible)"></esg-toggle-switch>
      </td>
      <td>
        <esg-select
          class="responsible-person-dropdown"
          size="small"
          [options]="state.responsiblePersonOptions"
          [value]="item.responsiblePerson"
          (onChange)="updateResponsiblePerson(item.id, $event)"></esg-select>
      </td>
      <td>
        <span [class.deactivated]="!item.visible">{{ item.modifiedDate | date : 'dd.MMM' }}</span>
        <span [class.deactivated]="!item.visible" class="font-italic">{{ item.modifiedDate | date : 'HH:mm' }}</span>
      </td>
      <td>
        <div class="btn-cell">
          <button class="btn-content">Content</button>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 8C13 7.79839 12.9182 7.6129 12.7628 7.46774L6.28425 1.20968C6.13701 1.07258 5.95706 1 5.74438 1C5.3272 1 5 1.31452 5 1.73387C5 1.93548 5.0818 2.12097 5.21268 2.25806L11.1677 8L5.21268 13.7419C5.0818 13.879 5 14.0565 5 14.2661C5 14.6855 5.3272 15 5.74438 15C5.95706 15 6.13701 14.9274 6.28425 14.7823L12.7628 8.53226C12.9182 8.37903 13 8.20161 13 8Z"
              fill="#999999" />
          </svg>
        </div>
      </td>
    </tr>
  </tbody>
</table>
