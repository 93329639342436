<div class="container" [class.not-valid]="!isValid && showValidation">
  <label *ngIf="label" for="input">
    {{ label }}
    <span *ngIf="!isRequired && type !== 'color' && showOptionalText">(Optional)</span>
  </label>
  <div class="input-container">
    <input
      #input
      esgKeyboardShortcuts
      (onEscapeKey)="handleEscape()"
      (onEnterKey)="handleEnter()"
      [keyOnlyOnFocus]="true"
      [class]="['input-field', variant, size]"
      [class.color]="type === 'color'"
      [class.auto-focus]="autoFocus"
      [value]="getValue"
      type="text"
      autocomplete="off"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [attr.maxlength]="maxLength"
      [ngStyle]="inputStyle"
      (keyup)="handleOnChange(input.value)"
      (focusout)="handleSubmit()" />
    <span class="icon" *ngIf="showClearText && getValue && !isDisabled">
      <esg-icon-clear (click)="handleClearText($event)"></esg-icon-clear>
    </span>
    <span class="percent" *ngIf="showPercent">%</span>
    <span
      *ngIf="type === 'color'"
      class="color-preview"
      [style.background]="getValue && isValid ? getValue : placeholder"></span>
  </div>
  <span *ngIf="errorMessage && !isValid && showValidation" class="error-message">{{ errorMessage }}</span>
</div>
