<esg-records-table
  [headerCells]="headerCells"
  [bodyRows]="dataRecordsRows"
  [newlyAddedRowsIds]="newlyAddedRowsIds"
  [readOnly]="readOnly"
  [addOnly]="addOnly"
  [isAdding]="recordFormgroup !== undefined && !editRecordId"
  [editRecordId]="editRecordId"
  [stickyHeaderTopOffset]="stickyHeaderTopOffset"
  [intersectionObserverRootMargin]="intersectionObserverRootMargin"
  [stickyHeaderPadding]="stickyHeaderPadding"
  (onAdding)="handleOnIniateAddRecord()"
  (onFormClose)="handleCloseForm()"
  (onEditing)="handleOnInitateEditRecord($event)"
  (onSubmit)="handleOnSubmit()"
  (onDelete)="handleOnDelete($event)">
  <ng-container *ngIf="recordFc">
    <td class="input-cell" [style.grid-column]="'span 2'" [class.error]="hasSubmitted && !recordFc.employee.valid">
      <esg-textbox-form
        *ngIf="recordFc.employee"
        class="input-text"
        placeholder="Name or ID"
        [fc]="recordFc.employee"
        variant="ghost-table"></esg-textbox-form>
    </td>
    <td class="input-cell">
      <esg-table-select
        *ngIf="recordFc.yearOfBirth"
        class="input-dropdown"
        [options]="ageOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.yearOfBirth.value || undefined"
        (onChange)="handleDropdownChange(recordFc.yearOfBirth, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-table-select
        *ngIf="recordFc.gender"
        class="input-dropdown"
        [options]="genderOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.gender.value || undefined"
        (onChange)="handleDropdownChange(recordFc.gender, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-table-select
        *ngIf="recordFc.employment"
        class="input-dropdown"
        [required]="false"
        [options]="employmentOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.employment.value || undefined"
        (onChange)="handleDropdownChange(recordFc.employment, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-table-select
        *ngIf="recordFc.contract"
        class="input-dropdown"
        [required]="false"
        [options]="contractOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.contract.value || undefined"
        (onChange)="handleDropdownChange(recordFc.contract, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-table-select
        *ngIf="recordFc.positionLevel"
        class="input-dropdown"
        [options]="positionLevelOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.positionLevel.value || undefined"
        (onChange)="handleDropdownChange(recordFc.positionLevel, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-table-select
        *ngIf="recordFc.location"
        class="input-dropdown"
        [required]="false"
        [options]="locationOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.location.value || undefined"
        (onChange)="handleDropdownChange(recordFc.location, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-table-select
        *ngIf="recordFc.region"
        class="input-dropdown"
        [required]="false"
        [options]="regionOptions"
        [fitLongestLabel]="false"
        [value]="recordFc.region.value || undefined"
        (onChange)="handleDropdownChange(recordFc.region, $event)"></esg-table-select>
    </td>
    <td class="input-cell">
      <esg-textbox-form
        *ngIf="recordFc.salary"
        class="input-text"
        type="number"
        placeholder="Number"
        [fc]="recordFc.salary"
        variant="ghost-table"
        [inputStyle]="{ 'text-align': 'right' }"
        (onChange)="recordFc.salary.setValue($event)"></esg-textbox-form>
    </td>
  </ng-container>
</esg-records-table>
