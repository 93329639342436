import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatUnitWithUnicode',
  standalone: true,
})
export class FormatUnitWithUnicodePipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) return '';

    const unicodeFormats: { [key: string]: string } = {
      CO2: 'CO₂',
      CH4: 'CH₄',
      N2O: 'N₂O',
      SOx: 'SOₓ',
      NOx: 'NOₓ',
      'PM2.5': 'PM₂.₅',

      m2: 'm²',
      m3: 'm³',
    };

    const regex = new RegExp(Object.keys(unicodeFormats).join('|'), 'g');

    return value.replace(regex, match => unicodeFormats[match]);
  }
}
