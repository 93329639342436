import { Injectable } from '@angular/core';
import {
  AgeGroup,
  AssetType,
  CreateEmployeeNewHiresAndTurnoversDataRecordCommandParams,
  DataRecordCategory,
  DataRecordType,
  EmploymentEvent,
  Gender,
  ICreateEmployeeNewHiresAndTurnoversDataRecordCommandParams,
  InputType,
  PositionLevel,
} from 'src/api-client/report-api.generated';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import {
  IOption,
  AgeGroupOptions,
  EmploymentEventOptions,
  GenderOptions,
  PositionLevelOptions,
} from 'src/app/static-data/options';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { RecordsStateService } from '../records-state.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegionClientApiService } from 'src/app/api-client/report-api/region-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  ExcelImportValidationMessage,
  FieldConfig,
  ImportDataOutput,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class NewHiresAndTurnoverStateService extends RecordsStateService {
  title = 'New Hires and Turnovers';
  recordCategory = DataRecordCategory.EmployeesNewHiresAndTurnovers;

  assetCategories = [AssetType.Organization, AssetType.Facility];

  employmentEventOptions = EmploymentEventOptions;
  genderOptions = GenderOptions;
  ageGroupOptions = AgeGroupOptions;
  positionLevelOptions = PositionLevelOptions;
  regionOptions: IOption[] = [];

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    assetApiService: AssetApiService,
    dataRecordService: DataRecordApiService,
    dataCategoryApiService: DataCategoryApiService,
    notificationService: NotificationService,
    router: Router,
    route: ActivatedRoute,
    private regionApiService: RegionClientApiService
  ) {
    super(
      appInfo,
      appConfig,
      assetApiService,
      dataRecordService,
      dataCategoryApiService,
      notificationService,
      router,
      route
    );
  }

  importFromExcelEnabled = true;
  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'employmentEvent', title: 'Action', type: 'string' },
    { propertyName: 'gender', title: 'Gender', type: 'string' },
    { propertyName: 'ageGroup', title: 'Age Group', type: 'string' },
    { propertyName: 'positionLevel', title: 'Position Level', type: 'string' },
    { propertyName: 'region', title: 'Region', type: 'string' },
  ];

  async importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    this.importingInProgressLabel = 'Importing...';
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) {
      validationMessages.push(assetValidation);
    }

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'employmentEvent',
      this.employmentEventOptions,
      data.records,
      'Action'
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'gender',
      this.genderOptions,
      data.records,
      'Gender'
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'ageGroup',
      this.ageGroupOptions,
      data.records,
      'Age Group'
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'positionLevel',
      this.positionLevelOptions,
      data.records,
      'Position Level'
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'region',
      this.regionOptions,
      data.records,
      'Region'
    );

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    var recordsToAdd = data.records.length;
    var recordsAdded = 0;
    this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
    try {
      const addRecordPromises = data.records.map(async (record, index) => {
        const asset = this.getAssetOptionColumn(record.asset?.value);

        const employmentEventOption = this.getExcelOptionColumn(
          'employmentEvent',
          this.employmentEventOptions,
          record,
          'Action'
        );
        const genderOption = this.getExcelOptionColumn('gender', this.genderOptions, record, 'Gender');
        const ageGroupOption = this.getExcelOptionColumn('ageGroup', this.ageGroupOptions, record, 'Age Group');
        const positionLevelOption = this.getExcelOptionColumn(
          'positionLevel',
          this.positionLevelOptions,
          record,
          'Position Level'
        );
        const regionOption = this.getExcelOptionColumn('region', this.regionOptions, record, 'Region');

        const body: ICreateEmployeeNewHiresAndTurnoversDataRecordCommandParams = {
          type: DataRecordType.EmployeeNewHiresAndTurnovers,
          dataSetId: dataSetId,
          assetId: asset.value,
          inputType: InputType.ImportedFromExcel,
          startDate: record.startDate?.value,
          employmentEvent: EmploymentEvent[employmentEventOption.value as keyof typeof EmploymentEvent],
          gender: Gender[genderOption.value as keyof typeof Gender],
          ageGroup: AgeGroup[ageGroupOption.value as keyof typeof AgeGroup],
          positionLevel: PositionLevel[positionLevelOption.value as keyof typeof PositionLevel],
          regionId: regionOption.value,
        };

        var result = await this.dataRecordService.createDataRecord(
          new CreateEmployeeNewHiresAndTurnoversDataRecordCommandParams(body)
        );
        if (!result.success) {
          this.notificationService.showError(
            `New Hires & Turnover from row ${index + 1} not added`,
            result.message || 'Unknown error'
          );
        }
        ++recordsAdded;
        this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
        return result;
      });

      var response = await Promise.all(addRecordPromises);
      response.forEach((res, index) => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });

      await this.loadRecords();
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
      this.importDataFromExcelClose();
    } catch (e) {
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
    }
  }

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getRegions(), this.getDataCategoryDetails()]);
  }

  async fetchOptions() {
    await Promise.all([this.getAssetOptions(), this.getRegions()]);
  }

  async getRegions() {
    const response = await this.regionApiService.getAllRegionNames();
    this.regionOptions = response.result.map(region => ({ value: region.id, label: region.name }));
  }
}
