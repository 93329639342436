import { Injectable } from '@angular/core';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { AssignedTaskUi } from './assigned-tasks/assigned-tasks.component';
import { IOption, topicItemStatusOptions } from 'src/app/static-data/options';
import { AppInfoService } from 'src/app/core/app-info.service';
import { Subscription } from 'rxjs';

@Injectable()
export class HomePageStateService {
  private contextSubscription?: Subscription;
  tasks: AssignedTaskUi[] = [];
  statusOptions: IOption[] = topicItemStatusOptions;
  loading: boolean = false;

  constructor(public topicItemServiceApi: TopicItemApiService, public appInfoState: AppInfoService) {
    this.contextSubscription = this.appInfoState.organizationContext$.subscribe(org => {
      if (org) {
        this.tasks = [];
        this.loadTopicItems();
      }
    });
  }

  async loadTopicItems() {
    this.loading = true;
    const topicItems = await this.topicItemServiceApi.getResponsiblePersonTopicItems();
    this.tasks = topicItems.result.topicItems.map(item => {
      const status = this.statusOptions.find(opt => opt.value === item.status);
      return {
        reportId: item.reportId,
        reportName: item.reportName,
        topicId: item.topicId,
        topicItemId: item.topicItemId,
        name: item.name,
        statusLabel: status?.label || item.status,
        statusColor: status?.color || 'transparent',
        lastEdited: item.lastEdited,
      };
    });
    this.loading = false;
  }

  ngOnDestroy() {
    this.contextSubscription?.unsubscribe();
  }
}
