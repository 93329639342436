import { Component, Input, SimpleChanges } from '@angular/core';
import { IndicatorDetailsStateService } from './indicator-details-state.service';
import { CurrencyCodes, SectorVm } from 'src/api-client/report-api.generated';
import { ObjectArray } from 'src/app/shared/utils/object-array';

@Component({
  selector: 'esg-indicator-details',
  templateUrl: './indicator-details.component.html',
  styleUrl: './indicator-details.component.scss',
  providers: [IndicatorDetailsStateService],
})
export class IndicatorDetailsComponent {
  @Input({ required: true }) indicatorId!: string;
  @Input() organizationCurrency?: CurrencyCodes;
  @Input() sectorTypes: ObjectArray<SectorVm> = {};
  @Input() updatingCalculation: boolean = false;

  constructor(public state: IndicatorDetailsStateService) {}

  ngOnInit(): void {
    this.state.init(this.indicatorId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organizationCurrency) {
      this.state.setOrganizationCurrency(changes.organizationCurrency.currentValue);
    }
  }

  handleOnTabClick(tabValue: string) {
    this.state.setActiveTab(tabValue);
  }
}
