import { Component, Input } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { NotificationLinkUi } from '../../ui/notification/notification.component';

@Component({
  selector: '[esg-notification-wrapper]',
  template: `
    <esg-notification
      [title]="title || ''"
      [message]="message || ''"
      [link]="link"
      [variant]="variant"
      (onClose)="handleClose()"></esg-notification>
  `,
  styles: [
    `
      :host {
        display: flex;
        pointer-events: all;
      }
    `,
  ],
})
export class NotificationWrapperComponent extends Toast {
  @Input() link?: NotificationLinkUi;

  variant: 'error' | 'info' | 'success' | 'warning' = 'info';

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
    switch (this.toastPackage.toastType) {
      case 'notification-error':
        this.variant = 'error';
        break;
      case 'notification-info':
        this.variant = 'info';
        break;
      case 'notification-success':
        this.variant = 'success';
        break;
      case 'notification-warning':
        this.variant = 'warning';
        break;
      default:
        this.variant = 'info';
        break;
    }
  }

  handleClose() {
    this.remove();
  }
}
