import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';
import { RecordsComponent } from '../records.component';
import { Component } from '@angular/core';

@Component({ selector: 'esg-fuel-combustion', template: '' })
export abstract class FuelCombustionComponent extends RecordsComponent {
  tabs: ITabItem[] = [
    { value: 'vessels', label: 'Vessels', link: ['../', 'vessels'] },
    {
      value: 'vehicles-crafts-machinery',
      label: 'Vehicles, Crafts & Machinery',
      link: ['../', 'vehicles-crafts-machinery'],
    },
  ];
}
