import { Component, OnInit } from '@angular/core';
import { AppInfoService } from 'src/app/core/app-info.service';
import { SupportContentListStateService } from './support-content-list/support-content-list-state.service';
import { SupportPageStateService } from './support-page-state.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

export interface SupportTopicUi {
  id: string;
  name: string;
  count: number;
}

@Component({
  selector: 'esg-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
  providers: [SupportPageStateService, SupportContentListStateService],
})
export class SupportPageComponent implements OnInit {
  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(
    private appConfig: AppConfigurationService,
    public state: SupportPageStateService,
    public appInfoState: AppInfoService
  ) {}

  ngOnInit(): void {
    this.state.init();
  }
}
