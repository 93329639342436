import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  GenericColumnType,
  GenericColumnUi,
  GenericRowUi,
} from 'src/app/shared/table/generic-table/generic-table.types';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'esg-generic-table-example',
  templateUrl: './generic-table-example.component.html',
  styleUrls: ['./generic-table-example.component.scss'],
})
export class GenericTableExampleComponent implements OnInit {
  columns: GenericColumnUi[] = [];
  rows: GenericRowUi[] = [];
  jsonPlaceholder: string = `{
    "columns": [
      { "header": "Name", "description": "The full name of the person", "columnId": "name", "type": "Generic" },
      { "header": "Age", "description": "Age in years", "columnId": "age", "type": "Numeric", "width": "15%"},
      {
        "header": "Role",
        "description": "The role of the person",
        "columnId": "role",
        "type": "Selector",
        "options": [
          { "value": "dev", "label": "Developer" },
          { "value": "des", "label": "Designer" }
        ]
      }
    ],
    "rows": [
      {
        "rowId": "1",
        "values": [
          { "columnId": "name", "value": "John Doe" },
          { "columnId": "age", "value": 30 },
          { "columnId": "role", "value": "dev" }
        ]
      },
      {
        "rowId": "2",
        "values": [
          { "columnId": "name", "value": "Jane Smith" },
          { "columnId": "age", "value": 25 },
          { "columnId": "role", "value": "des" }
        ]
      }
    ]
  }`;

  jsonInput = new FormControl<string>(this.jsonPlaceholder, [Validators.required]);

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.parseJson();
  }

  parseJson(): void {
    try {
      const inputObj = JSON.parse(this.jsonInput.value || '');
      this.columns = inputObj.columns;
      this.rows = inputObj.rows;
      this.jsonInput.setValue(JSON.stringify(inputObj, null, 2));
    } catch (e) {
      console.error('Parsing error:', e);
      this.notificationService.showError('Error parsing JSON. Check the console for more details.');
    }
  }

  updateJson(): void {
    const updatedData = {
      columns: this.columns,
      rows: this.rows,
    };

    this.jsonInput.setValue(JSON.stringify(updatedData, null, 2));
  }

  handleOnRowChange(row: GenericRowUi): void {
    const rowIndex = this.rows.findIndex(r => r.rowId === row.rowId);
    if (rowIndex !== -1) {
      this.rows = [...this.rows.slice(0, rowIndex), row, ...this.rows.slice(rowIndex + 1)];
    }
    this.updateJson();
  }

  handleOnAddRow(belowRowId?: string): void {
    const newRow: GenericRowUi = {
      rowId: uuidv4(),
      values: this.columns.map(column => ({
        columnId: column.columnId,
        value: column.type === GenericColumnType.Numeric || column.type === GenericColumnType.Currency ? 0 : '',
        unit: '',
      })),
    };

    const rowIndex = this.rows.findIndex(row => row.rowId === belowRowId);

    if (rowIndex !== -1) {
      this.rows = [...this.rows.slice(0, rowIndex + 1), newRow, ...this.rows.slice(rowIndex + 1)];
    } else {
      this.rows = [newRow, ...this.rows];
    }
    this.updateJson();
  }

  handleOnAddColumn(afterColumnId?: string): void {
    const newColumn: GenericColumnUi = {
      header: `Column ${this.columns.length + 1}`,
      description: 'New column',
      columnId: uuidv4(),
      type: GenericColumnType.Generic,
    };

    const columnIndex = this.columns.findIndex(column => column.columnId === afterColumnId);
    if (columnIndex !== -1) {
      this.columns = [...this.columns.slice(0, columnIndex + 1), newColumn, ...this.columns.slice(columnIndex + 1)];
      this.rows = this.rows.map(row => ({
        ...row,
        values: [
          ...row.values.slice(0, columnIndex + 1),
          { columnId: newColumn.columnId, value: '' },
          ...row.values.slice(columnIndex + 1),
        ],
      }));
    } else {
      this.columns = [newColumn, ...this.columns];
      this.rows = this.rows.map(row => ({
        ...row,
        values: [{ columnId: newColumn.columnId, value: '' }, ...row.values],
      }));
    }

    this.updateJson();
  }

  handleOnDeleteRow(rowId: string): void {
    this.rows = this.rows.filter(row => row.rowId !== rowId);
    this.updateJson();
  }

  handleOnDeleteColumn(columnId: string): void {
    this.columns = this.columns.filter(column => column.columnId !== columnId);
    this.rows = this.rows.map(row => ({
      ...row,
      values: row.values.filter(value => value.columnId !== columnId),
    }));

    this.updateJson();
  }

  handleOnColumnsChange(columns: GenericColumnUi[]): void {
    this.updateJson();
  }
}
