<div esg-on-click-outside (onClickOutside)="collapse()" class="container">
  <div #selector class="select-wrapper" (click)="toggleCollapse($event)">
    <div class="selected-option" [class.active]="!isCollapsed">
      <div class="label-wrapper">
        {{ selectedLabel }}
      </div>
      <esg-chevron color="#183642"></esg-chevron>
    </div>
    <div *ngIf="!isCollapsed" class="options">
      <div #scrollWrapper class="scroll-wrapper" [style.max-height]="maxHeight">
        <a *ngFor="let item of items" class="option" [routerLink]="item.link" routerLinkActive="selected">
          <esg-checkmark></esg-checkmark>
          <span class="option-label">
            {{ item.label }}
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
