import { Injectable } from '@angular/core';
import { AssetType, DataRecordCategory } from 'src/api-client/report-api.generated';
import { IOption, SpillTypeOptions } from 'src/app/static-data/options';
import { RecordsStateService } from '../records-state.service';

@Injectable()
export class SpillsToEnvironmentStateService extends RecordsStateService {
  recordCategory = DataRecordCategory.BiodiversitySpillsToEnvironment;

  assetCategories = [AssetType.Vessel, AssetType.Organization];
  spillsOptions = SpillTypeOptions;
  hazardiousOptions: IOption[] = [
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes' },
  ];
}
