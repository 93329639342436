import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RecordFormTableComponent } from '../shared/record-form-table/record-form-table.component';
import { RecordsStateService } from './records-state.service';
import { CreateDataRecordCommandParams } from 'src/api-client/report-api.generated';

@Component({ template: '' })
export abstract class RecordsComponent {
  @Input() addOnly: boolean = false;
  @Output() onSubmit = new EventEmitter<CreateDataRecordCommandParams>();

  @ViewChild(RecordFormTableComponent)
  private formTable?: RecordFormTableComponent;

  get formGroup() {
    return this.formTable?.recordFormgroup;
  }

  constructor(public state: RecordsStateService) {}

  ngOnInit(): void {
    if (this.addOnly) {
      this.state.initOptions();
    } else {
      this.state.init();
    }
  }

  handleOnSubmit() {
    if (this.formTable) {
      const params = this.formTable.getParams();
      if (params instanceof CreateDataRecordCommandParams) {
        this.onSubmit.emit(params);
      }
    }
  }
}
