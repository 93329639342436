<table>
  <thead>
    <tr>
      <th>CONTENT</th>
      <th>STATUS</th>
      <th>LAST EDITED</th>
      <th>REPORT</th>
      <th></th>
    </tr>
  </thead>
  <tbody class="drop-item-list">
    <tr *ngFor="let item of tasks" class="drop-item" (click)="handleTaskClick(item)">
      <td>
        {{ item.name }}
      </td>
      <td class="status">
        <span class="color-circle" [style.background]="item.statusColor"></span>
        <span>{{ item.statusLabel }}</span>
      </td>
      <td class="date">
        <span>{{ item.lastEdited | date : 'dd. MMM' }}</span>
        <span class="font-italic">{{ item.lastEdited | date : 'HH:mm' }}</span>
      </td>
      <td>
        {{ item.reportName }}
      </td>
      <td>
        <div class="arrow-cell">
          Go to report
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 8C13 7.79839 12.9182 7.6129 12.7628 7.46774L6.28425 1.20968C6.13701 1.07258 5.95706 1 5.74438 1C5.3272 1 5 1.31452 5 1.73387C5 1.93548 5.0818 2.12097 5.21268 2.25806L11.1677 8L5.21268 13.7419C5.0818 13.879 5 14.0565 5 14.2661C5 14.6855 5.3272 15 5.74438 15C5.95706 15 6.13701 14.9274 6.28425 14.7823L12.7628 8.53226C12.9182 8.37903 13 8.20161 13 8Z"
              fill="#999999" />
          </svg>
        </div>
      </td>
    </tr>
  </tbody>
</table>
