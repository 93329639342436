import { Component } from '@angular/core';

@Component({
  selector: 'esg-onboarding-example',
  templateUrl: './onboarding-example.component.html',
  styleUrl: './onboarding-example.component.scss',
})
export class OnboardingExampleComponent {
  isDialogOpen = false;
}
