import { Component, OnInit, Input } from '@angular/core';
import { IUser } from '../users-state.service';
import { UserRoleOptions } from '../../../../static-data/options';

@Component({
  selector: 'esg-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  @Input({ required: true }) user!: IUser;

  constructor() {}

  ngOnInit(): void {}

  getUserRoleLabel() {
    return UserRoleOptions.find(option => option.value === this.user.role)?.label || '';
  }
}
