import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esg-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  host: {
    '[class]': '[variant, size]',
    '(click)': 'handleClick($event)',
    '[class.disabled]': 'disabled',
    '[class.active]': '!isCollapsed',
  },
})
export class SelectFieldComponent {
  @Input() value: string | undefined;
  @Input() variant: 'standard' | 'ghost' | 'ghost-table' = 'standard';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() placeholder = 'Please select';
  @Input() longestLabel = '';
  @Input() isCollapsed: boolean = true;
  @Input() disabled: boolean = false;
  @Input() optional: boolean = false;
  @Output() onToggleCollapse = new EventEmitter();

  handleClick(event: Event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.onToggleCollapse.emit();
    }
  }
}
