import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { IDataSeriesUi } from './types';

import * as Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import { numberToFormattedNumberString } from '../../utils/number-converters';
HighchartsExporting(Highcharts);

function generateTooltip(title: string, points: Array<Highcharts.TooltipFormatterContextObject>) {
  var renderPoint = (point: Highcharts.TooltipFormatterContextObject): string => {
    return `<div class="target">
    <div class="circle" style="background-color: ${point.color}"></div>
    <span class="name">${point.series.name}:</span>
    <span class="value">${point.y || point.y === 0 ? numberToFormattedNumberString(point.y, 1) : ''}</span>
  </div>`;
  };

  var renderErrorMessage = (errorMessage: string) => {
    return `<div class='error-message'>${errorMessage}</div>`;
  };

  var pointWithError = points.find(p => !!(p.point as any).errorMessage);

  return `
  <div class="esg-chart-tooltip">
    <div class="title">${title}</div>
    <div class="content">
      ${
        !pointWithError
          ? points.reduce((prev, curr) => prev + renderPoint(curr), '')
          : renderErrorMessage((pointWithError.point as any).errorMessage)
      }
    </div>
  </div>
  `;
}

@Component({
  selector: 'esg-chart-v3',
  templateUrl: './chart-v3.component.html',
  styleUrls: ['./chart-v3.component.scss'],
})
export class ChartV3Component implements OnInit, OnChanges {
  @Input({ required: true }) name!: string;
  @Input({ required: true }) isLoading!: boolean;
  @Input({ required: true }) xLabel!: string;
  @Input({ required: true }) yLabel!: string;
  @Input({ required: true }) valueSeries!: IDataSeriesUi[];
  @Input({ required: true }) targetSeries!: IDataSeriesUi;
  @Output() chartIntanceHandler: EventEmitter<Highcharts.Chart> = new EventEmitter<Highcharts.Chart>();

  update = false;
  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: 'transparent',
    },
    title: { text: this.name },
    series: [],
    tooltip: {
      outside: true,
      shared: true,
      useHTML: true,
      formatter: function () {
        return generateTooltip(this.x as string, this.points || []); //s;
      },
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          className: 'esg-export-context-menu',
          menuItems: ['downloadPNG', 'downloadSVG'],
        },
      },
      fallbackToExportServer: false,
    },
    plotOptions: {
      series: {
        pointPlacement: 0, // Set the point placement to 0
        events: {
          click: undefined,
        },
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: 'gray',
        dashStyle: 'ShortDot',
      },
    },
  };

  ngOnInit(): void {}

  hanleOnCreateChart(chart: Highcharts.Chart) {
    this.chartIntanceHandler.emit(chart);
  }

  updateSeries = (valueSeriesArray: IDataSeriesUi[], targetSeries: IDataSeriesUi) => {
    let updatedSeries: Highcharts.SeriesOptionsType[] = [];

    if (this.targetSeries.series.length) {
      updatedSeries.push({
        type: 'line',
        name: 'Target',
        data: targetSeries.series.map(di => {
          // return { x: di.name, y: di.value };
          return { y: di.value, marker: { radius: 1 } };
        }),
      });
    }

    updatedSeries = updatedSeries.concat(
      valueSeriesArray.map<Highcharts.SeriesOptionsType>((valueSeries, index) => {
        return {
          type: 'line',
          name: valueSeries.name,
          visible: !valueSeries.hidden,
          // color: 'green',
          data: valueSeries.series.map(di => {
            return {
              y: di.value,
              marker: { radius: 3 },
              color: di.errorMessage ? 'red' : undefined,
              errorMessage: di.errorMessage,
            };
          }),
        };
      })
    );

    if (valueSeriesArray.length && valueSeriesArray[0].series.length) {
      this.chartOptions = {
        ...this.chartOptions,
        xAxis: {
          ...this.chartOptions.xAxis,
          categories: valueSeriesArray[0].series.map(s => s.name),
        },
      };
    }

    this.chartOptions = {
      ...this.chartOptions,
      series: updatedSeries,
    };
    this.update = true;
  };

  ngOnChanges(changes: SimpleChanges): void {
    const valueSeries: SimpleChange = changes['valueSeries'];
    const targetSeries: SimpleChange = changes['targetSeries'];
    if (
      valueSeries?.previousValue !== valueSeries?.currentValue ||
      targetSeries?.previousValue !== targetSeries?.currentValue
    ) {
      this.updateSeries(valueSeries.currentValue, targetSeries.currentValue);
    }
  }
}
