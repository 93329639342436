import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-preview-icon',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (mouseenter)="toggleHover()"
      (mouseleave)="toggleHover()">
      >
      <path
        d="M8 3.8C11.19 3.8 14 6.8 15 8C14 9.18 11.21 12.2 8 12.2C4.79 12.2 2 9.2 1 8C2 6.82 4.79 3.8 8 3.8ZM8 3C3.58 3 0 8 0 8C0 8 3.58 13 8 13C12.42 13 16 8 16 8C16 8 12.41 3 8 3Z"
        fill="#183642" />
      <path
        d="M8 5.8C8.43512 5.8 8.86046 5.92904 9.22225 6.17078C9.58404 6.41252 9.86602 6.7561 10.0325 7.1581C10.1991 7.56009 10.2426 8.00244 10.1577 8.4292C10.0728 8.85596 9.86331 9.24796 9.55563 9.55563C9.24796 9.86331 8.85596 10.0728 8.4292 10.1577C8.00244 10.2426 7.56009 10.1991 7.15809 10.0325C6.7561 9.86603 6.41251 9.58405 6.17077 9.22226C5.92903 8.86047 5.8 8.43512 5.8 8C5.80263 7.41733 6.03526 6.85929 6.44727 6.44728C6.85928 6.03527 7.41733 5.80263 8 5.8ZM8 5C7.40665 5 6.82664 5.17596 6.33329 5.5056C5.83994 5.83524 5.45542 6.30378 5.22836 6.85196C5.0013 7.40014 4.94189 8.00334 5.05765 8.58528C5.1734 9.16722 5.45912 9.70177 5.87868 10.1213C6.29823 10.5409 6.83278 10.8266 7.41472 10.9424C7.99667 11.0581 8.59987 10.9987 9.14805 10.7716C9.69622 10.5446 10.1648 10.1601 10.4944 9.66672C10.824 9.17337 11 8.59334 11 8C11 7.20435 10.6839 6.44129 10.1213 5.87868C9.55871 5.31607 8.79565 5 8 5Z"
        fill="#183642" />
      <path
        d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
        fill="#183642" />
    </svg>
  `,
  styles: [],
})
export class PreviewIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';
  @Input() hoverColor: string = '#0071E3';

  currentColor: string = this.color;
  hovered: boolean = false;

  toggleHover() {
    this.hovered = !this.hovered;
    this.currentColor = this.hovered ? this.hoverColor : this.color;
  }
}
