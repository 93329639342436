import { IOption } from 'src/app/static-data/options';
import { Injectable } from '@angular/core';
import {
  MoveTopicItemCommandParams,
  DeleteTopicItemCommandParams,
  TopicItemStatus,
  UpdateTopicItemStatusCommandParams,
  TimePeriod,
  TopicItemType,
  UpdateTopicItemResponsiblePersonCommandParams,
  UpdateTopicItemVisibilityCommandParams,
  NewIndicatorTopicItemVm,
} from 'src/api-client/report-api.generated';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { TopicItemUi } from './topic-item-list/topic-item-list.component';
import { AppInfoService } from 'src/app/core/app-info.service';
import { UserApiService } from 'src/app/api-client/report-api/user-api-service';
import { DataValuesUi, DataValueUi, TopicItemContentUi } from './topic-item-details/topic-item-details-state.service';
import {
  IContent,
  SectionContentViewModel,
} from 'src/app/shared/components/section-content-preview/section-content-preview.component';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { IUnit, UnitService } from 'src/app/shared/services/unit/unit.service';
import { Subscription } from 'rxjs';
import { ObjectArray } from 'src/app/shared/utils/object-array';
import { ReportDetailsStateService } from '../report-details-state.service';

@Injectable()
export class TopicDetailsStateService {
  private unitsSubscription?: Subscription;

  isLoadingTopicItems = false;
  reportId?: string;
  mainLevelId?: string;
  topicId?: string;
  topicName?: string;
  selectedTopicItem?: TopicItemContentUi;
  selectedType!: TopicItemType;
  selectedTopicItemId?: string;
  topicTopicItems?: TopicItemUi[];
  timePeriod?: TimePeriod;
  topicItemDetailsFade: boolean = false;

  responsiblePersonOptions: IOption[] = [];
  indicatorPreviewViewModel?: SectionContentViewModel;
  units: ObjectArray<IUnit> = {};

  get isSelectedTopicItemArticle() {
    return this.selectedType === TopicItemType.Article;
  }

  constructor(
    private topicItemApiService: TopicItemApiService,
    private appInfoService: AppInfoService,
    private appApiService: ReportAppApiService,
    private userApiService: UserApiService,
    private unitService: UnitService
  ) {
    this.unitsSubscription = this.unitService.units$.subscribe(units => {
      this.units = units;
      this.setIndicatorValueDescriptions();
    });
  }

  init(reportId: string, mainLevelId: string, topicId: string, topicName: string, timePeriod: TimePeriod) {
    this.topicName = topicName;
    this.timePeriod = timePeriod;
    this.allTopicItems(reportId, mainLevelId, topicId);
    if (this.appInfoService.organizationContext.value) {
      this.userApiService.getUsersByOrganizationId(this.appInfoService.organizationContext.value).subscribe(result => {
        this.responsiblePersonOptions = [
          { value: '', label: 'Unassigned' },
          ...result.result.map(u => ({ value: u.id, label: u.name })),
        ];
      });
    }
  }

  clearTopicItems() {
    this.topicTopicItems = undefined;
  }

  loadTopicTopicItems() {
    this.allTopicItems(this.reportId || '', this.mainLevelId || '', this.topicId || '');
  }

  allTopicItems(reportId: string, mainLevelId: string, topicId: string) {
    this.reportId = reportId;
    this.mainLevelId = mainLevelId;
    this.topicId = topicId;
    this.isLoadingTopicItems = true;
    this.topicItemApiService.allTopicItems(reportId, mainLevelId, topicId).subscribe(result => {
      this.topicTopicItems = result.map<TopicItemUi>(u => ({
        id: u.id,
        name: u.name,
        status: u.status,
        visible: u.visible,
        modifiedDate: u.modifiedDate,
        responsiblePerson:
          u.responsiblePersonId && u.responsiblePersonName
            ? { value: u.responsiblePersonId, label: u.responsiblePersonName }
            : undefined,
      }));
      this.isLoadingTopicItems = false;
    });
  }

  setTopicItemDetails(topicItemId: string) {
    this.topicItemApiService.getTopicItemDetails(topicItemId).subscribe(result => {
      if (result instanceof NewIndicatorTopicItemVm) {
        const references = Array.from(
          new Set(
            result.dataValues.flatMap(value => value.referenceStandards.replace(/(\r\n|\n|\r)/gm, '\n').split('\n'))
          )
        ).join('\n');

        const description = result.dataValues
          .reduce<string[]>((acc, value) => {
            if (value.description) {
              acc.push(value.description);
            }
            return acc;
          }, [])
          .join('\n\n');

        this.selectedTopicItem = {
          id: result.id,
          name: result.name,
          visible: result.visible,
          calculationDate: result.updateDate,
          type: result.type,
          referenceStandards: references,
          description: description,
          dataValues: result.dataValues.map(value => ({
            indicatorId: value.indicatorId,
            currentValue: value.currentValue as DataValueUi,
            metadata: value.metadata,
            previousValue: value.previousValue as DataValueUi,
            targetValue: value.targetValue as DataValueUi,
            isCurrentValueConnected: true,
            isPreviousValueConnected: true,
            substrateData: value.substrateData,
            referenceStandards: value.referenceStandards,
          })),
          components: result.components,
        };
      } else {
        this.selectedTopicItem = result;
      }
      const topicItemType = Object.values(TopicItemType).find(type => type === this.selectedTopicItem?.type);
      if (topicItemType) this.setSelectedContentType(topicItemType);
      this.setIndicatorValueDescriptions();
    });
  }

  setIndicatorValueDescriptions() {
    if (this.selectedTopicItem) {
      this.selectedTopicItem.dataValues?.map(value => {
        if (value.metadata.unit) value.metadata.unitDescription = this.units[value.metadata.unit]?.description;
      });
    }
  }

  onTopicItemDetailsReorder(reordering: boolean) {
    this.topicItemDetailsFade = reordering;
  }

  setSelectedContentType(selectedType: TopicItemType) {
    this.selectedType = selectedType;
  }

  getSelectedContentType() {
    return Object.values(TopicItemType).find(type => type === (this.selectedTopicItem as TopicItemContentUi)?.type);
  }

  clearTopicItemDetails() {
    this.selectedTopicItemId = undefined;
    this.selectedTopicItem = undefined;
  }

  moveTopicItem(topicItemId: string, prevIndex: number, newIndex: number) {
    this.isLoadingTopicItems = true;

    if (!this.reportId || !this.mainLevelId || !this.topicId) {
      throw Error('Reporta data has to be fetch before you use try set a topic details.');
    }

    this.topicItemApiService
      .moveTopicItem(
        new MoveTopicItemCommandParams({
          reportId: this.reportId,
          mainLevelId: this.mainLevelId,
          topicId: this.topicId,
          topicItemId: topicItemId,
          prevIndex: prevIndex,
          newIndex: newIndex,
        })
      )
      .subscribe(result => {
        if (!result.success) {
          console.error(result);
        }
        this.isLoadingTopicItems = false;
      });
  }

  removeTopicItem(topicItemId: string) {
    this.topicItemApiService
      .deleteTopicItem(new DeleteTopicItemCommandParams({ topicItemId: topicItemId }))
      .subscribe(result => {
        this.clearTopicItemDetails();
        this.allTopicItems(this.reportId || '', this.mainLevelId || '', this.topicId || '');
      });
  }

  updateStatus(topicItemId: string, status: TopicItemStatus) {
    this.topicItemApiService
      .updateStatus(
        new UpdateTopicItemStatusCommandParams({
          topicItemId: topicItemId,
          status: status,
        })
      )
      .subscribe(result => {
        if (this.topicTopicItems && result.success) {
          this.topicTopicItems = this.topicTopicItems.map<TopicItemUi>(topicItem =>
            topicItem.id !== topicItemId
              ? topicItem
              : {
                  ...topicItem,
                  status: status,
                  modifiedDate: result.result?.modifiedDate,
                }
          );
        }
      });
  }

  updateResponsiblePerson(topicItemId: string, user: IOption) {
    this.topicItemApiService
      .updateResponsiblePerson(
        new UpdateTopicItemResponsiblePersonCommandParams({
          topicItemId: topicItemId,
          responsiblePersonId: !!user.value ? user.value : undefined,
        })
      )
      .subscribe(result => {
        if (this.topicTopicItems && result.success) {
          this.topicTopicItems = this.topicTopicItems.map<TopicItemUi>(topicItem =>
            topicItem.id !== topicItemId
              ? topicItem
              : {
                  ...topicItem,
                  responsiblePerson: user,
                  modifiedDate: result.result?.modifiedDate,
                }
          );
        }
      });
  }

  updateVisibility(topicItemId: string, visible: boolean) {
    this.topicItemApiService
      .updateVisibility(
        new UpdateTopicItemVisibilityCommandParams({
          topicItemId: topicItemId,
          visible: visible,
        })
      )
      .subscribe(result => {
        if (this.topicTopicItems && result.success) {
          this.topicTopicItems = this.topicTopicItems.map<TopicItemUi>(topicItem =>
            topicItem.id !== topicItemId
              ? topicItem
              : {
                  ...topicItem,
                  visible: visible,
                }
          );
        }
      });
  }

  handleClickIndicatorPreview() {
    if (this.selectedTopicItem) {
      this.indicatorPreviewViewModel = this.mapAppStateToIndicatorViewModel(this.selectedTopicItem, this.timePeriod);
    } else {
      this.indicatorPreviewViewModel = undefined;
    }
  }

  handleCloseIndicatorPreview() {
    this.indicatorPreviewViewModel = undefined;
  }

  mapAppStateToIndicatorViewModel(topicItem: TopicItemContentUi, timePeriod?: TimePeriod): SectionContentViewModel {
    let contents: IContent[] = [];
    topicItem?.components?.forEach(c => {
      let content = c.content ? JSON.parse(c.content || '') : undefined;
      if (content) {
        let blobUrl = content.blobName ? this.appApiService.getBlobUrl(content.blobName) : '';
        const indicatorIds: string[] = content?.indicatorIds || [];
        const keyFigures = this.getOrderedIndicatorValues(topicItem.dataValues || [], indicatorIds);

        contents?.push({
          textHtml: content.textHtml ? content.textHtml : '',
          blobUrl: blobUrl,
          altText: content.altText || '',
          title: '',
          tableConfig: content.tableConfig || undefined,
          rows: content.rows || [],
          indicatorType: content.indicatorType,
          visibleColumns: content.visibleColumns,
          keyFigures: keyFigures,
        });
      }
    });

    return {
      id: topicItem.id,
      name: topicItem.name,
      type: topicItem.type,
      timePeriod: timePeriod,
      keyFigures: topicItem.dataValues,
      substrateData: topicItem.substrateData,
      contents: contents,
    } as SectionContentViewModel;
  }

  getOrderedIndicatorValues(indicatorValues: DataValuesUi[], indicatorIds: string[]): DataValuesUi[] {
    return indicatorIds.map(id => indicatorValues.find(value => value.indicatorId === id)!).filter(Boolean);
  }

  ngOnDestroy() {
    if (this.unitsSubscription) {
      this.unitsSubscription.unsubscribe();
    }
  }
}
