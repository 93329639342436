import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndicatorsPageComponent } from './indicators-page.component';
import { RouterModule } from '@angular/router';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { IndicatorsLibraryComponent } from './indicators-library/indicators-library.component';
import { IndicatorsListComponent } from './indicators-list/indicators-list.component';
import { DirectiveModule } from 'src/app/shared/directives/directive.module';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { IndicatorDetailsComponent } from './indicator-details/indicator-details.component';
import { TableModule } from 'src/app/shared/table/table.module';
import { IndicatorDataComponent } from './indicator-details/indicator-data/indicator-data.component';
import { IndicatorRecordFormTableComponent } from './indicator-details/indicator-data/indicator-record-form-table/indicator-record-form-table.component';
import { IndicatorPerformanceComponent } from './indicator-details/indicator-performance/indicator-performance.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllRecordsDialogComponent } from './indicator-details/indicator-data/all-records-dialog/all-records-dialog.component';
import { SetIndicatorTargetDialogComponent } from './indicator-details/indicator-performance/set-indicator-target-dialog/set-indicator-target-dialog.component';
import { IndicatorTableRecordsComponent } from './indicator-details/indicator-data/indicator-table-records/indicator-table-records.component';
import { IndicatorTableValueComponent } from './shared/indicator-table-value/indicator-table-value.component';
import { KeyboardShortcutsDirective } from 'src/app/shared/directives/keyboard-shortcuts.directive';
import { IndicatorPropertiesComponent } from './indicator-details/indicator-properties/indicator-properties.component';
import { FormatUnitWithUnicodePipe } from 'src/app/shared/pipes/format-unit-with-unicode.pipe';
import { FormatUnitWithHtmlPipe } from 'src/app/shared/pipes/format-unit-with-html.pipe';
import { IndicatorGraphViewComponent } from './indicator-details/indicator-performance/indicator-graph-view/indicator-graph-view.component';
import { NumericCalculatedDataComponent } from './indicator-details/indicator-performance/numeric-calculated-data/numeric-calculated-data.component';
import { NumericCalculatedSummaryComponent } from './indicator-details/indicator-performance/numeric-calculated-summary/numeric-calculated-summary.component';
import { SideNavigationComponent } from 'src/app/app-layout/side-navigation/side-navigation.component';

@NgModule({
  declarations: [
    IndicatorsPageComponent,
    IndicatorsLibraryComponent,
    IndicatorsListComponent,
    IndicatorDetailsComponent,
    IndicatorDataComponent,
    IndicatorRecordFormTableComponent,
    IndicatorPerformanceComponent,
    AllRecordsDialogComponent,
    SetIndicatorTargetDialogComponent,
    IndicatorTableRecordsComponent,
    IndicatorTableValueComponent,
    IndicatorPropertiesComponent,
    IndicatorGraphViewComponent,
    NumericCalculatedDataComponent,
    NumericCalculatedSummaryComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    DirectiveModule,
    TableModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    KeyboardShortcutsDirective,
    FormatUnitWithUnicodePipe,
    FormatUnitWithHtmlPipe,
    RouterModule.forChild([
      {
        path: `indicators`,
        component: AppLayoutComponent,
        children: [
          {
            path: ``,
            component: SideNavigationComponent,
            outlet: 'side',
          },
          {
            path: ``,
            component: IndicatorsPageComponent,
            children: [
              { path: '', redirectTo: 'our-indicators', pathMatch: 'full' },
              { path: 'library', component: IndicatorsLibraryComponent },
              { path: 'our-indicators', component: IndicatorsListComponent },
            ],
          },
        ],
        canActivate: [MsalGuard],
      },
    ]),
  ],
})
export class IndicatorsModule {}
