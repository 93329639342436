import { FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { SupportTopicItemApiService } from 'src/app/api-client/report-api/support-topic-item-api-service';
import { SupportArticleUi, ViewModeEnums } from './support-content-list.component';
import {
  CreateSupportTopicItemCommandParams,
  DeleteSupportTopicItemCommandParams,
  MoveSupportTopicItemCommandParams,
  UpdateSupportTopicItemCommandParams,
} from 'src/api-client/report-api.generated';

@Injectable()
export class SupportContentListStateService {
  topicId: string = '';
  topicName: string = '';
  articles: SupportArticleUi[] = [];

  viewMode?: ViewModeEnums;
  editPermisson: boolean = false;
  showDeleteDialog: boolean = false;
  articleFade: boolean = false;
  loading: boolean = false;

  editedArticle?: SupportArticleUi;
  articleNameFormControl = new FormControl<string>('');
  selectedArticleId?: string;

  constructor(private supportTopicItemApiService: SupportTopicItemApiService) {}

  async init(supportTopicId: string, topicName: string) {
    this.loading = true;
    this.topicId = supportTopicId;
    this.topicName = topicName;
    await this.getArticles();
    this.loading = false;
  }

  async getArticles() {
    const response = await this.supportTopicItemApiService.getTopicItemsByTopic(this.topicId);

    this.articles = response.result.map(item => ({
      id: item.id,
      name: item.name,
      lastUpdated: item.lastUpdated,
      editedBy: item.editedBy,
    }));
  }

  setEditPermission(editPermission: boolean) {
    this.editPermisson = editPermission;
  }

  create() {
    this.viewMode = ViewModeEnums.Create;
  }

  async submitCreate() {
    this.viewMode = undefined;
    if (this.articleNameFormControl.value && this.editPermisson) {
      await this.supportTopicItemApiService.createTopicItem(
        new CreateSupportTopicItemCommandParams({
          supportTopicId: this.topicId,
          name: this.articleNameFormControl.value,
        })
      );
      this.articleNameFormControl.setValue('');
      this.getArticles();
    }
  }

  cancelCreate() {
    this.viewMode = undefined;
    this.articleNameFormControl.setValue('');
  }

  selectArticle(articleId: string) {
    this.selectedArticleId = articleId;
  }

  onDetailsReorder(reordering: boolean) {
    this.articleFade = reordering;
  }

  editArticle(article: SupportArticleUi) {
    if (this.editPermisson) {
      this.viewMode = ViewModeEnums.Edit;
      this.articleNameFormControl.setValue(article.name);
      this.articleNameFormControl.markAsDirty();

      this.editedArticle = { ...article };
    }
  }

  async submitEdit() {
    if (!this.editedArticle || !this.articleNameFormControl.value) {
      throw Error('Edited indicator has to be set.');
    }

    if (this.editedArticle.name !== this.articleNameFormControl.value && this.editPermisson) {
      await this.supportTopicItemApiService.updateTopicItem(
        new UpdateSupportTopicItemCommandParams({
          supportTopicItemId: this.editedArticle?.id,
          name: this.articleNameFormControl.value,
        })
      );
      this.getArticles();
    }
  }

  clearSelection() {
    this.viewMode = undefined;
    this.editedArticle = undefined;
    this.articleNameFormControl.setValue('');
  }

  closeSelectedArticle() {
    this.selectedArticleId = undefined;
  }

  showDelete() {
    this.showDeleteDialog = true;
  }

  closeDelete() {
    this.showDeleteDialog = false;
  }

  async deleteArticle() {
    this.closeDelete();
    if (this.editPermisson && this.selectedArticleId) {
      await this.supportTopicItemApiService.deleteTopicItem(
        new DeleteSupportTopicItemCommandParams({ supportTopicItemId: this.selectedArticleId })
      );
      this.closeSelectedArticle();
      this.getArticles();
    }
  }

  async moveArticleItem(topicItemId: string, prevIndex: number, newIndex: number) {
    this.loading = true;

    if (!this.topicId) {
      throw Error('Support topic data has to be fetch before you use try set a topic details.');
    }

    const response = await this.supportTopicItemApiService.moveTopicItem(
      new MoveSupportTopicItemCommandParams({
        supportTopicId: this.topicId,
        supportTopicItemId: topicItemId,
        prevIndex: prevIndex,
        newIndex: newIndex,
      })
    );

    if (!response.success) {
      console.error(response.message);
    }
    this.loading = false;
  }
}
