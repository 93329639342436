import { Component } from '@angular/core';
import { GenderDiversityEqualityStateService } from './gender-diversity-equality-state.service';

@Component({
  selector: 'esg-gender-diversity-equality',
  templateUrl: './gender-diversity-equality.component.html',
  styleUrls: ['../profiles.component.scss', './gender-diversity-equality.component.scss'],
  providers: [GenderDiversityEqualityStateService],
})
export class GenderDiversityEqualityComponent {
  constructor(public state: GenderDiversityEqualityStateService) {}
}
