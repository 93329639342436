import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeResourceUrl, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { FileParameter } from 'src/api-client/report-api.generated';
import { FormControl } from '@angular/forms';

export interface IUploadPhotoComponentTexts {
  /**
   * Default: 'upload'
   */
  firstLineSpan?: string;
  /**
   * Default: 'Drag and drop a photo here or '
   */
  firstLineP1?: string;
  /**
   * Default: ' a photo'
   */
  firstLineP2?: string;
  /**
   * Default: 'Drag and drop or '
   */
  firstLineP1UploadedPhoto?: string;
  /**
   * Default: ' to replace photo'
   */
  firstLineP2UploadedPhoto?: string;
  /**
   * Default: 'Photo must be PNG or JPG and minimum 1200px width and height. Max 2MB.'
   */
  secondLine?: string;
}

export interface IUploadedPhoto {
  url: SafeResourceUrl;
  fileName: string;
  blobName?: string;
  blobThumbnailName?: string;
  file?: FileParameter;
}

export function generateUploadPhoto(file: File, url: SafeResourceUrl): IUploadedPhoto {
  return {
    url: url,
    fileName: file.name,
    file: file
      ? {
          data: file,
          fileName: file.name,
        }
      : undefined,
  };
}

@Component({
  selector: 'esg-upload-picture',
  templateUrl: './upload-picture.component.html',
  styleUrls: ['./upload-picture.component.scss'],
})
export class UploadPictureComponent implements OnInit {
  @Input() accepted = 'image/jpeg,image/jpg,image/png';
  @Input() uploadedPhotos: IUploadedPhoto[] = [];
  @Input() variant: 'standard' | 'component' = 'standard';
  @Input() dropZoneStyle: Object = {};
  @Input() altTagControl?: FormControl<string | null>;
  @Input() texts?: IUploadPhotoComponentTexts;
  @Output() onSelectFile = new EventEmitter<File>();
  @Output() onClearUploadedPhoto = new EventEmitter<IUploadedPhoto>();

  get uploaded() {
    return !!this.uploadedPhotos.length;
  }

  validImg = true;

  componentTexts: IUploadPhotoComponentTexts = {
    firstLineP1: 'Drag and drop or ',
    firstLineSpan: 'upload',
    firstLineP2: ' a photo',
    firstLineP1UploadedPhoto: 'Drag and drop or ',
    firstLineP2UploadedPhoto: ' to replace photo',
    secondLine: 'Photo must be PNG or JPG and minimum 1200px width and height. Max 2MB.',
  };

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.componentTexts = {
      ...this.componentTexts,
      ...this.texts,
    };
  }

  sanitizeHtml(html?: string): SafeHtml {
    return html ? this.domSanitizer.bypassSecurityTrustHtml(html) : '';
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    if (event.rejectedFiles?.length > 0) {
      this.validImg = false;
      return;
    }

    if (event.addedFiles?.length) {
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      var objectUrl = _URL.createObjectURL(event.addedFiles[0]);

      img.onload = () => {
        // if (img.width < 1200 || img.height < 1200) {
        //   this.validImg = false;
        //   return;
        // }
        // else {
        this.validImg = true;
        this.onSelectFile.emit(event.addedFiles[0]);
        // }
        _URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
    }
  }

  handleClearPhoto(uploadedPhoto: IUploadedPhoto) {
    this.onClearUploadedPhoto.emit(uploadedPhoto);
  }
}
