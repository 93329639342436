<div class="container">
  <span class="property-label">Indicator value</span>
  <span class="property-value">{{ valueType }}</span>

  <span class="property-label">Record Duration</span>
  <span class="property-value">{{ recordDuration }}</span>

  <span class="property-label">Unit</span>
  <span class="property-value">{{ (unit | formatUnitWithUnicode) || '-' }}</span>

  <span class="property-label">Calculation</span>
  <span class="property-value">{{ calculationType }}</span>

  <span class="property-label">Main Topic</span>
  <span class="property-value">{{ indicatorProperties.category }}</span>

  <span class="property-label">Relevant Sectors</span>
  <span class="property-value">{{ sectorsStringList }}</span>

  <span class="property-label">Raw Data Sources</span>
  <span class="property-value">{{ rawDataSourcesList }}</span>

  <span class="property-label">Indicator Data Sources</span>
  <span class="property-value">{{ indicatorDefinitionDataSourcesList }}</span>
</div>
