<div class="intro">
  <span class="intro-label">News from The ESG Team</span>
  <h1>What’s coming next?</h1>
  <span class="intro-content">
    Since our journey began in May 2022, we've made remarkable progress with our ESG application, continuously
    innovating and deploying new features every three to four weeks. Looking ahead, 2024 promises to be a year filled
    with significant advancements and milestones that will further enhance our ESG solutions.
  </span>
</div>
<div class="section">
  <span class="section-label">Innovations Q1 2024</span>
  <h2>New Indicator Methodology & Scope 3 Coverage</h2>
  <span class="section-content">
    Tackling the complexities of data collection head-on, we're introducing a groundbreaking approach to indicators.
    This new methodology is designed to streamline the data gathering process, ensuring more efficient and accurate
    insights for our users.
  </span>
  <br />
  <span class="section-content">
    Understanding the intricate challenges associated with Scope 3 emissions, we've committed to simplifying this
    process. By Q1, all 15 categories of Scope 3 will be fully integrated into our platform, ready for effortless data
    collection and reporting. Our goal is to demystify Scope 3, making it more accessible and manageable for businesses
    across all sectors.
  </span>
</div>
<div class="section">
  <span class="section-label">Highlights Q2 2024</span>
  <h2>Achieving ESRS Compliance</h2>
  <span class="section-content">
    In Q2 2023 we will start developing our next big thing: The Performance Module. This will give you real time
    graphics of your ESG performance. Use this data to improve your operations and to communicate environmental and
    social performance to stakeholders.
  </span>
</div>
<div class="section">
  <span class="section-label">Development Q3 2024</span>
  <h2>New Reporting Interface</h2>
  <span class="section-content">
    In our continuous pursuit of excellence and user satisfaction, Q3 will see the unveiling of a new report interface.
    This innovative interface is tailored to offer intuitive navigation, enhanced visualization, and a more interactive
    reporting experience. We're dedicated to making ESG reporting as straightforward and insightful as possible.
  </span>
</div>
