import { Injectable } from '@angular/core';
import {
  DataRecordType,
  CreateWasteDataRecordCommandParams,
  WasteClass,
  WasteSubClass,
  WasteToDisposal,
  AssetType,
  DataRecordCategory,
  ICreateWasteDataRecordCommandParams,
  InputType,
} from 'src/api-client/report-api.generated';
import {
  IOption,
  WasteClassOptions,
  WasteSubClassOptionsByClass,
  getWasteHandlingOptions,
} from 'src/app/static-data/options';
import { RecordsStateService } from '../records-state.service';
import {
  FieldConfig,
  ImportDataOutput,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import {
  DataRecordOutput,
  ExcelImportValidationMessage,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';

@Injectable()
export class WasteStateService extends RecordsStateService {
  title = 'Waste';
  recordCategory = DataRecordCategory.IndirectEmissionsWaste;

  assetCategories = [AssetType.Organization, AssetType.Facility, AssetType.Vessel];
  wasteClassOptions: IOption[] = WasteClassOptions;
  wasteSubClassOptions: IOption[] = WasteSubClassOptionsByClass[WasteClass.A_Plastics];
  disposalOptions: IOption[] = getWasteHandlingOptions(WasteClass.A_Plastics);
  unitOptions: IOption[] = [
    { value: 'mt', label: 'mt' },
    { value: 'm3', label: 'm³' },
  ];

  importFromExcelEnabled = true;
  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'class', title: 'Waste Class', type: 'string' },
    { propertyName: 'subclass', title: 'Sub Class', type: 'string', optional: true },
    { propertyName: 'isHazardous', title: 'Hazardius', type: 'boolean' },
    { propertyName: 'toDisposal', title: 'Waste Handling', type: 'string' },
    { propertyName: 'quantity', title: 'Quantity', type: 'number' },
    { propertyName: 'unit', title: 'Unit (mt or m³)', type: 'string' },
    { propertyName: 'cO2Measured_t', title: 'Measured', type: 'number', optional: true },
  ];

  async importDataFromExcel(data: ImportDataOutput) {
    this.importingInProgress = true;
    this.importingInProgressLabel = 'Importing...';
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) {
      validationMessages.push(assetValidation);
    }

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'class',
      this.wasteClassOptions,
      data.records,
      'Waste Class'
    );

    validationMessages = this.validateSubClassOptionColumn(validationMessages, data.records);

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'toDisposal',
      this.disposalOptions,
      data.records,
      'Disposal'
    );

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'unit',
      this.unitOptions,
      data.records,
      'Unit (mt or m³)'
    );

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    var recordsToAdd = data.records.length;
    var recordsAdded = 0;
    this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
    try {
      const wasteSubClassPossibleOptions = [
        ...WasteSubClassOptionsByClass[WasteClass.C_DomesticWastes],
        ...WasteSubClassOptionsByClass[WasteClass.F_OperationalWastes],
      ];

      const addRecordPromises = data.records.map(async (record, index) => {
        try {
          const asset = this.getAssetOptionColumn(record.asset?.value);

          const classOption = this.getExcelOptionColumn('class', this.wasteClassOptions, record, 'Class');

          const subclassOption = record.subclass?.value
            ? this.getExcelOptionColumn('subclass', wasteSubClassPossibleOptions, record, 'Sub Class')
            : undefined;

          const toDisposalOption = this.getExcelOptionColumn(
            'toDisposal',
            this.disposalOptions,
            record,
            'Waste Handling'
          );

          const unitOption = this.getExcelOptionColumn('unit', this.unitOptions, record, 'Unit (mt or m³)');

          const waste: ICreateWasteDataRecordCommandParams = {
            type: DataRecordType.Waste,
            assetId: asset.value,
            inputType: InputType.ImportedFromExcel,
            startDate: record.startDate?.value,
            endDate: record.endDate?.value,
            class: WasteClass[classOption.value as keyof typeof WasteClass],
            subclass: subclassOption && WasteSubClass[subclassOption.value as keyof typeof WasteSubClass],
            isHazardous: record.isHazardous?.value,
            toDisposal: WasteToDisposal[toDisposalOption.value as keyof typeof WasteToDisposal],
            quantity: record.quantity?.value,
            unit: unitOption.value,
            cO2Measured_t: record.cO2Measured_t?.value,
          };

          var result = await this.dataRecordService.createDataRecord(new CreateWasteDataRecordCommandParams(waste));
          if (!result.success) {
            this.notificationService.showError(
              `Waste record from row ${index + 1} not added`,
              result.message || 'Unknown error'
            );
          }
          ++recordsAdded;
          this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
          return result;
        } catch (e) {
          console.error(`Somthing went wrong for record in row ${index + 1} `, record);
          throw e;
        }
      });

      var response = await Promise.all(addRecordPromises);
      response.forEach((res, index) => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });

      await this.loadRecords();
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
      this.importDataFromExcelClose();
    } catch (e) {
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
    }
  }

  validateSubClassOptionColumn(
    validationMessages: ExcelImportValidationMessage[],
    records: DataRecordOutput[]
  ): ExcelImportValidationMessage[] {
    const notMappedColumns = records
      .filter(record => {
        var classOption = this.wasteClassOptions.find(c => c.label === record.class?.value);
        if (!classOption) {
          return true;
        }
        var options = WasteSubClassOptionsByClass[classOption.value as keyof typeof WasteClass];
        return record.subclass?.value && !options.some(o => o.label === record.subclass?.value);
      })
      .map(record => record.subclass?.rowNo);

    if (notMappedColumns.length) {
      return [
        ...validationMessages,
        {
          title: 'Not valid imported data',
          description: `Waste Sub Class column is not valid for rows: ${notMappedColumns.join(',')}`,
        },
      ];
    }
    return validationMessages;
  }
}
