import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-asset-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M1.85528 12.2172C1.56551 12.0586 1.35053 11.8742 1.21032 11.6643C1.07011 11.4496 1 11.137 1 10.7263V4.9377C1 4.65306 1.05842 4.41507 1.17526 4.22375C1.2921 4.03243 1.4697 3.86911 1.70806 3.73378L6.94492 0.79398C7.29544 0.597993 7.64831 0.5 8.00351 0.5C8.35871 0.5 8.70923 0.597993 9.05508 0.79398L14.2989 3.73378C14.5326 3.86911 14.7079 4.03243 14.8247 4.22375C14.9416 4.41507 15 4.65306 15 4.9377V10.7263C15 11.137 14.9299 11.4496 14.7897 11.6643C14.6495 11.8742 14.4345 12.0586 14.1447 12.2172L8.5363 15.346C8.35403 15.4487 8.17409 15.5 7.99649 15.5C7.82357 15.5 7.64831 15.4487 7.47071 15.346L1.85528 12.2172ZM2.38808 11.5383L7.57586 14.4291V8.33948L1.83425 5.12669V10.6843C1.83425 10.8803 1.8693 11.0436 1.93941 11.1743C2.01419 11.3003 2.16375 11.4216 2.38808 11.5383ZM13.6189 11.5383C13.8386 11.4216 13.9835 11.3003 14.0536 11.1743C14.1284 11.0436 14.1657 10.8803 14.1657 10.6843V5.12669L8.42414 8.33948V14.4291L13.6189 11.5383ZM8.00351 7.58353L10.4852 6.20462L4.72959 3.00583L2.26189 4.39874L8.00351 7.58353ZM11.3545 5.72165L13.7451 4.39874L8.65548 1.53593C8.2255 1.28861 7.79085 1.28861 7.35153 1.53593L5.58488 2.52287L11.3545 5.72165Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class AssetIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
