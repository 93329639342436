<div class="title" (click)="handleCollpase()">
  <span class="icon" [class.rotate]="collapsed">
    <esg-icon-expanders></esg-icon-expanders>
  </span>
  <span class="text">
    {{ title }}
  </span>
</div>
<div *ngIf="!collapsed" class="content">
  <ng-content></ng-content>
</div>
