import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ReportMainLevel } from 'src/api-client/report-api.generated';
import { ContextMenuComponent, IMenuItem } from '../../../../shared/ui/context-menu/context-menu.component';

export interface UpdateMainLevelData {
  id: string;
  name: string;
}

export interface DeleteMainLevelData {
  id: string;
  name?: string;
}

export interface AddTopicData {
  mainLevelId: string;
  name: string;
}

@Component({
  selector: 'esg-main-level-item',
  templateUrl: './main-level-item.component.html',
  styleUrls: ['./main-level-item.component.scss'],
})
export class MainLevelItemComponent implements OnInit {
  editingMainLevelFormControl?: FormControl<string | null>;
  addingTopicFormControl?: FormControl<string | null>;
  @Input({ required: true }) mainLevelData!: ReportMainLevel;
  @Input() isLoading: boolean = false;
  @Output() onUpdateMainLevel = new EventEmitter<UpdateMainLevelData>();
  @Output() onAddTopic = new EventEmitter<AddTopicData>();
  @Output() onDeleteMainLevel = new EventEmitter<DeleteMainLevelData>();

  expanded: boolean = true;

  @ViewChild(ContextMenuComponent)
  private contextMenuComponent?: ContextMenuComponent;

  constructor() {}

  ngOnInit(): void {}

  handleSingleClick() {
    this.toggleExpand();
    this.contextMenuComponent?.handleClose();
  }

  handleDoubleClick() {
    this.handleEditClick();
    this.contextMenuComponent?.handleClose();
  }

  handleMouseLeave() {
    this.contextMenuComponent?.handleClose();
  }

  handleEditClick() {
    this.editingMainLevelFormControl = new FormControl<string>(this.mainLevelData.name || '', [Validators.required]);
  }

  handleDragStart() {
    this.contextMenuComponent?.handleClose();
  }

  handleEditingMainLevelClickOutside() {
    if (this.editingMainLevelFormControl?.value) {
      this.onUpdateMainLevel.emit({
        id: this.mainLevelData.id || '',
        name: this.editingMainLevelFormControl?.value || '',
      });
    }
    this.editingMainLevelFormControl = undefined;
  }

  handleEnableAddTopic(event: Event) {
    event.stopPropagation();
    this.addingTopicFormControl = new FormControl<string>('', [Validators.required]);
  }

  handleAddingTopicClickOutside() {
    this.onAddTopic.emit({
      mainLevelId: this.mainLevelData.id || '',
      name: this.addingTopicFormControl?.value || '',
    });
    this.addingTopicFormControl = undefined;
  }

  handleCancelMainLevelForm() {
    this.editingMainLevelFormControl = undefined;
  }

  handleCancelTopicForm() {
    this.addingTopicFormControl = undefined;
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  mainLevelMenuProvider = (): IMenuItem[] => [
    { id: 'edit', label: 'Edit', onClick: () => this.handleEditClick() },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () =>
        this.onDeleteMainLevel.emit({ id: this.mainLevelData.id || '', name: this.mainLevelData.name || 'main topic' }),
    },
  ];
}
