<div esg-on-click-outside (onClickOutside)="collapse()" class="container">
  <div #input class="select-wrapper">
    <div
      class="selected-option"
      [class.disabled]="disabled"
      [class.active]="!isCollapsed"
      (click)="handleFieldClick($event)">
      <span *ngIf="value?.color" class="color-circle" [style.background]="value?.color"></span>
      <div class="label-wrapper">
        {{ value?.label || placeholder }}
      </div>
      <esg-arrow-down color="#183642"></esg-arrow-down>
    </div>
    <esg-select-options
      *ngIf="!isCollapsed"
      [selectedValues]="selectedValueAsArray"
      size="small"
      [options]="options"
      [groupedOptions]="groupedOptions"
      [favouriteOptions]="favouriteOptions"
      [dropUp]="dropUp"
      [maxHeight]="maxHeight"
      (onChange)="handleChange($event)"></esg-select-options>
  </div>
</div>
