<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading categories"></esg-dot-flashing>
<ng-container *ngIf="!state.loading">
  <h2>Regions</h2>
  <table *ngIf="!state.loading">
    <thead>
      <tr>
        <th></th>
        <th class="name">REGION NAME</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let region of state.regions">
        <tr class="read-mode" *ngIf="state.editedRegion?.id !== region.id" (dblclick)="handleEditRegion(region)">
          <td><esg-region-icon></esg-region-icon></td>
          <td class="name">
            {{ region.name }}
          </td>
          <td>
            <esg-table-delete-button
              [disabled]="state.regions.length === 1"
              label="Delete"
              (onClick)="handleDelete(region)"></esg-table-delete-button>
          </td>
        </tr>
        <tr
          class="input-row"
          *ngIf="state.viewMode === ViewModes.Edit && state.editedRegion?.id === region.id"
          esg-on-click-outside
          (onClickOutside)="handleSubmitEdit()"
          esgKeyboardShortcuts
          (onEscapeKey)="handleCancelEdit()">
          <td>
            <esg-region-icon></esg-region-icon>
          </td>
          <td class="name-input">
            <esg-table-input
              class="input"
              name="regionName"
              [fc]="state.regionNameFormControl"
              (onEnter)="handleSubmitEdit()"></esg-table-input>
          </td>
          <td>
            <esg-table-cancel-button label="Cancel" (onClick)="handleCancelEdit()"></esg-table-cancel-button>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="state.viewMode !== ViewModes.Create" class="add-line">
        <td [class.expand-line]="state.showStickyDivider">
          <div
            esgIntersectionObserver
            [threshold]="0"
            rootMargin="0px 0px -72px 0px"
            (onIntersection)="state.handleStickyDivider($event)"></div>
          <esg-add-button
            label="Add New Region"
            (onClick)="!state.viewMode && handleClickCreate($event)"></esg-add-button>
        </td>
      </tr>
      <tr
        class="input-row"
        *ngIf="state.viewMode === ViewModes.Create"
        esg-on-click-outside
        (onClickOutside)="handleSubmitCreate()"
        esgKeyboardShortcuts
        (onEscapeKey)="handleCancelCreate()">
        <td>
          <esg-region-icon></esg-region-icon>
        </td>
        <td class="name-input">
          <esg-table-input
            class="input"
            name="regionName"
            [fc]="state.regionNameFormControl"
            (onEnter)="handleSubmitCreate()"></esg-table-input>
        </td>
        <td>
          <esg-table-cancel-button label="Cancel" (onClick)="handleCancelCreate()"></esg-table-cancel-button>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
<esg-confirm-dialog
  *ngIf="state.showRemoveRegionFor"
  (onCancel)="handleCancelDelete()"
  (onSubmit)="handleSubmitDelete()"></esg-confirm-dialog>
