import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-assigned-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M2.83789 12.5C2.57656 12.5 2.37076 12.4399 2.2205 12.3198C2.0735 12.203 2 12.0412 2 11.8343C2 11.5139 2.09473 11.1752 2.2842 10.8181C2.47366 10.4611 2.74806 10.1274 3.10739 9.81702C3.46672 9.50334 3.89955 9.24972 4.40588 9.05617C4.91548 8.85929 5.48714 8.76085 6.12087 8.76085C6.75786 8.76085 7.32952 8.85929 7.83585 9.05617C8.34545 9.24972 8.77828 9.50334 9.13434 9.81702C9.49367 10.1274 9.76807 10.4611 9.95753 10.8181C10.1503 11.1752 10.2466 11.5139 10.2466 11.8343C10.2466 12.0412 10.1715 12.203 10.0212 12.3198C9.87423 12.4399 9.67007 12.5 9.40874 12.5H2.83789ZM6.12577 7.86485C5.77624 7.86485 5.45284 7.76808 5.15557 7.57453C4.85831 7.37764 4.61821 7.11402 4.43528 6.78365C4.25561 6.44994 4.16578 6.0762 4.16578 5.6624C4.16578 5.25528 4.25561 4.88821 4.43528 4.56118C4.61821 4.23415 4.85831 3.97553 5.15557 3.78532C5.45284 3.59511 5.77624 3.5 6.12577 3.5C6.4753 3.5 6.79869 3.59344 7.09596 3.78031C7.39322 3.96719 7.63169 4.22414 7.81135 4.55117C7.99428 4.87486 8.08575 5.24194 8.08575 5.65239C8.08575 6.06952 7.99428 6.44494 7.81135 6.77864C7.63169 7.11235 7.39322 7.37764 7.09596 7.57453C6.79869 7.76808 6.4753 7.86485 6.12577 7.86485ZM11.2462 8.16018C11.1646 8.16018 11.0894 8.14182 11.0208 8.10512C10.9555 8.06841 10.8918 8.01001 10.8297 7.92992L9.63414 6.42825C9.559 6.32481 9.52144 6.22136 9.52144 6.11791C9.52144 6.00779 9.55737 5.91435 9.62924 5.8376C9.70437 5.75751 9.7942 5.71746 9.89873 5.71746C9.96733 5.71746 10.0294 5.73248 10.0849 5.76251C10.1437 5.78921 10.2025 5.8426 10.2613 5.92269L11.2266 7.19911L13.2601 3.86541C13.3516 3.71524 13.4659 3.64016 13.6031 3.64016C13.7044 3.64016 13.7958 3.67353 13.8775 3.74027C13.9592 3.80701 14 3.89544 14 4.00556C14 4.06229 13.9869 4.11902 13.9608 4.17575C13.9379 4.22914 13.9118 4.2792 13.8824 4.32592L11.6431 7.92992C11.5451 8.08343 11.4128 8.16018 11.2462 8.16018Z"
        fill="#183642" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  },
})
export class AssignedIconComponent {
  @Input() size: number = 16;
}
