import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  Component,
  CreateSupportComponentCommandParams,
  CreateSupportComponentCommandResponse,
  CreateSupportTopicItemCommandParams,
  CreateSupportTopicItemCommandResponse,
  DeleteSupportComponentCommandParams,
  DeleteSupportComponentCommandResponse,
  DeleteSupportTopicItemCommandParams,
  DeleteSupportTopicItemCommandResponse,
  GetSupportTopicItemsByTopicQueryResponse,
  MoveSupportComponentCommandParams,
  MoveSupportComponentCommandResponse,
  MoveSupportTopicItemCommandParams,
  MoveSupportTopicItemCommandResponse,
  SupportTopicItem,
  SupportTopicItemClient,
  UpdateSupportComponentCommandParams,
  UpdateSupportComponentCommandResponse,
  UpdateSupportTopicItemCommandParams,
  UpdateSupportTopicItemCommandResponse,
} from '../../../api-client/report-api.generated';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportTopicItemApiService {
  client: SupportTopicItemClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new SupportTopicItemClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  // TopicItems

  async getTopicItemsByTopic(supportTopicId: string): Promise<GetSupportTopicItemsByTopicQueryResponse> {
    const observable$ = this.client.getSupportTopicItemsByTopic(supportTopicId);
    return await lastValueFrom(observable$);
  }

  async getTopicItem(supportTopicItemId: string): Promise<SupportTopicItem> {
    const observable$ = this.client.getSupportTopicItem(supportTopicItemId);
    return await lastValueFrom(observable$);
  }

  async createTopicItem(body: CreateSupportTopicItemCommandParams): Promise<CreateSupportTopicItemCommandResponse> {
    const observable$ = this.client.createSupportTopicItem(body);
    return await lastValueFrom(observable$);
  }

  async updateTopicItem(body: UpdateSupportTopicItemCommandParams): Promise<UpdateSupportTopicItemCommandResponse> {
    const observable$ = this.client.updateSupportTopicItem(body);
    return await lastValueFrom(observable$);
  }

  async deleteTopicItem(body: DeleteSupportTopicItemCommandParams): Promise<DeleteSupportTopicItemCommandResponse> {
    const observable$ = this.client.deleteSupportTopicItem(body);
    return await lastValueFrom(observable$);
  }

  async moveTopicItem(body: MoveSupportTopicItemCommandParams): Promise<MoveSupportTopicItemCommandResponse> {
    const observable$ = this.client.moveSupportTopicItem(body);
    return await lastValueFrom(observable$);
  }

  // Components

  async getTopicItemComponents(supportTopicItemId: string): Promise<Component[]> {
    const observable$ = this.client.getSupportTopicItemComponents(supportTopicItemId);
    return await lastValueFrom(observable$);
  }

  async createComponent(body: CreateSupportComponentCommandParams): Promise<CreateSupportComponentCommandResponse> {
    const observable$ = this.client.createSupportComponent(body);
    return await lastValueFrom(observable$);
  }

  async updateComponent(body: UpdateSupportComponentCommandParams): Promise<UpdateSupportComponentCommandResponse> {
    const observable$ = this.client.updateSupportComponent(body);
    return await lastValueFrom(observable$);
  }

  async deleteComponent(body: DeleteSupportComponentCommandParams): Promise<DeleteSupportComponentCommandResponse> {
    const observable$ = this.client.deleteSupportComponent(body);
    return await lastValueFrom(observable$);
  }

  async moveComponent(body: MoveSupportComponentCommandParams): Promise<MoveSupportComponentCommandResponse> {
    const observable$ = this.client.moveSupportComponent(body);
    return await lastValueFrom(observable$);
  }
}
