import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateNewIndicatorTopicItemCommandParams,
  NewIndicatorTopicItemVm,
  TopicItemType,
  UpdateNewIndicatorTopicItemCommandParams,
} from 'src/api-client/report-api.generated';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import { IOption } from 'src/app/shared/ui/dropdown-multiple/dropdown-multiple-form.component';
import { TopicItemContentUi } from '../topic-item-details/topic-item-details-state.service';
import _ from 'lodash';

interface IndicatorForm {
  title: FormControl<string>;
  selectedIndicators: FormControl<IOption[]>;
}

@Injectable()
export class NewIndicatorFormStateService {
  indicatorOptions: IOption[] = [];
  indicatorFc: IndicatorForm;
  formgroup: FormGroup;

  headerText = 'Create new content';
  submitText = 'Create';
  constructor(private topicItemApiService: TopicItemApiService, private indicatorApiService: IndicatorApiService) {
    this.indicatorFc = {
      title: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
      selectedIndicators: new FormControl({ value: [], disabled: true }, { nonNullable: true }),
    };
    this.formgroup = new FormGroup(this.indicatorFc);
  }

  async initForm(selectedIndicator?: TopicItemContentUi) {
    this.indicatorFc.title.setValue(selectedIndicator?.name || '', { emitEvent: false });
    var availableIndicatorsResponse = await this.indicatorApiService.getAllNamesIndicators();
    this.indicatorOptions = _.orderBy(availableIndicatorsResponse.result, i => i.name).map(x => {
      return { value: x.id, label: x.name };
    });

    this.headerText = selectedIndicator ? 'Edit Indicator' : 'Add Indicator';
    this.submitText = selectedIndicator ? 'Save changes' : 'Add Indicator';

    if (selectedIndicator?.id) {
      this.topicItemApiService.getTopicItemDetails(selectedIndicator.id).subscribe(x => {
        var topicItem = x as NewIndicatorTopicItemVm;

        if (!!this.indicatorFc) {
          this.indicatorFc.selectedIndicators.setValue(
            topicItem.dataValues.map(x => {
              var indicatorName = this.indicatorOptions.find(y => y.value === x.indicatorId)?.label;
              return { value: x.indicatorId, label: indicatorName || 'Not found indicator name' };
            })
          );
        }
      });
    }
  }

  async submitForm(reportId: string, mainLevelId: string, topicId: string, topicItemId?: string) {
    this.formgroup?.markAsPristine();
    if (this.formgroup.valid) {
      if (topicItemId) {
        return await this.topicItemApiService.updateTopicItem(
          new UpdateNewIndicatorTopicItemCommandParams({
            type: TopicItemType.NewIndicator,
            name: this.indicatorFc?.title.value || '',
            topicItemId: topicItemId,
            indicatorIds: this.indicatorFc.selectedIndicators.value.map(x => x.value),
          })
        );
      } else {
        return await this.topicItemApiService.addTopicItem(
          new CreateNewIndicatorTopicItemCommandParams({
            type: TopicItemType.NewIndicator,
            reportId: reportId,
            mainLevelId: mainLevelId,
            topicId: topicId,
            name: this.indicatorFc?.title.value || '',
            indicatorIds: this.indicatorFc.selectedIndicators.value.map(x => x.value),
          })
        );
      }
    }
    return;
  }
}
