import { Injectable } from '@angular/core';
import {
  DataRecordType,
  AssetType,
  CreateElectricityConsumptionDataRecordCommandParams,
  DataRecordCategory,
  CountryCodes,
  PowerSource,
  ICreateElectricityConsumptionDataRecordCommandParams,
  InputType,
} from 'src/api-client/report-api.generated';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import { IOption } from 'src/app/static-data/options';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { RecordsStateService } from '../records-state.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetCategoryLabel } from 'src/app/static-data/enum-mappings';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  ExcelImportValidationMessage,
  FieldConfig,
  ImportDataOutput,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { generateGUID } from 'src/app/shared/utils/guid';

interface AssetData {
  id: string;
  name: string;
  countryCode?: string;
  powerSource?: string;
}

@Injectable()
export class ElectricityStateService extends RecordsStateService {
  title = 'Electricity';
  recordCategory = DataRecordCategory.IndirectEmissionsElectricity;

  assetCategories = [AssetType.Organization, AssetType.Facility, AssetType.Vessel, AssetType.VehicleCraftMachinery];

  assetToCategoryMap = new Map<string, string>();

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    assetApiService: AssetApiService,
    dataRecordService: DataRecordApiService,
    dataCategoryApiService: DataCategoryApiService,
    notificationService: NotificationService,
    router: Router,
    route: ActivatedRoute,
    private countryApiService: CountryApiService
  ) {
    super(
      appInfo,
      appConfig,
      assetApiService,
      dataRecordService,
      dataCategoryApiService,
      notificationService,
      router,
      route
    );
  }

  importFromExcelEnabled = true;
  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'country', title: 'Country', type: 'string' },
    { propertyName: 'electricity_kwh', title: 'kwh', type: 'number' },
  ];

  importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) validationMessages.push(assetValidation);

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'country',
      this.countryOptions,
      data.records,
      'Country'
    );

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    const addRecordPromises = data.records.map((record, index) => {
      const countryCode = this.countryOptions.find(opt => opt.label === record.country?.value);
      if (!countryCode) {
        this.notificationService.showError(
          'Not valid imported data',
          `Country column is not valid for ${index + 1} row`
        );
        return;
      }
      const asset = this.getAssetOptionColumn(record.asset?.value);
      const electricityConsumption: ICreateElectricityConsumptionDataRecordCommandParams = {
        type: DataRecordType.ElectricityConsumption,
        dataSetId: dataSetId,
        assetId: asset.value,
        inputType: InputType.ImportedFromExcel,
        startDate: record.startDate?.value,
        endDate: record.endDate?.value,
        countryCode: CountryCodes[countryCode.value as keyof typeof CountryCodes],
        electricity_kwh: record.electricity_kwh?.value || 0,
      };

      return this.dataRecordService.createDataRecord(
        new CreateElectricityConsumptionDataRecordCommandParams({ ...electricityConsumption })
      );
    });
    Promise.all(addRecordPromises).then(async response => {
      response.forEach(res => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });
      await this.loadRecords();
      this.importingInProgress = false;
      this.importDataFromExcelClose();
    });
  }

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getCountries(), this.getDataCategoryDetails()]);
  }

  async fetchOptions() {
    await Promise.all([this.getAssetOptions(), this.getCountries()]);
  }

  async getCountries() {
    const response = await this.countryApiService.getAllCountries();
    this.countryOptions = response.result.map(country => ({ value: country.code, label: country.name }));
  }

  async getAssetOptionsByType(type: AssetType, organizationCountryMap: Map<string, string>) {
    const response = await this.assetApiService.getAssetsByOrganizationAndType(undefined, type);

    const options: IOption[] = [];
    response.result.map(a => {
      let asset = a as AssetData;
      if (
        type === AssetType.VehicleCraftMachinery &&
        asset.powerSource !== PowerSource.Electric &&
        asset.powerSource !== PowerSource.PlugInHybrid
      ) {
        return;
      }

      if (asset.countryCode) {
        this.assetCountryMap.set(asset.id, asset.countryCode);
      } else {
        const organizationCountry = organizationCountryMap.get(
          a.subOrganizationId ? a.subOrganizationId : a.organizationId
        );
        if (organizationCountry) this.assetCountryMap.set(asset.id, organizationCountry);
      }
      options.push({ value: asset.id, label: asset.name, group: type });
    });
    return {
      label: AssetCategoryLabel[type],
      options: options,
    };
  }
}
