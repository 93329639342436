import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import {
  CountryCodes,
  CreateOrganizationBusinessTravelHotelNightsDataRecordCommandParams,
  DataRecordType,
  InputType,
  OrganizationBusinessTravelHotelNightsDataRecord,
  UpdateOrganizationBusinessTravelHotelNightsDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import { formatDate } from '@angular/common';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface HotelNightsRecordForm extends RecordForm {
  country: FormControl<IOption>;
  numberOfNights: FormControl<string>;
}

@Component({
  selector: 'esg-hotel-nights-form-table',
  templateUrl: './hotel-nights-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: HotelNightsFormTableComponent }],
})
export class HotelNightsFormTableComponent extends RecordFormTableComponent {
  headerCells: RecordHeaderCell[] = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'country', label: 'Country', description: 'Hotel Country', number: false },
    { columnId: 'numberOfNights', label: 'Hotel Nights', description: 'Nights * Persons', number: true },
  ];

  recordFc?: HotelNightsRecordForm = undefined;
  recordFormgroup?: FormGroup<HotelNightsRecordForm> = undefined;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as OrganizationBusinessTravelHotelNightsDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'country',
          value: this.countryOptions.find(country => country.value === record.countryCode)?.label || '',
          number: false,
        },
        {
          columnId: 'numberOfNights',
          value: record.numberOfNights.toString(),
          number: true,
        },
      ],
    }));
  }

  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      country: new FormControl<IOption>(this.getCountryOptionByAssetId(asset?.value), {
        nonNullable: true,
        validators: [Validators.required],
      }),
      numberOfNights: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    };
    this.recordFormgroup = new FormGroup(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const hotelRecord = this.dataRecords.find(
      record => record.id === id
    ) as OrganizationBusinessTravelHotelNightsDataRecord;
    if (hotelRecord) {
      this.recordFc = {
        startDate: new FormControl<Date>(hotelRecord.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(hotelRecord.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === hotelRecord.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        country: new FormControl<IOption>(
          this.countryOptions.find(type => type.value === hotelRecord.countryCode) || this.countryOptions[0],
          { nonNullable: true, validators: [Validators.required] }
        ),
        numberOfNights: new FormControl<string>(hotelRecord.numberOfNights.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
      };
      this.recordFormgroup = new FormGroup(this.recordFc);
    }
  }

  getParams():
    | CreateOrganizationBusinessTravelHotelNightsDataRecordCommandParams
    | UpdateOrganizationBusinessTravelHotelNightsDataRecordCommandParams
    | undefined {
    const countryCode = Object.values(CountryCodes).find(code => code === this.recordFc?.country.value.value);
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc?.asset.value?.value && countryCode) {
      const hotelNights = {
        type: DataRecordType.OrganizationBusinessAirTravel,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        countryCode: countryCode,
        numberOfNights: formattedStringToNumber(this.recordFc.numberOfNights.value) || 0,
      };
      if (this.editRecordId) {
        return new UpdateOrganizationBusinessTravelHotelNightsDataRecordCommandParams({
          id: this.editRecordId,
          ...hotelNights,
        });
      } else {
        return new CreateOrganizationBusinessTravelHotelNightsDataRecordCommandParams(hotelNights);
      }
    }
    return undefined;
  }
}
