import { Component } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Component({
  selector: 'esg-assets-default',
  templateUrl: './assets-default.component.html',
  styleUrls: ['./assets-default.component.scss'],
})
export class AssetsDefaultComponent {
  noCategoryText: string = 'Please select a category in the \nleft column to manage assets.';

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(private appConfig: AppConfigurationService) {}
}
