import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface CarouselSlideUi {
  header: string;
  content: string;
  videoSrc?: string;
}

@Component({
  selector: 'esg-carousel-dialog',
  templateUrl: './carousel-dialog.component.html',
  styleUrl: './carousel-dialog.component.scss',
})
export class CarouselDialogComponent {
  @Input({ required: true }) slides: CarouselSlideUi[] = [];
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  currentSlideIndex = 0;

  get isFirstSlide(): boolean {
    return this.currentSlideIndex === 0;
  }

  get isLastSlide(): boolean {
    return this.currentSlideIndex === this.slides.length - 1;
  }

  handleNextSlide(): void {
    if (!this.isLastSlide) {
      this.currentSlideIndex++;
    }
  }

  handlePreviousSlide(): void {
    if (!this.isFirstSlide) {
      this.currentSlideIndex--;
    }
  }

  handleOnClose(): void {
    this.onClose.emit();
  }
}
