import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'esg-checkmark',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66251 13.5C6.93703 13.5 7.15409 13.3777 7.30731 13.1396L13.3404 3.56202C13.4553 3.37537 13.5 3.23376 13.5 3.08572C13.5 2.73171 13.2702 2.5 12.919 2.5C12.6637 2.5 12.5232 2.58367 12.37 2.82826L6.63697 12.0389L3.66193 8.11264C3.50232 7.88736 3.34272 7.79725 3.11288 7.79725C2.74898 7.79725 2.5 8.04827 2.5 8.40228C2.5 8.55032 2.56384 8.71767 2.68514 8.87215L5.99855 13.1267C6.19008 13.3777 6.38799 13.5 6.66251 13.5Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
})
export class CheckmarkComponent {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  @Input() size: string = '16';
  @Input() color: string = 'black';
}
