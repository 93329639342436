import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateOrganizationWhistleblowingDataRecordCommandParams,
  DataRecordType,
  InputType,
  OrganizationWhistleblowingDataRecord,
  UpdateOrganizationWhistleblowingDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { IOption } from 'src/app/static-data/options';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface WhistleblowingRecordForm extends RecordForm {
  incidents: FormControl<string>;
}

@Component({
  selector: 'esg-whistleblowing-form-table',
  templateUrl: './whistleblowing-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: WhistleblowingFormTableComponent }],
})
export class WhistleblowingFormTableComponent extends RecordFormTableComponent {
  headerCells: RecordHeaderCell[] = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'incidents', label: 'Incidents', description: 'Number', number: true },
  ];

  recordFc?: WhistleblowingRecordForm = undefined;
  recordFormgroup?: FormGroup<WhistleblowingRecordForm> = undefined;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as OrganizationWhistleblowingDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        { columnId: 'incidents', value: record.incidents.toString(), number: true },
      ],
    }));
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      incidents: new FormControl<string>('', { nonNullable: true }),
    };
    this.recordFormgroup = new FormGroup<WhistleblowingRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const record = this.dataRecords.find(record => record.id === id) as OrganizationWhistleblowingDataRecord;
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        incidents: new FormControl<string>(record.incidents.toString(), { nonNullable: true }),
      };
      this.recordFormgroup = new FormGroup<WhistleblowingRecordForm>(this.recordFc);
    }
  }

  getParams():
    | CreateOrganizationWhistleblowingDataRecordCommandParams
    | UpdateOrganizationWhistleblowingDataRecordCommandParams
    | undefined {
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const whistleblowing = {
        type: DataRecordType.OrganizationWhistleblowing,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        incidents: formattedStringToNumber(this.recordFc.incidents.value || '') || 0,
      };
      if (this.editRecordId) {
        return new UpdateOrganizationWhistleblowingDataRecordCommandParams({
          id: this.editRecordId,
          ...whistleblowing,
        });
      } else {
        return new CreateOrganizationWhistleblowingDataRecordCommandParams(whistleblowing);
      }
    }
    return undefined;
  }
}
