import { Component, Input } from '@angular/core';
import { formatNumbersInString } from 'src/app/shared/utils/number-converters';

export interface IndicatorContentSubstrateDataUi {
  tHead: TrUi[];
  tBody: TrUi[];
}

interface TrUi {
  items: TrItemUi[];
}

interface TrItemUi {
  rowSpan?: number;
  colSpan?: number;
  content?: string;
  isNumber: boolean;
}

@Component({
  selector: 'esg-indicator-content-substrate-data',
  templateUrl: './indicator-content-substrate-data.component.html',
  styleUrl: './indicator-content-substrate-data.component.scss',
})
export class IndicatorContentSubstrateDataComponent {
  @Input({ required: true }) substrateData!: IndicatorContentSubstrateDataUi;

  getFormattedNumbers = formatNumbersInString;

  constructor() {}

  ngOnInit(): void {}
}
