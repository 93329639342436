<div *ngIf="mode === Modes.Edit" class="wrapper" [class.collapsed]="reorderMode" [class.active]="active">
  <esg-content-topbar
    *ngIf="reorderMode"
    [id]="contentId"
    cdkDrag
    [text]="innerText.substring(0, 100) || ''"
    (onDelete)="onDelete.emit(contentId)">
    <span cdkDragHandle handle class="topbar__handle">
      <esg-drag-icon></esg-drag-icon>
    </span>
    <esg-text-icon typeIcon></esg-text-icon>
  </esg-content-topbar>
  <div
    *ngIf="!reorderMode"
    class="container"
    [class.empty]="!fc.content.value && !active"
    [class.not-visible]="!isVisibleInReport"
    esg-on-click-outside
    (onClickOutside)="active && handleOutsideClick()"
    [ignoreIfSelected]="true"
    [ngStyle]="style">
    <esg-text-editor
      *ngIf="loading || fc.content.value || active"
      name="fc.content"
      [fc]="fc.content"
      [placeholder]="loading ? '' : 'Add text...'"
      [spellcheck]="active"
      [readOnly]="loading || formgroup.controls.content.disabled"
      [editorStyles]="editorStyles"
      [toolbarStyles]="active ? toolbarStyles : toolbarHidden"
      [hasParagraphStyles]="true"
      [hasChatBotOption]="actions.generateText ? true : false"
      [autoFocus]="active"
      (onDelete)="onDelete.emit(contentId)"
      (onFocus)="handleComponentClick()"
      (onGenerateText)="handleOnGenerateText($event)"></esg-text-editor>
    <esg-dot-flashing *ngIf="loading" class="loader" label=""></esg-dot-flashing>
    <esg-empty-content
      *ngIf="!fc.content.value && !loading && !active"
      (onClick)="handleComponentClick()"
      infoText="No text added."
      addText=" Click to add text..."></esg-empty-content>
  </div>
</div>

<div *ngIf="mode === Modes.Read" class="wrapper">
  <div class="container read">
    <esg-text-view-editor *ngIf="data?.textHtml" [content]="readContent"></esg-text-view-editor>
  </div>
</div>
