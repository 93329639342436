import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esg-collapsible-icon',
  templateUrl: './collapsible-icon.component.html',
  styleUrl: './collapsible-icon.component.scss',
})
export class CollapsibleIconComponent {
  @Input({ required: true }) expanded!: boolean;
  @Output() onClick = new EventEmitter();

  handleClick() {
    this.onClick.emit();
  }
}
