import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UsersStateService } from '../users-state.service';
import { UserRoleOptions } from '../../../../static-data/options';

@Component({
  selector: 'esg-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  @Output() onUserClick = new EventEmitter<string>();

  constructor(public state: UsersStateService) {}

  ngOnInit(): void {}

  handleUserClick(userId: string | undefined) {
    if (userId) {
      this.onUserClick.emit(userId);
    }
  }

  getUserRoleLabel(value: string | undefined) {
    return UserRoleOptions.find(option => option.value === value)?.label || '';
  }
}
