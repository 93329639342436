import { Injectable } from '@angular/core';
import {
  DataProfileType,
  DataRecordCategory,
  EmployeeGenderDiversityAndEqualityDataProfile,
  UpdateEmployeeGenderDiversityAndEqualityDataProfileCommandParams,
  CreateEmployeeGenderDiversityAndEqualityDataProfileCommandParams,
  OrganizationAssetVm,
  AssetType,
  CurrencyCodes,
} from 'src/api-client/report-api.generated';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { IOption, ProfileInputOptions } from 'src/app/static-data/options';
import { InputType } from 'src/api-client/report-api.generated';
import { ProfileForm, ProfileUi, ProfilesStateService } from '../profiles-state.service';
import { DataProfileApiService } from 'src/app/api-client/report-api/data-profile-api-service';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

export interface GenderProfileForm extends ProfileForm {
  asset: FormControl<IOption>;
}

export interface GenderProfileUi extends ProfileUi {
  organizationAssetId: string;
}

@Injectable()
export class GenderDiversityEqualityStateService extends ProfilesStateService {
  selectedProfile?: GenderProfileUi;
  profiles: GenderProfileUi[] = [];
  profileFormgroup?: FormGroup<GenderProfileForm> = undefined;
  assetOptions: IOption[] = [];
  organizationCurrency: CurrencyCodes = CurrencyCodes.USD;

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    dataProfileApiService: DataProfileApiService,
    dataCategoryApiService: DataCategoryApiService,
    private assetApiService: AssetApiService
  ) {
    super(appInfo, appConfig, dataProfileApiService, dataCategoryApiService);
    this.fetchStaticData();
  }

  async fetchStaticData() {
    await Promise.all([this.getOrganizationAssets()]);
  }

  async fetchOptions() {
    await Promise.all([this.getOrganizationAssets()]);
  }

  async getOrganizationAssets() {
    const organizationResponse = await this.assetApiService.getAssetsByOrganizationAndType(
      undefined,
      AssetType.Organization
    );
    this.assetOptions = organizationResponse.result.map(a => {
      let asset = a as OrganizationAssetVm;
      if (!asset.subOrganizationId) this.organizationCurrency = asset.currencyCode;
      return { value: asset.id, label: asset.orgOrSuborgName };
    });
  }

  async getProfiles() {
    const response = await this.dataProfileApiService.getDataProfilesByType(
      DataProfileType.EmployeeGenderDiversityAndEquality
    );
    if (response.success)
      this.profiles = response.result.map(profile => {
        const genderProfile = profile as EmployeeGenderDiversityAndEqualityDataProfile;
        return {
          id: genderProfile.id,
          name: genderProfile.timePeriod.period
            ? getTimeLabelFromPeriod(genderProfile.timePeriod)
            : 'Annual ' + getTimeLabelFromPeriod(genderProfile.timePeriod),
          description: genderProfile.description,
          timePeriod: genderProfile.timePeriod,
          type: genderProfile.inputType,
          organizationAssetId: genderProfile.organizationAssetId,
        };
      });
  }

  async getDataCategoryDetails() {
    const { result } = await this.dataCategoryApiService.getDataCategoryByDataRecordCategory(
      DataRecordCategory.EmployeesGenderEqualityAndDiversity
    );
    this.categoryDetails = {
      name: result.name,
      guidance: result.guidance,
    };
  }

  initateAdd() {
    if (this.assetOptions.length) {
      this.showFormDialog = true;
      this.profileFormgroup = new FormGroup({
        timePeriod: new FormControl<ITimePeriodData | undefined>(undefined, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        inputType: new FormControl<IOption>(ProfileInputOptions[0], {
          nonNullable: true,
          validators: [Validators.required],
        }),
        asset: new FormControl<IOption>(this.assetOptions[0], {
          nonNullable: true,
        }),
      });
    }
  }

  handleSelectProfile(profile: GenderProfileUi) {
    this.selectedProfile = profile;
    this.profileFormgroup = new FormGroup({
      timePeriod: new FormControl<ITimePeriodData | undefined>(profile.timePeriod, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      inputType: new FormControl<IOption>(
        ProfileInputOptions.find(opt => opt.value === profile.type) || ProfileInputOptions[0],
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      asset: new FormControl<IOption>(
        this.assetOptions.find(opt => opt.value === profile.organizationAssetId) || this.assetOptions[0],
        {
          nonNullable: true,
        }
      ),
    });
  }

  async formSubmit() {
    if (!this.profileFormgroup) {
      return;
    }

    const formOpen = this.showFormDialog;
    const timePeriod = this.profileFormgroup.controls.timePeriod.value;
    const inputType = Object.values(InputType).find(
      type => type === this.profileFormgroup?.controls.inputType.value.value
    );
    const assetId = this.profileFormgroup.controls.asset.value.value;
    if (!inputType || !timePeriod || !this.assetOptions.length) {
      return;
    }

    this.handleCancelForm();
    if (this.selectedProfile) {
      await this.dataProfileApiService.updateDataProfile(
        new UpdateEmployeeGenderDiversityAndEqualityDataProfileCommandParams({
          id: this.selectedProfile.id,
          description: '',
          type: DataProfileType.EmployeeGenderDiversityAndEquality,
          inputType: inputType,
          timePeriodType: timePeriod.type,
          year: timePeriod.year,
          period: timePeriod.period,
          organizationAssetId: assetId,
        })
      );
      if (formOpen) {
        await this.getProfiles();
        this.selectedProfile = this.profiles.find(profile => profile.id === this.selectedProfile?.id);
      }
    } else {
      this.updating = true;
      await this.dataProfileApiService.createDataProfile(
        new CreateEmployeeGenderDiversityAndEqualityDataProfileCommandParams({
          description: '',
          type: DataProfileType.EmployeeGenderDiversityAndEquality,
          inputType: inputType,
          timePeriodType: timePeriod.type,
          year: timePeriod.year,
          period: timePeriod.period,
          organizationAssetId: assetId,
        })
      );
      await this.getProfiles();
      this.updating = false;
    }
  }
}
