<esg-dialog class="auto" [showCloseBtn]="false" (onClose)="handleClose()">
  <div [ngClass]="['content', variant]">
    <div class="title">{{ title }}</div>
    <div class="message">{{ message }}</div>
    <div class="btns">
      <esg-button [ngClass]="variant === 'error' ? 'primary' : 'tertiary'" (onClick)="handleClose()">
        {{ buttonText }}
      </esg-button>
    </div>
  </div>
</esg-dialog>
