<div class="modal-container">
  <div class="header">
    <span class="header-text">{{ header }}</span>
    <esg-context-menu
      *ngIf="isCalculated"
      [options]="indicatorValueMenuProvider()"
      [disableTab]="true"
      class="icon"
      menuPosition="BottomLeft"
      type="horizontal"></esg-context-menu>
  </div>
  <div class="input-container">
    <esg-lock-icon *ngIf="isCalculated && fc.disabled" class="icon" color="#999999"></esg-lock-icon>
    <esg-no-lock-icon *ngIf="isCalculated && !fc.disabled" class="icon" color="#999999"></esg-no-lock-icon>
    <esg-textbox-form
      [inputStyle]="{ 'text-align': 'right', 'font-weight': '600', 'font-size': '18px', padding: '0 8px' }"
      [autoFocus]="true"
      variant="ghost-table"
      type="number"
      [placeholder]="fc.disabled ? 'Update data' : 'Number'"
      [fc]="fc"
      (onEnter)="handleOnSubmit()"></esg-textbox-form>
  </div>
  <div class="unit">{{ unit }}</div>
</div>
<div class="modal-backdrop" (click)="handleOnClose($event)"></div>
<esg-confirm-dialog
  *ngIf="isCalculated && showConnectionDialog"
  [title]="alertTitle"
  [message]="alertMessage"
  cancelText="Cancel"
  [submitText]="alertSubmitText"
  (onCancel)="handleOnCloseConnectionDialog()"
  (onSubmit)="handleOnSetConnection()"></esg-confirm-dialog>
