import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AccessTokenTypes, AccessTokenVm, IAccessTokenVm } from 'src/api-client/report-api.generated';
import { AccessTokenApiService } from 'src/app/api-client/report-api/access-token-service';

interface IAccessTokenUi extends IAccessTokenVm {
  added: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AccessTokenStateService {
  list: IAccessTokenUi[] = [];
  showCreateAccessToken = false;
  accessTokenName = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(50)],
  });
  constructor(public accessTokenApi: AccessTokenApiService) {}

  async initAccessTokenPage() {
    var { result } = await this.accessTokenApi.getAll();
    this.list = result.map(i => ({ ...i, added: false }));
  }

  async createAccessToken() {
    this.showCreateAccessToken = true;
  }

  async cancelCreateAccessToken() {
    this.showCreateAccessToken = false;
    this.accessTokenName.reset();
  }

  async saveAccessToken() {
    if (!this.accessTokenName.valid) return;
    var response = await this.accessTokenApi.create(this.accessTokenName.value, AccessTokenTypes.External);
    if (response.success) {
      var accessToken = response.result;
      this.list.push({
        id: accessToken.id,
        type: AccessTokenTypes.External,
        scopeId: accessToken.id,
        name: accessToken.name,
        createdDateTime: accessToken.createdDateTime,
        addedBy: accessToken.addedBy,
        token: accessToken.token,
        added: true,
      });
    }
    this.showCreateAccessToken = false;
    this.accessTokenName.reset();
  }

  async deleteAccessToken(id: string) {
    await this.accessTokenApi.delete(id);
    this.list = this.list.filter(x => x.id !== id);
  }
}
