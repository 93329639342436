import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { MainTopicFeedStateService } from '../pages/main-topic-feed/main-topic-feed-state.service';
import { ReportPreviewStateService } from '../report-preview-state.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'esg-preview-layout',
  templateUrl: './preview-layout.component.html',
  styleUrls: ['./preview-layout.component.scss'],
})
export class PreviewLayoutComponent implements OnInit {
  @Input() showShareButton = true;
  @Output() onShare = new EventEmitter();
  brandColor: string | undefined = undefined;
  tabObserverRootMargin = '-60px';

  get coverPhotoBackground() {
    return this.reportState.coverPhoto
      ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(' +
          this.reportState.coverPhoto +
          ') no-repeat'
      : '#444444';
  }

  @ViewChild('tabs') tabs!: ElementRef;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public appSettings: AppConfigurationService,
    public appInfoState: AppInfoService,
    public reportState: ReportPreviewStateService,
    public feedState: MainTopicFeedStateService
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe([
        this.reportState.breakpointEnum.WideDesktop,
        this.reportState.breakpointEnum.NarrowDesktop,
        this.reportState.breakpointEnum.Tablet,
        this.reportState.breakpointEnum.Mobile,
      ])
      .subscribe(result => {
        if (result.matches) {
          this.reportState.setBreakPoint(
            Object.values(this.reportState.breakpointEnum).find(breakpoint => result.breakpoints[breakpoint])
          );
        }
      });
  }

  handleOnTabClick() {
    this.feedState.resetTabs();
  }
}
