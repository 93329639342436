import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TimePeriod, TopicItemType } from 'src/api-client/report-api.generated';
import { Router } from '@angular/router';
import { ComponentsStateService } from './components/components-state.service';
import { TopicItemDetailsStateService, TopicItemContentUi } from './topic-item-details-state.service';

@Component({
  selector: 'esg-topic-item-details',
  templateUrl: './topic-item-details.component.html',
  styleUrls: ['./topic-item-details.component.scss'],
  providers: [TopicItemDetailsStateService, ComponentsStateService],
})
export class TopicItemDetailsComponent implements OnInit {
  @Input({ required: true }) reportId!: string;
  @Input() topicItem?: TopicItemContentUi;
  @Input() timePeriod?: TimePeriod;
  @Output() onReorder = new EventEmitter<boolean>();
  @Output() onCalculate = new EventEmitter<void>();

  stickyComponentsToolbar: boolean = false;

  constructor(
    public hostRef: ElementRef,
    public state: TopicItemDetailsStateService,
    public componentsState: ComponentsStateService,
    private router: Router
  ) {}

  get isNewIndicator() {
    return this.topicItem?.type === TopicItemType.NewIndicator;
  }

  get showReferences() {
    return this.topicItem?.type === TopicItemType.TextDisclosure;
  }

  get formattedReferenceStandards() {
    return this.topicItem?.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ' | ');
  }

  ngOnInit(): void {
    this.state.setLastUpdated(this.topicItem?.calculationDate);
    this.componentsState.getTopicItemComponents(
      this.reportId,
      this.topicItem?.id || '',
      this.topicItem,
      this.timePeriod
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.topicItem && changes.topicItem.currentValue) {
      this.state.setLastUpdated(this.topicItem?.calculationDate);
    }
  }

  handleStickyComponentMenu(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.rootBounds?.top) {
      this.stickyComponentsToolbar = tabEntry.boundingClientRect.top < tabEntry.rootBounds.top;
    } else this.stickyComponentsToolbar = false;
  }

  handleGoToDatacollect() {
    this.router.navigate(['/data-collect']);
  }

  async handleCalculateIndicator() {
    if (!this.topicItem?.id) {
      throw Error('Lack of the information about topicItem identifier');
    }
    if (this.topicItem.type === TopicItemType.NewIndicator) {
      await this.state.handleCalculateNewIndicator(this.topicItem.id);
      this.componentsState.getTopicItemComponents(
        this.reportId,
        this.topicItem?.id || '',
        this.topicItem,
        this.timePeriod
      );
      this.onCalculate.emit();
    }
  }

  handleOnReorder(reordering: boolean) {
    this.componentsState.onReorder(reordering);
    this.onReorder.emit(reordering);
  }
}
