import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';

@Component({
  selector: 'esg-preview-side-menu',
  templateUrl: './preview-side-menu.component.html',
  styleUrls: ['./preview-side-menu.component.scss'],
})
export class PreviewSideMenuComponent implements OnInit {
  @Input() reportName: string = '';
  @Input() tabs: ITabItem[] = [];
  @Input() brandColor: string = '#4ad1da';
  @Output() onClose = new EventEmitter();
  @Output() onTabClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleOnClose() {
    this.onClose.emit();
  }

  handleOnTabClick() {
    this.onTabClick.emit();
    this.handleOnClose();
  }
}
