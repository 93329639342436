<esg-side-dialog [open]="open" (onClose)="onClose.emit()" [contentStyle]="dialogStyle">
  <div #content class="content">
    <div
      class="top-observer"
      esgIntersectionObserver
      [root]="content"
      [rootMargin]="'0px'"
      (onIntersection)="handleStickyToolbar($event)"></div>
    <div class="toolbar" [class.sticky]="stickyToolbar">
      <button class="close" [class.fade]="fade" (click)="onClose.emit()">
        <esg-arrow-right-long></esg-arrow-right-long>
        Close
      </button>
      <div class="right" [class.fade]="fade">
        <ng-content select="[action]"></ng-content>
        <span *ngIf="callToActionText" class="action" (click)="onCallToAction.emit()">
          {{ callToActionText }}
        </span>
        <esg-context-menu [options]="options" type="horizontal"></esg-context-menu>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</esg-side-dialog>
