import { Component, Input } from '@angular/core';
import { EmployeeCommutingDetailsStateService } from './employee-commuting-details-state.service';

@Component({
  selector: 'esg-employee-commuting-records',
  templateUrl: './employee-commuting-records.component.html',
  styleUrls: ['../../../records/records.component.scss'],
})
export class EmployeeCommutingRecordsComponent {
  @Input({ required: true }) profileId!: string;
  constructor(public state: EmployeeCommutingDetailsStateService) {}

  ngOnInit(): void {
    this.state.setProfileId(this.profileId);
    this.state.init();
  }
}
