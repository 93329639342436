<div class="content">
  <h2 class="title">{{ organization.name }}</h2>
  <div class="users">
    <span class="users-title">Users assigned to this organization:</span>
    <table>
      <thead>
        <tr>
          <th>USER</th>
          <th>EMAIL</th>
          <th>ROLE</th>
        </tr>
      </thead>
      <tbody *ngIf="organization.users && organization.users.length > 0">
        <tr *ngFor="let item of organization.users">
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.role }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
