import _ from 'lodash';
import { CellObject } from 'xlsx';

export interface CellValue {
  value: any;
  type: ValueType;
}

export type ValueType = 'string' | 'number' | 'date' | 'boolean' | 'empty' | 'error';
export type GetCellValue = (row: number, column: number) => CellValue | undefined;

export const mapToValueType = (cell: CellObject, row: number, column: number): ValueType => {
  if (cell.t === 's') {
    return 'string';
  } else if (cell.t === 'n') {
    return 'number';
  } else if (cell.t === 'd') {
    return 'date';
  } else if (cell.t === 'b') {
    return 'boolean';
  } else if (cell.t === 'e') {
    return 'error';
  } else {
    throw new Error(`Unknown value type: ${cell} at ${calculateAlphabeticalString(column)}:${row}}`);
  }
};

export class ExcelCell {
  get columnName(): string {
    return calculateAlphabeticalString(this.column);
  }
  constructor(public row: number, public column: number, public type: ValueType, public value: any) {}
}

export class ExcelSheet {
  public headers: ExcelCell[] = [];
  public data: ExcelCell[][];

  public get columnNames(): string[] {
    return this.headers.map(c => c.columnName + (c.value ? ` (${c.value})` : ''));
  }

  constructor(public name: string, public range: string, getCellValue: GetCellValue) {
    const dataRange = calculateExcelDataRange(range);
    this.data = [];

    for (let i = dataRange.leftTopCellRow; i <= dataRange.rightBottomCellRow; i++) {
      const row = [];
      for (let j = dataRange.leftTopCellColumn; j <= dataRange.rightBottomCellColumn; j++) {
        const cellValue = getCellValue(i, j);
        if (i === dataRange.leftTopCellRow) {
          if (cellValue) {
            this.headers.push(new ExcelCell(i, j, cellValue?.type, cellValue?.value));
          } else {
            this.headers.push(new ExcelCell(i, j, 'empty', undefined));
          }
        } else {
          if (cellValue) {
            row.push(new ExcelCell(i, j, cellValue?.type, cellValue?.value));
          } else {
            row.push(new ExcelCell(i, j, 'empty', undefined));
          }
        }
      }
      if (i !== dataRange.leftTopCellRow) {
        this.data.push(row);
      }
    }
  }
}

export function calculateAlphabeticalNumber(str: string): number {
  let result = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i) - 64;
    result = result * 26 + charCode;
  }
  return result;
}

export function calculateAlphabeticalString(num: number): string {
  let result = '';
  while (num > 0) {
    const remainder = (num - 1) % 26;
    result = String.fromCharCode(65 + remainder) + result;
    num = Math.floor((num - remainder) / 26);
  }
  return result;
}

export interface ExcelDataRange {
  leftTopCellAddress: string;
  leftTopCellRow: number;
  leftTopCellColumn: number;

  rightBottomCellAddress: string;
  rightBottomCellRow: number;
  rightBottomCellColumn: number;
}

export function calculateExcelDataRange(range: string): ExcelDataRange {
  const leftTopCellAddress = range.split(':')[0];
  const rightBottomCellAddress = range.split(':')[1];
  return {
    leftTopCellAddress: leftTopCellAddress,
    leftTopCellRow: parseInt(leftTopCellAddress.replace(/[a-zA-Z]+/g, ''), 10),
    leftTopCellColumn: calculateAlphabeticalNumber(leftTopCellAddress.replace(/[0-9]+/g, '')),

    rightBottomCellAddress: rightBottomCellAddress,
    rightBottomCellRow: parseInt(rightBottomCellAddress.replace(/[a-zA-Z]+/g, ''), 10),
    rightBottomCellColumn: calculateAlphabeticalNumber(rightBottomCellAddress.replace(/[0-9]+/g, '')),
  };
}

export function isValidExcelRange(range: string) {
  const regex = /^[A-Z]+\d+:[A-Z]+\d+$/;
  return regex.test(range);
}

/*
  We need to do additional checking of the excel cell type. 
  Excel cell type 'n' is a number, but it can be formatted as text.
  Related links: 
  https://docs.sheetjs.com/docs/csf/features/nf#number-format-strings
*/
export function isExcelNumberTextFormat(cell: CellObject): boolean {
  return cell?.v !== undefined && cell.t === 'n' && cell.z === '@';
}

export function isExcelStringDateFormat(cell: CellObject): DateAndExtractSeparatorResult {
  var isDateString = cell?.v !== undefined && cell.t === 's' && cell.z === 'm/d/yy' && typeof cell.v === 'string';
  return isDateString ? checkDateAndExtractSeparator(cell.v as string) : { isValid: false };
}

/*
  if a cell has a number type (cell.t === 'n') and the number of decimals is changed (cell.z === '0'), 
  we need to read just a number without decimals
*/
export function isExcelNumberChangedNumberOfDecimals(cell: CellObject): boolean {
  return cell?.v !== undefined && cell.t === 'n' && typeof cell.z === 'string' && cell.z.startsWith('0');
}

export function isValid(value: any, type: ValueType, optional: boolean = false): boolean {
  if (optional && value === undefined) {
    return true;
  }
  switch (type) {
    case 'string':
      return _.isString(value) || _.isNumber(value);
    case 'number':
      return _.isNumber(value);
    case 'date':
      return _.isDate(value);
    case 'boolean':
      return _.isBoolean(value);
    case 'empty':
      return true;
    default:
      return false;
  }
}
export type DateAndExtractSeparatorResult = { isValid: boolean; separator?: string };
export function checkDateAndExtractSeparator(input: string): DateAndExtractSeparatorResult {
  const regex = /^(\d{2})([./-])(\d{2})\2(\d{4})$/;
  const match = input.match(regex);

  if (match) {
    return { isValid: true, separator: match[2] };
  } else {
    return { isValid: false };
  }
}
