import { Component } from '@angular/core';
import { FuelCombustionComponent } from './fuel-combustion.component';
import { VehicleCombustionStateService } from './vehicle-combustion-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-vehicle-combustion',
  templateUrl: './vehicle-combustion.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: VehicleCombustionComponent }, VehicleCombustionStateService],
})
export class VehicleCombustionComponent extends FuelCombustionComponent {
  constructor(public state: VehicleCombustionStateService) {
    super(state);
  }
}
