<div class="container" [class.clickable]="onClick.observed" (click)="onClick.emit()">
  <div class="background-image">
    <div class="blank" *ngIf="blankReport">
      <div *ngIf="!backgroundPictureUrl" class="empty-field"></div>
      <div *ngIf="!backgroundPictureUrl" class="empty-field"></div>
      <img *ngIf="backgroundPictureUrl" [src]="backgroundPictureUrl" [alt]="shortDescription" />

      <span class="btn-select">
        <esg-button *ngIf="showChooseBtn" class="secondary" size="small" [disabledHover]="true">
          <span class="flex-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.899 7.73165L10.6993 4.13084C10.6277 4.05262 10.5281 4.00578 10.4222 4.0005C10.3163 3.99522 10.2126 4.03191 10.1336 4.10262C10.0585 4.17603 10.0139 4.2751 10.0086 4.38001C10.0034 4.48492 10.038 4.58794 10.1054 4.66846L12.7123 7.60019H2.39997C2.29389 7.60019 2.19216 7.64234 2.11715 7.71737C2.04214 7.7924 2 7.89417 2 8.00028C2 8.10639 2.04214 8.20815 2.11715 8.28318C2.19216 8.35822 2.29389 8.40037 2.39997 8.40037H12.713L10.1061 11.3321C9.9654 11.4875 9.98361 11.7522 10.1343 11.8979C10.2914 12.0498 10.5593 12.0255 10.7 11.8697L13.8997 8.26891C14.0426 8.07137 14.024 7.90383 13.8997 7.73308L13.899 7.73165Z"
                fill="white" />
            </svg>
            Choose
          </span>
        </esg-button>
      </span>
    </div>
    <div class="custom" *ngIf="!blankReport">
      <div *ngIf="!backgroundPictureUrl" class="empty-field"></div>
      <div *ngIf="!backgroundPictureUrl" class="empty-field"></div>
      <img *ngIf="backgroundPictureUrl" [src]="backgroundPictureUrl" [alt]="shortDescription" />

      <span class="btn-select">
        <esg-button *ngIf="showChooseBtn" class="secondary" size="small" [disabledHover]="true">
          <span class="flex-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.899 7.73165L10.6993 4.13084C10.6277 4.05262 10.5281 4.00578 10.4222 4.0005C10.3163 3.99522 10.2126 4.03191 10.1336 4.10262C10.0585 4.17603 10.0139 4.2751 10.0086 4.38001C10.0034 4.48492 10.038 4.58794 10.1054 4.66846L12.7123 7.60019H2.39997C2.29389 7.60019 2.19216 7.64234 2.11715 7.71737C2.04214 7.7924 2 7.89417 2 8.00028C2 8.10639 2.04214 8.20815 2.11715 8.28318C2.19216 8.35822 2.29389 8.40037 2.39997 8.40037H12.713L10.1061 11.3321C9.9654 11.4875 9.98361 11.7522 10.1343 11.8979C10.2914 12.0498 10.5593 12.0255 10.7 11.8697L13.8997 8.26891C14.0426 8.07137 14.024 7.90383 13.8997 7.73308L13.899 7.73165Z"
                fill="white" />
            </svg>
            Choose
          </span>
        </esg-button>
      </span>
    </div>
  </div>
  <div class="title">
    {{ title }}
  </div>
  <div class="description" *ngIf="blankReport || (!blankReport && showChooseBtn)">
    {{ shortDescription }}
  </div>
  <div class="custom-template-description" *ngIf="!blankReport && !showChooseBtn">
    <esg-text-view-editor [content]="longDescription"></esg-text-view-editor>
  </div>
</div>
