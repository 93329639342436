import { Injectable } from '@angular/core';
import { AppConfigurationVm } from '../../api-client/app-api.generated';

@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  private settings!: AppConfigurationVm;

  public get AppBaseUrl() {
    return this.settings.appBaseUrl;
  }

  public get ReportApiBaseUrl() {
    return this.settings.reportApiUrl;
  }

  public get NewNavbarEnabled() {
    return this.settings.newNavbarEnabled;
  }

  public get ShowBuildInfoOnMainPage() {
    return this.settings.showBuildInfoOnMainPage;
  }

  public get B2CClientId() {
    return this.settings.azureB2CClientId;
  }

  public get B2CAuthorityDomain() {
    return this.settings.azureB2CAuthorityDomain;
  }

  public get B2CWebApiScopeUrl() {
    return this.settings.azureB2CWebApiScopeUrl;
  }

  public get B2CDomain() {
    return this.settings.azureB2CDomain;
  }

  public get B2CSignInAuthority() {
    return `https://${this.settings.azureB2CAuthorityDomain}/${this.settings.azureB2CDomain}/B2C_1_sign_in`;
  }

  public get B2CResetPasswordAuthority() {
    return `https://${this.settings.azureB2CAuthorityDomain}/${this.settings.azureB2CDomain}/B2C_1_resetpassword`;
  }

  init(result: AppConfigurationVm) {
    this.settings = result;
  }
}
