import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'esg-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  @Input() title: string = 'Alert';
  @Input() message: string = '';
  @Input() buttonText: string = 'OK';
  @Input() variant: 'warning' | 'error' = 'warning';
  @Output() onClose = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  handleClose() {
    this.onClose.emit();
  }
}
