import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { IndicatorContentValuesUi, IndicatorPeriodLabelsUi, VisibleValueColumn } from '../indicator-content.component';

export interface MovedIndicator {
  indicatorId: string;
  prevIndex: number;
  newIndex: number;
}

@Component({
  selector: 'esg-indicator-content-set',
  templateUrl: './indicator-content-set.component.html',
  styleUrl: './indicator-content-set.component.scss',
  host: {
    '[class.active]': 'active',
    '[class.not-visible]': '!isVisibleInReport',
  },
})
export class IndicatorContentSetComponent {
  @Input({ required: true }) indicatorSet!: IndicatorContentValuesUi[];
  @Input({ required: true }) indicatorIds: string[] = [];
  @Input() indicatorPeriodLabels: IndicatorPeriodLabelsUi = {
    targetLabel: 'Target',
    currentLabel: 'Current',
    previousLabel: 'Previous',
  };
  @Input() visibleColumns: VisibleValueColumn[] = [];
  @Input() indicatorOptions: IGroupedOptions[] = [];
  @Input() active: boolean = false;
  @Input() isVisibleInReport: boolean = true;
  @Output() onAddIndicator = new EventEmitter<string>();
  @Output() onRemoveIndicator = new EventEmitter<string>();
  @Output() onMoveIndicator = new EventEmitter<MovedIndicator>();

  indicatorSelectCollapsed: boolean = true;
  expandedRowIds: string[] = [];
  visibleColumnEnum = VisibleValueColumn;
  references: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.indicatorIds) {
      this.references = Array.from(
        new Set(
          this.indicatorSet.flatMap(value => value.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ' | ').split(' | '))
        )
      ).join(' | ');
    }
  }

  handleOnAddIndicator(option: IOption) {
    if (this.indicatorIds.some(id => id === option.value)) return;
    this.onAddIndicator.emit(option.value);
  }

  handleOnRemoveIndicator(indicatorId: string) {
    if (this.indicatorSet.length > 1) {
      this.onRemoveIndicator.emit(indicatorId);
    }
  }

  handleOnIndicatorSelectClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.indicatorSelectCollapsed = !this.indicatorSelectCollapsed;
  }

  handleOnIndicatorSelectCollapse(collapse: boolean) {
    this.indicatorSelectCollapsed = collapse;
  }

  handleOnContentDrop(event: CdkDragDrop<any[]>) {
    this.onMoveIndicator.emit({
      indicatorId: event.item.element.nativeElement.id,
      prevIndex: event.previousIndex,
      newIndex: event.currentIndex,
    });
  }

  handleOnExpandRow(id: string) {
    var exists = this.expandedRowIds.some(i => i === id);
    if (exists) {
      this.expandedRowIds = this.expandedRowIds.filter(i => i !== id);
    } else {
      this.expandedRowIds = [...this.expandedRowIds, id];
    }
  }

  formatNumberCell(value: string) {
    return stringToFormattedNumberString(value, 0, 2) || '';
  }

  isColumnVisible(columnValue: string): boolean {
    if (!this.visibleColumns) return true;
    return this.visibleColumns.some(column => column === columnValue);
  }
}
