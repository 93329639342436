<div esg-on-click-outside (onClickOutside)="collapse()" class="container" [class.popup]="isPopup">
  <label *ngIf="label" class="input-label">
    {{ label }}
    <span *ngIf="required">*</span>
  </label>
  <div #input class="select-wrapper">
    <esg-select-field
      *ngIf="!customDropdown"
      [value]="selectedValue?.label"
      [variant]="variant"
      [size]="size"
      [placeholder]="placeholder"
      [longestLabel]="longestLabel"
      [isCollapsed]="isCollapsed"
      [disabled]="disabled"
      (onToggleCollapse)="toggleCollapse()"></esg-select-field>
    <ng-content *ngIf="customDropdown"></ng-content>
    <esg-select-options
      *ngIf="!isCollapsed || focused"
      class="options"
      [ngStyle]="popUpStyle"
      [selectedValues]="selectedValueAsArray"
      [size]="size"
      [options]="options"
      [groupedOptions]="groupedOptions"
      [favouriteOptions]="favouriteOptions"
      [dropUp]="dropUp"
      [maxHeight]="maxHeight"
      (onChange)="handleChange($event)"></esg-select-options>
  </div>
</div>
