import { Injectable } from '@angular/core';
import { AssetType, DataRecordCategory } from 'src/api-client/report-api.generated';
import { RecordsStateService } from '../records-state.service';

@Injectable()
export class FinesStateService extends RecordsStateService {
  recordCategory = DataRecordCategory.BusinessEthicsFines;

  assetCategories = [AssetType.Organization];
}
