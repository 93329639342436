<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading suppliers"></esg-dot-flashing>
<ng-container *ngIf="!state.loading">
  <span *ngIf="state.useNewMainNavigation" class="category-selector-label">Select category</span>
  <div class="top-section">
    <h2 *ngIf="!state.useNewMainNavigation">Suppliers</h2>
    <esg-route-selector
      *ngIf="state.useNewMainNavigation"
      selectedLabel="Suppliers"
      [items]="state.assetCategoryRoutes"></esg-route-selector>
    <esg-import-from-excel-toolbar
      [title]="state.excelTitle"
      [dialogOpen]="state.importFromExcelDialog"
      [filedConfigs]="state.importFromExcelFieldConfigs"
      [inProgress]="state.importingInProgress"
      [inProgressLabel]="state.importingInProgressLabel"
      [enabled]="state.importFromExcelEnabled"
      (onImportData)="state.importDataFromExcel($event)"
      (onImportDataOpen)="state.handleOnImportAssets()"
      (onClose)="state.importDataFromExcelClose()"></esg-import-from-excel-toolbar>
  </div>
  <esg-suppliers-table
    [assets]="state.assets"
    [selectedAsset]="state.selectedAsset"
    [organizationId]="state.organizationId"
    [organizationOptions]="state.organizationOptions"
    [countryOptions]="state.countryOptions"
    [sectorOptions]="state.sectorOptions"
    [organizationCountryMap]="state.organizationCountryMap"
    [isUpdating]="state.updating"
    (onDetailsClick)="state.getAssetDetails($event)"
    (onSelectedAssetChange)="state.setSelectedAsset($event)"
    (onDelete)="state.handleInitateDelete()"
    (onFormSubmit)="state.formSubmit($event)"></esg-suppliers-table>
</ng-container>
<esg-details-dialog
  [open]="state.assetDetailsOpen"
  [options]="menuProvider()"
  (onClose)="state.handleCloseAssetDetails()">
  <esg-supplier-details
    *ngIf="state.selectedAsset"
    [assetId]="state.selectedAsset.id"
    [organizationOptions]="state.organizationOptions"
    [countryOptions]="state.countryOptions"
    [sectorsOptions]="state.sectorOptions"></esg-supplier-details>
</esg-details-dialog>
<esg-confirm-dialog
  *ngIf="state.removeDialogOpen && state.selectedAsset"
  [message]="'Once you delete this asset, you delete all its\n content, and there is no turning back.'"
  cancelText="No, keep it"
  (onCancel)="state.handleCloseDeleteDialog()"
  (onSubmit)="deleteSubmit(state.selectedAsset.id)"></esg-confirm-dialog>
