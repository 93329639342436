<div
  class="table-observer"
  esgIntersectionObserver
  [threshold]="0"
  [rootMargin]="rootObserverMargin"
  (onIntersection)="handleStickyHeaders($event)"></div>
<div class="container">
  <esg-generic-table
    class="table-headers"
    [class.sticky]="stickyHeaders"
    [columns]="columns"
    [isConfigurable]="false"
    [style.top.px]="stickyHeaderTopOffset"
    [style.padding-left.px]="stickyHeaderPadding"
    [style.margin-left.px]="-stickyHeaderPadding"
    [style.padding-right.px]="stickyHeaderPadding"
    [style.margin-right.px]="-stickyHeaderPadding"></esg-generic-table>
  <ng-container *ngIf="isAddEnabled">
    <div *ngIf="!recordFc || editRecordId" class="add-button-container">
      <esg-add-button
        label="Add data"
        variant="filled"
        size="small"
        (onClick)="handleOnIniateAddRecord($event)"></esg-add-button>
    </div>
    <div
      *ngIf="recordFc && !editRecordId"
      class="table-form"
      esg-on-click-outside
      (onClickOutside)="handleOnSubmit()"
      esgKeyboardShortcuts
      (onEscapeKey)="handleCloseForm()"
      (onEnterKey)="handleOnSubmit()">
      <ng-container *ngTemplateOutlet="recordForm"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngFor="let record of indicatorRecords; trackBy: trackByRecordId">
    <esg-indicator-table-value
      *ngIf="editRecordId !== record.id && record.tableValue"
      [class.connected]="record.recordSource ? true : false"
      (click)="!record.recordSource && handleOnInitateEditRecord($event, record.id)"
      [tableValue]="record.tableValue"
      [tableDefinition]="tableDefinition"
      [periodLabel]="getDurationLabel(record.timePeriod)"
      [assetName]="record.assetName"
      [recordSource]="record.recordSource"
      [isNewlyAdded]="newlyAddedRowsIds.includes(record.id)"
      [contextMenuOptions]="userContextMenuProvider(record.id, record.recordSource ? true : false)"
      [columnTypeMap]="columnTypeMap"
      [columnOptionsMap]="columnOptionsMap"
      [columnUnitMap]="columnUnitMap"
      [unitOptions]="unitOptions"></esg-indicator-table-value>
    <div
      *ngIf="editRecordId === record.id"
      class="table-form"
      esg-on-click-outside
      (onClickOutside)="handleCloseForm()"
      esgKeyboardShortcuts
      (onEscapeKey)="handleCloseForm()"
      (onEnterKey)="handleCloseForm()">
      <ng-container
        *ngTemplateOutlet="
          recordForm;
          context: { id: record.id, connected: record.recordSource ? true : false }
        "></ng-container>
    </div>
  </ng-container>
  <ng-template #recordForm let-id="id" let-connected="connected">
    <ng-container *ngIf="recordFc">
      <div class="table-upper">
        <div class="table-upper-inputs">
          <ng-container *ngIf="!showPeriodSelector">
            <div
              *ngIf="!showPeriodSelector"
              class="period-cell"
              [class.start-date-cell]="indicatorRecordDuration === recordDurationEnum.DateFromTo"
              [class.focus]="startDateOpen">
              <esg-datepicker
                *ngIf="recordFc.startDate"
                class="input-text"
                [fc]="recordFc.startDate"
                variant="ghost-table"
                [maxDate]="recordFc.endDate.value || undefined"
                [inputIndent]="15"
                [autoFocus]="startDateOpen"
                (onOpen)="startDateOpen = true"
                (onClose)="onStartDateClose()"></esg-datepicker>
            </div>
            <ng-container *ngIf="indicatorRecordDuration === recordDurationEnum.DateFromTo">
              <span class="date-divider" [class.focus]="startDateOpen"><span class="triangle"></span></span>
              <div class="period-cell end-date-cell" [class.focus]="endDateOpen">
                <esg-datepicker
                  *ngIf="recordFc.endDate"
                  class="input-text"
                  [fc]="recordFc.endDate"
                  variant="ghost-table"
                  [minDate]="recordFc.startDate.value || undefined"
                  [inputIndent]="13"
                  [autoFocus]="endDateOpen"
                  (onOpen)="endDateOpen = true"
                  (onClose)="endDateOpen = false"></esg-datepicker>
              </div>
            </ng-container>
          </ng-container>
          <div *ngIf="showPeriodSelector" class="period-cell">
            <esg-time-period-selector
              class="input-dropdown"
              [formControl]="recordFc.timePeriod"
              variant="ghost-table"
              size="large"></esg-time-period-selector>
          </div>
          <div class="input-cell asset-cell">
            <esg-table-select
              *ngIf="recordFc.asset"
              class="input-dropdown"
              [groupedOptions]="assetOptions"
              placeholder="Select Asset"
              [fitLongestLabel]="true"
              [value]="recordFc.asset.value || undefined"
              [disabled]="isAssetsDisabled"
              (onChange)="handleDropdownChange(recordFc.asset, $event)"></esg-table-select>
          </div>
        </div>
        <div class="table-upper-spacing"></div>
        <div *ngIf="editRecordId" class="table-upper-action">
          <esg-context-menu class="ellipsis" [options]="userContextMenuProvider(id, connected)"></esg-context-menu>
        </div>
        <div *ngIf="!editRecordId" class="table-upper-action" (click)="handleCloseForm()">
          <esg-icon-close size="16"></esg-icon-close>
        </div>
      </div>
      <esg-generic-table
        [columns]="columns"
        [rows]="recordFc.tableValue.value"
        [isConfigurable]="false"
        [hideColumns]="true"
        [currencyOptions]="currencyOptions"
        (onRowChange)="handleTableValueChange($event)"></esg-generic-table>
    </ng-container>
  </ng-template>
</div>
