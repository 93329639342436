<div class="menu-container">
  <esg-collapsible-section title="Direct Emissions">
    <div class="menu-section">
      <a routerLink="fuel-combustion" routerLinkActive="active">
        <span class="label">Fuel Combustion</span>
      </a>
    </div>
  </esg-collapsible-section>

  <esg-collapsible-section title="Indirect Emissions">
    <div class="menu-section">
      <a routerLink="electricity" routerLinkActive="active">
        <span class="label">Electricity</span>
      </a>
      <a routerLink="waste" routerLinkActive="active">
        <span class="label">Waste</span>
      </a>
      <a routerLink="business-travel" routerLinkActive="active">
        <span class="label">Business Travel</span>
      </a>
      <a routerLink="employee-commuting" routerLinkActive="active">
        <span class="label">Employee Commuting</span>
        <esg-beta-tag></esg-beta-tag>
      </a>
    </div>
  </esg-collapsible-section>

  <esg-collapsible-section title="Embedded Emissions">
    <div class="menu-section">
      <a routerLink="leased-assets" routerLinkActive="active">
        <span class="label">Leased Assets</span>
        <esg-beta-tag></esg-beta-tag>
      </a>
    </div>
  </esg-collapsible-section>

  <esg-collapsible-section title="Biodiversity">
    <div class="menu-section">
      <a routerLink="spills-to-environment" routerLinkActive="active">
        <span class="label">Spills to environment</span>
      </a>
      <a routerLink="marine-protected-areas" routerLinkActive="active">
        <span class="label">Marine Protected Areas</span>
      </a>
    </div>
  </esg-collapsible-section>

  <esg-collapsible-section title="Employees, Health and Safety">
    <div class="menu-section">
      <a routerLink="gender-diversity-equality" routerLinkActive="active">
        <span class="label">Gender, Position and Age Profiles</span>
      </a>
      <a routerLink="parental-leave" routerLinkActive="active">
        <span class="label">Parental Leave</span>
      </a>
      <a routerLink="sick-leave" routerLinkActive="active">
        <span class="label">Sick Leave</span>
      </a>
      <a routerLink="work-related-injuries" routerLinkActive="active">
        <span class="label">Work Related Injuries</span>
      </a>
      <a routerLink="marine-casualties" routerLinkActive="active">
        <span class="label">Marine Casualties</span>
      </a>
      <a routerLink="new-hires-and-turnovers" routerLinkActive="active">
        <span class="label">New Hires & Turnovers</span>
      </a>
    </div>
  </esg-collapsible-section>

  <esg-collapsible-section title="Business Ethics">
    <div class="menu-section">
      <a routerLink="corruption-risk" routerLinkActive="active">
        <span class="label">Corruption Risk</span>
      </a>
      <a routerLink="fines" routerLinkActive="active">
        <span class="label">Fines</span>
      </a>
      <a routerLink="lobby-political-contribution" routerLinkActive="active">
        <span class="label">Lobby/Political Contribution</span>
      </a>
      <a routerLink="whistleblowing" routerLinkActive="active">
        <span class="label">Whistleblowing</span>
      </a>
    </div>
  </esg-collapsible-section>
</div>
