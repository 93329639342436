import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Permission } from 'src/api-client/report-api.generated';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { IndicatorsPageStateService } from 'src/app/pages/indicators/indicators-page-state.service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { getInitials } from 'src/app/shared/utils/user-utils';
import { IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  @Output() onLogout = new EventEmitter();

  userContextOpen = false;
  organizationDropdownCollapsed = true;
  assetFormOpen = false;
  recordFormOpen = false;
  reportFormOpen = false;

  get getInitials() {
    return getInitials(this.appInfoState.userInfo.value?.name || '');
  }

  get hasAdminPanelPermission() {
    return this.appInfoState.hasPermission(Permission.Admin_Panel);
  }

  constructor(
    private router: Router,
    private indicatorPageStateService: IndicatorsPageStateService,
    public appInfoState: AppInfoService,
    public settingsService: AppConfigurationService
  ) {}

  handleOpenContextMenu(event: Event) {
    event.stopPropagation();
    this.userContextOpen = !this.userContextOpen;
  }

  handleOpenAssetForm() {
    this.assetFormOpen = true;
  }

  handleCloseAssetForm() {
    this.assetFormOpen = false;
  }

  handleOpenRecordForm() {
    this.recordFormOpen = true;
  }

  handleCloseRecordForm() {
    this.recordFormOpen = false;
  }

  handleOpenReportForm() {
    this.reportFormOpen = true;
  }

  handleCloseReportForm() {
    this.reportFormOpen = false;
  }

  async handleSetOrganizationContext(organizationOption: IOption) {
    await this.appInfoState.setOrganizationContext(organizationOption.value);
    this.indicatorPageStateService.getAllIndicatorsCount();
    this.router.navigate(['/home']);
  }

  userContextMenuProvider = (): IMenuItem[] => [
    {
      id: '1',
      label: 'Log out',
      onClick: () => {
        this.handleOnLogout();
        this.userContextOpen = false;
      },
    },
  ];

  handleOnLogout() {
    this.onLogout.emit();
  }
}
