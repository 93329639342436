<input
  #input
  esgKeyboardShortcuts
  (onEscapeKey)="handleEscape()"
  (onEnterKey)="handleEnter()"
  [keyOnlyOnFocus]="true"
  autocomplete="off"
  [name]="name"
  type="text"
  autocomplete="off"
  [formControl]="fc" />
