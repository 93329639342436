<esg-dialog (onClose)="handleCancel()">
  <div class="container">
    <div *ngIf="!templateData!.id" class="side-panel">
      <div class="title">Save report as template</div>
      <div class="description">
        When saving a report as a template, you create a template with all Main Topics, Topics and Indicators from the
        report, but not the content that is filled in. In other words: The new template will have the complete structure
        of this report, but not the content.
      </div>
      <div class="border-space" [class.border]="showStickyDivider"></div>
    </div>
    <div *ngIf="templateData!.id" class="side-panel">
      <div class="title">Edit template</div>
      <div class="description">
        Editing a template will not have impact on reports that has been created using these template. It will only
        affect new reports created with this template.
      </div>
      <div class="border-space" [class.border]="showStickyDivider"></div>
    </div>
    <div #main class="main">
      <div class="content">
        <div class="name">
          <span class="name-header">What is the name of the new template?</span>
          <esg-textbox
            placeholder="My Precious Template"
            [required]="true"
            [value]="templateData.name || ''"
            size="large"
            (onChange)="handleNameChange($event)"></esg-textbox>
        </div>
        <ng-container *ngIf="hasAccessToMarkTemplateAsGlobal">
          <div class="global">
            <esg-checkbox [checked]="templateData.global" (onCheck)="handleGlobalClick()"></esg-checkbox>
            <span>Make template available for all users in all organizations.</span>
          </div>
          <span *ngIf="templateData.global && !canCreateAsGlobalTemplate" class="global-error">
            {{
              'Only Indicators with global accessibility can be included in a Global template.\n Please remove non-global indicators from the report and try again:'
            }}
            {{ '\n\n' + notGlobalIndicatorsWarningMessage }}
          </span>
        </ng-container>
        <div class="short-description">
          <header>
            Please add a very short description of the template
            <span class="header-tip">(Max 70 characters)</span>
          </header>
          <esg-textarea
            [fc]="templateData.shortDescription"
            [heigth]="69"
            placeholder="Short description here..."
            [required]="true"></esg-textarea>
        </div>
        <div class="long-description">
          <header>Please write a more detailed description of the template</header>
          <div class="quill-container">
            <esg-text-editor
              name="longDescriptionValue"
              [fc]="templateData.longDescription"
              placeholder="Detailed description here"
              [hasParagraphStyles]="false"
              [editorStyles]="textAreaStyles"
              classes="ql-resize"></esg-text-editor>
          </div>
        </div>
        <div class="cover-photo">
          <header>And finally, upload a cover photo for the template</header>
          <esg-upload-picture
            [uploadedPhotos]="templateData!.coverPhoto ? [templateData!.coverPhoto] : []"
            (onSelectFile)="handleUploadCoverPhoto($event)"
            (onClearUploadedPhoto)="handleClearPhoto($event)"></esg-upload-picture>
        </div>
      </div>
      <div
        class="scroll-observer"
        esgIntersectionObserver
        [root]="main"
        [threshold]="0"
        rootMargin="0px 0px -103px 0px"
        (onIntersection)="handleStickyDivider($event)"></div>

      <div class="btn-line" [class.border]="showStickyDivider">
        <div class="btn-wrapper">
          <esg-button class="tertiary" (onClick)="handleCancel()">Cancel</esg-button>
          <esg-button (onClick)="handleSaveTemplate()" [disabled]="!templateDataValid || submitted">
            Save Template
          </esg-button>
        </div>
      </div>
    </div>
  </div>
</esg-dialog>
