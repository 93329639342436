import { Component, Input } from '@angular/core';
import { IndicatorTableValue, IndicatorTableValueDefinition } from 'src/api-client/report-api.generated';
import { RecordSource } from 'src/app/shared/components/records-table/records-table.component';
import { GenericColumnType } from 'src/app/shared/table/generic-table/generic-table.types';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { IOption } from 'src/app/static-data/options';

interface IndicatorTableRowUi {
  rowId: string;
  cells: IndicatorTableCellUi[];
}

interface IndicatorTableCellUi {
  columnId: string;
  value: string | number | undefined;
  isNumber: boolean;
  unit?: string;
}

@Component({
  selector: 'esg-indicator-table-value',
  templateUrl: './indicator-table-value.component.html',
  styleUrl: './indicator-table-value.component.scss',
})
export class IndicatorTableValueComponent {
  @Input() tableValue?: IndicatorTableValue;
  @Input({ required: true }) tableDefinition!: IndicatorTableValueDefinition;
  @Input() periodLabel: string = '';
  @Input() assetName: string = '';
  @Input() errorMessage?: string;
  @Input() recordSource?: RecordSource;
  @Input() isNewlyAdded: boolean = false;
  @Input() contextMenuOptions: IMenuItem[] = [];
  @Input() unitOptions: IOption[] = [];
  @Input() columnTypeMap: Map<string, GenericColumnType> = new Map();
  @Input() columnOptionsMap: Map<string, IOption[]> = new Map();
  @Input() columnUnitMap: Map<string, string | undefined> = new Map();

  readonly RecordSourceEnum = RecordSource;
  rows: IndicatorTableRowUi[] = [];

  ngOnChanges() {
    this.rows = this.convertTableValueToGenericRowUi();
  }

  private convertTableValueToGenericRowUi(): IndicatorTableRowUi[] {
    const tableDefinitionHasRows = this.tableDefinition.rows && this.tableDefinition.rows.length > 0;

    if (!this.tableValue) return [];

    return this.tableValue.rows.map(row => ({
      rowId: row.rowId,
      cells: row.cells.map((cell, index) => {
        const columnUnit = this.columnUnitMap.get(cell.columnId);
        const type = this.columnTypeMap.get(cell.columnId);
        if (index === 0 && tableDefinitionHasRows) {
          const rowDef = this.tableDefinition.rows.find(r => r.rowId === row.rowId);
          const unit = this.findUnitInOptions(rowDef?.unit || cell.unit || columnUnit);
          return {
            columnId: cell.columnId,
            value: rowDef?.header || cell.value,
            unit: unit,
            isNumber: false,
          };
        } else {
          const unit = this.findUnitInOptions(cell.unit || columnUnit);
          switch (type) {
            case GenericColumnType.Selector:
              return {
                columnId: cell.columnId,
                value:
                  this.columnOptionsMap.get(cell.columnId)?.find(option => option.value === cell.value?.toString())
                    ?.label || cell.value,
                unit: unit,
                isNumber: false,
              };
            case GenericColumnType.Currency:
              return {
                columnId: cell.columnId,
                value: stringToFormattedNumberString(cell.value?.toString() || '', 0, 2),
                unit: cell.unit,
                isNumber: true,
              };
            case GenericColumnType.Numeric:
              return {
                columnId: cell.columnId,
                value: stringToFormattedNumberString(cell.value?.toString() || '', 0, 2),
                unit: unit,
                isNumber: true,
              };
            default:
              return {
                columnId: cell.columnId,
                value: cell.value,
                unit: unit,
                isNumber: false,
              };
          }
        }
      }),
    }));
  }

  trackByRowId(index: number, row: IndicatorTableRowUi): string {
    return row.rowId;
  }

  trackByCell(index: number, row: IndicatorTableCellUi): string {
    return row.columnId;
  }

  private findUnitInOptions(unitValue: string | undefined): string | undefined {
    if (!unitValue || unitValue.toUpperCase() === 'NUMBER') return undefined;
    return this.unitOptions.find(option => option.value === unitValue || option.label === unitValue)?.label;
  }
}
