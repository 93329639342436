import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, SimpleChanges } from '@angular/core';

type MenuPosition = 'TopLeft' | 'TopRight' | 'BottomLeft' | 'BottomRight';

export interface IMenuItem {
  id: string;
  label: string;
  disabled?: boolean;
  onClick: () => void;
  children?: IMenuItem[];
}

interface Style {
  [key: string]: string;
}

@Component({
  selector: 'esg-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  @Input() type: 'horizontal' | 'vertical' | 'custom' = 'horizontal';
  @Input() open: boolean = false;
  @Input() options: IMenuItem[] = [];
  @Input() menuPosition: MenuPosition = 'BottomRight';
  @Input() menuIndent: number = 0;
  @Input() disableTab: boolean = false;
  @Input() optionsHeader: string = '';
  @Input() isPopup: boolean = false;
  @Output() onOpenOptions = new EventEmitter();
  @Output() onCloseOptions = new EventEmitter();

  iconHover: boolean = false;
  style: Style = {};
  currentMenuPosition: MenuPosition = 'BottomRight';

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.isPopup && this.open) this.handlePopupPositionStyle();
  }

  constructor(private hostRef: ElementRef) {}

  ngOnInit(): void {
    this.currentMenuPosition = this.menuPosition;
    this.handlePositionStyle();
    if (this.isPopup) this.addScrollEventListener();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menuPosition && !changes.menuPosition.firstChange) {
      this.currentMenuPosition = changes.menuPosition.currentValue;
      this.handlePositionStyle();
    }
  }

  handleClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.open = !this.open;
    if (this.open) {
      if (this.isPopup) {
        this.handlePopupPositionStyle();
      }
      this.onOpenOptions.emit();
    }
  }

  handleClose() {
    if (this.type === 'custom') this.onCloseOptions.emit();
    else this.open = false;
  }

  handleClickedOnOption(item: IMenuItem, event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (item.disabled) {
      return;
    }
    item.onClick();
    this.handleClose();
  }

  trackByOption(index: number, option: IMenuItem) {
    return option.id;
  }

  handlePositionStyle() {
    const isTop = this.currentMenuPosition.includes('Top');
    const isLeft = this.currentMenuPosition.includes('Left');
    const menuIndentPx = `${this.menuIndent}px`;

    let top = isTop ? 'unset' : '100%';
    let right = isLeft ? menuIndentPx : 'unset';
    let bottom = isTop ? '100%' : 'unset';
    let left = isLeft ? 'unset' : menuIndentPx;

    this.style = { top: top, right: right, bottom: bottom, left: left };
  }

  handlePopupPositionStyle() {
    if (!this.hostRef) return;

    const inputRect = this.hostRef.nativeElement.getBoundingClientRect();
    const isTop = this.currentMenuPosition.includes('Top');
    const isLeft = this.currentMenuPosition.includes('Left');

    const top = isTop ? `${inputRect.top}px` : `${inputRect.bottom}px`;
    const left = isLeft
      ? `${inputRect.left + inputRect.width - this.menuIndent}px`
      : `${inputRect.left + this.menuIndent}px`;
    const translateX = isLeft ? '-100%' : '0';
    const translateY = isTop ? '-100%' : '0';

    this.style = { top, left, transform: `translate(${translateX}, ${translateY})` };
  }

  addScrollEventListener(): void {
    document.addEventListener(
      'scroll',
      () => {
        if (this.open) this.handlePopupPositionStyle();
      },
      true
    );
  }

  handleIntersection(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.intersectionRatio < 1) {
      let position: string = this.currentMenuPosition;
      if (tabEntry.intersectionRect.width < tabEntry.boundingClientRect.width) {
        if (position.includes('Left')) position = position.replace('Left', 'Right');
        else position = position.replace('Right', 'Left');
      }
      if (tabEntry.intersectionRect.height < tabEntry.boundingClientRect.height) {
        if (position.includes('Top')) position = position.replace('Top', 'Bottom');
        else position = position.replace('Bottom', 'Top');
      }

      this.currentMenuPosition = position as MenuPosition;

      if (this.isPopup) this.handlePopupPositionStyle();
      else this.handlePositionStyle();
    }
  }
}
