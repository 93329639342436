import { Injectable } from '@angular/core';
import { AssetType, DataRecordCategory } from 'src/api-client/report-api.generated';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { RecordsStateService } from '../records-state.service';
import { CountryApiService } from 'src/app/api-client/report-api/country-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Injectable()
export class LobbyPoliticalContributionStateService extends RecordsStateService {
  recordCategory = DataRecordCategory.BusinessEthicsLobbyPoliticalContribution;

  assetCategories = [AssetType.Organization];

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    assetApiService: AssetApiService,
    dataRecordService: DataRecordApiService,
    dataCategoryApiService: DataCategoryApiService,
    notificationService: NotificationService,
    router: Router,
    route: ActivatedRoute,
    private countryApiService: CountryApiService
  ) {
    super(
      appInfo,
      appConfig,
      assetApiService,
      dataRecordService,
      dataCategoryApiService,
      notificationService,
      router,
      route
    );
  }

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getCountries(), this.getDataCategoryDetails()]);
  }

  async fetchOptions() {
    await Promise.all([this.getAssetOptions(), this.getCountries()]);
  }

  async getCountries() {
    const response = await this.countryApiService.getAllCountries();
    this.countryOptions = response.result.map(country => ({ value: country.code, label: country.name }));
  }
}
