import { Component, Input } from '@angular/core';
import { IndicatorDataStateService } from './indicator-data-state.service';
import { IndicatorDetailsUi, IndicatorUnitUi } from '../indicator-details-state.service';
import { IndicatorTableValueDefinition, IndicatorValueType, RecordDuration } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-indicator-data',
  templateUrl: './indicator-data.component.html',
  styleUrl: './indicator-data.component.scss',
  providers: [IndicatorDataStateService],
})
export class IndicatorDataComponent {
  @Input({ required: true }) indicator!: IndicatorDetailsUi;
  @Input({ required: true }) indicatorUnit!: IndicatorUnitUi;
  @Input({ required: true }) indicatorRecordDuration!: RecordDuration;
  @Input({ required: true }) indicatorValueType!: IndicatorValueType;
  @Input() indicatorTableDefinition?: IndicatorTableValueDefinition;

  public isSingleIndicator : boolean = false;

  constructor(public state: IndicatorDataStateService) {}

  ngOnInit(): void {
    this.state.init(this.indicator.id, this.indicatorRecordDuration);
    this.isSingleIndicator = this.indicatorValueType === IndicatorValueType.Numeric;
  }
}
