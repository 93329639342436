import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface IOption {
  value: string;
  label: string;
}

@Component({
  selector: 'esg-select-simple',
  templateUrl: './select-simple.component.html',
  styleUrl: './select-simple.component.scss',
})
export class SelectSimpleComponent {
  open = false;
  @Input({ required: true }) value?: IOption;
  @Input({ required: true }) options: IOption[] = [];
  @Output() onChange = new EventEmitter<IOption>();

  handleOpen() {
    this.open = !this.open;
  }

  handleSelectOption(option: IOption) {
    this.onChange.emit(option);
    this.open = false;
  }

  handleClickOutside() {
    this.open = false;
  }
}
