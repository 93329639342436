import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  DataCategoryClient,
  DataRecordCategory,
  GetDataCategoryByDataRecordCategoryQueryResponse,
  GetListDataCategoriesQueryResponse,
  UpdateDataCategoryCommandParams,
  UpdateDataCategoryCommandResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class DataCategoryApiService {
  client: DataCategoryClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new DataCategoryClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async getAllDataCategories(): Promise<GetListDataCategoriesQueryResponse> {
    const observable$ = this.client.getListDataCategories().pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async getDataCategoryByDataRecordCategory(
    dataRecordCategory: DataRecordCategory
  ): Promise<GetDataCategoryByDataRecordCategoryQueryResponse> {
    const observable$ = this.client.getDataCategoryByDataRecordCategory(dataRecordCategory).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  async updateDataCategory(body: UpdateDataCategoryCommandParams): Promise<UpdateDataCategoryCommandResponse> {
    const observable$ = this.client.updateDataCategory(body).pipe(
      catchError(error => {
        console.log(error.errors);
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }
}
