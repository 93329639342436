import { Component } from '@angular/core';

@Component({
  selector: 'esg-gri-report-tip',
  templateUrl: './gri-report-tip.component.html',
  styleUrl: './gri-report-tip.component.scss'
})
export class GriReportTipComponent {
  isProTipVisible = false;

  toggleProTip(): void {
    this.isProTipVisible = !this.isProTipVisible;
  }
}
