import { Component, OnInit } from '@angular/core';
import { AssetsMenuStateService } from './assets-menu-state.service';
import { AssetType } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-assets-menu',
  templateUrl: './assets-menu.component.html',
  styleUrls: ['./assets-menu.component.scss'],
})
export class AssetsMenuComponent implements OnInit {
  assetTypeEnum = AssetType;

  constructor(public state: AssetsMenuStateService) {}

  ngOnInit(): void {
    this.state.loadAssetTypeCounters();
  }

  getAssetTypeCount(assetType: AssetType) {
    return this.state.assetTypeCounters[assetType]?.assetCount === undefined
      ? '-'
      : this.state.assetTypeCounters[assetType]?.assetCount;
  }
}
