<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading indicators"></esg-dot-flashing>
<ng-container *ngIf="!state.loading">
  <h2>Indicator Library</h2>
  <table>
    <thead>
      <tr>
        <th>INDICATOR</th>
        <th>CATEGORY</th>
        <th>ACCESSIBILITY</th>
        <th>CREATED BY</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let indicator of state.indicatorDefinitions">
        <tr
          *ngIf="state.editedIndicatorId !== indicator.id"
          [class.active]="state.selectedIndicatorId === indicator.id"
          (click.single)="handleIndicatorSingleClick(indicator.id)"
          (click.double)="handleIndicatorDoubleClick(indicator.id)">
          <td>
            {{ indicator.name }}
          </td>
          <td>
            {{ indicator.category }}
          </td>
          <td>
            {{ indicator.accessibility }}
          </td>
          <td>
            {{ indicator.createdBy }}
          </td>
          <td class="arrow-cell">
            <esg-table-button label="Details" (onClick)="state.selectIndicator(indicator.id)"></esg-table-button>
          </td>
        </tr>
        <tr
          *ngIf="state.editedIndicatorId === indicator.id && state.indicatorForm"
          class="edit-row"
          esg-on-click-outside
          (onClickOutside)="handleUpdateIndicatorSubmit()"
          esgKeyboardShortcuts
          (onEscapeKey)="handleEditIndicatorCancel()"
          (onEnterKey)="handleUpdateIndicatorSubmit()">
          <td class="input-cell">
            <esg-textbox-form
              class="input-text"
              type="text"
              [autoFocus]="true"
              [fc]="state.indicatorForm.name"
              variant="ghost-table"
              placeholder=""></esg-textbox-form>
          </td>
          <td class="input-cell">
            <esg-table-select
              class="input-dropdown"
              [options]="state.categoryOptions"
              [value]="state.indicatorForm.category.value ? state.indicatorForm.category.value : undefined"
              (onChange)="state.handleSelectCategory($event)"></esg-table-select>
          </td>
          <td></td>
          <td></td>
          <td>
            <esg-table-cancel-button label="Cancel" (onClick)="handleEditIndicatorCancel()"></esg-table-cancel-button>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="!state.indicatorForm" class="add-line">
        <td [class.expand-line]="state.showStickyDivider">
          <div
            esgIntersectionObserver
            [threshold]="0"
            rootMargin="0px 0px -72px 0px"
            (onIntersection)="state.handleStickyDivider($event)"></div>
          <esg-add-button label="Create New" (onClick)="handleCreateIndicatorClick($event)"></esg-add-button>
        </td>
      </tr>
      <tr
        *ngIf="!state.editedIndicatorId && state.indicatorForm"
        class="create-row"
        esg-on-click-outside
        (onClickOutside)="handleSubmitCreate()"
        esgKeyboardShortcuts
        (onEscapeKey)="handleCreateIndicatorCancel()"
        (onEnterKey)="handleSubmitCreate()">
        <td class="input-cell">
          <esg-textbox-form
            class="input-text"
            type="text"
            [autoFocus]="true"
            [fc]="state.indicatorForm.name"
            variant="ghost-table"
            placeholder=""></esg-textbox-form>
        </td>
        <td class="input-cell">
          <esg-table-select
            class="input-dropdown"
            [options]="state.categoryOptions"
            [value]="state.indicatorForm.category.value ? state.indicatorForm.category.value : undefined"
            (onChange)="state.handleSelectCategory($event)"></esg-table-select>
        </td>
        <td></td>
        <td></td>
        <td>
          <esg-table-cancel-button label="Cancel" (onClick)="handleCreateIndicatorCancel()"></esg-table-cancel-button>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
<esg-details-dialog
  [open]="isIndicatorDetailsOpen"
  [options]="detailsMenuProvider()"
  (onClose)="state.closeSelectedIndicator()">
  <esg-indicator-definition-details
    *ngIf="state.selectedIndicatorId"
    [selectedIndicatorId]="state.selectedIndicatorId"></esg-indicator-definition-details>
</esg-details-dialog>

<esg-confirm-dialog
  *ngIf="state.showDeleteDialog"
  (onCancel)="handleCancelDelete()"
  (onSubmit)="handleDeleteSubmit()"></esg-confirm-dialog>
