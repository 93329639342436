<h2>{{ state.hasAddPermission ? 'Organizations' : 'Organization' }}</h2>

<esg-add-button
  *ngIf="state.hasAddPermission"
  label="Create New Organization"
  variant="filled"
  size="standard"
  (onClick)="handleAddOrganization()"></esg-add-button>

<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading organizations"></esg-dot-flashing>
<esg-organization-list
  *ngIf="!state.loading"
  class="list"
  [countryOptions]="state.countryOptions"
  (onOrganizationClick)="handleSelectOrganization($event)"></esg-organization-list>

<esg-organization-form
  *ngIf="showOrganizationForm"
  [organizationId]="state.selectedOrganization?.id || ''"
  [countryOptions]="state.countryOptions"
  (onCancel)="handleDialogClose()"
  (onSubmited)="handleSubmitOrganization()"></esg-organization-form>

<esg-details-dialog
  [open]="showOrganizationDetails"
  [options]="organizationMenuProvider()"
  (onClose)="handleDetailsDialogClose()">
  <esg-organization-details
    *ngIf="state.selectedOrganization"
    [organization]="state.selectedOrganization"></esg-organization-details>
</esg-details-dialog>
<esg-confirm-dialog
  *ngIf="showRemoveOrganization && state.selectedOrganization && !hasUsers()"
  [message]="removeOrganizationMessage"
  (onCancel)="handleDialogClose()"
  (onSubmit)="handleDeleteOrganizationSubmit(state.selectedOrganization.id)"></esg-confirm-dialog>
<esg-alert-dialog
  *ngIf="showRemoveOrganization && state.selectedOrganization && hasUsers()"
  variant="error"
  [title]="removeAlertTitle"
  [message]="removeAlertMessage"
  (onClose)="handleDialogClose()"></esg-alert-dialog>
