import { Injectable } from '@angular/core';
import { numberToFormattedNumberString } from '../../../shared/utils/number-converters';
import { MainLevel } from '../../report-preview-state.service';

interface KeyFigureRowsUi {
  indicator?: string;
  references?: string;
  rows: KeyFigureRowUi[];
}

interface KeyFigureRowUi {
  label?: string | undefined;
  unit?: string | undefined;
  type?: KeyFigureInputType;
  previous?: string | undefined;
  current: string | undefined;
  target?: string | undefined;
}

enum KeyFigureInputType {
  Text = 'Text',
  Numeric = 'Numeric',
}

export interface KeyFiguresTable {
  mainTopic?: string;
  keyFigures?: KeyFigureRowsUi[];
}

@Injectable()
export class KeyFiguresPreviewStateService {
  keyFiguresList: KeyFiguresTable[] = [];

  constructor() {}

  setKeyFigures(mainLevels: (MainLevel | undefined)[]) {
    this.keyFiguresList = mainLevels?.map(mainTopic => {
      let topicItems = mainTopic?.topics?.flatMap(x => x.topicItems || []);
      let keyFigures: KeyFigureRowsUi[] = [];
      topicItems?.map(item => {
        let indicatorValues = item.dataValues?.reduce((acc: KeyFigureRowUi[], figure) => {
          if (figure.metadata.type === 'Numeric' && figure.metadata.isVisibleInReport)
            acc.push({
              label: figure.metadata.label,
              unit: figure.currentValue.unitDetails || figure.metadata.unit,
              target:
                figure.targetValue.value || figure.targetValue.value === 0
                  ? numberToFormattedNumberString(Number(figure.targetValue.value))
                  : '',
              current:
                figure.currentValue.value || figure.currentValue.value === 0
                  ? numberToFormattedNumberString(Number(figure.currentValue.value))
                  : '',
              previous:
                figure.previousValue.value || figure.previousValue.value === 0
                  ? numberToFormattedNumberString(Number(figure.previousValue.value))
                  : '',
            });
          return acc;
        }, []);
        indicatorValues?.length &&
          keyFigures.push({ indicator: item.name, references: item.referenceStandards, rows: indicatorValues });
      });
      return keyFigures && keyFigures.length > 0 ? { mainTopic: mainTopic?.name, keyFigures: keyFigures } : {};
    });
  }
}
