<div class="container" (click)="handleClick($event)">
  <div class="checkbox" [class.checked]="fc.value" [class.disabled]="disabled">
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.39997 0.791041L3.03997 5.23549L1.59109 3.35993C1.28917 2.9721 0.730024 2.90246 0.342197 3.20437C-0.0456297 3.50629 -0.115274 4.06544 0.186641 4.45326L2.34664 7.21771C2.51626 7.43237 2.77528 7.55696 3.04886 7.55549C3.32397 7.55482 3.58327 7.42682 3.75109 7.20882L7.81331 1.87549C8.11277 1.48275 8.03716 0.921609 7.64442 0.622152C7.25168 0.32269 6.69054 0.398305 6.39109 0.791041L6.39997 0.791041Z"
        fill="white" />
    </svg>
  </div>
  <span class="label">
    {{ label }}
  </span>
</div>
