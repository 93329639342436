import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryCodes } from 'src/api-client/report-api.generated';
import { IOption } from 'src/app/static-data/options';
import {
  ISubOrganization,
  ISubOrganizationCreate,
  ISubOrganizationDelete,
  ISubOrganizationUpdate,
} from '../../organizations-types';
import { SubOrganizationForm } from '../sub-organization-form/sub-organization-form.component';

export enum FormMode {
  Read,
  Edit,
  Add,
}

@Component({
  selector: 'esg-sub-organization-row',
  templateUrl: './sub-organization-row.component.html',
  styleUrls: ['./sub-organization-row.component.scss'],
})
export class SubOrganizationRowComponent {
  @Input({ required: true }) organization!: ISubOrganization;
  @Input() countryOptions: IOption[] = [];
  @Input() indent: number = 0;
  @Output() onDeleteSubOrganization = new EventEmitter<ISubOrganizationDelete>();
  @Output() onEditSubOrganization = new EventEmitter<ISubOrganizationUpdate>();
  @Output() onAddSubOrganization = new EventEmitter<ISubOrganizationCreate>();
  readonly modes = FormMode;
  mode: FormMode = FormMode.Read;

  expanded: boolean = true;
  showErrors: boolean = false;

  subOrgFc?: SubOrganizationForm;
  subOrgFormGroup?: FormGroup<SubOrganizationForm>;

  constructor() {}

  handleExpand(event: Event) {
    event.stopPropagation();
    this.expanded = !this.expanded;
  }

  handleOnAddClick(event: Event) {
    event.stopPropagation();
    this.expanded = true;
    this.mode = this.modes.Add;
    this.subOrgFc = {
      name: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      orgID: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(opt => opt.value === this.organization.countryCode),
        {
          nonNullable: true,
        }
      ),
    };

    this.subOrgFormGroup = new FormGroup(this.subOrgFc);
  }

  handleOnEditClick(event: Event) {
    event.stopPropagation();
    this.mode = this.modes.Edit;
    this.subOrgFc = {
      name: new FormControl<string>(this.organization.name, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      orgID: new FormControl<string>(this.organization.orgID, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(opt => opt.value === this.organization.countryCode),
        {
          nonNullable: true,
        }
      ),
    };

    this.subOrgFormGroup = new FormGroup(this.subOrgFc);
  }

  handleOnSubOrgEdit() {
    if (this.subOrgFormGroup && this.subOrgFc) {
      const countryCode = Object.values(CountryCodes).find(code => code === this.subOrgFc?.country.value?.value);
      if (this.subOrgFormGroup.valid && countryCode) {
        if (this.subOrgFormGroup.dirty) {
          this.onEditSubOrganization.emit({
            subOrganizationId: this.organization.id,
            subOrganizationName: this.subOrgFc.name.value,
            orgID: this.subOrgFc.orgID.value,
            countryCode: countryCode,
          });
        }
        this.handleOnSubOrgCancel();
      } else {
        this.showErrors = true;
      }
    }
  }

  handleOnSubOrgSubmit() {
    if (this.subOrgFormGroup && this.subOrgFc) {
      if (!this.subOrgFormGroup.dirty) {
        this.handleOnSubOrgCancel();
        return;
      }

      const countryCode = Object.values(CountryCodes).find(code => code === this.subOrgFc?.country.value?.value);
      if (this.subOrgFormGroup.valid && countryCode) {
        this.onAddSubOrganization.emit({
          parentSubOrganizationId: this.organization.id,
          subOrganizationName: this.subOrgFc.name.value,
          orgID: this.subOrgFc.orgID.value,
          countryCode: countryCode,
        });
        this.handleOnSubOrgCancel();
      } else {
        this.showErrors = true;
      }
    }
  }

  handleOnSubOrgCancel() {
    this.subOrgFc = undefined;
    this.subOrgFormGroup = undefined;
    this.mode = this.modes.Read;
    this.showErrors = false;
  }

  handleOnDelete() {
    this.onDeleteSubOrganization.emit({
      subOrganizationId: this.organization.id,
      hasAssignedAssets: this.organization.hasAssignedAssets,
    });
  }
}
