import { Component } from '@angular/core';
import { NewHiresAndTurnoverStateService } from './new-hires-and-turnover-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-new-hires-and-turnover',
  templateUrl: './new-hires-and-turnover.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [
    { provide: RecordsComponent, useExisting: NewHiresAndTurnoverComponent },
    NewHiresAndTurnoverStateService,
  ],
})
export class NewHiresAndTurnoverComponent extends RecordsComponent {
  constructor(public state: NewHiresAndTurnoverStateService) {
    super(state);
  }
}
