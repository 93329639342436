import { Component } from '@angular/core';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { OrganizationAssetsStateService } from './organization-assets-state.service';

@Component({
  selector: 'esg-organization-assets',
  templateUrl: './organization-assets.component.html',
  styleUrls: ['../../shared/asset-table/asset-table.component.scss', './organization-assets.component.scss'],
  providers: [OrganizationAssetsStateService],
})
export class OrganizationAssetsComponent {
  constructor(public state: OrganizationAssetsStateService) {}

  menuProvider = (): IMenuItem[] => [{ id: 'delete', label: 'Delete', onClick: () => {}, disabled: true }];
}
