import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'esg-tag [onClose]',
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
  host: {
    '[class]': '[size]',
  },
})
export class TagComponent {
  @Input({ required: true }) label!: string;
  @Input() size: 'small' | 'medium' = 'medium';
  @Output() onClose: EventEmitter<Event> = new EventEmitter<Event>();

  handleOnClose(event: Event) {
    this.onClose.emit(event);
  }
}
