import { Component } from '@angular/core';
import { AirTravelStateService } from './air-travel-state.service';
import { BusinessTravelComponent } from './business-travel.component';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-air-travel',
  templateUrl: './air-travel.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: AirTravelComponent }, AirTravelStateService],
})
export class AirTravelComponent extends BusinessTravelComponent {
  constructor(public state: AirTravelStateService) {
    super(state);
  }
}
