import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { GetAllSupportTopicsQueryResponse, SupportTopicClient } from '../../../api-client/report-api.generated';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportTopicApiService {
  client: SupportTopicClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new SupportTopicClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async all(): Promise<GetAllSupportTopicsQueryResponse> {
    const observable$ = this.client.getAllSupportTopics();
    return await lastValueFrom(observable$);
  }
}
