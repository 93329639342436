import { Component } from '@angular/core';
import { AssetTableComponent } from '../../../shared/asset-table/asset-table.component';
import { IOption } from 'src/app/static-data/options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetUi } from '../../asset-list.component';
import {
  AssetType,
  CountryCodes,
  CreateSupplierAssetCommandParams,
  SectorType,
  SupplierStatus,
} from 'src/api-client/report-api.generated';
@Component({
  selector: 'esg-suppliers-table',
  templateUrl: './suppliers-table.component.html',
  styleUrls: ['../../../shared/asset-table/asset-table.component.scss', './suppliers-table.component.scss'],
  providers: [{ provide: AssetTableComponent, useExisting: SuppliersTableComponent }],
})
export class SuppliersTableComponent extends AssetTableComponent {
  initateAdd(event?: Event) {
    event?.stopPropagation();
    this.fc = {
      name: new FormControl<string>('', [Validators.required]),
      orgId: new FormControl<string>('', [Validators.required]),
      sector: new FormControl<IOption | undefined>(undefined, [Validators.required]),
      country: new FormControl<IOption | undefined>(this.defaultCountry),
      organization: new FormControl<IOption | undefined>(
        {
          value: this.defaultOrganization,
          disabled: this.defaultOrganization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  iniateEdit(asset: AssetUi) {
    if (this.fc) return;

    this.onSelectedAssetChange.emit(asset);

    const organization =
      this.organizationOptions.find(org => org.label === asset.organizationName) || this.defaultOrganization;
    const sectorOption = this.sectorOptions.find(
      sector => sector.label === asset.sector || sector.value === asset.sector
    );
    this.fc = {
      name: new FormControl<string>(asset.name, [Validators.required]),
      orgId: new FormControl<string>(asset.orgId || '', [Validators.required]),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(country => country.label === asset.country) || this.defaultCountry,
        [Validators.required]
      ),
      sector: new FormControl<IOption | undefined>(sectorOption, [Validators.required]),
      organization: new FormControl<IOption | undefined>(
        {
          value: organization,
          disabled: organization !== undefined && this.organizationOptions.length === 1,
        },
        [Validators.required]
      ),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  handleOnFormSubmit() {
    const countryCode = Object.values(CountryCodes).find(code => code === this.fc?.country?.value?.value);
    const sector = this.fc?.sector?.value?.value
      ? SectorType[this.fc.sector?.value?.value as keyof typeof SectorType]
      : undefined;
    const orgId = this.fc?.orgId?.value;
    if (this.fc && this.formgroup?.valid && countryCode && sector && orgId) {
      const subOrganizationId =
        this.organizationId === this.fc.organization.value?.value ? undefined : this.fc.organization.value?.value;
      const params = new CreateSupplierAssetCommandParams({
        type: AssetType.Supplier,
        name: this.fc.name.value || '',
        orgID: orgId,
        organizationId: this.organizationId || '',
        subOrganizationId: subOrganizationId,
        countryCode: countryCode,
        sector: sector,
        status: SupplierStatus.Active,
        versionValidFrom: new Date(),
      });
      this.onFormSubmit.emit(params);
      this.resetForm();
    } else this.handleSubmitRejection();
  }
}
