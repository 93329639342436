<div class="content">
  <ng-container *ngIf="!state.loading">
    <h2 class="title">{{ organizationName }}</h2>
    <div class="records">
      <esg-tabs class="records-tab" [activeTab]="state.activeTab" [tabs]="state.tabs" [tabIndent]="100"></esg-tabs>
      <esg-records-overview
        class="records-overview"
        [assetId]="organizationId"
        [data]="state.recordsOverview"></esg-records-overview>
    </div>
  </ng-container>
  <esg-dot-flashing *ngIf="state.loading" class="loader" label="We are loading asset details"></esg-dot-flashing>
</div>
