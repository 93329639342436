import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'esg-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input({ required: true }) checked!: boolean;
  @Input() disabled: boolean = false;
  @Output() onCheck = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleOnCheck(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (!this.disabled) {
      this.onCheck.emit();
      this.checked = !this.checked;
    }
  }
}
