import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IndicatorDetailsUi, IndicatorUnitUi } from '../../indicator-details-state.service';
import { AllRecordsDialogStateService } from './all-records-dialog-state.service';
import { IndicatorTableValueDefinition, IndicatorValueType, RecordDuration } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-all-records-dialog',
  templateUrl: './all-records-dialog.component.html',
  styleUrl: './all-records-dialog.component.scss',
  providers: [AllRecordsDialogStateService],
})
export class AllRecordsDialogComponent {
  @Input({ required: true }) indicator!: IndicatorDetailsUi;
  @Input({ required: true }) indicatorValueType!: IndicatorValueType;
  @Input({ required: true }) indicatorRecordDuration!: RecordDuration;
  @Input() indicatorUnit: IndicatorUnitUi = { key: '', name: '', label: '', description: '' };
  @Input() indicatorTableDefinition?: IndicatorTableValueDefinition;
  @Output() onClose = new EventEmitter();

  constructor(public state: AllRecordsDialogStateService) {}

  ngOnInit(): void {
    this.state.init(this.indicator.id);
  }
}
