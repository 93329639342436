import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface TutorialUi {
  title: string;
  description: string;
  thumbnailSrc: string;
  videoSrc: string;
}

@Component({
  selector: 'esg-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrl: './tutorials.component.scss',
})
export class TutorialsComponent {
  tutorials: TutorialUi[] = [
    {
      title: 'Basic Overview',
      videoSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/1-General-master-720.mp4',
      thumbnailSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/1-General-master-720-thumbnail.png',
      description: 'Get a basic overview of the ESG Application',
    },
    {
      title: 'Assets',
      videoSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/2-Asset-master-720.mp4',
      thumbnailSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/2-Asset-master-720-thumbnail.png',
      description: 'Get an overview of the asset feature',
    },
    {
      title: 'Raw Data',
      videoSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/3-Records-master-720.mp4',
      thumbnailSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/3-Records-master-720-thumbnail.png',
      description: 'Get an overview of how to add raw data records',
    },
    {
      title: 'Reports',
      videoSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/4-Report-master-720.mp4',
      thumbnailSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/4-Report-master-720-thumbnail.png',
      description: 'Get an overview of the report tools',
    },
  ];

  selectedTutorial?: TutorialUi = undefined;

  constructor(private domSanitizer: DomSanitizer) {}

  handleOnVideoClick(selectedTutorial: TutorialUi) {
    this.selectedTutorial = selectedTutorial;
  }

  getTrustedImageSrc(thumbnailSrc: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(thumbnailSrc);
  }

  handleOnDialogClose() {
    this.selectedTutorial = undefined;
  }
}
