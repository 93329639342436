export type ObjectArray<T> = { [key: string]: T };

export function toHashTable<T extends { root_id: string }>(aggregates: T[]): ObjectArray<T> {
  return aggregates.reduce((obj, aggregate) => {
    obj[aggregate.root_id] = aggregate;
    return obj;
  }, {} as ObjectArray<T>);
}

export function toHashTableBy<T>(collection: T[], property: keyof T & string): ObjectArray<T> {
  return collection.reduce((result, element) => {
    const propertyValue = String(element[property]);
    result[propertyValue] = element as T;
    return result;
  }, {} as ObjectArray<T>);
}
