import { CellValueType } from 'src/api-client/report-api.generated';
import { IOption } from '../table-dropdown/table-dropdown.component';

export interface GenericColumnUi {
  header: string;
  description?: string;
  columnId: string;
  type: GenericColumnType;
  width?: string;
  options?: IOption[];
  unit?: string;
}

export interface GenericRowUi {
  rowId: string;
  values: GenericRowValueUi[];
}

export interface GenericRowValueUi {
  columnId: string;
  value: string | number;
  unit?: string;
}

export enum GenericColumnType {
  StaticText = 'StaticText',
  Numeric = 'Numeric',
  Generic = 'Generic',
  Selector = 'Selector',
  Currency = 'Currency',
}

export enum HoverActionStateType {
  AddRow,
  AddColumn,
  DeleteRow,
  DeleteColumn,
  None,
}

export function mapCellValueTypeToGenericType(type: CellValueType): GenericColumnType {
  switch (type) {
    case CellValueType.StaticText:
      return GenericColumnType.Generic;
    case CellValueType.Numeric:
      return GenericColumnType.Numeric;
    case CellValueType.Currency:
      return GenericColumnType.Currency;
    default:
      return GenericColumnType.Generic;
  }
}
