import { Component } from '@angular/core';
import { ReportDetailsStateService } from '../report-details-state.service';
import { noContentImages } from 'src/app/static-data/no-content-images';

@Component({
  selector: 'esg-no-topic-selected',
  templateUrl: './no-topic-selected.component.html',
  styleUrls: ['./no-topic-selected.component.scss'],
})
export class NoTopicSelectedComponent {
  noContentImage = {
    header: 'Great to have you back.',
    text: 'Keep up the good work :-)',
    src: '/assets/images/report/report-welcome.png',
    width: '488.75',
    height: '320',
    alt: 'Two colleagues working together in front of a monitor',
  };
  // noContentImage = noContentImages[Math.floor(Math.random() * noContentImages.length)];
  // Temporary removed due to text guidelines ref: https://metizoft.atlassian.net/browse/ESG-1887

  constructor(public reportState: ReportDetailsStateService) {}
}
