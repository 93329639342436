import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-history-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M1.44576 6.7701H2.32932C2.90583 3.78774 5.52519 1.5 8.60826 1.5C12.0987 1.5 15 4.44412 15 8C15 11.5495 12.0987 14.5 8.60826 14.5C6.49649 14.5 4.6291 13.4039 3.43848 11.7789C3.22542 11.4985 3.29435 11.148 3.54501 10.9887C3.8082 10.8294 4.10272 10.9123 4.29698 11.1735C5.25574 12.5309 6.82234 13.4103 8.60826 13.4167C11.566 13.4294 13.9347 11.0078 13.9347 8C13.9347 4.99216 11.566 2.58971 8.60826 2.58971C6.0641 2.58971 3.95859 4.36127 3.41341 6.7701H4.32204C4.76696 6.7701 4.87975 7.08235 4.64163 7.43284L3.23795 9.46569C3.03743 9.75245 2.73664 9.75882 2.52985 9.46569L1.12617 7.43922C0.881784 7.08235 0.994579 6.7701 1.44576 6.7701ZM8.37014 4.26569C8.64586 4.26569 8.86519 4.48873 8.86519 4.76912V8.15294L10.3817 10.2304C10.5696 10.4917 10.5509 10.7912 10.319 10.9569C10.0621 11.1353 9.77382 11.0907 9.56703 10.8167L8.01922 8.73922C7.91269 8.59902 7.86883 8.47157 7.86883 8.325V4.76912C7.86883 4.48873 8.09442 4.26569 8.37014 4.26569Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class HistoryIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
