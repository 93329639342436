import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TableSelectComponent } from '../../table/table-select/table-select.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'esg-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TableSelectComponent),
      multi: true,
    },
  ],
})
export class StatusSelectComponent extends SelectComponent {
  handleFieldClick(event: Event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.toggleCollapse();
    }
  }
}
