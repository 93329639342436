import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'esg-dropdown-arrow',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33634 10.1942C8.14563 10.3676 7.85437 10.3676 7.66366 10.1942L3.45697 6.36997C3.1188 6.06255 3.33629 5.5 3.7933 5.5L12.2067 5.5C12.6637 5.5 12.8812 6.06255 12.543 6.36997L8.33634 10.1942Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
})
export class DropdownArrowComponent {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  @Input() size: string = '16';
  @Input() color: string = 'black';
}
