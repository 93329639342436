import { Component, Input, OnInit } from '@angular/core';
import { OrganizationAssetDetailsStateService } from './organization-asset-details-state.service';

@Component({
  selector: 'esg-organization-asset-details',
  templateUrl: './organization-asset-details.component.html',
  styleUrls: ['./organization-asset-details.component.scss'],
  providers: [OrganizationAssetDetailsStateService],
})
export class OrganizationAssetDetailsComponent implements OnInit {
  @Input({ required: true }) organizationId!: string;
  @Input() organizationName: string = '';
  @Input() organizationCountry: string = '';

  constructor(public state: OrganizationAssetDetailsStateService) {}
  ngOnInit(): void {
    this.state.init(this.organizationId);
  }
}
