<div class="full-screen-container">
  <div class="container">
    <div class="background" (click)="handleClickBackground($event)"></div>
    <div class="content-container">
      <div class="content">
        <button *ngIf="showCloseBtn" class="close" (click)="closeClicked()">
          <esg-icon-close size="24"></esg-icon-close>
        </button>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
