<div *ngIf="appAuth.userInitialized && appAuth.userInitialized" class="layout">
  <esg-navbar *ngIf="settingsService.NewNavbarEnabled" (onLogout)="handleLogout()"></esg-navbar>
  <esg-large-navbar *ngIf="!settingsService.NewNavbarEnabled" (onLogout)="handleLogout()"></esg-large-navbar>
  <div class="layout-content">
    <ng-container *ngIf="settingsService.NewNavbarEnabled">
      <router-outlet name="side"></router-outlet>
    </ng-container>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div *ngIf="!appAuth.userInitialized" class="layout">
  <div class="init-content">
    <div class="message">App initialization...</div>
  </div>
</div>

<ng-container *ngIf="appAuth.userInitialized">
  <esg-alert-dialog
    *ngIf="!appInfoState.hasConfirmedWidthSize"
    class="alert-dialog"
    variant="warning"
    title="Screen size recommendations"
    [message]="widthAlertText"
    buttonText="OK, I got it"
    (onClose)="confirmSmallWidth()"></esg-alert-dialog>

  <esg-onboarding-dialog
    *ngIf="appInfoState.hasConfirmedWidthSize && appInfoState.showOnboardingDialog"
    (onClose)="handleCloseOnboardingDialog()"></esg-onboarding-dialog>
</ng-container>
