import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { AssetsPageComponent } from './assets-page.component';
import { AssetListComponent } from './asset-list/asset-list.component';
import { VesselsComponent } from './asset-list/vessels/vessels.component';
import { VehiclesCraftsMachineryComponent } from './asset-list/vehicles-crafts-machinery/vehicles-crafts-machinery.component';
import { FacilitiesComponent } from './asset-list/facilities/facilities.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { VesselDetailsComponent } from './asset-list/vessels/vessel-details/vessel-details.component';
import { VehicleCraftMachineryDetailsComponent } from './asset-list/vehicles-crafts-machinery/vehicle-craft-machinery-details/vehicle-craft-machinery-details.component';
import { MsalGuard } from '@azure/msal-angular';
import { FacilityDetailsComponent } from './asset-list/facilities/facility-details/facility-details.component';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { OrganizationAssetsComponent } from './asset-list/organization-assets/organization-assets.component';
import { OrganizationAssetDetailsComponent } from './asset-list/organization-assets/organization-asset-details/organization-asset-details.component';
import { AssetsMenuComponent } from './assets-menu/assets-menu.component';
import { SuppliersComponent } from './asset-list/suppliers/suppliers.component';
import { ClientsComponent } from './asset-list/clients/clients.component';
import { ClientDetailsComponent } from './asset-list/clients/client-details/client-details.component';
import { SupplierDetailsComponent } from './asset-list/suppliers/supplier-details/supplier-details.component';
import { DirectiveModule } from 'src/app/shared/directives/directive.module';
import { RecordsOverviewComponent } from './shared/records-overview/records-overview.component';
import { TableModule } from 'src/app/shared/table/table.module';
import { AssetsDefaultComponent } from './assets-default/assets-default.component';
import { AssetVersionFormComponent } from './shared/asset-version-form/asset-version-form.component';
import { AssetVersionMenuComponent } from './shared/asset-version-menu/asset-version-menu.component';
import { AssetsMenuStateService } from './assets-menu/assets-menu-state.service';
import { AssetFormDialogComponent } from './asset-form-dialog/asset-form-dialog.component';
import { AssetTableComponent } from './shared/asset-table/asset-table.component';
import { VesselsTableComponent } from './asset-list/vessels/vessels-table/vessels-table.component';
import { VehiclesCraftsMachineryTableComponent } from './asset-list/vehicles-crafts-machinery/vehicles-crafts-machinery-table/vehicles-crafts-machinery-table.component';
import { SuppliersTableComponent } from './asset-list/suppliers/suppliers-table/suppliers-table.component';
import { FacilitiesTableComponent } from './asset-list/facilities/facilities-table/facilities-table.component';
import { ClientsTableComponent } from './asset-list/clients/clients-table/clients-table.component';
import { KeyboardShortcutsDirective } from 'src/app/shared/directives/keyboard-shortcuts.directive';
import { SideNavigationComponent } from 'src/app/app-layout/side-navigation/side-navigation.component';

@NgModule({
  declarations: [
    AssetsPageComponent,
    AssetListComponent,
    VesselsComponent,
    VehiclesCraftsMachineryComponent,
    FacilitiesComponent,
    OrganizationAssetsComponent,
    VesselDetailsComponent,
    VehicleCraftMachineryDetailsComponent,
    FacilityDetailsComponent,
    OrganizationAssetDetailsComponent,
    SuppliersComponent,
    ClientsComponent,
    ClientDetailsComponent,
    SupplierDetailsComponent,
    AssetsMenuComponent,
    RecordsOverviewComponent,
    AssetsDefaultComponent,
    AssetVersionFormComponent,
    AssetVersionMenuComponent,
    AssetTableComponent,
    VesselsTableComponent,
    AssetFormDialogComponent,
    VehiclesCraftsMachineryTableComponent,
    SuppliersTableComponent,
    FacilitiesTableComponent,
    ClientsTableComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    TableModule,
    SharedComponentsModule,
    DirectiveModule,
    KeyboardShortcutsDirective,
    RouterModule.forChild([
      {
        path: `assets`,
        component: AppLayoutComponent,
        children: [
          {
            path: ``,
            component: SideNavigationComponent,
            outlet: 'side',
          },
          {
            path: ``,
            component: AssetsPageComponent,
            children: [
              { path: '', component: AssetsDefaultComponent },
              { path: `vessels`, component: VesselsComponent },
              { path: `vehicles-crafts-machinery`, component: VehiclesCraftsMachineryComponent },
              { path: `facilities`, component: FacilitiesComponent },
              { path: `organizations`, component: OrganizationAssetsComponent },
              { path: `suppliers`, component: SuppliersComponent },
              { path: `clients`, component: ClientsComponent },
            ],
          },
        ],
        canActivate: [MsalGuard],
      },
    ]),
  ],
  exports: [AssetFormDialogComponent],
  providers: [AssetsMenuStateService],
})
export class AssetsModule {}
