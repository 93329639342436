import { IOption } from 'src/app/static-data/options';

export function sortOptionsAlphabetically(options: IOption[]): IOption[] {
  return options.sort((a, b) => {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  });
}

export function findLongestLabelInOptions(options: IOption[]): string {
  if (options.length) {
    return options.reduce(function (a, b) {
      const labelA = a.label || '';
      const labelB = b.label || '';
      return labelA.length > labelB.length ? a : b;
    }).label;
  } else return '';
}
