import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface NotificationLinkUi {
  text: string;
  routerLink: string | string[];
  queryParams?: { [key: string]: any };
  fragment?: string;
}

@Component({
  selector: 'esg-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  host: {
    '[class]': 'variant',
    '(click)': 'handleClick($event)',
  },
})
export class NotificationComponent {
  @Input() title: string = '';
  @Input() variant: 'error' | 'info' | 'success' | 'warning' = 'info';
  @Input() message?: string;
  @Input() link?: NotificationLinkUi;
  @Output() onClose = new EventEmitter();

  handleClose() {
    this.onClose.emit();
  }

  handleClick(event: Event) {
    event.stopPropagation();
  }
}
