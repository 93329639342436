import { Injectable } from '@angular/core';
import {
  DataRecordType,
  DataRecordCategory,
  AssetType,
  ICreateEmployeeWorkRelatedInjuriesDataRecordCommandParams,
  CreateEmployeeWorkRelatedInjuriesDataRecordCommandParams,
  CreateEmployeeMarineCasualtyDataRecordCommandParams,
  ICreateEmployeeMarineCasualtyDataRecordCommandParams,
  MarineCasualtyType,
  InputType,
} from 'src/api-client/report-api.generated';
import {
  ExcelImportValidationMessage,
  FieldConfig,
  ImportDataOutput,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { RecordsStateService } from '../records-state.service';
import { MarineCasualtyTypeOptions } from 'src/app/static-data/options';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class MarineCasualtyStateService extends RecordsStateService {
  title = 'Marine Casualty';
  recordCategory = DataRecordCategory.EmployessMarineCasualties;
  assetCategories = [AssetType.Organization, AssetType.Facility];

  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'marineCasultyType', title: 'Type', type: 'string' },
    { propertyName: 'incidents', title: 'Incidents', type: 'number' },
  ];

  marineCasualtyTypeOptions = MarineCasualtyTypeOptions;

  importFromExcelEnabled = true;

  importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) {
      this.notificationService.showError(assetValidation.title, assetValidation.description);
      this.importingInProgress = false;
      return;
    }

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'marineCasultyType',
      this.marineCasualtyTypeOptions,
      data.records,
      'Type'
    );

    const addRecordPromises = data.records.map((record, index) => {
      const asset = this.getAssetOptionColumn(record.asset?.value);

      const marineCasualtyType = this.marineCasualtyTypeOptions.find(o => o.label === record.marineCasultyType?.value);
      if (!marineCasualtyType) {
        this.notificationService.showError('Not valid imported data', `Type column is not valid for ${index + 1} row`);
        return;
      }

      const incidents: ICreateEmployeeMarineCasualtyDataRecordCommandParams = {
        type: DataRecordType.EmployeeMarineCasualty,
        dataSetId: dataSetId,
        assetId: asset.value,
        inputType: InputType.ImportedFromExcel,
        startDate: record.startDate?.value,
        endDate: record.endDate?.value,
        marineCasualtyType: MarineCasualtyType[marineCasualtyType.value as keyof typeof MarineCasualtyType],
        incidents: record.incidents?.value,
      };

      return this.dataRecordService.createDataRecord(
        new CreateEmployeeMarineCasualtyDataRecordCommandParams({ ...incidents })
      );
    });
    Promise.all(addRecordPromises).then(async response => {
      response.forEach(res => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });
      await this.loadRecords();
      this.importingInProgress = false;
      this.importDataFromExcelClose();
    });
  }

  async loadRecords() {
    await this.getDataRecords(DataRecordType.EmployeeMarineCasualty);
  }

  handleDeleteAllFilteredRecordsSubmit() {
    this.handleOnDeleteAllFilteredRecords(DataRecordType.EmployeeMarineCasualty);
  }
}
