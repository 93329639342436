import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ITreeOption {
  value: string;
  label: string;
  children?: ITreeOption[];
}

export function findNestedOption(options: ITreeOption[], value: string): ITreeOption | undefined {
  for (const option of options) {
    if (option.value === value) {
      return option;
    }
    if (option.children) {
      const nestedOption = findNestedOption(option.children, value);
      if (nestedOption) {
        return nestedOption;
      }
    }
  }
  return undefined;
}

@Component({
  selector: 'esg-select-tree-options',
  templateUrl: './select-tree-options.component.html',
  styleUrl: './select-tree-options.component.scss',
})
export class SelectTreeOptionsComponent {
  @Input() level = 0;
  @Input({ required: true }) options: ITreeOption[] = [];
  @Input({ required: true }) value?: ITreeOption;

  @Output() onSelectOption = new EventEmitter<ITreeOption>();

  handleSelectOption(option: ITreeOption) {
    this.onSelectOption.emit(option);
  }
  getPadding(): string {
    return `${this.level * 12}px`; // Adjust the multiplier as needed
  }
}
