<div class="container">
  <button
    *ngIf="type === 'horizontal'"
    [tabIndex]="disableTab ? -1 : 0"
    class="ellipsis"
    [ngClass]="{ 'ellipsis--active': open }"
    (click)="handleClick($event)"
    (mouseenter)="iconHover = true"
    (mouseleave)="iconHover = false">
    <esg-ellipsis-horizontal
      class="ellipsis-icon"
      [color]="iconHover && !open ? '#183642' : '#666666'"></esg-ellipsis-horizontal>
  </button>
  <button
    *ngIf="type === 'vertical'"
    [tabIndex]="disableTab ? -1 : 0"
    class="ellipsis"
    [ngClass]="{ 'ellipsis--active': open }"
    (click)="handleClick($event)"
    (mouseenter)="iconHover = true"
    (mouseleave)="iconHover = false">
    <esg-ellipsis-vertical
      class="ellipsis-icon"
      [color]="iconHover && !open ? '#183642' : '#666666'"></esg-ellipsis-vertical>
  </button>
  <ng-content *ngIf="type === 'custom'"></ng-content>
  <div
    *ngIf="open"
    esg-on-click-outside
    (onClickOutside)="handleClose()"
    class="options"
    esgIntersectionObserver
    [threshold]="0"
    rootMargin="0px"
    (onIntersection)="handleIntersection($event)"
    [class.popup]="isPopup"
    [ngStyle]="style">
    <div *ngIf="optionsHeader" class="header">{{ optionsHeader }}</div>
    <div
      *ngFor="let item of options; trackBy: trackByOption"
      class="option"
      [class.disabled]="item.disabled"
      (click)="handleClickedOnOption(item, $event)">
      <div class="label">{{ item.label }}</div>
    </div>
    <div *ngIf="options.length === 0" class="option no-item">
      <div class="label">no items</div>
    </div>
  </div>
</div>
