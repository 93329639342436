import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page.component';
import { RouterModule } from '@angular/router';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { HomeContentComponent } from './home-content/home-content.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { AssignedTasksComponent } from './assigned-tasks/assigned-tasks.component';
import { SideNavigationComponent } from 'src/app/app-layout/side-navigation/side-navigation.component';

@NgModule({
  declarations: [HomePageComponent, HomeContentComponent, AssignedTasksComponent],
  imports: [
    CommonModule,
    UiModule,
    RouterModule.forChild([
      {
        path: `home`,
        component: AppLayoutComponent,
        children: [
          {
            path: ``,
            component: SideNavigationComponent,
            outlet: 'side',
          },
          {
            path: ``,
            component: HomePageComponent,
          },
        ],
        canActivate: [MsalGuard],
      },
    ]),
  ],
})
export class HomeModule {}
