import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IMenuItem } from '../../ui/context-menu/context-menu.component';
import { AppInfoService } from 'src/app/core/app-info.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

interface Style {
  [key: string]: string;
}

@Component({
  selector: 'esg-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss'],
})
export class DetailsDialogComponent {
  @Input() open: boolean = false;
  @Input() options: IMenuItem[] = [];
  @Input() callToActionText: string = '';
  @Input() fade: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() onCallToAction = new EventEmitter();
  @ViewChild('content', { static: true }) content!: ElementRef;

  stickyToolbar: boolean = false;
  dialogStyle: Style = { 'margin-top': '72px', 'padding-bottom': '72px' };

  constructor(private settingsService: AppConfigurationService, public hostRef: ElementRef) {}

  ngOnInit(): void {
    if (!this.settingsService.NewNavbarEnabled) {
      this.dialogStyle = { 'margin-top': '120px', 'padding-bottom': '120px' };
    }
  }

  handleStickyToolbar(tabEntry: IntersectionObserverEntry) {
    this.stickyToolbar = tabEntry.intersectionRatio === 0;
  }
}
