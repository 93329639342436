import { Injectable } from '@angular/core';
import {
  AssetType,
  CreateVesselDurationInProtectedAreasDataRecordCommandParams,
  DataRecordCategory,
  DataRecordType,
  ICreateVesselDurationInProtectedAreasDataRecordCommandParams,
  InputType,
} from 'src/api-client/report-api.generated';
import { RecordsStateService } from '../records-state.service';
import {
  ExcelImportValidationMessage,
  FieldConfig,
  ImportDataOutput,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class MarineProtectedAreasStateService extends RecordsStateService {
  recordCategory = DataRecordCategory.BiodiversityProtectedAreas;

  assetCategories = [AssetType.Vessel, AssetType.Organization];

  importFromExcelEnabled = true;
  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'days', title: 'Days', type: 'number' },
  ];

  importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) validationMessages.push(assetValidation);

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    const addRecordPromises = data.records.map((record, index) => {
      const asset = this.getAssetOptionColumn(record.asset?.value);
      const vesselDurationInProtectedAreasData: ICreateVesselDurationInProtectedAreasDataRecordCommandParams = {
        type: DataRecordType.VesselDurationInProtectedAreas,
        dataSetId: dataSetId,
        assetId: asset.value,
        inputType: InputType.ImportedFromExcel,
        startDate: record.startDate?.value,
        endDate: record.endDate?.value,
        days: record.days?.value || 0,
      };

      return this.dataRecordService.createDataRecord(
        new CreateVesselDurationInProtectedAreasDataRecordCommandParams({ ...vesselDurationInProtectedAreasData })
      );
    });
    Promise.all(addRecordPromises).then(async response => {
      response.forEach(res => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });
      await this.loadRecords();
      this.importingInProgress = false;
      this.importDataFromExcelClose();
    });
  }
}
