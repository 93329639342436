<esg-form-dialog
  [submitText]="state.submitText"
  [submitDisabled]="!state.formgroup || !state.formgroup.valid || !state.formgroup.dirty"
  (onCancel)="onCancel.emit()"
  (onSubmit)="handleFormSubmit()">
  <div class="container">
    <header>{{ state.headerText }}</header>

    <div *ngIf="state.textDisclosureFc" class="content">
      <div class="input-columns">
        <div class="input-column">
          <esg-textbox-form
            label="TITLE"
            placeholder="e.g GHG Emissions Policy"
            [fc]="state.textDisclosureFc.title"></esg-textbox-form>
          <esg-textarea
            class="input-references"
            label="ADD REFERENCES"
            [showOptionalText]="false"
            placeholder="i.e GRI 306-1"
            [fc]="state.textDisclosureFc.references"></esg-textarea>
        </div>
        <div class="input-column">
          <esg-textarea
            class="input-description"
            [fc]="state.textDisclosureFc.description"
            label="DESCRIPTION"
            placeholder="Write a short guidance of what to report"></esg-textarea>
        </div>
      </div>
    </div>
  </div>
</esg-form-dialog>
