import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'esg-topic-item-placeholder',
  templateUrl: './topic-item-placeholder.component.html',
  styleUrl: './topic-item-placeholder.component.scss',
})
export class TopicItemPlaceholderComponent {
  @Input() scrollIntoViewOnInit: boolean = false;

  constructor(private hostRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.scrollIntoViewOnInit) {
      this.hostRef.nativeElement.scrollIntoView();
    }
  }
}
