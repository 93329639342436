<esg-dialog class="auto" (onClose)="onClose.emit()">
  <div class="content">
    <div class="title">
      {{ title }}
    </div>
    <div class="history-list">
      <div *ngFor="let item of historyItems; let i = index" class="history-item-container">
        <div class="history-item">
          <div class="arrow" (click)="handleOpenDetails(i)"><esg-arrow-down></esg-arrow-down></div>
          <div class="date">{{ item.date | date }}</div>
          <div class="user-name">{{ item.userName }}</div>
        </div>
        <div *ngIf="openDetailsIndex === i" class="history-item-details">
          <ng-container *ngFor="let item of item.changes">
            <div class="property">
              {{ item.name }}
            </div>
            <div class="value">
              {{ item.value }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</esg-dialog>
