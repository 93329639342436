import { Component, Input } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenderOptions, IOption, ParentalLeaveOptions } from 'src/app/static-data/options';
import {
  CreateEmployeeParentalLeavesDataRecordCommandParams,
  DataRecordType,
  EmployeeParentalLeavesDataRecord,
  Gender,
  InputType,
  ParentalLeaveMode,
  UpdateEmployeeParentalLeavesDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';

interface ParentalLeaveRecordForm extends RecordForm {
  mode: FormControl<IOption>;
  gender: FormControl<IOption>;
  region: FormControl<IOption | undefined>;
}

@Component({
  selector: 'esg-parental-leave-form-table',
  templateUrl: './parental-leave-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: ParentalLeaveFormTableComponent }],
})
export class ParentalLeaveFormTableComponent extends RecordFormTableComponent {
  @Input() regionOptions: IOption[] = [];

  headerCells = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'mode', label: 'Mode', description: '', number: false },
    { columnId: 'gender', label: 'Gender', description: '', number: false },
    { columnId: 'region', label: 'Region', description: '', number: false },
  ];

  genderOptions = GenderOptions;
  parentalLeaveOptions = ParentalLeaveOptions;

  recordFc?: ParentalLeaveRecordForm = undefined;
  recordFormgroup?: FormGroup<ParentalLeaveRecordForm> = undefined;
  submitOnPristine: boolean = true;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as EmployeeParentalLeavesDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'mode',
          value: this.parentalLeaveOptions.find(c => c.value === record.mode)?.label || '',
          number: false,
        },
        {
          columnId: 'gender',
          value: this.genderOptions.find(c => c.value === record.gender)?.label || '',
          number: false,
        },
        {
          columnId: 'region',
          value: this.regionOptions.find(c => c.value === record.regionId)?.label || '',
          number: false,
        },
      ],
    }));
  }

  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      mode: new FormControl<IOption>(this.parentalLeaveOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      gender: new FormControl<IOption>(this.genderOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      region: new FormControl<IOption | undefined>(this.regionOptions.length ? this.regionOptions[0] : undefined, {
        validators: [Validators.required],
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const parentalLeavesRecord = this.dataRecords.find(record => record.id === id) as EmployeeParentalLeavesDataRecord;
    if (parentalLeavesRecord) {
      this.recordFc = {
        startDate: new FormControl<Date>(parentalLeavesRecord.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(parentalLeavesRecord.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === parentalLeavesRecord.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        mode: new FormControl<IOption>(
          this.parentalLeaveOptions.find(opt => opt.value === parentalLeavesRecord.mode) ||
            this.parentalLeaveOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        gender: new FormControl<IOption>(
          this.genderOptions.find(opt => opt.value === parentalLeavesRecord.gender) || this.genderOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        region: new FormControl<IOption | undefined>(
          this.regionOptions.find(opt => opt.value === parentalLeavesRecord.regionId) || this.regionOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
      };
      this.recordFormgroup = new FormGroup(this.recordFc);
    }
  }

  getParams():
    | CreateEmployeeParentalLeavesDataRecordCommandParams
    | UpdateEmployeeParentalLeavesDataRecordCommandParams
    | undefined {
    const parentGender = Object.values(Gender).find(type => type === this.recordFc?.gender.value?.value);
    const mode = Object.values(ParentalLeaveMode).find(type => type === this.recordFc?.mode.value?.value);
    if (
      this.recordFormgroup?.valid &&
      this.recordFc &&
      this.recordFc?.asset.value?.value &&
      parentGender &&
      mode &&
      this.recordFc.region.value
    ) {
      const parentLeave = {
        type: DataRecordType.EmployeeParentalLeaves,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        mode: mode,
        gender: parentGender,
        regionId: this.recordFc.region.value.value,
      };
      if (this.editRecordId) {
        return new UpdateEmployeeParentalLeavesDataRecordCommandParams({ id: this.editRecordId, ...parentLeave });
      } else {
        return new CreateEmployeeParentalLeavesDataRecordCommandParams(parentLeave);
      }
    }
    return undefined;
  }
}
