import { Injectable } from '@angular/core';
import { Report, ReportTemplate, ReportVersion } from 'src/api-client/report-api.generated';
import { ReportApiService } from 'src/app/api-client/report-api/report-api-service';
import { TemplateApiService } from 'src/app/api-client/report-api/template-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IndicatorsPageStateService } from '../indicators/indicators-page-state.service';
import { result } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ReportStateService {
  reportFormDialogOpen: boolean = false;
  reportVersion?: ReportVersion;

  isLoadingReports = false;
  isLoadingTemplates = false;
  reports: Report[] = [];
  templates: ReportTemplate[] = [];
  constructor(
    private reportApiService: ReportApiService,
    private templateApiService: TemplateApiService,
    private IndicatorsPageStateService: IndicatorsPageStateService,
    private notificationService: NotificationService
  ) { }

  handleCreate(reportVersion: ReportVersion) {
    this.reportVersion = reportVersion;
    this.reportFormDialogOpen = true;
  }

  handleCloseCreateDialog() {
    this.reportVersion = undefined;
    this.reportFormDialogOpen = false;
  }

  loadReports() {
    this.isLoadingReports = true;
    this.reportApiService.all().subscribe({
      next: result => {
        this.reports = result.result || [];
        this.isLoadingReports = false;
      },
      error: error => {
        this.isLoadingReports = false;
      },
      complete: () => {
        this.isLoadingReports = false;
      },
    });
  }

  async duplicateReport(reportId: string) {
    const reportIndex = this.reports.findIndex(report => report.id === reportId);
    const emptyReport = new Report();
    if (reportIndex !== -1) {
      this.reports.splice(reportIndex + 1, 0, emptyReport);
    } else {
      this.reports.push(emptyReport);
    }

    try {
      const result = await this.reportApiService.duplicateById(reportId);
      if (result.success && result.newDuplicatedReportId) {
        const response = await this.reportApiService.getByReportId(result.newDuplicatedReportId);
        if (reportIndex !== -1) {
          this.reports.splice(reportIndex + 1, 1, response.result);
        } else {
          this.reports.pop();
          this.reports.push(response.result);
        }
      } else {
        if (reportIndex !== -1) {
          this.reports.splice(reportIndex + 1, 1);
        } else {
          this.reports.pop();
        }
      }
    } catch (error) {
      if (reportIndex !== -1) {
        this.reports.splice(reportIndex + 1, 1);
      } else {
        this.reports.pop();
      }
      this.notificationService.showError('Unable to duplicate report');
    }
  }

  removeReport(reportId: string) {
    this.reports = this.reports.filter(r => r.id !== reportId);
    this.reportApiService.removeById(reportId).subscribe(result => {
      console.log('Removed: ' + reportId);
    });
  }

  removeTemplate(templateId: string) {
    this.templates = this.templates.filter(r => r.id !== templateId);
    this.templateApiService.removeTemplate(templateId).subscribe(result => {
      console.log('Removed: ' + templateId);
    });
  }

  loadTemplates() {
    this.isLoadingTemplates = true;
    this.templateApiService.getAllTemplates(false).subscribe(
      result => {
        this.templates = result.result || [];
        this.isLoadingTemplates = false;
      },
      error => {
        this.isLoadingTemplates = false;
      },
      () => {
        this.isLoadingTemplates = false;
      }
    );
  }
}
