import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import {
  CreateEmployeeWorkRelatedInjuriesDataRecordCommandParams,
  DataRecordType,
  EmployeeWorkRelatedInjuriesDataRecord,
  InputType,
  UpdateEmployeeWorkRelatedInjuriesDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';

interface WorkRelatedInjuriesRecordForm extends RecordForm {
  recordableInjuries: FormControl<string>;
  fatalities: FormControl<string>;
  highConsequenceInjuries: FormControl<string>;
  illnessHealth: FormControl<string>;
  lostTimeInjuries: FormControl<string>;
  daysLost: FormControl<string>;
  exposureHours: FormControl<string>;
}

@Component({
  selector: 'esg-work-related-injuries-form-table',
  templateUrl: './work-related-injuries-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: WorkRelatedInjuriesFormTableComponent }],
})
export class WorkRelatedInjuriesFormTableComponent extends RecordFormTableComponent {
  headerCells = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    {
      columnId: 'recordableInjuries',
      label: 'Recordable Injuries',
      description: 'Total Recordable Injuries',
      number: true,
    },
    { columnId: 'fatalities', label: 'Fatalities', description: 'Total Number', number: true },
    {
      columnId: 'highConsequenceInjuries',
      label: 'High Consequence Injuries',
      description: 'Total Number',
      number: true,
    },
    { columnId: 'illnessHealth', label: 'Illness/Health', description: 'Total Number', number: true },
    { columnId: 'lostTimeInjuries', label: 'Lost Time Injuries', description: 'Total Number', number: true },
    { columnId: 'daysLost', label: 'Days lost', description: 'Total Number', number: true },
    { columnId: 'exposureHours', label: 'Exposure Hours', description: 'Total Work Hours', number: true },
  ];

  recordFc?: WorkRelatedInjuriesRecordForm = undefined;
  recordFormgroup?: FormGroup<WorkRelatedInjuriesRecordForm> = undefined;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as EmployeeWorkRelatedInjuriesDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        { columnId: 'recordableInjuries', value: record.recordableInjuries?.toString() || '', number: true },
        { columnId: 'fatalities', value: record.fatalities?.toString() || '', number: true },
        { columnId: 'highConsequenceInjuries', value: record.highConsequenceInjuries?.toString() || '', number: true },
        { columnId: 'illnessHealth', value: record.illnessHealth?.toString() || '', number: true },
        { columnId: 'lostTimeInjuries', value: record.lostTimeInjuries?.toString() || '', number: true },
        { columnId: 'daysLost', value: record.daysLost?.toString() || '', number: true },
        { columnId: 'exposureHours', value: record.exposureHours?.toString() || '', number: true },
      ],
    }));
  }

  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      recordableInjuries: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      fatalities: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      highConsequenceInjuries: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      illnessHealth: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      lostTimeInjuries: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      daysLost: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      exposureHours: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    };
    this.recordFormgroup = new FormGroup(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const incidentsRecord = this.dataRecords.find(record => record.id === id) as EmployeeWorkRelatedInjuriesDataRecord;
    if (incidentsRecord) {
      this.recordFc = {
        startDate: new FormControl<Date>(incidentsRecord.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(incidentsRecord.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === incidentsRecord.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        recordableInjuries: new FormControl<string>(incidentsRecord.recordableInjuries.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        fatalities: new FormControl<string>(incidentsRecord.fatalities.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        highConsequenceInjuries: new FormControl<string>(incidentsRecord.highConsequenceInjuries.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        illnessHealth: new FormControl<string>(incidentsRecord.illnessHealth.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        lostTimeInjuries: new FormControl<string>(incidentsRecord.lostTimeInjuries.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        daysLost: new FormControl<string>(incidentsRecord.daysLost.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        exposureHours: new FormControl<string>(incidentsRecord.exposureHours.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
      };
      this.recordFormgroup = new FormGroup(this.recordFc);
    }
  }

  getParams():
    | CreateEmployeeWorkRelatedInjuriesDataRecordCommandParams
    | UpdateEmployeeWorkRelatedInjuriesDataRecordCommandParams
    | undefined {
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc?.asset.value?.value) {
      const incidents = {
        type: DataRecordType.EmployeeWorkRelatedInjuries,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        recordableInjuries: formattedStringToNumber(this.recordFc.recordableInjuries.value) || 0,
        fatalities: formattedStringToNumber(this.recordFc.fatalities.value) || 0,
        highConsequenceInjuries: formattedStringToNumber(this.recordFc.highConsequenceInjuries.value) || 0,
        illnessHealth: formattedStringToNumber(this.recordFc.illnessHealth.value) || 0,
        lostTimeInjuries: formattedStringToNumber(this.recordFc.lostTimeInjuries.value) || 0,
        daysLost: formattedStringToNumber(this.recordFc.daysLost.value) || 0,
        exposureHours: formattedStringToNumber(this.recordFc.exposureHours.value) || 0,
      };
      if (this.editRecordId) {
        return new UpdateEmployeeWorkRelatedInjuriesDataRecordCommandParams({ id: this.editRecordId, ...incidents });
      } else {
        return new CreateEmployeeWorkRelatedInjuriesDataRecordCommandParams(incidents);
      }
    }
    return undefined;
  }
}
