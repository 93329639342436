import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUserInfoVm, Permission, UserRole } from 'src/api-client/report-api.generated';
import { AppInfoService } from 'src/app/core/app-info.service';

@Injectable({
  providedIn: 'root',
})
export class AdminPageStateService {
  private userInfoSub!: Subscription;

  hasOrganizationReadPermission = false;
  hasUserReadPermission = false;
  hasAccessTokenReadPermission = false;
  hasSettingsReadPermission = false;
  showClientSettings = false;
  showSuperAdminSettings = false;
  userInfo?: IUserInfoVm;
  constructor(public appInfoState: AppInfoService) {
    this.userInfoSub = this.appInfoState.userInfo$.subscribe(result => {
      this.userInfo = result;
      this.showClientSettings = result?.role === UserRole.Admin || result?.role === UserRole.SuperAdmin;
      this.showSuperAdminSettings = result?.role === UserRole.SuperAdmin;
      this.hasOrganizationReadPermission = this.appInfoState.hasPermission(Permission.Report_Organization_Read);
      this.hasUserReadPermission = this.appInfoState.hasPermission(Permission.Report_User_Read);
      this.hasAccessTokenReadPermission = this.appInfoState.hasPermission(Permission.AccessToken_Read);
      this.hasSettingsReadPermission = this.appInfoState.hasPermission(Permission.Settings_Read);
    });
  }

  ngOnDestroy() {
    this.userInfoSub.unsubscribe();
  }
}
