import { Component, ElementRef, ViewChild } from '@angular/core';
import { ContentExampleStateService } from './content-example-state.service';
import { FormControl } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import _ from 'lodash';

@Component({
  selector: 'esg-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.scss'],
})
export class ExamplesComponent {
  @ViewChild('content', { static: true }) content!: ElementRef;

  dropdownFavouriteOptions: IOption[] = [
    { value: 'EUR', label: 'EUR' },
    { value: 'NOK', label: 'NOK' },
    { value: 'PLN', label: 'PLN' },
    { value: 'USD', label: 'USD' },
  ];

  dropdownFavouriteValue: IOption = { value: 'PLN', label: 'PLN' };

  favouriteOptions: IOption[] = [
    { value: 'NOK', label: 'NOK' },
    { value: 'PLN', label: 'PLN' },
  ];

  dropdownMultipleOptions: IOption[] = [
    { value: 'Emissions - Scope 1', label: 'Emissions - Scope 1' },
    { value: 'Emissions - Scope 2', label: 'Emissions - Scope 2' },
    { value: 'Emissions - SOx', label: 'Emissions - SOx' },
    { value: 'Emissions - NOx', label: 'Emissions - NOx' },
  ];
  dropdownMultipleFc = new FormControl<IOption[]>(
    [this.dropdownMultipleOptions[2], this.dropdownMultipleOptions[0], this.dropdownMultipleOptions[3]],
    { nonNullable: true }
  );

  checkboxFc = new FormControl<boolean>(true, { nonNullable: true });

  tableInputFormControl: FormControl = new FormControl<string>('test text');
  tableInputFormControl2: FormControl = new FormControl<string>('test text2');

  tableDropdownOptions1: IOption[] = [
    { value: 'aa', label: 'aaaaaa' },
    { value: 'bb', label: 'bbbbbbbbbbbbbbb' },
  ];
  tableDropdownFormControl1: FormControl = new FormControl<IOption>(this.tableDropdownOptions1[1]);

  constructor(public contentState: ContentExampleStateService) {}

  ngOnInit(): void {
    console.log(detectFont('已選擇聯合'));
    console.log(detectFont('到目前为止，我们对Covid-19变体了解多少？'));
  }
}

function detectFont(text: string) {
  if (!_.isString(text)) {
    return 'Roboto';
  }

  // https://stackoverflow.com/a/15034560/1501613
  const containsCJK = text.match(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/);

  return containsCJK ? 'Chinese' : 'Roboto';
}
