import { Component } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption, SpillTypeOptions } from 'src/app/static-data/options';
import { formatDate } from '@angular/common';
import {
  CreateVesselSpillsDataRecordCommandParams,
  DataRecordType,
  InputType,
  UpdateVesselSpillsDataRecordCommandParams,
  VesselSpillsDataRecord,
} from 'src/api-client/report-api.generated';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface SpillsToEnvironmentRecordForm extends RecordForm {
  spillsType: FormControl<IOption>;
  isHazardous: FormControl<IOption>;
  volume_m3: FormControl<string>;
}

@Component({
  selector: 'esg-spills-to-environment-form-table',
  templateUrl: './spills-to-environment-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: SpillsToEnvironmentFormTableComponent }],
})
export class SpillsToEnvironmentFormTableComponent extends RecordFormTableComponent {
  spillsOptions = SpillTypeOptions;
  hazardiousOptions: IOption[] = [
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes' },
  ];

  headerCells: RecordHeaderCell[] = [
    { columnId: 'date', label: 'Date', description: 'Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'spillsType', label: 'Type', description: 'Gas, fuel, oil, chemicals, bulk cargoes', number: false },
    { columnId: 'isHazardous', label: 'Hazardous', description: 'yes/no', number: false },
    { columnId: 'volume_m3', label: 'Volume', description: 'm&sup3;', number: true },
  ];

  recordFc?: SpillsToEnvironmentRecordForm = undefined;
  recordFormgroup?: FormGroup<SpillsToEnvironmentRecordForm> = undefined;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as VesselSpillsDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'date',
          value: formatDate(record.startDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'spillsType',
          value: this.spillsOptions.find(c => c.value === record.spillsType)?.label || '',
          number: false,
        },
        {
          columnId: 'isHazardous',
          value: record.isHazardous ? 'Yes' : 'No',
          number: false,
        },
        {
          columnId: 'volume_m3',
          value: record.volume_m3.toString(),
          number: true,
        },
      ],
    }));
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      spillsType: new FormControl<IOption>(this.spillsOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      isHazardous: new FormControl<IOption>(this.hazardiousOptions[0], {
        nonNullable: true,
      }),
      volume_m3: new FormControl<string>('', {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup<SpillsToEnvironmentRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const record = this.dataRecords.find(record => record.id === id) as VesselSpillsDataRecord;
    if (record) {
      const spillOption: IOption = this.spillsOptions.find(o => o.value === record.spillsType) || this.spillsOptions[0];
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.startDate, {
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        spillsType: new FormControl<IOption>(spillOption || this.spillsOptions[0], {
          validators: [Validators.required],
          nonNullable: true,
        }),
        isHazardous: new FormControl<IOption>(
          record.isHazardous ? this.hazardiousOptions[1] : this.hazardiousOptions[0],
          {
            nonNullable: true,
          }
        ),
        volume_m3: new FormControl<string>(record.volume_m3.toString(), {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup<SpillsToEnvironmentRecordForm>(this.recordFc);
    }
  }

  getParams(): CreateVesselSpillsDataRecordCommandParams | UpdateVesselSpillsDataRecordCommandParams | undefined {
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const spills = {
        type: DataRecordType.VesselSpills,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.startDate.value || new Date(),
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        spillsType: this.recordFc.spillsType.value?.value || '',
        isHazardous: this.recordFc.isHazardous.value?.value === 'true' ? true : false,
        volume_m3: formattedStringToNumber(this.recordFc.volume_m3.value || '') || 0,
      };
      if (this.editRecordId) {
        return new UpdateVesselSpillsDataRecordCommandParams({ id: this.editRecordId, ...spills });
      } else {
        return new CreateVesselSpillsDataRecordCommandParams(spills);
      }
    }
    return undefined;
  }
}
