import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-ellipsis-horizontal',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9ZM8 9C7.44772 9 7 8.55228 7 8C7 7.44771 7.44772 7 8 7C8.55228 7 9 7.44772 9 8C9 8.55228 8.55228 9 8 9ZM3 8C3 8.55228 3.44772 9 4 9C4.55229 9 5 8.55228 5 8C5 7.44771 4.55229 7 4 7C3.44772 7 3 7.44771 3 8Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
})
export class EllipsisHorizontalComponent {
  @Input() size: string = '16';
  @Input() color: string = 'black';
}
