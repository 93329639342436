<esg-side-menu class="side">
  <div class="top section">
    <a [routerLink]="['/home']" routerLinkActive="active">
      <esg-home-icon class="icon" [size]="16"></esg-home-icon>
      <span class="label">Home</span>
    </a>
    <a [routerLink]="['/indicators', 'library']" routerLinkActive="active">
      <esg-ind-library-icon class="icon"></esg-ind-library-icon>
      <span class="label">Indicator Library</span>
      <span class="counter">({{ state.libraryIndicatorCount ?? '-' }})</span>
    </a>
    <a [routerLink]="['/assets']" routerLinkActive="active">
      <esg-asset-icon class="icon"></esg-asset-icon>
      <span class="label">Assets</span>
    </a>
    <a [routerLink]="['/data-collect']" routerLinkActive="active">
      <esg-records-icon class="icon"></esg-records-icon>
      <span class="label">Raw Data</span>
    </a>
  </div>
  <div class="divider"></div>
  <div class="section">
    <span class="section-header">key performance indicators</span>
    <div class="section-tab">
      <a
        [routerLink]="['/indicators', 'our-indicators']"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active">
        <esg-kpi-icon class="icon"></esg-kpi-icon>
        <span class="label">All</span>
        <span class="counter">({{ state.ourIndicatorsCount ?? '-' }})</span>
      </a>
    </div>
    <div class="section-tab">
      <a
        [routerLink]="['/indicators', 'our-indicators']"
        [queryParams]="{ category: 'environment' }"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active">
        <esg-environment-icon class="icon"></esg-environment-icon>
        <span class="label">Environment</span>
        <span class="counter">({{ state.categoryCounts[state.categoryEnum.Environment] ?? '-' }})</span>
        <esg-tooltip
          *ngIf="state.recentlyAdded[state.categoryEnum.Environment]"
          text="Indicator Added"
          position="right"
          variant="dark"
          [underline]="false"
          [alwaysVisible]="true"></esg-tooltip>
      </a>
    </div>
    <div class="section-tab">
      <a
        [routerLink]="['/indicators', 'our-indicators']"
        [queryParams]="{ category: 'social' }"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active">
        <esg-social-icon class="icon"></esg-social-icon>
        <span class="label">Social</span>
        <span class="counter">({{ state.categoryCounts[state.categoryEnum.Social] ?? '-' }})</span>
      </a>
      <esg-tooltip
        *ngIf="state.recentlyAdded[state.categoryEnum.Social]"
        text="Indicator Added"
        position="right"
        variant="dark"
        [underline]="false"
        [alwaysVisible]="true"></esg-tooltip>
    </div>
    <div class="section-tab">
      <a
        [routerLink]="['/indicators', 'our-indicators']"
        [queryParams]="{ category: 'governance' }"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active">
        <esg-governance-icon class="icon"></esg-governance-icon>
        <span class="label">Governance</span>
        <span class="counter">({{ state.categoryCounts[state.categoryEnum.Governance] ?? '-' }})</span>
      </a>
      <esg-tooltip
        *ngIf="state.recentlyAdded[state.categoryEnum.Governance]"
        text="Indicator Added"
        position="right"
        variant="dark"
        [underline]="false"
        [alwaysVisible]="true"></esg-tooltip>
    </div>
    <div class="section-tab">
      <a
        [routerLink]="['/indicators', 'our-indicators']"
        [queryParams]="{ category: 'other' }"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active">
        <esg-stack-icon class="icon"></esg-stack-icon>
        <span class="label">Other</span>
        <span class="counter">({{ state.categoryCounts[state.categoryEnum.Other] ?? '-' }})</span>
      </a>
      <esg-tooltip
        *ngIf="state.recentlyAdded[state.categoryEnum.Other]"
        text="Indicator Added"
        position="right"
        variant="dark"
        [underline]="false"
        [alwaysVisible]="true"></esg-tooltip>
    </div>
  </div>
  <div class="divider"></div>
  <div class="section">
    <span class="section-header">Your reports</span>
    <div class="section-tab">
      <a [routerLink]="['/report']" routerLinkActive="active">
        <esg-dashboard-icon class="icon"></esg-dashboard-icon>
        <span class="label">All reports</span>
      </a>
    </div>
  </div>
</esg-side-menu>
