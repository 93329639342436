import { Component, SimpleChanges } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import {
  CountryCodes,
  CreateOrganizationLobbyPoliticalContributionDataRecordCommandParams,
  DataRecordType,
  InputType,
  OrganizationLobbyPoliticalContributionDataRecord,
  UpdateOrganizationLobbyPoliticalContributionDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import { formatDate } from '@angular/common';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface LobbyPoliticalContributionRecordForm extends RecordForm {
  monetaryValue: FormControl<string>;
  beneficiary: FormControl<string>;
  country: FormControl<IOption>;
}

@Component({
  selector: 'esg-lobby-political-contibution-form-table',
  templateUrl: './lobby-political-contibution-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: LobbyPoliticalContibutionFormTableComponent }],
})
export class LobbyPoliticalContibutionFormTableComponent extends RecordFormTableComponent {
  headerCells: RecordHeaderCell[] = [
    { columnId: 'date', label: 'Date', description: 'Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'beneficiary', label: 'Beneficiary', description: 'Name', number: false },
    { columnId: 'country', label: 'Country', description: 'Name', number: false },
    { columnId: 'monetaryValue', label: 'Monetary Value', description: this.organizationCurrency, number: true },
  ];

  recordFc?: LobbyPoliticalContributionRecordForm = undefined;
  recordFormgroup?: FormGroup<LobbyPoliticalContributionRecordForm> = undefined;

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.organizationCurrency) {
      this.setCurrencyDescription(changes.organizationCurrency.currentValue);
    }
  }

  setCurrencyDescription(currency: string) {
    const currencyHeaderCell = this.headerCells.findIndex(cell => cell.columnId === 'monetaryValue');
    this.headerCells[currencyHeaderCell].description = currency;
  }

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as OrganizationLobbyPoliticalContributionDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'date',
          value: formatDate(record.startDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'beneficiary',
          value: record.beneficiary,
          number: false,
        },
        {
          columnId: 'country',
          value: this.countryOptions.find(opt => opt.value === record.countryCode)?.label || '',
          number: false,
        },
        {
          columnId: 'monetaryValue',
          value: record.monetaryValue.toString(),
          number: true,
          unit: record.currencyCode,
        },
      ],
    }));
  }

  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      monetaryValue: new FormControl<string>('', { nonNullable: true }),
      beneficiary: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      country: new FormControl<IOption>(this.getCountryOptionByAssetId(asset?.value), {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup<LobbyPoliticalContributionRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const record = this.dataRecords.find(
      record => record.id === id
    ) as OrganizationLobbyPoliticalContributionDataRecord;
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.startDate, {
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        monetaryValue: new FormControl<string>(record.monetaryValue.toString(), { nonNullable: true }),
        beneficiary: new FormControl<string>(record.beneficiary, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        country: new FormControl<IOption>(
          this.countryOptions.find(opt => opt.value === record.countryCode) || this.countryOptions[0],
          { nonNullable: true }
        ),
      };
      this.recordFormgroup = new FormGroup<LobbyPoliticalContributionRecordForm>(this.recordFc);
    }
  }

  getParams():
    | CreateOrganizationLobbyPoliticalContributionDataRecordCommandParams
    | UpdateOrganizationLobbyPoliticalContributionDataRecordCommandParams
    | undefined {
    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const countryCode = Object.values(CountryCodes).find(code => code === this.recordFc?.country?.value?.value);
      const lobbyPoliticalContribution = {
        type: DataRecordType.OrganizationLobbyPoliticalContribution,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.startDate.value || new Date(),
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        monetaryValue: formattedStringToNumber(this.recordFc.monetaryValue.value || '') || 0,
        currencyCode: this.organizationCurrency,
        beneficiary: this.recordFc.beneficiary.value || '',
        countryCode: countryCode || CountryCodes.AD,
      };
      if (this.editRecordId) {
        return new UpdateOrganizationLobbyPoliticalContributionDataRecordCommandParams({
          id: this.editRecordId,
          ...lobbyPoliticalContribution,
        });
      } else {
        return new CreateOrganizationLobbyPoliticalContributionDataRecordCommandParams(lobbyPoliticalContribution);
      }
    }
    return undefined;
  }
}
