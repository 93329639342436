import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'esg-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @Input() @Input({ required: true }) fc!: FormControl<string | null>;
  @Input() label = '';
  @Input() placeholder = 'Type...';
  @Input() heigth: number = 48;
  @Input() maxlength = '524288';
  @Input() variant: 'standard' | 'ghost' = 'standard';
  @Input() required = false;
  @Input() valid = false;
  @Input() showOptionalText = true;
  @Input() inputStyle: { [key: string]: string } = {};

  @ViewChild('input') private input: ElementRef<HTMLTextAreaElement> | undefined;

  initialValue: string | null = '';

  constructor() {}

  ngOnInit(): void {
    if (this.fc.hasValidator(Validators.required)) this.required = true;
    if (!this.required && !this.label && this.showOptionalText) this.placeholder = this.placeholder + ' (Optional)';
    this.initialValue = this.fc.value;
  }

  handleFocusOut() {
    this.initialValue = this.fc.value;
  }

  handleEscape() {
    if (this.initialValue === this.fc.defaultValue) {
      this.fc.reset();
    } else {
      this.fc.setValue(this.initialValue);
    }
    this.input?.nativeElement.blur();
  }
}
