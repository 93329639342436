import * as pdfMake from 'pdfmake/build/pdfmake';
import { Content, TDocumentDefinitions, TFontDictionary } from 'pdfmake/interfaces';
import { getFontBase64 } from './fonts';

export class PdfGenerationError extends Error {
  constructor(public id: string, public message: string) {
    super(message);
  }
}

export async function generateVfs(fonts: TFontDictionary): Promise<{ [file: string]: string }> {
  return Object.assign(
    {},
    ...(await Promise.all(
      Object.values(fonts).flatMap(fontStyles =>
        Object.values(fontStyles).map(async fontPath => {
          return getFontBase64(fontPath);
        })
      )
    ))
  );
}

export async function generateReport(
  definition: TDocumentDefinitions,
  fonts: TFontDictionary,
  vfs: { [file: string]: string }
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      const pdfGenerator = pdfMake.createPdf(definition, undefined, fonts, vfs);

      let chunks: Uint8Array[] = [];
      const stream = pdfGenerator.getStream();
      stream.on('data', chunk => {
        chunks.push(chunk);
      });
      stream.on('end', () => {
        const result = new Uint8Array(chunks.reduce((prev, curr) => prev + curr.length, 0));
        let offset = 0;
        chunks.forEach(chunk => {
          result.set(chunk, offset);
          offset += chunk.length;
        });
        const objectUrl = URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
        resolve(objectUrl);
      });
      stream.on('error', error => {
        reject(error);
      });
      stream.end();
    } catch (e) {
      reject(e);
    }
  });
}

export function throwErrorIfPdfDefinitionNotValid(
  contentId: string,
  definition: TDocumentDefinitions,
  fonts: TFontDictionary,
  vfs: { [file: string]: string }
) {
  try {
    pdfMake.createPdf(definition, undefined, fonts, vfs).getStream();
  } catch (e) {
    throw new PdfGenerationError(contentId, `Error in ${contentId}. Error: ${e}`);
  }
}
