import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  DataImportFromExcelStateService,
  FieldConfig,
  ImportDataOutput,
  MappintToValueTypeError,
  ExcelImportValidationMessage,
  validateExcelOptionColumn,
} from './data-import-from-excel-state.service';
import { NotificationService } from '../../services/notification/notification.service';
export { ImportDataOutput, FieldConfig, ExcelImportValidationMessage, validateExcelOptionColumn };
@Component({
  selector: 'esg-data-import-from-excel [importData] [onClose]',
  templateUrl: './data-import-from-excel.component.html',
  styleUrls: ['./data-import-from-excel.component.scss'],
  providers: [DataImportFromExcelStateService],
})
export class DataImportFromExcelComponent implements OnInit {
  @Input() fieldConfigs?: FieldConfig[];
  @Input() importingInProgress = false;
  @Input() importingInProgressLabel = 'Importing...';
  @Output() importData = new EventEmitter<ImportDataOutput>();
  @Output() onClose = new EventEmitter();

  showStickyDivider = false;

  @ViewChild('content') public content!: ElementRef<HTMLElement>;

  constructor(public state: DataImportFromExcelStateService, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.state.initialize(this.fieldConfigs);
  }

  async handleUploadFile(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;

    if (!fileList) {
      throw new Error('No file selected');
    }

    const file = fileList[0];

    this.state.uploadExcelFile(file);
  }

  handleUpdateImport() {
    this.state.importDataOfUpdatedRange(this.state.importSheetRangeFc.value);
  }

  async handleImportData() {
    var importDataOutput = await this.state.getImportDataOutput();
    this.importData.emit(importDataOutput);
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    if (tabEntry.rootBounds?.bottom) {
      this.showStickyDivider = tabEntry.boundingClientRect.bottom > tabEntry.rootBounds.bottom;
    } else this.showStickyDivider = false;
  }

  handleSelectSheet(sheetName: string) {
    try {
      this.state.handleSelectSheet(sheetName);
    } catch (e) {
      if (e instanceof MappintToValueTypeError) {
        this.notificationService.showError(
          'Excel file include not supported Excel cell type',
          'Please, contact to the system administarator if you need to import uploaded Excel file.'
        );
        console.error(e);
      } else {
        throw e;
      }
    }
  }

  handleClose() {
    this.onClose.emit();
  }
}
