import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AssetFormDialogStateService } from './asset-form-dialog-state.service';
import { AssetTableComponent } from '../shared/asset-table/asset-table.component';
import { CreateAssetCommandParams } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-asset-form-dialog',
  templateUrl: './asset-form-dialog.component.html',
  styleUrls: ['./asset-form-dialog.component.scss'],
  providers: [AssetFormDialogStateService],
})
export class AssetFormDialogComponent {
  @Output() onCancel = new EventEmitter();

  @ViewChild(AssetTableComponent)
  private assetComponent?: AssetTableComponent;

  get isFormDisabled() {
    return !this.assetComponent?.formgroup?.valid;
  }

  constructor(public state: AssetFormDialogStateService) {}

  handleCancel() {
    this.onCancel.emit();
  }

  handleOnSubmitClick() {
    if (this.assetComponent) {
      this.assetComponent.handleOnFormSubmit();
    }
  }

  async handleOnFormSubmit(params: CreateAssetCommandParams) {
    await this.state.submitAssetForm(params);
    this.handleCancel();
  }
}
