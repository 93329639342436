<div
  esg-on-click-outside
  (onClickOutside)="collapse()"
  class="container"
  [class.disabled]="disabled"
  [class.popup]="isPopup">
  <div #input class="select-wrapper">
    <esg-select-field
      [value]="selectedValue?.label"
      variant="ghost-table"
      size="large"
      [optional]="!required"
      [placeholder]="placeholder"
      [longestLabel]="longestLabel"
      [isCollapsed]="isCollapsed"
      [disabled]="disabled"
      (onToggleCollapse)="toggleCollapse()"></esg-select-field>
    <esg-select-options
      *ngIf="!isCollapsed || focused"
      class="options"
      [selectedValues]="selectedValueAsArray"
      [options]="options"
      [ngStyle]="popUpStyle"
      size="large"
      [groupedOptions]="groupedOptions"
      [favouriteOptions]="favouriteOptions"
      [dropUp]="dropUp"
      [maxHeight]="maxHeight"
      (onChange)="handleChange($event)"></esg-select-options>
  </div>
</div>
