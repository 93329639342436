import { Component } from '@angular/core';
import { LeasedAssetsStateService } from './leased-assets-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-leased-assets',
  templateUrl: './leased-assets.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: LeasedAssetsComponent }, LeasedAssetsStateService],
})
export class LeasedAssetsComponent extends RecordsComponent {
  constructor(public state: LeasedAssetsStateService) {
    super(state);
  }
}
