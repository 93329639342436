import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input({ required: true }) text!: string;
  @Input() variant: 'dark' | 'light' = 'dark';
  @Input() position: 'top' | 'right' | 'bottom' | 'left' = 'top';
  @Input() underline: boolean = true;
  @Input() alwaysVisible: boolean = false;
}
