import { Component } from '@angular/core';
import { MarineCasualtyStateService } from './marine-casualty-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-marine-casualties',
  templateUrl: './marine-casualty.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: MarineCasualtyComponent }, MarineCasualtyStateService],
})
export class MarineCasualtyComponent extends RecordsComponent {
  constructor(public state: MarineCasualtyStateService) {
    super(state);
  }
}
