import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportPreviewStateService } from '../../report-preview-state.service';
import { MainTopicFeedStateService } from './main-topic-feed-state.service';

@Component({
  selector: 'esg-main-topic-feed',
  templateUrl: './main-topic-feed.component.html',
  styleUrls: ['./main-topic-feed.component.scss'],
})
export class MainTopicFeedComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public reportState: ReportPreviewStateService,
    public state: MainTopicFeedStateService
  ) {}

  ngOnInit(): void {
    this.reportState.mainLevelsObservable$.subscribe(result => {
      if (result) {
        this.route.params.subscribe(params => {
          const mainLevelId = params['mainLevelId'];
          const topicId = params['topicId'];
          if (mainLevelId !== this.state.mainTopicPreviewUiState.id) {
            const mainLevel = this.reportState.mainLevels?.find(item => item.id === mainLevelId);
            if (mainLevel)
              this.state.initMainlevel(this.reportState.reportId || '', mainLevel, this.reportState.timePeriod);
          }
          if (topicId !== this.state.activeTopicId) {
            this.state.activeTopicId = topicId;
            this.state.setTopicItemTabs(topicId);
          }
        });

        this.route.fragment.subscribe(f => {
          this.state.activeTopicItemId = f || '';
        });
      }
    });
  }
}
