import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-arrow-right-long',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8485 11.5975L16.0489 6.19626C15.9415 6.07893 15.7922 6.00868 15.6333 6.00075C15.4745 5.99283 15.3189 6.04787 15.2004 6.15393C15.0877 6.26405 15.0208 6.41265 15.013 6.57002C15.0051 6.72738 15.0569 6.88191 15.1581 7.00269L19.0685 11.4003H3.59995C3.44083 11.4003 3.28823 11.4635 3.17572 11.5761C3.06321 11.6886 3 11.8413 3 12.0004C3 12.1596 3.06321 12.3122 3.17572 12.4248C3.28823 12.5373 3.44083 12.6006 3.59995 12.6006H19.0695L15.1592 16.9981C14.9481 17.2312 14.9754 17.6283 15.2015 17.8469C15.4372 18.0746 15.8389 18.0382 16.05 17.8046L20.8496 12.4034C21.0638 12.107 21.036 11.8557 20.8496 11.5996L20.8485 11.5975Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [],
})
export class ArrowRightLongComponent {
  @Input() size: string = '24';
  @Input() color: string = 'black';
}
