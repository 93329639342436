import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateIndicatorDataCommandParams,
  RecordDuration,
  TimePeriodType,
  UpdateIndicatorDataCommandParams,
} from 'src/api-client/report-api.generated';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { IGroupedOptions, IOption, globalMonthShortOptions, globalQuarterOptions } from 'src/app/static-data/options';
import { IndicatorRecordData } from './indicator-data-state.service';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { GenericRowUi } from 'src/app/shared/table/generic-table/generic-table.types';
import { formatDate } from '@angular/common';

export interface IndicatorRecordForm {
  startDate: FormControl<Date>;
  endDate: FormControl<Date>;
  timePeriod: FormControl<ITimePeriodData>;
  asset: FormControl<IOption | undefined>;
  tableValue: FormControl<GenericRowUi[]>;
  description: FormControl<string>;
  singleValue: FormControl<string>;
}

@Component({ template: '' })
export abstract class IndicatorRecordsComponent {
  @Input({ required: true }) indicatorId!: string;
  @Input({ required: true }) indicatorRecordDuration!: RecordDuration;
  @Input() indicatorRecords: IndicatorRecordData[] = [];
  @Input() newlyAddedRowsIds: string[] = [];
  @Input() assetOptions: IGroupedOptions[] = [];
  @Input() isAddEnabled: boolean = true;
  @Input() stickyHeaderTopOffset: number = 55;
  @Input() intersectionObserverRootMargin: number = 136;
  @Input() stickyHeaderPadding: number = 100;

  @Output() onCreate = new EventEmitter<CreateIndicatorDataCommandParams>();
  @Output() onUpdate = new EventEmitter<UpdateIndicatorDataCommandParams>();
  @Output() onDelete = new EventEmitter<string>();

  readonly recordDurationEnum = RecordDuration;

  recordFc?: IndicatorRecordForm = undefined;
  recordFormgroup?: FormGroup<IndicatorRecordForm> = undefined;
  editRecordId?: string;

  startDateOpen = false;
  endDateOpen = false;
  hasSubmitted: boolean = false;

  defaultTimeperiod = {
    type: TimePeriodType.Annual,
    year: new Date().getFullYear(),
    period: 1,
  };

  get isAssetsDisabled() {
    return this.assetOptions.flatMap(group => group.options).length < 2;
  }

  get showPeriodSelector() {
    return this.indicatorRecordDuration === this.recordDurationEnum.Period;
  }

  get periodDescription() {
    switch (this.indicatorRecordDuration) {
      case RecordDuration.DateFromTo:
        return 'From - To';
      case RecordDuration.SingleDate:
        return 'Date';
      case RecordDuration.Period:
        return 'Interval';
      default:
        return '';
    }
  }

  handleOnSubmit() {}

  handleOnDelete(id: string) {
    this.onDelete.emit(id);
  }

  handleCloseForm() {
    if (!this.startDateOpen && !this.endDateOpen) {
      if (this.editRecordId) {
        if (this.recordFormgroup?.dirty) this.handleOnSubmit();
        this.editRecordId = undefined;
      }
      this.resetForm();
    }
  }

  getDurationLabel(timePeriod: ITimePeriodData) {
    switch (timePeriod.type) {
      case TimePeriodType.Annual: {
        return timePeriod?.year ? (timePeriod?.year).toString() : '';
      }
      case TimePeriodType.Quarterly: {
        if (!timePeriod?.period || !timePeriod?.year) return '';
        const year = timePeriod.year.toString();
        const quarter =
          globalQuarterOptions.find(option => option.value === timePeriod?.period?.toString())?.label || '';
        return quarter + ' ' + year;
      }
      case TimePeriodType.Monthly: {
        if (!timePeriod?.period || !timePeriod?.year) return '';
        const year = timePeriod.year.toString();
        const month =
          globalMonthShortOptions.find(option => option.value === timePeriod?.period?.toString())?.label || '';
        return month + ' ' + year;
      }
      case TimePeriodType.Custom: {
        const startDate = timePeriod?.customStart;

        if (this.indicatorRecordDuration === this.recordDurationEnum.SingleDate && startDate) {
          return formatDate(startDate, 'dd. MMM yyyy', 'en_US');
        }
        const endDate = timePeriod?.customEnd;
        if (!startDate || !endDate) {
          return '';
        }
        return formatDate(startDate, 'dd. MMM yyyy', 'en_US') + ' - ' + formatDate(endDate, 'dd. MMM yyyy', 'en_US');
      }
      default:
        return '';
    }
  }

  getDefaultAssetOption(): IOption | undefined {
    for (const group of this.assetOptions) {
      if (group.options.length > 0) {
        return group.options[0];
      }
    }
    return undefined;
  }

  onStartDateClose() {
    this.startDateOpen = false;
    const endDateControl = this.recordFormgroup?.controls['endDate'];
    const startDateControl = this.recordFormgroup?.controls['startDate'];
    if (!this.editRecordId && startDateControl && endDateControl && !endDateControl.touched) {
      endDateControl.setValue(startDateControl.value);
      if (endDateControl.hasValidator(Validators.required)) this.endDateOpen = true;
    }
  }

  handleDropdownChange(formControl: FormControl<IOption | undefined>, option: IOption) {
    if (option !== formControl.value) {
      formControl.setValue(option);
      formControl.markAsDirty();
    }
  }

  userContextMenuProvider = (id: string, connected: boolean = false): IMenuItem[] => [
    {
      id: 'history',
      label: 'History',
      onClick: () => {},
      disabled: true,
    },
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => {
        this.handleOnDelete(id);
      },
      disabled: connected,
    },
  ];

  resetForm() {
    this.startDateOpen = false;
    this.endDateOpen = false;

    this.recordFc = undefined;
    this.recordFormgroup = undefined;

    this.hasSubmitted = false;
  }
}
