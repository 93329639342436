import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Permission } from 'src/api-client/report-api.generated';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { getInitials } from 'src/app/shared/utils/user-utils';
import { IOption } from 'src/app/static-data/options';

// Currently not used

@Component({
  selector: 'esg-large-navbar',
  templateUrl: './large-navbar.component.html',
  styleUrls: ['./large-navbar.component.scss'],
})
export class LargeNavbarComponent {
  @Output() onLogout = new EventEmitter();

  userContextOpen = false;
  organizationDropdownCollapsed = true;
  assetFormOpen = false;
  recordFormOpen = false;
  reportFormOpen = false;

  get getInitials() {
    return getInitials(this.appInfoState.userInfo.value?.name || '');
  }

  constructor(
    private router: Router,
    public appInfoState: AppInfoService,
    public settingsService: AppConfigurationService
  ) {}

  handleOpenContextMenu(event: Event) {
    event.stopPropagation();
    this.userContextOpen = !this.userContextOpen;
  }

  handleOpenOrganizationDropdown(event: Event) {
    event.stopPropagation();
    this.organizationDropdownCollapsed = !this.organizationDropdownCollapsed;
  }

  handleToggleOrganizationDropdown(collapse: boolean) {
    this.organizationDropdownCollapsed = collapse;
  }

  handleOpenAssetForm() {
    this.assetFormOpen = true;
  }

  handleCloseAssetForm() {
    this.assetFormOpen = false;
  }

  handleOpenRecordForm() {
    this.recordFormOpen = true;
  }

  handleCloseRecordForm() {
    this.recordFormOpen = false;
  }

  handleOpenReportForm() {
    this.reportFormOpen = true;
  }

  handleCloseReportForm() {
    this.reportFormOpen = false;
  }

  handleSetOrganizationContext(organizationOption: IOption) {
    this.appInfoState.setOrganizationContext(organizationOption.value);
    this.router.navigate(['/home']);
  }

  userContextMenuProvider = (): IMenuItem[] => {
    const menuItems: IMenuItem[] = [];

    if (this.appInfoState.hasPermission(Permission.Admin_Panel)) {
      menuItems.push({
        id: '1',
        label: 'Settings',
        onClick: () => {
          this.router.navigate(['/admin']);
          this.userContextOpen = false;
        },
      });
    }
    menuItems.push({
      id: '2',
      label: 'Log out',
      onClick: () => {
        this.handleOnLogout();
        this.userContextOpen = false;
      },
    });
    return menuItems;
  };
  handleOnLogout() {
    this.onLogout.emit();
  }
}
