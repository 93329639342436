import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  UserClient,
  CreateUserCommandParams,
  CreateUserCommandResponse,
  UpdateUserCommandParams,
  UpdateUserCommandResponse,
  DeleteUserCommandParams,
  DeleteUserCommandResponse,
  GetUserByIdQueryResponse,
  UpdateUserProfileCommandResponse,
  UpdateUserProfileCommandParams,
  GetOrganizationUsersQueryResponse,
  GetUsersFilteredByQueryResponse,
  UpdateUserOnboardedCommandResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  client: UserClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new UserClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  createUser(data: CreateUserCommandParams): Observable<CreateUserCommandResponse> {
    return this.client.createUser(data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  updateUser(data: UpdateUserCommandParams): Observable<UpdateUserCommandResponse> {
    return this.client.updateUser(data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  async updateUserProfile(data: UpdateUserProfileCommandParams): Promise<UpdateUserProfileCommandResponse> {
    const observable$ = this.client.updateUserProfile(data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  deleteUser(data: DeleteUserCommandParams): Observable<DeleteUserCommandResponse> {
    return this.client.deleteUser(data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  async getUserDetailsById(id: string): Promise<GetUserByIdQueryResponse> {
    const observable$ = this.client.getUserById(id).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }

  getOrganizationById(id: string): Observable<GetUserByIdQueryResponse> {
    return this.client.getUserById(id).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  getUsersByOrganizationId(id: string): Observable<GetOrganizationUsersQueryResponse> {
    return this.client.getOrganizationUsers(id).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  getUsersFilteredBy(organizationId?: string): Observable<GetUsersFilteredByQueryResponse> {
    return this.client.getUsersFilteredBy(organizationId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  async setOnboardedUser(): Promise<UpdateUserOnboardedCommandResponse> {
    const observable$ = this.client.setOnboardedUser().pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }
}
