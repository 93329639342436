<esg-dialog class="full-screen" (onClose)="onClose.emit()" [showCloseBtn]="false">
  <div class="container">
    <button class="back-button" (click)="onClose.emit()">
      <esg-chevron></esg-chevron>
      Back
    </button>

    <div class="content">
      <h1 class="title">{{ indicator.name }}</h1>
      <span class="subtitle">All records</span>
      <ng-container *ngIf="!state.loading">
        <esg-filter-pagination-bar
          class="filter"
          [paginationData]="state.currentPagination"
          [pageSizeOptions]="state.pageSizeOptions"
          [menuItems]="state.filterMenuProvider()"
          (onPaginationChange)="state.handlePaginationChange($event)">
          <div class="params">
            <esg-datepicker
              [fc]="state.startDateFilter"
              [isFilter]="true"
              size="small"
              placeholder="Date From"
              [maxDate]="state.endDateFilter.value || undefined"
              (onChange)="state.handleFilterChange()"></esg-datepicker>
            <esg-datepicker
              [fc]="state.endDateFilter"
              [isFilter]="true"
              size="small"
              placeholder="Date To"
              [minDate]="state.startDateFilter.value || undefined"
              (onChange)="state.handleFilterChange()"></esg-datepicker>
            <esg-dropdown-multiple-form
              *ngIf="state.assetTypeOptions.length > 1"
              [fc]="state.filteredAssetTypes"
              size="small"
              [options]="state.assetTypeOptions"
              [isFilter]="true"
              placeholder="All asset types"
              (onChange)="state.handleAssetTypeFilterChange()"></esg-dropdown-multiple-form>
            <esg-dropdown-multiple-form
              [fc]="state.filteredAssets"
              size="small"
              [groupedOptions]="state.assetFilterOptions"
              [isFilter]="true"
              placeholder="All assets"
              (onChange)="state.handleFilterChange()"></esg-dropdown-multiple-form>
          </div>
        </esg-filter-pagination-bar>
        <esg-indicator-record-form-table
          *ngIf="indicatorValueType === state.valueTypeEnum.Numeric"
          [indicatorId]="indicator.id"
          [indicatorRecordDuration]="indicatorRecordDuration"
          [indicatorUnit]="indicatorUnit"
          [indicatorRecords]="state.indicatorRecords"
          [assetOptions]="state.assetOptions"
          [isAddEnabled]="false"
          [stickyHeaderTopOffset]="-68"
          [intersectionObserverRootMargin]="1"
          [stickyHeaderPadding]="260"
          (onUpdate)="state.handleUpdateRecord($event)"
          (onDelete)="state.handleDeleteRecord($event)"></esg-indicator-record-form-table>

        <esg-indicator-table-records
          *ngIf="indicatorValueType === state.valueTypeEnum.Table && indicatorTableDefinition"
          [indicatorId]="indicator.id"
          [indicatorRecordDuration]="indicatorRecordDuration"
          [tableDefinition]="indicatorTableDefinition"
          [indicatorRecords]="state.indicatorRecords"
          [assetOptions]="state.assetOptions"
          [currencyOptions]="state.currencyOptions"
          [unitOptions]="state.unitOptions"
          [isAddEnabled]="false"
          [stickyHeaderTopOffset]="-68"
          [intersectionObserverRootMargin]="1"
          [stickyHeaderPadding]="260"
          (onUpdate)="state.handleUpdateRecord($event)"
          (onDelete)="state.handleDeleteRecord($event)"></esg-indicator-table-records>
      </ng-container>
      <esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading records"></esg-dot-flashing>
    </div>
  </div>
</esg-dialog>
