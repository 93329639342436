<div *ngIf="!useNewMainNavigation" class="no-category">
  <img
    class="no-category-image"
    src="/assets/images/records-and-assets/select-assets.svg"
    width="690"
    height="240"
    alt="Arrow pointed towards menu, timber loading truck, and factory" />
  <span class="no-category-text">{{ noCategoryText }}</span>
</div>

<div *ngIf="useNewMainNavigation" class="select-category">
  <h2>Assets</h2>

  <div class="category-links">
    <a routerLink="vessels">Vessels</a>
    <a routerLink="vehicles-crafts-machinery">Vehicles, Crafts and Machinery</a>
    <a routerLink="facilities">Facilities</a>
    <a routerLink="organizations">Organizations</a>
    <a routerLink="suppliers">Suppliers</a>
    <a routerLink="clients">Clients</a>
  </div>
</div>
