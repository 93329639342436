import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';

export interface SubOrganizationForm {
  name: FormControl<string>;
  country: FormControl<IOption | undefined>;
  orgID: FormControl<string>;
}

@Component({
  selector: 'esg-sub-organization-form',
  templateUrl: './sub-organization-form.component.html',
  styleUrls: ['./sub-organization-form.component.scss'],
})
export class SubOrganizationFormComponent implements OnInit {
  @Input({ required: true }) subOrgForm!: SubOrganizationForm;
  @Input() countryOptions: IOption[] = [];
  @Input() indent: number = 0;
  @Input() expanded: boolean = true;
  @Input() showErrors: boolean = false;
  @Output() onSubmit = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleSubmit() {
    this.onSubmit.emit();
  }

  handleCancel() {
    this.onCancel.emit();
  }

  handleCountryChange(option: IOption) {
    if (option !== this.subOrgForm.country.value) {
      this.subOrgForm.country.setValue(option);
      this.subOrgForm.country.markAsDirty();
    }
  }
}
