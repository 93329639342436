import { Component } from '@angular/core';
import { IndicatorsLibraryStateService } from './admin-indicator-library-state.service';
import {
  IndicatorCategory,
  IndicatorNumericValueDefinition,
  IndicatorTableValueDefinition,
  IndicatorValueType,
  RecordDuration,
  SectorType,
} from 'src/api-client/report-api.generated';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';

export interface IndicatorDefinitionUi {
  id: string;
  name: string;
  category: IndicatorCategory;
  valueType: IndicatorValueType;
  description: string;
  numericValueDefinition: IndicatorNumericValueDefinition | undefined;
  tableValueDefinition: IndicatorTableValueDefinition | undefined;
  frameworkStandards: string;
  sectors: SectorType[];
  rawDataSourceIds: string[];
  indicatorDefinitionDataSourceIds: string[];
  organizationAccessIds?: string[];
  isIndicatorFromSystem: boolean;
  recordDuration: RecordDuration;
  isUsed?: boolean;
  accessibility?: string;
  createdBy?: string;
}

@Component({
  selector: 'esg-admin-indicator-library',
  templateUrl: './admin-indicator-library.component.html',
  styleUrl: './admin-indicator-library.component.scss',
  providers: [IndicatorsLibraryStateService],
})
export class AdminIndicatorLibraryComponent {
  get isIndicatorDetailsOpen(): boolean {
    return this.state.selectedIndicatorId !== undefined;
  }

  constructor(public state: IndicatorsLibraryStateService) {}

  handleIndicatorSingleClick(indicatorId: string) {
    this.state.selectIndicator(indicatorId);
  }

  handleIndicatorDoubleClick(indicatorId: string) {
    this.state.editIndicator(indicatorId);
  }

  handleCreateIndicatorClick(event: Event) {
    event.stopPropagation();
    this.state.createIndicator();
  }

  handleSubmitCreate() {
    this.state.createIndicatorSubmit();
  }

  handleCreateIndicatorCancel() {
    this.state.cancelCreate();
  }

  handleUpdateIndicatorSubmit() {
    this.state.updateIndicatorSubmit();
  }

  handleEditIndicatorCancel() {
    this.state.cancelEdit();
  }

  handleInitiateDelete() {
    this.state.initiateDelete();
  }

  handleDeleteSubmit() {
    this.state.deleteIndicatorSubmit();
  }

  handleCancelDelete() {
    this.state.cancelDelete();
  }

  detailsMenuProvider = (): IMenuItem[] => [
    {
      id: 'delete',
      label: 'Delete',
      onClick: () => this.state.initiateDelete(),
    },
  ];
}
