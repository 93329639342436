import { UiModule } from 'src/app/shared/ui/ui.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableInputComponent } from './table-input/table-input.component';
import { TableDropdownComponent } from './table-dropdown/table-dropdown.component';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../ui/icons/icon.module';
import { TableTextComponent } from './table-text/table-text.component';
import { TableButtonComponent } from './table-button/table-button.component';
import { TableCancelButtonComponent } from './table-cancel-button/table-cancel-button.component';
import { TableHeaderTextComponent } from './table-header-text/table-header-text.component';
import { TableDeleteButtonComponent } from './table-delete-button/table-delete-button.component';
import { TableSelectComponent } from './table-select/table-select.component';
import { KeyboardShortcutsDirective } from '../directives/keyboard-shortcuts.directive';
import { GenericTableComponent } from './generic-table/generic-table.component';

@NgModule({
  declarations: [
    TableInputComponent,
    TableDropdownComponent,
    TableTextComponent,
    TableButtonComponent,
    TableCancelButtonComponent,
    TableDeleteButtonComponent,
    TableHeaderTextComponent,
    TableSelectComponent,
    GenericTableComponent,
  ],
  imports: [CommonModule, BrowserModule, ReactiveFormsModule, IconModule, UiModule, KeyboardShortcutsDirective],
  exports: [
    TableInputComponent,
    TableDropdownComponent,
    TableTextComponent,
    TableButtonComponent,
    TableCancelButtonComponent,
    TableDeleteButtonComponent,
    TableHeaderTextComponent,
    TableSelectComponent,
    GenericTableComponent,
  ],
})
export class TableModule {}
