import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esg-asset-version-form',
  templateUrl: './asset-version-form.component.html',
  styleUrls: ['./asset-version-form.component.scss'],
})
export class AssetVersionFormComponent {
  @Input() active: boolean = false;
  @Input() saveDisabled: boolean = false;
  @Input() deleteDisabled: boolean = true;
  @Output() onClick = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  handleOnClick() {
    this.onClick.emit();
  }

  handleOnOutsideClick() {
    if (this.active) {
      this.onCancel.emit();
    }
  }

  handleOnCancel(event: Event) {
    event.stopPropagation();
    this.onCancel.emit();
  }

  handleOnSave(event: Event) {
    event.stopPropagation();
    this.onSave.emit();
  }

  handleOnDelete(event: Event) {
    event.stopPropagation();
    this.onDelete.emit();
  }
}
