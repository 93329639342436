import { Component, EventEmitter, Output } from '@angular/core';
import { CarouselSlideUi } from '../../ui/carousel-dialog/carousel-dialog.component';

@Component({
  selector: 'esg-onboarding-dialog',
  templateUrl: './onboarding-dialog.component.html',
})
export class OnboardingDialogComponent {
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  slides: CarouselSlideUi[] = [
    {
      header: 'The tools you need to crush your goals',
      videoSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/1-General-master-720.mp4',
      content:
        "To really nail ESG reporting, start adding your organizations’ resources in <strong>Assets</strong> and <strong>Records</strong>. Throw in some <strong>Indicators</strong> and <strong>KPIs</strong>, and bam! You've got a performance review to flaunt in a <strong>Report</strong>. We'll handle the behind-the-scenes magic, leaving you free to chase your goals. Oh, and because ESG is always evolving, you can tweak everything to fit your needs.",
    },
    {
      header: "Assets are your company's goods",
      videoSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/2-Asset-master-720.mp4',
      content:
        "<strong>Assets</strong> are <strong>goods that your company holds,</strong> whether they're tangible or more on the metaphysical side. Keep tabs on facilities, vehicles, vessels, machinery, suppliers, clients and more.</br></br> Add your assets in <strong>Assets</strong> from the tab menu.",
    },
    {
      header: 'Think of Records as a journal for resources',
      videoSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/3-Records-master-720.mp4',
      content:
        'A <strong>Record</strong> holds info about a <strong>resource that your organization uses</strong> — for example electricity, fuel or waste. But it is not entirerly about environmental emissions, you can also add records about social and business ethics.</br></br> Add your records in <strong>Data Records</strong> from the tab menu.',
    },
    {
      header: 'Report on whatever floats your boat',
      videoSrc: 'https://esgreportdevblobstorage.blob.core.windows.net/videos/4-Report-master-720.mp4',
      content:
        '<strong>Reports</strong> are super flexible, wether you want a full ESG report, a rundown on the main topics, or maybe just spotlighting one <strong>KPI</strong> for that monthly report. We’ve also got your back for spicing up content with <strong>ChatGPT!</strong></br></br> Find and manage <strong>Reports</strong> from the top menu.',
    },
  ];
}
