import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-warning-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M3.19323 14C2.84176 14 2.53992 13.9242 2.28769 13.7726C2.03546 13.6211 1.84113 13.4147 1.70468 13.1537C1.56823 12.8968 1.5 12.6084 1.5 12.2884C1.5 11.9811 1.58063 11.6863 1.74189 11.4042L6.55487 2.86526C6.71199 2.57895 6.9208 2.36421 7.1813 2.22105C7.44179 2.07368 7.71469 2 8 2C8.28531 2 8.55614 2.07368 8.8125 2.22105C9.073 2.36421 9.28387 2.57895 9.44513 2.86526L14.2581 11.4042C14.3367 11.5432 14.3966 11.6884 14.438 11.84C14.4793 11.9916 14.5 12.1411 14.5 12.2884C14.5 12.6084 14.4318 12.8968 14.2953 13.1537C14.1589 13.4147 13.9645 13.6211 13.7123 13.7726C13.4601 13.9242 13.1582 14 12.8068 14H3.19323ZM3.19943 13.0084H12.7944C13.0094 13.0084 13.1789 12.9347 13.303 12.7874C13.4311 12.6442 13.4952 12.4758 13.4952 12.2821C13.4952 12.1474 13.4621 12.0147 13.396 11.8842L8.58922 3.34526C8.52306 3.22737 8.43623 3.14316 8.32872 3.09263C8.22535 3.03789 8.11578 3.01053 8 3.01053C7.88422 3.01053 7.77258 3.03789 7.66508 3.09263C7.55757 3.14316 7.47074 3.22737 7.40458 3.34526L2.59781 11.8779C2.56473 11.9453 2.53992 12.0147 2.52338 12.0863C2.51097 12.1579 2.50477 12.2232 2.50477 12.2821C2.50477 12.4758 2.56679 12.6442 2.69084 12.7874C2.81489 12.9347 2.98441 13.0084 3.19943 13.0084ZM8 9.73684C7.68162 9.73684 7.51829 9.57053 7.51002 9.23789L7.42939 5.89684C7.42525 5.73684 7.47487 5.60421 7.57824 5.49895C7.68575 5.39368 7.82427 5.34105 7.9938 5.34105C8.15919 5.34105 8.29564 5.39579 8.40315 5.50526C8.51479 5.61053 8.56854 5.74316 8.56441 5.90316L8.47137 9.23789C8.46724 9.57053 8.31011 9.73684 8 9.73684ZM8 11.7895C7.81807 11.7895 7.66094 11.7263 7.52863 11.6C7.39631 11.4695 7.33015 11.3137 7.33015 11.1326C7.33015 10.9516 7.39631 10.7979 7.52863 10.6716C7.66094 10.5411 7.81807 10.4758 8 10.4758C8.18193 10.4758 8.33906 10.5389 8.47137 10.6653C8.60369 10.7916 8.66985 10.9474 8.66985 11.1326C8.66985 11.3179 8.60162 11.4737 8.46517 11.6C8.33286 11.7263 8.1778 11.7895 8 11.7895Z"
        fill="#183642" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  },
})
export class WarningIconComponent {
  @Input() size: number = 16;
}
