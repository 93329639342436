import { Component } from '@angular/core';

@Component({
  selector: 'esg-records-menu',
  templateUrl: './records-menu.component.html',
  styleUrls: ['./records-menu.component.scss'],
})
export class RecordsMenuComponent {
  constructor() {}

  ngOnInit(): void {}
}
