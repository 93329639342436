import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';
import { RecordsComponent } from '../records.component';
import { Component } from '@angular/core';

@Component({ selector: 'esg-business-travel', template: '' })
export abstract class BusinessTravelComponent extends RecordsComponent {
  tabs: ITabItem[] = [
    { value: 'air-travel', label: 'Air Travel', link: ['../', 'air-travel'] },
    { value: 'hotel-nights', label: 'Hotel Nights', link: ['../', 'hotel-nights'] },
  ];
}
