import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainLevel } from 'src/app/report-preview/report-preview-state.service';

@Component({
  selector: 'esg-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent implements OnInit {
  @Input() mainLevels?: MainLevel[] = [];
  @Output() onClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleOnClose() {
    this.onClose.emit();
  }
}
