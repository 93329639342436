<h2>Users</h2>
<esg-add-button
  label="Create New User"
  variant="filled"
  size="standard"
  [disabled]="!state.hasAddPermission"
  (onClick)="handleAddUser()"></esg-add-button>
<esg-user-list class="list" (onUserClick)="handleSelectUser($event)"></esg-user-list>
<esg-user-form
  *ngIf="showUserForm"
  [selectedUser]="state.selectedUser"
  [organizationOptions]="state.organizationOptions"
  [isSuperAdmin]="state.isSuperAdmin"
  (onCancel)="handleDialogClose()"
  (onAddSubmit)="handleSubmitAddUser($event)"
  (onEditSubmit)="handleSubmitEditUser($event)"></esg-user-form>
<esg-details-dialog [open]="showUserDetails" [options]="userMenuProvider()" (onClose)="handleDetailsDialogClose()">
  <esg-user-details *ngIf="state.selectedUser" [user]="state.selectedUser"></esg-user-details>
</esg-details-dialog>
<esg-confirm-dialog
  *ngIf="showRemoveUser && state.selectedUser"
  [message]="removeUserMessage"
  (onCancel)="handleDialogClose()"
  (onSubmit)="handleDeleteUserSubmit(state.selectedUser.id)"></esg-confirm-dialog>
