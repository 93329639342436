import { Injectable } from '@angular/core';
import {
  DataRecordType,
  CreateEmployeeSickLeavesDataRecordCommandParams,
  ICreateEmployeeSickLeavesDataRecordCommandParams,
  DataRecordCategory,
  AssetType,
  InputType,
} from 'src/api-client/report-api.generated';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import { IOption } from 'src/app/static-data/options';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegionClientApiService } from 'src/app/api-client/report-api/region-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  ExcelImportValidationMessage,
  FieldConfig,
  ImportDataOutput,
  validateExcelOptionColumn,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { RecordsStateService } from '../records-state.service';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class SickLeaveStateService extends RecordsStateService {
  title = 'Sick leave';
  recordCategory = DataRecordCategory.EmployeesSickLeave;
  assetCategories = [AssetType.Organization, AssetType.Facility];

  regionOptions: IOption[] = [];

  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'region', title: 'Region', type: 'string' },
    { propertyName: 'women', title: 'Women', type: 'number' },
    { propertyName: 'men', title: 'Men', type: 'number' },
    { propertyName: 'workingDays', title: 'Working days', type: 'number' },
  ];

  importFromExcelEnabled = true;

  constructor(
    appInfo: AppInfoService,
    appConfig: AppConfigurationService,
    assetApiService: AssetApiService,
    dataRecordService: DataRecordApiService,
    dataCategoryApiService: DataCategoryApiService,
    notificationService: NotificationService,
    router: Router,
    route: ActivatedRoute,
    private regionApiService: RegionClientApiService
  ) {
    super(
      appInfo,
      appConfig,
      assetApiService,
      dataRecordService,
      dataCategoryApiService,
      notificationService,
      router,
      route
    );
  }

  importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    let validationMessages: ExcelImportValidationMessage[] = [];

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) validationMessages.push(assetValidation);

    validationMessages = validateExcelOptionColumn(
      validationMessages,
      'region',
      this.regionOptions,
      data.records,
      'Region'
    );

    if (validationMessages.length) {
      validationMessages.forEach(message => {
        this.notificationService.showError(message.title, message.description);
      });
      this.importingInProgress = false;
      return;
    }

    const addRecordPromises = data.records.map((record, index) => {
      const region = this.regionOptions.find(region => region.label === record.region?.value);
      if (!region) {
        this.notificationService.showError(
          'Not valid imported data',
          `Region column is not valid for ${index + 1} row`
        );
        return;
      }

      const asset = this.getAssetOptionColumn(record.asset?.value);
      const sickLeave: ICreateEmployeeSickLeavesDataRecordCommandParams = {
        type: DataRecordType.EmployeeParentalLeaves,
        dataSetId: dataSetId,
        assetId: asset.value,
        inputType: InputType.ImportedFromExcel,
        startDate: record.startDate?.value,
        endDate: record.endDate?.value,
        regionId: region?.value,
        women: record.women?.value,
        men: record.men?.value,
        workingDays: record.workingDays?.value,
      };

      return this.dataRecordService.createDataRecord(
        new CreateEmployeeSickLeavesDataRecordCommandParams({ ...sickLeave })
      );
    });
    Promise.all(addRecordPromises).then(async response => {
      response.forEach(res => {
        if (res) {
          const addedId = res.result?.id;
          if (addedId) {
            this.newlyAddedRowsIds.push(addedId);
            this.delayRemoveAddedId(addedId, 15000);
          }
        }
      });
      await this.loadRecords();
      this.importingInProgress = false;
      this.importDataFromExcelClose();
    });
  }

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getRegions(), this.getDataCategoryDetails()]);
  }

  async fetchOptions() {
    await Promise.all([this.getAssetOptions(), this.getRegions()]);
  }

  async getRegions() {
    const response = await this.regionApiService.getAllRegionNames();
    this.regionOptions = response.result.map(region => ({ value: region.id, label: region.name }));
  }

  async loadRecords() {
    await this.getDataRecords(DataRecordType.EmployeeSickLeaves);
  }

  handleDeleteAllFilteredRecordsSubmit() {
    this.handleOnDeleteAllFilteredRecords(DataRecordType.EmployeeSickLeaves);
  }
}
