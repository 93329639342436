<table>
  <thead>
    <tr>
      <th>ORGANIZATION</th>
      <th>COUNTRY</th>
      <th>ORG ID</th>
      <th class="align-right">SUB ORGS</th>
      <th class="align-right">USERS</th>
      <th class="align-right"></th>
    </tr>
  </thead>
  <tbody
    *ngFor="let item of state.organizations"
    esg-organization-tbody
    [organization]="item"
    [countryOptions]="countryOptions"
    (onOrganizationClick)="handleOrganizationClick($event)"
    (onAddSubOrganization)="state.addSubOrganization(item.id || '', $event)"
    (onEditSubOrganization)="state.editSubOrganization(item.id || '', $event)"
    (onDeleteSubOrganization)="state.deleteSubOrganization(item.id || '', $event)"
    [active]="state.selectedOrganization?.id === item.id"></tbody>
</table>
<esg-alert-dialog
  *ngIf="state.showHasAssetsDialog"
  variant="error"
  [title]="alertTitle"
  [message]="alertMessage"
  [buttonText]="alertButtonText"
  (onClose)="state.showHasAssetsDialog = false"></esg-alert-dialog>
