import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AccessTokenTypes } from 'src/api-client/report-api.generated';
import { AccessTokenApiService } from 'src/app/api-client/report-api/access-token-service';

export interface ReportShareLink {
  id: string;
  name: string;
  token: string;
  url: string;
  addedBy: string;
  createdDateTime: Date;
}

@Injectable({
  providedIn: 'root',
})
export class ReportShareDialogStateService {
  reportId!: string;
  list: ReportShareLink[] = [];

  showCreateAccessToken = false;
  accessTokenName = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(50)],
  });

  constructor(private accessTokenApi: AccessTokenApiService) {}

  init(reportId: string) {
    this.reportId = reportId;
    this.loadShareLinks(reportId);
  }

  async loadShareLinks(reportId: string) {
    const reponse = await this.accessTokenApi.getBy(AccessTokenTypes.Report, reportId);

    this.list = reponse.result.map(i => ({
      id: i.id,
      name: i.name,
      token: i.token,
      url: `${window.location.origin}/share/${reportId}?t=${i.token}`,
      addedBy: i.addedBy,
      createdDateTime: i.createdDateTime,
    }));
  }

  async createAccessToken() {
    this.showCreateAccessToken = true;
  }

  async cancelCreateAccessToken() {
    this.showCreateAccessToken = false;
    this.accessTokenName.reset();
  }

  async saveAccessToken() {
    if (!this.accessTokenName.valid) return;
    var response = await this.accessTokenApi.create(this.accessTokenName.value, AccessTokenTypes.Report, this.reportId);
    if (response.success) {
      this.loadShareLinks(this.reportId);
    }
    this.showCreateAccessToken = false;
    this.accessTokenName.reset();
  }

  async deleteAccessToken(id: string) {
    await this.accessTokenApi.delete(id);
    this.list = this.list.filter(x => x.id !== id);
  }
}
