import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'esg-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnInit {
  @Input({ required: true }) checked!: boolean;
  @Input() disabled: boolean = false;
  @Output() onCheck = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}

  handleOnClick() {
    if (!this.disabled) this.onCheck.emit();
  }
}
