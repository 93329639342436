import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-column-viewer',
  templateUrl: './column-viewer.component.html',
  styleUrl: './column-viewer.component.scss',
})
export class ColumnViewerComponent {
  @Input({ required: true }) fc!: FormControl<IOption[]>;
  @Input() options: IOption[] = [];
  @Output() onChange = new EventEmitter();
  open = false;
  dropUp = false;
  maxHeight: string = 'unset';

  @ViewChild('button') private button: ElementRef | undefined;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMaxHeigth();
  }

  toggleOpenOptions() {
    this.open = !this.open;
    if (this.open) {
      this.setDropUp();
      this.setMaxHeigth();
    }
  }

  handleSelectOption(option: IOption) {
    if (this.fc.value.some(o => o.value == option.value)) {
      this.fc.setValue(this.fc.value.filter(o => o.value !== option.value));
    } else {
      this.fc.setValue([...this.fc.value, option]);
    }
    this.fc.markAsDirty();
    this.setDropUp();
    this.onChange.emit();
  }

  optionSelected(option: IOption) {
    return this.fc.value.some(o => o.value == option.value);
  }

  deleteOption(event: Event, option: IOption) {
    event.stopPropagation();
    this.fc.setValue(this.fc.value.filter(o => o.value !== option.value));
    this.fc.markAsDirty();
    this.onChange.emit();
  }

  closeOptions() {
    this.open = false;
  }

  setDropUp() {
    if (this.button && window.innerHeight - this.button.nativeElement.getBoundingClientRect().bottom < 200)
      this.dropUp = true;
    else this.dropUp = false;
  }

  setMaxHeigth() {
    if (this.button?.nativeElement) {
      const inputRect = this.button.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const remainingHeight = this.dropUp ? inputRect.top - 160 : windowHeight - inputRect.bottom - 100;
      this.maxHeight = `${remainingHeight}px`;
    } else {
      this.maxHeight = 'unset';
    }
  }
}
