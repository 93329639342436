<h2 class="slide-header">{{ slide.header }}</h2>
<div
  esgIntersectionObserver
  [threshold]="0"
  rootMargin="0px"
  (onIntersection)="handleOutOfViewport($event)"
  class="slide-video-container"
  (click)="handleVideoClick()">
  <video #video class="slide-video" width="620px" height="348px" loop>
    <source *ngIf="trustedVideoSrc" [src]="trustedVideoSrc" type="video/mp4" />
  </video>
  <ng-container *ngIf="trustedVideoSrc">
    <esg-dot-flashing *ngIf="!isVideoLoaded" class="loader" label=""></esg-dot-flashing>
    <ng-container *ngIf="isVideoLoaded && !isPlayingVideo">
      <esg-play-icon></esg-play-icon>
      <div class="pause-overlay"></div>
    </ng-container>
  </ng-container>
</div>
<div class="slide-content" [innerHTML]="sanitizedHtml">{{ slide.content }}</div>
