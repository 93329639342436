import { Component, Input } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { CommutingModeByTransport, CommutingTransportOptions, IOption } from 'src/app/static-data/options';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateEmployeeCommutingDataRecordCommandParams,
  DataRecordType,
  EmployeeCommutingDataRecord,
  EmployeeCommutingMode,
  EmployeeCommutingTransport,
  InputType,
  UpdateEmployeeCommutingDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';

interface EmployeeCommutingRecordForm extends RecordForm {
  transport: FormControl<IOption>;
  mode: FormControl<IOption | undefined>;
  employees: FormControl<string>;
  days: FormControl<string>;
  distance_km: FormControl<string>;
  cO2Measured_kg: FormControl<string>;
}

@Component({
  selector: 'esg-employee-commuting-form-table',
  templateUrl: './employee-commuting-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: EmployeeCommutingFormTableComponent }],
})
export class EmployeeCommutingFormTableComponent extends RecordFormTableComponent {
  @Input({ required: true }) profileId!: string;

  headerCells: RecordHeaderCell[] = [
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'transport', label: 'Transport', description: 'Type', number: false },
    { columnId: 'mode', label: 'Mode', description: 'Type', number: false },
    { columnId: 'employees', label: 'Employees', description: 'Numbers', number: true },
    { columnId: 'days', label: 'Days', description: 'Numbers', number: true },
    { columnId: 'distance', label: 'Distance', description: 'Kilometres', number: true },
    { columnId: 'measured', label: 'Measured', description: 'kgCO2-e', number: true },
  ];

  recordFc?: EmployeeCommutingRecordForm = undefined;
  recordFormgroup?: FormGroup<EmployeeCommutingRecordForm> = undefined;

  commutingTransportOptions: IOption[] = CommutingTransportOptions;
  commutingModeOptions: IOption[] = CommutingModeByTransport[EmployeeCommutingMode.Car];

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as EmployeeCommutingDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'transport',
          value: this.commutingTransportOptions.find(c => c.value === record.transport)?.label || '',
          number: false,
        },
        {
          columnId: 'mode',
          value: CommutingModeByTransport[record.transport].find(c => c.value === record.mode)?.label || 'n/a',
          number: false,
        },
        {
          columnId: 'employees',
          value: record.employees.toString(),
          number: true,
        },
        {
          columnId: 'days',
          value: record.days.toString(),
          number: true,
        },
        {
          columnId: 'distance',
          value: record.distance_km.toString(),
          number: true,
        },
        {
          columnId: 'measured',
          value: record.cO2Measured_kg?.toString() || '',
          number: true,
        },
      ],
    }));
  }

  handleOnIniateAddRecord() {
    if (this.editRecordId) this.handleCloseForm();
    this.setAddRecordForm();
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      transport: new FormControl<IOption>(this.commutingTransportOptions[0], {
        validators: [Validators.required],
        nonNullable: true,
      }),
      mode: new FormControl<IOption | undefined>(this.commutingModeOptions[0], {
        nonNullable: true,
      }),
      employees: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      days: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      distance_km: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      cO2Measured_kg: new FormControl<string>('', {
        nonNullable: true,
      }),
    };
    this.recordFormgroup = new FormGroup<EmployeeCommutingRecordForm>(this.recordFc);
  }

  setEditRecordForm(id: string) {
    this.editRecordId = id;
    const record = this.dataRecords.find(record => record.id === id) as EmployeeCommutingDataRecord;
    this.commutingModeOptions = CommutingModeByTransport[record.transport];
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        transport: new FormControl<IOption>(
          this.commutingTransportOptions.find(opt => opt.value === record.transport) ||
            this.commutingTransportOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        mode: new FormControl<IOption | undefined>(
          this.commutingModeOptions.find(opt => opt.value === record.mode) || this.commutingModeOptions[0],
          {
            nonNullable: true,
          }
        ),
        employees: new FormControl<string>(record.employees.toString(), {
          validators: [Validators.required],
          nonNullable: true,
        }),
        days: new FormControl<string>(record.days.toString(), {
          validators: [Validators.required],
          nonNullable: true,
        }),
        distance_km: new FormControl<string>(record.distance_km.toString(), {
          validators: [Validators.required],
          nonNullable: true,
        }),
        cO2Measured_kg: new FormControl<string>(record.cO2Measured_kg?.toString() || '', {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup<EmployeeCommutingRecordForm>(this.recordFc);
    }
  }

  getParams():
    | CreateEmployeeCommutingDataRecordCommandParams
    | UpdateEmployeeCommutingDataRecordCommandParams
    | undefined {
    const transport = Object.values(EmployeeCommutingTransport).find(
      type => type === this.recordFc?.transport.value.value
    );
    const mode = Object.values(EmployeeCommutingMode).find(type => type === this.recordFc?.mode.value?.value);
    if (
      this.recordFormgroup?.valid &&
      this.recordFc &&
      this.profileId &&
      this.recordFc.asset.value?.value &&
      transport
    ) {
      const commuting = {
        dataProfileId: this.profileId,
        type: DataRecordType.EmployeeCommuting,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        transport: transport,
        mode: mode,
        employees: formattedStringToNumber(this.recordFc.employees.value || '') || 0,
        days: formattedStringToNumber(this.recordFc.days.value || '') || 0,
        distance_km: formattedStringToNumber(this.recordFc.distance_km.value || '') || 0,
        cO2Measured_kg: formattedStringToNumber(this.recordFc.cO2Measured_kg.value || ''),
      };
      if (this.editRecordId) {
        return new UpdateEmployeeCommutingDataRecordCommandParams({ id: this.editRecordId, ...commuting });
      } else {
        return new CreateEmployeeCommutingDataRecordCommandParams(commuting);
      }
    }
    return undefined;
  }

  handleTransportChange(option: IOption) {
    const transport = Object.values(EmployeeCommutingTransport).find(type => type === option.value);
    if (this.recordFc && transport) {
      this.commutingModeOptions = CommutingModeByTransport[transport];
      this.handleDropdownChange(this.recordFc.transport, option);
      this.handleDropdownChange(
        this.recordFc.mode,
        this.commutingModeOptions.find(mode => mode.value === this.recordFc?.mode.value?.value) ||
          this.commutingModeOptions[0]
      );
    }
  }

  resetForm() {
    super.resetForm();
    this.commutingModeOptions = CommutingModeByTransport[EmployeeCommutingMode.Car];
  }
}
