import { Component, EventEmitter, Input, Output } from '@angular/core';
import { formattedStringToNumber, stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { IOption } from 'src/app/static-data/options';
import { IMenuItem } from '../../ui/context-menu/context-menu.component';

export interface PaginationUi {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

@Component({
  selector: 'esg-filter-pagination-bar',
  templateUrl: './filter-pagination-bar.component.html',
  styleUrls: ['./filter-pagination-bar.component.scss'],
  host: {
    '[class.no-filter]': '!showFilter',
  },
})
export class FilterPaginationBarComponent {
  @Input({ required: true }) paginationData!: PaginationUi;
  @Input() pageSizeOptions: IOption[] = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '250', label: '250' },
  ];
  @Input() showFilter: boolean = true;
  @Input() menuItems: IMenuItem[] = [];
  @Output() onPaginationChange = new EventEmitter<PaginationUi>();

  getFormattedNumber = stringToFormattedNumberString;

  setPage(page: number) {
    if (page >= 1 && page <= this.paginationData.totalPages) {
      this.onPaginationChange.emit({ ...this.paginationData, currentPage: page });
    }
  }

  handleNextPage() {
    if (this.paginationData.hasNext) this.setPage(this.paginationData.currentPage + 1);
  }

  handlePreviousPage() {
    if (this.paginationData.hasPrevious) this.setPage(this.paginationData.currentPage - 1);
  }

  setPageSize(sizeOption: IOption) {
    const size = formattedStringToNumber(sizeOption.value);
    if (size) this.onPaginationChange.emit({ ...this.paginationData, pageSize: size, currentPage: 1 });
  }

  get getDisplayedItemsRange() {
    if (this.paginationData.totalCount === 0) {
      return '0 – 0';
    }

    const start = (this.paginationData.currentPage - 1) * this.paginationData.pageSize + 1;
    const end = Math.min(start + this.paginationData.pageSize - 1, this.paginationData.totalCount);
    return (
      stringToFormattedNumberString(start.toString(), 0, 0) +
      ' – ' +
      stringToFormattedNumberString(end.toString(), 0, 0)
    );
  }

  get sizeOption() {
    return this.pageSizeOptions.find(opt => opt.value === this.paginationData.pageSize.toString());
  }
}
