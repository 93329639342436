import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-document-icon',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="12" y="6" width="1" height="8" transform="rotate(90 12 6)" attr.fill="{{ color }}" />
      <rect x="12" y="8" width="1" height="8" transform="rotate(90 12 8)" attr.fill="{{ color }}" />
      <rect x="10" y="10" width="1" height="6" transform="rotate(90 10 10)" attr.fill="{{ color }}" />
      <path
        d="M11.5 4V4.5H12L15.5 4.5V14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2C0.5 1.17157 1.17157 0.5 2 0.5H11.5V4Z"
        attr.stroke="{{ color }}" />
      <path d="M12 4L11.9971 -7.54466e-05L15.9971 3.99985L15 4L12 4Z" attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class DocumentIconComponent {
  @Input() color: string = '#183642';
}
