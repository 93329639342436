import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-organization-icon',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_948_708)">
        <path
          d="M12 7.71972C12.7361 7.71972 13.4557 7.49334 14.0677 7.06921C14.6797 6.64509 15.1568 6.04226 15.4385 5.33696C15.7202 4.63167 15.7939 3.85558 15.6503 3.10684C15.5067 2.3581 15.1522 1.67034 14.6317 1.13053C14.1112 0.590717 13.448 0.223101 12.7261 0.0741679C12.0041 -0.0747656 11.2558 0.00167234 10.5757 0.293816C9.89566 0.585959 9.3144 1.08069 8.90545 1.71544C8.49649 2.35019 8.27821 3.09645 8.27821 3.85986C8.27921 4.88324 8.67164 5.86441 9.36939 6.58805C10.0671 7.3117 11.0132 7.71869 12 7.71972Z"
          fill="#183642" />
        <path
          d="M12 17.7419C11.4033 17.7419 10.82 17.9254 10.3239 18.2692C9.8278 18.613 9.44112 19.1017 9.21279 19.6734C8.98445 20.2451 8.9247 20.8742 9.04111 21.4811C9.15751 22.0881 9.44484 22.6455 9.86676 23.0831C10.2887 23.5207 10.8262 23.8187 11.4114 23.9394C11.9967 24.0601 12.6032 23.9982 13.1545 23.7614C13.7058 23.5245 14.1769 23.1235 14.5084 22.609C14.8399 22.0945 15.0169 21.4895 15.0169 20.8707C15.016 20.0412 14.6979 19.2459 14.1323 18.6593C13.5667 18.0728 12.7999 17.7428 12 17.7419ZM12 23.5915C11.4812 23.5915 10.9741 23.4319 10.5428 23.133C10.1114 22.8341 9.77524 22.4093 9.57671 21.9122C9.37819 21.4152 9.32624 20.8682 9.42745 20.3405C9.52866 19.8128 9.77847 19.3281 10.1453 18.9477C10.5121 18.5673 10.9795 18.3082 11.4883 18.2032C11.9971 18.0983 12.5245 18.1521 13.0038 18.358C13.483 18.5639 13.8927 18.9126 14.1809 19.3599C14.4691 19.8073 14.623 20.3332 14.623 20.8712C14.6221 21.5924 14.3455 22.2838 13.8538 22.7937C13.362 23.3037 12.6954 23.5906 12 23.5915Z"
          fill="#183642" />
        <path
          d="M3.01686 23.9981C3.6136 23.9981 4.19694 23.8145 4.69309 23.4707C5.18924 23.1268 5.57591 22.6381 5.80421 22.0663C6.03251 21.4945 6.09217 20.8653 5.97565 20.2583C5.85913 19.6514 5.57166 19.0939 5.14961 18.6564C4.72755 18.2189 4.18987 17.921 3.60456 17.8004C3.01925 17.6799 2.41262 17.742 1.86139 17.9791C1.31015 18.2161 0.839078 18.6173 0.50775 19.1321C0.176422 19.6468 -0.000277613 20.2518 3.27387e-07 20.8707C0.00123995 21.7 0.31953 22.4949 0.885073 23.0812C1.45062 23.6674 2.21725 23.9972 3.01686 23.9981ZM3.01686 18.149C3.53563 18.149 4.04275 18.3085 4.47409 18.6075C4.90544 18.9064 5.24163 19.3312 5.44015 19.8283C5.63868 20.3253 5.69062 20.8723 5.58941 21.4C5.48821 21.9276 5.23839 22.4123 4.87157 22.7928C4.50474 23.1732 4.03738 23.4323 3.52857 23.5373C3.01977 23.6422 2.49238 23.5884 2.0131 23.3825C1.53382 23.1766 1.12417 22.8279 0.835958 22.3806C0.547745 21.9332 0.393911 21.4073 0.393911 20.8693C0.39515 20.1483 0.671939 19.4573 1.16361 18.9477C1.65528 18.438 2.32172 18.1514 3.01686 18.1505V18.149Z"
          fill="#183642" />
        <path
          d="M17.9663 20.8707C17.9662 21.4896 18.143 22.0945 18.4745 22.6091C18.8059 23.1237 19.2771 23.5248 19.8283 23.7617C20.3796 23.9986 20.9862 24.0606 21.5714 23.9399C22.1567 23.8193 22.6943 23.5213 23.1162 23.0838C23.5382 22.6462 23.8256 22.0887 23.942 21.4818C24.0584 20.8749 23.9987 20.2457 23.7704 19.674C23.5421 19.1023 23.1554 18.6136 22.6593 18.2698C22.1631 17.9259 21.5798 17.7424 20.9831 17.7424C20.1834 17.7433 19.4166 18.0732 18.851 18.6597C18.2855 19.2461 17.9673 20.0413 17.9663 20.8707ZM23.6066 20.8707C23.6066 21.4088 23.4527 21.9347 23.1645 22.382C22.8763 22.8294 22.4666 23.178 21.9874 23.3839C21.5081 23.5898 20.9807 23.6437 20.4719 23.5387C19.9631 23.4338 19.4957 23.1747 19.1289 22.7942C18.7621 22.4138 18.5123 21.9291 18.4111 21.4014C18.3099 20.8738 18.3618 20.3268 18.5603 19.8297C18.7588 19.3327 19.095 18.9078 19.5264 18.6089C19.9577 18.31 20.4648 18.1505 20.9836 18.1505C21.679 18.1515 22.3455 18.4384 22.8372 18.9484C23.3289 19.4583 23.6056 20.1496 23.6066 20.8707Z"
          fill="#183642" />
        <path
          d="M3.21358 14.9916C3.21358 13.9996 4.05948 13.1942 5.09929 13.1942H9.91803C10.7007 13.1942 11.393 12.8834 11.8033 12.4112L11.8033 16.9968H12.1967L12.1967 12.4112C12.6075 12.8834 13.2988 13.1942 14.082 13.1942L18.9012 13.1942C19.941 13.1942 20.7869 14.0011 20.7869 14.9916V16.9948H21.1803V14.9916C21.1803 13.7772 20.1578 12.7843 18.9012 12.7843L14.082 12.7843C13.0604 12.7843 12.1967 12.1528 12.1967 11.4086V8.09473H11.8037V11.4086C11.8037 12.1547 10.9405 12.7847 9.91849 12.7847H5.09929C3.84308 12.7847 2.8206 13.7747 2.8206 14.9916L2.8206 16.9948H3.21404L3.21358 14.9916Z"
          fill="#183642" />
      </g>
      <defs>
        <clipPath id="clip0_948_708">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width]': 'sizePx',
    '[style.height]': 'sizePx',
  },
})
export class OrganizationIconComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';

  sizePx = this.size + 'px';
}
