import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITabItem } from './tabs.component';
import { pickTextColorBasedOnBgColor } from '../../utils/colors';

@Component({
  selector: 'esg-segmented-tabs',
  templateUrl: './segmented-tabs.component.html',
  styleUrls: ['./segmented-tabs.component.scss'],
})
export class SegmentedTabsComponent implements OnInit {
  @Input() tabs: ITabItem[] = [];
  @Input() activeTab?: string;
  @Input() activeColor?: string;
  @Output() onTabClick = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  get activeFontColor() {
    return this.activeColor ? pickTextColorBasedOnBgColor(this.activeColor) : '#4ad1da';
  }

  handleOnTabClick(tabValue: string) {
    this.tabs.length > 1 && this.onTabClick.emit(tabValue);
  }

  handleOnLinkChange(tabValue: string) {
    this.activeTab = tabValue;
  }
}
