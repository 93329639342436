import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TutorialUi } from '../tutorials.component';

@Component({
  selector: 'esg-tutorial-dialog',
  templateUrl: './tutorial-dialog.component.html',
  styleUrl: './tutorial-dialog.component.scss',
})
export class TutorialDialogComponent {
  @Input() tutorial!: TutorialUi;
  @Output() onClose = new EventEmitter<void>();

  @ViewChild('video') video!: ElementRef;

  isPlayingVideo = false;
  isVideoLoaded = false;

  get trustedVideoSrc(): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.tutorial.videoSrc);
  }

  get trustedImageSrc(): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.tutorial.thumbnailSrc);
  }

  constructor(private domSanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    this.video.nativeElement.addEventListener('loadedmetadata', () => this.videoIsLoaded());
  }

  handleClose() {
    this.onClose.emit();
  }

  videoIsLoaded() {
    this.isVideoLoaded = true;
    this.video.nativeElement.play();
    this.isPlayingVideo = true;
  }

  handleVideoClick(): void {
    if (!this.isVideoLoaded) return;

    if (this.isPlayingVideo) {
      this.video.nativeElement.pause();
      this.isPlayingVideo = false;
    } else {
      this.video.nativeElement.play();
      this.isPlayingVideo = true;
    }
  }

  ngOnDestroy() {
    this.video.nativeElement.removeEventListener('loadedmetadata', () => this.videoIsLoaded());
  }
}
