<div class="header">
  <h2>{{ label }}</h2>
  <div *ngIf="!hideGuidance" class="dropdown" [class.active]="!collapsed" (click)="handleCollapse()">
    <esg-info-icon></esg-info-icon>
    <span class="divider"></span>
    <esg-arrow-down></esg-arrow-down>
  </div>
</div>
<div *ngIf="!hideGuidance" class="guidance" [class.show]="!collapsed">
  <esg-text-view-editor class="text" [content]="guidance" typography="standard"></esg-text-view-editor>
</div>
