import { FormControl } from '@angular/forms';
import {
  DataRecord,
  DataRecordType,
  UpdateDataRecordCommandResponse,
  CreateDataRecordCommandResponse,
  UpdateDataRecordCommandParams,
  CreateDataRecordCommandParams,
  AssetType,
  OrganizationAssetVm,
  DataRecordCategory,
  InputType,
  CurrencyCodes,
} from 'src/api-client/report-api.generated';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import { IOption, IGroupedOptions, RecordInputTypeOptions } from 'src/app/static-data/options';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { Injectable } from '@angular/core';
import { AssetCategoryLabel } from 'src/app/static-data/enum-mappings';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  FieldConfig,
  ImportDataOutput,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import {
  DataRecordOutput,
  ExcelImportValidationMessage,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel-state.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { PaginationUi } from 'src/app/shared/components/filter-sort-bar/filter-pagination-bar.component';
import { formatDate } from '@angular/common';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { convertToLocalDateOnlyUTC } from 'src/app/shared/utils/date';
import { RecordCategoryRoutes } from '../data-collect-page.component';

interface AssetData {
  id: string;
  name: string;
  countryCode?: string;
}

interface CategoryDetailsUi {
  name: string;
  guidance: string;
}

@Injectable()
export class RecordsStateService {
  readonly recordEnum = DataRecordType;

  title = 'Records';

  recordCategory: DataRecordCategory | undefined = undefined;
  categoryDetails: CategoryDetailsUi | undefined = undefined;

  recordCategoryRoutes = RecordCategoryRoutes;

  dataRecords: DataRecord[] = [];
  newlyAddedRowsIds: string[] = [];

  loading = false;
  initialized = false;
  isReadonly: boolean = false;

  isSubmitting: boolean = false;
  isDeleteDialogOpen: boolean = false;

  assetCategories: AssetType[] = [];
  assetTypeOptions: IOption[] = [];
  assetOptions: IGroupedOptions[] = [];
  assetFilterOptions: IGroupedOptions[] = [];
  isAssetsDisabled: boolean = false;

  organizationCurrency: CurrencyCodes = CurrencyCodes.USD;
  countryOptions: IOption[] = [];
  inputTypeOptions: IOption[] = RecordInputTypeOptions;

  assetCountryMap = new Map<string, string>();

  pageSizeOptions: IOption[] = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '250', label: '250' },
  ];

  currentPagination: PaginationUi = {
    currentPage: 1,
    totalPages: 1,
    pageSize: 50,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  };

  startDateFilter: FormControl<Date | null> = new FormControl<Date | null>(null);
  endDateFilter: FormControl<Date | null> = new FormControl<Date | null>(null);
  filteredAssets: FormControl<IOption[]> = new FormControl([], { nonNullable: true });
  filteredAssetTypes: FormControl<IOption[]> = new FormControl([], { nonNullable: true });
  filteredInputType: FormControl<IOption> = new FormControl<IOption>(this.inputTypeOptions[0], { nonNullable: true });

  importFromExcelEnabled = false;
  importFromExcelDialog = false;
  importingInProgress = false;
  importingInProgressLabel = 'Importing...';
  importFromExcelFieldConfigs: FieldConfig[] = [];

  get intersectionObserverRootMargin(): number {
    if (this.appConfig.NewNavbarEnabled) {
      return 76;
    } else return 124;
  }

  get deleteDialogMessage() {
    return `You have selected a total of ${this.currentPagination.totalCount} records to delete. Once you delete these records, \n there is no turning back.`;
  }

  get isFilterApplied(): boolean {
    return (
      this.startDateFilter.value !== null ||
      this.endDateFilter.value !== null ||
      this.filteredAssets.value.length > 0 ||
      (this.filteredAssetTypes.value.length > 0 && this.assetTypeOptions.length > 1) ||
      this.filteredInputType.value !== this.inputTypeOptions[0]
    );
  }

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(
    protected appInfo: AppInfoService,
    protected appConfig: AppConfigurationService,
    protected assetApiService: AssetApiService,
    protected dataRecordService: DataRecordApiService,
    protected dataCategoryApiService: DataCategoryApiService,
    protected notificationService: NotificationService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {}

  async init() {
    this.loading = true;
    await this.fetchStaticData();
    this.getQueryParams();
    await this.loadRecords();
    this.setQueryParams();
    this.loading = false;
    this.initialized = true;
  }

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getDataCategoryDetails()]);
  }

  async fetchOptions() {
    await this.getAssetOptions();
  }

  async initOptions() {
    this.loading = true;
    await this.fetchOptions();
    this.loading = false;
  }

  async loadRecords() {
    await this.getDataRecords();
  }

  async getOrganizationAssets() {
    const organizationCountryMap = new Map<string, string>();
    const organizationOptions: IOption[] = [];
    const organizationResponse = await this.assetApiService.getAssetsByOrganizationAndType(
      undefined,
      AssetType.Organization
    );
    organizationResponse.result.map(a => {
      let asset = a as OrganizationAssetVm;

      if (!asset.subOrganizationId) this.organizationCurrency = asset.currencyCode;

      organizationCountryMap.set(
        asset.subOrganizationId ? asset.subOrganizationId : asset.organizationId,
        asset.countryCode
      );
      organizationOptions.push({ value: asset.id, label: asset.orgOrSuborgName, group: AssetType.Organization });
      if (this.assetCategories.includes(AssetType.Organization)) this.assetCountryMap.set(asset.id, asset.countryCode);
    });

    return { organizationCountryMap, organizationOptions };
  }

  async getAssetOptions() {
    this.assetOptions = [];
    this.assetCountryMap = new Map<string, string>();
    this.assetTypeOptions = this.assetCategories.map(assetType => ({
      value: assetType,
      label: AssetCategoryLabel[assetType],
    }));
    if (this.assetTypeOptions.length === 1) this.filteredAssetTypes.setValue([this.assetTypeOptions[0]]);

    const { organizationOptions, organizationCountryMap } = await this.getOrganizationAssets();

    this.assetOptions = await Promise.all(
      this.assetCategories.map(async type => {
        if (type === AssetType.Organization) {
          return {
            label: AssetCategoryLabel[type],
            options: organizationOptions,
          };
        } else {
          return await this.getAssetOptionsByType(type, organizationCountryMap);
        }
      })
    );

    this.assetFilterOptions = this.assetOptions;
    this.isAssetsDisabled = this.assetOptions.flatMap(group => group.options).length < 2;
  }

  async getAssetOptionsByType(type: AssetType, organizationCountryMap: Map<string, string>) {
    const response = await this.assetApiService.getAssetsByOrganizationAndType(undefined, type);

    const options: IOption[] = [];
    response.result.map(a => {
      let asset = a as AssetData;
      if (asset.countryCode) this.assetCountryMap.set(asset.id, asset.countryCode);
      else {
        const organizationCountry = organizationCountryMap.get(
          a.subOrganizationId ? a.subOrganizationId : a.organizationId
        );
        if (organizationCountry) this.assetCountryMap.set(asset.id, organizationCountry);
      }
      options.push({ value: asset.id, label: asset.name, group: type });
    });
    return {
      label: AssetCategoryLabel[type],
      options: options,
    };
  }

  async getDataCategoryDetails() {
    if (this.recordCategory) {
      const { result } = await this.dataCategoryApiService.getDataCategoryByDataRecordCategory(this.recordCategory);
      this.categoryDetails = {
        name: result.name,
        guidance: result.guidance,
      };
    }
  }

  async getDataRecords(recordType?: DataRecordType, profileId?: string) {
    const startDate = this.startDateFilter.value ? convertToLocalDateOnlyUTC(this.startDateFilter.value) : undefined;
    const endDate = this.endDateFilter.value ? convertToLocalDateOnlyUTC(this.endDateFilter.value) : undefined;
    const inputType = Object.values(InputType).find(type => type === this.filteredInputType.value.value);

    const response = await this.dataRecordService.getDataRecordsByFilter(
      inputType,
      recordType,
      this.recordCategory,
      profileId,
      this.filteredAssets.value.map(opt => opt.value),
      Object.values(AssetType).filter(type => this.filteredAssetTypes.value.find(opt => type === opt.value)),
      undefined,
      startDate,
      endDate,
      this.currentPagination.currentPage,
      this.currentPagination.pageSize
    );
    this.dataRecords = response.result.items;

    this.currentPagination = {
      currentPage: response.result.currentPage,
      totalPages: response.result.totalPages,
      pageSize: response.result.pageSize,
      totalCount: response.result.totalCount,
      hasPrevious: response.result.hasPrevious,
      hasNext: response.result.hasNext,
    };
  }

  async delayRemoveAddedId(addedId: string, ms: number) {
    await new Promise(resolve => setTimeout(resolve, ms));
    this.newlyAddedRowsIds = this.newlyAddedRowsIds.filter(id => id !== addedId);
  }

  async handleRecordFormSubmit(params: UpdateDataRecordCommandParams | CreateDataRecordCommandParams) {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    const response = await this.getAddOrEditResponse(params);
    this.isSubmitting = false;
    if (response && response.success) {
      this.loadRecords();
      if (response instanceof CreateDataRecordCommandResponse) {
        const addedId = response.result?.id;
        if (addedId) {
          this.newlyAddedRowsIds.push(addedId);
          this.delayRemoveAddedId(addedId, 15000);
        }
      }
    }
  }

  getAddOrEditResponse(
    params: CreateDataRecordCommandParams | UpdateDataRecordCommandParams
  ): Promise<CreateDataRecordCommandResponse> | Promise<UpdateDataRecordCommandResponse> | undefined {
    if (params instanceof UpdateDataRecordCommandParams) {
      return this.dataRecordService.updateDataRecord(params);
    } else if (params instanceof CreateDataRecordCommandParams) {
      return this.dataRecordService.createDataRecord(params);
    } else {
      return undefined;
    }
  }

  handleDeleteRecord(id: string) {
    this.dataRecordService.deleteDataRecord(id).then(response => {
      if (response.success) {
        this.loadRecords();
      }
    });
  }

  handleDeleteAllFilteredRecordsInitiate() {
    this.isDeleteDialogOpen = true;
  }

  handleDeleteAllFilteredRecordsClose() {
    this.isDeleteDialogOpen = false;
  }

  handleDeleteAllFilteredRecordsSubmit() {
    this.handleOnDeleteAllFilteredRecords();
  }

  handleOnDeleteAllFilteredRecords(recordType?: DataRecordType, profileId?: string) {
    const startDate = this.startDateFilter.value ? convertToLocalDateOnlyUTC(this.startDateFilter.value) : undefined;
    const endDate = this.endDateFilter.value ? convertToLocalDateOnlyUTC(this.endDateFilter.value) : undefined;
    const inputType = Object.values(InputType).find(type => type === this.filteredInputType.value.value);
    this.isDeleteDialogOpen = false;
    this.dataRecordService
      .deleteDataRecordsBy(
        inputType,
        recordType,
        this.recordCategory,
        profileId,
        this.filteredAssets.value.map(opt => opt.value),
        Object.values(AssetType).filter(type => this.filteredAssetTypes.value.find(opt => type === opt.value)),
        undefined,
        startDate,
        endDate
      )
      .then(response => {
        if (response.success) {
          this.notificationService.showSuccess(
            this.currentPagination.totalCount + ' Records were successfully deleted'
          );
          this.loadRecords();
        }
      });
  }

  handlePaginationChange(newPaginationData: PaginationUi) {
    this.currentPagination = newPaginationData;
    this.loadRecords();
    this.setQueryParams();
  }

  resetPagination() {
    this.currentPagination = {
      currentPage: 1,
      totalPages: 1,
      pageSize: this.currentPagination.pageSize,
      totalCount: 0,
      hasPrevious: false,
      hasNext: false,
    };
  }

  handleAssetTypeFilterChange() {
    this.setVisibleAssetOptions();
    this.handleFilterChange();
  }

  setVisibleAssetOptions() {
    if (this.filteredAssetTypes.value.length) {
      const selectedAssetTypes = this.filteredAssetTypes.value.map(option => option.value);
      this.filteredAssets.setValue(
        this.filteredAssets.value.filter(opt => opt?.group && selectedAssetTypes.includes(opt.group))
      );
      this.assetFilterOptions = this.assetOptions.filter(group => {
        return group.options.some(option => option?.group && selectedAssetTypes.includes(option.group));
      });
    } else this.assetFilterOptions = this.assetOptions;
  }

  handleInputTypeChange(value: IOption) {
    this.filteredInputType.setValue(value);
    this.handleFilterChange();
  }

  handleFilterChange() {
    this.currentPagination = { ...this.currentPagination, currentPage: 1, hasNext: false, hasPrevious: false };
    this.loadRecords();
    this.setQueryParams();
  }

  clearFilters() {
    this.filteredAssets.setValue([]);
    this.filteredAssetTypes.setValue([]);
    this.startDateFilter.setValue(null);
    this.endDateFilter.setValue(null);
    this.filteredInputType.setValue(this.inputTypeOptions[0]);
    this.setVisibleAssetOptions();
    this.handleFilterChange();
  }

  filterMenuProvider = (): IMenuItem[] => [
    { id: 'clear', label: 'Clear all filters', onClick: () => this.clearFilters() },
    {
      id: 'delete',
      label: 'Delete all filtered records',
      onClick: () => this.handleDeleteAllFilteredRecordsInitiate(),
      disabled: this.currentPagination.totalCount === 0 || !this.isFilterApplied,
    },
  ];

  setQueryParams() {
    const queryParams = {
      ...this.route.snapshot.queryParams,
    };
    queryParams['pageSize'] = this.currentPagination.pageSize;
    queryParams['currentPage'] = this.currentPagination.currentPage;
    queryParams['assetId'] = this.filteredAssets.value.length === 1 ? this.filteredAssets.value[0].value : undefined;
    queryParams['assetType'] =
      this.assetTypeOptions.length > 1 && this.filteredAssetTypes.value.length === 1
        ? this.filteredAssetTypes.value[0].value
        : undefined;

    queryParams['inputType'] = queryParams['startDate'] = this.startDateFilter.value
      ? formatDate(this.startDateFilter.value, 'YYYY-MM-dd', 'en_US')
      : undefined;
    queryParams['endDate'] = this.endDateFilter.value
      ? formatDate(this.endDateFilter.value, 'YYYY-MM-dd', 'en_US')
      : undefined;

    queryParams['inputType'] = this.filteredInputType.value?.value;

    this.router.navigate([], { queryParams });
  }

  getQueryParams() {
    const queryParams = this.route.snapshot.queryParams;
    const pageSize = queryParams['pageSize'];
    const currentPage = queryParams['currentPage'];
    const assetId = queryParams['assetId'];
    const assetType = queryParams['assetType'];
    const inputType = queryParams['inputType'];
    const startDate = queryParams['startDate'];
    const endDate = queryParams['endDate'];

    if (pageSize || currentPage) {
      this.currentPagination = {
        ...this.currentPagination,
        currentPage: currentPage || this.currentPagination.currentPage,
        pageSize: pageSize || this.currentPagination.pageSize,
      };
    }

    if (assetId) {
      const asset = this.assetOptions.flatMap(group => group.options).find(asset => asset.value === assetId);
      if (asset) this.filteredAssets.setValue([asset]);
    }

    if (assetType && this.assetTypeOptions.length > 1) {
      const type = this.assetTypeOptions.find(type => type.value === assetType);
      if (type) {
        this.filteredAssetTypes.setValue([type]);
        this.setVisibleAssetOptions();
      }
    }

    if (!inputType) {
      this.filteredInputType.setValue(this.inputTypeOptions[0]);
    } else {
      const inputTypeOption = this.inputTypeOptions.find(option => option.value === inputType);
      if (inputTypeOption) {
        this.filteredInputType.setValue(inputTypeOption);
      }
    }

    if (startDate) {
      this.startDateFilter.setValue(new Date(startDate));
    }

    if (endDate) {
      this.endDateFilter.setValue(new Date(endDate));
    }
  }

  importDataFromExcel(data: ImportDataOutput) {}

  importDataFromExcelClose() {
    this.importFromExcelDialog = false;
  }

  handleOnImportRecords() {
    this.importFromExcelDialog = true;
  }

  validateExcelAssets(records: DataRecordOutput[]): ExcelImportValidationMessage | undefined {
    const assets = this.assetOptions.flatMap(group => group.options);

    const notMappedRecordAssets = records.filter(
      record => !assets.some(asset => asset.label.trim() === record.asset?.value.trim())
    );

    if (notMappedRecordAssets.length) {
      const notMappedAssetsString = notMappedRecordAssets.map(record => record.asset.value).join(', ');
      return {
        title: 'Not valid imported data',
        description: `The following assets are not mapped to any of your assets: ${notMappedAssetsString}`,
      };
    }
    return undefined;
  }

  getAssetOptionColumn(assetName: string) {
    const findAsset = this.assetOptions
      .flatMap(group => group.options)
      .find(asset => asset.label.trim() === assetName.trim());
    if (!findAsset) {
      this.notificationService.showError('Not mapped asset', `Not mapped asset for ${assetName}`);
      throw Error();
    }
    return findAsset;
  }

  getExcelOptionColumn(
    propertyName: string,
    options: IOption[],
    record: DataRecordOutput,
    optionLabel: string
  ): IOption {
    const valueInUpperCase = record[propertyName]?.value?.trim().toUpperCase();
    const findOption = options.find(
      o => o.label.toUpperCase() === valueInUpperCase || o.value.toUpperCase() === valueInUpperCase
    );

    if (!findOption) {
      this.notificationService.showError(
        'Not mapped select option',
        `Not mapped select option for ${optionLabel} column in ${record[propertyName]?.rowNo} row`
      );
      throw Error();
    }
    return findOption;
  }
}
