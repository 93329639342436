import { Component, OnInit } from '@angular/core';
import { AppAuthService } from 'src/app/core/app-auth.service';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { ProfileStateService } from './profile-state.service';

@Component({
  selector: 'esg-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileStateService],
})
export class ProfileComponent implements OnInit {
  constructor(public state: ProfileStateService, private appAuth: AppAuthService) {}

  ngOnInit(): void {}

  changePasswordRedirect() {
    this.appAuth.changePassword();
  }
}
