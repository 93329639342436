import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

export interface RouteItemUi {
  label: string;
  link: string[];
}

@Component({
  selector: 'esg-route-selector',
  templateUrl: './route-selector.component.html',
  styleUrl: './route-selector.component.scss',
})
export class RouteSelectorComponent {
  @Input() selectedLabel: string = '';
  @Input() items: RouteItemUi[] = [];
  isCollapsed: boolean = true;
  maxHeight: string = 'unset';

  @ViewChild('selector') private selector: ElementRef | undefined;

  @ViewChild('scrollWrapper', { static: false })
  set scrollWrapper(element: ElementRef) {
    if (element) {
      this.scrollOptionIntoView(element);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMaxHeigth();
  }

  constructor() {}

  ngOnInit(): void {}

  collapse(): void {
    this.isCollapsed = true;
  }

  toggleCollapse(event: Event): void {
    event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  setMaxHeigth() {
    if (this.selector?.nativeElement) {
      const inputRect = this.selector.nativeElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const remainingHeight = windowHeight - inputRect.bottom - 100;
      this.maxHeight = `${remainingHeight}px`;
    } else {
      this.maxHeight = 'unset';
    }
  }

  scrollOptionIntoView(element: ElementRef) {
    const optionElement = element.nativeElement.querySelector('.selected');

    if (optionElement) {
      const scrollWrapperElement = element.nativeElement;
      scrollWrapperElement.scrollTop = optionElement.offsetTop - 12;
    }
  }
}
