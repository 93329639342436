import { Component } from '@angular/core';
import { WorkRelatedInjuriesStateService } from './work-related-injuries-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-work-related-injuries',
  templateUrl: './work-related-injuries.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [
    { provide: RecordsComponent, useExisting: WorkRelatedInjuriesComponent },
    WorkRelatedInjuriesStateService,
  ],
})
export class WorkRelatedInjuriesComponent extends RecordsComponent {
  constructor(public state: WorkRelatedInjuriesStateService) {
    super(state);
  }
}
