<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading indicator"></esg-dot-flashing>
<ng-container *ngIf="!state.loading">
  <h2 *ngIf="state.indicatorDefinition" class="title">{{ state.indicatorDefinition.name }}</h2>
  <div *ngIf="state.indicatorForm" class="indicator-form">
    <span class="form-header">Basic Details</span>
    <span class="input-label">Accessibility</span>
    <div class="input accesibility">
      <esg-select
        [options]="state.accessibilityOptions"
        variant="ghost"
        size="small"
        [formControl]="state.indicatorForm.accessiblity"></esg-select>
      <esg-dropdown-multiple-form
        *ngIf="state.indicatorForm.accessiblity.value.value === state.accessibilityTypesEnum.Custom"
        [options]="state.organizationOptions"
        variant="ghost"
        size="small"
        [fc]="state.indicatorForm.accesibilityCustomOrganiations"></esg-dropdown-multiple-form>
    </div>
    <span class="input-label">Indicator value</span>
    <div class="input">
      <esg-select
        class="value-type"
        [options]="state.valueTypeOptions"
        variant="ghost"
        size="small"
        [disabled]="state.indicatorForm.valueType.disabled || !!state.indicatorDefinition?.isUsed"
        [value]="state.indicatorForm.valueType.value"
        (onChange)="state.handleValueTypeChange($event)"></esg-select>
      <esg-button
        *ngIf="state.indicatorForm.valueType.value.value === state.valueTypeEnum.Table"
        [disabled]="!!state.indicatorDefinition?.isUsed"
        size="xsmall"
        (onClick)="onTableSetupClick()">
        Table Setup
      </esg-button>
    </div>
    <span class="input-label">Record Duration</span>
    <div class="input">
      <esg-select
        [value]="state.indicatorForm.recordDuration.value"
        [disabled]="state.indicatorForm.recordDuration.disabled || !!state.indicatorDefinition?.isUsed"
        variant="ghost"
        size="small"
        placeholder="Select"
        [options]="state.recordDurationOptions"
        (onChange)="state.handleDropdownChange(state.indicatorForm.recordDuration, $event)"></esg-select>
    </div>
    <span class="input-label" [class.disabled]="state.indicatorForm.unit.disabled">Unit</span>
    <div class="input">
      <esg-select
        [value]="state.indicatorForm.unit.value"
        [disabled]="state.indicatorForm.unit.disabled || !!state.indicatorDefinition?.isUsed"
        variant="ghost"
        size="small"
        [placeholder]="state.indicatorForm.valueType.value.value === state.valueTypeEnum.Table ? 'n/a' : 'Select'"
        [options]="state.unitOptions"
        (onChange)="state.handleSelectUnit($event)"></esg-select>
    </div>
    <span class="input-label" [class.disabled]="state.indicatorForm.unitLabel.disabled">Unit Label</span>
    <div class="input">
      <esg-textbox-form
        class="unit-label"
        [placeholder]="
          state.indicatorForm.valueType.value.value === state.valueTypeEnum.Table ? 'n/a' : 'e.g. Emissions'
        "
        [showOptionalText]="false"
        variant="ghost"
        [fc]="state.indicatorForm.unitLabel"
        (onEnter)="state.updateIndicator()"></esg-textbox-form>
    </div>
    <span class="input-label" [class.disabled]="state.indicatorForm.calculationType.disabled">Calculation</span>
    <div class="input">
      <esg-select
        [value]="state.indicatorForm.calculationType.value"
        [disabled]="state.indicatorForm.calculationType.disabled || !!state.indicatorDefinition?.isUsed"
        variant="ghost"
        size="small"
        [placeholder]="state.indicatorForm.valueType.value.value === state.valueTypeEnum.Table ? 'n/a' : 'Select'"
        [options]="state.calculationTypeOptions"
        (onChange)="state.handleSelectCalculationType($event)"></esg-select>
    </div>

    <span class="input-label">Disclosure Reference</span>
    <div class="input">
      <esg-textarea
        [showOptionalText]="false"
        variant="ghost"
        [fc]="state.indicatorForm.referenceStandars"
        (focusout)="state.updateIndicator()"
        [placeholder]="'i.e GRI 305-1\nOne per line\nlike this\n:-)'"></esg-textarea>
    </div>
    <span class="input-label">Description</span>
    <div class="input">
      <esg-textarea
        [showOptionalText]="false"
        variant="ghost"
        [fc]="state.indicatorForm.description"
        (focusout)="state.updateIndicator()"
        placeholder="Add decription  text here (max 200 characters) ..."></esg-textarea>
    </div>

    <span class="form-header">Tags</span>
    <span class="input-label">Main Topic</span>
    <div class="input">
      <esg-select
        [options]="state.categoryOptions"
        [disabled]="!!state.indicatorDefinition?.isUsed"
        variant="ghost"
        size="small"
        [value]="state.indicatorForm.category.value"
        (onChange)="state.handleDropdownChange(state.indicatorForm.category, $event)"></esg-select>
    </div>
    <span class="input-label">Relevant Sectors</span>
    <div class="input">
      <esg-dropdown-multiple-form
        [fc]="state.indicatorForm.sectors"
        size="small"
        variant="ghost"
        [isFilter]="true"
        placeholder="All sectors"
        [options]="state.sectorOptions"></esg-dropdown-multiple-form>
    </div>

    <span class="form-header sources">Raw Data Sources</span>
    <div class="input sources">
      <esg-dropdown-multiple-form
        [fc]="state.indicatorForm.rawDataSources"
        placeholder="Select"
        [options]="state.rawDataSourceOptions"></esg-dropdown-multiple-form>
    </div>

    <span class="form-header sources">Indicator Data Sources</span>
    <div class="input sources">
      <esg-dropdown-multiple-form
        [fc]="state.indicatorForm.indicatorDefinitionDataSources"
        placeholder="Select"
        [options]="state.indicatorDefinitionDataSourceOptions"></esg-dropdown-multiple-form>
    </div>
  </div>
  <esg-indicator-definition-table
    *ngIf="state.isTableDialogOpen"
    [tableDefinition]="state.indicatorForm?.tableDefinition?.value"
    (onCancel)="onTableSetupClose()"
    (onSubmit)="state.handleTableDefinitionChange($event)"></esg-indicator-definition-table>
</ng-container>
