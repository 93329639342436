import { Component } from '@angular/core';
import { FacilitiesStateService } from './facilities-state.service';
import { AssetListComponent } from '../asset-list.component';
import { AssetListStateService } from '../asset-list-state.service';

@Component({
  selector: 'esg-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['../asset-list.component.scss'],
  providers: [{ provide: AssetListStateService, useClass: FacilitiesStateService }],
})
export class FacilitiesComponent extends AssetListComponent {}
