import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppAuthService } from '../core/app-auth.service';
import { AppConfigurationService } from '../core/app-configuration.service';
import { AppInfoService } from '../core/app-info.service';
import { IMenuItem } from '../shared/ui/context-menu/context-menu.component';
import { getInitials } from '../shared/utils/user-utils';
import { IOption } from '../static-data/options';
import { Permission } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit {
  userContextOpen = false;
  userContextHover = false;
  widthAlertText =
    'We have detected that your screen width is\nlower than 1366 pixels. The application will\nwork properly, but it is optimized for screens\nwider than 1366 pixels';
  organizationDropdownCollapsed = true;
  organizationDropdownHover = false;

  get getInitials() {
    return getInitials(this.appInfoState.userInfo.value?.name || '');
  }

  constructor(
    public appAuth: AppAuthService,
    public appInfoState: AppInfoService,
    public settingsService: AppConfigurationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.appInfoState.initSmallWidthConfirmation();
  }

  confirmSmallWidth() {
    this.appInfoState.confirmSmallWidth();
  }

  handleCloseOnboardingDialog() {
    this.appInfoState.onboardUser();
  }

  handleOpenContextMenu(event: Event) {
    event.stopPropagation();
    this.userContextOpen = !this.userContextOpen;
  }

  handleOpenOrganizationDropdown(event: Event) {
    event.stopPropagation();
    this.organizationDropdownCollapsed = !this.organizationDropdownCollapsed;
  }

  handleToggleOrganizationDropdown(collapse: boolean) {
    this.organizationDropdownCollapsed = collapse;
  }

  handleSetOrganizationContext(organizationOption: IOption) {
    this.appInfoState.setOrganizationContext(organizationOption.value);
    this.router.navigate(['/home']);
  }

  userContextMenuProvider = (): IMenuItem[] => {
    const menuItems: IMenuItem[] = [];

    if (this.appInfoState.hasPermission(Permission.Admin_Panel)) {
      menuItems.push({
        id: '1',
        label: 'Settings',
        onClick: () => {
          this.router.navigate(['/admin']);
          this.userContextOpen = false;
        },
      });
    }
    menuItems.push({
      id: '2',
      label: 'Log out',
      onClick: () => {
        this.handleLogout();
        this.userContextOpen = false;
      },
    });
    return menuItems;
  };
  handleLogout() {
    this.appAuth.logout();
  }
}
