<div class="container">
  <esg-side-menu class="side" [showBackButton]="useNewMainNavigation">
    <span top class="title">Support</span>
    <div class="menu-container">
      <div class="topics">
        <a
          *ngFor="let topic of state.topics"
          [routerLink]="topic.name === 'Tutorials' ? 'tutorials' : topic.id"
          routerLinkActive="active"
          class="topic-link">
          <div class="topic-text">
            <span class="name">{{ topic.name }}</span>
            <span class="count">({{ topic.count }})</span>
          </div>
        </a>
      </div>
      <div class="version-details">
        <span class="version-number">ESG {{ appInfoState.webAppBuildVersion }} Beta</span>
        <span class="version-date">AppVersion: {{ appInfoState.webAppBuildInfo }}</span>
        <span class="version-date">ApiVersion: {{ appInfoState.reportApiBuildVersion }}</span>
      </div>
    </div>
  </esg-side-menu>
  <div class="main" *ngIf="state.initialized">
    <router-outlet></router-outlet>
  </div>
</div>
