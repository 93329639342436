<div class="content">
  <ng-container *ngIf="!state.loading">
    <h2 class="title">{{ state.fc?.name?.value }}</h2>
    <div class="asset-details">
      <esg-asset-version-form
        *ngIf="state.fc"
        [active]="state.isFormOpen"
        [saveDisabled]="!state.newVersionDate && !state.formgroup?.dirty"
        [deleteDisabled]="state.assetVersions.length < 2 || state.newVersionDate ? true : false"
        (onClick)="state.handleOnFormClick()"
        (onSave)="state.handleVersionSubmit()"
        (onDelete)="state.handleOnDeleteClick()"
        (onCancel)="state.handleCancel()">
        <div class="static-data" [class.active]="state.isFormOpen">
          <span class="input-label">Version valid from</span>
          <div class="input">
            <esg-datepicker
              [fc]="state.fc.versionValidFrom"
              variant="ghost"
              (onOpen)="state.handleValidFromOpen()"
              (onClose)="state.handleValidFromClose()"
              (onChange)="$event && state.handleValidFromChange($event)"></esg-datepicker>
          </div>
          <span class="input-label">Facility type</span>
          <div class="input">
            <esg-select
              [options]="state.typeOptions"
              variant="ghost"
              [disabled]="state.typeOptions.length === 1"
              size="small"
              [value]="state.fc.type.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.type, $event)"></esg-select>
          </div>
          <span class="input-label">Address</span>
          <div class="input">
            <esg-textbox-form
              [fc]="state.fc.address"
              type="text"
              variant="ghost"
              placeholder="Street, Postal Number, City"
              (onChange)="state.fc.address.setValue($event)"></esg-textbox-form>
          </div>
          <span class="input-label">Country</span>
          <div class="input">
            <esg-select
              [options]="countryOptions"
              variant="ghost"
              [disabled]="countryOptions.length === 1"
              size="small"
              [value]="state.fc.country.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.country, $event)"></esg-select>
          </div>
          <span class="input-label">Organization</span>
          <div class="input">
            <esg-select
              [options]="organizationOptions"
              variant="ghost"
              [disabled]="organizationOptions.length === 1"
              size="small"
              [value]="state.fc.organization.value || undefined"
              (onChange)="state.handleDropdownChange(state.fc.organization, $event)"></esg-select>
          </div>
          <span class="input-label">Sqm (m²)</span>
          <div class="input">
            <esg-textbox-form
              class="space-input"
              [fc]="state.fc.sqm_m2"
              type="number"
              variant="ghost"
              placeholder="m²"
              (onChange)="state.fc.sqm_m2.setValue($event)"></esg-textbox-form>
          </div>
        </div>
      </esg-asset-version-form>
      <esg-asset-version-menu
        *ngIf="state.fc && state.activeVersion"
        [assetVersions]="state.assetVersions"
        [activeVersion]="state.activeVersion"
        [newVersion]="state.newVersionDate"
        (onVersionClick)="state.handleVersionClick($event)"
        (onNewVersionClick)="state.handleNewVersion()"></esg-asset-version-menu>
    </div>
    <div class="records">
      <esg-tabs class="records-tab" [activeTab]="state.activeTab" [tabs]="state.tabs" [tabIndent]="100"></esg-tabs>
      <esg-records-overview
        class="records-overview"
        [assetId]="assetId"
        [data]="state.recordsOverview"></esg-records-overview>
    </div>
  </ng-container>
  <esg-dot-flashing *ngIf="state.loading" class="loader" label="We are loading asset details"></esg-dot-flashing>
</div>
<esg-confirm-dialog
  *ngIf="state.isDeleteDialogOpen"
  [message]="'Once you delete this version, you delete all its\n content, and there is no turning back.'"
  cancelText="No, keep it"
  (onCancel)="state.handleDeleteDialogClose()"
  (onSubmit)="state.handleVersionDelete()"></esg-confirm-dialog>
