import { Component } from '@angular/core';
import { SpillsToEnvironmentStateService } from './spills-to-environment-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-spills-to-environment',
  templateUrl: './spills-to-environment.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [
    { provide: RecordsComponent, useExisting: SpillsToEnvironmentComponent },
    SpillsToEnvironmentStateService,
  ],
})
export class SpillsToEnvironmentComponent extends RecordsComponent {
  constructor(public state: SpillsToEnvironmentStateService) {
    super(state);
  }
}
