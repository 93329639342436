import { Injectable } from '@angular/core';
import {
  DataProfileType,
  DataRecordCategory,
  EmployeeCommutingDataProfile,
  UpdateEmployeeCommutingDataProfileCommandParams,
} from 'src/api-client/report-api.generated';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { IOption, ProfileInputOptions } from 'src/app/static-data/options';
import { CreateEmployeeCommutingDataProfileCommandParams } from 'src/api-client/report-api.generated';
import { InputType } from 'src/api-client/report-api.generated';
import { formattedStringToNumber, stringToFormattedNumberString } from 'src/app/shared/utils/number-converters';
import { ProfileForm, ProfilesStateService, ProfileUi } from '../profiles-state.service';

export interface CommutingProfileForm extends ProfileForm {
  inputType: FormControl<IOption>;
  timePeriod: FormControl<ITimePeriodData | undefined>;
  totalEmployees: FormControl<string>;
  totalHomeOfficeDays: FormControl<string>;
  totalCommutingDays: FormControl<string>;
}

export interface CommutingProfileUi extends ProfileUi {
  totalCommutingDays: Number;
  totalEmployees: Number;
  totalHomeOfficeDays: Number;
}

@Injectable()
export class EmployeeCommutingStateService extends ProfilesStateService {
  selectedProfile?: CommutingProfileUi;
  profiles: CommutingProfileUi[] = [];
  profileFormgroup?: FormGroup<CommutingProfileForm> = undefined;

  async getProfiles() {
    const response = await this.dataProfileApiService.getDataProfilesByType(DataProfileType.EmployeeCommuting);
    if (response.success)
      this.profiles = response.result.map(profile => {
        const commutingProfile = profile as EmployeeCommutingDataProfile;
        return {
          id: commutingProfile.id,
          name: commutingProfile.timePeriod.period
            ? getTimeLabelFromPeriod(commutingProfile.timePeriod)
            : 'Annual ' + getTimeLabelFromPeriod(commutingProfile.timePeriod),
          description: commutingProfile.description,
          timePeriod: commutingProfile.timePeriod,
          type: commutingProfile.inputType,
          totalCommutingDays: commutingProfile.totalCommutingDays,
          totalEmployees: commutingProfile.totalEmployees,
          totalHomeOfficeDays: commutingProfile.totalHomeOfficeDays,
        };
      });
  }

  async getDataCategoryDetails() {
    const { result } = await this.dataCategoryApiService.getDataCategoryByDataRecordCategory(
      DataRecordCategory.IndirectEmissionsEmployeeCommuting
    );
    this.categoryDetails = {
      name: result.name,
      guidance: result.guidance,
    };
  }

  initateAdd() {
    this.showFormDialog = true;
    this.profileFormgroup = new FormGroup({
      timePeriod: new FormControl<ITimePeriodData | undefined>(undefined, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      inputType: new FormControl<IOption>(ProfileInputOptions[0], {
        nonNullable: true,
        validators: [Validators.required],
      }),
      totalCommutingDays: new FormControl<string>('0', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      totalEmployees: new FormControl<string>('0', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      totalHomeOfficeDays: new FormControl<string>('0', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  handleSelectProfile(profile: CommutingProfileUi) {
    this.selectedProfile = profile;
    this.profileFormgroup = new FormGroup({
      timePeriod: new FormControl<ITimePeriodData | undefined>(profile.timePeriod, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      inputType: new FormControl<IOption>(
        ProfileInputOptions.find(opt => opt.value === profile.type) || ProfileInputOptions[0],
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      totalCommutingDays: new FormControl<string>(
        stringToFormattedNumberString(profile.totalCommutingDays.toString()),
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      totalEmployees: new FormControl<string>(stringToFormattedNumberString(profile.totalEmployees.toString()), {
        nonNullable: true,
        validators: [Validators.required],
      }),
      totalHomeOfficeDays: new FormControl<string>(
        stringToFormattedNumberString(profile.totalHomeOfficeDays.toString()),
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
    });
  }

  async formSubmit() {
    if (!this.profileFormgroup) {
      return;
    }

    const formOpen = this.showFormDialog;
    const timePeriod = this.profileFormgroup.controls.timePeriod.value;
    const inputType = Object.values(InputType).find(
      type => type === this.profileFormgroup?.controls.inputType.value.value
    );

    if (!inputType || !timePeriod) {
      return;
    }

    this.handleCancelForm();
    if (this.selectedProfile) {
      await this.dataProfileApiService.updateDataProfile(
        new UpdateEmployeeCommutingDataProfileCommandParams({
          id: this.selectedProfile.id,
          description:
            'A commuting profile is based on travel patterns for each employee based on average monthly commuting where each employee has 20 commuting days. The total of commuting days in a package is calculated by total employees x 20 minus total home office days',
          type: DataProfileType.EmployeeCommuting,
          inputType: inputType,
          timePeriodType: timePeriod.type,
          year: timePeriod.year,
          period: timePeriod.period,
          totalEmployees: formattedStringToNumber(this.profileFormgroup.controls.totalEmployees.value || '') || 0,
          totalHomeOfficeDays:
            formattedStringToNumber(this.profileFormgroup.controls.totalHomeOfficeDays.value || '') || 0,
          totalCommutingDays:
            formattedStringToNumber(this.profileFormgroup.controls.totalCommutingDays.value || '') || 0,
        })
      );
      if (formOpen) {
        await this.getProfiles();
        this.selectedProfile = this.profiles.find(profile => profile.id === this.selectedProfile?.id);
      }
    } else {
      this.updating = true;
      await this.dataProfileApiService.createDataProfile(
        new CreateEmployeeCommutingDataProfileCommandParams({
          description:
            'A commuting profile is based on travel patterns for each employee based on average monthly commuting where each employee has 20 commuting days. The total of commuting days in a package is calculated by total employees x 20 minus total home office days',
          type: DataProfileType.EmployeeCommuting,
          inputType: inputType,
          timePeriodType: timePeriod.type,
          year: timePeriod.year,
          period: timePeriod.period,
          totalEmployees: 0,
          totalHomeOfficeDays: 0,
          totalCommutingDays: 0,
        })
      );
      await this.getProfiles();
      this.updating = false;
    }
  }
}
