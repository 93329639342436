import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-import-from-excel-icon',
  template: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 5V5.5H11H15.5V14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2C0.5 1.17157 1.17157 0.5 2 0.5H10.5V5Z"
        attr.stroke="{{ color }}" />
      <path d="M11 5L11 2.12012e-07L15.9971 5.00001L15 5L11 5Z" attr.fill="{{ color }}" />
      <rect x="5" width="1" height="16" attr.fill="{{ color }}" />
      <rect x="10" width="1" height="16" attr.fill="{{ color }}" />
      <rect x="16" y="5" width="1" height="16" transform="rotate(90 16 5)" attr.fill="{{ color }}" />
      <rect x="16" y="10" width="1" height="16" transform="rotate(90 16 10)" attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ImportFromExcelIconComponent {
  @Input() color: string = '#183642';
}
