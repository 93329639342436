import { Component } from '@angular/core';
import { WhistleblowingStateService } from './whistleblowing-state.service';
import { RecordsComponent } from '../records.component';

@Component({
  selector: 'esg-whistleblowing',
  templateUrl: './whistleblowing.component.html',
  styleUrls: ['../records.component.scss'],
  providers: [{ provide: RecordsComponent, useExisting: WhistleblowingComponent }, WhistleblowingStateService],
})
export class WhistleblowingComponent extends RecordsComponent {
  constructor(public state: WhistleblowingStateService) {
    super(state);
  }
}
