<div class="pro-tip" *ngIf="isProTipVisible">
    <div class="pro-tip-header">
        <p>If you are reporting using the GRI Standards, <br> you can check the documents here.</p>
        <button (click)="toggleProTip()" class="close-button">
            <esg-icon-close size="16" color="#ffffff"></esg-icon-close>
        </button>
    </div>
    <a href="https://esg.metizone.com/support/24e9b28c-2ffe-49e4-8048-dad46f393796#c909bf27-bc3d-4233-a5c2-a5e61bb3c2fe"
        target="_blank" class="pro-tip-content">
        <div>How to report using the GRI Standards?</div>
        <esg-chevron size="16px" color="#ffffff"></esg-chevron>
    </a>
</div>

<div class="gri-icon" (click)="toggleProTip()">
    <img src="assets/griicon.png" alt="GRI Icon" />
</div>