import { Injectable } from '@angular/core';
import { TemplateData } from '../report-form/report-form-state.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateStateService {
  templateData?: TemplateData;
  constructor() {}

  async getBlobFromImgUrl(url: string): Promise<Blob> {
    if (!url) {
      throw Error('url required!');
    }
    const response = await fetch(url);
    return response.blob();
  }
}
