import { Injectable } from '@angular/core';
import {
  DataRecordType,
  ICreateVesselConsumptionDataRecordCommandParams,
  CreateVesselConsumptionDataRecordCommandParams,
  AssetType,
  DataRecordCategory,
  InputType,
} from 'src/api-client/report-api.generated';
import {
  FieldConfig,
  ImportDataOutput,
} from 'src/app/shared/components/data-import-from-excel/data-import-from-excel.component';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';
import { RecordsStateService } from '../records-state.service';
import { IOption } from 'src/app/static-data/options';
import { generateGUID } from 'src/app/shared/utils/guid';

@Injectable()
export class VesselCombustionStateService extends RecordsStateService {
  title = 'Fuel Combustion';
  recordCategory = DataRecordCategory.DirectEmissionFuelCombustion;
  assetCategories = [AssetType.Vessel];
  segmentedTabs: ITabItem[] = [
    { value: DataRecordType.VesselConsumption, label: 'Manual Records' },
    { value: DataRecordType.VesselEmissions, label: 'Imported Data' },
  ];
  activeSegmentedTab = this.segmentedTabs[0].value;
  showSegmentedControl = false;

  unitOptions: IOption[] = [
    { value: 'mt', label: 'mt' },
    { value: 'm3', label: 'm³' },
  ];

  importFromExcelFieldConfigs: FieldConfig[] = [
    { propertyName: 'startDate', title: 'Start Date', type: 'date' },
    { propertyName: 'endDate', title: 'End Date', type: 'date' },
    { propertyName: 'asset', title: 'Asset', type: 'string' },
    { propertyName: 'inOperation_hrs', title: 'In Operation Hours', type: 'number', optional: true },
    { propertyName: 'distanceSailed_nm', title: 'Distance Sailed (nm)', type: 'number', optional: true },
    { propertyName: 'mgo', title: 'MGO', type: 'number', optional: true },
    { propertyName: 'mgo_unit', title: 'MGO unit (mt or m³)', type: 'string', optional: true },
    { propertyName: 'hfo', title: 'HFO', type: 'number', optional: true },
    { propertyName: 'hfo_unit', title: 'HFO unit (mt or m³)', type: 'string', optional: true },
    { propertyName: 'lfo', title: 'LFO', type: 'number', optional: true },
    { propertyName: 'lfo_unit', title: 'LFO unit (mt or m³)', type: 'string', optional: true },
    { propertyName: 'lng', title: 'LNG', type: 'number', optional: true },
    { propertyName: 'lng_unit', title: 'LNG unit (mt or m³)', type: 'string', optional: true },
    { propertyName: 'urea_l', title: 'UREA (l)', type: 'number', optional: true },
  ];

  async init() {
    this.getRecordTypeParam();
    this.loading = true;
    await this.fetchStaticData();
    await this.handleOnSegmentedTabClick(this.activeSegmentedTab);
    this.initialized = true;
  }

  async fetchStaticData() {
    await Promise.all([this.getAssetOptions(), this.getDataCategoryDetails(), this.checkForImportedData()]);
  }

  async loadRecords() {
    let type = Object.values(DataRecordType).find(type => type === this.activeSegmentedTab);
    await this.getDataRecords(type);
  }

  handleDeleteAllFilteredRecordsSubmit() {
    let type = Object.values(DataRecordType).find(type => type === this.activeSegmentedTab);
    this.handleOnDeleteAllFilteredRecords(type);
  }

  async importDataFromExcel(data: ImportDataOutput) {
    var dataSetId = generateGUID();
    this.importingInProgress = true;
    this.importingInProgressLabel = 'Importing...';

    const assetValidation = this.validateExcelAssets(data.records);
    if (assetValidation) {
      this.importingInProgress = false;
      this.notificationService.showError(assetValidation.title, assetValidation.description);
      return;
    }
    var recordsToAdd = data.records.length;
    var recordsAdded = 0;
    this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
    try {
      const addRecordPromises = data.records.map(async record => {
        const asset = this.getAssetOptionColumn(record.asset?.value);
        const mgoUnitOption = this.unitOptions.find(opt => opt.value === record.mgo_unit?.value);
        const hfoUnitOption = this.unitOptions.find(opt => opt.value === record.hfo_unit?.value);
        const lfoUnitOption = this.unitOptions.find(opt => opt.value === record.lfo_unit?.value);
        const lngUnitOption = this.unitOptions.find(opt => opt.value === record.lng_unit?.value);
        const vesselCompustion: ICreateVesselConsumptionDataRecordCommandParams = {
          type: DataRecordType.VesselConsumption,
          dataSetId: dataSetId,
          assetId: asset.value,
          inputType: InputType.ImportedFromExcel,
          startDate: record.startDate?.value,
          endDate: record.endDate?.value,
          inOperation_hrs: record.inOperation_hrs?.value,
          distanceSailed_nm: record.distanceSailed_nm?.value,
          mgo: record.mgo?.value,
          mgO_Unit: mgoUnitOption?.value || 'm3',
          hfo: record.hfo?.value,
          hfO_Unit: hfoUnitOption?.value || 'm3',
          urea_l: record.urea_l?.value,
          lfo: record.lfo?.value,
          lfO_Unit: lfoUnitOption?.value || 'm3',
          lng: record.lng?.value,
          lnG_Unit: lngUnitOption?.value || 'm3',
        };
        try {
          var result = await this.dataRecordService.createDataRecord(
            new CreateVesselConsumptionDataRecordCommandParams({ ...vesselCompustion })
          );
          ++recordsAdded;
          this.importingInProgressLabel = `Imported ${recordsAdded} of ${recordsToAdd}`;
          return result;
        } catch (e) {
          throw e;
        }
      });

      var response = await Promise.all(addRecordPromises);
      response.forEach((res, index) => {
        const addedId = res.result?.id;
        if (addedId) {
          this.newlyAddedRowsIds.push(addedId);
          this.delayRemoveAddedId(addedId, 15000);
        }
      });

      await this.loadRecords();
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
      this.importDataFromExcelClose();
    } catch (e) {
      this.importingInProgress = false;
      this.importingInProgressLabel = '';
    }
  }

  async checkForImportedData() {
    const { count } = await this.dataRecordService.getDataRecordsCountByFilter(
      undefined,
      DataRecordType.VesselEmissions,
      this.recordCategory,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    this.showSegmentedControl = count ? true : false;
  }

  async handleOnSegmentedTabClick(value: string) {
    this.loading = true;
    this.resetPagination();
    this.activeSegmentedTab = value;
    this.importFromExcelEnabled = false;
    if (this.showSegmentedControl && this.activeSegmentedTab === DataRecordType.VesselEmissions) {
      this.isReadonly = true;
    } else {
      this.isReadonly = false;
      this.importFromExcelEnabled = true;
    }
    this.setRecordTypeParam(this.activeSegmentedTab);
    if (!this.initialized) this.getQueryParams();
    await this.loadRecords();
    this.setQueryParams();
    this.loading = false;
  }

  setRecordTypeParam(recordType: string) {
    const queryParams = { ...this.route.snapshot.queryParams };
    queryParams['recordType'] = recordType;

    this.router.navigate([], { queryParams });
  }

  getRecordTypeParam() {
    const queryParams = this.route.snapshot.queryParams;
    const recordType = queryParams['recordType'];
    if (recordType) {
      if (this.segmentedTabs.find(tab => tab.value === recordType)) {
        this.activeSegmentedTab = recordType;
      }
    }
  }
}
