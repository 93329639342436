import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AssetType,
  CountryCodes,
  ClientAsset,
  UpdateClientAssetCommandParams,
} from 'src/api-client/report-api.generated';
import { IOption } from 'src/app/static-data/options';
import { AssetDetailsForm, AssetDetailsStateService } from '../../asset-details-state.service';

interface ClientDetailsForm extends AssetDetailsForm {
  country: FormControl<IOption | undefined>;
  address: FormControl<string>;
  contactPerson: FormControl<string>;
  email: FormControl<string>;
}

@Injectable()
export class ClientDetailsStateService extends AssetDetailsStateService {
  countryOptions: IOption[] = [];

  fc?: ClientDetailsForm;

  async init(assetId: string, organizationOptions: IOption[], countryOptions: IOption[]) {
    this.loading = true;
    this.assetId = assetId;
    this.organizationOptions = organizationOptions;
    this.countryOptions = countryOptions;
    await Promise.all([this.getAssetVersions(), this.getRecordsOverview()]);
    this.loading = false;
  }

  async getAssetVersion(versionId: string) {
    const response = await this.assetApiService.getAssetById(this.assetId, versionId);
    const client = response.result as ClientAsset;
    this.mainOrganizationId = client.organizationId;

    this.fc = {
      versionValidFrom: new FormControl<Date>(client.versionValidFrom, { nonNullable: true }),
      name: new FormControl<string>(client.name, { validators: [Validators.required], nonNullable: true }),
      organization: new FormControl<IOption | undefined>(
        this.organizationOptions?.find(org =>
          client?.subOrganizationId ? org.value === client?.subOrganizationId : org.value === client?.organizationId
        ),
        {
          validators: [Validators.required],
          nonNullable: true,
        }
      ),
      country: new FormControl<IOption | undefined>(
        this.countryOptions.find(country => country.value === client?.countryCode),
        { validators: [Validators.required], nonNullable: true }
      ),
      address: new FormControl<string>(client.address || '', { nonNullable: true }),
      contactPerson: new FormControl<string>(client.contactPerson || '', { nonNullable: true }),
      email: new FormControl<string>(client.email || '', { nonNullable: true }),
    };
    this.formgroup = new FormGroup(this.fc);
  }

  async updateAsset(versionId: string) {
    const countryCode = Object.values(CountryCodes).find(code => code === this.fc?.country?.value?.value);
    if (this.fc && countryCode) {
      return await this.assetApiService.updateAsset(
        new UpdateClientAssetCommandParams({
          id: this.assetId,
          type: AssetType.Client,
          name: this.fc.name.value,
          versionId: versionId,
          subOrganizationId:
            this.fc.organization.value?.value === this.mainOrganizationId
              ? undefined
              : this.fc.organization.value?.value,
          address: this.fc.address.value || undefined,
          countryCode: countryCode,
          contactPerson: this.fc.contactPerson.value || undefined,
          email: this.fc.email.value || undefined,
          versionValidFrom: this.fc.versionValidFrom.value,
        })
      );
    } else return undefined;
  }
}
