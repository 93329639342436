<button class="menu-button" [class.active]="open" (click)="handleClick($event)">
  <esg-icon-add></esg-icon-add>
</button>
<div
  *ngIf="open"
  class="menu-options"
  [class.drop-up]="dropUp"
  esg-on-click-outside
  (onClickOutside)="handleClose()"
  esgIntersectionObserver
  [threshold]="0"
  rootMargin="0px"
  (onIntersection)="handleIntersection($event)">
  <div class="menu-option" (click)="handleAddIndicator($event)">
    <esg-kpi-icon class="menu-option-icon"></esg-kpi-icon>
    Add indicator
  </div>
  <div class="menu-option" (click)="handleAddText($event)">
    <esg-text-icon class="menu-option-icon"></esg-text-icon>
    Add Text
  </div>
  <div class="menu-option" (click)="handleAddImage($event)">
    <esg-image-icon class="menu-option-icon"></esg-image-icon>
    Add Image
  </div>
  <div class="menu-option" (click)="handleAddImportFromExcel($event)">
    <esg-import-from-excel-2-icon class="menu-option-icon"></esg-import-from-excel-2-icon>
    Import excel
  </div>
</div>
