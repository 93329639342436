import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateIndicatorDataCommandParams,
  IndicatorValueType,
  TimePeriod,
  TimePeriodType,
  UpdateIndicatorDataCommandParams,
} from 'src/api-client/report-api.generated';
import { RecordBodyRow, RecordHeaderCell } from 'src/app/shared/components/records-table/records-table.component';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';
import { IOption } from 'src/app/static-data/options';
import { IndicatorUnitUi } from '../../indicator-details-state.service';
import { ITimePeriodData } from 'src/app/shared/components/time-period/time-period.component';
import { IndicatorRecordForm, IndicatorRecordsComponent } from '../indicator-records.component';
import { GenericRowUi } from 'src/app/shared/table/generic-table/generic-table.types';

@Component({
  selector: 'esg-indicator-record-form-table',
  templateUrl: './indicator-record-form-table.component.html',
  styleUrl: './indicator-record-form-table.component.scss',
})
export class IndicatorRecordFormTableComponent extends IndicatorRecordsComponent {
  @Input() indicatorUnit: IndicatorUnitUi = { key: '', name: '', label: '', description: '' };

  dataRecordsRows: RecordBodyRow[] = [];
  headerCells: RecordHeaderCell[] = [];

  editRecordId?: string;
  recordFc?: IndicatorRecordForm = undefined;
  recordFormgroup?: FormGroup<IndicatorRecordForm> = undefined;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.indicatorRecords) {
      this.setRecordsRows();
    }

    if (changes.indicatorUnit) {
      this.setHeaderCells();
    }
  }

  setHeaderCells() {
    this.headerCells = [
      { columnId: 'period', label: 'Period', description: this.periodDescription, number: false },
      { columnId: 'asset', label: 'Asset', description: '', number: false },
      { columnId: 'description', label: 'Description', description: '', number: false, optional: true },
      {
        columnId: 'value',
        label: this.indicatorUnit.label || 'Value',
        description: this.indicatorUnit.name,
        number: true,
      },
    ];
  }

  setRecordsRows() {
    this.dataRecordsRows = this.indicatorRecords.map(record => ({
      id: record.id,
      recordSource: record.recordSource,
      cells: [
        {
          columnId: 'period',
          value: this.getDurationLabel(record.timePeriod),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        { columnId: 'description', value: record.description || '', number: false },
        {
          columnId: 'value',
          value: record.numericValue?.toString() || '',
          number: true,
          unit: this.indicatorUnit.name === 'Number' ? '' : this.indicatorUnit.name,
        },
      ],
    }));
  }

  handleOnIniateAddRecord() {
    if (this.editRecordId) this.handleCloseForm();
    if (!this.showPeriodSelector) this.startDateOpen = true;
    this.setAddRecordForm();
  }

  setAddRecordForm() {
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: this.showPeriodSelector ? null : [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: this.indicatorRecordDuration === this.recordDurationEnum.DateFromTo ? [Validators.required] : null,
        nonNullable: true,
      }),
      timePeriod: new FormControl<ITimePeriodData>(this.defaultTimeperiod, {
        validators: this.showPeriodSelector ? [Validators.required] : null,
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(this.getDefaultAssetOption(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      description: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
      singleValue: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
      tableValue: new FormControl<GenericRowUi[]>([], { nonNullable: true }),
    };
    this.recordFormgroup = new FormGroup<IndicatorRecordForm>(this.recordFc);
  }

  handleOnInitateEditRecord(id: string) {
    this.editRecordId = id;
    this.setEditRecordForm(id);
  }

  setEditRecordForm(id: string) {
    const record = this.indicatorRecords.find(record => record.id === id);
    if (record) {
      this.recordFc = {
        startDate: new FormControl<Date>(record.timePeriod.customStart || new Date(), {
          validators: this.showPeriodSelector ? null : [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(record.timePeriod.customEnd || new Date(), {
          validators:
            this.indicatorRecordDuration === this.recordDurationEnum.DateFromTo ? [Validators.required] : null,
          nonNullable: true,
        }),
        timePeriod: new FormControl<ITimePeriodData>(
          record.timePeriod?.type ? record.timePeriod : this.defaultTimeperiod,
          {
            validators: this.showPeriodSelector ? [Validators.required] : null,
            nonNullable: true,
          }
        ),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === record.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        description: new FormControl<string>(record.description || '', {
          validators: [Validators.required],
          nonNullable: true,
        }),
        singleValue: new FormControl<string>(record.numericValue?.toString() || '', {
          validators: [Validators.required],
          nonNullable: true,
        }),
        tableValue: new FormControl<GenericRowUi[]>([], {
          nonNullable: true,
        }),
      };
      this.recordFormgroup = new FormGroup<IndicatorRecordForm>(this.recordFc);
    }
  }

  handleOnSubmit() {
    if (this.startDateOpen || this.endDateOpen) return;

    this.hasSubmitted = true;

    if (this.recordFormgroup?.valid && this.recordFc && this.recordFc.asset.value?.value) {
      const timePeriod = this.showPeriodSelector
        ? new TimePeriod(this.recordFc.timePeriod.value)
        : new TimePeriod({
            type: TimePeriodType.Custom,
            customStart: this.recordFc.startDate.value,
            customEnd:
              this.indicatorRecordDuration === this.recordDurationEnum.SingleDate
                ? this.recordFc.startDate.value
                : this.recordFc.endDate.value,
          });

      const record = {
        indicatorId: this.indicatorId,
        timePeriod: timePeriod,
        assetId: this.recordFc.asset.value.value,
        description: this.recordFc.description.value,
        valueType: IndicatorValueType.Numeric,
        numericValue: formattedStringToNumber(this.recordFc.singleValue.value || '') || 0,
      };

      if (this.editRecordId) {
        this.onUpdate.emit(
          new UpdateIndicatorDataCommandParams({
            id: this.editRecordId,
            ...record,
          })
        );
      } else {
        this.handleCloseForm();
        this.onCreate.emit(new CreateIndicatorDataCommandParams(record));
      }
    }
  }
}
