import { Component, Input } from '@angular/core';

@Component({
  selector: 'esg-description-and-reference',
  templateUrl: './description-and-reference.component.html',
  styleUrls: ['./description-and-reference.component.scss'],
})
export class DescriptionAndReferenceComponent {
  @Input({ required: true }) description!: string;
  @Input() reference: string = '';
  @Input() collapsedByDefault = true;
  collapsed = true;

  get formattedFrameworkStandards() {
    return this.reference.replace(/(\r\n|\n|\r)/gm, '\n');
  }

  constructor() {}

  ngOnInit(): void {
    this.collapsed = this.collapsedByDefault;
  }

  handleCollapse() {
    this.collapsed = !this.collapsed;
  }
}
