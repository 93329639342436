import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportTemplate, ReportVersion } from 'src/api-client/report-api.generated';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { ReportFormStateService } from './report-form-state.service';

export enum TemplateType {
  Custom,
  Existing,
}

export enum VisibleForm {
  TemplateList,
  ReportForm,
}

@Component({
  selector: 'esg-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
  providers: [ReportFormStateService],
})
export class ReportFormComponent implements OnInit {
  @Input() reportId?: string;
  @Input() version?: ReportVersion;
  @Output() onClose = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  readonly VisibleFormEnum = VisibleForm;

  constructor(public state: ReportFormStateService, private appConfig: AppConfigurationService) {}

  ngOnInit(): void {
    this.state.visibleForm = this.reportId ? VisibleForm.ReportForm : VisibleForm.TemplateList;
    this.state.initReport(this.reportId, this.version);
  }

  getCoverPhotoUrl(blobName?: string) {
    // TODO CANSU : CURRENTLY THIS FUNCTION IS REPEATING. SHOULD BE IN A COMMON PLACE
    return blobName ? `${this.appConfig.ReportApiBaseUrl}/report/cover-photo/${blobName}` : undefined;
  }

  handleSelectTemplate(event?: ReportTemplate) {
    if (event) {
      const { id: templateId, title, description, shortDescription, coverPhoto } = event;
      const backgroundPhotoUrl = this.getCoverPhotoUrl(coverPhoto?.blobName);
      this.state.selectTemplate({
        type: TemplateType.Existing,
        id: templateId,
        title,
        description,
        shortDescription,
        backgroundPhotoUrl,
      });
    } else {
      this.state.selectTemplate({
        type: TemplateType.Custom,
      });
    }
  }

  handleBackBtnClick() {
    this.state.clearState();
  }

  handleOnSubmit() {
    this.onSubmit.emit();
  }

  handleClose() {
    this.state.clearState();
    this.onClose.emit();
  }
}
