import { Injectable } from '@angular/core';
import { Form, FormControl, FormGroup } from '@angular/forms';
import {
  IndicatorTableValue,
  NumericCalculatedDataVm,
  SubOrganizationVm,
  TimePeriod,
  TimePeriodType,
  Trend,
} from 'src/api-client/report-api.generated';
import { CalculatedDataApiService } from 'src/app/api-client/report-api/calculated-data-api-service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { IOption, KpisTimePeriodTypeOptions } from 'src/app/static-data/options';
import { Subscription } from 'rxjs';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import * as _ from 'lodash';
import { indicatorGraphViewData } from './indicator-graph-view/indicator-graph-view.component';
import {
  AssetCalculatedDataUi,
  NumericCalculatedDataUi,
} from './numeric-calculated-data/numeric-calculated-data.component';
import { NumericCalculatedSummaryUi } from './numeric-calculated-summary/numeric-calculated-summary.component';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { AppInfoService } from 'src/app/core/app-info.service';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { ITreeOption } from 'src/app/shared/ui/select/select-tree-options/select-tree-options.component';

interface TimeFormControls {
  yearFrom: FormControl<IOption | null>;
  yearTo: FormControl<IOption | null>;
  timePeriod: FormControl<IOption>;
  indicatorScope: FormControl<ITreeOption | null>;
  includeSubOrgs: FormControl<boolean>;
}

interface CalculatedTableDataUi {
  value?: IndicatorTableValue;
  periodLabel: string;
  errorMessage?: string;
}

function getSubOrganization(subOrganizations: SubOrganizationVm[]): ITreeOption[] {
  return subOrganizations.map(so => ({
    value: so.id || '',
    label: so.name,
    children: getSubOrganization(so.subOrganizations),
  }));
}

@Injectable()
export class IndicatorPerformanceStateService {
  targetDialogOpen = false;
  private formSubscription?: Subscription;
  private unitOptionsSubscription?: Subscription;

  indicatorId?: string;
  fromYearOptions: IOption[] = [];
  toYearOptions: IOption[] = [];
  timeFormGroup: FormGroup<TimeFormControls> = new FormGroup<TimeFormControls>({
    yearFrom: new FormControl<IOption | null>(null),
    yearTo: new FormControl<IOption | null>(null),
    timePeriod: new FormControl<IOption>(KpisTimePeriodTypeOptions[0], { nonNullable: true }),
    indicatorScope: new FormControl<ITreeOption | null>(null, { nonNullable: true }),
    includeSubOrgs: new FormControl<boolean>(true, { nonNullable: true }),
  });
  timePeriodTypeOptions: IOption[] = KpisTimePeriodTypeOptions;
  organizationSubOrganizationOptions: ITreeOption[] = [];
  numericCalculatedData: NumericCalculatedDataUi[] = [];
  numericCalculatedDataSummary?: NumericCalculatedSummaryUi;
  tableCalculatedData: CalculatedTableDataUi[] = [];
  calculatedGraph?: indicatorGraphViewData;
  unitOptions: IOption[] = [];

  loading: boolean = false;

  get isAnnual() {
    return this.timeFormGroup.controls.timePeriod.value.value === TimePeriodType.Annual;
  }

  get yearInterval() {
    const fromYear = this.timeFormGroup.controls.yearFrom.value?.value;
    const toYear = this.timeFormGroup.controls.yearTo.value?.value;
    if (!fromYear && !toYear) {
      return '';
    }

    if (!fromYear || !toYear || fromYear === toYear) {
      return fromYear || toYear || '';
    }

    return `${fromYear} - ${toYear}`;
  }

  constructor(
    private appInfo: AppInfoService,
    private calculatedDataApiService: CalculatedDataApiService,
    private unitService: UnitService,
    private notificationService: NotificationService,
    protected organizationApiService: OrganizationApiService
  ) {
    this.unitOptionsSubscription = this.unitService.units$.subscribe(units => {
      this.unitOptions = Object.values(units).map(unit => ({
        value: unit.key,
        label: unit.name,
      }));
    });
  }

  async init(indicatorId: string) {
    var organizationContextId = this.appInfo.organizationContext.value;
    if (!organizationContextId) {
      throw Error('Organization context not set.');
    }
    this.indicatorId = indicatorId;

    var organizationListResponse = await this.organizationApiService.getSubOrganizationTree(organizationContextId);
    this.organizationSubOrganizationOptions = organizationListResponse.result.map(o => ({
      value: '',
      label: o.name,
      children: getSubOrganization(o.subOrganizations),
    }));
    this.handleReportScopeChange(this.organizationSubOrganizationOptions[0]);

    await this.getCalculatedData(indicatorId);
    this.formSubscription = this.timeFormGroup.valueChanges.subscribe(() => {
      this.setDisabledYearOptions();
      this.handleTimeChange();
    });
  }

  setYearOptions(minYear: number, maxYear: number) {
    const yearOptions = [];
    for (let year = minYear; year <= maxYear; year++) {
      yearOptions.push({
        value: year.toString(),
        label: year.toString(),
      });
    }

    this.fromYearOptions = yearOptions;
    this.toYearOptions = yearOptions;

    if (yearOptions.length) {
      this.timeFormGroup.controls.yearFrom.setValue(yearOptions[0]);
      this.timeFormGroup.controls.yearTo.setValue(yearOptions[yearOptions.length - 1]);

      if (yearOptions.length === 1) {
        this.timeFormGroup.controls.yearFrom.disable();
        this.timeFormGroup.controls.yearTo.disable();
      } else {
        this.timeFormGroup.controls.yearFrom.enable();
        this.timeFormGroup.controls.yearTo.enable();
      }
    }
  }

  setDisabledYearOptions() {
    if (!this.timeFormGroup.controls.yearFrom.value?.value || !this.timeFormGroup.controls.yearTo.value?.value) return;

    const yearFrom = parseInt(this.timeFormGroup.controls.yearFrom.value.value);
    const yearTo = parseInt(this.timeFormGroup.controls.yearTo.value.value);
    this.fromYearOptions = this.fromYearOptions.map(opt => ({
      ...opt,
      disabled: parseInt(opt.value) > yearTo ? true : false,
    }));
    this.toYearOptions = this.toYearOptions.map(opt => ({
      ...opt,
      disabled: parseInt(opt.value) < yearFrom ? true : false,
    }));
  }

  handleReportScopeChange(option: ITreeOption) {
    this.timeFormGroup.controls.indicatorScope.setValue(option);
    if (!option.children || option.children.length === 0) {
      this.timeFormGroup.controls.includeSubOrgs.setValue(false);
      this.timeFormGroup.controls.includeSubOrgs.disable();
    } else {
      this.timeFormGroup.controls.includeSubOrgs.setValue(true);
      this.timeFormGroup.controls.includeSubOrgs.enable();
    }
  }

  handleTimeChange() {
    if (!this.indicatorId) return;
    this.getCalculatedData(this.indicatorId);
  }

  async getCalculatedData(indicatorId: string) {
    this.loading = true;
    const response = await this.calculatedDataApiService.getByIndicatorIdCalculatedData(
      this.timeFormGroup.controls.includeSubOrgs.value,
      indicatorId,
      this.timeFormGroup.controls.timePeriod.value.value as TimePeriodType,
      this.timeFormGroup.controls.indicatorScope.value?.value || undefined,
      this.timeFormGroup.controls.yearFrom.value?.value
        ? parseInt(this.timeFormGroup.controls.yearFrom.value.value)
        : undefined,
      this.timeFormGroup.controls.yearTo.value?.value
        ? parseInt(this.timeFormGroup.controls.yearTo.value.value)
        : undefined
    );

    if (response.success === false) {
      this.loading = false;
      this.notificationService.showError(
        response.message || 'Failed to get calculated data',
        response.validationErrors?.join(',\n')
      );
      return;
    }

    if (!response.result) {
      this.loading = false;
      this.notificationService.showError('Failed to get calculated data');
      return;
    }

    if (!this.timeFormGroup.controls.yearFrom.value?.value || !this.timeFormGroup.controls.yearTo.value?.value) {
      this.setYearOptions(response.result.minFromYear, response.result.maxToYear);
    }

    if (response.result.numericCalculatedData) {
      this.setNumericCalculatedData(response.result.numericCalculatedData);
    }

    if (response.result.tableCalculatedData) {
      this.tableCalculatedData = _.orderBy(
        response.result.tableCalculatedData.items,
        ['year', 'periodLabel'],
        ['desc', 'desc']
      ).map(item => ({
        value: item.value,
        periodLabel: item.periodLabel,
        errorMessage: item.errorMessage,
      }));
    }

    this.loading = false;
  }

  setNumericCalculatedData(data: NumericCalculatedDataVm) {
    this.numericCalculatedData = _.orderBy(data.items, ['year', 'periodLabel'], ['desc', 'desc']).map(item => ({
      periodLabel: item.periodLabel,
      period: item.period,
      year: item.year,
      number: item.number,
      value: item.value,
      targetValue: item.targetValue,
      performanceToTarget: item.performanceToTarget,
      errorMessage: item.errorMessage,
    }));

    this.numericCalculatedDataSummary = {
      value: data.value,
      targetValue: data.targetValue,
      performanceToTarget: data.performanceToTarget,
      targetTrend: data.targetTrend || Trend.Decrease,
    };

    this.calculatedGraph = {
      valueSeriesArray: [
        {
          name: 'Total',
          series: data.items.map(item => ({
            value: item.value || 0,
            name: item.periodLabel,
          })),
          labelSeries: [],
        },
      ],
      targetSeries: {
        name: 'Target',
        series: data.items.map(item => ({
          value: item.targetValue || 0,
          name: item.periodLabel,
        })),
        labelSeries: [],
      },
    };
  }

  contextMenuProvider = (): IMenuItem[] => [];

  handleExpandSubstrateData = async (index: number) => {
    var calculateValue = this.numericCalculatedData[index];

    if (calculateValue.substrateData) {
      this.numericCalculatedData[index] = {
        ...calculateValue,
        substrateData: undefined,
      };
      return;
    }

    var timePeriodType = this.timeFormGroup.value.timePeriod?.value as TimePeriodType;
    if (!!this.indicatorId && calculateValue.year && this.timeFormGroup.value.timePeriod?.value) {
      var response = await this.calculatedDataApiService.getCalculatedDataSubstrateData(
        this.indicatorId,
        this.timeFormGroup.controls.indicatorScope.value?.value || undefined,
        this.timeFormGroup.controls.includeSubOrgs.value,
        timePeriodType,
        calculateValue.year,
        calculateValue.period
      );

      this.numericCalculatedData[index] = {
        ...calculateValue,
        substrateDataIsLoading: false,
        substrateData: (response.result.groups || []).map(item => ({
          groupName: item.name,
          numberOfAssets: item.assetsData?.length || 0,
          valueTotal: item.total || 0,
          expanded: false,
          assets: (item.assetsData || []).map<AssetCalculatedDataUi>(asset => ({
            name: asset.assetName,
            value: asset.total || 0,
          })),
        })),
      };
    }
  };

  handleExpandSubstrateGroupData = async (data: { index: number; groupIndex: number }) => {
    var calculateValue = this.numericCalculatedData[data.index];

    if (calculateValue.substrateData) {
      this.numericCalculatedData[data.index] = {
        ...calculateValue,
        substrateData: calculateValue.substrateData.map((group, groupIndex) => {
          return groupIndex === data.groupIndex ? { ...group, expanded: !group.expanded } : group;
        }),
      };
      return;
    }
  };

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.unitOptionsSubscription?.unsubscribe();
  }
}
