import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Permission } from 'src/api-client/report-api.generated';
import { AppInfoService } from 'src/app/core/app-info.service';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { SupportPageStateService } from '../support-page-state.service';
import { SupportContentListStateService } from './support-content-list-state.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface SupportArticleUi {
  id: string;
  name: string;
  lastUpdated: Date;
  editedBy: string;
}

export enum ViewModeEnums {
  Read,
  Create,
  Edit,
}

@Component({
  selector: 'esg-support-content-list',
  templateUrl: './support-content-list.component.html',
  styleUrls: ['./support-content-list.component.scss'],
})
export class SupportContentListComponent implements OnInit, OnDestroy {
  private topicSub?: Subscription;
  private articleSub?: Subscription;
  private userInfoSub?: Subscription;
  ViewModes = ViewModeEnums;

  removeArticleMessage = 'Once you delete the article\nthere is no turning back.';

  constructor(
    private parentState: SupportPageStateService,
    private route: ActivatedRoute,
    private router: Router,
    private appInfo: AppInfoService,
    public state: SupportContentListStateService
  ) {}

  ngOnInit(): void {
    this.topicSub = this.route.params.subscribe(params => {
      const topicId = params['topicId'];
      if (topicId) {
        const topicName = this.parentState.topics.find(topic => topic.id === topicId)?.name;
        this.state.init(topicId, topicName || '');
      } else if (this.parentState.topics.length) {
        this.router.navigate(['/support', this.parentState.topics[0].id]);
      }
    });
    this.articleSub = this.route.fragment.subscribe(f => {
      f && this.state.selectArticle(f);
    });
    this.userInfoSub = this.appInfo.userInfo$.subscribe(userInfo => {
      if (userInfo) {
        this.state.setEditPermission(
          this.appInfo.hasPermission(Permission.Support_Create) &&
            this.appInfo.hasPermission(Permission.Support_Update) &&
            this.appInfo.hasPermission(Permission.Support_Delete)
        );
      }
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousIndex !== event.currentIndex) {
      var supportTopicItemId = this.state.articles[event.previousIndex].id || '';
      moveItemInArray(this.state.articles, event.previousIndex, event.currentIndex);

      this.state.moveArticleItem(supportTopicItemId, event.previousIndex, event.currentIndex);
    }
  }

  userMenuProvider = (): IMenuItem[] =>
    this.state.editPermisson
      ? [
          {
            id: 'delete',
            label: 'Delete',
            onClick: () => this.handleDelete(),
            disabled: false,
          },
        ]
      : [];

  handleClickCreate(event: Event) {
    event.stopPropagation();
    this.state.create();
  }

  async handleSubmitCreate() {
    await this.state.submitCreate();
    this.parentState.getSupportTopics();
  }

  handleCancelCreate() {
    this.state.cancelCreate();
  }

  handleEditArticle(article: SupportArticleUi) {
    this.state.editArticle(article);
  }

  handleSelectArticle(articleId: string) {
    this.router.navigate(['/support', this.state.topicId], { fragment: articleId });
    this.state.selectArticle(articleId);
  }

  handleCloseSelectedArticle() {
    this.router.navigate(['/support', this.state.topicId]);
    this.state.closeSelectedArticle();
  }

  handleCancelEdit() {
    this.state.clearSelection();
  }

  async handleSubmitEdit() {
    await this.state.submitEdit();
    this.state.clearSelection();
  }

  handleDelete() {
    this.state.showDelete();
  }

  handleCancelDelete() {
    this.state.closeDelete();
  }

  async handleDeleteSubmit() {
    await this.state.deleteArticle();
    this.parentState.getSupportTopics();
    this.router.navigate(['/support', this.state.topicId]);
  }

  ngOnDestroy() {
    this.topicSub?.unsubscribe();
    this.articleSub?.unsubscribe();
    this.userInfoSub?.unsubscribe();
  }
}
