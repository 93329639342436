import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-section-icon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none">
      <g clip-path="url(#clip0_4240_70)">
        <rect x="15.5" y="0.5" width="6" height="15" transform="rotate(90 15.5 0.5)" stroke="#183642" />
        <rect x="16" y="9" width="1" height="16" transform="rotate(90 16 9)" fill="#183642" />
        <rect x="16" y="12" width="1" height="16" transform="rotate(90 16 12)" fill="#183642" />
        <rect x="9" y="15" width="1" height="9" transform="rotate(90 9 15)" fill="#183642" />
      </g>
      <defs>
        <clipPath id="clip0_4240_70">
          <rect attr.width="{{ size }}" attr.height="{{ size }}" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  },
})
export class SectionIconComponent {
  @Input() size: number = 16;
}
