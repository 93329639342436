<esg-dialog (onClose)="handleOnCancel()">
  <div class="container">
    <esg-textarea
      class="json-input"
      [fc]="jsonTable"
      [inputStyle]="jsonInputStyle"
      (keydown.enter)="$event.stopPropagation()"></esg-textarea>
    <div class="table-preview">
      <h3>Indicator Table setup</h3>
      <esg-generic-table [columns]="columns" [rows]="rows" [isConfigurable]="false"></esg-generic-table>
      <div class="footer">
        <span *ngIf="jsonTable.errors?.['invalidJson']" class="error">
          {{ jsonTable.errors?.['invalidJson'] }}
        </span>
        <esg-button class="tertiary" (onClick)="handleOnCancel()">Cancel</esg-button>
        <esg-button (onClick)="handleOnSubmit()" [disabled]="!jsonTable.valid || !jsonTable.dirty">
          Save Table
        </esg-button>
      </div>
    </div>
  </div>
</esg-dialog>
