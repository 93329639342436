import { Component, Input } from '@angular/core';
import { CurrencyCodes } from 'src/api-client/report-api.generated';
import { GenderDiversityEqualityDetailsRecordsStateService } from '../gender-diversity-equality-details/gender-diversity-equality-details-state.service';

@Component({
  selector: 'esg-gender-diversity-equality-records',
  templateUrl: './gender-diversity-equality-records.component.html',
  styleUrls: ['../../../records/records.component.scss'],
})
export class GenderDiversityEqualityRecordsComponent {
  @Input({ required: true }) profileId!: string;
  @Input({ required: true }) assetId!: string;
  @Input() currencyCode: string = CurrencyCodes.USD;
  constructor(public state: GenderDiversityEqualityDetailsRecordsStateService) {}

  ngOnInit(): void {
    this.state.setProfileId(this.profileId);
    this.state.setAssetId(this.assetId);
    this.state.init();
  }
}
