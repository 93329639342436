import { Component, OnInit } from '@angular/core';
import { AppInfoService } from 'src/app/core/app-info.service';
import { greetingsByCurrentTime } from 'src/app/shared/utils/date';
import { HomePageStateService } from './home-page-state.service';
import { Router } from '@angular/router';
import { AssignedTaskUi } from './assigned-tasks/assigned-tasks.component';

@Component({
  selector: 'esg-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [HomePageStateService],
})
export class HomePageComponent implements OnInit {
  greetings: string = 'Good day';
  name: string = '.\n';

  constructor(public state: HomePageStateService, public appInfoState: AppInfoService, private router: Router) {}

  ngOnInit(): void {
    this.greetings = greetingsByCurrentTime();
    this.appInfoState.userInfo$.subscribe(result => {
      this.name = result?.name ? ', ' + result.name.split(' ')[0] + '.\n' : '.\n';
    });
  }

  handleTaskClick(item: AssignedTaskUi) {
    this.router.navigate(['/report/' + item.reportId + '/' + item.topicId], { fragment: item.topicItemId });
  }
}
