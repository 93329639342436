import { Component } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';

@Component({
  selector: 'esg-records-default',
  templateUrl: './records-default.component.html',
  styleUrls: ['./records-default.component.scss'],
})
export class RecordsDefaultComponent {
  noCategoryText: string = 'Please select a category in the \nleft column to add data.';

  get useNewMainNavigation() {
    return this.appConfig.NewNavbarEnabled;
  }

  constructor(private appConfig: AppConfigurationService) {}
}
