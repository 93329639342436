import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  CalculateIndicatorCommandParams,
  CalculateIndicatorCommandResponse,
  CreateIndicatorCommandParams,
  CreateIndicatorCommandResponse,
  DeleteIndicatorCommandResponse,
  GetAllIndicatorsQueryResponse,
  GetAllNamesIndicatorsQueryResponse,
  GetByIdIndicatorQueryResponse,
  GetTargetValuesQueryResponse,
  IndicatorCategory,
  IndicatorClient,
  UpdateIndicatorTargetCommandParams,
  UpdateIndicatorTargetCommandResponse,
} from '../../../api-client/report-api.generated';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndicatorApiService {
  client: IndicatorClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new IndicatorClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async createIndicator(body: CreateIndicatorCommandParams): Promise<CreateIndicatorCommandResponse> {
    const observable$ = this.client.createIndicator(body);
    return await lastValueFrom(observable$);
  }

  async removeIndicator(id: string): Promise<DeleteIndicatorCommandResponse> {
    const observable$ = this.client.deleteIndicator(id);
    return await lastValueFrom(observable$);
  }

  async getAllIndicators(
    name: string | undefined,
    category: IndicatorCategory[] | undefined,
    pageNumber: number | undefined,
    pageSize: number | undefined
  ): Promise<GetAllIndicatorsQueryResponse> {
    const observable$ = this.client.getAllIndicators(name, category, pageNumber, pageSize);
    return await lastValueFrom(observable$);
  }

  async getAllNamesIndicators(): Promise<GetAllNamesIndicatorsQueryResponse> {
    const observable$ = this.client.getAllNamesIndicators();
    return await lastValueFrom(observable$);
  }

  async getIndicatorById(id: string): Promise<GetByIdIndicatorQueryResponse> {
    const observable$ = this.client.getIndicatorById(id);
    return await lastValueFrom(observable$);
  }

  async updateIndicatorTarget(body: UpdateIndicatorTargetCommandParams): Promise<UpdateIndicatorTargetCommandResponse> {
    const observable$ = this.client.updateIndicatorTarget(body);
    return await lastValueFrom(observable$);
  }

  async getTargetValues(indicatorId: string, subOrganizationId?: string): Promise<GetTargetValuesQueryResponse> {
    const observable$ = this.client.getTargetValues(indicatorId, subOrganizationId);
    return await lastValueFrom(observable$);
  }

  async calculateIndicator(indicatorId: string): Promise<CalculateIndicatorCommandResponse> {
    const observable$ = this.client.calculateIndicator(
      new CalculateIndicatorCommandParams({ indicatorId: indicatorId })
    );
    return await lastValueFrom(observable$);
  }
}
