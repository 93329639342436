import { Content, TableCell } from 'pdfmake/interfaces';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { IndicatorDataValueType, TimePeriod } from 'src/api-client/report-api.generated';
import { formatNumbersInString } from 'src/app/shared/utils/number-converters';
import { IKeyfigures, ISingleKeyFiguresMetaData, ITableKeyFigureValue } from '../types';
import {
  IIndicatorContentData,
  VisibleValueColumn,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';

export const generateTableValue = function (
  content: IIndicatorContentData,
  data: IKeyfigures,
  timePeriod?: TimePeriod
): Content {
  const calculatedTable: TableCell[][] = [];
  const metaData = data.metadata.tableColumnsMetadata;

  const visibleColumns = content.visibleColumns || [];

  const showCurrentValue = visibleColumns.includes(VisibleValueColumn.CurrentValue);
  const showPreviousValue = visibleColumns.includes(VisibleValueColumn.PreviousValue);

  if (metaData && metaData.length) {
    // Generate label, current, and previous tables
    const labelTable = generateLabelTable(data);
    const currentTable = showCurrentValue
      ? generateValueTable(
          metaData,
          data.currentValue.tableRows || [],
          timePeriod ? getTimeLabelFromPeriod(timePeriod, 0).replace('\n', '') : ''
        )
      : [];
    const previousTable = showPreviousValue
      ? generateValueTable(
          metaData,
          data.previousValue.tableRows || [],
          timePeriod ? getTimeLabelFromPeriod(timePeriod, -1).replace('\n', '') : ''
        )
      : [];

    // Combine tables
    for (let i = 0; i < labelTable.length; i++) {
      const row = [...labelTable[i]];

      if (showCurrentValue) {
        const currentCells = i < currentTable.length ? currentTable[i] : currentTable[0].map(() => ({ text: '' }));
        row.push(...currentCells);
      }

      if (showPreviousValue) {
        const previousCells = i < previousTable.length ? previousTable[i] : previousTable[0].map(() => ({ text: '' }));
        row.push(...previousCells);
      }

      calculatedTable.push(row);
    }

    // Calculate column widths
    const widths = Array(calculatedTable[0].length).fill('auto');

    const referenceRow: TableCell[] = [];

    referenceRow.push({
      text: data.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ', ') || ' ',
      colSpan: calculatedTable[0].length,
      margin: [4, 8, 4, 8],
      border: [false, false, false, false],
      style: 'indicatorReference',
    });

    for (let i = 1; i < calculatedTable[0].length; i++) {
      referenceRow.push({});
    }

    calculatedTable.push(referenceRow);

    return {
      stack: [
        { text: data.metadata.label || '', style: 'indicatorTableHeading' },
        {
          margin: [122, 0, 14, 0],
          table: {
            body: calculatedTable,
            widths: widths,
            dontBreakRows: true,
          },

          layout: {
            hLineColor: '#DDDDDD',
            vLineColor: '#DDDDDD',
            fillColor: function (rowIndex, node, columnIndex) {
              return columnIndex > 0 && columnIndex < metaData.length ? '#f4f4f4' : null;
            },
            hLineWidth: function () {
              return 0.5;
            },
            vLineWidth: function (i) {
              if (i === 0 || i === calculatedTable[0].length) {
                return 0;
              } else {
                return 0.5;
              }
            },
            paddingRight: function () {
              return 0;
            },
            paddingLeft: function () {
              return 0;
            },
            paddingTop: function () {
              return 0;
            },
            paddingBottom: function () {
              return 0;
            },
          },
        },
      ],
    };
  } else {
    return {
      text: '',
    };
  }
};

const generateLabelTable = function (data: IKeyfigures): TableCell[][] {
  const tableBody: TableCell[][] = [
    [
      {
        text: '',
      },
    ],
  ];

  if (data.metadata.tableColumnsMetadata?.length) {
    const hasGroupLabel = data.metadata.tableColumnsMetadata.some(column => column.groupLabel);

    if (hasGroupLabel) {
      tableBody.push([
        {
          text: '',
        },
      ]);
    }

    const firstColumn = data.metadata.tableColumnsMetadata[0];

    tableBody.push([
      {
        text: firstColumn.label?.toUpperCase() || '',
        style: 'indicatorHeading',
        margin: [4, 10, 4, 10],
      },
    ]);
  }

  const tableRows = data.currentValue.tableRows ? data.currentValue.tableRows : data.previousValue.tableRows;

  tableRows?.map(row => {
    tableBody.push([
      {
        text: row?.values?.length ? (row.values[0]?.value || '').toString() : '',
        style: 'indicatorValue',
        margin: [4, 8, 4, 8],
      },
    ]);
  });

  return tableBody;
};

const generateValueTable = function (
  metadata: ISingleKeyFiguresMetaData[],
  tableRows: ITableKeyFigureValue[],
  timperiod: string
): TableCell[][] {
  const tableBody: TableCell[][] = [];
  const colSpan = metadata.length - 1;

  const timePeriodRow: TableCell[] = [];
  timePeriodRow.push({
    text: timperiod.toUpperCase(),
    colSpan: colSpan,
    style: 'indicatorHeading',
    margin: [4, 6, 4, 6],
    alignment: 'center',
  });

  for (let i = 1; i < colSpan; i++) {
    timePeriodRow.push({ text: '' });
  }

  tableBody.push(timePeriodRow);
  const units: string[] = [];
  const groups: string[] = [];

  const groupCells: TableCell[] = [];
  let currentGroup = undefined;
  let groupColSpan = 1;
  const headerCells: TableCell[] = [];
  for (let i = 1; i < metadata.length; i++) {
    if (metadata[i].groupLabel !== currentGroup) {
      if (currentGroup) {
        groupCells.push({
          text: currentGroup.toUpperCase(),
          colSpan: groupColSpan,
          style: 'indicatorHeading',
          alignment: 'center',
          margin: [4, 10, 4, 10],
        });
        for (let i = 1; i < groupColSpan; i++) {
          groupCells.push({ text: '' });
        }
      }
      currentGroup = metadata[i].groupLabel;
      groupColSpan = 1;
    } else if (currentGroup) {
      groupColSpan++;
    }

    units.push(metadata[i].unit === 'Number' ? '' : metadata[i].unit || '');
    groups.push(metadata[i].groupLabel || '');

    headerCells.push({
      text: metadata[i].label?.toUpperCase() || '',
      style: 'indicatorHeading',
      alignment: metadata[i].type === IndicatorDataValueType.Numeric ? 'right' : 'left',
      margin: [4, 10, 4, 10],
    });
  }

  if (groupColSpan > 1) {
    groupCells.push({
      text: currentGroup?.toUpperCase() || '',
      colSpan: groupColSpan,
      style: 'indicatorHeading',
      alignment: 'center',
      margin: [4, 10, 4, 10],
    });
    for (let i = 1; i < groupColSpan; i++) {
      groupCells.push({ text: '' });
    }
  }

  if (groupCells.length) tableBody.push(groupCells);
  tableBody.push(headerCells);

  for (const row of tableRows) {
    const rowCells: TableCell[] = [];

    if (row.values) {
      for (let j = 1; j < headerCells.length + 1; j++) {
        if (j < row.values.length) {
          let text = row.values[j]?.value?.toString();
          let unit = row.values[j]?.unitDetails || units[j - 1];
          rowCells.push({
            text:
              row.values[j]?.type === IndicatorDataValueType.Numeric
                ? formatNumbersInString(text || '', 0, 0) + ' ' + unit
                : text,
            style: 'indicatorValue',
            alignment: row.values[j]?.type === IndicatorDataValueType.Numeric ? 'right' : 'left',
            margin: [4, 8, 4, 8],
          });
        } else {
          rowCells.push({ text: '' });
        }
      }
    }
    tableBody.push(rowCells);
  }

  return tableBody;
};
