import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-home-icon',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4195 20.8161V14.2562C14.4195 14.0556 14.3591 13.8971 14.2382 13.7807C14.1236 13.6578 13.9678 13.5963 13.7705 13.5963H10.239C10.0418 13.5963 9.87954 13.6578 9.75228 13.7807C9.63138 13.8971 9.57093 14.0556 9.57093 14.2562V20.8161H14.4195ZM3.72972 20.0398V11.7137L11.413 5.15381C11.8075 4.8174 12.202 4.8174 12.5965 5.15381L20.2894 11.7137V20.0398C20.2894 20.6608 20.1176 21.1428 19.774 21.4857C19.4304 21.8286 18.95 22 18.3328 22H5.69588C5.07867 22 4.59508 21.8286 4.24512 21.4857C3.90152 21.1428 3.72972 20.6608 3.72972 20.0398ZM1 11.2285C1 11.028 1.08272 10.8598 1.24816 10.7239L10.9931 2.40757C11.3112 2.13586 11.6516 2 12.0143 2C12.377 2 12.7174 2.13586 13.0356 2.40757L22.7614 10.7239C22.9205 10.8598 23 11.0344 23 11.2479C23 11.4226 22.9396 11.5617 22.8187 11.6652C22.6978 11.7623 22.5546 11.8108 22.3892 11.8108C22.2873 11.8108 22.1951 11.7881 22.1124 11.7428C22.0296 11.6911 21.9533 11.6361 21.8833 11.5779L12.3866 3.47501C12.2593 3.35856 12.132 3.30357 12.0048 3.31004C11.8775 3.31004 11.7566 3.36503 11.6421 3.47501L2.13579 11.5779C2.05944 11.6361 1.9799 11.6911 1.89718 11.7428C1.81446 11.7881 1.7222 11.8108 1.62039 11.8108C1.4295 11.8108 1.27679 11.7525 1.16226 11.6361C1.05409 11.5132 1 11.3773 1 11.2285Z"
        fill="#183642" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  host: {
    '[style.width.px]': 'size',
    '[style.height.px]': 'size',
  },
})
export class HomeIconComponent {
  @Input() size: number = 24;
}
