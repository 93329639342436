import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnitUi } from './units.component';
import { UnitApiService } from 'src/app/api-client/report-api/unit-api-service';
import * as _ from 'lodash';
import { CreateUnitCommandParams, UpdateUnitCommandParams } from 'src/api-client/report-api.generated';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

interface UnitForm {
  name: FormControl<string>;
  description: FormControl<string>;
}

@Injectable()
export class UnitsStateService {
  units: UnitUi[] = [];
  loading: boolean = false;

  editedUnitKey?: string;
  unitForm?: UnitForm;
  unitFormGroup?: FormGroup<UnitForm>;

  showRemoveForUnitKey?: string;

  showStickyDivider: boolean = false;
  hasSubmitted: boolean = false;

  constructor(private unitService: UnitApiService, private notificationService: NotificationService) {
    this.init();
  }

  async init() {
    this.loading = true;
    await this.getUnits();
    this.loading = false;
  }

  async getUnits() {
    try {
      const response = await this.unitService.getAllUnits();
      this.units = _.sortBy(response.result, [pi => pi.name.toLowerCase()]).map(unit => ({
        key: unit.key,
        name: unit.name,
        description: unit.description,
        isSystem: unit.isSystem,
      }));
    } catch (error) {
      this.loading = false;
    }
  }

  createUnit() {
    this.showRemoveForUnitKey = undefined;
    this.unitForm = {
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      description: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    };
    this.unitFormGroup = new FormGroup(this.unitForm);
  }

  async createUnitSubmit() {
    this.hasSubmitted = true;
    if (!this.unitFormGroup || !this.unitFormGroup?.dirty) {
      this.cancelCreate();
      return;
    }

    if (!this.unitFormGroup?.valid) {
      return;
    }

    const unit = new CreateUnitCommandParams({
      key: this.unitFormGroup.controls.name.value,
      name: this.unitFormGroup.controls.name.value,
      description: this.unitFormGroup.controls.description.value,
    });
    this.resetForm();

    const response = await this.unitService.createUnit(unit);
    if (response.success) {
      this.getUnits();
    } else if (response.validationErrors) {
      this.notificationService.showError(response.validationErrors.join('\n'));
    }
  }

  cancelCreate() {
    this.resetForm();
  }

  editUnit(unitKey: string) {
    this.editedUnitKey = unitKey;
    const unit = this.units.find(i => i.key === unitKey);
    if (unit) {
      this.unitForm = {
        name: new FormControl<string>(unit.name, { nonNullable: true, validators: [Validators.required] }),
        description: new FormControl<string>(unit.description, {
          nonNullable: true,
          validators: [Validators.required],
        }),
      };
      this.unitFormGroup = new FormGroup(this.unitForm);
    }
  }

  async updateUnitSubmit() {
    if (!this.editedUnitKey || !this.unitFormGroup || !this.unitFormGroup?.dirty || !this.unitFormGroup?.valid) {
      this.cancelEdit();
      return;
    }

    const unit = new UpdateUnitCommandParams({
      key: this.editedUnitKey,
      name: this.unitFormGroup.controls.name.value,
      description: this.unitFormGroup.controls.description.value,
    });
    this.editedUnitKey = undefined;
    this.resetForm();

    const response = await this.unitService.updateUnit(unit);
    if (response.success) {
      this.getUnits();
    } else if (response.validationErrors) {
      this.notificationService.showError(response.validationErrors.join('\n'));
    }
  }

  cancelEdit() {
    this.editedUnitKey = undefined;
    this.resetForm();
  }

  deleteUnit(unitKey: string) {
    this.showRemoveForUnitKey = unitKey;
  }

  async deleteUnitSubmit() {
    const key = this.showRemoveForUnitKey;
    this.showRemoveForUnitKey = undefined;
    if (key) {
      await this.unitService.deleteUnit(key);
      this.getUnits();
    }
  }

  cancelDelete() {
    this.showRemoveForUnitKey = undefined;
  }

  resetForm() {
    this.unitForm = undefined;
    this.unitFormGroup = undefined;

    this.hasSubmitted = false;
  }

  handleStickyDivider(tabEntry: IntersectionObserverEntry) {
    this.showStickyDivider = tabEntry.intersectionRatio === 0;
  }
}
