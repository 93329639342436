import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataCollectPageComponent } from './data-collect-page.component';
import { RouterModule } from '@angular/router';
import { AppLayoutComponent } from 'src/app/app-layout/app-layout.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { MsalGuard } from '@azure/msal-angular';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { SpillsToEnvironmentComponent } from './records/spills-to-environment/spills-to-environment.component';
import { MarineProtectedAreasComponent } from './records/marine-protected-areas/marine-protected-areas.component';
import { RecordsMenuComponent } from './records-menu/records-menu.component';
import { WasteComponent } from './records/waste/waste.component';
import { SegmentedControlComponent } from './shared/segmented-control/segmented-control.component';
import { CorruptionRiskComponent } from './records/corruption-risk/corruption-risk.component';
import { FinesComponent } from './records/fines/fines.component';
import { LobbyPoliticalContributionComponent } from './records/lobby-political-contribution/lobby-political-contribution.component';
import { WhistleblowingComponent } from './records/whistleblowing/whistleblowing.component';
import { ElectricityComponent } from './records/electricity/electricity.component';
import { EmployeeCommutingComponent } from './profiles/employee-commuting/employee-commuting.component';
import { ProfileFormComponent } from './shared/profile-form/profile-form.component';
import { EmployeeCommutingDetailsComponent } from './profiles/employee-commuting/employee-commuting-details/employee-commuting-details.component';
import { EmployeeCommutingRecordsComponent } from './profiles/employee-commuting/employee-commuting-records/employee-commuting-records.component';
import { LeasedAssetsComponent } from './records/leased-assets/leased-assets.component';
import { DirectiveModule } from 'src/app/shared/directives/directive.module';
import { NewHiresAndTurnoverComponent } from './records/new-hires-and-turnover/new-hires-and-turnover.component';
import { GenderDiversityEqualityComponent } from './profiles/gender-diversity-equality/gender-diversity-equality.component';
import { GenderDiversityEqualityDetailsComponent } from './profiles/gender-diversity-equality/gender-diversity-equality-details/gender-diversity-equality-details.component';
import { RecordsSheetComponent } from './shared/records-sheet/records-sheet.component';
import { TableModule } from 'src/app/shared/table/table.module';
import { RecordsDefaultComponent } from './records-default/records-default.component';
import { RecordFormDialogComponent } from './record-form-dialog/record-form-dialog.component';
import { RecordFormTableComponent } from './shared/record-form-table/record-form-table.component';
import { WhistleblowingFormTableComponent } from './records/whistleblowing/whistleblowing-form-table/whistleblowing-form-table.component';
import { WasteFormTableComponent } from './records/waste/waste-form-table/waste-form-table.component';
import { NewHiresAndTurnoverFormTableComponent } from './records/new-hires-and-turnover/new-hires-and-turnover-form-table/new-hires-and-turnover-form-table.component';
import { MarineProtectedAreasFormTableComponent } from './records/marine-protected-areas/marine-protected-areas-form-table/marine-protected-areas-form-table.component';
import { LobbyPoliticalContibutionFormTableComponent } from './records/lobby-political-contribution/lobby-political-contibution-form-table/lobby-political-contibution-form-table.component';
import { LeasedAssetsFormTableComponent } from './records/leased-assets/leased-assets-form-table/leased-assets-form-table.component';
import { FinesFormTableComponent } from './records/fines/fines-form-table/fines-form-table.component';
import { ElectricityFormTableComponent } from './records/electricity/electricity-form-table/electricity-form-table.component';
import { CorruptionRiskFormTableComponent } from './records/corruption-risk/corruption-risk-form-table/corruption-risk-form-table.component';
import { SpillsToEnvironmentFormTableComponent } from './records/spills-to-environment/spills-to-environment-form-table/spills-to-environment-form-table.component';
import { AirTravelFormTableComponent } from './records/business-travel/air-travel-form-table/air-travel-form-table.component';
import { HotelNightsFormTableComponent } from './records/business-travel/hotel-nights-form-table/hotel-nights-form-table.component';
import { VehicleCombustionFormTableComponent } from './records/fuel-combustion/vehicle-combustion-form-table/vehicle-combustion-form-table.component';
import { VesselCombustionFormTableComponent } from './records/fuel-combustion/vessel-combustion-form-table/vessel-combustion-form-table.component';
import { WorkRelatedInjuriesFormTableComponent } from './records/work-related-injuries/work-related-injuries-form-table/work-related-injuries-form-table.component';
import { ParentalLeaveFormTableComponent } from './records/parental-leave/parental-leave-form-table/parental-leave-form-table.component';
import { SickLeaveFormTableComponent } from './records/sick-leave/sick-leave-form-table/sick-leave-form-table.component';
import { AirTravelComponent } from './records/business-travel/air-travel.component';
import { HotelNightsComponent } from './records/business-travel/hotel-nights.component';
import { VesselCombustionComponent } from './records/fuel-combustion/vessel-combustion.component';
import { VehicleCombustionComponent } from './records/fuel-combustion/vehicle-combustion.component';
import { SickLeaveComponent } from './records/sick-leave/sick-leave.component';
import { ParentalLeaveComponent } from './records/parental-leave/parental-leave.component';
import { WorkRelatedInjuriesComponent } from './records/work-related-injuries/work-related-injuries.component';
import { GenderDiversityEqualityFormTableComponent } from './profiles/gender-diversity-equality/gender-diversity-equality-form-table/gender-diversity-equality-form-table.component';
import { EmployeeCommutingFormTableComponent } from './profiles/employee-commuting/employee-commuting-form-table/employee-commuting-form-table.component';
import { GenderDiversityEqualityRecordsComponent } from './profiles/gender-diversity-equality/gender-diversity-equality-records/gender-diversity-equality-records.component';
import { KeyboardShortcutsDirective } from 'src/app/shared/directives/keyboard-shortcuts.directive';
import { MarineCasualtyComponent } from './records/marine-casualty/marine-casualty.component';
import { MarineCasualtyFormTableComponent } from './records/marine-casualty/marine-casualty-form-table/marine-casualty-form-table.component';
import { SideNavigationComponent } from 'src/app/app-layout/side-navigation/side-navigation.component';

@NgModule({
  declarations: [
    DataCollectPageComponent,
    WasteComponent,
    SpillsToEnvironmentComponent,
    MarineProtectedAreasComponent,
    VesselCombustionComponent,
    VehicleCombustionComponent,
    RecordsMenuComponent,
    SegmentedControlComponent,
    CorruptionRiskComponent,
    FinesComponent,
    SickLeaveComponent,
    WorkRelatedInjuriesComponent,
    MarineCasualtyComponent,
    AirTravelComponent,
    ParentalLeaveComponent,
    LobbyPoliticalContributionComponent,
    WhistleblowingComponent,
    ElectricityComponent,
    EmployeeCommutingComponent,
    ProfileFormComponent,
    EmployeeCommutingDetailsComponent,
    EmployeeCommutingRecordsComponent,
    LeasedAssetsComponent,
    NewHiresAndTurnoverComponent,
    GenderDiversityEqualityComponent,
    GenderDiversityEqualityDetailsComponent,
    RecordsSheetComponent,
    RecordsDefaultComponent,
    RecordFormDialogComponent,
    RecordFormTableComponent,
    WhistleblowingFormTableComponent,
    WasteFormTableComponent,
    SpillsToEnvironmentFormTableComponent,
    NewHiresAndTurnoverFormTableComponent,
    MarineProtectedAreasFormTableComponent,
    LobbyPoliticalContibutionFormTableComponent,
    LeasedAssetsFormTableComponent,
    FinesFormTableComponent,
    ElectricityFormTableComponent,
    CorruptionRiskFormTableComponent,
    AirTravelFormTableComponent,
    HotelNightsComponent,
    HotelNightsFormTableComponent,
    VehicleCombustionFormTableComponent,
    VesselCombustionFormTableComponent,
    WorkRelatedInjuriesFormTableComponent,
    MarineCasualtyFormTableComponent,
    ParentalLeaveFormTableComponent,
    SickLeaveFormTableComponent,
    GenderDiversityEqualityFormTableComponent,
    EmployeeCommutingFormTableComponent,
    GenderDiversityEqualityRecordsComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    TableModule,
    SharedComponentsModule,
    DirectiveModule,
    KeyboardShortcutsDirective,
    RouterModule.forChild([
      {
        path: `data-collect`,
        component: AppLayoutComponent,
        children: [
          {
            path: ``,
            component: SideNavigationComponent,
            outlet: 'side',
          },
          {
            path: ``,
            component: DataCollectPageComponent,
            children: [
              { path: '', component: RecordsDefaultComponent },
              // Direct Emissions
              {
                path: `fuel-combustion`,
                children: [
                  { path: '', redirectTo: 'vessels', pathMatch: 'full' },
                  { path: `vessels`, component: VesselCombustionComponent },
                  { path: `vehicles-crafts-machinery`, component: VehicleCombustionComponent },
                ],
              },
              // Indirect Emissions
              { path: `electricity`, component: ElectricityComponent },
              { path: `waste`, component: WasteComponent },
              {
                path: `business-travel`,
                children: [
                  { path: '', redirectTo: 'air-travel', pathMatch: 'full' },
                  { path: `air-travel`, component: AirTravelComponent },
                  { path: `hotel-nights`, component: HotelNightsComponent },
                ],
              },
              { path: `employee-commuting`, component: EmployeeCommutingComponent },
              // Embedded Emissions
              { path: `leased-assets`, component: LeasedAssetsComponent },
              // Biodiversity
              { path: `spills-to-environment`, component: SpillsToEnvironmentComponent },
              { path: `marine-protected-areas`, component: MarineProtectedAreasComponent },
              // Employees, Health and Safety
              { path: `gender-diversity-equality`, component: GenderDiversityEqualityComponent },
              { path: `parental-leave`, component: ParentalLeaveComponent },
              { path: `sick-leave`, component: SickLeaveComponent },
              { path: `work-related-injuries`, component: WorkRelatedInjuriesComponent },
              { path: `marine-casualties`, component: MarineCasualtyComponent },
              { path: `new-hires-and-turnovers`, component: NewHiresAndTurnoverComponent },
              // Business Ethics
              { path: `corruption-risk`, component: CorruptionRiskComponent },
              { path: `fines`, component: FinesComponent },
              { path: `lobby-political-contribution`, component: LobbyPoliticalContributionComponent },
              { path: `whistleblowing`, component: WhistleblowingComponent },
            ],
          },
        ],
        canActivate: [MsalGuard],
      },
    ]),
  ],
  exports: [RecordFormDialogComponent],
})
export class DataCollectModule {}
