<table class="numeric-data-table">
  <thead>
    <tr>
      <th></th>
      <th>
        <div class="header-cell-content">
          <span class="header-cell-label">{{ isAnnual ? 'Year' : 'Quarter' }}</span>
        </div>
      </th>
      <th class="value asset">
        <div class="header-cell-content">
          <span class="header-cell-label">Assets</span>
          <span class="header-cell-desc">Number</span>
        </div>
      </th>
      <th class="value">
        <div class="header-cell-content">
          <span class="header-cell-label">Total</span>
          <span class="header-cell-desc" [innerHTML]="unit === 'Number' ? '' : (unit | formatUnitWithHtml)"></span>
        </div>
      </th>
      <th class="value">
        <div class="header-cell-content">
          <span class="header-cell-label">Target</span>
          <span class="header-cell-desc" [innerHTML]="unit === 'Number' ? '' : (unit | formatUnitWithHtml)"></span>
        </div>
      </th>
      <th class="value">
        <div class="header-cell-content">
          <span class="header-cell-label">Performance to target</span>
          <span class="header-cell-desc">Percentage</span>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let period of data; let i = index">
      <tr [class.period-expanded]="period.substrateData">
        <td class="expand-icon">
          <esg-collapsible-icon [expanded]="!!period.substrateData" (onClick)="handleExpand(i)"></esg-collapsible-icon>
        </td>
        <td>{{ isAnnual ? period.year : period.periodLabel }}</td>
        <td class="asset">{{ period.number }}</td>
        <td class="value">
          <ng-container *ngIf="!period.errorMessage">
            {{ getFormattedNumber(period.value) || '-' }}
          </ng-container>
          <esg-tooltip
            *ngIf="period.errorMessage"
            class="warning-tooltip"
            [text]="period.errorMessage"
            position="bottom"
            variant="dark"
            [underline]="false">
            <esg-warning-icon class="warning-icon"></esg-warning-icon>
          </esg-tooltip>
        </td>
        <td class="value">
          {{ getFormattedNumber(period.targetValue) || 'Not set' }}
        </td>
        <td
          class="value"
          [class.value-negative]="period.performanceToTarget && period.performanceToTarget < 0"
          [class.value-positive]="period.performanceToTarget && period.performanceToTarget >= 0">
          {{
            getFormattedNumber(period.performanceToTarget)
              ? getFormattedNumber(period.performanceToTarget) + ' %'
              : 'n/a'
          }}
        </td>
      </tr>
      <ng-container *ngFor="let group of period.substrateData; let groupIndex = index">
        <tr class="group-asset-row">
          <td></td>
          <td class="name">
            <esg-collapsible-icon
              [expanded]="group.expanded"
              (onClick)="handleExpandGroup(i, groupIndex)"></esg-collapsible-icon>
            <div>{{ group.groupName }}</div>
          </td>
          <td class="number-of-assets">{{ group.numberOfAssets }}</td>
          <td class="value">
            {{ getFormattedNumber(group.valueTotal) || '-' }}
          </td>
          <td></td>
          <td></td>
        </tr>
        <ng-container *ngIf="group.expanded">
          <ng-container *ngFor="let item of group.assets">
            <tr class="asset-row">
              <td></td>
              <td class="name">
                <span>{{ item.name }}</span>
              </td>
              <td></td>
              <td class="value">
                {{ getFormattedNumber(item.value) || '-' }}
              </td>
              <td></td>
              <td></td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
      <tr *ngIf="period.substrateData && period.substrateData.length" class="period-expanded-padding">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </ng-container>
  </tbody>
</table>
