import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  AccessTokenClient,
  AccessTokenTypes,
  CreateAccessTokenCommandParams,
  CreateAccessTokenCommandResponse,
  DeleteAccessTokenCommandParams,
  DeleteAccessTokenCommandResponse,
  GetAccessTokensByQueryResponse,
  GetAllAccessTokensQueryResponse,
  GetValidateTokenQueryResponse,
} from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenApiService {
  client: AccessTokenClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new AccessTokenClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async create(name: string, type: AccessTokenTypes, scopeId?: string): Promise<CreateAccessTokenCommandResponse> {
    const observable$ = this.client.createAccessToken(new CreateAccessTokenCommandParams({ name, type, scopeId }));
    return await lastValueFrom(observable$);
  }

  async delete(id: string): Promise<DeleteAccessTokenCommandResponse> {
    const observable$ = this.client.deleteAccessToken(new DeleteAccessTokenCommandParams({ id }));
    return await lastValueFrom(observable$);
  }

  async getBy(type: AccessTokenTypes, scopeId: string): Promise<GetAccessTokensByQueryResponse> {
    const observable$ = this.client.getAccessTokensBy(type, scopeId);
    return await lastValueFrom(observable$);
  }

  async getAll(): Promise<GetAllAccessTokensQueryResponse> {
    const observable$ = this.client.getAllAccessTokens();
    return await lastValueFrom(observable$);
  }

  async validateToken(token: string): Promise<GetValidateTokenQueryResponse> {
    const observable$ = this.client.validateToken(token);
    return await lastValueFrom(observable$);
  }
}
