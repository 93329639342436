import { Injectable } from '@angular/core';

type CalculationMode = 'percentage' | 'lastYearValue';

export interface CalculateOptions {
  baseYear: number;
  targetYear: number;
  baseValue: number;
  percentageChange?: number;
  lastYearValue?: number;
  mode: CalculationMode;
}

export interface TargetValue {
  year: number;
  value: number;
}

@Injectable({
  providedIn: 'root',
})
export class TargetValueCalculatorService {
  constructor() {}

  public calculateTargetValuesByTargetValue(options: {
    baseYear: number;
    baseValue: number;
    targetYear: number;
    targetYearValue: number;
  }): TargetValue[] {
    const targetValues: TargetValue[] = [];
    let currentValue = options.baseValue;
    let yearlyChangeValue = (options.targetYearValue - options.baseValue) / (options.targetYear - options.baseYear);

    targetValues.push({ year: options.baseYear, value: options.baseValue });
    for (let year = options.baseYear + 1; year <= options.targetYear; year++) {
      currentValue += yearlyChangeValue;
      targetValues.push({ year, value: parseFloat(currentValue.toFixed(2)) });
    }
    return targetValues;
  }
}
