export function downloadFile(objectUrl: string, fileName: string): void {
  const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

  a.href = objectUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);
  }, 0);
}

export function openFileInNewTab(objectUrl: string): void {
  const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

  a.href = objectUrl;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
  }, 0);
}

export function getBase64ImageFromURL(url: string): Promise<string> {
  return new Promise(resolve => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx && ctx.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };

    img.onerror = error => {
      console.log(error);
      resolve('');
    };

    img.src = url;
  });
}
