<div
  [class]="['input-wrapper', variant, size]"
  [class.disabled]="fc.disabled"
  [class.active]="open"
  (click)="picker.open()">
  <input
    class="datepicker-input"
    readonly
    matInput
    [min]="minDate"
    [max]="maxDate"
    [matDatepicker]="picker"
    [formControl]="fc"
    [placeholder]="placeholder"
    (dateChange)="handleChange($event)"
    [style.paddingLeft.px]="inputIndent" />
  <esg-arrow-down class="arrow" color="#183642"></esg-arrow-down>
  <esg-tag
    class="tag"
    *ngIf="isFilter && fc.value"
    label="{{ fc.value | date : 'dd. MMM yyyy' }}"
    [size]="size"
    (onClose)="handleClearFilter($event)"></esg-tag>
</div>
<mat-datepicker
  #picker
  [calendarHeaderComponent]="header"
  [opened]="autoFocus"
  (opened)="handleOpen()"
  (closed)="handleClose()"></mat-datepicker>
