export const base64ToBlob = async (base64Data: string) => {
  const base64Response = await fetch(`data:image/jpeg;base64,${base64Data}`);
  const blob = await base64Response.blob();
  return blob;
};

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}
