<esg-topic-item-placeholder *ngIf="state.isLoadingComponents"></esg-topic-item-placeholder>

<ng-container *ngIf="!state.isLoadingComponents">
  <esg-topic-item-header
    [topicItemId]="topicItem.id"
    [headerText]="topicItem.name"
    [responsiblePerson]="topicItem.responsiblePerson"
    [visible]="topicItem.visible"
    [reorderMode]="reorderMode"
    [responsiblePersonOptions]="responsiblePersonOptions"
    [showEmptyText]="showEmptyText && !state.dynamicContent.length"
    (onAddContent)="state.handleOnAddComponent($event)"
    (onEdit)="handleOnEditHeader($event)"
    (onDelete)="handleOnDeleteHeader()"
    (onResponsiblePersonChange)="handleOnResponsiblePersonChange($event)"
    (onUpdateVisibility)="handleOnUpdateVisibility($event)"></esg-topic-item-header>
  <esg-content-list
    *ngIf="state.dynamicContent.length"
    [class.bottom-padding]="isLastItem"
    [readMode]="false"
    [reorderMode]="reorderMode"
    [isVisibleInReport]="topicItem.visible"
    cdkDropList
    (cdkDropListDropped)="state.moveComponent($event)"
    [onChange]="state.onDataChangeComponent"
    [contentItems]="state.dynamicContent"
    (onDelete)="state.handleOnInitateDeleteComponent($event)"></esg-content-list>
  <esg-confirm-dialog
    *ngIf="state.removeContentId"
    [message]="state.removeContentMessage"
    (onCancel)="state.handleOnDeleteComponentCancel()"
    (onSubmit)="state.deleteComponent()"></esg-confirm-dialog>
</ng-container>
