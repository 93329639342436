<div class="tab-container">
  <ng-container *ngFor="let tab of tabs">
    <a
      *ngIf="tab.link"
      class="tab-item"
      [class.disabled]="tab.disabled"
      [routerLink]="tab.link"
      queryParamsHandling="preserve"
      routerLinkActive="active"
      (isActiveChange)="$event && handleOnLinkChange(tab.value)"
      (click)="handleOnTabClick(tab.value)"
      [style.background]="tab.value === activeTab ? activeColor : ''"
      [style.color]="tab.value === activeTab ? activeFontColor : ''">
      {{ tab.label ? tab.label : tab.value }}
    </a>
    <div
      *ngIf="!tab.link"
      class="tab-item"
      [class.disabled]="tab.disabled"
      [class.active]="tab.value === activeTab"
      (click)="handleOnTabClick(tab.value)"
      [style.background]="tab.value === activeTab ? activeColor : ''">
      {{ tab.label ? tab.label : tab.value }}
    </div>
  </ng-container>
</div>
