import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import { AnswerDto, ChatAIClient } from '../../../api-client/report-api.generated';

@Injectable({
  providedIn: 'root',
})
export class ChatAIApiService {
  client: ChatAIClient;

  constructor(readonly httpClient: HttpClient, readonly appSettings: AppConfigurationService) {
    this.client = new ChatAIClient(httpClient, appSettings.ReportApiBaseUrl);
  }

  async getBotAnswer(question: string): Promise<AnswerDto> {
    const observable$ = this.client.ask(question).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
    return await lastValueFrom(observable$);
  }
}
