import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'esg-icon-add',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      (mouseenter)="toggleHover()"
      (mouseleave)="toggleHover()"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5 2.5H7.5V7.5H2.5V8.5H7.5V13.5H8.5V8.5H13.5V7.5H8.5V2.5Z"
        [ngStyle]="{ fill: currentColor }" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class AddIconComponent implements OnInit {
  // TODO CANSU ADD VARİABLES COLORS SIZES AND TYPES LIKE LARGE SMALL
  // TODO CANSU How can I see icon hover color on Figma?
  @Input() size: string = '16';
  @Input() color: string = '#183642';
  @Input() hoverColor: string = this.color;

  currentColor: string = this.color;
  hovered: boolean = false;

  ngOnInit(): void {
    this.currentColor = this.color;
    this.hoverColor = this.color;
  }

  toggleHover() {
    this.hovered = !this.hovered;
    this.currentColor = this.hovered ? this.hoverColor : this.color;
  }
}
