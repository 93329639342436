<esg-dialog (onClose)="handleClose()">
  <container-element *ngIf="!state.isLoading" [ngSwitch]="state.visibleForm">
    <esg-template-list
      *ngSwitchCase="VisibleFormEnum.TemplateList"
      [version]="version"
      (onSelectTemplate)="handleSelectTemplate($event)"></esg-template-list>
    <esg-edit-form
      *ngSwitchCase="VisibleFormEnum.ReportForm"
      [reportId]="reportId"
      [isLoading]="state.isLoading"
      (onBackBtnClick)="handleBackBtnClick()"
      (onSubmit)="handleOnSubmit()"></esg-edit-form>
  </container-element>
  <div *ngIf="state.isLoading" class="is-loading">
    <esg-spinner label="Loading report"></esg-spinner>
  </div>
</esg-dialog>
