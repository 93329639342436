import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-select-options',
  templateUrl: './select-options.component.html',
  styleUrls: ['./select-options.component.scss'],
  host: {
    '[class]': '[size]',
    '[class.drop-up]': 'dropUp',
    '[class.has-categories]': 'groupedOptions.length',
  },
})
export class SelectOptionsComponent {
  @Input() selectedValues: IOption[] = [];
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() options: IOption[] = [];
  @Input() groupedOptions: IGroupedOptions[] = [];
  @Input() favouriteOptions: IOption[] = [];
  @Input() headerLabel: string = '';
  @Input() dropUp: boolean = false;
  @Input() allowReset: boolean = false;
  @Input() resetLabel: string = 'Clear';
  @Input() maxHeight: string = '';
  @Output() onChange = new EventEmitter<IOption>();
  @Output() onReset = new EventEmitter();

  @ViewChild('scrollWrapper', { static: false })
  set scrollWrapper(element: ElementRef) {
    if (element) {
      this.scrollOptionIntoView(element);
    }
  }

  handleChange(event: Event, option: IOption) {
    if (!option.disabled) {
      event.stopPropagation();
      this.onChange.emit(option);
    }
  }

  handleOnReset(event: Event) {
    event.stopPropagation();
    this.onReset.emit();
  }

  isOptionSelected(option: IOption) {
    return this.selectedValues.some(o => o.value == option.value);
  }

  scrollOptionIntoView(element: ElementRef) {
    if (!this.selectedValues.length) {
      return;
    }

    const optionElement = element.nativeElement.querySelector('.selected');

    if (optionElement) {
      const scrollWrapperElement = element.nativeElement;
      scrollWrapperElement.scrollTop = optionElement.offsetTop - 12;
    }
  }
}
