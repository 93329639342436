<div *ngIf="!showOnlyContent" class="details">
  <img *ngIf="organizationLogoSquare" class="logo" [src]="organizationLogoSquare" alt="logo" />
  <div *ngIf="!showDetails && !isMobile" class="organization-details">
    <span>{{ organizationName }}</span>
    <span>|</span>
    <span>{{ reportName }}</span>
  </div>
</div>
<div *ngIf="!isMobile" class="content" [class.tablet]="isTablet"><ng-content></ng-content></div>
<div *ngIf="!showOnlyContent" class="buttons">
  <button *ngIf="!isMobile && showShareButton" (click)="handleOnCopyLink()">
    <esg-share-icon [color]="hasContent ? '#333333' : '#EEEEEE'"></esg-share-icon>
  </button>
  <button *ngIf="isMobile" (click)="handleOnMenuClick()">
    <esg-menu-icon *ngIf="!open" color="#333333"></esg-menu-icon>
    <esg-icon-close *ngIf="open" color="#333333"></esg-icon-close>
  </button>
</div>
