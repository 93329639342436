import { Injectable } from '@angular/core';
import { GetIndicatorDataDescription, IndicatorRecordData } from '../indicator-data-state.service';
import { AssetApiService } from 'src/app/api-client/report-api/asset-api-service';
import { IndicatorDataApiService } from 'src/app/api-client/report-api/indicator-data-api-service';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { AssetCategoryLabel } from 'src/app/static-data/enum-mappings';
import { PaginationUi } from 'src/app/shared/components/filter-sort-bar/filter-pagination-bar.component';
import { FormControl } from '@angular/forms';
import { IMenuItem } from 'src/app/shared/ui/context-menu/context-menu.component';
import { convertToLocalDateOnlyUTC } from 'src/app/shared/utils/date';
import { AssetType, IndicatorValueType, UpdateIndicatorDataCommandParams } from 'src/api-client/report-api.generated';
import { RecordSource } from 'src/app/shared/components/records-table/records-table.component';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import { CurrencyApiService } from 'src/app/api-client/report-api/currency-api-service';
import { sortOptionsAlphabetically } from 'src/app/shared/utils/array';
import { Subscription } from 'rxjs';

@Injectable()
export class AllRecordsDialogStateService {
  readonly valueTypeEnum = IndicatorValueType;
  private unitOptionsSubscription?: Subscription;
  indicatorId?: string;

  indicatorRecords: IndicatorRecordData[] = [];
  loading = false;
  assetOptions: IGroupedOptions[] = [];
  assetFilterOptions: IGroupedOptions[] = [];
  currencyOptions: IOption[] = [];
  unitOptions: IOption[] = [];
  assetTypeOptions: IOption[] = [];

  pageSizeOptions: IOption[] = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '250', label: '250' },
  ];

  currentPagination: PaginationUi = {
    currentPage: 1,
    totalPages: 1,
    pageSize: 50,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  };

  startDateFilter: FormControl<Date | null> = new FormControl<Date | null>(null);
  endDateFilter: FormControl<Date | null> = new FormControl<Date | null>(null);
  filteredAssets: FormControl<IOption[]> = new FormControl([], { nonNullable: true });
  filteredAssetTypes: FormControl<IOption[]> = new FormControl([], { nonNullable: true });

  constructor(
    private assetApiService: AssetApiService,
    private indicatorDataService: IndicatorDataApiService,
    private currencyApiService: CurrencyApiService,
    private unitService: UnitService
  ) {
    this.unitOptionsSubscription = this.unitService.units$.subscribe(units => {
      this.unitOptions = Object.values(units).map(unit => ({
        value: unit.key,
        label: unit.name,
      }));
    });
  }

  async init(indicatorId: string) {
    this.indicatorId = indicatorId;
    this.loading = true;
    await Promise.all([this.getDataRecords(), this.getAssetOptions(), this.getCurrencies()]);
    this.loading = false;
  }

  async getAssetOptions() {
    const response = await this.assetApiService.getAllAssetOptions();
    const assets = response.result;

    const groupedAssets: { [key: string]: IGroupedOptions } = {};
    this.assetTypeOptions = [];

    assets.map(a => {
      if (!groupedAssets[a.assetType]) {
        this.assetTypeOptions.push({ value: a.assetType, label: AssetCategoryLabel[a.assetType] });
        groupedAssets[a.assetType] = { label: AssetCategoryLabel[a.assetType], options: [] };
      }
      groupedAssets[a.assetType].options.push({ value: a.id, label: a.name });
    });

    this.assetOptions = Object.values(groupedAssets);
    this.assetFilterOptions = this.assetOptions;
  }

  async getDataRecords() {
    if (this.indicatorId) {
      const startDate = this.startDateFilter.value ? convertToLocalDateOnlyUTC(this.startDateFilter.value) : undefined;
      const endDate = this.endDateFilter.value ? convertToLocalDateOnlyUTC(this.endDateFilter.value) : undefined;
      const response = await this.indicatorDataService.getIndicatorDataBy(
        this.indicatorId,
        startDate,
        endDate,
        this.filteredAssets.value.map(opt => opt.value),
        Object.values(AssetType).filter(type => this.filteredAssetTypes.value.find(opt => type === opt.value)),
        undefined,
        this.currentPagination.currentPage,
        this.currentPagination.pageSize
      );

      this.currentPagination = {
        currentPage: response.result.currentPage,
        totalPages: response.result.totalPages,
        pageSize: response.result.pageSize,
        totalCount: response.result.totalCount,
        hasPrevious: response.result.hasPrevious,
        hasNext: response.result.hasNext,
      };

      const assetMap = new Map(
        this.assetOptions.flatMap(group => group.options.map(option => [option.value, option.label]))
      );

      this.indicatorRecords = response.result.items.map(record => {
        const recordSource = record.dataRecordId
          ? RecordSource.RawData
          : record.indicatorDataId
          ? RecordSource.IndicatorDefinition
          : undefined;
        return {
          id: record.id,
          assetId: record.assetId,
          assetName: assetMap.get(record.assetId) || '',
          timePeriod: record.timePeriod,
          description: GetIndicatorDataDescription(record),
          numericValue: record.numericValue,
          tableValue: record.tableValue,
          recordSource: recordSource,
        };
      });
    }
  }

  async getCurrencies() {
    const response = await this.currencyApiService.getAllCurrencies();
    this.currencyOptions = sortOptionsAlphabetically(
      response.result.map(currency => ({ value: currency.code, label: currency.code }))
    );
  }

  async handleUpdateRecord(params: UpdateIndicatorDataCommandParams) {
    const response = await this.indicatorDataService.updateIndicatorData(params);

    if (response.success) {
      this.getDataRecords();
    }
  }

  handleDeleteRecord(id: string) {
    this.indicatorDataService.deleteIndicatorData(id).then(response => {
      if (response.success) {
        this.getDataRecords();
      }
    });
  }

  filterMenuProvider = (): IMenuItem[] => [
    { id: 'clear', label: 'Clear all filters', onClick: () => this.clearFilters() },
  ];

  handleAssetTypeFilterChange() {
    this.setVisibleAssetOptions();
    this.handleFilterChange();
  }

  setVisibleAssetOptions() {
    if (this.filteredAssetTypes.value.length) {
      const selectedAssetTypes = this.filteredAssetTypes.value.map(option => option.value);
      this.filteredAssets.setValue(
        this.filteredAssets.value.filter(opt => opt?.group && selectedAssetTypes.includes(opt.group))
      );
      this.assetFilterOptions = this.assetOptions.filter(group => {
        return group.options.some(option => option?.group && selectedAssetTypes.includes(option.group));
      });
    } else this.assetFilterOptions = this.assetOptions;
  }

  clearFilters() {
    this.filteredAssets.setValue([]);
    this.filteredAssetTypes.setValue([]);
    this.startDateFilter.setValue(null);
    this.endDateFilter.setValue(null);
    this.setVisibleAssetOptions();
    this.handleFilterChange();
  }

  handleFilterChange() {
    this.currentPagination = { ...this.currentPagination, currentPage: 1, hasNext: false, hasPrevious: false };
    this.getDataRecords();
  }

  handlePaginationChange(newPaginationData: PaginationUi) {
    this.currentPagination = newPaginationData;
    this.getDataRecords();
  }

  ngOnDestroy() {
    if (this.unitOptionsSubscription) {
      this.unitOptionsSubscription.unsubscribe();
    }
  }
}
