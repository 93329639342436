<span *ngIf="state.useNewMainNavigation" class="category-selector-label">Select category</span>
<esg-description-and-reference
  [description]="state.categoryDetails?.guidance || ''"
  [class.is-selector]="state.useNewMainNavigation">
  <h2 *ngIf="!state.useNewMainNavigation" class="title">
    {{ state.categoryDetails && state.categoryDetails.name ? state.categoryDetails.name : 'Employee Commuting' }}
  </h2>
  <esg-route-selector
    *ngIf="state.useNewMainNavigation"
    [selectedLabel]="state.categoryDetails?.name || 'Employee Commuting'"
    [items]="state.recordCategoryRoutes"></esg-route-selector>
</esg-description-and-reference>

<esg-add-button
  label="Create New Profile"
  variant="filled"
  size="standard"
  (onClick)="state.initateAdd()"></esg-add-button>
<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading profiles"></esg-dot-flashing>
<table *ngIf="!state.loading">
  <thead>
    <tr>
      <th>COMMUTING PROFILE</th>
      <th>PROFILE TYPE</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let profile of state.profiles">
      <tr (click)="state.handleSelectProfile(profile)" [class.active]="state.selectedProfile === profile">
        <td>{{ profile.name }}</td>
        <td>{{ profile.type }}</td>
        <td>
          <div class="arrow-cell">
            Details
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 8C13 7.79839 12.9182 7.6129 12.7628 7.46774L6.28425 1.20968C6.13701 1.07258 5.95706 1 5.74438 1C5.3272 1 5 1.31452 5 1.73387C5 1.93548 5.0818 2.12097 5.21268 2.25806L11.1677 8L5.21268 13.7419C5.0818 13.879 5 14.0565 5 14.2661C5 14.6855 5.3272 15 5.74438 15C5.95706 15 6.13701 14.9274 6.28425 14.7823L12.7628 8.53226C12.9182 8.37903 13 8.20161 13 8Z"
                fill="#999999" />
            </svg>
          </div>
        </td>
      </tr>
    </ng-container>
    <tr class="add-line">
      <td>
        <esg-spinner
          *ngIf="state.updating"
          class="spinner"
          label="Creating..."
          [useDefaultTextStyle]="false"></esg-spinner>
      </td>
    </tr>
  </tbody>
</table>
<esg-details-dialog
  [open]="state.selectedProfile && !this.state.showFormDialog ? true : false"
  [options]="state.menuProvider()"
  (onClose)="state.handleCloseDetails()">
  <esg-employee-commuting-details
    *ngIf="state.selectedProfile && state.profileFormgroup"
    [profile]="state.selectedProfile"
    [formgroup]="state.profileFormgroup"
    (onUpdate)="state.formSubmit()"></esg-employee-commuting-details>
</esg-details-dialog>
<esg-profile-form
  *ngIf="state.showFormDialog && state.profileFormgroup"
  [inputTypeFc]="state.profileFormgroup.controls.inputType"
  [timePeriodFc]="state.profileFormgroup.controls.timePeriod"
  [submitText]="state.selectedProfile ? 'Update' : 'Create'"
  [header]="state.selectedProfile ? 'Update Commuting Profile' : 'Create new Commuting Profile'"
  (onCancel)="state.handleCancelForm()"
  (onSubmit)="state.formSubmit()"></esg-profile-form>
<esg-confirm-dialog
  *ngIf="state.showRemoveDialog && state.selectedProfile"
  [message]="'Once you delete this profile, you delete all its\n content, and there is no turning back.'"
  cancelText="No, keep it"
  (onCancel)="state.handleCloseDeleteDialog()"
  (onSubmit)="state.handleDelete()"></esg-confirm-dialog>
