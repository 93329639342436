<div class="container">
  <div class="section">
    <esg-time-period-selector [formControl]="timePeriodFormControl"></esg-time-period-selector>
  </div>
  <div class="risk-label-section">
    <esg-risk-label [riskCategory]="riskCategoryEnum.Unknown"></esg-risk-label>
    <esg-risk-label [riskCategory]="riskCategoryEnum.VeryHighRisk"></esg-risk-label>
    <esg-risk-label [riskCategory]="riskCategoryEnum.HighRisk"></esg-risk-label>
    <esg-risk-label [riskCategory]="riskCategoryEnum.MediumRisk"></esg-risk-label>
    <esg-risk-label [riskCategory]="riskCategoryEnum.LowRisk"></esg-risk-label>
    <esg-risk-label [riskCategory]="riskCategoryEnum.NoRisk"></esg-risk-label>
  </div>
  <div class="risk-bar-section">
    <esg-risk-bar [score]="99"></esg-risk-bar>
    <esg-risk-bar [score]="91"></esg-risk-bar>
    <esg-risk-bar [score]="75"></esg-risk-bar>
    <esg-risk-bar [score]="36"></esg-risk-bar>
    <esg-risk-bar [score]="29"></esg-risk-bar>
    <esg-risk-bar [score]="3"></esg-risk-bar>
    <esg-risk-bar [score]="1"></esg-risk-bar>
    <esg-risk-bar [score]="0"></esg-risk-bar>
    <esg-risk-bar [score]="nan"></esg-risk-bar>
  </div>
</div>
