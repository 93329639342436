<div class="table-toolbar">
  <div class="table-labels">
    <span class="table-period">
      <span class="icon-wrapper">
        <ng-container *ngIf="!errorMessage">
          <ng-container *ngIf="recordSource">
            <esg-raw-data-icon
              *ngIf="recordSource === RecordSourceEnum.RawData"
              class="source-icon"></esg-raw-data-icon>
            <esg-column-stack-icon
              *ngIf="recordSource === RecordSourceEnum.IndicatorDefinition"
              class="source-icon"></esg-column-stack-icon>
          </ng-container>
          <esg-pulsing-dot *ngIf="!recordSource" [class.pulsing]="isNewlyAdded"></esg-pulsing-dot>
        </ng-container>
        <esg-tooltip
          *ngIf="errorMessage"
          class="warning-tooltip"
          [text]="errorMessage"
          position="right"
          variant="dark"
          [underline]="false">
          <esg-warning-icon class="warning-icon"></esg-warning-icon>
        </esg-tooltip>
      </span>
      {{ periodLabel }}
    </span>
    <span>{{ assetName }}</span>
  </div>
  <div *ngIf="contextMenuOptions.length" class="table-menu">
    <esg-context-menu class="ellipsis" [options]="contextMenuOptions"></esg-context-menu>
  </div>
</div>
<table class="table-value">
  <tbody>
    <tr *ngFor="let row of rows; trackBy: trackByRowId">
      <td *ngFor="let cell of row.cells; trackBy: trackByCell">
        <div class="cell" [class.number]="cell.isNumber">
          {{ cell.value || '' }}
          <span *ngIf="cell.unit" class="unit">&nbsp;{{ cell.unit | formatUnitWithUnicode }}</span>
        </div>
      </td>
    </tr>
  </tbody>
</table>
