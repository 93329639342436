import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'esg-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent implements OnInit {
  @Input() label = '';
  @Input() variant: 'ghost' | 'filled' = 'ghost';
  @Input() size: 'standard' | 'small' = 'standard';
  @Input() disabled = false;
  @Input() showIcon: boolean = true;
  @Output() onClick = new EventEmitter<Event>();
  constructor() {}

  ngOnInit(): void {}

  handleClick(event: Event) {
    this.onClick.emit(event);
  }
}
