import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../shared/services/notification/notification.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private router: Router) {}
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      // tap(evt => {}),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              this.notificationService.showError('You are not authorized');
              this.router.navigate(['/home']);
              break;
            default:
              this.notificationService.showError(error?.message ? error.message : 'Something went wrong');
          }
          throw error;
        }
        return of(error);
      })
    );
  }
}
