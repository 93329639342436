import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatUnitWithHtml',
  standalone: true,
})
export class FormatUnitWithHtmlPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) return '';

    const htmlFormats: { [key: string]: string } = {
      CO2: 'CO<sub>2</sub>',
      CH4: 'CH<sub>4</sub>',
      N2O: 'N<sub>2</sub>O',
      SOx: 'SO<sub>x</sub>',
      NOx: 'NO<sub>x</sub>',
      'PM2.5': 'PM<sub>2.5</sub>',

      m2: 'm<sup>2</sup>',
      m3: 'm<sup>3</sup>',
    };

    const regex = new RegExp(Object.keys(htmlFormats).join('|'), 'g');

    return value.replace(regex, match => htmlFormats[match]);
  }
}
