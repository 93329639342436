import { Injectable } from '@angular/core';
import {
  CreateDataRecordCommandParams,
  CurrencyCodes,
  DataProfileType,
  DataRecordCategory,
  DataRecordType,
} from 'src/api-client/report-api.generated';
import { DataProfileApiService } from 'src/app/api-client/report-api/data-profile-api-service';
import { DataRecordApiService } from 'src/app/api-client/report-api/data-record-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NotificationLinkUi } from 'src/app/shared/ui/notification/notification.component';
import { RecordCategoryPath, RecordSubCategoryPath } from 'src/app/static-data/enum-mappings';
import { IOption } from 'src/app/static-data/options';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';

interface DataProfileUi {
  id: string;
  organizationAssetId?: string;
  salaryCurrency?: CurrencyCodes;
}

@Injectable()
export class RecordFormDialogStateService {
  readonly categoryEnum = DataRecordCategory;
  readonly subCategoryEnum = DataRecordType;

  recordCategoryOptions: IOption[] = [
    { value: DataRecordCategory.DirectEmissionFuelCombustion, label: 'Fuel Combustion' },
    { value: DataRecordCategory.IndirectEmissionsElectricity, label: 'Electricity' },
    { value: DataRecordCategory.IndirectEmissionsWaste, label: 'Waste' },
    { value: DataRecordCategory.IndirectEmissionsBusinessTravel, label: 'Business Travel' },
    { value: DataRecordCategory.IndirectEmissionsEmployeeCommuting, label: 'Employee Commuting', disabled: true },
    { value: DataRecordCategory.EmbeddedEmissionsLeasedAssets, label: 'Leased Assets' },
    { value: DataRecordCategory.BiodiversitySpillsToEnvironment, label: 'Spills to environment' },
    { value: DataRecordCategory.BiodiversityProtectedAreas, label: 'Marine Protected Areas' },
    { value: DataRecordCategory.EmployeesGenderEqualityAndDiversity, label: 'Gender Distribution', disabled: true },
    { value: DataRecordCategory.EmployeesParentalLeave, label: 'Parental Leave' },
    { value: DataRecordCategory.EmployeesSickLeave, label: 'Sick Leave' },
    { value: DataRecordCategory.EmployessWorkRelatedInjuries, label: 'Work Related Injuries' },
    { value: DataRecordCategory.EmployessMarineCasualties, label: 'Marine Casualties' },
    { value: DataRecordCategory.EmployeesNewHiresAndTurnovers, label: 'New Hires & Turnovers' },
  ];

  recordSubCategoryOptions?: IOption[];
  profileOptions?: IOption[];
  profiles: DataProfileUi[] = [];
  selectedProfile?: IOption = undefined;

  selectedCategory?: IOption = undefined;
  selectedSubCategory?: IOption = undefined;

  constructor(
    private dataRecordService: DataRecordApiService,
    private dataProfileApiService: DataProfileApiService,
    private notificationService: NotificationService
  ) {}

  setSelectedCategory(category: IOption) {
    this.selectedCategory = category;
    switch (category.value) {
      case DataRecordCategory.DirectEmissionFuelCombustion:
        this.recordSubCategoryOptions = [
          { value: DataRecordType.VesselConsumption, label: 'Vessels' },
          { value: DataRecordType.VehicleConsumption, label: 'Vehicles, Crafts & Machinery' },
        ];
        this.setSelectedSubCategory(undefined);
        break;
      case DataRecordCategory.IndirectEmissionsBusinessTravel:
        this.recordSubCategoryOptions = [
          { value: DataRecordType.OrganizationBusinessAirTravel, label: 'Air Travel' },
          { value: DataRecordType.OrganizationBusinessTravelHotelNights, label: 'Hotel Nights' },
        ];
        this.setSelectedSubCategory(undefined);
        break;
      default:
        this.recordSubCategoryOptions = undefined;
    }
  }

  async submitRecordForm(params: CreateDataRecordCommandParams) {
    const response = await this.dataRecordService.createDataRecord(params);
    if (response.success) {
      const categoryType = Object.values(DataRecordCategory).find(type => type === this.selectedCategory?.value);
      const subCategoryType = Object.values(DataRecordType).find(type => type === this.selectedSubCategory?.value);
      const routerLink = ['/', 'data-collect'];
      if (categoryType) routerLink.push(RecordCategoryPath[categoryType] || '');
      if (subCategoryType) routerLink.push(RecordSubCategoryPath[subCategoryType] || '');
      const link: NotificationLinkUi = {
        text: 'Go to records.',
        routerLink: routerLink,
        queryParams: { assetId: params.assetId },
      };
      this.notificationService.showSuccess('1 new record was added.', '', link);
    }
  }

  setSelectedSubCategory(category?: IOption) {
    this.selectedSubCategory = category;
  }

  setSelectedProfile(profile: IOption) {
    this.selectedProfile = profile;
  }

  async getProfiles(type: DataProfileType) {
    this.profileOptions = [];
    const response = await this.dataProfileApiService.getDataProfilesByType(type);
    if (response.success)
      this.profiles = response.result.map(profile => {
        this.profileOptions?.push({
          value: profile.id,
          label: profile.timePeriod.period
            ? getTimeLabelFromPeriod(profile.timePeriod)
            : 'Annual ' + getTimeLabelFromPeriod(profile.timePeriod),
        });
        return profile as DataProfileUi;
      });
  }
}
