import { Component, Input } from '@angular/core';
import { RecordForm, RecordFormTableComponent } from '../../../shared/record-form-table/record-form-table.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IOption } from 'src/app/static-data/options';
import {
  CreateEmployeeSickLeavesDataRecordCommandParams,
  DataRecordType,
  EmployeeSickLeavesDataRecord,
  InputType,
  UpdateEmployeeSickLeavesDataRecordCommandParams,
} from 'src/api-client/report-api.generated';
import { formatDate } from '@angular/common';
import { formattedStringToNumber } from 'src/app/shared/utils/number-converters';

interface SickLeaveRecordForm extends RecordForm {
  region: FormControl<IOption | undefined>;
  women: FormControl<string>;
  men: FormControl<string>;
  workingDays: FormControl<string>;
}

@Component({
  selector: 'esg-sick-leave-form-table',
  templateUrl: './sick-leave-form-table.component.html',
  styleUrls: ['../../../shared/record-form-table/record-form-table.component.scss'],
  providers: [{ provide: RecordFormTableComponent, useExisting: SickLeaveFormTableComponent }],
})
export class SickLeaveFormTableComponent extends RecordFormTableComponent {
  @Input() regionOptions: IOption[] = [];

  headerCells = [
    { columnId: 'period', label: 'Period', description: 'From – To Date', number: false },
    { columnId: 'asset', label: 'Asset', description: '', number: false },
    { columnId: 'region', label: 'Region', description: '', number: false },
    { columnId: 'women', label: 'Women', description: 'Total Sick days', number: true },
    { columnId: 'men', label: 'Men', description: 'Total Sick days', number: true },
    { columnId: 'workingDays', label: 'Working days', description: 'Total Working days', number: true },
  ];

  recordFc?: SickLeaveRecordForm = undefined;
  recordFormgroup?: FormGroup<SickLeaveRecordForm> = undefined;

  setRecordsRows() {
    this.dataRecordsRows = (this.dataRecords as EmployeeSickLeavesDataRecord[]).map(record => ({
      id: record.id,
      cells: [
        {
          columnId: 'period',
          value:
            formatDate(record.startDate, 'dd. MMM yyyy', 'en_US') +
            ' - ' +
            formatDate(record.endDate, 'dd. MMM yyyy', 'en_US'),
          number: false,
        },
        {
          columnId: 'asset',
          value: this.assetOptions.flatMap(group => group.options).find(c => c.value === record.assetId)?.label || '',
          number: false,
        },
        {
          columnId: 'region',
          value: this.regionOptions.find(c => c.value === record.regionId)?.label || '',
          number: false,
        },
        { columnId: 'women', value: record.women?.toString() || '', number: true },
        { columnId: 'men', value: record.men?.toString() || '', number: true },
        { columnId: 'workingDays', value: record.workingDays?.toString() || '', number: true },
      ],
    }));
  }
  setAddRecordForm() {
    const asset = this.getDefaultAssetOption();
    this.recordFc = {
      startDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      endDate: new FormControl<Date>(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      asset: new FormControl<IOption | undefined>(asset, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      region: new FormControl<IOption | undefined>(this.regionOptions.length ? this.regionOptions[0] : undefined, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      women: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      men: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      workingDays: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    };
    this.recordFormgroup = new FormGroup(this.recordFc);
  }

  setEditRecordForm(id: string) {
    const sickLeaveRecord = this.dataRecords.find(record => record.id === id) as EmployeeSickLeavesDataRecord;
    if (sickLeaveRecord) {
      this.recordFc = {
        startDate: new FormControl<Date>(sickLeaveRecord.startDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        endDate: new FormControl<Date>(sickLeaveRecord.endDate, {
          validators: [Validators.required],
          nonNullable: true,
        }),
        asset: new FormControl<IOption | undefined>(
          this.assetOptions.flatMap(group => group.options).find(opt => opt.value === sickLeaveRecord.assetId) ||
            this.getDefaultAssetOption(),
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        region: new FormControl<IOption | undefined>(
          this.regionOptions.find(opt => opt.value === sickLeaveRecord.regionId) || this.regionOptions[0],
          {
            validators: [Validators.required],
            nonNullable: true,
          }
        ),
        women: new FormControl<string>(sickLeaveRecord.women.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        men: new FormControl<string>(sickLeaveRecord.men.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        workingDays: new FormControl<string>(sickLeaveRecord.workingDays.toString(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
      };
      this.recordFormgroup = new FormGroup(this.recordFc);
    }
  }

  getParams():
    | CreateEmployeeSickLeavesDataRecordCommandParams
    | UpdateEmployeeSickLeavesDataRecordCommandParams
    | undefined {
    if (
      this.recordFormgroup?.valid &&
      this.recordFc &&
      this.recordFc?.asset.value?.value &&
      this.recordFc.region.value
    ) {
      const sickLeave = {
        type: DataRecordType.EmployeeSickLeaves,
        assetId: this.recordFc.asset.value.value,
        inputType: InputType.Manual,
        startDate: this.recordFc.startDate.value || new Date(),
        endDate: this.recordFc.endDate.value || new Date(),
        regionId: this.recordFc.region.value.value,
        women: formattedStringToNumber(this.recordFc.women.value) || 0,
        men: formattedStringToNumber(this.recordFc.men.value) || 0,
        workingDays: formattedStringToNumber(this.recordFc.workingDays.value) || 0,
      };
      if (this.editRecordId) {
        return new UpdateEmployeeSickLeavesDataRecordCommandParams({ id: this.editRecordId, ...sickLeave });
      } else {
        return new CreateEmployeeSickLeavesDataRecordCommandParams(sickLeave);
      }
    }
    return undefined;
  }
}
