import { Component, Input } from '@angular/core';
@Component({
  selector: 'esg-double-chevron',
  template: `
    <svg
      attr.width="{{ size }}"
      attr.height="{{ size }}"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 8C8.5 7.79839 8.4182 7.6129 8.26278 7.46774L1.78425 1.20968C1.63701 1.07258 1.45706 1 1.24438 1C0.827198 1 0.5 1.31452 0.5 1.73387C0.5 1.93548 0.5818 2.12097 0.712679 2.25806L6.66769 8L0.712679 13.7419C0.5818 13.879 0.5 14.0565 0.5 14.2661C0.5 14.6855 0.827198 15 1.24438 15C1.45706 15 1.63701 14.9274 1.78425 14.7823L8.26278 8.53226C8.4182 8.37903 8.5 8.20161 8.5 8Z"
        attr.fill="{{ color }}" />
      <path
        d="M15.5 8C15.5 7.79839 15.4182 7.6129 15.2628 7.46774L8.78425 1.20968C8.63701 1.07258 8.45706 1 8.24438 1C7.8272 1 7.5 1.31452 7.5 1.73387C7.5 1.93548 7.5818 2.12097 7.71268 2.25806L13.6677 8L7.71268 13.7419C7.5818 13.879 7.5 14.0565 7.5 14.2661C7.5 14.6855 7.8272 15 8.24438 15C8.45706 15 8.63701 14.9274 8.78425 14.7823L15.2628 8.53226C15.4182 8.37903 15.5 8.20161 15.5 8Z"
        attr.fill="{{ color }}" />
    </svg>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class DoubleChevronComponent {
  @Input() size: string = '16';
  @Input() color: string = '#183642';
}
