import { Injectable, OnDestroy } from '@angular/core';
import { DataCategoryApiService } from 'src/app/api-client/report-api/categories-api-service';
import { DataCategoryDetailsUi } from './data-collect-category-details.component';
import { DataRecordCategory, UpdateDataCategoryCommandParams } from 'src/api-client/report-api.generated';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable()
export class DataCollectCategoryDetailsStateService implements OnDestroy {
  dataCategory!: DataCategoryDetailsUi;
  private guidanceSubscription?: Subscription;

  constructor(private dataCategoryApiService: DataCategoryApiService) {}

  async init(dataRecordCategory: string) {
    const category = Object.values(DataRecordCategory).find(category => category === dataRecordCategory);
    if (category) {
      const { result } = await this.dataCategoryApiService.getDataCategoryByDataRecordCategory(category);
      this.dataCategory = {
        name: result.name,
        dataRecordCategory: result.dataRecordCategory,
        guidance: new FormControl<string>(result.guidance, { nonNullable: true }),
      };
      this.subscribeToGuidanceChanges();
    }
  }

  private subscribeToGuidanceChanges() {
    this.guidanceSubscription = this.dataCategory.guidance.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(result => {
        if (this.dataCategory.guidance.dirty) {
          this.updateGuidance(result || '');
        }
      });
  }

  private updateGuidance(guidance: string) {
    const category = Object.values(DataRecordCategory).find(
      category => category === this.dataCategory.dataRecordCategory
    );
    if (category) {
      const commandParams = new UpdateDataCategoryCommandParams({
        name: this.dataCategory.name,
        dataRecordCategory: category,
        guidance: guidance,
      });
      this.dataCategoryApiService.updateDataCategory(commandParams);
    }
  }

  ngOnDestroy() {
    if (this.guidanceSubscription) {
      this.guidanceSubscription.unsubscribe();
    }
  }
}
