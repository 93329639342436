<esg-form-dialog
  [submitText]="state.submitText"
  [submitDisabled]="!state.formgroup || !state.formgroup.valid || !state.formgroup.dirty"
  (onCancel)="onCancel.emit()"
  (onSubmit)="handleFormSubmit()">
  <div class="container">
    <header>{{ state.headerText }}</header>
    <div *ngIf="state.indicatorFc" class="content article">
      <esg-textbox-form
        class="title"
        label="TITLE"
        placeholder="Placeholder"
        [fc]="state.indicatorFc.title"></esg-textbox-form>
      <esg-dropdown-multiple-form
        [fc]="state.indicatorFc.selectedIndicators"
        [options]="state.indicatorOptions"
        [isPopup]="true"></esg-dropdown-multiple-form>
    </div>
  </div>
</esg-form-dialog>
