import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetUserInfoQueryResponse, IUserInfoVm, Permission } from 'src/api-client/report-api.generated';
import { ReportAppApiService } from '../api-client/report-api/app-api-service';
import { OrganizationApiService } from '../api-client/report-api/organization-api-service';
import { WebAppApiService } from '../api-client/web-app-api/web-app-api-service';
import { IOption } from '../static-data/options';
import { AppAuthService } from './app-auth.service';
import { UserApiService } from '../api-client/report-api/user-api-service';

@Injectable({
  providedIn: 'root',
})
export class AppInfoService {
  webAppBuildInfo?: string;
  webAppBuildVersion?: string;
  reportApiBuildVersion?: string;
  userInitialized: boolean = false;
  hasConfirmedWidthSize: boolean = false;
  showOrganizationDropdown = false;
  showCreateReport = false;
  showCreateTemplate = false;
  showOnboardingDialog = false;
  organizationOptions: IOption[] = [];
  currentOrganizationOption?: IOption = undefined;
  public readonly userInfo = new BehaviorSubject<IUserInfoVm | undefined>(undefined);
  public readonly organizationContext = new BehaviorSubject<string | undefined>(undefined);
  userInfo$ = this.userInfo.asObservable();
  organizationContext$ = this.organizationContext.asObservable();

  get userProfileName() {
    const { value } = this.userInfo;
    return value ? `${value.name}, ${value.organizationName}` : '';
  }

  constructor(
    private appAuth: AppAuthService,
    private webAppApiService: WebAppApiService,
    private reportAppApiService: ReportAppApiService,
    private organizationApiService: OrganizationApiService,
    private userApiService: UserApiService
  ) {
    appAuth.accountInfo$.subscribe(result => {
      if (result) {
        this.init();
      }
    });
  }

  async init() {
    var webAppResult = await this.webAppApiService.getAppInfo();
    this.webAppBuildInfo = webAppResult.buildInfo;
    this.webAppBuildVersion = webAppResult.buildVersion;

    var webApiResult = await this.reportAppApiService.getAppInfo();
    this.reportApiBuildVersion = webApiResult?.buildInfo;

    await this.loadUserInfo();
  }

  async setOrganizationContext(organizationId: string) {
    if (this.hasPermission(Permission.Change_Current_Context_Organization)) {
      const response = await this.reportAppApiService.setUserCurrentContextOrganizationId(organizationId);
      if (response.success) {
        this.loadUserInfo();
      }
    }
  }

  initSmallWidthConfirmation() {
    var confirmedWidth = localStorage.getItem('confirmedWidth');
    if (confirmedWidth) this.hasConfirmedWidthSize = confirmedWidth === 'true';
  }

  confirmSmallWidth() {
    this.hasConfirmedWidthSize = true;
    localStorage.setItem('confirmedWidth', this.hasConfirmedWidthSize.toString());
  }

  onboardUser() {
    this.showOnboardingDialog = false;
    this.userApiService.setOnboardedUser();
  }

  hasPermission(permission: Permission): boolean {
    return !!this.userInfo?.value?.permissions?.includes(permission);
  }

  async loadUserInfo(): Promise<void> {
    var userInfoResponse = await this.reportAppApiService.getUserInfo();

    if (!userInfoResponse?.result) {
      throw new Error(`User data empty`);
    }

    if (!userInfoResponse?.result.exists) {
      await this.reportAppApiService.initilizeUser();
      var existingUserInfo = await this.reportAppApiService.getUserInfo();

      if (!existingUserInfo.result) {
        throw new Error(`User data empty`);
      }

      this.userInfo.next(existingUserInfo.result.userInfo);
      this.organizationContext.next(existingUserInfo.result.userInfo.organizationContextId);
    } else {
      this.userInfo.next(userInfoResponse.result.userInfo);
      this.organizationContext.next(userInfoResponse.result.userInfo.organizationContextId);
    }

    if (this.userInfo.value) {
      this.showOnboardingDialog = this.userInfo.value.onboarded ? false : true;
    }

    if (this.hasPermission(Permission.Report_Create)) {
      this.showCreateReport = true;
    }

    if (this.hasPermission(Permission.Template_Create)) {
      this.showCreateTemplate = true;
    }

    if (this.hasPermission(Permission.Change_Current_Context_Organization)) {
      this.showOrganizationDropdown = true;
      this.organizationApiService.getOrganizationList().then(result => {
        if (result.success) {
          this.organizationOptions = result.result.map(org => {
            if (org.organizationId === result.currentContextOrganizationId)
              this.currentOrganizationOption = { value: org.organizationId, label: org.name };
            return {
              value: org.organizationId,
              label: org.name,
            };
          });
        }
      });
    }
  }

  async loadInitUserInfo(): Promise<GetUserInfoQueryResponse> {
    let result!: GetUserInfoQueryResponse;

    result = await this.reportAppApiService.getUserInfo();

    return result;
  }
}
